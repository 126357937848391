import { Box } from '@mui/material'
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';
import { ReviewsPropsInterface } from 'app/pages/PublicProfile/interface/interface';
import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating';
import ReviewOptions from './reviewOptions';
import { useWindowSize } from 'app/Hooks/windowResize';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { SvgFlagged } from 'app/components/svgicons/svg2';
import * as publicProfileConstants from "config/publicProfileConfig"
import { SvgfluentIcon } from 'app/components/svgicons/svgNew';
import AccountVerifiedIcon from 'app/Component2/GlobalComponent/AccountVerifiedIcon ';

const Reviews = ({ recId, title, userImage, time, flag, review, rating, editable ,flaggable, isflagged, allData }: ReviewsPropsInterface) => {
    const [mobile] = useWindowSize()
    const {t} = useTranslation()

    const [readMore, setReadMore] = useState<boolean>(false)

    return (
        <Box className='reviewsContainer'>
            <Box className='flexRow justifyContentBetween' sx={{ padding: '0 10px' }}>
                <Box className='flexRow '>
                    <Box className='flexRow'>
                        <Box className='userDetailsSubContainers userImageBox'>
                            <img src={userImage ? userImage : userPlaceHolder} alt='userimg' className='reviewerImage' />
                            {flag && <img src={flag} alt='userimg' className='reviewerFlag' />}
                        </Box>
                    </Box>
                    <Box className='reviewerName'>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <p>{title}</p>
                            <AccountVerifiedIcon isVerified={allData?.profile_is_verified} />
                            <span style={{ marginLeft: '5px' }}>{time}</span>
                        </Box>
                        <Box className='flexRow alignItemsCenter'>
                            <Rating initialValue={rating} readonly size={20} className='reviewerRating' /> <span className='reviewerRatingCount'>{rating}</span>
                        </Box>
                    </Box>
                </Box>
                <Box className='flexRow'>

                    <Box className='flexColumn justifyContentCenter'>
                        {isflagged == publicProfileConstants.isFlagged.true && <SvgFlagged style={{height:'23px', padding:'1px'}}/>}
                    </Box>
 
                    {(editable ||( flaggable && isflagged == publicProfileConstants.isFlagged.false)) &&
                        <ReviewOptions recId={recId} userRating={rating} userReview={review} editable={editable} flaggable={flaggable}/>
                    }
                </Box>
            </Box>
            {review && <Box className='reviewContentBox'>
                {( review !== null && review !== undefined ) && <span style={{ color: '#6F6F70', fontSize: '15px' }}>
                    
                    {/* Read more appaers only in mobile view */}
                    {mobile ?
                        <>
                            {/* Truncating string if only length of review is greater than 250 */}
                            {( readMore || review.length < 250) ? review : review.substring(0, 250)}

                            {/* Read more button appears only when length of review is greater than 250 */}
                            {review.length > 250 && <span onClick={() => setReadMore(!readMore)} className='pointer readMore'>{readMore ? t(translations.PUBLICPROFILE.READ_LESS) : t(translations.PUBLICPROFILE.READ_MORE)}</span>}
                        </>
                        : review
                    }
                </span>}
            </Box>}
        </Box>
    )
}

export default Reviews