import { Box, Checkbox } from '@mui/material'
import { FieldWrapper } from 'app/components/CustomFields/style'
import { SvgSearchLite } from 'app/components/svgicons/svg'
import { SvgMobileLeftArrow } from 'app/components/svgicons/svgNew'
import React, { useEffect, useState } from 'react'
import { equimentCategories } from '../filterConfig'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'

const EquipmentList = ({ equipmentFilterData, showEquipments, setShowEquipments, selectedEquipments, setSelectedEquipments }) => {

    const { t } = useTranslation()

    const [activeCategory, _activeCategory] = useState<number>(equimentCategories.Confort)

    const [equipmentList, _equipmentList] = useState<Array<Object>>([])
    const [selectedEquipmentCount, _selectedEquipmentCount] = useState<number>(0)


    useEffect(() => {
        if (equipmentFilterData && equipmentFilterData.equipments && equipmentFilterData.equipments.length > 0) {
            _equipmentList(equipmentFilterData.equipments.filter(o => o.category_equipment_id == activeCategory))
        }
    }, [activeCategory, equipmentFilterData])


    const getCount = (id) => {
        let count = 0
        if (equipmentFilterData && equipmentFilterData.equipments && equipmentFilterData.equipments.length > 0) {
            return equipmentFilterData.equipments.filter(o => o.category_equipment_id == id && (o.media && o.media.length > 0) && o.option_name).length
        }
        return count
    }

    const handleChange = (id) => {
        let selectedIds: number[] = [...selectedEquipments]
        let existence = selectedIds.findIndex((equipmentId) => equipmentId == id)
        if (existence != -1) {
            setSelectedEquipments(selectedIds.filter((equipmentId) => equipmentId != id))
            return
        }
        selectedIds.push(id)
        setSelectedEquipments(selectedIds)
    }

    const getCountSelectedEquipment = (id) => {
        let count = 0
        if (selectedEquipments && selectedEquipments.length > 0) {
            let equiments = equipmentFilterData.equipments.filter((o) => o.category_equipment_id == id)
            selectedEquipments.forEach(selectedValue => {
                if (equiments && equiments.length > 0) {
                    let existence = equiments.findIndex(equiment => equiment.id == selectedValue)
                    if (existence !== -1) {
                        count = count + 1
                    }
                }
            });
        }
        return count
    }

    const checked = (id) => {
        let existence = selectedEquipments.findIndex((equipmentId) => equipmentId == id)
        if (existence != -1) {
            return true
        }
        return false
    }


    const handleSeach = (text) => {
        if (equipmentFilterData && equipmentFilterData.equipments && equipmentFilterData.equipments.length > 0) {
            let list = equipmentFilterData.equipments.filter(o => o.category_equipment_id == activeCategory)
            if (text && text !== '') {
                _equipmentList(list.filter(o => o.option_name.toLowerCase().includes(text.toLowerCase())))
                return
            }
            _equipmentList(list)
        }
    }

    return (
        <FieldWrapper>
            <Box sx={{ px: 2, py: 1 }} className='flexRow justifyContentBetween alignItemsCenter'>
                <SvgMobileLeftArrow onClick={() => setShowEquipments(!showEquipments)} className='pointer' />
                <span className='filterHeader'>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_EQUIPMENTS)}</span>
                <SvgMobileLeftArrow style={{ opacity: '0' }} />
            </Box>
            <Box sx={{ mx: 2, p: 1 }} className='filterList scrollBar equipmentsList'>

                <Box className='flexRow alignItemsCenter searchEquimentsField'>
                    <SvgSearchLite />
                    <span>|</span>
                    <input type="text" onChange={(e) => handleSeach(e.target.value)} />
                </Box>

                {equipmentFilterData && equipmentFilterData.categories && equipmentFilterData.categories.length > 0 && <Box className='equipmentsCategory scrollBar'>
                    {equipmentFilterData.categories.map((category, index) => {
                        return <Box className={(activeCategory == category.id ? 'activeCategory' : '') + ' categoryName'} key={category.id} onClick={() => _activeCategory(category.id)}>
                            {category.category_name} ({getCountSelectedEquipment(category.id)}/{getCount(category.id)})
                        </Box>
                    })}
                </Box>}

                {equipmentList && equipmentList.length > 0 && <Box className='equimentListContainer scrollBar' sx={{ mt: 1 }}>
                    {equipmentList.map((equipment) => {
                        return <Box key={equipment.option_name + equipment.id} className='flexRow alignItemsCenter equipment pointer' onClick={() => handleChange(equipment.id)}>
                            <Checkbox checked={checked(equipment.id)} />
                            <Box className='flexRow alignItemsCenter equipmentBox'>
                                {equipment.media && equipment.media.length > 0 && <img src={equipment.media[0].thumb_url} alt="equiment" />}
                                <span>
                                    {equipment.option_name ?? '-'}
                                </span>
                            </Box>
                        </Box>
                    })}
                </Box>}
            </Box>

        </FieldWrapper>
    )
}

export default EquipmentList