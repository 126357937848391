
export const profileType = {
  'subsidiary': 1,
  'user': 2,
}

export const profileTypeString = {
  'subsidiary': 'subsidiary',
  'user': 'user',
}

export const profile = {
  'pro': "pro",
  'user': "user",
}

export const statusClass = {
  1 : 'parked',
  2 : 'inSale',
  3 : 'reserved',
  4 : 'sold',
}

export const status = {
  'parked' : 1,
  'inSale' : 2,
  'reserved' : 3,
  'sold' : 4,
}

export const Tabs = {
  "History" : 0,
  "Review" : 1
}

export const TabForUrl = {
  "history" : 0,
  "review" : 1
}

export const FollowerTabs = {
  "Followers" : 0,
  "Following" : 1
}

export const isFlagged = {
  "true" : 1,
  "false" : 0
}

export const convertFollowersCount = (num) =>{
  num = num.toString().replace(/[^0-9.]/g, '');
    if (num < 1000) {
        return num;
    }
    let si = [
      {v: 1E3, s: "k"},
      {v: 1E6, s: "m"},
      {v: 1E9, s: "b"},
      {v: 1E12, s: "t"},
      {v: 1E15, s: "p"},
      {v: 1E18, s: "e"}
      ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
        if (num >= si[index].v) {
            break;
        }
    }
    return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
}