import { Box } from '@mui/material'
import { DetailListing, DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { lan } from 'locales/multiPageTranslation/adsLan'
import { translations } from 'locales/translations'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { DetailsWrapper } from '../Details'
import { EngineDetailsI } from 'app/pages/AdsAuctionDetailsPage/AuctionDetailInterface/AuctionInterface'

const EngineDetails = ({ item, nameDriveVehilce }: EngineDetailsI) => {
    const { t } = useTranslation();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType();

    return (
        <DetailsWrapper>
            <Box
                sx={{
                    'span': {
                        fontWeight: 400,
                        display: "inline-block",
                        whiteSpace: "nowrap"
                    }
                }}
            >
                <DetailsTitle sx={{ ml: '15px' }}>{t(...lan.engine())}</DetailsTitle>
                <Box sx={{
                    // p: 1,
                    display: isSmallDevice || isLargeDevice || isExtraLargeDevice ? 'block' : 'flex',
                    columnGap: '20px',
                }}>
                    <Box className={`${!isSmallDevice && 'grid_color_odd'}`} sx={{ flex: 1 }}>
                        <DetailListing className='vehicle-detail-label'>
                            <label>{t(...lan.cylinderCc())}</label>
                            <span>{item?.owner_vehicle?.cylinder_cc ? item?.owner_vehicle?.cylinder_cc + " cc" : "-"}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.coupleNm())}</label>
                            <span>{item?.owner_vehicle?.couple_nm ? item?.owner_vehicle?.couple_nm + " nm" : "-"}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.numberOfCylinder())}</label>
                            <span>{item?.owner_vehicle?.number_of_cylinder || '-'}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.numberOfSpeeds())}</label>
                            <span>{item?.owner_vehicle?.number_of_speeds || '-'}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.powerCv())}</label>
                            <span>{item?.owner_vehicle?.power_cv ? item?.owner_vehicle?.power_cv + " CV" : '-'}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.powerCvFiscal())}</label>
                            <span>{item?.owner_vehicle?.power_cv_fiscal ? item?.owner_vehicle?.power_cv_fiscal + " CV Fiscal" : '-'}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.angleOfAttack())}</label>
                            <span>{item?.owner_vehicle?.angle_of_attack ? item?.owner_vehicle?.angle_of_attack + "°" : "-"}</span>
                        </DetailListing>
                    </Box>
                    <Box className={`${!isSmallDevice && 'grid_color_odd'}`} sx={{ flex: 1 }}>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.departAngle())}</label>
                            <span>{item?.owner_vehicle?.departure_angle ? item?.owner_vehicle?.departure_angle + "°" : "-"}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.tankVolume())}</label>
                            <span>{item?.owner_vehicle?.tank_volume_liter ? item?.owner_vehicle?.tank_volume_liter + " " + t(translations.UNITS.UNITS_LITERS) : "-"}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.topSpeedKmH())}</label>
                            <span>{item?.owner_vehicle?.top_speed ? item?.owner_vehicle?.top_speed + " km/h" : '-'}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.oTto100())}</label>
                            <span>{item?.owner_vehicle['0_100'] ? item?.owner_vehicle['0_100'] + " Sec." : null}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.carDifferentielLock())}</label>
                            <span>{item?.owner_vehicle?.car_differential_lock ? t(translations.addVehicleScreen.YES) : t(translations.addVehicleScreen.NO)}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.euroStandard())}</label>
                            <span>{item?.owner_vehicle?.euro_standard ? item?.owner_vehicle?.euro_standard?.name : null}</span>
                        </DetailListing>
                        <DetailListing
                            className='vehicle-detail-label'
                        >
                            <label>{t(...lan.drivingWheel())}</label>
                            <span>{ nameDriveVehilce || '-'}</span>
                        </DetailListing> 
                    </Box>
                </Box>
            </Box>
        </DetailsWrapper>
    )
}

export default EngineDetails