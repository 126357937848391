import React, { useEffect, useState } from 'react'
import { ContentLoaderOver } from '../ContantLoader/ContentLoader'
import { useHistory } from 'react-router-dom'
import { localstorageKey } from 'config/constants';
import { ApiPaths } from 'services/ApiPaths';
import Api from 'services/axios';
import { useQueryStringParams } from 'app/Hooks/useParams';
import { Toaster } from 'services/Toaster';
import { routes } from 'routes/routes';
import { safeRedirect } from 'config/utils';

const PaymentFailure = () => {

    const { payment_id: paymentId, is_mobile: isMobile } = useQueryStringParams()
    const history = useHistory();

    const redirectTo = localStorage.getItem(localstorageKey.paymentFailure)


    useEffect(() => {
        if (isMobile != 'false' && isMobile != null && isMobile != undefined) {
            // redirect on mobile app
            safeRedirect(process.env.REACT_APP_REDIRECT_ON_APP_LINK)
            return
        }
        if (paymentId) {
            // Api call for update checkout session after payment failure
            Api.post(ApiPaths.user.update_session, {
                payment_id: paymentId,
            }).then(response => {
            }).catch(error => {
                Toaster.error(error?.response?.data?.message)
            }).finally(() => {
                // redirect on credit payment/ reserve vehicle page
                if (history.location.pathname.includes(routes.userRoutes.creditBuyFail)) {
                    localStorage.removeItem(localstorageKey.makeCreditPaymentIntentId)
                }
                if (history.location.pathname.includes(routes.userRoutes.vehiclePaymentFail)) {
                    localStorage.removeItem(localstorageKey.hotdealReservationIntentId)
                }
                history.push(redirectTo)
            });
            return
        }
        history.push(redirectTo)
    }, [paymentId])



    return (
        <div>
            <ContentLoaderOver />
        </div>
    )
}

export default PaymentFailure