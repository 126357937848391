import { getSubsidiaryStored } from 'config/variables';
import { put, takeEvery } from 'redux-saga/effects';
import {
  getShowCaseRequest,
  getShowCaseSuccess,
  getShowCaseFailure,

  updateShowCaseRequest,
  updateShowCaseSuccess,
  updateShowCaseFailure,

  getActiveDealerSuccess,
  getActiveDealerFailure,
  getActiveDealerRequest,

  getShowcaseContactSuccess,
  getShowcaseContactFailure,
  getShowcaseContactRequest,

  updateShowCaseItemRequest,
  updateShowCaseItemSuccess,
  updateShowCaseItemFailure,

  getShowCaseItemRequest,
  getShowCaseItemSuccess,
  getShowCaseItemFailure,

  getShowCaseItemServiceRequest,
  getShowCaseItemServiceSuccess,
  getShowCaseItemServiceFailure,

  updateShowCaseItemServicesRequest,
  updateShowCaseItemServicesSuccess,
  updateShowCaseItemServicesFailure,

  getServicesRequest,
  getServicesSuccess,
  getServicesFailure

} from 'store/actions/showcase-action';

import { ApiPaths } from '../../services/ApiPaths';
import Api from '../../services/axios';

// import { getSubsidiaryStored } from 'config/variables';

function* _getShowCaseRequest({ payload }) {
  try {
    const uri = ApiPaths.showcase.showcaseSetting
    const { data } = yield Api.get(uri);
    yield put(getShowCaseSuccess(data.data))
  } catch (e) {
    yield put(getShowCaseFailure(e));
  }
}

function* _getShowCaseItemRequest({ payload }) {
  try {
    const uri = ApiPaths.showcase.showcaseSetting + '/' + payload.settingID + '?type=' + payload.type
    const { data } = yield Api.get(uri);
    yield put(getShowCaseItemSuccess(data.data))
  } catch (e) {
    yield put(getShowCaseItemFailure(e));
  }
}
function* _getShowCaseItemServiceRequest({ payload }) {
  try {
    const uri = ApiPaths.showcase.showcaseSetting + '/' + payload.settingID + '?type=' + payload.type
    const { data } = yield Api.get(uri);
    yield put(getShowCaseItemServiceSuccess(data.data))
  } catch (e) {
    yield put(getShowCaseItemServiceFailure(e));
  }
}

function* _updateShowCaseItemRequest({ payload }) {
  try {
    const formdata = new FormData();
    formdata.append('subsidiary_id', getSubsidiaryStored());
    formdata.append('type', payload.type);
    for (let index = 0; index < payload.items.length; index++) {
      if (payload.items[index].title || payload.items[index].image || payload.items[index].image_url || payload.items[index].content) {
        formdata.append(`items[${index}][id]`, payload.items[index].id);
        formdata.append(`items[${index}][title]`, payload.items[index].title);
        formdata.append(`items[${index}][description]`, payload.items[index].description);
        if (!payload.items[index].image_url && !payload.items[index].image) {
          formdata.append(`items[${index}][image_url]`, '');
        } else {
          if (payload.items[index].image_url && !payload.items[index].image) {
            formdata.append(`items[${index}][image_url]`, payload.items[index].image_url);
          }
        }
        if (payload.items[index].image) {
          formdata.append(`items[${index}][image]`, payload.items[index].image);
        } else {
          formdata.append(`items[${index}][image]`, '');
        }
        formdata.append(`items[${index}][type]`, payload.items[index].type);
      }
    }
    const uri = ApiPaths.showcase.showcaseSetting + '/' + payload.settingID + ApiPaths.showcase.showcaseItem + '?_method=PATCH'
    const { data } = yield Api.post(uri, formdata);
    yield put(updateShowCaseItemSuccess(data));
  } catch (e) {
    yield put(updateShowCaseItemFailure(e.response));
  }
}
function* _updateShowCaseItemServicesRequest({ payload }) {
  try {
    const formdata = new FormData();
    formdata.append('subsidiary_id', getSubsidiaryStored());
    formdata.append('type', payload.type);
    for (let index = 0; index < payload.items.length; index++) {
      formdata.append(`items[${index}][id]`, payload.items[index].id);
      formdata.append(`items[${index}][description]`, payload.items[index].description);
      if (payload.items[index].service_id) {
        formdata.append(`items[${index}][service_id]`, payload.items[index].service_id);
      }
      if (!payload.items[index].image_url && !payload.items[index].image) {
        formdata.append(`items[${index}][image_url]`, '');
      } else {
        if (payload.items[index].image_url && !payload.items[index].image) {
          formdata.append(`items[${index}][image_url]`, payload.items[index].image_url);
        }
      }
      if (payload.items[index].image) {
        formdata.append(`items[${index}][image]`, payload.items[index].image);
      } else {
        formdata.append(`items[${index}][image]`, '');
      }
      formdata.append(`items[${index}][type]`, payload.items[index].type);
    }
    const uri = ApiPaths.showcase.showcaseSetting + '/' + payload.settingID + ApiPaths.showcase.showcaseItem + '?_method=PATCH'
    const { data } = yield Api.post(uri, formdata);
    yield put(updateShowCaseItemServicesSuccess(data));
  } catch (e) {
    yield put(updateShowCaseItemServicesFailure(e.response));
  }
}
function* _updateShowCaseRequest({ payload }) {
  try {
    let setting = payload.payload.settingID
    let payloadClone = payload.payload
    let subsidiaryId = payloadClone.subsidary_id;
    const formdata = new FormData();
    if (payloadClone.background_image) {
      formdata.append('background_image', payloadClone.background_image);
    }
    if (payloadClone.background_image_url) {
      formdata.append('background_image_url', payloadClone.background_image_url);
    }
    formdata.append('subsidiary_id', subsidiaryId);
    if (payloadClone.link) {
      formdata.append('link', payloadClone.link);
    }
    const uri = ApiPaths.showcase.showcaseSetting + '/' + setting + '?_method=PATCH'
    const { data } = yield Api.post(uri, formdata);
    yield put(updateShowCaseSuccess(data));
  } catch (e) {
    yield put(updateShowCaseFailure(e.response));
  }
}
function* _getActiveDealerRequest({ payload }) {
  try {
    let uri = ApiPaths.company.subsidiary;
    if ("currentPage" in payload) {
      uri += "?page=" + payload.currentPage
    }
    if ("keyword" in payload) {
      uri += "?keyword=" + payload.keyword
    }
    const { data } = yield Api.get(uri, {});
    const row = data;
    yield put(getActiveDealerSuccess(row));
  }
  catch (e) {
    yield put(getActiveDealerFailure(e.response));
  }
}
function* _getShowcaseContactRequest({ payload }) {
  try {
    const uri = ApiPaths.public.shop + '/' + payload.subsidiary_id + ApiPaths.company.department + '/' + payload.department_subsidiary + ApiPaths.company.contacts;
    const { data } = yield Api.get(uri);
    yield put(getShowcaseContactSuccess(data.data));
  } catch (e) {
    yield put(getShowcaseContactFailure(e.data));
  }
}
function* _getServicesRequest({ payload }) {
  try {
    let uri = ApiPaths.showcase.services
    // if(payload.settingID){
    //   uri += '?filter[showcase_setting_id]='+ payload.settingID;
    // }
    const { data } = yield Api.get(uri);
    yield put(getServicesSuccess(data.data.items));
  } catch (e) {
    yield put(getServicesFailure(e.data));
  }
}



export const showcaseSaga = [
  takeEvery(getShowCaseRequest, _getShowCaseRequest),
  takeEvery(getShowCaseItemRequest, _getShowCaseItemRequest),
  takeEvery(getShowCaseItemServiceRequest, _getShowCaseItemServiceRequest),
  takeEvery(updateShowCaseRequest, _updateShowCaseRequest),
  takeEvery(updateShowCaseItemRequest, _updateShowCaseItemRequest),
  takeEvery(updateShowCaseItemServicesRequest, _updateShowCaseItemServicesRequest),
  takeEvery(getActiveDealerRequest, _getActiveDealerRequest),
  takeEvery(getShowcaseContactRequest, _getShowcaseContactRequest),
  takeEvery(getServicesRequest, _getServicesRequest)
];
