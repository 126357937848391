import { put, select, takeEvery } from 'redux-saga/effects';
import { ApiPaths } from "services/ApiPaths";
import Api from "services/axios";
import { createLicensePlateFailure, 
         createLicensePlateRequest, 
         createLicensePlateSuccess,
         deleteLicensePlateFailure,
         deleteLicensePlateRequest,
         deleteLicensePlateSuccess,
         findableForPublicFailure,
         findableForPublicRequest,
         findableForPublicSuccess,
         getVehicleLicensePlateListFailure,
         getVehicleLicensePlateListRequest,
         getVehicleLicensePlateListSuccess,
         getVehicleListFailure,
         getVehicleListRequest, 
         getVehicleListSuccess} from 'store/actions/licensePlate-action';

function* _createLicensePlateRequest({payload}){
  try {
    let uri = ApiPaths.licensePlate.license_plates
    const { data } = yield Api.post(uri, payload.newLicensePlate);
    let response = data.data
    yield put(createLicensePlateSuccess({response}))
  } catch (e:any) {
    yield put(createLicensePlateFailure(e.response));
  }
}

function* _getVehicleListRequest({payload}){
  try {
    let uri = ApiPaths.licensePlate.license_plates+'?filter[with_license_plate]=0&filter[product_type]=1'
    if(payload.keyword){
      uri += '&keyword='+payload.keyword
    }
    if(payload.page){
      uri += '&page='+payload.page
    }
    const { data } = yield Api.get(uri);
    yield put(getVehicleListSuccess(data))
  } catch (e:any) {
    yield put(getVehicleListFailure(e.response));
  }
}

function* _getVehicleLicensePlateListRequest({payload}){ 
  try {
    let uri = ApiPaths.licensePlate.license_plates+'?filter[with_license_plate]=1&filter[product_type]=1'
    if(payload.keyword){
      uri += '&keyword='+payload.keyword
    }
    if(payload.page){
      uri += '&page='+payload.page
    }
    const { data } = yield Api.get(uri);
    yield put(getVehicleLicensePlateListSuccess(data.data))
  } catch (e:any) {
    yield put(getVehicleLicensePlateListFailure(e.response));
  }
}
function* _findableForPublicRequest({payload}){
  try {
    let uri = ApiPaths.licensePlate.license_plates+"/"+payload.licensePlate+"?_method=patch"
    const { data } = yield Api.post(uri,{searchable:payload.searchable});
    yield put(findableForPublicSuccess(data))
  } catch (e:any) {
    yield put(findableForPublicFailure(e.response));
  }
}
function* _deleteLicensePlateRequest({payload}){
  try {
    let uri = ApiPaths.licensePlate.license_plates+"/"+payload.licensePlate
    const { data } = yield Api.delete(uri);
    yield put(deleteLicensePlateSuccess(data))
  } catch (e:any) {
    yield put(deleteLicensePlateFailure(e.response));
  }
}

export const licensePlateSaga = [
  takeEvery(createLicensePlateRequest,_createLicensePlateRequest),
  takeEvery(getVehicleListRequest,_getVehicleListRequest),
  takeEvery(getVehicleLicensePlateListRequest,_getVehicleLicensePlateListRequest),
  takeEvery(findableForPublicRequest,_findableForPublicRequest),
  takeEvery(deleteLicensePlateRequest,_deleteLicensePlateRequest),
]