import { createAction } from 'redux-actions';

export const userProfileSaveRequest = createAction('PROFILE_SAVE_REQUEST');
export const userProfileSaveSuccess = createAction('PROFILE_SAVE_SUCCESS');
export const userProfileSaveFailure = createAction('PROFILE_SAVE_FAILURE');

export const userAddressSaveRequest = createAction('ADDRESS_SAVE_REQUEST');
export const userAddressSaveSuccess = createAction('ADDRESS_SAVE_SUCCESS');
export const userAddressSaveFailure = createAction('ADDRESS_SAVE_FAILURE');

export const changePasswordRequest = createAction('CHANGE_PASSWORD_REQUEST');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordFailure = createAction('CHANGE_PASSWORD_FAILURE');

export const changeEmailRequest = createAction('CHANGE_EMAIL_REQUEST');
export const changeEmailSuccess = createAction('CHANGE_EMAIL_SUCCESS');
export const changeEmailFailure = createAction('CHANGE_EMAIL_FAILURE');

export const changeEmailActivateAccountRequest = createAction('CHANGE_EMAIL_ACTIVATE_ACCOUNT_REQUEST');
export const changeEmailActivateAccountFailure = createAction('CHANGE_EMAIL_ACTIVATE_ACCOUNT_FAILURE');
export const changeEmailActivateAccountSuccess = createAction('CHANGE_EMAIL_ACTIVATE_ACCOUNT_SUCCESS');

export const userProfileImageRequest = createAction('PROFILE_SAVE_IMAGE_REQUEST');
export const userProfileImageFailure = createAction('PROFILE_SAVE_IMAGE_FAILURE');
export const userProfileImageSuccess = createAction('PROFILE_SAVE_IMAGE_SUCCESS');
export const userProfileImageReset = createAction('PROFILE_SAVE_IMAGE_RESET');

export const updateUserPrivacyRequest = createAction('UPDATE_USER_PRIVACY_REQUEST');
export const updateUserPrivacySuccess = createAction('UPDATE_USER_PRIVACY_SUCCESS');
export const updateUserPrivacyFailure = createAction('UPDATE_USER_PRIVACY_FAILURE');

export const getUserDataRequest = createAction('GET_USER_DATA_REQUEST');
export const getUserDataSuccess = createAction('GET_USER_DATA_SUCCESS');
export const getUserDataFailure = createAction('GET_USER_DATA_FAILURE');

export const getUsersPermissionsRequest = createAction('GET_USERS_PERMISSIONS_REQUEST');
export const getUsersPermissionsSuccess = createAction('GET_USERS_PERMISSIONS_SUCCESS');
export const getUsersPermissionsFailure = createAction('GET_USERS_PERMISSIONS_FAILURE');

export const deleteAccountRequest = createAction('DELETE_ACCOUNT_REQUEST');
export const deleteAccountSuccess = createAction('DELETE_ACCOUNT_SUCCESS');
export const deleteAccountFailure = createAction('DELETE_ACCOUNT_FAILURE');

export const getCreditPlansRequest = createAction('GET_CREDIT_PLAN_REQUEST');
export const getCreditPlansSuccess = createAction('GET_CREDIT_PLAN_SUCCESS');
export const getCreditPlansFailure = createAction('GET_CREDIT_PLAN_FAILURE');

export const getPromoCodeRequest = createAction('GET_PROMO_CODE_REQUEST');
export const getPromoCodeSuccess = createAction('GET_PROMO_CODE_SUCCESS');
export const getPromoCodeFailure = createAction('GET_PROMO_CODE_FAILURE');

export const creditsHistoryRequest = createAction('CREDIT_HISTORY_REQUEST');
export const creditsHistorySuccess = createAction('CREDIT_HISTORY_SUCCESS');
export const creditsHistoryFailure = createAction('CREDIT_HISTORY_FAILURE');


export const creditsBalanceRequest = createAction('CREDIT_BALANCE_REQUEST');
export const creditsBalanceSuccess = createAction('CREDIT_BALANCE_SUCCESS');
export const creditsBalanceFailure = createAction('CREDIT_BALANCE_FAILURE');

export const creditsPaymentRequest = createAction('CREDIT_PAYMNENT_REQUEST');
export const creditsPaymentSuccess = createAction('CREDIT_PAYMNENT_SUCCESS');
export const creditsPaymentFailure = createAction('CREDIT_PAYMNENT_FAILURE');