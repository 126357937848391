import { Button, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ConfirmationPopWrapper } from 'app/pages/ChangePlanPage/Component/Style/ConfirmationPopWrapper'
import { translations } from 'locales/translations'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const DeleteConfirm = (props: any) => {
  const { t } = useTranslation()
  const history = useHistory();
  const { open, setOpen, deleteFunc, message, confirmBtn, cancleNaviagtion, cancelBtn, showDelete, verifyAccount } = props

  const handleCancleFunction = () => {
    setOpen(false)
    // in case of switching account by clicking on notification if user cancle switching 
    if (cancleNaviagtion) {
      //then redirect on home page
      history.push("/")
    }
  }
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ConfirmationPopWrapper>
          <Box sx={{ ...style }} className="disable-confirmation-pop">
            <Typography sx={{ textAlign: 'center' }}>{message}</Typography>
            <Box className="disable-confirmation-button">
              {!showDelete && <Button onClick={(e) => deleteFunc(e)} className={verifyAccount ? "button-no" : "button-yes"}>{confirmBtn ? confirmBtn : t(translations.summaryVehicleScreen.YES)}</Button>}
              <Button onClick={() => handleCancleFunction()} className={verifyAccount ? "button-yes" : "button-no"}>{cancelBtn ? cancelBtn : t(translations.ads.CANCEL)}</Button>
            </Box>
          </Box>
        </ConfirmationPopWrapper>
      </Modal>
    </>
  )
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 310,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: '8px',
  padding: "25px 8px",
  'button': {
    textTransform: 'capitalize'
  }
};
export default DeleteConfirm; 