import { auctionType, hotDealsFilterType, vehicleTypeContant } from 'config/appConfig';
import { hotDealAdStatus } from 'config/constants';
import { handleActions } from 'redux-actions';

import {
  getHomePageDataRequest,
  getHomePageDataSuccess,
  getHomePageDataFailure,
  getShopRequest,
  getShopSuccess,
  getShopFailure,
  sliderHomePageFailure,
  sliderHomePageRequest,
  sliderHomePageSuccess,
  verifyPromoRequest,
  verifyPromoSuccess,
  verifyPromoFailure,
  activeLanguagesRequest,
  activeLanguagesSuccess,
  activeLanguagesFailure,
  sendPromoRequest,
  sendPromoSuccess,
  sendPromoFailure,
  legalDocumentRequest,
  legalDocumentSuccess,
  legalDocumentFailure,
  legalDocumentUpdateRequest,
  legalDocumentUpdateSuccess,
  legalDocumentUpdateFailure,
  getPartnerOptionRequest,
  getPartnerOptionFailure,
  PartnersRequest,
  PartnersSuccess,
  PartnersFailure,
  getPartnerOptionSuccessSalutation,
  getPartnerOptionSuccessSubject,
  CountryChecker,
  GetCountriesRequest,
  GetCountriesSuccess,
  GetCountriesFailure,
  setSearchToggler,
  setAdsSearchText,
  setAppBarOpen,
  getPublicAdsRequest,
  getPublicAdsSuccess,
  getPublicAdsFailure,
  getPublicAuctionAdsRequest,
  getPublicAuctionAdsSuccess,
  getPublicAuctionAdsFailure,
  getPublicAdsListReset,
  udpatePublicAdsList,
  updateAuctionAdsList,
  setCreateAdModalOpen,
  resetPublicAuctionAds,
  updatePublicAuctionAds,
  removeAuctionFromList,
  removeAdFromList,
  updateAuctionTimer,
  updateAuctionAd,
  getPublicHotdealAdsRequest,
  getPublicHotdealAdsSuccess,
  updatePublicHotdealAds,
  getPublicHotdealAdsFailure,
  resetPublicHotdealAds,
  getHotdealHomeDataRequest,
  getHotdealHomeDataSuccess,
  getHotdealHomeDataFailure,
  removeAdFromHotdeal,
  removeDealFromHomepage
} from 'store/actions/public-actions';

const initialState = {
  homepageLoader: false,
  homepageAuctionAds: {},
  homepageClassicAds: {},
  homepageHotDealAds: {},
  homepageHotDealAdsCount: {},
  homepageAuctions: {},
  brands: {},
  homepageError: {},
  loading: false,
  shopCaseItems: {},
  shopError: {},
  promoSuccess: {},
  promoFailure: {},
  languagesSuccess: {},
  languagesFailure: {},
  sendPromoDataSuccess: {},
  sendPromoDataFailure: {},
  termsDocument: {},
  privacyPolicyDocument: {},
  dataProtectionDocument: {},
  legalNoticeDocument: {},
  legalBocumentsDocument: {},
  invalidRequest: false,
  documentLoading: true,
  partnerOptionSalutation: {},
  partnerOptionSubject: {},
  partnerOptionError: {},
  partners: {},
  partnersError: {},
  countriesStore: [],
  CountryCheckerAdded: false,
  searchToggler: false,
  searchAdsText: '',
  isAppBarOpen: true,
  publicAdsList: [],
  publicAdsCount: 0,
  publicAdsListCurrentPage: 1,
  publicAuctionAdsListCurrentPage: 1,
  publicAdsListLastPage: null,
  publicAdsListError: {},
  publicAuctionAdsList: [],
  publicAuctionAdsCount: 0,
  publicAuctionAdsListLastPage: null,
  publicAuctionAdsListError: {},
  loadingPublicAds: true,
  publicAuctionAdDetail: {},
  isCreateAdModalOpen: false,
  publicHotdealAdsListLastPage: null,
  publicHotdealAdsCount: 0,
  publicHotdealAdsListCurrentPage: 1,
  publicHotdealAdsList: [],
  publicHotdealAdDetail: {},
  publicHotdealAdsListError: {},
  loadingPublicHotdealAds: true,
  hotDealLoader: false,
  hotDealyoutubeLink: '',
  hotDealSoldCars: [],
  hotDealAdsList: {},
  hotDealError: {}
};
const publicReducer = handleActions(
  {
    [setCreateAdModalOpen]: (state, { payload }) => ({
      ...state,
      isCreateAdModalOpen: payload
    }),
    [getHomePageDataRequest]: state => ({
      ...state,
      homepageLoader: true,
      shopError: {},
    }),
    [getHomePageDataSuccess]: (state, { payload }) => (
      {
        ...state,
        homepageAuctionAds: payload.auction_ads,
        homepageClassicAds: payload.classic_ads,
        homepageHotDealAds: payload.hotdeals,
        homepageHotDealAdsCount: payload.hotdeals_count,
        homepageAuctions: payload.auction_list,
        brands: payload.brands,
        homepageLoader: false
      }),
    // This reducer function removes auctions displayed on homepage
    [removeAuctionFromList]: (state, { payload }) => {
      let homepageAuctionsClone = { ...state.homepageAuctions }
      let listClone = []
      // If auction is of running type we will get array of running auctions
      if (payload.type == auctionType.running) {
        listClone = homepageAuctionsClone[auctionType.running] ?? []
      }
      // Otherwise if auction is of upcoming type we will get array of upcoming auctions
      if (payload.type == auctionType.startingSoon) {
        listClone = homepageAuctionsClone[auctionType.startingSoon] ?? []
      }
      // Checking if auction exists in array or not, if yes then removing in from array
      let existence = listClone.findIndex(o => o.id == payload.id);
      if (existence !== -1) {
        listClone.splice(existence, 1);
      }
      homepageAuctionsClone[payload.type] = listClone
      return {
        homepageAuctions: homepageAuctionsClone,
        ...state,
      };
    }
    ,
    // This reducer function removes auctions ads displayed on homepage
    [removeAdFromList]: (state, { payload }) => {
      let homepageAdsClone = { ...state.homepageAuctionAds }
      // Extracing array of ads from redux state based on product type
      let listClone = homepageAdsClone[payload.product] ?? []
      // Checking if ad exists in array or not, if yes then removing in from array
      let existence = listClone.findIndex(o => o.id == payload.id);
      if (existence !== -1) {
        listClone.splice(existence, 1);
      }
      homepageAdsClone[payload.product] = listClone
      return {
        homepageAuctionAds: homepageAdsClone,
        ...state,
      };
    }
    ,
    // This reducer function updates timer for auctions displayed on homepage
    [updateAuctionTimer]: (state, { payload }) => {
      const homepageAuctionsClone = { ...state.homepageAuctions }
      let listClone = []
      // If auction is of running type we will get array of running auctions
      if (payload.type == auctionType.running) {
        listClone = homepageAuctionsClone[auctionType.running] ?? []
      }
      // Otherwise if auction is of upcoming type we will get array of upcoming auctions
      if (payload.type == auctionType.startingSoon) {
        listClone = homepageAuctionsClone[auctionType.startingSoon] ?? []
      }
      // Checking if auction exists in array or not, if yes then updating timer
      const existence = listClone.findIndex(o => o.id == payload.id);
      if (existence !== -1) {
        listClone[existence].end = payload.time
      }
      homepageAuctionsClone[payload.type] = listClone
      return {
        homepageAuctions: homepageAuctionsClone,
        ...state,
      };
    },
    // This reducer function updates ad details for auctions ads displayed on homepage
    [updateAuctionAd]: (state, { payload }) => {
      const homepageAuctionsAdsClone = { ...state.homepageAuctionAds }
      let listClone = homepageAuctionsAdsClone[payload.product_type_id] ?? []
      // Checking if auction exists in array or not, if yes then updating timer
      const existence = listClone?.findIndex(o => o.id == payload.id);
      if (existence !== -1) {
        if (listClone[existence].best_manual_bid_relation == null) {
          listClone[existence].best_manual_bid_relation = { 'amount': payload.new_price ?? 0 }
        }
        if (listClone[existence].best_manual_bid_relation) {
          listClone[existence].best_manual_bid_relation.amount = payload.new_price ?? 0
        }
        listClone[existence].filled_percent = payload.filled_percent
        listClone[existence].end_auction = payload.end_auction
      }
      homepageAuctionsAdsClone[payload.product] = listClone
      return {
        homepageAuctionAds: homepageAuctionsAdsClone,
        ...state,
      };
    },
    // This reducer function moves deal to respective section when timer ends
    [removeDealFromHomepage]: (state, { payload }) => {
      const dealsClone = { ...state.homepageHotDealAds }
      const countClone = { ...state.homepageHotDealAdsCount }
      const runningAds = dealsClone[hotDealsFilterType.running] ?? []
      const finishedAds = dealsClone[hotDealsFilterType.finished] ?? []
      // Checking if ad exists in running ads section, if yes then we will remove the ad from running ads list
      // and return updated response of running ads to render
      let existence = runningAds.findIndex((ad) => ad.id === payload.id)
      if (existence !== -1) {
        const cloneAd = runningAds[existence]
        dealsClone[hotDealsFilterType.finished] = [...finishedAds, cloneAd]
        runningAds.splice(existence, 1);
        dealsClone[hotDealsFilterType.running] = runningAds
        // updating count after operation
        countClone[hotDealsFilterType.finished] = countClone[hotDealsFilterType.finished] + 1
        countClone[hotDealsFilterType.running] = countClone[hotDealsFilterType.running] - 1
        const newObj = JSON.parse(JSON.stringify({ ...state }))
        newObj["homepageHotDealAds"] = JSON.parse(JSON.stringify(dealsClone))
        newObj["homepageHotDealAdsCount"] = JSON.parse(JSON.stringify(countClone))
        return newObj;
      }
      // If ad was not present in running ads, we will check in starting soon data and if found, will remove it 
      // and return updated starting soon response to render in UI
      const startSoonAds = dealsClone[hotDealsFilterType.startingSoon] ?? []
      existence = startSoonAds.findIndex((ad) => ad.id === payload.id)
      if (existence !== -1) {
        const cloneAd = startSoonAds[existence]
        dealsClone[hotDealsFilterType.running] = [...runningAds, cloneAd]
        startSoonAds.splice(existence, 1);
        dealsClone[hotDealsFilterType.startingSoon] = startSoonAds
        // updating count after operation
        countClone[hotDealsFilterType.running] = countClone[hotDealsFilterType.running] + 1
        countClone[hotDealsFilterType.startingSoon] = countClone[hotDealsFilterType.startingSoon] - 1
        const newObj = JSON.parse(JSON.stringify({ ...state }))
        newObj["homepageHotDealAds"] = JSON.parse(JSON.stringify(dealsClone))
        newObj["homepageHotDealAdsCount"] = JSON.parse(JSON.stringify(countClone))
        return newObj;
      }
    },
    [getHomePageDataFailure]: (state, { payload }) => ({
      ...state,
      homepageError: payload,
      homepageLoader: false
    }),

    [getHotdealHomeDataRequest]: state => ({
      ...state,
      hotDealLoader: true,
      hotDealError: {}
    }),
    [getHotdealHomeDataSuccess]: (state, { payload }) => ({
      ...state,
      hotDealyoutubeLink: payload.video_link ?? '',
      hotDealSoldCars: payload.sold_cars ?? [],
      hotDealAdsList: payload.list ?? {},
      hotDealLoader: false
    }),
    [getHotdealHomeDataFailure]: (state, { payload }) => ({
      ...state,
      hotDealError: payload,
      hotDealLoader: false
    }),
    // This reducer function removes hotdeal ads displayed on hotdeals homepage
    [removeAdFromHotdeal]: (state, { payload }) => {
      const adsClone = { ...state.hotDealAdsList }
      const runningAds = adsClone[hotDealsFilterType.running] ?? []
      const finishedAds = adsClone[hotDealsFilterType.finished] ?? []
      // Checking if ad exists in running ads section, if yes then we will remove the ad from running ads list
      // and return updated response of running ads to render
      let existence = runningAds.findIndex((ad) => ad.id === payload.id)
      if (existence !== -1) {
        const cloneAd = runningAds[existence]
        adsClone[hotDealsFilterType.finished] = [...finishedAds, cloneAd]
        runningAds.splice(existence, 1);
        adsClone[hotDealsFilterType.running] = runningAds
        const newObj = JSON.parse(JSON.stringify({ ...state }))
        newObj["hotDealAdsList"] = JSON.parse(JSON.stringify(adsClone))
        return newObj;
      }
      // If ad was not present in running ads, we will check in starting soon data and if found, will remove it 
      // and return updated starting soon response to render in UI
      const startSoonAds = adsClone[hotDealsFilterType.startingSoon] ?? []
      existence = startSoonAds.findIndex((ad) => ad.id === payload.id)
      if (existence !== -1) {
        const cloneAd = startSoonAds[existence]
        adsClone[hotDealsFilterType.running] = [...runningAds, cloneAd]
        startSoonAds.splice(existence, 1);
        adsClone[hotDealsFilterType.startingSoon] = startSoonAds
        const newObj = JSON.parse(JSON.stringify({ ...state }))
        newObj["hotDealAdsList"] = JSON.parse(JSON.stringify(adsClone))
        return newObj;
      }
    },


    [setAdsSearchText]: (state, { payload }) => ({
      ...state,
      searchAdsText: payload
    }),
    [setSearchToggler]: (state, { payload }) => ({
      ...state,
      searchToggler: payload
    }),
    [getShopRequest]: state => ({
      ...state,
      loading: true,
      shopError: {},
    }),
    [getShopSuccess]: (state, { payload }) => ({
      ...state,
      shopCaseItems: payload,
      shopError: {},
      loading: false
    }),
    [getShopFailure]: (state, { payload }) => ({
      ...initialState,
      shopError: payload,
      loading: false
    }),
    [verifyPromoRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    [sliderHomePageRequest]: (state, { payload }) => ({
      ...state,
      loadingSliders: true,
    }),
    [sliderHomePageSuccess]: (state, { payload }) => (
      {
        ...state,
        sliderBanners: payload.items[sliderType.banners] ?? [],
        sliderPartners: payload.items[sliderType.partners] ?? [],
        loadingSliders: false,
      }),
    [sliderHomePageFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      sliderBanners: {},
      sliderPartners: {},
      loadingSliders: false,
    }),
    [verifyPromoSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      promoSuccess: payload,
      promoFailure: {}
    }),

    [verifyPromoFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      promoSuccess: {},
      promoFailure: payload
    }),
    [activeLanguagesRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      languagesFailure: {}
    }),
    [activeLanguagesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      languagesSuccess: payload,
      languagesFailure: {}
    }),

    [activeLanguagesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      languagesSuccess: {},
      languagesFailure: payload
    }),
    [sendPromoRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    [sendPromoSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      sendPromoDataSuccess: payload,
      sendPromoDataFailure: {}
    }),

    [sendPromoFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      sendPromoDataSuccess: {},
      sendPromoDataFailure: payload
    }),

    [legalDocumentRequest]: (state, { payload }) => ({
      ...state,
      documentLoading: true,
      invalidRequest: false,
      documentError: {}

    }),
    [legalDocumentSuccess]: (state, { payload }) => {
      let object = {
        ...state,
        documentLoading: false,
        documentError: {}
      }
      if (payload.data.item.type === 'terms') {
        object['termsDocument'] = payload.data;
      } else if (payload.data.item.type === 'privacy_policy') {
        object['privacyPolicyDocument'] = payload.data;
      } else if (payload.data.item.type === 'data_protection') {
        object['dataProtectionDocument'] = payload.data;
      } else if (payload.data.item.type === 'legal_notice') {
        object['legalNoticeDocument'] = payload.data;
      } else if (payload.data.item.type === 'legal_documents') {
        object['legalBocumentsDocument'] = payload.data;
      }
      return object;
    },
    [legalDocumentFailure]: (state, { payload }) => {
      if (payload?.status && payload?.status === 404) {
        state.invalidRequest = true;
      }
      return {
        documentLoading: false,
        ...state
      }
    },
    [legalDocumentUpdateRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      document: {},
      documentError: {}

    }),
    [legalDocumentUpdateSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      document: payload.data,
      documentError: {}
    }),
    [legalDocumentUpdateFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      document: {},
      documentError: payload.data
    }),

    [getPartnerOptionRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      partnerOptionSalutation: {},
      partnerOptionSubject: {},
      partnerOptionError: {}

    }),
    [getPartnerOptionSuccessSalutation]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        partnerOptionSalutation: payload.data,
        partnerOptionError: {}

      }),
    [getPartnerOptionSuccessSubject]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        partnerOptionSubject: payload.data,
        partnerOptionError: {}

      }),

    [getPartnerOptionFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      partnerOptionSalutation: {},
      partnerOptionSubject: {},
      partnerOptionError: payload
    }),

    [PartnersRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      partners: {},
      partnersError: {}

    }),
    [PartnersSuccess]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        partners: payload,
        partnersError: {}

      }),

    [PartnersFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      partners: {},
      partnersError: payload.data
    }),

    [GetCountriesRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      countriesStore: [],

    }),
    [GetCountriesSuccess]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        countriesStore: payload,
        partnersError: {}
      }),

    [GetCountriesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      partners: {},
      countriesStore: []
    }),
    [CountryChecker]: (state, { payload }) => ({
      ...state,
      CountryCheckerAdded: true
    }),
    [setAppBarOpen]: (state, { payload }) => ({
      ...state,
      isAppBarOpen: payload
    }),

    //  Get Public Classic Ads list
    [getPublicAdsRequest]: state => ({
      ...state,
      loadingPublicAds: true
    }),
    [getPublicAdsSuccess]: (state, { payload }) => (
      {
        ...state,
        publicAdsListLastPage: payload.ads.last_page,
        publicAdsListCurrentPage: payload.ads.current_page,
        publicAdsCount: payload.ads.total,
        publicAdsList: payload.ads.data,
        loadingPublicAds: false
      }),
    [udpatePublicAdsList]: (state, { payload }) => (
      {
        ...state,
        publicAdsList: payload
      }),
    [getPublicAdsFailure]: (state, { payload }) => (
      {
        ...state,
        publicAdsListError: payload.data,
        loadingPublicAds: false
      }),
    // reset public ads list on vehicle page change  
    [getPublicAdsListReset]: (state) => {
      localStorage.removeItem('screen')
      localStorage.removeItem('prevPath')
      return {
        ...state,
        publicAdsList: [],
        publicAdsListCurrentPage: 1,
        loadingPublicAds: true,
        publicAdsListLastPage: null,
        publicAdsCount: 0
      }
    }
    ,

    //  Get Public Auction Ads list
    [getPublicAuctionAdsRequest]: state => ({
      ...state,
      loadingPublicAds: true
    }),
    [getPublicAuctionAdsSuccess]: (state, { payload }) => (
      {
        ...state,
        publicAuctionAdsListLastPage: payload.ads.last_page,
        publicAuctionAdsCount: payload.ads.total,
        publicAuctionAdsListCurrentPage: payload.ads.current_page,
        publicAuctionAdsList: payload.ads.current_page === 1 ? payload.ads.data : [...state.publicAuctionAdsList, ...payload.ads.data],
        publicAuctionAdDetail: payload.auction_detail,
        loadingPublicAds: false
      }),
    // Function to remove ad from list whenever auction time end based on ad id
    [updatePublicAuctionAds]: (state, { payload }) => {
      if (payload.id) {
        return {
          ...state,
          publicAuctionAdsList: state.publicAuctionAdsList.filter((ads) => ads.id !== payload.id),
          loadingPublicAds: false
        }
      }
    },
    [updateAuctionAdsList]: (state, { payload }) => (
      {
        ...state,
        publicAuctionAdsList: payload
      }),
    [getPublicAuctionAdsFailure]: (state, { payload }) => (
      {
        ...state,
        publicAuctionAdsListError: payload.data,
        loadingPublicAds: false
      }),
    [resetPublicAuctionAds]: (state, { payload }) => (
      {
        ...state,
        publicAuctionAdsListLastPage: null,
        publicAuctionAdsCount: 0,
        publicAuctionAdsListCurrentPage: 1,
        publicAuctionAdsList: [],
        publicAuctionAdDetail: {},
        loadingPublicAds: false
      }),

    //  Get Public Hot deals Ads list
    [getPublicHotdealAdsRequest]: state => ({
      ...state,
      loadingPublicHotdealAds: true
    }),
    [getPublicHotdealAdsSuccess]: (state, { payload }) => (
      {
        ...state,
        publicHotdealAdsListLastPage: payload.ads.last_page,
        publicHotdealAdsCount: payload.ads.total,
        publicHotdealAdsListCurrentPage: payload.ads.current_page,
        publicHotdealAdsList: payload.ads.current_page === 1 ? payload.ads.data : [...state.publicHotdealAdsList, ...payload.ads.data],
        publicHotdealAdDetail: payload.hotdeal_detail,
        loadingPublicHotdealAds: false
      }),
    // Function to remove ad from list whenever auction time end based on ad id
    [updatePublicHotdealAds]: (state, { payload }) => {
      if (payload.id) {
        const adsClone = [...state.publicHotdealAdsList]
        const existence = adsClone.findIndex((ad) => ad.id === payload.id)
        if (existence !== -1) {
          if (payload.status && ((adsClone[existence]['ad_status'] && adsClone[existence]['ad_status'] != hotDealAdStatus.sold) || !adsClone[existence]['ad_status'])) {
            adsClone[existence]['ad_status'] = payload.status
          }
          if (payload.blocked_until !== undefined) {
            adsClone[existence]['blocked_until'] = payload.blocked_until
          }
        }
        return {
          ...state,
          publicHotdealAdsList: adsClone,
          loadingPublicHotdealAds: false
        }
      }
    },
    [getPublicHotdealAdsFailure]: (state, { payload }) => (
      {
        ...state,
        publicHotdealAdsListError: payload.data,
        loadingPublicHotdealAds: false
      }),
    [resetPublicHotdealAds]: (state, { payload }) => (
      {
        ...state,
        publicHotdealAdsListLastPage: null,
        publicHotdealAdsCount: 0,
        publicHotdealAdsListCurrentPage: 1,
        publicHotdealAdsList: [],
        publicHotdealAdDetail: {},
        loadingPublicHotdealAds: true
      }),
  },
  initialState,
);

export default publicReducer;
