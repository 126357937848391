
import { Box } from '@mui/material'
import { lan } from 'locales/multiPageTranslation/adsLan'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const NotFound = (props) => {
  const { message } = props;
  const { t } = useTranslation()
  return (
    <Wrapper className={props?.classes ? props.classes : ''}>
      <Box className='not-found-message'>
        {props.message ? props.message : t(...lan.notFound())}
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    margin: 20px 0px 0px 0px;
    background:#fff;
    font-size:22px;
    width:100%;   
    display:flex;
    // justify-content:center;
    .not-found-message{
      padding:20px;
      margin:10px 0;
      border-radius: 6px;
      box-shadow:0 0 3px #ccc;
      max-width:1170px;
      width :100%; 
    }
    @media(max-width: 768px) {
      font-size:18px;
      .not-found-message{
        padding:10px;
      }
    }
    
`

export default NotFound