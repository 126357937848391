import { Box } from '@mui/material'
import { DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { numberFormat } from 'config/variables'
import { lan } from 'locales/multiPageTranslation/adsLan'
import { translations } from 'locales/translations'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'

const VehiclePrice = ({ item }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const [isSmallDevice] = useDeviceType()

    const [isHotDealsPage] = useState(() => location.pathname.includes(routes.publicRoutes.hotDeal))
    const [isAuctionDetailsPage] = useState(() => location.pathname.includes(routes.vehicleRoutes.auctionAds))

    const [price, _price] = useState<string>()
    const [discountedPrice, _discountedPrice] = useState<string>()
    const [vatValue, _vatValue] = useState<string>()
    const [totalAmount, _totalAmount] = useState<string>()

    const getPrices = useCallback(() => {
        if (item?.owner_vehicle?.vehicle_deductible_vat) {
            _price(parseFloat(item.public_price).toFixed(2))
            _vatValue(parseFloat(item.total_price - item.public_price).toFixed(2))
            _totalAmount(parseFloat(item.total_price).toFixed(2))
        } else {
            _price(parseFloat(item.total_price).toFixed(2))
            _totalAmount(parseFloat(item.total_price).toFixed(2))
        }

        if (isHotDealsPage) {
            _price(parseFloat(item.total_price).toFixed(2))
            _discountedPrice(parseFloat(item.discounted_price).toFixed(2))
            _totalAmount(parseFloat(item.total_discounted_price).toFixed(2))
            _vatValue(parseFloat(item.total_discounted_price - item.discounted_price).toFixed(2))
        }
    }, [item]);


    useEffect(() => {
        getPrices()
    }, [item])


    //Function for display formated Price of vehicle, VAT and Total Price 
    const showFormattedPrice = (netPrice) => {
        if (netPrice && !isAuctionDetailsPage) {
            return '€ ' + numberFormat(netPrice)
        }
        // Display Not aplicable price on auction page
        return '€  N/A'
    }


    return (
        <>
            <Box sx={{
                flex: 1,
                mb: isSmallDevice ? 2 : 0,
            }}>
                <DetailsTitle sx={{ ml: '10px' }}>{t(translations.ads.PRICE)}</DetailsTitle>
                <Box>
                    {/* Net public price of Vehicle */}
                    <Box className="price-box text-style" sx={{ background: "#FAFAFF", color: '#666' }}>
                        <label>{t(translations.ads.PRICE)}</label>
                        <span> {showFormattedPrice(price)}</span>
                    </Box>

                    {isHotDealsPage && <Box className="price-box text-style" sx={{ background: "#FAFAFF", color: '#666' }}>
                        <label>{t(translations.HOT_DEALS.DISCOUNTED_PRICE)}</label>
                        <span> {showFormattedPrice(discountedPrice)}</span>
                    </Box>}

                    {/* Percentage value amd amount of VAT applied on Vehicle  */}
                    {item?.owner_vehicle?.vehicle_deductible_vat ? <Box className="price-box text-style" sx={{ color: '#666' }}>
                        <label>{t(...lan.vat())}.
                            {item?.user_data?.limited_address?.vat_rate ? ' ' + parseInt(item.user_data.limited_address.vat_rate) + '%' : '-%'}
                        </label>
                        <span>{showFormattedPrice(vatValue)}</span>
                    </Box> : null}

                    {/* Total price of Vehicle after including VAT */}
                    <Box className="text-style"
                        sx={{
                            background: "#6579F4",
                            color: '#ffffff',
                        }}
                    >
                        <label>{t(...lan.total())}</label>
                        <span>{showFormattedPrice(totalAmount)}</span>
                    </Box>

                </Box>
            </Box>
        </>
    )
}

export default VehiclePrice