import { Box, CircularProgress } from '@mui/material';
import useAuctionEnd from 'app/Hooks/useAuctionEnd';
import { getUtcTime, useCountdown } from 'app/Hooks/useCoundown';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import Timer from 'app/components/AuctionTimer/timer';
import { HomePageWrapper } from 'app/pages/AdsHomePage/style/HomepageWrapper';
import { auctionEndAnimationTime } from 'config/variables';
import { translations } from 'locales/translations';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Counter = ({ adDetail, auctionTime, removeAdsAuction, _isContestEnding, _contestEndingMessage, _updateTimer }) => {
    const { t } = useTranslation();
    const [days, hours, minutes, seconds] = useCountdown(auctionTime);

    const _isAuctionEnding = () => {
        return adDetail?.auction?.start && adDetail?.end_auction && (!getUtcTime().isBefore(moment(adDetail.auction.start))) && (!getUtcTime().isBefore(moment(adDetail.end_auction)));
    }

    const isAuctionEnding = _isAuctionEnding();
    const [contestEnding, endingMessage] = useAuctionEnd(days, hours, minutes, seconds, adDetail, isAuctionEnding, removeAdsAuction, auctionEndAnimationTime.endingTime, auctionEndAnimationTime.endedTime);

    useEffect(() => {
        _contestEndingMessage(endingMessage)
    }, [endingMessage])

    useEffect(() => {
        _isContestEnding(contestEnding)
    }, [contestEnding])

    useEffect(() => {
        if (days == '00' && hours == '00' && minutes == '00' && seconds == '00') {
            if (adDetail && Object.keys(adDetail).length > 0) {
                const auctionStartin = new Date(adDetail.auction.start).getTime();
                setTimeout(() => {
                    const currentTime = new Date().getTime();
                    if (currentTime > auctionStartin) {
                        _updateTimer(true)
                    }
                }, 1000)
            }
        }
    }, [seconds])

    return (
        <HomePageWrapper style={{ width: '90%', margin: '0 auto' }}>
            <Timer days={days} hours={hours} minutes={minutes} seconds={seconds} />
        </HomePageWrapper>
    )
}

export default Counter