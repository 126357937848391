import React from 'react'
import { ShareAdWrapper } from './style'
import { Box, Button, Popover } from '@mui/material'
import { SvgShareIconWhite } from '../svgicons/svg2'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from 'react-share'
import { SvgCloseRound, SvgCopyLink } from '../svgicons/svgNew'
import { Toaster } from 'services/Toaster'
import { shareLink } from 'config/utils'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { adsTypes } from 'config/variables'

const ShareAd = ({ title, linkToShare, topBar, adType = 'park', showLikeButton }) => {

    const { t } = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [isSmallDevice, isMediumDevice] = useDeviceType()
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const emailBody =
        `${t(translations.SHARE_AD.HELLO)}, 
${t(translations.SHARE_AD.FOUND_VEHICLE_AT_CTB)}:
${title}
${t(translations.SHARE_AD.ALL_DETAIL_ABOUT_VEHICLE)}`

    return (
        <ShareAdWrapper>
            {(isSmallDevice || isMediumDevice) && topBar
                ?
                <SvgShareIconWhite className='share-icon' onClick={(e) => shareLink(e, t(translations.webTexts.SHARED_FROM_CARTOBIKE), title, linkToShare, setAnchorEl)} />

                : <Button sx={{
                    position: 'absolute',
                }} aria-describedby={id} className={`contentBox ${!showLikeButton ? 'withoutlike' : 'share-icon'
                    } `} onClick={(e) => shareLink(e, t(translations.webTexts.SHARED_FROM_CARTOBIKE), title, linkToShare, setAnchorEl)}>
                    <SvgShareIconWhite />
                </Button>}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ShareAdWrapper>
                    <Box className='shareContainer' sx={{
                        'button': {
                            textAlign: 'left',
                            'svg': {
                                mr: '13px'
                            }
                        },
                        '.close-icon': {
                            fontWeight: 600,
                            'svg': {
                                margin: '0',
                                position: 'absolute',
                                right: '18px',
                                top: '18px',
                            }
                        }
                    }}>
                        <Box className='flexRow justifyContentBetween width-100'>
                            <span>{t(translations.PUBLICPROFILE.SHARE_PROFILE)}</span>
                            <span onClick={() => handleClose()} className='pointer'><SvgCloseRound /></span>
                        </Box>
                        <FacebookShareButton
                            url={linkToShare}
                            quote={title}
                            className="some-network_share-button width-100 pointer"
                        >
                            <FacebookIcon size={32} round />{t(translations.PUBLICPROFILE.FACEBOOK)}
                        </FacebookShareButton>

                        <EmailShareButton
                            url={linkToShare}
                            subject={`Cartobike.com - ${t(translations.ads.ADS)} `}
                            body={adType == adsTypes.hotDeals ? title : emailBody}
                            className="network__share-button width-100 pointer"
                        >
                            <EmailIcon size={32} round />{t(translations.PUBLICPROFILE.EMAIL)}
                        </EmailShareButton>
                        <TwitterShareButton
                            url={linkToShare}
                            title={title}
                            hashtags={["CarToBike", "Profile"]}
                            className="network__share-button width-100 pointer"
                        >
                            <XIcon size={32} round />{t(translations.PUBLICPROFILE.TWITTER)}
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={`${title} \n\n ${linkToShare} `}
                            separator={""}
                            className="network_share-button width-100 pointer"
                        >
                            <WhatsappIcon size={32} round />{t(translations.PUBLICPROFILE.WHATSAPP)}
                        </WhatsappShareButton>
                        <Box
                            className="some-network_share-button width-100 pointer"
                            sx={{
                                'svg': {
                                    mr: "13px",
                                    width: 32
                                }
                            }}
                            onClick={() => {
                                if ('clipboard' in navigator) {
                                    navigator.clipboard.writeText(linkToShare)
                                    Toaster.success(t(translations.webTexts.COPIED))
                                } else {
                                    document.execCommand('copy', true, linkToShare);
                                    Toaster.success(t(translations.webTexts.COPIED))
                                }
                            }}
                        >
                            <SvgCopyLink />{t(translations.PUBLICPROFILE.COPYLINK)}
                        </Box>
                    </Box>
                </ShareAdWrapper>
            </Popover >
        </ShareAdWrapper >

    )
}

export default ShareAd