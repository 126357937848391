// import firebase from "firebase";
import { initializeApp } from 'firebase/app';
import { getToken, onMessage, getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
}

const firebaseApp = initializeApp(firebaseConfig);
let messaging: any = ""
if ('serviceWorker' in navigator) {
    messaging = getMessaging(firebaseApp);
}

export const setTokenToLocal = (params: any) => {
    localStorage.setItem('deviceToken', JSON.stringify(params));
}

export const fetchToken = (setTokenFound, func) => {
    const devicTokenLocal = localStorage.getItem('deviceToken');
    const notificationToke = devicTokenLocal ? JSON.parse(devicTokenLocal) : ''
    if (window.Notification) {
        Notification.requestPermission().then(function (getperm) {
            if (getperm !== 'granted') {
                if (notificationToke) {
                    func(notificationToke, true)
                }
                return;
            }
            if (getperm === 'granted') {
                if (notificationToke) {
                    func(notificationToke, false)
                }
            }
        })
    }

    if ('serviceWorker' in navigator) {
        return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_APIDKEY })
            .then((currentToken) => {
                if (currentToken) {
                    setTokenFound(true);
                    setTokenToLocal(currentToken);
                    if (!notificationToke) {
                        func(currentToken, false)
                    }
                    // Perform any other neccessary action with the token
                } else {
                    // Show permission request UI
                    setTokenFound(false);
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token.');
            });
    }
};
export const onMessageListener = () =>
    new Promise(resolve => {
        onMessage(messaging, payload => {
            resolve(payload);
        });
    });