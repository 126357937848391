import { Button, Tooltip } from '@mui/material'
import { colorNew } from 'styles/StyleConstants';

const ButtonCustom = (props: any) => {
  const { clickFunction, textBtn, btnColor, icon, style, classes, href, disable, id, buttonId, tooltiptitle } = props;
  return (
    <Tooltip title={tooltiptitle || ''}>
      <Button
        id={buttonId}
        key={id}
        disabled={disable ? disable : false}
        href={href ? href : null}
        onClick={(e) => clickFunction ? clickFunction(e) : ''}
        className={btnColor + ' ' + classes}
        sx={{
          position: 'relative',
          fontWeight: 400,
          '.btnText': {
            wordBreak: 'auto-phrase',
          },
          '&.text-gray': {
            color: colorNew.colorGray2
          },
          '&.outline-gray': {
            color: colorNew.colorGrayDark,
            border: `1px solid ${colorNew.colorGray2}`,
            background: colorNew.colorWhite,
          },
          '&.big-btn': {
            marginTop: '41px',
            lineHeight: '25px',
            width: '100%',
            padding: '16px',
            fontSize: '21px',
          },
          '&.medium': {
            p: '8px 20px',
            width: '100%',
          },
          '&.fadeRed': {
            color: colorNew.colorRed,
            background: colorNew.colorRedFade
          },
          '&.red': {
            color: colorNew.colorRed,
            background: colorNew.colorRedFade
          },
          '&.red-btn': {
            color: colorNew.colorWhite,
            background: colorNew.colorRed
          },
          '&.red-out-line': {
            color: `${colorNew.colorRed} !important`,
            border: `1px solid ${colorNew.colorRed}`,
            background: 'transparent',
            'svg': {
              'path': {
                stroke: colorNew.colorRed
              }
            }
          },
          '&.primary-out-line': {
            color: `${colorNew.primaryColor} !important`,
            border: `1px solid ${colorNew.primaryColor}`,
            background: 'transparent',
            'svg': {
              'path': {
                stroke: colorNew.primaryColor
              }
            }
          },
          '&.primary': {
            color: colorNew.colorWhite,
            background: colorNew.primaryColor
          },
          '&.gray-btn': {
            color: colorNew.colorGrayDark,
            background: colorNew.colorGray
          },
          '&.green': {
            color: colorNew.colorWhite,
            border: `1px solid ${colorNew.colorGreenDark} !important`,
            background: `${colorNew.colorGreenDark} !important`
          },
          '&.yellow': {
            color: colorNew.colorBlack,
            background: colorNew.colorYellow2
          },
          '&.green-out-line': {
            color: `${colorNew.colorGreenDark} !important`,
            border: `1px solid ${colorNew.colorGreenDark}`,
            background: 'transparent'
          },
          '&.green-btn': {
            color: `${colorNew.colorWhite} !important`,
            background: colorNew.colorGreen
          },
          '&.light-green-btn': {
            color: `${colorNew.colorWhite} !important`,
            background: colorNew.lightGreenColor
          },
          '&.white-btn': {
            color: `${colorNew.primaryColor} !important`,
            background: colorNew.colorWhite
          },
          '&.grey-btn': {
            color: `${colorNew.colorGrayOnButton} !important`,
            background: colorNew.colorGrayButton
          },
          '&.black-btn:hover': {
            color: `${colorNew.colorWhite} !important`,
            background: colorNew.colorBlack
          },
          '&.red_white': {
            color: `${colorNew.colorWhite} !important`,
            background: '#ff912c !important',
            border: 'unset !important',
          },
          '&.orange_white': {
            color: `${colorNew.colorWhite} !important`,
            background: `${colorNew.orangeColor} !important`,
            border: 'unset !important',
          },
          '&.disable-btn': {
            '.btnText': {
              color: '#999999 !important',
            },
            background: '#F2F2F2',
            'svg': {
              'path': {
                fill: '#999999'
              }
            }
          },
          'span': {
            'svg': {
              width: 20,
              height: 20
            }
          },
          '.icon': {
            mr: textBtn && textBtn !== "" ? 1 : 0
          },
          ...style,

        }}
      >
        {icon && !props.buttonIcon ?
          <span className='icon'>
            {icon !== undefined ? icon : ''}
          </span>
          : null
        }
        {textBtn ? <span className='btnText'> {textBtn}</span> : null}
        {props.buttonIcon ?
          <span className='icon'>
            {icon !== undefined ? icon : ''}
          </span>
          : null
        }
      </Button>
    </Tooltip>
  )
}
export default ButtonCustom;