import { createAction } from 'redux-actions';

export const getHomePageDataRequest = createAction('GET_HOMEPAGE_DATA_REQUEST');
export const getHomePageDataSuccess = createAction('GET_HOMEPAGE_DATA_SUCCESS');
export const getHomePageDataFailure = createAction('GET_HOMEPAGE_DATA_FAILURE');
export const updateAuctionTimer = createAction('UPDATE_AUCTION_TIMER');
export const updateAuctionAd = createAction('UPDATE_AUCTION_AD');
export const removeAuctionFromList = createAction('REMOVE_AUCTION_FROM_LIST');
export const removeAdFromList = createAction('REMOVE_AD_FROM_LIST');
export const removeDealFromHomepage = createAction('REMOVE_HOTDEAL_FROM_LIST');

export const getHotdealHomeDataRequest = createAction('GET_HOTDEALHOME_DATA_REQUEST');
export const getHotdealHomeDataSuccess = createAction('GET_HOTDEALHOME_DATA_SUCCESS');
export const getHotdealHomeDataFailure = createAction('GET_HOTDEALHOME_DATA_FAILURE');
export const removeAdFromHotdeal = createAction('REMOVE_AD_FROM_HOTDEAL');


export const getShopRequest = createAction('GET_SHOP_REQUEST');
export const getShopSuccess = createAction('GET_SHOP_SUCCESS');
export const getShopFailure = createAction('GET_SHOP_FAILURE');

export const sliderHomePageRequest = createAction('SLIDER_HOMEPAGE_REQUEST');
export const sliderHomePageSuccess = createAction('SLIDER_HOMEPAGE_SUCCESS');
export const sliderHomePageFailure = createAction('SLIDER_HOMEPAGE_FAILURE');


export const verifyPromoRequest = createAction('VERIFY_PROMO_REQUEST');
export const verifyPromoSuccess = createAction('VERIFY_PROMO_SUCCESS');
export const verifyPromoFailure = createAction('VERIFY_PROMO_FAILURE');

export const activeLanguagesRequest = createAction('ACTIVE_LANGUAGES_REQUEST');
export const activeLanguagesSuccess = createAction('ACTIVE_LANGUAGES_SUCCESS');
export const activeLanguagesFailure = createAction('ACTIVE_LANGUAGES_FAILURE');

export const sendPromoRequest = createAction('SEND_PROMO_REQUEST');
export const sendPromoSuccess = createAction('SEND_PROMO_SUCCESS');
export const sendPromoFailure = createAction('SEND_PROMO_FAILURE');

export const legalDocumentRequest = createAction('LEGAL_DOCUMENT_REQUEST');
export const legalDocumentSuccess = createAction('LEGAL_DOCUMENT_SUCCESS');
export const legalDocumentFailure = createAction('LEGAL_DOCUMENT_FAILURE');

export const legalDocumentUpdateRequest = createAction('LEGAL_DOCUMENT_UPDATE_REQUEST');
export const legalDocumentUpdateSuccess = createAction('LEGAL_DOCUMENT_UPDATE_SUCCESS');
export const legalDocumentUpdateFailure = createAction('LEGAL_DOCUMENT_UPDATE_FAILURE');

export const getPartnerOptionRequest = createAction('PARTNER_OPTION_REQUEST');
export const getPartnerOptionSuccessSalutation = createAction('PARTNER_OPTION_SALUTATION_SUCCESS');
export const getPartnerOptionSuccessSubject = createAction('PARTNER_OPTION_SUBJECT_SUCCESS');
export const getPartnerOptionFailure = createAction('PARTNER_OPTION_FAILURE');

export const PartnersRequest = createAction('PARTNERS_REQUEST');
export const PartnersSuccess = createAction('PARTNERS_SUCCESS');
export const PartnersFailure = createAction('PARTNERS_FAILURE');

export const GetCountriesRequest = createAction('GET_COUNTRIES_REQUEST');
export const GetCountriesSuccess = createAction('GET_COUNTRIES_SUCCESS');
export const GetCountriesFailure = createAction('GET_COUNTRIES_FAILURE');

export const CountryChecker = createAction('COUNTRYCHECKER');
export const setSearchToggler = createAction('SETSEARCH_TOGGLER')
export const setAdsSearchText = createAction('SET_ADS_SEARCH_TEXT');
export const setAppBarOpen = createAction('SET_APP_BAR_OPEN');

export const getPublicAdsRequest = createAction('GET_PUBLIC_ADS_REQUEST');
export const getPublicAdsSuccess = createAction('GET_PUBLIC_ADS_SUCCESS')
export const getPublicAdsFailure = createAction('GET_PUBLIC_ADS_FAILURE');
export const udpatePublicAdsList = createAction('UPDATE_PUBLIC_ADS_LIST');
export const getPublicAdsListReset = createAction('GET_PUBLIC_ADS_RESET');

export const getPublicAuctionAdsRequest = createAction('GET_PUBLIC_AUCTION_ADS_REQUEST');
export const getPublicAuctionAdsSuccess = createAction('GET_PUBLIC_AUCTION_ADS_SUCCESS')
export const updateAuctionAdsList = createAction('UPDATE_AUCTION_ADS_LIST');
export const getPublicAuctionAdsFailure = createAction('GET_PUBLIC_AUCTION_ADS_FAILURE');
export const updatePublicAuctionAds = createAction('UPDATE_AUCTION_ADS');
export const resetPublicAuctionAds = createAction('RESET_AUCTION_ADS');

export const getPublicHotdealAdsRequest = createAction('GET_PUBLIC_HOT_DEAL_ADS_REQUEST');
export const getPublicHotdealAdsSuccess = createAction('GET_PUBLIC_HOT_DEAL_ADS_SUCCESS')
export const getPublicHotdealAdsFailure = createAction('GET_PUBLIC_HOT_DEAL_ADS_FAILURE');
export const updatePublicHotdealAds = createAction('UPDATE_HOT_DEAL_ADS');
export const resetPublicHotdealAds = createAction('RESET_HOT_DEALS_ADS');

export const setCreateAdModalOpen = createAction('SET_CREATE_AD_AUCTION_MODAL');
