import { Box } from '@mui/material'
import { SvgMobileLeftArrow } from '../svgicons/svgNew'
import { useHistory } from 'react-router-dom'


const StaticSmallDeviceHeader = ({ title }) => {
    const history = useHistory()

    const handleGoBack = () => {
        // go back on home page , if there is no previous url in history related to cartobike,
        // length <= 2 means one is current page , one is google home page, if there more page than there two page , than redirect on previous page from those pages 
        if (history.length <= 2) {
            history.push('/')
        }
        else history.goBack();
    }

    return (
        <>
            <Box className='flexRow justifyContentBetween' style={{ width: '100%', alignItems: 'center', position: 'sticky', top: 0, padding: '10px 15px', height: '50px', backgroundColor: '#fff', zIndex: 1, borderBottom: '1px solid rgb(239 239 239)' }}>
                <SvgMobileLeftArrow onClick={handleGoBack} />
                <Box className='flexColumn alignItemsCenter'>
                    <span className='header'>{title}</span>
                </Box>
                <Box></Box>
            </Box>
        </>
    )
}

export default StaticSmallDeviceHeader