import { Box, Modal, Typography } from '@mui/material'
import { SvgClose } from 'app/components/svgicons/svg';
import { colorNew } from 'styles/StyleConstants';
import { useTranslation } from 'react-i18next';

export const InfoPopup = (props: any) => {
    const { t } = useTranslation();
    const { open, handleClosePopup, PopupBody, title } = props;
    return (
        <>
            <Modal
                open={open}
                onClose={handleClosePopup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 400,
                    width: 'calc(100% - 20px)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 5,
                    overflow: 'hidden',
                    '.Header-box': {
                        borderBottom: title && '1px solid #9E9E9E',
                        padding: '13px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        'span': {
                            fontWeight: 600
                        }
                    },
                    '.body-box': {
                        p: 2,
                        display: 'flex',
                        fontSize: 14,
                        '.toggler': {
                            minWidth: 80,
                            textAlign: 'center',
                            alignSelf: 'center',
                        },
                    }
                }}>
                    <div className='Header-box'>
                        <span className='fs-4 fw-bold'>{title}</span>
                        <span onClick={handleClosePopup}> <SvgClose /></span>
                    </div>
                    <div>
                        {<PopupBody />}
                    </div>

                </Box>
            </Modal>
        </>
    )
}
