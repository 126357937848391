import { Box } from '@mui/material'
import { translations } from 'locales/translations';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SVGPlayBtn } from '../Assets/NavigationHeaderSvg';
import { Link, useHistory } from 'react-router-dom';
import { routes } from 'routes/routes';
import { vehicles } from 'config/variables';

const Auction = () => {
    const { t } = useTranslation();

    return (
        <>
            <Box className='sub-menu-container'>
                <Box sx={{
                    borderBottom: '1px solid #efefef',
                    '.myCar-text': { margin: '10px 0', fontSize: '15px', color: '#666', fontWeight: 400 },
                    '.header': { fontSize: '21px', color: '#000', fontWeight: 500 }
                }}>
                    <span className='header'>{t(translations.summaryVehicleScreen.AUCTIONS_ADS)}</span>
                    <Box className='myCar-text'>{t(translations.NAVBAR.DESCRIPTION_AUCTION_TAB)}</Box>
                </Box>
                <Box className='auction-type'
                    sx={{
                        fontSize: '18px',
                        fontWeight: 500,
                        letterSpacing: '0.36px'
                    }}
                >
                    <Link to={`${routes.vehicleRoutes.auction}/${vehicles.car}`}>
                        <span className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.AUCTION_CAR)}</span>
                    </Link>
                    <Link to={`${routes.vehicleRoutes.auction}/${vehicles.motorcycles}`}>
                        <span className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.AUCTION_MOTORCYCLES)}</span>
                    </Link>
                    <Link to={`${routes.vehicleRoutes.auction}/${vehicles.bike}`}>
                        <span className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.AUCTION_BIKE)}</span>
                    </Link>
                    <Link to={`${routes.vehicleRoutes.auction}/${vehicles.step}`}>
                        <span className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.AUCTION_STEP)}</span>
                    </Link>
                </Box>
            </Box>
        </>
    )
}

export default Auction