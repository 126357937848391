import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Modal } from '@mui/material'
import { LicPlateWrapper } from '../style/licensePlateStyle'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { getVehicleListRequest } from 'store/actions/licensePlate-action'
import VehicleListComponent from './VehicleListComponent'
import { useWindowSize } from 'app/Hooks/windowResize'
import { LicensePlateslistI, SuggestedVehiclesListI } from '../Interface/interface'
import { getSubsidiaryStored } from 'config/variables'

const SuggestedVehiclesList = ({ openPopup, setOpen, setSelectedVehicle, selectedVehicle }: SuggestedVehiclesListI) => {
  const [page, setPage] = useState<number>(1)
  const [suggestedVehicles, setSuggestedVehicle] = useState<Array<LicensePlateslistI>>([])
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [searchAble, setSearchAble] = React.useState<number>(0);
  const [scrollFlag, _scrollFlag] = useState<boolean>(true);
  const scrollRef: any = useRef(null);
  const [mobile] = useWindowSize();
  const { vehicleList, vehicleListLastPage, deletePlateResponse } = useSelector((state: RootStateOrAny) => state.licensePlate)
  const dispatch = useDispatch();
  const subsidiary = getSubsidiaryStored()

  useEffect(() => {
    if (vehicleList) {
      setSuggestedVehicle(vehicleList)
    }
  }, [vehicleList])

  useEffect(() => {
    const allSuggestedVehicleList = [...suggestedVehicles]
    const remove = allSuggestedVehicleList.filter((Vehicle) => Vehicle.id === selectedVehicle?.id)
    setSuggestedVehicle(remove)
  }, [deletePlateResponse])

  // Clearing search if modal is closed 
  useEffect(() => {
    if (!openPopup) {
      setSearchKeyWord('')
      return
    }
  }, [openPopup])

  useEffect(() => {
    // Search License Plate 
    if (searchKeyWord !== undefined) {
      const delayDebounceFn = setTimeout(() => {
        // if search vehicle by keyword    
        dispatch(getVehicleListRequest({ keyword: searchKeyWord }));
        return
      }, 500)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchKeyWord, subsidiary])

  const handleSelectedVehicle = (data) => {
    setOpen(false)
    setSelectedVehicle(data)
    setSearchKeyWord('')
  }
  // handle search keywords
  const handleKeyPress = (event) => {
    setSearchKeyWord(event.target.value);
  }

  //Function request for next page data when scrolled to bottom
  const handleScroll = (e) => {
    // bottom return boolean value and Math.abs to get absolute value 
    const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
    if (bottom && scrollFlag && page < vehicleListLastPage) {
      setPage(page + 1)
      dispatch(getVehicleListRequest({ page: page + 1 }))
      _scrollFlag(false)
      return
    } else {
      _scrollFlag(true)
    }
  }

  const toggleDrawer = (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
    };
  return (
    <>
      {!mobile ?
        // display suggested vehicle list in modal on web view and 
        <Modal
          open={openPopup}
          onClose={() => {
            setOpen(false)
            setSearchKeyWord('')
          }}
        >
          <LicPlateWrapper>
            <VehicleListComponent
              searchAble={searchAble}
              setSearchAble={setSearchAble}
              handleSelectedVehicle={handleSelectedVehicle}
              suggestedVehicles={suggestedVehicles}
              setSearchKeyWord={setSearchKeyWord}
              closePopup={setOpen}
              handleScroll={handleScroll}
              scrollRef={scrollRef}
              handleKeyPress={handleKeyPress}
              searchKeyWord={searchKeyWord} />
          </LicPlateWrapper>
        </Modal> :
        //  as drawer in mobile view
        <Drawer
          anchor='bottom'
          open={openPopup}
          onClose={() => {
            toggleDrawer(false)
            setOpen(false)
          }}
          sx={{
            '.MuiPaper-root': {
              borderRadius: '20px 20px 0 0'
            },
            '.vehicle-list-mobile': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }
          }}
        >
          <VehicleListComponent
            searchAble={searchAble}
            setSearchAble={setSearchAble}
            handleSelectedVehicle={handleSelectedVehicle}
            suggestedVehicles={suggestedVehicles}
            setSearchKeyWord={setSearchKeyWord}
            closePopup={setOpen}
            handleScroll={handleScroll}
            scrollRef={scrollRef}
            handleKeyPress={handleKeyPress}
            searchKeyWord={searchKeyWord} />
        </Drawer>}
    </>
  )
}

export default SuggestedVehiclesList