import styled from 'styled-components/macro';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from 'routes/routes';
import { loginReset } from 'store/actions/auth-actions';
import { getSubsidiaryStored } from 'config/variables';
import { translations } from 'locales/translations';
import { Trans, useTranslation } from 'react-i18next';
import PermissionComponent from 'app/Component2/GlobalComponent/PermissionComponent';
import { Box } from '@mui/material';
import NotFound from "app/components/svgicons/NewIcons/404NotFound.webp"
import PublicLayout from 'app/Component2/Layouts/PublicLayout';

const loginRoutes = [
  routes.authRoutes.register,
  routes.authRoutes.login,
  routes.authRoutes.forgotPassword,
  routes.authRoutes.passwordReset
]

const routeArray = [
  routes.companyRoutes.changePlan,
  routes.showCaseRoutes.showcaseConfigure,
  routes.companyRoutes.companyProfile,
  routes.companyRoutes.roleList,
  routes.companyRoutes.planDetails,
  routes.companyRoutes.companySettings,
  routes.companyRoutes.createRole,
  routes.companyRoutes.editRole,
  routes.companyRoutes.roles,
  routes.companyRoutes.invoices,
  routes.companyRoutes.companyPrivacy,
  routes.companyRoutes.paymentMethod,
  routes.professionalSettingsRoutes.employeesList,
  routes.professionalSettingsRoutes.employeesAdd,
]

const privateRouteArray = [
  routes.userRoutes.myProfile,
  routes.companyRoutes.createCompany,
  routes.userRoutes.userPrivacy,
  routes.userRoutes.profileSetting
]

const onlyPrivateRoutesArray = [
  routes.userRoutes.myProfile,
  routes.userRoutes.userProfileEdit,
  routes.companyRoutes.createCompany,
  routes.userRoutes.userPrivacy,
  routes.userRoutes.profileSetting,
  routes.userRoutes.idVerfication,
  routes.companyRoutes.company
]

export function NotFoundPage(props: any) {

  const history = useHistory()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const sub = getSubsidiaryStored();
  const location = useLocation()

  const { loading } = useSelector((state: any) => state.company)
  const user = useSelector((state: any) => state.auth)

  useEffect(() => {
    if (!user.loggedIn && (routeArray.includes(location.pathname) || privateRouteArray.includes(location.pathname))) {
      history.push(routes.authRoutes.login)
    }
  }, [])


  useEffect(() => {
    if (user.loggedInRedirect) {
      history.push(routes.vehicleRoutes.adsHome)
      dispatch(loginReset())
    }
  }, [user.loggedInRedirect])


  //for removing canonical and alternate from DOM
  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll('link[rel="canonical"]').forEach(link => link.remove());
      document.querySelectorAll('link[rel="alternate"]').forEach(link => link.remove());
    }, 1000);
  }, [])

  return (
    <>
      <PublicLayout
        title={props.authrized ? t(translations.NOT_FOUND_SCREEN.UNAUTHRIZED_ACCESS) :
          t(translations.NOT_FOUND_SCREEN.NOT_FOUND_SCREEN_PAGE_NOT_FOUND)
        }
        meta={props.authrized ? t(translations.NOT_FOUND_SCREEN.UNAUTHRIZED_ACCESS) :
          t(translations.NOT_FOUND_SCREEN.NOT_FOUND_SCREEN_PAGE_NOT_FOUND)
        }
        description={props.authrized ? t(translations.NOT_FOUND_SCREEN.UNAUTHRIZED_ACCESS) :
          t(translations.NOT_FOUND_SCREEN.NOT_FOUND_SCREEN_PAGE_NOT_FOUND)
        }
        image={process.env.REACT_APP_BASE_URL + '/content.jpg'}
        type="website"
        showNavbar={true}
        hideHeader={false}
      >

        <Wrapper>

          {/* If user is logged in we will redirect it to homepage */}
          {user.loggedIn && loginRoutes.includes(location.pathname) && <Redirect to="/" />}


          {/* If user is logged in as subsidiary and accessing user routes or vice versa then we will display permission denied page */}
          {
            (
              (!sub && user.loggedIn && routeArray.includes(location.pathname) && !loading && !user.loading) ||
              (sub && sub !== null && user.loggedIn && onlyPrivateRoutesArray.includes(location.pathname) && !loading && !user.loading)
            ) &&
            <PermissionComponent />
          }

          {
            (
              !loginRoutes.includes(location.pathname) &&
              !routeArray.includes(location.pathname) &&
              !privateRouteArray.includes(location.pathname) &&
              !onlyPrivateRoutesArray.includes(location.pathname)
            ) &&
            <>
              <Box className='mainContainer'>
                <Box className='contentBox'>
                  <h1 className='title'>
                    <Trans
                      i18nKey={t(translations.COMMON_TEXTS.THIS_PAGE_IS_NOT_AVAIALABLE)}
                      components={{
                        bold: <span className='blueText' />,
                        br: <br />
                      }}
                    />
                  </h1>
                  <p className='desc'>
                    <Trans
                      i18nKey={t(translations.COMMON_TEXTS['WE_COULDN\'T_FIND_PAGE_YOU_WERE_LOOKING_FOR'])}
                      components={{
                        bold: <span className='blueText' />
                      }}
                    />
                  </p>
                </Box>
                <img src={NotFound} className='icon' />
              </Box>
              <p className='backtoHome'>
                <Trans
                  i18nKey={t(translations.COMMON_TEXTS.GET_BACK_TO_HOME)}
                  components={{
                    link1: <Link to={routes.vehicleRoutes.adsHome} />,
                    link2: <Link to={routes.publicRoutes.contactUs} />
                  }}
                />
              </p>
            </>
          }


        </Wrapper>
      </PublicLayout>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(80vh - 91px);
  padding: 16px;
  p, h1{
    margin: 0;
  }
  .mainContainer{
    display: flex;
    flex-direction: row;
    gap:50px;
    .contentBox{
      display: flex;
      flex-direction: column;
      gap:10px;
    }
  }
  .title{
    font-family: Inter;
    font-size: 60px;
    font-weight: 600;
    line-height: 75px;
    color: #5B5B5B;
    .blueText{
      font-weight: 700;
      color : #617AF7;
    }
  }
  .desc{
    font-size: 21px;
    font-weight: 400;
    line-height: 31.47px;
    max-width: 470px;
    color: #5B5B5B;
    .blueText{
      font-weight: 700;
      color : #617AF7;
    }
  }
  .icon{
    width: 500px;
    height: auto;
    object-fit: contain;
  } 
  .backtoHome{
    margin-top: 96px;
    font-family: Inter;
    font-size: 21px;
    font-weight: 400;
    line-height: 31.47px;
    color: #5B5B5B;
    text-align: center;
    a{
      font-weight: 700;
      font-size: 21px;
      line-height: 31.47px;
      color : #617AF7;
      text-decoration: none;
    }
  }
  @media (max-width :992px){
    .mainContainer{
      flex-direction: column;
      align-items: center;
    }
    .title{
      font-size: 40px;
      line-height: 50px;
      text-align: center;
    }
    .desc , .navBack{
      font-size: 18px;
      text-align: center;
      a, span{
        font-size: 18px;
      }
    }
    .icon{
      width: 90%;
      max-width: 500px;
      height: auto;
    }
  }
`;
