import { Box } from '@mui/material'
import { SvgArrowBack } from 'app/components/svgicons/svgNew'
import { translations } from 'locales/translations'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Header = ({ handleGoBack }) => {

    const { t } = useTranslation()
    return (
        <Box className='flexRow justifyContentBetween alignItemsCenter header'>
            <SvgArrowBack onClick={handleGoBack} />
            <h2>{t(translations.HOT_DEALS.HOT_DEALS)}</h2>
            <SvgArrowBack style={{ opacity: '0' }} />
        </Box>
    )
}

export default Header