import styled from 'styled-components';
import { colorNew } from 'styles/StyleConstants';

export const ChatWrapper = styled.div`
  .chatListContentBox{
    width: 30%;
  }
  .chatMessageContentBox{
    width: 70%;
  }
  p, span {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02 em;
  }
  button {
    text-transform :none;
  }
  .flexRow{
    display: flex;
    flex-direction: row;
  }
  .flexColumn{
    display: flex;
    flex-direction: column;
  }
  .alignItemsCenter{
    align-items:center;
  }
  .justifyContentCenter{
    justify-content:center;
  }
  .justifyContentBetween{
    justify-content:space-between;
  }
  .selfMessageContainer {
    justify-content: flex-end;
  }
  .borderBottom{
    border-bottom: 1px solid rgb(205, 199, 199);
  }

  // CHAT INDEX PAGE STYLING
  .screenHeader{
    padding: 10px 0;
    margin: 0 20px;
  }
  .screenHeader span{
    font-size: 21px; 
    line-height: 25px;
    font-weight:500
  }
  .chatScreenContentBox{
    display: flex;
    flex-direction: row;
    border: 0.5px solid #cdc7c7;
    margin: 0 5px;
  }

  // ChatList styling
  .userProfileView{
    height: 40px;
    width: 40px;
  }
  .userProfileView img{
    height: 40px;
    width: 40px;
    border-radius:50%;
    object-fit:cover;
  }

  .chatListHeader{
    padding: 10px 15px;
  }
  .chatListHeader span,.chatListHeaderPopup span{
    font-size: 21px; 
    line-height: 25px;
    letter-spacing: 0.02em;
    font-weight:500;
  }
  .userAvatarAndDetails{
    cursor:pointer;
    padding:10px
  }
  .userAvatarAndDetails:hover{
    transition: all 0.1s ease-in-out;
    background: #F2F2F2;
  }
  .newMessage-icon{
    background: #F2F2F2;
  }
  .icon-dot {
    width: 10px;
    height: 10px;
    background: rgb(236, 28, 36);
    position: absolute;
    border-radius: 6px;
    /* right    : 37%; */
  }
  .icon-dot-mobile{
    width: 10px;
    height: 10px;
    position: absolute;
    background: rgb(236, 28, 36);
    right:10px;
    border-radius: 6px;
  }

  .newMessage-icon{
    width: 8px;
    height: 8px;
    background: rgb(236, 28, 36);
    display: block;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    right: -2px;
  }

  .icon-dot-header {
    width: 10px;
    height: 10px;
    background: rgb(236, 28, 36);
    position: absolute;
    border-radius: 6px;
    right    : 8px;
    top:12px
  }

  //ChatMessageScreen Styling
  .chatMessageMainContainer{
    display: flex;
    flex-direction: column;
    border-left: 0.5px solid #cdc7c7;
    width: 100%;
  }
  .noChatMessageContainer{
    min-height: calc(100vh - 206px);
    display: flex;
    justify-content:center;
    align-items:center;
    border-left: 0.5px solid #cdc7c7;
    width: 100%;
  }
  .chatMessageHeader{
    border-bottom: 0.5px solid #cdc7c7;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .chatOptions{
    padding: 5px;
    display: flex;
    align-items: center;
    min-width: 20px;
  }
  .optionListContainer{
    position:relative;
  }
  .optionList{
    position: absolute;
    top: -9px;
    right: 55px;
    background: white;
    padding:10px;
    gap: 15px;
  }
  .optionIcon{
    margin: 0 25px 0 25px;
  }
  .textAndInputContainer{
    padding: 10px;
  }
  .textBoxContainer{
    position : relative;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    margin: 10px 0;
    padding: 5px;
    height: calc(100vh - 383px);
    max-height: calc(100vh - 383px);
    position: relative;
  }
  .attachmentPreview{
    z-index:2;
    position: absolute;
    left: 0px;
    bottom: 60px;
    /* min-height:200px; */
    min-width: 100%;
    max-width: 100%;
    border-radius:8px;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 23px -4px;
    background:white;
    padding:10px;
    display: flex;
    overflow: scroll;
  }
  .previewContainer{
    margin-right: 20px;
    max-width:200px;
    min-width:115px;
    max-height:200px;
    height:auto;
    width:auto;
    position:relative;
    padding:5px;
    border:1px solid #e2e2e2;
    background:#F1F1F1;
    border-radius:5px;
    display:flex;
    align-items: center;
    justify-content: center;
    color:grey;
  }
  .docDetails svg{
    display:flex;
    padding:10px
    align-items: center;
  }
  .docDetails{
    display:flex;
    align-items: center;
  }
  .closeIcon{
    position:absolute;
    right:4px;
    top:4px;
    border :0.5px solid grey;
    border-radius:50%;
    padding:2px;
  }
  .chatIcon svg{
    /* width:32px;
    height:32px; */
  }
  .chatIcon  svg circle{
     /* fill:lightgray !important;  */
  }
  .chatIcon.unread svg circle {
      /* fill:red !important;   */
  }
  .imagePreview{ 
    max-width:100%;
    max-height:100%;
    border-radius:5px;
    height:auto;
  }
  .textBoxContainerPopup{
    height: calc(100vh - 400px);
    max-height: calc(100vh - 400px);
  }
  .inputContainer{
    background:white;
    position:relative;
    padding: 10px 0;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 23px -4px;
    border-radius: 10px;
    display: flex;
    height:65px;
    align-items:center;
  }
  .inputTextContainer{
    max-height: 50px;
    overflow-y: scroll;
  }
  .MuiInputBase-fullWidth{
    width:98% !important;
  }
  .countryField .MuiInputBase-fullWidth{
    width:100% !important;

  }
  .messageContainer {
    margin-top: 5px;
  }
  .message {
    margin-left: 10px;
    max-width: 70%;
  }
  .messageInfo {
    font-size: 13px;
    color: #777777;
    max-width: 320px; 
  }
  .selfMessage {
    justify-content: flex-end;
    align-items: end;
  }
  .messageText{
    width: fit-content;
    background: #F1F1F1;
    padding: 5px;
    border-radius: 0px 10px 10px;
    font-size: 18px;
    color: ${colorNew.colorGrayOnButton};
    line-height: 22px;
    letter-spacing: 0.02em;
  }
  .messageFile{
    width: 100%;
    background: #F1F1F1;
    padding: 5px;
    border-radius: 0px 10px 10px;
    font-size: 18px;
    color: ${colorNew.colorGrayOnButton};
    line-height: 22px;
    letter-spacing: 0.02em;
  }
  .selfMessageText {
    background: #6579F4;
    padding: 5px;
    border-radius: 10px 10px 0px;
    color: #ffffff;
    max-width:100%;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
  }
  .fileContainer{
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  .fileMessage{
    display:flex;
    flex-direction:column;
    width:320px;
    max-width:100%;
    min-height:80px;
  }
  .attachment img{
    width: 100%;
    max-width: 320px;
    object-fit: cover;
    border-radius:5px;
  }
  .selfButton{
    color:white;
    border-top: 1px solid #fff;
  }
  .otherButton{
    color:#6579F4;
    border-top: 1px solid  ${colorNew.colorGrayOnButton};
  }
  .fileIconSelf path{
    stroke:white !important;
  }
  .fileIcon path{
    stroke:#6579F4;
  }
  .fileMessage Button{
    border-radius:0;
  }

  .fileMessage span{
    padding:10px;
    font-size:18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:310px;
  }
  .imageMessage img{
    border-radius:6px;
    width:auto;
    max-width:320px;
    object-fit:cover;
  }
  .activeFlag {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    position: absolute;
    bottom: -3px;
    right: 1px;
    border: 1px solid white
  }
  .activeDot {
    width: 13px;
    height: 13px;
    background: #28A745;
    border-radius: 50%;
    position: absolute;
    bottom: -3px;
    left: 1px;
    border: 1px solid white
  }
  .blueDot{
    background: #617AF7;
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    width: 12px;
  }
  .inActiveDot {
    width: 13px;
    height: 13px;
    background: #FBC623;
    border-radius: 50%;
    position: absolute;
    bottom: -3px;
    left: 1px;
    border: 1px solid white
  }
  .messageAvataricon{
    height: 32px;
    width: 32px;
  }
  .messageAvataricon img{
    height: 32px;
    width: 32px;
    border-radius:50%;
    object-fit:cover;
  }

  // Mobile Drawer Chat options Styling
  .chatOptionsMobileNameContainer{
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid rgb(205, 199, 199);
  }
  .chatOptionsMobileNameContainer span{
    font-size: 17px;
    font-weight: 500;
    margin-left: 10px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
  .chatOptionsMobileNameContainer svg{
    height: 40px; 
    width: 40px;
  }
  .chatOptionsMobileOptionsContainer{
    gap: 15px;
    padding: 20px;
  }
  .optionContainer{
    cursor:pointer; 
  }
  .optionContainer .iconContainer{
    width: 30px;
    display: flex; 
    align-items: center; 
    justify-content: center;
  }
  .optionContainer span{
    margin-left: 5px
  }
  .filterTextContainer{
    display: flex; 
    align-items: center; 
    justify-content: space-between;
    width:90%;
  }

  // Choose Attachment Styling
  .attachmentHeader{
    text-align: center;
    padding: 24px;
    font-size: 18px;
  }
  .attachmentOptions{
    /* border: 0.5px solid rgb(205, 199, 199); */
    border-radius: 5px;
    padding: 8px;
    width: 45px;
  }
  .attachmentOptions svg{
    width: 25px; 
    height: 25px;
  }
  .attachmentIconName{
    padding: 16px 0px;
    font-size: 14px;
    color: rgb(114, 114, 114);
  }
  .width-50{
    width:50%;
  }
  .width-33{
    width:33%;
  }


  //Intiate Chat Popup Styling
  .intiateChat{
    width: 100%;
    background: white;
    padding:20px;
    pt: 2;
    px: 4;
    pb: 3;
  }
  .intiateChatPopup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    border-radius: 8px;
    box-shadow: 24;
  }
  .intiateChatPopupInMobile{
    height: 100vh;
    border: none;
  }
  .intiateChatUserList{
    max-height: 48vh;
    overflow-y: scroll;
    margin-top: 16px;
  }
  .intiateChatUserListMobile{
    max-height: calc( 100vh - 270px);
    overflow-y: scroll;
    margin-top: 16px;
  }
  .intiateChatUserListPopup{
    max-height: 39vh;
  }



  /* user list popUp style */
  .chat-wrapper {
    width: 100%;
    background: ${colorNew.colorWhite};
    bottom: 0px;
    top: 0px;
    border-radius: 8px 8px 8px 8px;
    outline: 0px;
  }
  .licensePlateSearchButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    border: 1px solid rgb(229, 229, 229);
    margin: 16px 0 0 10px;
    height: auto;
    max-height: 56px;
    width: 70px;
    padding: 15px;
    border-radius: 4px;
    background-color: rgb(101, 121, 244);
    path{
      stroke: white;
    }
  }
  .creditChargeNote{
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    color: rgb(153, 153, 153);
    font-size: 15px;
    font-weight: 400;
  }
  .user-list {
    height: calc(100vh - 325px);
    max-height: calc(100vh - 325px);
    display: flex;
    flex-direction: column;
    overflow-Y: auto;
    padding: 0 0 5px 0 ;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 5px;
    }
  }
  .NoChatsContainer{
    height: calc(100vh - 173px);
    max-height: calc(100vh - 173px);
    display: flex;
    flex-direction: column;
    overflow-Y: scroll;
    padding: 0 0 5px 0 ;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 5px;
    }
  }
  .user-list-popup{
    height: calc(100vh - 360px) !important;
    max-height: calc(100vh - 360px) !important;
  }
  .more-option {
    display: flex;
    align-items: center;
    column-gap: 20px;
    span.add-chat {
      svg {
        background: ${colorNew.primaryColor};
        height: 30px;
        width: 30px;
        border-radius: 50%;
        padding: 7px;
        path {
          fill: ${colorNew.colorWhite};
          stroke: ${colorNew.colorWhite};
        }
      }
    }
  }
  .Search-box {
    display: flex;
    align-items: center;
    column-gap: 10px;
    max-width: 85%;
    width: 85%;
    svg {
      width: 30px;
      height: 30px;
      path {
        fill: ${colorNew.colorBlack};
        stroke: ${colorNew.colorBlack};
      }
    }
    input {
      border: none;
      font-size: 18px;
      width: 80%;
      border-left: solid 1px #333;
      padding-left: 10px;
      :focus-visible {
        outline: none;
      }
    }
  }  
  .userList-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid rgb(205, 199, 199);
    padding: 20.5px 12px;
  }
  .popup-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid rgb(205, 199, 199);
    padding: 15px;
  }
  .userList-header-popup{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid rgb(205, 199, 199);
    border-radius:8px;
    padding: 10.5px;
    margin:15px;
  }
  
  /* Avtart Details */
  .avtar-detail-wrapper {
    padding: 5px;
    display: flex;
    align-items: center;
    min-height: 61px;
  }
  .user-details {
    min-width: 95px;
    position : relative;
    display: flex;
    flex-direction: column;
    margin-left: 10px;   
    width: calc( 100% - 40px);     
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 148.02%;
      letter-spacing:0.02em;
    }
  }
  .mutedIcon{
    position :absolute;
    bottom:0;
    right:0;
  }
  .chat-Time {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    svg {
      margin-left:8px;
    }    
  }
  .user-name{
    width:70%;
  }
  .name{
    display:block;
    white-space:nowrap;
    text-overflow: ellipsis;
    width:100%;
    overflow: hidden;
  }
  .lastMessage{
    min-width: 130px;
    width:90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
  }
  .days {
    font-weight: 300 !important;
    font-size: 15px !important;
    color:${colorNew.colorGrayOnButton};
    text-wrap:nowrap;
  }
  .dealerText{
    z-index: 1;
    font-size: 10px;
    background: rgb(233, 233, 233);
    padding: 2px 5px;
    border-radius: 15px;
    right: 10px;
    top: 10px;
    opacity: 0.8;
  }

  .confirm-popup {
      background: #FBFBFF;
      position: absolute;
      box-shadow: 0px 1px 20px 2px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      top: 50%;
      left: 50%;
      padding: 20px 14px 0px;
  }

  /* initiate chat style */
  .invite-member {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    .input-group  {
        position: relative;
        margin: 20px 0 26px;
        span  {
            position: absolute;
            left: 15px;
            top: 12px;
            border-right: solid 1px ${colorNew.colorGrayOnButton};
            padding-right: 10px;
        }
        input {
            width: 100%;
            height: 52px;
            border: none;
            background: #F8F8F8;
            padding-left: 55px;
            font-size: 18px;
        }
    }
    h2 {
        font-size: 21px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 0.02em;
        margin: 0px;
        text-align:left;
    }
    .suggestedMember {
        h6 {
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
            margin: 0px;
            padding-bottom: 16px;

        }
    }
    .addMemberButton{
      position : absolute;
      right : 10px;

    }
    .select_uer_img {
        height: 40px;
        width: 40px;
        position: relative;
        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
        }
        img .select_flag {
            height: 15px;
            width: 15px;
            position: absolute;
            bottom: 0px;
            right: 0px;
        }}
    .select_user  {
        box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.04);
        border-radius: 6px;
        background: #ffffff;
        padding: 10px;
        margin-bottom: 16px;
        display: flex;
        column-gap: 15px;
        align-items: center;
        position : relative;
        /* [theme.breakpoints.down('sm')]: {
            marginBottom: '15px;
        }, */
        .select_uer_details {
            h5 {
                margin: 0;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                color: #000;
                span {
                    padding-left: '10px'
                }
            }
             p {
                font-size: 15px;
                line-height: 18px;
                color: ${colorNew.colorGrayOnButton};
                font-weight: 400;
                padding-top: 16px;
                margin: 0px;
            }
        }
    }
  }

  /* Capture Shot style */
  .capture-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 80%; */
    min-width: 360px;
    height: auto;
    max-width: auto;
    background-color: ${colorNew.colorWhite};
    border-radius: 8px;
    box-shadow: 24px;
    padding: 24px 32px 24px 32px;    
  }
  .camContainer{
    width: 100%;
  }
  .switchCamera{
    height:20px;
    width:20px;
    position: absolute;
    top: 25px;
    right: 40px;
    z-index: 2;
  }

  /* more option page style */
  .chatOptionsMobileOptionsContainer {
      padding-top:10px !important;
      padding-bottom:10px !important;
      border-bottom:0.5px solid rgb(205,199,199);
  }
  .option-wrapper {
    display:flex;
    flex-direction:column;
  }

  // No message Component
  .noMessageContainer{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    min-width:370px;
  }
  .noMessageContainer img{
    width:130px;
    height:auto;
  }
  .noMessageText{
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 150.02%;
    color:${colorNew.colorGrayDark4};
    margin-top:10px;
  }

  .blocked-border{
    border-left : 5px solid #EC1C24;
    padding: 4px 10px !important;
    width : 80%;
    margin : auto;
  }
  .archived-border{
    padding: 4px 10px !important;
    border-left : 5px solid #FBC623;
    width : 80%;
    margin : auto;
  }
  .accessMessage p{
    font-size: 13px;
    line-height: 138.52%;
    color: ${colorNew.colorGrayOnButton};
  }
  .accessMessage p span{
    text-decoration-line: underline;
    color: #384386;
  }

  .addedUserName{
    max-width: 100px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }




  // Back Button 

  .backButton{
    justify-content: space-between;
  }
  .backButton span{
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    color: #000000;
  }
  /* on swipe chat list style */
  .btn-on-swipe {
       width:100px;
       display:flex;
       justify-content:center;
       align-items:center;
       font-size:18px;
       font-weight:400;
       line-height:22px;  
  }


  /* chat time */
 .msg-time { 
        background:#fff;
        width:145px;
        display:flex;
        justify-content:center;
        font-weight:400;
        font-size:18px;
        color:#666666
  }
  .msg-time-border{
    z-index:-1;
    position:absolute;
    border:1px dashed ${colorNew.colorGrayButton};
    width:100%;
    top:12px;
  }

 .msg-time-container {
    display:flex;
    justify-content:center;
    position:relative;
  }
  .read{
    font-weight: 400;
    color: black
  }
  .unread{
    font-weight: 600 !important;
    color: black
  }
  .followers{
    font-size: 14px;
    color: grey;
  }
  .chatList-position {
      max-width: 400px;
      min-width: 400px;
      border-radius: 8px;
      margin-right:10px !important;
      margin-left:auto;
      width: 100%;
      background-color: white;
      overflow: hidden;
      position:fixed;
   }

  /* Create chat popover style */
  .create_chat_popover {
    font-size:18px;
    color:${colorNew.colorBlack};
    line-height:22px;
    padding:20px;
    svg {
      path{
        fill:${colorNew.colorBlack}
      }
    }    
  }
  .create-chat{
        margin-left:16px;
  }  

  // Media Queries

  @media (max-width: 991px) {

    .chatMessageMainContainer{
      border:none;
      height: 100vh;
    }
    .textBoxContainer {
      height: 100vh;
      // border: 1px solid red;
      margin-top: 0;
      overflow-y: auto;
      max-height: calc(100vh - 250px);
      margin: 0 !important;
      position:relative;
    }
    .iosMessageContainer{
      height: calc(100vh - 270px);
      max-height: calc(100vh - 270px);
    }
    .user-list {
      height: calc(100vh - 250px);
      max-height: calc(100vh - 250px);
      padding-bottom:20px;
    }
    .noMessageContainer{
      min-width:auto
    }
    .chatMessageHeader{
      position: fixed;
      width: 100%;
      background: #ffffff;
      height: 72px;
      display: flex;
    }
    .inputContainer{
      position: fixed;
      bottom: 15px;
      right: 15px;
      height:52px;
      width: calc(100% - 30px);
    }
    .scrollerDiv{
      position: fixed;
      bottom: 0px;
    }
    .scrollmsgDiv{
      position :absolute;
      bottom :0;
    }
    .textAndInputContainer {
      padding: 0px;
      margin-top: 76px;
     // border: 1px solid green;
      height: calc(100vh - 200px);
    }
    .intiateChatUserListPopup{
      padding : 5px 0; 
    }
  }
   @media (max-width: 700px) {
    .fileMessage{
     width:220px;
   }
   .fileMessage span{
     max-width:210px;
   }
   .imageMessage img{
     max-width:220px;
   }
  }
   @media only screen and (max-width: 1240px) and (min-width: 992px)  {
    .chatListContentBox{
      width: 40%;
    }
    .chatMessageContentBox{
      width: 60%;
    }
  }
  @media (max-width :575px){
    .user-list {
      max-height: 100vh;
      height: calc(100vh - 280px);
    }
  }
`;
