import React, { useEffect, useRef, useState } from 'react'
import { Box, Modal } from '@mui/material'
import { SvgChatIconHeader, SvgEmail } from 'app/components/svgicons/svgNew'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'
import { ChatWrapper } from '../../style/chatStyle'
import { ChatIconI, Nullable } from '../../interface/interface'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next';
import { SvgChatDetailIcon, SvgChatIconSelected, SvgChatIcon } from 'app/components/svgicons/svg'
import * as ChatConstants from 'config/chatConfig';
import { Toaster } from 'services/Toaster'
import { archiveParticularChatFailure, archiveParticularChatSuccess, blockParticularChatFailure, blockParticularChatSuccess, chatStoreFailure, deleteParticularChatFailure, deleteParticularChatSuccess, getArchivedChatSuccess, getBlockedChatSuccess, getChatSuccess, resetUserList, muteParticularChatFailure, muteParticularChatSuccess, particularChatSuccess, unreadParticularChatFailure, unreadParticularChatSuccess } from 'store/actions/chat-action'
import ChatMessageScreen from '../ChatMessageScreen'
import ChatList from '../ChatList'
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom'
import ToastMessageWithButton from 'app/components/ToastMessageWithButton'
import { Strings } from 'config/variables'
import { useDeviceType } from 'app/Hooks/useMediaQuery'


const ChatIcon = ({ itemId, ads, handleChat, isDisableBtn }: ChatIconI) => {

    const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLButtonElement>>(null);
    const [open, setOpen] = React.useState<boolean>(false);
    const [chatListData, setChatListData] = useState<Array<Object>>([])
    const [showInitiateChatModal, setShowInitiateChatModal] = useState<boolean>(false)
    const [chatListDataWithPagination, setChatListDataWithPagination] = useState<Array<Object>>([])

    // Hooks and states
    const history = useHistory();
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()
    const spanRef = useRef();
    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth)
    const { userData, loading } = useSelector((state: RootStateOrAny) => state.user)
    const companyReducerData = useSelector((state: RootStateOrAny) => state.company)


    // Chat State Management
    const [chatType, setChatType] = useState<number>(ChatConstants.chatType.ALL_MESSAGES)
    const [chatId, setChatId] = useState<Nullable<number>>()
    const [unreadMessage, setUnreadMessage] = useState<boolean>()
    const [showTooltip, _showTooltip] = useState<boolean>(false)
    const [isSmallDevice] = useDeviceType()

    const { chatStoreData, chatStoreError, chatList, blockedChatList, chatListAllData, blockedChatListAllData, archivedChatList, archivedChatListAllData, muteParticularChat, archiveParticularChat, deleteParticularChat, blockParticularChat, unreadParticularChat, unreadParticularChatFailureData, muteParticularChatFailureData, blockParticularChatFailureData, archiveParticularChatFailureData, deleteParticularChatFailureData, userListError } = useSelector((state: RootStateOrAny) => state.chat)

    // Chat Icon
    let ChatIcon = (ads === 'adsDetail')
        ?
        <SvgChatDetailIcon onClick={() => {
            if (userData.profile_is_complete) {
                handleChat()
                localStorage.removeItem('chatScrollPosition')
            }
        }} />
        :
        ads === 'contact' || ads === 'contact-footer'
            ?
            <ButtonCustom style={{ width: '165px', height: '49px' }} clickFunction={() => {
                if (userData.profile_is_complete) {
                    handleChat()
                    localStorage.removeItem('chatScrollPosition')
                }
            }} textBtn={t(translations.CHAT.MSSG)} icon={<SvgChatDetailIcon style={{ height: '67px', width: '40px', display: "block" }} />} btnColor={"primary"} />
            :
            ads === 'adsList' ?
                <ButtonCustom disable={isDisableBtn} style={{ width: '100%', height: '35px', fontSize: '12px' }} clickFunction={() => {
                    if (userData.profile_is_complete) {
                        handleChat()
                        localStorage.removeItem('chatScrollPosition')
                    }
                }} textBtn={t(translations.CHAT.MSSG)} icon={<SvgEmail style={{ height: '18px', width: '18px' }} />} btnColor={isDisableBtn ? 'disable-btn ' : "green"} /> :
                !location.pathname.includes(routes.userRoutes.chat) ?
                    isSmallDevice ? <SvgChatIcon /> : <SvgChatIconHeader />
                    :
                    isSmallDevice && <SvgChatIconSelected />

    useEffect(() => {
        if (chatList) {
            setUnreadMessage(chatList.some((o) => o.is_read === ChatConstants.readStatus.unRead))
        }
    }, [chatList])

    useEffect(() => {
        return setChatId(null)
    }, [])

    useEffect(() => {
        setChatId(null)
        handleClose()
    }, [isSmallDevice])

    useEffect(() => {
        // Function that is called on page refresh for removing scrollposition from local storage
        const unloadCallback = () => {
            localStorage.removeItem("chatScrollPosition")
        };

        // removing scrollposition from local storage in case page is refreshed
        window.addEventListener("beforeunload", unloadCallback);

        return () => {
            window.removeEventListener("beforeunload", unloadCallback);
        }

    }, [])

    // Updates internal states while deleting particular chat
    const removeData = (chatType, id) => {
        // fetching Current state
        let chatListObject = { ...chatListDataWithPagination }
        let chatListArray = [...chatListData]
        let updatedArray = chatListArray.filter(o => o.id !== id)
        chatListObject.data.data = updatedArray
        updateReduxData(chatType, chatListObject)
        setChatId(null)
    }

    // Updates internal states while blocking/unblocking particular chat
    const blockUnblockData = (blocked) => {
        // fetching Current state
        let chatListObject = { ...chatListDataWithPagination }
        let chatListArray = []
        if (blocked) {
            chatListArray = [...chatList]
        } else {
            chatListArray = [...blockedChatList]
        }
        // Searching the index where chat exists.
        let index = chatListArray.findIndex((o) => o.id === chatId)

        if (index !== -1) {

            // Runs if chat is unblocked 
            // Fetches all messages chatlist and adds unblocked chat to it
            let updateChatList = chatListAllData
            let newArray = [chatListArray[index], ...chatListAllData.data.data]
            updateChatList.data.data = newArray.sort(function (a, b) { return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime() })
            // Removes unblocked chat from archived chatlist 
            chatListArray.splice(index, 1)
            chatListObject.data.data = chatListArray
            updateReduxData(ChatConstants.chatType.BLOCKED, chatListObject)
            updateReduxData(ChatConstants.chatType.ALL_MESSAGES, updateChatList)
            setChatId(null)
        }
        setChatId(null)
    }


    // Updates internal states while muting/unmuting particular chat
    const readOrMuteParticularData = (chatType, key, value) => {
        // fetching Current state
        let chatListObject = { ...chatListDataWithPagination }
        let chatListArray = [...chatListData]
        let index = chatListArray.findIndex((o) => o.id === chatId)
        if (index !== -1) {
            chatListArray[index][key] = value
        }
        chatListObject.data.data = chatListArray
        updateReduxData(chatType, chatListObject)
    }

    // Updates internal states while archiving/unarchiving particular chat
    const archiveUnarchiveData = (archived) => {
        // fetching Current state
        let chatListObject = { ...chatListDataWithPagination }
        let chatListArray = [...chatList]
        // Searching index where chat exists. if found updates the value of is_archived accordingly to the user request
        let index = chatListArray.findIndex((o) => o.id === chatId)
        if (index !== -1) {
            chatListArray[index].is_archived = archived

            // Runs if chat is unarchived 
            // Fetches all messages chatlist and adds unarchived chat to it
            let record = chatListArray[index]
            let updateChatList = chatListAllData
            let updateArchivedListData = archivedChatListAllData
            if (archived) {
                // Adding archived data to archived chat list
                let oldArchivedRecord = [record, ...archivedChatList]
                updateArchivedListData.data.data = oldArchivedRecord.sort(function (a, b) { return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime() })
                updateReduxData(ChatConstants.chatType.ARCHIVED, updateArchivedListData)
                // Removing archived data fromall messages list
                chatListArray.splice(index, 1)
                updateChatList.data.data = chatListArray
                updateReduxData(ChatConstants.chatType.ALL_MESSAGES, updateChatList)
                setChatId(null)
                return;
            }

            // updating allmessages list on base of time
            let oldArchivedRecord = [...archivedChatList]
            let archivedChatIndex = oldArchivedRecord.findIndex(o => o.id == chatId)
            updateChatList.data.data = chatListArray.sort(function (a, b) { return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime() })
            // Removing archived chat from archived list as when archived chat opens through ads it get appended in all messages list 
            if (archivedChatIndex !== -1) {
                oldArchivedRecord.splice(archivedChatIndex, 1)
                updateArchivedListData.data.data = oldArchivedRecord
            }
            updateReduxData(ChatConstants.chatType.ARCHIVED, updateArchivedListData)
            updateReduxData(ChatConstants.chatType.ALL_MESSAGES, updateChatList)
            setChatId(null)
        }
        setChatId(null)
    }

    // Updates redux states with latest changes in data  
    const updateReduxData = (chatType, data) => {
        if (chatType === ChatConstants.chatType.ALL_MESSAGES) {
            dispatch(getChatSuccess({ data: data, append: false }))
        } else if (chatType === ChatConstants.chatType.ARCHIVED) {
            dispatch(getArchivedChatSuccess({ data: data, append: false }))
        } else if (chatType === ChatConstants.chatType.BLOCKED) {
            dispatch(getBlockedChatSuccess({ data: data, append: false }))
        }
    }

    // ----------------------------------- Success Handling -----------------------------------
    useEffect(() => {
        if (anchorEl !== null) {
            // Archive particular chat success handling
            if (archiveParticularChat && Object.keys(archiveParticularChat).length > 0) {
                archiveUnarchiveData(archiveParticularChat.data.is_archived)
                Toaster.success(archiveParticularChat.data.message)
                dispatch(archiveParticularChatSuccess({}))
            }


            // delete particular chat success handling
            if (deleteParticularChat && Object.keys(deleteParticularChat).length > 0) {
                Toaster.success(deleteParticularChat.data.message)
                removeData(chatType, chatId)
                dispatch(deleteParticularChatSuccess({}))
            }

            // Block particular chat success handling
            if (blockParticularChat && Object.keys(blockParticularChat).length > 0) {
                blockUnblockData(blockParticularChat.data.is_blocked)
                Toaster.success(blockParticularChat.data.message)
                dispatch(blockParticularChatSuccess({}))
            }

            // Mute particular chat success handling
            if (muteParticularChat && Object.keys(muteParticularChat).length > 0) {
                readOrMuteParticularData(chatType, 'is_muted', muteParticularChat.data.is_muted)
                Toaster.success(muteParticularChat.data.message)
                dispatch(muteParticularChatSuccess({}))
            }

            // Unread particular chat success handling
            if (unreadParticularChat && Object.keys(unreadParticularChat).length > 0) {
                readOrMuteParticularData(chatType, 'is_read', unreadParticularChat.data.is_read)
                dispatch(unreadParticularChatSuccess({}))
                if (!unreadParticularChat.data.is_read) {
                    Toaster.success(unreadParticularChat.data.message)
                }
            }
        }
    }, [muteParticularChat, archiveParticularChat, deleteParticularChat, blockParticularChat, unreadParticularChat])


    // ----------------------------------- Error Handling -----------------------------------

    useEffect(() => {

        if (anchorEl !== null) {

            // Archive particular chat Error message display
            if (archiveParticularChatFailureData && Object.keys(archiveParticularChatFailureData).length > 0) {
                Toaster.error(archiveParticularChatFailureData.message)
                dispatch(archiveParticularChatFailure({}))
            }

            // Delete particular chat Error message display
            if (deleteParticularChatFailureData && Object.keys(deleteParticularChatFailureData).length > 0) {
                Toaster.error(deleteParticularChatFailureData.message)
                dispatch(deleteParticularChatFailure({}))
            }

            // Block particular chat Error message display
            if (blockParticularChatFailureData && Object.keys(blockParticularChatFailureData).length > 0) {
                Toaster.error(blockParticularChatFailureData.message)
                dispatch(blockParticularChatFailure({}))
            }

            // Unread particular chat Error message display
            if (unreadParticularChatFailureData && Object.keys(unreadParticularChatFailureData).length > 0) {
                Toaster.error(unreadParticularChatFailureData.message)
                dispatch(unreadParticularChatFailure({}))
            }

            // Mute particular chat Error message display
            if (muteParticularChatFailureData && Object.keys(muteParticularChatFailureData).length > 0) {
                Toaster.error(muteParticularChatFailureData.message)
                dispatch(muteParticularChatFailure({}))
            }

            if (userListError && Object.keys(userListError).length > 0) {
                Toaster.error(userListError.message)
                dispatch(resetUserList({}))
            }
        }
    }, [unreadParticularChatFailureData, muteParticularChatFailureData, blockParticularChatFailureData, archiveParticularChatFailureData, deleteParticularChatFailureData, userListError])


    useEffect(() => {
        setChatListData([...chatList])
        setChatListDataWithPagination({ ...chatListAllData })
        if (chatType === ChatConstants.chatType.BLOCKED) {
            setChatListData(blockedChatList)
            setChatListDataWithPagination(blockedChatListAllData)
        } else if (chatType === ChatConstants.chatType.ARCHIVED) {
            setChatListData(archivedChatList)
            setChatListDataWithPagination(archivedChatListAllData)
        }
    }, [chatType, chatList, blockedChatList, archivedChatList])


    useEffect(() => {
        if (chatStoreError && Object.keys(chatStoreError).length && !chatStoreError.success) {
            setAnchorEl(null);
            setOpen(false)
            dispatch(chatStoreFailure({}))
        }
    }, [chatStoreError])

    // Open popOver when chat created successfully.
    useEffect(() => {
        if (Object.keys(chatStoreData).length && chatStoreData.success) {
            dispatch(particularChatSuccess({}))
            const spanRefId = anchorEl?.getAttribute('id')
            if (spanRefId === 'user_id' + itemId) {
                // Open chat popup from detail or contact list after getting response
                setOpen(true)
            }
            setChatId(chatStoreData.data.item.id)
        }
    }, [chatStoreData])

    useEffect(() => {
        //for preventing default padding when mui modal opens
        let elem = document.getElementById('navbarContainer')
        if (elem) {
            if (open) {
                elem.style.paddingRight = '5px'
                return
            }
            elem.style.paddingRight = '0px'
        }
    }, [open]);

    const handleClick = () => {
        localStorage.removeItem('chatScrollPosition')
        setChatId(null)
        setAnchorEl(spanRef.current);
        //From header open popup Directly
        if (!ads) {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
        setChatId(null)
    };

    return (
        <ChatWrapper>
            {loggedIn ?
                userData.profile_is_complete ?
                    // If user profile completed
                    !isSmallDevice ?
                        // on web open popUp of user chat list
                        <Box id={!ads ? 'chatIcon' : ''} sx={{ position: 'relative' }} className={`chatIcon ${isDisableBtn ? 'noCursor' : 'pointer'}`} onClick={handleClick} id={'user_id' + itemId} ref={spanRef}>
                            {ChatIcon}
                            {unreadMessage && !ads && <span className='icon-dot-header'></span>}
                        </Box>
                        :
                        ads !== 'bottom-navBar' ?
                            <Box sx={{ position: 'relative' }}>
                                <span className={(((unreadMessage) && !ads) ? ' unread ' : ' ') + ' pointer chatIcon'}>{ChatIcon}</span>
                            </Box>
                            :
                            // on mobile view redirect on chat link 
                            <NavLink activeClassName="active" to={routes.userRoutes.chat} onClick={() => { localStorage.removeItem('chatScrollPosition') }} className="relative">
                                {(unreadMessage) && ads === "bottom-navBar" && <span style={{ marginTop: '10px' }} className="newMessage-icon"></span>}
                                <span className={` chatIcon pointer ${((unreadMessage) && ads === 'bottom-navBar') ? ' unread' : ' '}`}>{ChatIcon}</span>
                            </NavLink>
                    :
                    // If users profile Incomplete then
                    <>
                        <span className={(((unreadMessage) && !ads) ? ' unread ' : ' ') + ' pointer chatIcon'} onClick={() => {
                            if (loading || companyReducerData.loading) {
                                return
                            }
                            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.CHAT.COMPLETE_PROFILE_BUTTON)} message={Strings.completeProfileString} click={() => history.push(routes.userRoutes.userProfileEdit)} />, false)
                        }
                        }>{ChatIcon}</span>
                    </>
                :
                // if guest user then re-direct to login screen
                ads == 'logout' ?
                    <span className={(((unreadMessage) && !ads) ? ' unread ' : ' ') + ' pointer chatIcon'}>{ChatIcon}</span>
                    :
                    <NavLink activeClassName="active" to={routes.authRoutes.login}>
                        <span className={(((unreadMessage) && !ads) ? ' unread ' : ' ') + ' pointer chatIcon'}>{ChatIcon}</span>
                    </NavLink>
            }

            {/* PopOver  */}
            <Modal
                className='PopOver'
                open={open}
                onClose={handleClose}
                sx={{
                    top: location.pathname.includes(routes.publicRoutes.shopURL) ? '85px' : '64px !important',
                    boxShadow: 'none',
                    '.MuiBackdrop-root': {
                        background: 'none'
                    }
                }}
            >
                <ChatWrapper>
                    <Box
                        sx={{
                            position: 'relative',
                            maxWidth: '400px',
                            marginLeft: 'auto',
                            right: '10px',
                            '&:after': {
                                content: `""`,
                                position: 'absolute',
                                top: "-10px",
                                left:
                                    location.pathname.includes(routes.publicRoutes.shopURL) ||
                                        location.pathname.includes(routes.publicRoutes.public_profile_subisidiary) ||
                                        location.pathname.includes(routes.publicRoutes.public_profile_user)
                                        ? '100px' : '300px',
                                width: 0,
                                height: 0,
                                borderLeft: '10px solid transparent',
                                borderRight: '10px solid transparent',
                                borderBottom: '15px solid white',
                                zIndex: 9999999,
                            },
                        }}
                    >
                        <Box className={"chatList-position"}
                            sx={{
                                right: location.pathname.includes(routes.publicRoutes.shopURL) ? '20px' : '10px',
                                height: !chatId ? 'calc(100vh - 235px)' : 'auto',
                                boxShadow: 24
                            }}
                        >
                            {chatId ?
                                <ChatMessageScreen showInitiateGroupChatModal={showInitiateChatModal} setShowInitiateGroupChatModal={setShowInitiateChatModal} popup={true} setChatId={setChatId} chatId={chatId} chatType={chatType} /> :
                                <ChatList showInitiateChatModal={showInitiateChatModal} setShowInitiateChatModal={setShowInitiateChatModal} chatListData={chatListData} expandedView={true} popup={true} setChatId={setChatId} chatId={chatId} chatType={chatType} setChatType={setChatType} />
                            }
                        </Box>
                    </Box>
                </ChatWrapper>
            </Modal>
        </ChatWrapper>
    )
}

export default ChatIcon