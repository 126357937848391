import { Box, Checkbox } from '@mui/material'
import SearchDropdown from 'app/components/CustomFields/searchDropdown'
import { Nullable } from 'app/pages/PublicProfile/interface/interface'
import React, { useEffect, useState } from 'react'
import { MAX_NO_OF_OWNERS, isServiceHistory } from '../filterConfig'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { getParamQueries } from 'config/appConfig'
import { useLocation } from 'react-router-dom'

const History = ({ updateURL }) => {

    const { t } = useTranslation()
    const location = useLocation()

    const [dropdownValues, _dropdownValues] = useState<Array<Object>>([])

    const [serviceHistory, _serviceHistory] = useState<boolean>(false)
    const [previousOwner, _previousOwner] = useState<Nullable<number>>()

    useEffect(() => {
        let ownersArray: Object[] = []
        for (let i = 1; i <= MAX_NO_OF_OWNERS; i++) {
            ownersArray.push({
                id: i < 10 ? `0${i}` : i,
                name: i < 10 ? `0${i}` : i
            })
        }
        _dropdownValues(ownersArray)
    }, [])

    // Sets values got from url to fields (runs only when page is rendered for the first time)
    useEffect(() => {
        let payload = getParamQueries(location.search)
        _previousOwner(payload.owner_number ?? null)
        _serviceHistory(payload.maintenance_history ?? false)
    }, [location.search])

    const handlePreviousOwner = (value) => {
        if (previousOwner == value) {
            _previousOwner(null)
            updateURL('owner_number', '')
            return
        }
        _previousOwner(value)
        updateURL('owner_number', value)
    }

    const handleServiceHistory = () => {
        _serviceHistory(!serviceHistory)
        updateURL('maintenance_history', serviceHistory ? '' : isServiceHistory.true)
    }

    return (
        <Box sx={{ gap: '24px', mt: 1 }} className='flexColumn'>
            <Box className='flexRow justifyContentBetween alignItemsCenter'>
                <span className='vatCheckBoxLabel'>{t(translations.FILTER_COMPONENT.SERVICE_HISTORY)}</span>
                <Checkbox checked={serviceHistory} onChange={handleServiceHistory} />
            </Box>
            <SearchDropdown title={t(translations.FILTER_COMPONENT.PREVIOUS_OWNERS)} list={dropdownValues ?? []} value={previousOwner} handleChange={(value) => handlePreviousOwner(value)} hideSearch={true} />
        </Box>
    )
}

export default History