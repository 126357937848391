import { Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import AuthImage from 'app/AssetNew/Images/authImage.webp'
import LoginFooter from 'app/Component2/GlobalComponent/LoginFooter'
import { colorNew } from 'styles/StyleConstants'
import { SvgSiteLogo } from 'app/components/svgicons/svgNew'
import { Link } from 'react-router-dom'
import { routes } from 'routes/routes'
import { langugageSwitchOpen } from 'store/actions/component-actions'
import { useDispatch } from 'react-redux'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next'
import english from 'styles/img/english.png';
import france from 'styles/img/france.png';
import deLanguage from 'app/AssetNew/Images/de.png';
import esLanguage from 'app/AssetNew/Images/es.png';
import nlLanguage from 'app/AssetNew/Images/nl.png';
import { AuthLayoutStyle } from 'app/Component2/styled/AuthLayoutStyle'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
const AuthLayout = (props: any) => {
    const { isHeader } = props;
    const [isSmallDevice] = useDeviceType()
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const FlatImage = () => {
        let imageSrc = ""
        if (i18n?.language === 'en' || i18n?.language === 'en-GB') {
            imageSrc = english
        } else if (i18n?.language === 'fr') {
            imageSrc = france
        } else if (i18n?.language === 'de') {
            imageSrc = deLanguage
        } else if (i18n?.language === 'es') {
            imageSrc = esLanguage
        } else if (i18n?.language === 'nl') {
            imageSrc = nlLanguage
        } else {
            imageSrc = english
        }
        return <img src={imageSrc} alt="flagIcon" />
    }
    return (
        <AuthLayoutStyle>
            <Helmet>
                {props.metaTitle ? <title>{props.metaTitle}</title> : null}
                {props.metaTitle ? <meta property="og:title" content={props.metaTitle} /> : null}
                {props.metaDescription ? <meta property="description" content={props.metaDescription} /> : null}
                {props.metaDescription ? <meta property="og:description" content={props.metaDescription} /> : null}
                {props.image ? <meta property="og:image" content={props.image} /> : null}
                <meta property="og:image:width" content={"800"} />
                <meta property="og:image:height" content={"405"} />
                <meta property="og:image:user_generated" content="true" />
            </Helmet>
            <Box
                sx={{
                    minHeight: !isHeader && '100vh',
                    display: 'flex',
                    background: `${colorNew.colorGrayLight}`,
                    borderRadius: isHeader && '10px',
                }}
            >
                {!isHeader &&
                    <Box className="imageSide" sx={{ backgroundImage: `url(${AuthImage})` }}>
                        <Link to={routes.vehicleRoutes.adsHome}>
                            <SvgSiteLogo />
                        </Link>
                    </Box>}
                <Box className="children">
                    <Box className={'children-wraper'} sx={{ minHeight: { md: !isHeader && `calc(100vh - 150px)` } }}>
                        {!isHeader && !isSmallDevice &&
                            <Box
                                onClick={() => dispatch(langugageSwitchOpen())}
                                className='pointer change-language'>
                                <span>
                                    <FlatImage />
                                    <ExpandMoreIcon sx={{ color: `${colorNew.colorGrayDark}` }} />
                                </span>
                            </Box>}
                        {props.children}
                    </Box>
                    {!isHeader &&
                        <Box
                            sx={{
                                bottom: 0,
                                px: {
                                    md: 0,
                                    xs: 1
                                }
                            }}
                        >
                            <LoginFooter />
                        </Box>}
                </Box>
            </Box>
        </AuthLayoutStyle>
    )
}

export default AuthLayout