import React from 'react'
import { Box } from '@mui/material'
import { DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice'
import { lan } from 'locales/multiPageTranslation/adsLan'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { vehicleColorI } from 'app/pages/AdsAuctionDetailsPage/AuctionDetailInterface/AuctionInterface'

const VehicleColor = ({ outside_color, inside_color, interior }: vehicleColorI) => {

    const { t } = useTranslation();
    return (
        <>
            <Box sx={{ flex: 1 }}>
                <DetailsTitle sx={{ ml: '10px' }}>{t(translations.ads.COLOR)}</DetailsTitle>
                <Box sx={{
                    fontSize: '18px',
                    fontWeight: 400,
                    color: '#666',
                    ml: "10px",
                    'span': {
                        width: "16px",
                        height: "16px",
                        borderRadius: "2px",
                        display: 'inline-block',
                        mr: 1,
                        border: '1px solid #eaeaea'
                    }
                }}>
                    {/* Code for Outside color of vehicle */}
                    <Box className="color-parent">
                        <Box className='flexRow justifyContentBetween' sx={{ margin: '23px 0' }}>
                            <label>{t(translations.ads.COLOR_OUTSIDE)}</label>
                            <Box className='color-name'>
                                <p>{outside_color?.name || "-"}</p>
                                {outside_color?.hexa && <span style={{ backgroundColor: outside_color?.hexa || '' }}></span>}
                            </Box>
                        </Box>
                    </Box>
                    {/* Code for Inside color of vehicle */}
                    <Box className="color-parent">
                        <Box className='flexRow justifyContentBetween' sx={{ margin: '23px 0' }}>
                            <label>{t(translations.AD_DETAIL_PAGE.UPHOLSTERY_COLOR)}</label>
                            <Box className='color-name'>
                                <p>{inside_color?.name || '-'}</p>
                                {inside_color?.hexa && <span style={{ backgroundColor: inside_color?.hexa }}></span>}
                            </Box>
                        </Box>
                    </Box>
                    {/* Code for Interior of vehicle */}
                    <Box className="color-parent">
                        <Box className='flexRow justifyContentBetween color-name' sx={{ margin: '23px 0', pr: '10px' }}>
                            <label>{t(translations.AD_DETAIL_PAGE.UPHOLSTRY)}</label>
                            <p>{interior?.name || "-"}</p>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default VehicleColor