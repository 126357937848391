export const userRoutes = {
  userProfile: '/profile',
  profileSetting: '/profile-setting',
  changeEmailAccountConfirmation: '/change_email/:id/:hash',
  userPrivacy: '/profile-privacy',
  myProfile: '/my-profile',
  custom: '/custom',
  userProfileEdit: '/my-profileEdit',
  publicDisplay: '/public-display',
  idVerfication: '/verification',
  chat: "/chat",
  myVehicles: "/my-vehicles",
  createOrder: "/create-order",
  confirmSale: "/confirm-sale",
  credits: "/credits-pricing",
  creditsPayment: "/credits-payment",
  orderReservation: "/order-reservation",
  myWallet: "/my-wallet",
  creditBuySuccess: '/credit_payment_success',
  creditBuyFail: '/credit_payment_fail',
  vehiclePaymentSuccess: '/vehicle_payment_success',
  vehiclePaymentFail: '/vehicle_payment_fail',
  subscriptionBuySuccess: '/subscription_buy_success',
  subscriptionBuyFail: '/subscription_buy_fail'

};