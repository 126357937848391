import React from 'react'
import { Box, Popover, Typography } from '@mui/material'

const CountryByContinent = ({ id, open1, anchorEl, handleClose, filteredCountry, SelectCountry, countryPopupRef, selectedCountry }) => {
    return (
        <>
            <Popover
                className='continentPopup'
                id={id}
                open={open1}
                anchorEl={anchorEl}
                onClose={handleClose}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {filteredCountry && Object.keys(filteredCountry).length > 0 &&
                    <Box  sx={{ 
                            maxHeight: '250px', 
                            width: countryPopupRef?.current ? countryPopupRef.current.offsetWidth - 15 : 0, 
                            maxWidth: countryPopupRef?.current ? countryPopupRef.current.offsetWidth : 0
                        }}>
                        {Object.keys(filteredCountry).map((continent, index) => (
                            <Box key={continent}>

                                <Typography sx={{
                                    fontSize: "20px",
                                    fontWeight: '600',
                                    mb: 1,
                                    ml: 2
                                }}>                                    
                                    {continent}
                                </Typography>

                                <ul style={{ listStyleType: 'none', marginLeft: "-30px" }}>
                                    {filteredCountry[continent]?.map((country) => (
                                        <li key={country.id} onClick={() => SelectCountry(
                                            {
                                                id: country.id,
                                                name: country.country_name,
                                                flag: country.flag_thumbnail,
                                                code: country.prefix_call,
                                                fullname: country.fullname
                                            }
                                        )
                                        }>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    width: "361px",
                                                    backgroundColor: country.fullname === selectedCountry ? 'aliceblue' : '',
                                                    '.flag1': {
                                                        mb: 1,
                                                        ml: 2,
                                                        'img': {
                                                            width: "28px",
                                                            height: "28px",
                                                            ml: 2,
                                                            mr: 2
                                                        }
                                                    }
                                                }}
                                            >
                                                <Box className="flag1">
                                                    {/* <img src={country.flag_thumbnail} alt={country.country_name} /> */}
                                                    <span className="_fullname">{country?.fullname}</span>
                                                </Box>
                                            </Box>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        ))

                        }
                    </Box>}
            </Popover>
        </>
    )
}

export default CountryByContinent