import { put, takeEvery } from 'redux-saga/effects';
import { ApiPaths } from '../../services/ApiPaths';
import Api from '../../services/axios';
import {
  getMyClassicAdsRequest,
  getMyClassicAdsSuccess,
  getMyClassicAdsFailure,
  getMyParkedAdsRequest,
  getMyParkedAdsFailure,
  getMyParkedAdsSuccess,
  getMyLiveAuctionAdsFailure,
  getMyLiveAuctionAdsSuccess,
  getMyLiveAuctionAdsRequest,
  getMyActionRequiredAdsRequest,
  getMyActionRequiredAdsSuccess,
  getMyActionRequiredAdsFailure,
  getAuctionAdsHistoryFailure,
  getAuctionAdsHistorySuccess,
  getAuctionAdsHistoryRequest,
  sellReserveVehicleFailure,
  sellReserveVehicleSuccess,
  sellReserveVehicleRequest,
  getMyHotdealAdsFailure,
  getMyHotdealAdsSuccess,
  getMyHotdealAdsRequest,
} from 'store/actions/myAds-actions';

function* _getMyClassicAdsRequest({ payload }) {
  try {
    let uri = generateURI(ApiPaths.vehicles.addVehicles + ApiPaths.vehicles.myAdds + '?filter[type_of_sale]=0', payload);
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getMyClassicAdsSuccess(row));
  } catch (e) {
    yield put(getMyClassicAdsFailure(e.response));
  }
}

function* _getMyLiveAuctionAdsRequest({ payload }) {
  try {
    let uri = generateURI(ApiPaths.vehicles.addVehicles + ApiPaths.vehicles.myAdds + '?filter[type_of_sale]=1&filter[ad_auction_active_or_upcoming]=1', payload);
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getMyLiveAuctionAdsSuccess(row));
  } catch (e) {
    yield put(getMyLiveAuctionAdsFailure(e.response));
  }
}


function* _getMyActionRequiredAdsRequest({ payload }) {
  try {
    let uri = generateURI(ApiPaths.vehicles.addVehicles + ApiPaths.vehicles.myAdds + '?filter[type_of_sale]=1&filter[ad_auction_finished]=1&filter[bid_accepted]=0', payload);
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getMyActionRequiredAdsSuccess(row));
  } catch (e) {
    yield put(getMyActionRequiredAdsFailure(e.response));
  }
}

function* _getAuctionAdsHistoryRequest({ payload }) {
  try {
    let uri = generateURI(ApiPaths.vehicles.addVehicles + ApiPaths.vehicles.myAdds + '?filter[type_of_sale]=1&filter[ad_auction_finished]=1&filter[bid_accepted]=1,2&withSold=1', payload);
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getAuctionAdsHistorySuccess(row));
  } catch (e) {
    yield put(getAuctionAdsHistoryFailure(e.response));
  }
}

function* _getMyParkedAdsRequest({ payload }) {
  try {
    let uri = generateURI(ApiPaths.vehicles.ownerVehicles + '?filter[park]=1', payload);
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getMyParkedAdsSuccess(row));
  } catch (e) {
    yield put(getMyParkedAdsFailure(e.response));
  }
}

function* _getMyHotdealAdsRequest({ payload }) {
  try {
    let uri = generateURI(ApiPaths.vehicles.addVehicles + ApiPaths.vehicles.myHotDeals + '?', payload);
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getMyHotdealAdsSuccess(row));
  } catch (e) {
    yield put(getMyHotdealAdsFailure(e.response));
  }
}

function* _sellReserveVehicleRequest({ payload }) {
  try {
    const uri = ApiPaths.vehicles.ownerVehicles + "/" + payload.id + ApiPaths.vehicles.sold;
    delete payload.id;
    const { data } = yield Api.post(uri, payload);
    const row = data
    yield put(sellReserveVehicleSuccess(row));
  } catch (e) {
    yield put(sellReserveVehicleFailure(e.response));
  }
}

const generateURI = (uri, payload) => {
  if (!payload) {
    return uri
  }
  if ('auction_id' in payload) {
    uri += '&filter[auction_id]=' + payload.auction_id;
  }
  if ('page' in payload) {
    uri += '&page=' + payload.page;
  }
  if ('product_type_id' in payload) {
    uri += '&filter[product_type_id]=' + payload.product_type_id;
  }
  if ('keyword' in payload && payload.keyword !== '') {
    uri += '&filter[keyword]=' + payload.keyword;
  }
  if ('sort' in payload && payload.sort !== '') {
    uri += '&sort=' + payload.sort;
  }
  if ('hidden_sold' in payload) {
    uri += `&hidden_sold=${payload.hidden_sold ? 1 : 0}`;
  }
  if ('is_online' in payload) {
    uri += `&filter[is_online]=${payload.is_online}`;
  }
  if ('order' in payload) {
    uri += `&filter[order]=${payload.order}`;
  }
  // --------------------Slug Filters--------------------
  if ('brand_slug' in payload) {
    uri += '&filter[brand_slug]=' + payload.brand_slug;
  }
  if ('model_slug' in payload) {
    uri += '&filter[model_slug]=' + payload.model_slug;
  }
  if ('country_slug' in payload) {
    uri += '&filter[country_slug]=' + payload.country_slug;
  }
  // --------------------Key Data Filter--------------------
  if ('brand_id' in payload) {
    uri += '&filter[brand_id]=' + payload.brand_id;
  }
  if ('model_id' in payload) {
    uri += '&filter[model_id]=' + payload.model_id;
  }
  if ('new_vehicle' in payload) {
    uri += '&filter[new_vehicle]=' + payload.new_vehicle;
  }
  if ('registration_start' in payload) {
    uri += '&filter[registration_start]=' + payload.registration_start;
  }
  if ('registration_end' in payload) {
    uri += '&filter[registration_end]=' + payload.registration_end;
  }
  if ('body_vehicle_id' in payload) {
    uri += '&filter[body_vehicle_id]=' + payload.body_vehicle_id;
  }
  if ('outside_color_id' in payload) {
    uri += '&filter[outside_color_id]=' + payload.outside_color_id;
  }
  if ('vehicle_mileage_start' in payload) {
    uri += '&filter[vehicle_mileage_start]=' + payload.vehicle_mileage_start;
  }
  if ('vehicle_mileage_end' in payload) {
    uri += '&filter[vehicle_mileage_end]=' + payload.vehicle_mileage_end;
  }
  if ('gearbox_id' in payload) {
    uri += '&filter[gearbox_id]=' + payload.gearbox_id;
  }
  if ('fuel_id' in payload) {
    uri += '&filter[fuel_id]=' + payload.fuel_id;
  }
  if ('frame_size_id' in payload) {
    uri += '&filter[frame_size_id]=' + payload.frame_size_id;
  }
  // --------------------Price Filter--------------------
  if ('price_start' in payload) {
    uri += '&filter[price_start]=' + payload.price_start;
  }
  if ('price_end' in payload) {
    uri += '&filter[price_end]=' + payload.price_end;
  }
  if ('deductible_vat' in payload) {
    uri += '&filter[deductible_vat]=' + payload.deductible_vat;
  }
  // --------------------Performance Filter--------------------
  if ('power_cv_start' in payload) {
    uri += '&filter[power_cv_start]=' + payload.power_cv_start;
  }
  if ('power_cv_end' in payload) {
    uri += '&filter[power_cv_end]=' + payload.power_cv_end;
  }
  if ('power_kw_start' in payload) {
    uri += '&filter[power_kw_start]=' + payload.power_kw_start;
  }
  if ('power_kw_end' in payload) {
    uri += '&filter[power_kw_end]=' + payload.power_kw_end;
  }
  if ('euro_standard_level' in payload) {
    uri += '&filter[euro_standard_level]=' + payload.euro_standard_level;
  }
  if ('battery_range_max' in payload) {
    uri += '&filter[battery_range_max]=' + payload.battery_range_max;
  }
  if ('charge_duration_home_80_end' in payload) {
    uri += '&filter[charge_duration_home_80_end]=' + payload.charge_duration_home_80_end;
  }
  if ('charge_duration_fast_80_end' in payload) {
    uri += '&filter[charge_duration_fast_80_end]=' + payload.charge_duration_fast_80_end;
  }
  // --------------------Electric Filter--------------------
  if ('is_electric' in payload) {
    uri += '&filter[is_electric]=' + payload.is_electric;
  }
  if ('top_speed' in payload) {
    uri += '&filter[top_speed]=' + payload.top_speed;
  }
  if ('motor_power_watt' in payload) {
    uri += '&filter[motor_power_watt]=' + payload.motor_power_watt;
  }
  // --------------------History Filter--------------------
  if ('owner_number' in payload) {
    uri += '&filter[owner_number]=' + payload.owner_number;
  }
  if ('maintenance_history' in payload) {
    uri += '&filter[maintenance_history]=' + payload.maintenance_history;
  }
  // --------------------Equiments Filter--------------------
  if ('seats_start' in payload) {
    uri += '&filter[seats_start]=' + payload.seats_start;
  }
  if ('seats_end' in payload) {
    uri += '&filter[seats_end]=' + payload.seats_end;
  }
  if ('interior_id' in payload) {
    uri += '&filter[interior_id]=' + payload.interior_id;
  }
  if ('equipment_id' in payload) {
    uri += '&filter[equipment_id]=' + payload.equipment_id;
  }
  // --------------------Seller and Warranty Filter--------------------
  if ('dealer_seller' in payload) {
    uri += '&filter[dealer_seller]=' + payload.dealer_seller;
  }
  if ('private_seller' in payload) {
    uri += '&filter[private_seller]=' + payload.private_seller;
  }
  if ('warranty_start' in payload) {
    uri += '&filter[warranty_start]=' + payload.warranty_start;
  }
  if ('with_media' in payload) {
    uri += '&filter[with_media]=' + payload.with_media;
  }
  return uri;
};

export const myAdsSaga = [
  takeEvery(getMyClassicAdsRequest, _getMyClassicAdsRequest),
  takeEvery(getMyParkedAdsRequest, _getMyParkedAdsRequest),
  takeEvery(getMyLiveAuctionAdsRequest, _getMyLiveAuctionAdsRequest),
  takeEvery(getMyActionRequiredAdsRequest, _getMyActionRequiredAdsRequest),
  takeEvery(getAuctionAdsHistoryRequest, _getAuctionAdsHistoryRequest),
  takeEvery(sellReserveVehicleRequest, _sellReserveVehicleRequest),
  takeEvery(getMyHotdealAdsRequest, _getMyHotdealAdsRequest)
];
