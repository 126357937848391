import { Box, Typography } from '@mui/material';
import { useWindowSize } from 'app/Hooks/windowResize';
import { SvgMobileLeftArrow, SvgmemSearch } from 'app/components/svgicons/svgNew';
import { translations } from 'locales/translations';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { addMemberRequest } from 'store/actions/chat-action';
import { colorNew } from 'styles/StyleConstants';
import AvatarandDetails from './AvatarandDetails';
import { SvgPlusLite } from 'app/components/svgicons/svg';
import MemberAdded from './MemberAdded';
import { UserInterface, groupMemberListI } from '../../interface/interface';
import { suggestedMembers } from 'config/chatConfig';

const SuggestedMemberList = ({ chatId, newMembers, handleRemoveMember, allUserList, handleCreateGroupchat, setAddNewMember, setNewSelectedMemberList, groupMembers, setAllData }: groupMemberListI) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [mobile] = useWindowSize();
    const [filteredData, setFilteredData] = useState<Array<UserInterface>>(allUserList ? allUserList : [])

    const { subsidiaryMemberList, groupUpdateLoading } = useSelector((state: RootStateOrAny) => state.chat)

    const [searchUser, _searchUser] = useState<string>('')

    // handle searched user function
    const handleSearchUser = (event) => {
        _searchUser(event.target.value)
        let findMembers = allUserList.filter(item => {
            return item.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
        setFilteredData(findMembers)
    }

    useEffect(() => {
        if (searchUser) {
            let findMembers = allUserList.filter(item => {
                return item.name.toLowerCase().includes(searchUser.toLowerCase());
            });
            setFilteredData(findMembers)
            return
        }
        setFilteredData(allUserList)
    }, [allUserList])

    const handleAddNewMemberInGroup = () => {
        var newMembersId = newMembers.map((newMember) => {
            return newMember.id;
        });
        dispatch(addMemberRequest({ member: newMembersId, chat_id: chatId }))
    }


    // Resets data to original state when popup gets closed without any action
    useEffect(() => {
        return () => {
            setNewSelectedMemberList([])
            const data = suggestedMembers(subsidiaryMemberList?.members, groupMembers)
            setAllData(data)
        }
    }, [])


    // Removes user from filtered list when any users gets removed from group
    const updateFilteredData = (id) => {
        let groupUsers = [...filteredData]
        let index = groupUsers.findIndex((o) => o.id === id)
        if (index !== -1) {
            groupUsers.splice(index, 1)
            setFilteredData(groupUsers)
        }
    }

    return <>
        {<>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                ".creatGroup": {
                    color: colorNew.primaryColor,

                },
                ".error": {
                    color: colorNew.colorRed,
                    'svg': {
                        'path': {
                            fill: colorNew.colorRed
                        }
                    }
                },
                ".Add-Member": {
                    fontSize: "21px",
                    fontWeight: "400"
                },
                ".disable": {
                    color: colorNew.colorGrayDark,
                }
            }}>

                <span className={"Add-Member"}>
                    <SvgMobileLeftArrow onClick={() => setAddNewMember(false)} style={{ marginRight: '10px' }} className='pointer' /> {t(translations.formText.ADD_NEW_MEMBER)}
                </span>
                <h2 className={`creatGroup ${newMembers.length > 0 ? 'pointer' : 'disable'}`} onClick={() => {
                    if (newMembers.length > 0 && !groupUpdateLoading) {
                        handleAddNewMemberInGroup()
                        //  setAddNewMember(false)
                    }
                }}>{t(translations.ads.DONE)}</h2>
            </Box>
            {(newMembers.length > 0) && <Box sx={{ mt: 2 }}><MemberAdded memberList={newMembers} handleRemoveMember={handleRemoveMember} /></Box>}
            <Box>
                <div className="input-group">
                    <span>
                        <SvgmemSearch />
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t(translations.webTexts.SEARCH_MEMBER)}
                        aria-label="search"
                        aria-describedby="search"
                        onChange={(e) => handleSearchUser(e)}
                    />
                </div>
            </Box>
        </>
        }
        <Box>
            <Box className="suggestedMember">
                <h6 className="suggesttitle">
                    {t(translations.webTexts.SUGESTION_MEMBER_CARTOBIKE)}
                </h6>
            </Box>
        </Box>
        <Box className="intiateChatUserList intiateChatUserListPopup scrollBar" sx={{
            maxHeight: window.innerWidth < 992 ? (newMembers.length > 0 ? 'calc(100vh - 365px) !important' : 'calc(100vh - 292px) !important') : "39vh"
        }}>
            {filteredData && filteredData.length > 0 ? filteredData.map((item, index) => (
                <Box className='select_user pointer' key={item?.id}
                    onClick={() => {
                        if (!groupUpdateLoading) {
                            handleCreateGroupchat(item)
                        }
                        updateFilteredData(item?.id)
                    }
                    }
                    sx={{
                        justifyContent: "space-between",
                        '.svg-style': {
                            'svg': {
                                'path': {
                                    fill: colorNew.colorBlack
                                }
                            }
                        }
                    }}
                >
                    <AvatarandDetails description={true} userInfo={item} hideStatus={true} hideFlag={false} newChat={true} hidebackButton={true} popup={true} />
                    <Box className='addMemberButton'>
                        <SvgPlusLite className='svg-style' /> {!mobile && t(translations.CHAT.ADD_TO_GROUP)}
                    </Box>
                </Box>
            ))
                :
                <Box className='select_user pointer'>
                    {t(translations.CHAT.USER_NOT_FOUND)}
                </Box>
            }
        </Box>
    </>
}



export default SuggestedMemberList