import { Tab, Tabs } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { colorNew } from 'styles/StyleConstants';
import { translations } from 'locales/translations';
import { productTypes } from 'config/variables';


const TabberSwiper = (props: any) => {
  const { valueTab, handleChange, type, setIsHovered } = props
  const { t } = useTranslation();

  const tabsForCar = [{ id: 'detail', label: t(translations.ads.DETAILS) },
  { id: 'equipment', label: t(translations.ads.EQUIPMENTS) },
  { id: 'contact', label: t(translations.ads.CONTACT) },
  { id: 'expertise', label: t(translations.ads.EXPERTISE) }]

  const tabsForBikeStep = [{ id: 'detail', label: t(translations.ads.DETAILS) },
  { id: 'component', label: t(translations.AD_DETAIL_PAGE.COMPONENT) },
  { id: 'electric', label: t(translations.ads.ELECTRIC) },
  { id: 'contact', label: t(translations.ads.CONTACT) }]

  const tabsForMotorBike = [{ id: 'detail', label: t(translations.ads.DETAILS) },
  { id: 'component', label: t(translations.AD_DETAIL_PAGE.COMPONENT) },
  { id: 'contact', label: t(translations.ads.CONTACT) }]


  const handlescrolling = (elem) => {
    const element = document.getElementById(elem)
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
    }
  }

  return (
    type === productTypes.car ?
      <Tabs
        value={valueTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        sx={{
          mr: 2,
          'button': {
            mr: 2,
            textTransform: "none",
            '&:not(.Mui-selected)': {
              backgroundColor: '#fafaff',
              borderRadius: '6px',
              color: '#666',
              fontSize: "18px",
              fontWeight: 400,
              letterSpacing: "0.36px",
            }
          }
        }}
      >
        {tabsForCar.map((tab, index) => {
          return <Tab key={'tab' + index} id={tab.id} label={tab.label} onMouseEnter={() => setIsHovered(true)} onTouchStartCapture={() => setIsHovered(true)} onClick={(e) => handlescrolling(tab.id)} />
        })}
      </Tabs>
      : type === productTypes.bike ?
        <Tabs
          value={valueTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            mr: 2,
            'button': {
              mr: 2,
              textTransform: 'none',
              fontSize: '18px',
              '&:not(.Mui-selected)': {
                backgroundColor: colorNew.colorGray2,
                opacity: 0.5,
                borderRadius: '6px'
              }
            }
          }}
        >
          {tabsForBikeStep.map((tab, index) => {
            return <Tab key={'tab' + index} id={tab.id} label={tab.label} onMouseEnter={() => setIsHovered(true)} onTouchStartCapture={() => setIsHovered(true)} onClick={(e) => handlescrolling(tab.id)} />
          })}
        </Tabs>
        : type === productTypes.step ?
          <Tabs
            value={valueTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              mr: 2,
              'button': {
                mr: 2,
                '&:not(.Mui-selected)': {
                  backgroundColor: colorNew.colorGray2,
                  opacity: 0.5,
                  borderRadius: '6px'
                }
              }
            }}
          >
            {tabsForBikeStep.map((tab, index) => {
              return <Tab key={'tab' + index} id={tab.id} label={tab.label} onMouseEnter={() => setIsHovered(true)} onTouchStartCapture={() => setIsHovered(true)} onClick={(e) => handlescrolling(tab.id)} />
            })}
          </Tabs>
          : <Tabs
            value={valueTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              mr: 2,
              'button': {
                mr: 2,
                textTransform: 'none',
                fontSize: '18px',
                '&:not(.Mui-selected)': {
                  backgroundColor: colorNew.colorGray2,
                  opacity: 0.5,
                  borderRadius: '6px'
                }
              }
            }}
          >
            {tabsForMotorBike.map((tab, index) => {
              return <Tab key={'tab' + index} id={tab.id} label={tab.label} onMouseEnter={() => setIsHovered(true)} onTouchStartCapture={() => setIsHovered(true)} onClick={(e) => handlescrolling(tab.id)} />
            })}
          </Tabs>
  )
}

export default TabberSwiper;