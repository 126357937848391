import * as React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgMyAds, SvgActionPlaces, SvgFaq, SvgLegal, SvgSiteLogo, SvgAnnounce, SvgPlus, Svgauction, SvgTranslation } from "app/components/svgicons/svgNew";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import MobileLanguages from '../Languages/MobileLanguages';
import { PopWrapper } from '../Pops/MobilePop';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserSubsidiaryRequest } from 'store/actions/company-actions';
import { sidebarOpenReset, switchUserOpen, langugageSwitchOpen } from 'store/actions/component-actions';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useEffect } from 'react';
import { useState } from 'react';
import { adsTypes, getSubsidiaryStored, vehicles } from 'config/variables';
import { Button, Tooltip, Typography } from '@mui/material';
import { translations } from 'locales/translations';
import { PERMISSIONS, hasPermissions } from 'config/permission';
import { setCreateAdModalOpen } from 'store/actions/public-actions';
const MobileSidebar = (props: any) => {
  const { t } = useTranslation()
  const [langugesPop, _langugesPop] = useState<boolean>(false);
  const { getUserSubsidiaryRequest, sidebarOpenReset } = props;
  const loggedIn = useSelector((state: any) => state.auth.loggedIn);
  const permissionList = useSelector((state: any) => state.user.permissionData);
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const languagesHanlder = () => {
    _langugesPop(!langugesPop);
  }
  useEffect(() => {
    if (loggedIn) {
      getUserSubsidiaryRequest()
    }
  }, [])

  const dispatch = useDispatch()

  return (
    <Wrapper className="container-fluid px-0">
      <button className="closeDrawer" onClick={() => sidebarOpenReset()}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="content-wrapper-main">
        <div className="Dashboard-profile-menu-1">
          <div className="profile-dp-add mt-5">
            <Link to={routes.vehicleRoutes.adsHome}>
              <SvgSiteLogo />
            </Link>
          </div>
          <ul className="profile-menu-list">
            <li>
              <Link to={routes.vehicleRoutes.publicAds + '/' + vehicles.car}
                onClick={() => sidebarOpenReset()}
              >
                <>
                  <span>
                    <SvgAnnounce />
                  </span>
                  {t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_CLASSIC_ADS)}
                </>
              </Link>
            </li>
            <li>
              <Link to={
                {
                  pathname: routes.vehicleRoutes.auction + '/' + vehicles.car,
                  state: { auctiontab: true }
                }
              }
                onClick={() => sidebarOpenReset()}
              >
                <>
                  <span>
                    <Svgauction />
                  </span>
                  {t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_AUCTIONS)}
                </>
              </Link>
            </li>
            {user?.profile_is_complete ? <>
              {hasPermissions(permissionList, PERMISSIONS.FEATURES.ADS, PERMISSIONS.ACTIONS.READ) ?
                <li>
                  <Link to={routes.vehicleRoutes.myAds + `/${vehicles.car}/${adsTypes.classic}`}
                    onClick={() => sidebarOpenReset()}
                  >
                    <>
                      <span>
                        <SvgMyAds />
                      </span>
                      {t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_MYADS)}
                    </>
                  </Link>
                </li> : null}

              {loggedIn && hasPermissions(permissionList, PERMISSIONS.FEATURES.BUYING, PERMISSIONS.ACTIONS.READ) ?
                <li>
                  <Link to={routes.vehicleRoutes.auctionPlace + routes.vehicleRoutes.auctionPlaceInprogress}
                    onClick={() => sidebarOpenReset()}
                  >
                    <>
                      <span>
                        <SvgActionPlaces />
                      </span>
                      {t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_AUCTIONPLACE)}
                    </>
                  </Link>
                </li>
                : null}

            </> : null}

            <li>
              <Link to={routes.publicRoutes.faq}
                onClick={() => sidebarOpenReset()}
              >
                <>
                  <span>
                    <SvgFaq />
                  </span>
                  {t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_FAQ)}
                </>
              </Link>
            </li>
            <li>
              <Link to={routes.publicRoutes.legal_notice}
                onClick={() => sidebarOpenReset()}
              >
                <>
                  <span>
                    <SvgLegal />
                  </span>
                  {t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_LEGAL)}
                </>
              </Link>
            </li>
            {user?.profile_is_complete ?
              <li>
                {hasPermissions(permissionList, PERMISSIONS.FEATURES.VEHICLE, PERMISSIONS.ACTIONS.ADD) && <span
                  onClick={() => { sidebarOpenReset(); dispatch(setCreateAdModalOpen(true)) }}
                >
                  <>
                    <span className="menu-icon">
                      <SvgPlus />
                    </span>
                    {t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_ADDVEHICLE)}
                  </>
                </span>}
              </li>
              : null}
            <li>
              <div
                onClick={() => { sidebarOpenReset(); dispatch(langugageSwitchOpen()) }}
              >
                <div style={{ display: 'flex', cursor: 'pointer' }}>
                  <span className="menu-icon l-swicth">
                    <SvgTranslation />
                  </span>
                  <Typography className='l-swicth'>
                    {t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_SWITCHL)}
                  </Typography>
                </div>
              </div>

            </li>
          </ul>
        </div>
      </div>

      {langugesPop &&
        <PopWrapper title={t(translations.MOBILE_SIDEBAR.MOBILE_SIDEBAR_LANGUAGES)} popAction={languagesHanlder}>
          <MobileLanguages />
        </PopWrapper>
      }
    </Wrapper>
  );
};

MobileSidebar.propTypes = {
  sideMenuHandler: PropTypes.func
};

export default connect((state: any) => ({ ...state }), {
  getUserSubsidiaryRequest,
  sidebarOpenReset,
  switchUserOpen,
  langugageSwitchOpen
})(MobileSidebar);

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  max-width: 300px;
  min-width: 300px;
  max-width: 300px;
  .closeDrawer {
    cursor:pointer;
    position: absolute;
    right: 10px;
    background: transparent;
    border: 0;
    font-size: 22px;
    top: 5px;
    right: 5px;
  }
  .content-wrapper-main{
    .Dashboard-profile-menu-1{
      padding: 0 8px;
      .profile-dp-add{
        margin: 25px 0;
        img{
          width: 100%;
          max-width: 170px;
          height: auto;
        }
      }
      .profile-menu-list{
          padding: 0 0 0 15px;
          margin: 0;
          list-style: none;
          li{
            margin-bottom: 25px;
            a,span{
              color:#000000;
              font-size: 20px;
              line-height: 22px;
              span{
                padding-right: 20px;
                filter: invert(36%) sepia(34%) saturate(0%) hue-rotate(226deg) brightness(105%) contrast(109%);
                svg{
                  height: 23px;
                  width: 23px;
                }
              }
            }
          }
      }
    }
  }
  .menu-icon{
    padding-right:20px;
    svg{
      path{
      stroke: #b8b8b8;
      }
    }
  }
  .l-swicth{
    font-size:1.2rem;
  }
`;
