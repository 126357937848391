import { createAction } from 'redux-actions';

export const checkVatRequest = createAction('CHECK_VAT_REQUEST');
export const checkVatSuccess = createAction('CHECK_VAT_SUCCESS');
export const checkVatFailure = createAction('CHECK_VAT_FAILURE');

export const checkSlugRequest = createAction('CHECK_SLUG_REQUEST');
export const checkSlugSuccess = createAction('CHECK_SLUG_SUCCESS');
export const checkSlugFailure = createAction('CHECK_SLUG_FAILURE');

export const createParentRequest = createAction('CREATE_PARENT_REQUEST');
export const createParentSuccess = createAction('CREATE_PARENT_SUCCESS');
export const createParentFailure = createAction('CREATE_PARENT_FAILURE');
export const createParentReset = createAction('CREATE_PARENT_RESET');

export const createCompanyRequest = createAction('CREATE_COMPANY_REQUEST');
export const createCompanySuccess = createAction('CREATE_COMPANY_SUCCESS');
export const createCompanyFailure = createAction('CREATE_COMPANY_FAILURE');

export const getSubsidiaryDetailsRequest = createAction('SUBSIDIARY_DETAILS_REQUEST');
export const getSubsidiaryDetailsSuccess = createAction('SUBSIDIARY_DETAILS_SUCCESS');
export const getSubsidiaryDetailsFailure = createAction('SUBSIDIARY_DETAILS_FAILURE');
export const getSubsidiaryDetailsReset = createAction('SUBSIDIARY_DETAILS_RESET');

export const getSubsidiaryDetailsChange = createAction('SUBSIDIARY_DETAILS_CHANGE_SUCCESS');
export const privateSwitchRequest = createAction('PERIVATE_SWITCH_REQUEST');
export const privateSwitchSuccess = createAction('PERIVATE_SWITCH_SUCCESS');

export const createRoleRequest = createAction('CREATE_ROLE_REQUEST');
export const createRoleSuccess = createAction('CREATE_ROLE_SUCCESS');
export const createRoleFailure = createAction('CREATE_ROLE_FAILURE');

export const getRoleDetailsRequest = createAction('GET_ROLE_DETAILS_REQUEST');
export const getRoleDetailsSuccess = createAction('GET_ROLE_DETAILS_SUCCESS');
export const getRoleDetailsFailure = createAction('GET_ROLE_DETAILS_FAILURE');

export const editRoleRequest = createAction('EDIT_ROLE_REQUEST');
export const editRoleSuccess = createAction('EDIT_ROLE_SUCCESS');
export const editRoleFailure = createAction('EDIT_ROLE_FAILURE');

export const deleteRoleRequest = createAction('DELETE_ROLE_REQUEST');
export const deleteRoleSuccess = createAction('DELETE_ROLE_SUCCESS');
export const deleteRoleFailure = createAction('DELETE_ROLE_FAILURE');

export const getRolesRequest = createAction('GET_ROLES_REQUEST');
export const getRolesSuccess = createAction('GET_ROLES_SUCCESS');
export const getRolesFailure = createAction('GET_ROLES_FAILURE');

export const getUserSubsidiaryRequest = createAction('GET_USER_SUBSIDIARY_REQUEST');
export const getUserSubsidiarySuccess = createAction('GET_USER_SUBSIDIARY_SUCCESS');
export const getUserSubsidiaryFailure = createAction('GET_USER_SUBSIDIARY_FAILURE');

export const getSubscriptionPlansRequest = createAction('GET_SUBSCRIPTION_PLANS_REQUEST');
export const getSubscriptionPlansSuccess = createAction('GET_SUBSCRIPTION_PLANS_SUCCESS');
export const getSubscriptionPlansFailure = createAction('GET_SUBSCRIPTION_PLANS_FAILURE');

export const getSubscriptionPlanDetailsRequest = createAction('GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST');
export const getSubscriptionPlanDetailsSuccess = createAction('GET_SUBSCRIPTION_PLAN_DETAILS_SUCCESS');
export const getSubscriptionPlanDetailsFailure = createAction('GET_SUBSCRIPTION_PLAN_DETAILS_FAILURE');

export const getFeaturesRequest = createAction('GET_FEATURES_REQUEST');
export const getFeaturesSuccess = createAction('GET_FEATURES_SUCCESS');
export const getFeaturesFailure = createAction('GET_FEATURES_FAILURE');

export const getPermissionsRequest = createAction('GET_PERMISSIONS_REQUEST');
export const getPermissionsSuccess = createAction('GET_PERMISSIONS_SUCCESS');
export const getPermissionsFailure = createAction('GET_PERMISSIONS_FAILURE');

export const createSubscriptionRequest = createAction('CREATE_SUBSCRIPTION_REQUEST');
export const createSubscriptionSuccess = createAction('CREATE_SUBSCRIPTION_SUCCESS');
export const createSubscriptionFailure = createAction('CREATE_SUBSCRIPTION_FAILURE');
export const createSubscriptionReset = createAction('CREATE_SUBSCRIPTION_RESET');
export const createSubscriptionErrorMessage = createAction('CREATE_SUBSCRIPTION_ERROR_MESSAGE');

export const getSubsidiarySubscriptionStatusRequest = createAction('GET_SUBSIDIARY_SUBSCRIPTION_STATUS_REQUEST');
export const getSubsidiarySubscriptionStatusSuccess = createAction('GET_SUBSIDIARY_SUBSCRIPTION_STATUS_SUCCESS');
export const getSubsidiarySubscriptionStatusFailure = createAction('GET_SUBSIDIARY_SUBSCRIPTION_STATUS_FAILURE');

export const getMembersRequest = createAction('GET_MEMBERS_REQUEST');
export const getMembersSuccess = createAction('GET_MEMBERS_SUCCESS');
export const getMembersFailure = createAction('GET_MEMBERS_FAILURE');

export const editSubsidiaryMemberRequest = createAction('EDIT_SUBSIDIARY_MEMBERS_REQUEST');
export const editSubsidiaryMemberSuccess = createAction('EDIT_SUBSIDIARY_MEMBERS_SUCCESS');
export const editSubsidiaryMemberFailure = createAction('EDIT_SUBSIDIARY_MEMBERS_FAILURE');
export const editSubsidiaryMemberReset = createAction('EDIT_SUBSIDIARY_MEMBERS_RESET');

export const updateSubsidiaryMemberRolesRequest = createAction('UPDATE_SUBSIDIARY_ROLE_REQUEST');
export const updateSubsidiaryMemberRolesSuccess = createAction('UPDATE_SUBSIDIARY_ROLE_SUCCESS');
export const updateSubsidiaryMemberRolesFailure = createAction('UPDATE_SUBSIDIARY_ROLE_FAILURE');

export const changeStatusSubsidiaryMemberRequest = createAction('CHANGE_STATUS_SUBSIDIARY_MEMBERS_REQUEST');
export const changeStatusSubsidiaryMemberSuccess = createAction('CHANGE_STATUS_SUBSIDIARY_MEMBERS_SUCCESS');
export const changeStatusSubsidiaryMemberFailure = createAction('CHANGE_STATUS_SUBSIDIARY_MEMBERS_FAILURE');
export const changeStatusSubsidiaryMemberReset = createAction('CHANGE_STATUS_SUBSIDIARY_MEMBERS_RESET');

export const deleteSubsidiaryMemberRequest = createAction('DELETE_SUBSIDIARY_MEMBERS_REQUEST');
export const deleteSubsidiaryMemberSuccess = createAction('DELETE_SUBSIDIARY_MEMBERS_SUCCESS');
export const deleteSubsidiaryMemberFailure = createAction('DELETE_SUBSIDIARY_MEMBERS_FAILURE');
export const deleteSubsidiaryMemberReset = createAction('DELETE_SUBSIDIARY_MEMBERS_RESET');

export const getSuggestedMembersRequest = createAction('GET_SUGGESTED_MEMBERS_REQUEST');
export const getSuggestedMembersSuccess = createAction('GET_SUGGESTED_MEMBERS_SUCCESS');
export const getSuggestedMembersFailure = createAction('GET_SUGGESTED_MEMBERS_FAILURE');

export const addSubsidiaryMembersRequest = createAction('ADD_SUBSIDIARY_MEMBERS_REQUEST');
export const addSubsidiaryMembersSuccess = createAction('ADD_SUBSIDIARY_MEMBERS_SUCCESS');
export const addSubsidiaryMembersFailure = createAction('ADD_SUBSIDIARY_MEMBERS_FAILURE');

export const getDepartmentsRequest = createAction('GET_DEPARTMENTS_REQUEST');
export const getDepartmentsSuccess = createAction('GET_DEPARTMENTS_SUCCESS');
export const getDepartmentsFailure = createAction('GET_DEPARTMENTS_FAILURE');

export const getSubsidiaryDepartmentsRequest = createAction('GET_SUBSIDIARY_DEPARTMENTS_REQUEST');
export const getSubsidiaryDepartmentsSuccess = createAction('GET_SUBSIDIARY_DEPARTMENTS_SUCCESS');
export const getSubsidiaryDepartmentsFailure = createAction('GET_SUBSIDIARY_DEPARTMENTS_FAILURE');

export const addSubsidiaryDepartmentsRequest = createAction('ADD_SUBSIDIARY_DEPARTMENTS_REQUEST');
export const addSubsidiaryDepartmentsSuccess = createAction('ADD_SUBSIDIARY_DEPARTMENTS_SUCCESS');
export const addSubsidiaryDepartmentsFailure = createAction('ADD_SUBSIDIARY_DEPARTMENTS_FAILURE');

export const deleteSubsidiaryDepartmentsRequest = createAction('DELETE_SUBSIDIARY_DEPARTMENTS_REQUEST');
export const deleteSubsidiaryDepartmentsSuccess = createAction('DELETE_SUBSIDIARY_DEPARTMENTS_SUCCESS');
export const deleteSubsidiaryDepartmentsFailure = createAction('DELETE_SUBSIDIARY_DEPARTMENTS_FAILURE');

export const getDepartmentSchedulesRequest = createAction('GET_DEPARTMENT_SCHEDULES_REQUEST');
export const getDepartmentSchedulesSuccess = createAction('GET_DEPARTMENT_SCHEDULES_SUCCESS');
export const getDepartmentSchedulesFailure = createAction('GET_DEPARTMENT_SCHEDULE_FAILURE');

export const addDepartmentSchedulesRequest = createAction('ADD_DEPARTMENT_SCHEDULES_REQUEST');
export const addDepartmentSchedulesSuccess = createAction('ADD_DEPARTMENT_SCHEDULES_SUCCESS');
export const addDepartmentSchedulesFailure = createAction('ADD_DEPARTMENT_SCHEDULE_FAILURE');

export const updateSubsidiaryPrivacyRequest = createAction('UPDATE_SUBSIDIARY_PRIVACY_REQUEST');
export const updateSubsidiaryPrivacySuccess = createAction('UPDATE_SUBSIDIARY_PRIVACY_SUCCESS');
export const updateSubsidiaryPrivacyFailure = createAction('UPDATE_SUBSIDIARY_PRIVACY_FAILURE');

export const getAllCardsRequest = createAction('ALL_CARDS_REQUEST');
export const getAllCardsSuccess = createAction('ALL_CARDS_SUCCESS');
export const getAllCardsFailure = createAction('ALL_CARDS_FAILURE');

export const addCardRequest = createAction('ADD_CARD_REQUEST');
export const addCardSuccess = createAction('ADD_CARD_SUCCESS');
export const addCardFailure = createAction('ADD_CARD_FAILURE');
export const addCardReset = createAction('ADD_CARD_RESET');

export const deleteCardRequest = createAction('DELETE_CARD_REQUEST');
export const deleteCardSuccess = createAction('DELETE_CARD_SUCCESS');
export const deleteCardFailure = createAction('DELETE_CARD_FAILURE');
export const deleteCardReset = createAction('DELETE_CARD_RESET');

export const updateCardRequest = createAction('UPDATE_CARD_REQUEST');
export const updateCardSuccess = createAction('UPDATE_CARD_SUCCESS');
export const updateCardFailure = createAction('UPDATE_CARD_FAILURE');
export const updateCardReset = createAction('UPDATE_CARD_RESET');

export const createStripeCustomerRequest = createAction('CREATE_STRIPE_CUSTOMER_REQUEST');
export const createStripeCustomerSuccess = createAction('CREATE_STRIPE_CUSTOMER_SUCCESS');
export const createStripeCustomerFailure = createAction('CREATE_STRIPE_CUSTOMER_FAILURE');

export const getCheckoutTotalRequest = createAction('CHECKOUT_TOTAL_REQUEST');
export const getCheckoutTotalSuccess = createAction('CHECKOUT_TOTAL_SUCCESS');
export const getCheckoutTotalFailure = createAction('CHECKOUT_TOTAL_FAILURE');

export const addAppointmentPeriodRequest = createAction('ADD_APPOINTMENT_PERIOD_REQUEST');
export const addAppointmentPeriodSuccess = createAction('ADD_APPOINTMENT_PERIOD_SUCCESS');
export const addAppointmentPeriodFailure = createAction('ADD_APPOINTMENT_PERIOD_FAILURE');

export const getDepartmentAvailabilityRequest = createAction('GET_DEPARTMENT_AVAILABILITY_REQUEST');
export const getDepartmentAvailabilitySuccess = createAction('GET_DEPARTMENT_AVAILABILITY_SUCCESS');
export const getDepartmentAvailabilityFailure = createAction('GET_DEPARTMENT_AVAILABILITY_FAILURE');

export const deleteSubsidiaryRequest = createAction('DELETE_SUBSIDIARY_REQUEST');
export const deleteSubsidiarySuccess = createAction('DELETE_SUBSIDIARY_SUCCESS');
export const deleteSubsidiaryFailure = createAction('DELETE_SUBSIDIARY_FAILURE');

export const getUserSubsidiariesRequest = createAction('GET_USER_SUBSIDIARIES_REQUEST');
export const getUserSubsidiariesSuccess = createAction('GET_USER_SUBSIDIARIES_SUCCESS');
export const getUserSubsidiariesFailure = createAction('GET_USER_SUBSIDIARIES_FAILURE');

export const updateVatRequest = createAction('UPDATE_VAT_REQUEST');
export const updateVatSuccess = createAction('UPDATE_VAT_SUCCESS');
export const updateVatFailure = createAction('UPDATE_VAT_FAILURE');

export const editSubsidiaryRequest = createAction('EDIT_SUBSIDIARY_REQUEST');
export const editSubsidiarySuccess = createAction('EDIT_SUBSIDIARY_SUCCESS');
export const editSubsidiaryReset = createAction('EDIT_SUBSIDIARY_RESET');
export const editSubsidiaryFailure = createAction('EDIT_SUBSIDIARY_FAILURE');


export const getInvoiceRequest = createAction('GET_INVOICE_REQUEST');
export const getInvoiceSuccess = createAction('GET_INVOICE_SUCCESS');
export const getInvoiceFailure = createAction('GET_INVOICE_FAILURE');

export const getInvoiceDetailRequest = createAction('GET_INVOICE_DETAIL_REQUEST');
export const getInvoiceDetailSuccess = createAction('GET_INVOICE_DETAIL_SUCCESS');
export const getInvoiceDetailFailure = createAction('GET_INVOICE_DETAIL_FAILURE');
  
export const addSubsidiaryLogoRequest = createAction('CREATE_SUBSIDIARY_LOGO_REQUEST');
export const addSubsidiaryLogoSuccess = createAction('CREATE_SUBSIDIARY_LOGO_SUCCESS');
export const addSubsidiaryLogoReset = createAction('CREATE_SUBSIDIARY_LOGO_RESET');
export const addSubsidiaryLogoFailure = createAction('CREATE_SUBSIDIARY_LOGO_FAILURE');

export const handleInvitationUserRequest = createAction('HANDLE_INVITATION_USER_REQUEST');
export const handleInvitationUserSuccess = createAction('HANDLE_INVITATION_USER_SUCCESS');
export const handleInvitationUserFailure = createAction('HANDLE_INVITATION_USER_FAILURE');

export const downloadPdfRequest = createAction('DOWNLOAD_PDF_REQUEST');
export const downloadPdfSuccess = createAction('DOWNLOAD_PDF_SUCCESS');
export const downloadPdfFailure = createAction('DOWNLOAD_PDF_FAILURE');
 
export const reactivateSubscriptionRequest = createAction('REACTIVE_SUBSCRIPTION_REQUEST');
export const reactivateSubscriptionSuccess = createAction('REACTIVE_SUBSCRIPTION_SUCCESS');
export const reactivateSubscriptionFailure = createAction('REACTIVE_SUBSCRIPTION_FAILURE');
export const reactivateSubscriptionReset = createAction('REACTIVE_SUBSCRIPTION_RESET');

export const cancelStripeScheduleRequest = createAction('CANCEL_STREIPE_SCHEDULE_REQUEST');
export const cancelStripeScheduleSuccess = createAction('CANCEL_STREIPE_SCHEDULE_SUCCESS');
export const cancelStripeScheduleFailure = createAction('CANCEL_STREIPE_SCHEDULE_FAILURE');
export const cancelStripeScheduleReset = createAction('CANCEL_STREIPE_SCHEDULE_RESET');

export const previewPriceRequest = createAction('PREVIEW_PRICE_REQUEST');
export const previewPriceSuccess = createAction('PREVIEW_PRICE_SUCCESS');
export const previewPriceFailure = createAction('PREVIEW_PRICE_FAILURE');
export const previewPriceReset = createAction('PREVIEW_PRICE_RESET');

export const changePlanRequest = createAction('CHANGE_PLAN_REQUEST')
export const changePlanSuccess = createAction('CHANGE_PLAN_SUCCESS')
export const changePlanFailure = createAction('CHANGE_PLAN_FAILURE')
export const changePlanReset = createAction('CHANGE_PLAN_Reset')

export const subscriptionPlanDetailRequest = createAction('SUBSCRIPTION_PLAN_DETAIL_REQUEST')
export const subscriptionPlanDetailSuccess = createAction('SUBSCRIPTION_PLAN_DETAIL_SUCCESS')
export const subscriptionPlanDetailFailure = createAction('SUBSCRIPTION_PLAN_DETAIL_FAILURE')
export const subscriptionPlanDetailReset = createAction('SUBSCRIPTION_PLAN_DETAIL_RESET')

export const cancelUpcomingSubscriptionRequest  = createAction('CANCEL_UPCOMING_SUBSCRIPTION_REQUEST')
export const cancelUpcomingSubscriptionSuccess  = createAction('CANCEL_UPCOMING_SUBSCRIPTION_SUCCESS')
export const cancelUpcomingSubscriptionFailure  = createAction('CANCEL_UPCOMING_SUBSCRIPTION_FAILURE')
export const cancelUpcomingSubscriptionReset  = createAction('CANCEL_UPCOMING_SUBSCRIPTION_RESET')

export const getNotificationsRequest = createAction('GET_NOTIFICATIONS_REQUEST');
export const getNotificationsSuccess = createAction('GET_NOTIFICATIONS_SUCCESS');
export const getNotificationsSuccessCartobike = createAction('GET_NOTIFICATIONS_SUCCESS_CARTOBIKE');
export const getNotificationsSuccessInvitation = createAction('GET_NOTIFICATIONS_SUCCESS_INVITATION');
export const getNotificationsFailure = createAction('GET_NOTIFICATIONS_FAILURE');

export const readNotificationRequest = createAction('READ_NOTIFICATION_REQUEST');
export const readNotificationSuccess = createAction('READ_NOTIFICATION_SUCCESS');
export const readNotificationFailure = createAction('READ_NOTIFICATION_FAILURE');

export const readAllNotificationsRequest = createAction('READ_ALL_NOTIFICATIONS_REQUEST');
export const readAllNotificationsSuccess = createAction('READ_ALL_NOTIFICATIONS_SUCCESS');
export const readAllNotificationsFailure = createAction('READ_ALL_NOTIFICATIONS_FAILURE');

export const deleteNotificationRequest = createAction('DELETE_NOTIFICATION_REQUEST');
export const deleteNotificationSuccess = createAction('DELETE_NOTIFICATION_SUCCESS');
export const deleteNotificationFailure = createAction('DELETE_NOTIFICATION_FAILURE');

export const respondNotificationRequest = createAction('RESPOND_NOTIFICATION_REQUEST');
export const respondNotificationSuccess = createAction('RESPOND_NOTIFICATION_SUCCESS');
export const respondNotificationFailure = createAction('RESPOND_NOTIFICATION_FAILURE');

export const openNotificationsRequest = createAction('OPEN_NOTIFICATIONS_REQUEST');
export const openNotificationsSuccess = createAction('OPEN_NOTIFICATIONS_SUCCESS');
export const openNotificationsFailure = createAction('OPEN_NOTIFICATIONS_FAILURE');

export const getNotificationCountRequest = createAction('GET_NOTIFICATION_COUNT_REQUEST');
export const getNotificationCountSuccess = createAction('GET_NOTIFICATION_COUNT_SUCCESS');
export const getNotificationCountFailure = createAction('GET_NOTIFICATION_COUNT_FAILURE');

export const createIntentRequest = createAction('CREATE_INTENT_REQUEST');
export const createIntentSuccess = createAction('CREATE_INTENT_SUCCESS');
export const createIntentFailure = createAction('CREATE_INTENT_FAILURE');

export const storeNewCardRequest = createAction('STORE_NEW_CARD_REQUEST');
export const storeNewCardSuccess = createAction('STORE_NEW_CARD_SUCCESS');
export const storeNewCardFailure = createAction('STORE_NEW_CARD_FAILURE');
export const storeNewCardReset = createAction('STORE_NEW_CARD_RESET');

export const showLoaderRequest = createAction('SHOW_LOADER_REQUEST');
export const hideLoaderRequest = createAction('HIDE_LOADER_REQUEST');