import useGaTracker from 'app/Hooks/useGaTracker';
import React from 'react'

const GoogleAnalytics = ({ isAgree }) => {
    /* Google Analytics Hook*/
    useGaTracker(isAgree);

    return (
        <>
        </>
    )
}

export default GoogleAnalytics