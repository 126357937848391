import styled from '@emotion/styled';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { SvgAddVehicleIcon, SvgChatIcon, SvgSelectedHomeIcon } from '../svgicons/svg';
import { getSubsidiaryStored } from 'config/variables';
import { color, colorNew } from 'styles/StyleConstants';
import { RootStateOrAny, connect, useDispatch, useSelector } from 'react-redux';
import { SvgHomeIcon } from 'app/components/svgicons/svgNew';
import loginIcon from 'app/AssetNew/Images/login-png.png'
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import ProfileImage from 'app/Component2/GlobalComponent/ProfileImage';
import Notification from 'app/Component2/GlobalComponent/Notification';
import ChatIcon from 'app/pages/Chat/Component/ChildComponents/ChatIcon';
import { Button, Tooltip } from '@mui/material';
import { setCreateAdModalOpen } from 'store/actions/public-actions';


const MobileNavBottom = (props: any) => {
  const { t } = useTranslation();
  const { loggedIn } = useSelector((state: any) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch()
  return (
    <>
      <MobileNavWrapper className="footer-nav">
        <li>
          {/* Home Icon */}
          <NavLink
            activeClassName="active"
            to={routes.vehicleRoutes.adsHome}
            onClick={() => window.scrollTo(0, 0)}
          >
            {location.pathname !== '/' ? <SvgHomeIcon /> : <SvgSelectedHomeIcon />}
          </NavLink>
        </li>
        <li>
          {/*Chat Icon*/}
          <ChatIcon ads={'bottom-navBar'} />
        </li>
        <li>
          {/* Add vehicle Button */}
          <Button onClick={() => { dispatch(setCreateAdModalOpen(true)) }} >
            <span><SvgAddVehicleIcon /></span>
          </Button>
        </li>
        <li>
          {/*Notification Button*/}
          <Notification />
        </li>
        <li className="active">
          {/* Profile of User/subsidiary loggedIn */}
          {loggedIn ?
            <NavLink activeClassName="active" to={getSubsidiaryStored() == null ? routes.userRoutes.myProfile : routes.companyRoutes.companyProfile}>
              <span>
                <ProfileImage classes="image-url round-circle" />
              </span>
            </NavLink>
            :
            <NavLink activeClassName="active" to={routes.authRoutes.login}>
              <span>
                <Tooltip title={t(translations.authScreens.LOGIN)}>

                  <img src={loginIcon} width='25' height="25" />
                </Tooltip>
                {/* <h4>{t(translations.authScreens.LOGIN)}</h4> */}
              </span>
            </NavLink>
          }
        </li>
      </MobileNavWrapper >
    </>
  );
};
const MobileNavWrapper = styled.ul`
  &.footer-nav {
    display: flex;
    align-items: center;
    list-style-type: none;
    box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.06);
    position: fixed;
    bottom: 0;
    background: ${colorNew.colorWhite};
    z-index: 12;
    width: 100%;
    padding: 14px 0;
    margin: 0;
    left:0;
    /* position:relative; */
  }
  &.footer-nav li span.icon-dot {
            background: ${colorNew.colorRed};            
            border-radius: 6px;
            display: inline-block;
            position: absolute;
            z-index: 99999999;
            width: 10px;
            height: 10px;
            /* left:220px; */
  }
  &.footer-nav li {
    display: inline-block;
    width: 33.3333%;
    text-align: center;
}
&.footer-nav li a h4 {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: ${colorNew.colorBlack};
    margin-top: 9px;
}

  .footer-nav li svg {
    color:${colorNew.colorWhite};
  }
  &.footer-nav li.plus_footer svg {
    background: #6579F4;
    height: 70px;
    width: 70px;
    border-radius: 10%;
    padding: 23px;
    position: absolute;
    left: 0;
    top: -35px;
    right: 0;
    margin: 0 auto;
    color: #fff;
      path{
        stroke: ${colorNew.colorWhite};
    }
    @media(max-width:991px){
      border: 2px solid white;
  } 
}
  &.footer-nav li a.active i {
    color: #7287ee;
  }
  &.footer-nav li a.active{
    svg path{
      /* stroke: ${color.primaryColor}; */
    }
  }
  .image-url{
    width: 30px;
        min-width: 30px;
        max-width: 30px;
        height: 30px;
        min-height: 30px;
        max-height: 30px;
        bottom: 0px;
        right: -5px;
        border-radius: 100%;
        object-fit: cover;
  }
`;

export default connect((state: any) => ({ ...state }), {
})(MobileNavBottom);

