import { handleActions } from 'redux-actions';
import { onlineUsersFailure, onlineUsersRequest, onlineUsersSuccess } from 'store/actions/onlineUser-actions';

const initialState = {
  onlineUserData: [],
}

const onlineUsers = handleActions({

  // Get online users
  [onlineUsersRequest]: (state, { payload }) => (
    {
      ...state,
    }),
  [onlineUsersSuccess]: (state, { payload }) => (
    {
      ...state,
      onlineUserData: payload,
    }),
  [onlineUsersFailure]: (state, { payload }) => (
    {
      ...state,
      onlineUserData: payload,
    }),
}, initialState,)

export default onlineUsers;