import { Box, CircularProgress } from '@mui/material'
import { getParamQueries } from 'config/appConfig'
import { urlParamInterface } from 'config/interface'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { FilterWrapper } from '../style'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { getProductTypeId, productTypes, secToHourMinun2, vehicles } from 'config/variables'
import { SvgClose } from 'app/components/svgicons/svg'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkKey, removeIDfromSlug, vehicleTypeStatus, withPicture } from '../filterConfig'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { replaceSpaceAndAmpersand } from 'config/utils'
import { localstorageKey } from 'config/constants'

const doModelsExist = localStorage.getItem('modelList') ? true : false

const AppliedFilters = () => {

    const location = useLocation()
    const { t } = useTranslation()
    const history = useHistory()

    const { vehicle, brand_id, model_id, country_id }: urlParamInterface = useParams();

    const { modelRequested, modelList, loadingForce, loading } = useSelector((state: RootStateOrAny) => state.vehicle)


    const [appliedFilter, _appliedFilter] = useState<Object>({})
    const [filterData, _filterData] = useState<Object>({})
    const [countries, _countries] = useState<Array<Object>>([])
    const [isSmallDevice] = useDeviceType()


    useEffect(() => {
        if (vehicle == vehicles.step) {
            if (localStorage.getItem(localstorageKey.stepFilterdata)) {
                _filterData(JSON.parse(localStorage.getItem(localstorageKey.stepFilterdata) || ''))
            }
            return
        }
        if (vehicle == vehicles.bike) {
            if (localStorage.getItem(localstorageKey.bikeFilterdata)) {
                _filterData(JSON.parse(localStorage.getItem(localstorageKey.bikeFilterdata) || ''))
            }
            return
        }
        if (vehicle == vehicles.motorcycles) {
            if (localStorage.getItem(localstorageKey.motorCycleFilterdata)) {
                _filterData(JSON.parse(localStorage.getItem(localstorageKey.motorCycleFilterdata) || ''))
            }
            return
        }
        if (vehicle == vehicles.car) {
            if (localStorage.getItem(localstorageKey.carFilterdata)) {
                _filterData(JSON.parse(localStorage.getItem(localstorageKey.carFilterdata) || ''))
            }
            return
        }

    }, [vehicle, loadingForce])

    useEffect(() => {
        if (localStorage.getItem('countries')) {
            _countries(JSON.parse(localStorage.getItem('countries') || ''))
        }
    }, [loadingForce])

    useEffect(() => {
        let payload = {};

        // / If all params are present in the url /
        if (brand_id && model_id && country_id) {
            payload['brand_slug'] = brand_id
            payload['model_slug'] = model_id
            payload['country_slug'] = country_id
        }

        // / if two params present in the url the first url should be considered as brand and second param could be country or model /
        if (brand_id && model_id) {
            payload['brand_slug'] = brand_id
            /* To ensure that second param is country, we'll check this in country array
                If found it in array then mark it as country else it's model */
            if (countries && countries.some((country) => country.slug === model_id)) {
                payload['country_slug'] = model_id
            } else {
                payload['model_slug'] = model_id
            }
        }

        // / if single params present in the url then we have to check between country and brand /

        if (brand_id) {
            /* To ensure that second param is country, we'll check this in country array
                If found it in array then mark it as country else it's brand */
            if (countries && countries.some((country) => country.slug === brand_id)) {
                payload['country_slug'] = brand_id
            } else {
                payload['brand_slug'] = brand_id
            }
        }

        payload = {
            ...payload,
            ...getParamQueries(location.search)
        }
        _appliedFilter(payload)
    }, [location.search, brand_id, model_id, country_id, countries])

    const productType = useMemo(() => getProductTypeId(vehicle), [vehicle])


    // Generates translatable Filter name based on query param keys 
    const getFilterName = (key, value) => {
        if (key === "brand_slug") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_BRAND)
        }
        if (key === "model_slug") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MODEL)
        }
        if (key === "country_slug") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_COUNTRY)
        }
        if (key == "brand_id") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_BRAND)
        }
        if (key == "model_id") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MODEL)
        }
        if (key == "registration_start") {
            return `${t(translations.ads.YEAR)} ${t(translations.FILTER_COMPONENT.FROM)}`
        }
        if (key == "registration_end") {
            return `${t(translations.ads.YEAR)} ${t(translations.FILTER_COMPONENT.TO)}`
        }
        if (key == "outside_color_id") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_COLOR)
        }
        if (key == "body_vehicle_id") {
            return vehicle == vehicles.car ? t(translations.FILTER_COMPONENT.FILTER_COMPONENT_BODY) : vehicle == vehicles.motorcycles ? t(translations.FILTER_COMPONENT.TYPE_OF_MOTOR_CYCLE) : t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FRAME)
        }
        if (key == "vehicle_mileage_start") {
            return `${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_KM)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FROM)}`
        }
        if (key == "vehicle_mileage_end") {
            return `${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_KM)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_TO)}`
        }
        if (key == "gearbox_id") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_TRANSMISSION)
        }
        if (key == "fuel_id") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FUEL)
        }
        if (key == "frame_size_id") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FRAMESIZE)
        }
        if (key == "price_start") {
            return `${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_PRICE)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FROM)}`
        }
        if (key == "price_end") {
            return `${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_PRICE)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_TO)}`
        }
        if (key == "deductible_vat") {
            return t(translations.ads.VAT_REFUNDABLE)
        }
        if (key == "power_cv_start") {
            return `${t(translations.FILTER_COMPONENT.POWER)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_CV)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FROM)}`
        }
        if (key == "power_cv_end") {
            return `${t(translations.FILTER_COMPONENT.POWER)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_CV)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_TO)}`
        }
        if (key == "power_kw_start") {
            return `${t(translations.FILTER_COMPONENT.POWER)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_KW)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FROM)}`
        }
        if (key == "power_kw_end") {
            return `${t(translations.FILTER_COMPONENT.POWER)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_KW)} ${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_TO)}`
        }
        if (key == "euro_standard_level") {
            return t(translations.ads.EURO_STANDARD)
        }
        if (key == "battery_range_max") {
            if (vehicle == vehicles.car) {
                return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_DRIVING_RANGE)
            }
            return t(translations.ads.AUTONOMY)
        }
        if (key == "charge_duration_home_80_end") {
            return t(translations.FILTER_COMPONENT.HOME_CHARGING_TIME)
        }
        if (key == "charge_duration_fast_80_end") {
            return t(translations.FILTER_COMPONENT.FAST_CHARGING_TIME)
        }
        if (key == "is_electric") {
            return (vehicle == vehicles.step) ? t(translations.FILTER_COMPONENT.FILTER_COMPONENT_ELECTRIC_SCOOTER) : ((vehicle == vehicles.bike) && t(translations.FILTER_COMPONENT.FILTER_COMPONENT_ELECTRIC_BIKE))
        }
        if (key == "top_speed") {
            return t(translations.ads.TOP_SPEED_KM_H)
        }
        if (key == "motor_power_watt") {
            return t(translations.webTexts.ENGINE_POWER)
        }
        if (key == "owner_number") {
            return t(translations.FILTER_COMPONENT.PREVIOUS_OWNERS)
        }
        if (key == "maintenance_history") {
            return t(translations.FILTER_COMPONENT.SERVICE_HISTORY)
        }
        if (key == "seats_start") {
            return `${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_NB_SEATS)} ${t(translations.FILTER_COMPONENT.MINIMUM)}`
        }
        if (key == "seats_end") {
            return `${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_NB_SEATS)} ${t(translations.FILTER_COMPONENT.MAXIMUM)}`
        }
        if (key == "interior_id") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_UPHOLSTERY)
        }
        if (key == "equipment_id") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_EQUIPMENTS)
        }
        // if (key == "dealer_seller") {
        //     return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_DEALER)
        // }
        // if (key == "private_seller") {
        //     return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_PRIVATE)
        // }
        if (key == "warranty_start") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_WARRANTY)
        }
        if (key == "with_media") {
            return t(translations.FILTER_COMPONENT.ONLY_WITH_PICTURE)
        }
        // if (key == "new_vehicle") {
        //     if (value == vehicleTypeStatus.USED) {
        //         return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_USED)
        //     }
        //     return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_NEW)
        // }
        if (key == "is_online") {
            return `${t(translations.FILTER_COMPONENT.FILTER_AD_TYPE)}`
        }
        if (key == "order") {
            return `${t(translations.FILTER_COMPONENT.FILTER_ORDER_TYPE)}`
        }
        if (key == "page") {
            return
        }
        if (key == "keyword") {
            return t(translations.FILTER_COMPONENT.KEYWORD);
        }
        return ''
    }

    // return name if filter is based in ids
    const getNamebasedonId = (array, id) => {
        const existence = array.findIndex((value) => value.id == id)
        if (existence !== -1) {
            return array[existence].name
        }
    }

    // return name if filter is based in ids
    const getModelNamebasedonId = (array, id) => {
        const existence = array.findIndex((value) => value.id == id)
        if (existence !== -1) {
            return array[existence].model_name
        }
    }

    // return name on;y for equiments
    const getNameofEquipment = (array, id) => {
        const existence = array.findIndex((value) => value.id == id)
        if (existence !== -1) {
            return array[existence].option_name
        }
    }

    // return name if filter is based in slugs
    const getNamebasedonSlug = (array, slug, key) => {
        if (array && array.length > 0) {
            const existence = array.findIndex((value) => removeIDfromSlug(value.slug) == removeIDfromSlug(slug) || removeIDfromSlug(value.slug) == slug)
            if (existence !== -1) {
                return array[existence][key]
            }
            return slug.replaceAll("-", " ")
        }
        if (!loading && (modelRequested || doModelsExist) && key === "model_name") {
            return slug.replaceAll("-", " ")
        }
        return <CircularProgress size={'10px'} sx={{ color: 'rgb(97, 122, 247) !important' }} />
    }



    // Generates Named value based on id and slugs for filters
    const getFilterValue = (key, value) => {

        if (key === "brand_slug") {
            return getNamebasedonSlug(filterData?.brandList?.items ?? [], value, "name")
        }
        if (key === "model_slug") {
            const modelList = JSON.parse(localStorage.getItem('modelList') || '[]')
            return getNamebasedonSlug(modelList, value, "model_name")
        }
        if (key == "brand_id" && filterData?.brandList?.items) {
            return getNamebasedonId(filterData.brandList.items, value)
        }
        if (key == "model_id" && modelList) {
            return getModelNamebasedonId(modelList, value)
        }
        if (key == "body_vehicle_id" && filterData?.bodiesList?.items) {
            return getNamebasedonId(filterData.bodiesList.items, value)
        }
        if (key == "outside_color_id" && filterData?.colorList?.items) {
            return getNamebasedonId(filterData.colorList.items, value)
        }
        if (key == "gearbox_id" && filterData?.gearList?.items) {
            return getNamebasedonId(filterData.gearList.items, value)
        }
        if (key == "fuel_id" && filterData?.fuelList?.items) {
            return getNamebasedonId(filterData.fuelList.items, value)
        }
        if (key == "country_slug") {
            const filterValue = value.charAt(0).toUpperCase() + value.slice(1)
            return filterValue.replaceAll("-", " ")
        }
        if (key == "frame_size_id" && filterData?.frameList?.items) {
            return getNamebasedonId(filterData.frameList.items, value)
        }
        if (key == "euro_standard_level" && filterData?.euroList?.items) {
            return getNamebasedonId(filterData.euroList.items, value)
        }
        if (key == "equipment_id" && filterData?.equipmentList && filterData?.equipmentList?.equipments) {
            return getNameofEquipment(filterData.equipmentList.equipments, value)
        }
        if (key == "interior_id" && filterData?.interiorList?.items) {
            return getNamebasedonId(filterData.interiorList.items, value)
        }
        if (key == "deductible_vat") {
            return t(translations.ads.YES)
        }
        if (key == "maintenance_history") {
            return t(translations.ads.YES)
        }
        if (key == "is_electric") {
            return t(translations.ads.YES)
        }
        if (key == "with_media") {
            if (value == withPicture.true) {
                return t(translations.ads.YES)
            }
            return t(translations.ads.NO)
        }

        if (key == "new_vehicle") {
            if (value == vehicleTypeStatus.USED) {
                return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_USED)
            }
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_NEW)
        }
        if (key == "dealer_seller") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_DEALER)
        }
        if (key == "private_seller") {
            return t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_PRIVATE)
        }
        if (key == "price_start") {
            return value + ' €'
        }
        if (key == "price_end") {
            return value + ' €'
        }
        if (key == "vehicle_mileage_start") {
            return value + ' km'
        }
        if (key == "vehicle_mileage_end") {
            return value + ' km'
        }
        if (key == "power_kw_start") {
            return value + ' kw'
        }
        if (key == "power_kw_end") {
            return value + ' kw'
        }
        if (key == "power_cv_start") {
            return value + ' CV'
        }
        if (key == "power_cv_end") {
            return value + ' CV'
        }
        if (key == "battery_range_max") {
            return value + ' km'
        }
        if (key == "charge_duration_fast_80_end" || key == "charge_duration_home_80_end") {
            return secToHourMinun2(value) + ' hr'
        }
        if (key == "warranty_start") {
            return `${value} ${value == 1 ? t(translations.ads.MONTH) : t(translations.FILTER_COMPONENT.UNTIL_MONTH)}`
        }
        if (key == "is_online") {
            return `${value == 1 ? t(translations.FILTER_COMPONENT.FILTER_ONLINE_ADS) : t(translations.FILTER_COMPONENT.FILTER_OFFLINE_ADS)}`
        }
        if (key == "page") {
            return value;
        }
        if (key == "keyword") {
            return replaceSpaceAndAmpersand(value)
        }
        if (key == "page") {
            return value;
        }

        return value
    }

    const removeFilter = (filterKey, filterValue) => {

        const route = location.pathname
        const str = location.search
        // removes slugs from url
        if (filterKey == 'brand_slug' || filterKey == 'model_slug' || filterKey == 'country_slug') {
            if ((checkKey(filterValue, productType, 'brand')?.name == 'brand_slug')) {
                if (model_id && checkKey(model_id, productType, 'model')?.name == 'model_slug') {
                    const newRoute = route.replace(`/${filterValue}/${model_id}`, "")
                    history.replace({
                        pathname: newRoute,
                        search: str
                    })
                    return
                }
            }
            const newRoute = route.replace(`/${filterValue}`, "")
            history.replace({
                pathname: newRoute,
                search: str
            })
            return
        }


        // removes query params from url
        const payload = getParamQueries(location.search)
        if (payload[filterKey]) {
            // runs in case multiple values are selected for filter
            if (payload[filterKey].includes(",")) {
                let values = payload[filterKey].split(",")
                let existence = values.findIndex(value => value == filterValue)
                if (existence !== -1) {
                    values.splice(existence, 1)
                }
                let newQuery = ''
                if (values.length > 0) {
                    newQuery = str.replace(`${filterKey}=${payload[filterKey]}`, `${filterKey}=${values.toString()}`)
                } else {
                    newQuery = str.replace(`${filterKey}=${payload[filterKey]}`, "")
                }
                history.push({
                    pathname: route,
                    search: newQuery
                })
            }
            // runs in case single value is selected
            else {
                let newQuery = str.replace(`${filterKey}=${filterValue}`, "")
                if (filterKey == 'is_electric') {
                    if (newQuery.includes('battery_range_max')) {
                        let value = getValue(newQuery, 'battery_range_max')
                        newQuery = newQuery.replace(`&battery_range_max${value}`, "")
                    }
                    if (newQuery.includes('top_speed')) {
                        let value = getValue(newQuery, 'top_speed')
                        newQuery = newQuery.replace(`&top_speed${value}`, "")
                    }
                    if (newQuery.includes('motor_power_watt')) {
                        let value = getValue(newQuery, 'motor_power_watt')
                        newQuery = newQuery.replace(`&motor_power_watt${value}`, "")
                    }
                }
                history.push({
                    pathname: route,
                    search: newQuery
                })
            }
        }
    }

    const getValue = (str, key) => {
        let newStr = str.split(key)[1]
        if (newStr.includes('&')) {
            newStr = newStr.split('&')[0]
            return newStr
        }
        return newStr
    }

    return (
        <FilterWrapper style={{ width: (isSmallDevice ? 'calc(90% - 10px)' : 'calc(100% - 36px)') }}>
            <Box className={`flexRow scrollBar ${Object.keys(appliedFilter).length == 0 ? ' not-applied-filters ' : ''}`} sx={{ gap: '10px', width: '100%', overflowY: 'auto', py: '3px' }}>
                {appliedFilter && Object.keys(appliedFilter).length > 0 && Object.keys(appliedFilter).map((filter) => {
                    return filter != '' && filter != 'page' && <Box className='flexRow appliedfilter' sx={{ paddingLeft: '5px' }} key={filter}>
                        <span className='appliedfilterHeader'>{getFilterName(filter, appliedFilter[filter])} {filter == 'new_vehicle' || filter == 'dealer_seller' || filter == 'private_seller' ? ' ' : ':'} </span>
                        <Box className='appliedFilterValue'>
                            {appliedFilter[filter].includes(",") && filter !== "keyword" ?
                                appliedFilter[filter].split(",").map((value) => {
                                    return <span className='filterValue' key={value}>{getFilterValue(filter, value)}<SvgClose onClick={() => removeFilter(filter, value)} className='removeIcon' /></span>
                                })
                                : <span className='filterValue'>{getFilterValue(filter, appliedFilter[filter])} <SvgClose onClick={() => removeFilter(filter, appliedFilter[filter])} className='removeIcon' /></span>
                            }
                        </Box>

                    </Box>

                })}

            </Box>
        </FilterWrapper>
    )
}
export default AppliedFilters