import { handleActions } from 'redux-actions';

import {
  getShowCaseRequest,
  getShowCaseSuccess,
  getShowCaseFailure,
  updateShowCaseRequest,
  updateShowCaseSuccess,
  updateShowCaseFailure,
  getActiveDealerRequest,
  getActiveDealerSuccess,
  getActiveDealerFailure,
  getShowcaseContactRequest,
  getShowcaseContactSuccess,
  getShowcaseContactFailure,
  updateShowCaseItemFailure,
  updateShowCaseItemRequest,
  updateShowCaseItemSuccess,
  getShowCaseItemRequest,
  getShowCaseItemSuccess,
  getShowCaseItemFailure,
  getShowCaseItemServiceRequest,
  getShowCaseItemServiceSuccess,
  getShowCaseItemServiceFailure,
  getServicesRequest,
  getServicesSuccess,
  getServicesFailure,
  updateShowCaseItemServicesRequest,
  updateShowCaseItemServicesSuccess,
  updateShowCaseItemServicesFailure,
  resetShowCaseStates
} from 'store/actions/showcase-action';

const initialState = {
  shopConfigureloading: false,
  shopContactsloading: false,
  upDatedShowCaseFail: false,
  upDatedShowCaseSuccess: false,
  upDatedShowCServicesSuccess: false,
  showCaseItems: {},
  showCaseAboutServices: [],
  showCaseAboutServicesItem: [],
  updateShowCaseFailureError: {},
  activeDealerData: {},
  activeDealerList: [],
  activeDealerError: false,
  servicesList: [],
  upDatedShowcaseAbout: false,
  showcaseAboutError: {},
  showcaseServiceError: {},
  showcaseContactData: {},
};
const showcase = handleActions(
  {

    [getShowCaseRequest]: state => ({
      ...state,
      shopConfigureloading: true,
    }),
    [getShowCaseSuccess]: (state, { payload }) => ({
      ...state,
      showCaseItems: payload,
      shopConfigureloading: false
    }),
    [getShowCaseFailure]: (state, { payload }) => ({
      ...state,
      shopConfigureloading: false
    }),
    [getShowCaseItemRequest]: (state, { payload }) => ({
      ...state,
      showCaseAboutServices: [],
      shopConfigureloading: false
    }),
    [getShowCaseItemSuccess]: (state, { payload }) => ({
      ...state,
      showCaseAboutServices: payload.items,
      shopConfigureloading: false
    }),
    [getShowCaseItemFailure]: state => ({
      ...state,
      showCaseAboutServices: [],
      shopConfigureloading: false,
    }),

    [getShowCaseItemServiceRequest]: (state, { payload }) => ({
      ...state,
      showCaseAboutServicesItem: [],
      upDatedShowcaseAbout: false,
      shopConfigureloading: false
    }),
    [getShowCaseItemServiceSuccess]: (state, { payload }) => ({
      ...state,
      showCaseAboutServicesItem: payload?.items ? payload?.items : [],
      shopConfigureloading: false
    }),
    [getShowCaseItemServiceFailure]: state => ({
      ...state,
      showCaseAboutServicesItem: [],
      upDatedShowcaseAbout: false,
      shopConfigureloading: false,

    }),
    [updateShowCaseRequest]: state => ({
      ...state,
      upDatedShowCaseSuccess: false,
      shopConfigureloading: true,
    }),
    [updateShowCaseSuccess]: (state, { payload }) => ({
      ...state,
      upDatedShowCaseSuccess: true,
      shopConfigureloading: false,
    }),
    [updateShowCaseFailure]: (state, { payload }) => ({
      ...state,
      upDatedShowCaseFail: true,
      updateShowCaseFailureError: payload.data,
      shopConfigureloading: false
    }),
    [getActiveDealerRequest]: (state, { payload }) => ({
      ...state,
      shopConfigureloading: true,
      activeDealerData: {},
    }),
    [getActiveDealerSuccess]: (state, { payload }) => {
      if (payload.data.subsidiaries.current_page === 1) {
        return {
          ...state,
          shopConfigureloading: false,
          activeDealerData: payload,
          activeDealerList: payload.data.subsidiaries.data
        }
      } else {
        return {
          ...state,
          shopConfigureloading: false,
          activeDealerData: payload,
          activeDealerError: false,
          activeDealerList: [...state.activeDealerList, ...payload.data.subsidiaries.data]
        }
      }
    },
    [getActiveDealerFailure]: (state, { payload }) => ({
      ...state,
      shopConfigureloading: false,
      activeDealerData: {},
      activeDealerList: [],
      activeDealerError: true
    }),
    [getShowcaseContactRequest]: state => ({
      ...state,
      shopContactsloading: true,
      showcaseContactData: {},
    }),
    [getShowcaseContactSuccess]: (state, { payload }) => ({
      ...state,
      showcaseContactData: payload,
      shopContactsloading: false,
    }),
    [getShowcaseContactFailure]: (state, { payload }) => ({
      ...state,
      shopContactsloading: false,
      showcaseContactData: {},
    }),
    [getServicesRequest]: state => ({
      ...state,
      servicesList: [],
      shopConfigureloading: true,
    }),
    [getServicesSuccess]: (state, { payload }) => (
      {
        ...state,
        servicesList: payload,
        shopConfigureloading: false,
      }),
    [getServicesFailure]: (state, { payload }) => ({
      ...state,
      servicesList: [],
      shopConfigureloading: false
    }),

    [updateShowCaseItemServicesRequest]: state => ({
      ...state,
      upDatedShowCServicesSuccess: false,
      shopConfigureloading: true,
    }),
    [updateShowCaseItemServicesSuccess]: (state, { payload }) => ({
      ...state,
      upDatedShowCServicesSuccess: true,
      showCaseAboutServicesItem: payload?.data?.items ?? [],
      shopConfigureloading: false,
    }),
    [updateShowCaseItemServicesFailure]: (state, { payload }) => ({
      ...state,
      updateShowCaseFailureError: {},
      upDatedShowCServicesSuccess: false,
      shopConfigureloading: false,
      showcaseServiceError: payload.data
    }),
    [updateShowCaseItemRequest]: state => ({
      ...state,
      upDatedShowcaseAbout: false,
      shopConfigureloading: true,
    }),
    [updateShowCaseItemSuccess]: (state, { payload }) => ({
      ...state,
      upDatedShowcaseAbout: true,
      showCaseAboutServices: payload?.data?.items ?? [],
      shopConfigureloading: false,
    }),
    [updateShowCaseItemFailure]: (state, { payload }) => ({
      ...state,
      upDatedShowcaseAbout: false,
      shopConfigureloading: false,
      showcaseAboutError: payload.data
    }),
    [resetShowCaseStates]: (state, { payload }) => ({
      ...state,
      upDatedShowCaseSuccess: false,
      upDatedShowcaseAbout: false,
      upDatedShowCServicesSuccess: false,
      shopConfigureloading: false,
      updateShowCaseFailureError: {},
      showcaseAboutError: {},
      showcaseServiceError: {},
    }),

  },
  initialState,
);

export default showcase;
