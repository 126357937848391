import { Box, Skeleton } from '@mui/material'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import React from 'react'

const UserDetailSkeleton = () => {

    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    return (
        <Box className="flexRow justifyContentBetween" sx={{ p: 2, maxWidth: isSmallDevice ? '100%' : '400px' }}>
            <Box className="flexRow">
                <Skeleton animation={false} variant="rectangular" width='32px' height='32px' />
                <Box className='flexColumn' sx={{ ml: 1 }}>
                    <Skeleton animation={false} variant="rectangular" width='100px' height='15px' />
                    <Skeleton animation={false} variant="rectangular" width='40px' height='11px' sx={{ mt: '10px' }} />
                    <Box className='flexRow alignItemsCenter justifyContentBetween' sx={{ mt: '10px' }}>
                        <Skeleton animation={false} variant="rectangular" width='50px' height='13px' />
                        <Skeleton animation={false} variant="rectangular" width='20px' height='13px' />
                    </Box>
                </Box>
            </Box>
            <Box className='flexColumn'>
                <Box className='flexRow justifyContentAround'>
                    <Skeleton animation={false} variant="rectangular" width='150px' height='30px' />
                </Box>
                <Skeleton animation={false} variant="rectangular" width='100px' height='14px' sx={{ mt: '15px' }} />
            </Box>
        </Box>
    )
}

export default UserDetailSkeleton