import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { Trans, useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import permissionDenied from "app/components/svgicons/NewIcons/PermissionDenied.webp"


const PermissionComponent = (props: any) => {

    const { t } = useTranslation()

    const permissionList = useSelector((state: any) => state.user.permissionData);

    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    return (
        <>
            {permissionList.length > 0 ?
                <Wrapper>
                    <Box className='container'>
                        <h1 className='header'>{t(translations.COMMON_TEXTS.ACCESS_DENIED)}</h1>
                        <p className='headerDesc'>
                            <Trans
                                i18nKey={t(translations.COMMON_TEXTS.DONT_HAVE_PERMISSION_TO_VIEW_PAGE)}
                                components={{
                                    bold: <span className='redText' />
                                }}
                            />
                        </p>
                        {(isSmallDevice || isMediumDevice) && <img src={permissionDenied} className='svgIcon' />}
                        <p className='navBack'>
                            <Trans
                                i18nKey={t(translations.COMMON_TEXTS.GET_BACK_TO_HOME)}
                                components={{
                                    link1: <Link to={routes.vehicleRoutes.adsHome} />,
                                    link2: <Link to={routes.publicRoutes.contactUs} />
                                }}
                            />
                        </p>
                    </Box>
                    {(isLargeDevice || isExtraLargeDevice) && <img src={permissionDenied} className='svgIcon' />}
                </Wrapper>
                : null
            }
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:50px;
    justify-content: space-around;
    max-width: 1080px;
    margin: 0 auto;
    p, h1{
        margin: 0;
    }
    .header{
        font-family: Inter;
        font-size: 50px;
        font-weight: 700;
        color: #FE4340;
        text-align: center;
    }
    .headerDesc, .navBack{
        font-family: Inter;
        font-size: 21px;
        font-weight: 400;
        line-height: 31.47px;
        color: #5B5B5B;
        span, a{
            font-weight: 700;
            font-size: 21px;
            line-height: 31.47px;
        }
    }
    .headerDesc {
        margin-bottom: 30px;
        .redText{
            color: #FE4340;
        }
    }
    .navBack a{
        color : #617AF7;
        text-decoration: none;
    }
    .svgIcon{
        width: 330px;
        object-fit: contain;
    }
    @media (max-width :992px){
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .svgIcon{
            width: 90%;
            max-width: 250px;
            height: auto;
            margin-bottom: 50px;
        }
        .header{
            font-size: 40px;
        }
        .headerDesc, .navBack{
            font-size: 18px;
            margin: 0;
            text-align: center;
        }
    }
`

export default PermissionComponent