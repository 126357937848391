import { Box, Select } from "@mui/material";
import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const AuthLayoutStyle = styled(Box)`
 .children {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding:24px;
  justify-content: center;
}

.children .authForm .authform-inner{
  max-width: 575px;
  margin: 0px auto;
}

.authform-inner .MuiButton-root{
width: 100%;
text-align: center;
font-weight: 400;
font-size: 21px;
border-radius: 4px;
letter-spacing: 0.1em;
cursor: pointer;
}

.primary-btn {
 color: ${colorNew.colorWhite};
 border: 1px solid ${colorNew.primaryColor};
 background-color: ${colorNew.primaryColor};
}

 .disable-btn {
 color: ${colorNew.colorWhite};
 border: 1px solid ${colorNew.primaryColor};
 background-color: ${colorNew.primaryColor};
 opacity: 0.5
}
 .yellow-btn {
 color: ${colorNew.colorBlack};
 border: 1px solid ${colorNew.colorYellow2};
 background-color: ${colorNew.colorYellow2};
}

.error {
color: ${colorNew.colorRed};
font-size: 13;
}

.imageSide{
  background-size: cover;
  background-position: center center;
  background-repeat: 'no-repeat';
  max-width: 50%;
  width: 100%;
  position: relative;
}
.imageSide svg {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-width: 320px;
  height: auto;
  width: 100%;
  }

  .imageSide img{
    width: 100%;
    max-height: 100vh;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  .children-wraper .auth-title {
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 37px ;
  }
.sub-title {
    display: flex;
    justify-content: center;
    color: ${colorNew.colorGrayDark};
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 44.78px;
}
.children-wraper{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.change-language{
  top: 0;
  background: ${colorNew.colorWhite};
  right: 0;
  border: 1px solid ${colorNew.colorGray2};
  align-self: center;
  padding: 5px 15px;
  z-index: 10;
  position: fixed;
}

.change-language span{
  display: flex;
  align-items: center;
}
.change-language span img{
  margin-right: 8px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.not-account {
  font-weight: 400;
  size: 18px;
  color: #999999;
}
.join-here {
  color: #384386;
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
  padding-left: 3px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  background: ${colorNew.colorWhite};
              }
.input-wrapper input {
  background: ${colorNew.colorWhite};
}

.forgot-password{
  margin-bottom: 16px;
  text-align: right;
}

.forgot-password  a {   
  border-bottom: 2px solid #384386;
  color: #384386;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;                
}
.topBar {
    padding-top: 0px;
    padding-bottom: 0px;
 }

@media (max-width: 960px) {
  .children-wraper .auth-title {
   font-size: 27px !important;
  }

  .children {
   padding: 1 ;
   justify-content:space-between
  }
  .imageSide{
    display: none;
  }
}

@media (max-width: 992px) {
  .change-language{ 
  position: absolute;
}
}
`
