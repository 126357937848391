import { Box, Tooltip } from '@mui/material'
import DeleteConfirm from 'app/components/stylesComponents/Ads/DeleteConfirm'
import { SvgClose } from 'app/components/svgicons/svg'
import { SvgBlock, SvgImport, SvgMail, SvgMute, SvgUnmute } from 'app/components/svgicons/svg2'
import { SvgverticalDots } from 'app/components/svgicons/svgNew'
import { useDispatch } from 'react-redux'
import { deleteParticularChatRequest } from 'store/actions/chat-action'
import { ChatOptionInterface, Nullable } from '../../interface/interface'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import * as ChatConstants from "config/chatConfig"
import { useState } from 'react'

const ChatOptionsWeb = ({ showOptions, _showOptions, chatData, handleArchive, handleBlock, handleMute, handleUnread,setOpen,open ,chatType}:ChatOptionInterface) => {

    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [actionType,setActionType] = useState<number>(ChatConstants.ACTION_TYPE.BLOCK)

    const getWarningMessage = () => {        
        let msg = t(translations.CHAT.ARE_YOU_SURE_TO_BLOCK_THIS_USER)
        if(chatType === ChatConstants.chatType.ALL_MESSAGES && chatData && Object.keys(chatData).length > 0 && chatData.chat_user_info.subsidiary_id){
                msg = t(translations.CHAT.ARE_YOU_SURE_TO_BLOCK_THIS_SUBSIDIARY)
        }
        if(chatType === ChatConstants.chatType.BLOCKED){
            msg = t(translations.CHAT.ARE_YOU_SURE_TO_UNBLOCK_THIS_USER)
            if (chatData && Object.keys(chatData).length > 0 && chatData.chat_user_info.subsidiary_id){
                msg = t(translations.CHAT.ARE_YOU_SURE_TO_UNBLOCK_THIS_SUBSIDIARY)
            }
        }        
        return msg
      }

    //----------------------------------- Action Functions-----------------------------------

    const handleAction = (type :number ,val:boolean) => {
        setActionType(type)
        setOpen(val)
    }
    const handleDelete = () => {
        dispatch(deleteParticularChatRequest(chatData.id))
        setOpen(false)
        _showOptions(false)
    }

    return (
        <>
            {showOptions ?
                <Box className='flexRow optionListContainer'>
                    <Box className='optionList flexRow'>
                        <Box>
                            <Tooltip title={chatData.is_read?`${t(translations.CHAT.UNREAD)}`:`${t(translations.CHAT.READ)}`}>
                                <SvgMail className='pointer' onClick={()=>handleUnread()} />
                            </Tooltip>
                        </Box>
                        {/* If a user is blocked, it can't be archived */}
                        {chatType !== ChatConstants.chatType.BLOCKED && <Box>
                            <Tooltip title={chatData.is_archived?`${t(translations.CHAT.UNARCHIVE)}`:`${t(translations.CHAT.ARCHIVE)}`}>
                                <SvgImport className='pointer' onClick={()=>handleArchive()} />
                            </Tooltip>
                        </Box>}
                        {/* Chat can be muted only if is is not blocked and archived */}
                        {chatType === ChatConstants.chatType.ALL_MESSAGES && <Box>
                            <Tooltip title={chatData.is_muted?`${t(translations.CHAT.UNMUTE)}`:`${t(translations.CHAT.MUTE)}`}>
                                {chatData.is_muted ? <SvgUnmute className='pointer' onClick={()=>handleMute()} /> :
                                <SvgMute className='pointer' onClick={()=>handleMute()} /> }
                            </Tooltip>
                        </Box>}
                        {/* If a chat is archived, it can't be blocked */}
                        {!chatData.is_archived && <Box>
                            <Tooltip title={chatType === ChatConstants.chatType.BLOCKED ?`${t(translations.CHAT.UNBLOCKS)}`:`${t(translations.CHAT.BLOCK)}`}>
                                <SvgBlock className='pointer' onClick={()=>handleAction(ChatConstants.ACTION_TYPE.BLOCK,true)} />
                            </Tooltip>
                        </Box> }
                        {/* <Box >
                            <Tooltip title={`${t(translations.ads.DELETE)}`}>
                                <SvgDelete className='pointer' onClick={() => handleAction(ChatConstants.ACTION_TYPE.DELETE,true)} />
                            </Tooltip>
                        </Box> */}
                    </Box>
                    <Box className='optionIcon'>
                        <SvgClose className='pointer' onClick={() => _showOptions(false)} />
                    </Box>
                </Box> :
                chatData && chatData.chat_user_info && chatData.chat_user_info.id !== undefined &&
                <SvgverticalDots className='pointer optionIcon' onClick={() => _showOptions(true)} />}

            <DeleteConfirm open={open} setOpen={setOpen} deleteFunc={actionType===ChatConstants.ACTION_TYPE.BLOCK?handleBlock:handleDelete} confirmBtn={actionType===ChatConstants.ACTION_TYPE.BLOCK?t(translations.ads.CONFIRM):null} message={getWarningMessage()} />
        </>
    )
}

export default ChatOptionsWeb