import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const lan = {
  basic: () => _t(translations.ads.BASIC),
  home: () => _t(translations.ads.HOME),
  ads: () => _t(translations.ads.ADS),
  sortBy: () => _t(translations.ads.SORT_BY),
  sort: () => _t(translations.ads.SORT),
  done: () => _t(translations.ads.DONE),
  filter: () => _t(translations.ads.FILTER),
  price: () => _t(translations.ads.PRICE),
  call: () => _t(translations.ads.CALL),
  mail: () => _t(translations.ads.MAIL),
  monthWarranty: () => _t(translations.ads.MONTH_WARRANTY),
  serviceBook: () => _t(translations.ads.SERVICE_BOOK),
  brand: () => _t(translations.ads.BRAND),
  model: () => _t(translations.ads.MODEL),
  body: () => _t(translations.ads.BODY),
  year: () => _t(translations.ads.YEAR),
  firstRegistration: () => _t(translations.ads.FIRST_REGISTRATION),
  fuel: () => _t(translations.ads.FUEL),
  transmission: () => _t(translations.ads.TRANSMISSION),
  damage: () => _t(translations.ads.DAMAGE),
  km: () => _t(translations.ads.KM),
  power: () => _t(translations.ads.POWER),
  country: () => _t(translations.ads.COUNTRY),
  color: () => _t(translations.ads.COLOR),
  condition: () => _t(translations.ads.CONDITION),
  nbSeats: () => _t(translations.ads.NB_SEATS),
  seller: () => _t(translations.ads.SELLER),
  interiorCoverage: () => _t(translations.ads.INTERIOR_COVERAGE),
  warrantyMonth: () => _t(translations.ads.WARRANTY_MONTH),
  interiorType: () => _t(translations.ads.INTERIOR_TYPE),
  euroStandardMin: () => _t(translations.ads.EURO_STANDARD_MIN),
  equipments: () => _t(translations.ads.EQUIPMENTS),
  search: () => _t(translations.ads.SEARCH),
  auctionsList: () => _t(translations.ads.AUCTIONS_LIST),
  started: () => _t(translations.ads.STARTED),
  coming: () => _t(translations.ads.COMING),
  vehicles: () => _t(translations.ads.VEHICLES),
  onlyFor: () => _t(translations.ads.ONLY_FOR),
  endDate: () => _t(translations.ads.END_DATE),
  finishIn: () => _t(translations.ads.FINISH_IN),
  startIn: () => _t(translations.ads.STARTS_IN),
  startDate: () => _t(translations.ads.START_DATE),
  currentPrice: () => _t(translations.ads.CURRENT_PRICE),
  autoBid: () => _t(translations.ads.AUTO_BID),
  quickBid: () => _t(translations.ads.QUICK_BID),
  details: () => _t(translations.ads.DETAILS),
  contact: () => _t(translations.ads.CONTACT),
  expertise: () => _t(translations.ads.EXPERTISE),
  vehicle: () => _t(translations.ads.VEHICLE),
  sizeWeight: () => _t(translations.ads.SIZE_WEIGHT),
  size: () => _t(translations.ads.SIZE),
  tires: () => _t(translations.ads.TIRES),
  yes: () => _t(translations.ads.YES),
  no: () => _t(translations.ads.NO),
  weight: () => _t(translations.ads.WEIGHT),
  conditionMaintenance: () => _t(translations.ads.CONDITION_MAINTENANCE),
  engineEnvironment: () => _t(translations.ads.ENGINE_ENVIRONMENT),
  identification: () => _t(translations.ads.IDENTIFICATION),
  description: () => _t(translations.ads.DESCRIPTION),
  numberOfDoor: () => _t(translations.ads.NUMBER_OF_DOOR),
  numberOfSeats: () => _t(translations.ads.NUMBER_OF_SEATS),
  foldingRearSeat: () => _t(translations.ads.FOLDING_REAR_SEAT),
  numberOfAirbags: () => _t(translations.ads.NUMBER_OF_AIRBAGS),
  colorOutside: () => _t(translations.ads.COLOR_OUTSIDE),
  colorInside: () => _t(translations.ads.COLOR_INSIDE),
  emptyWeightKg: () => _t(translations.ads.EMPTY_WEIGHT_KG),
  grossWeghtKg: () => _t(translations.ads.GROSS_WEGHT_KG),
  payloadWeightKg: () => _t(translations.ads.PAYLOAD_WEIGHT_KG),
  roofLoadKg: () => _t(translations.ads.ROOF_LOAD_KG),
  lengthMm: () => _t(translations.ads.LENGTH_MM),
  widthMm: () => _t(translations.ads.WIDTH_MM),
  heigtMm: () => _t(translations.ads.HEIGT_MM),
  wheelbaseMm: () => _t(translations.ads.WHEELBASE_MM),
  clearanceMm: () => _t(translations.ads.CLEARANCE_MM),
  frontTires: () => _t(translations.ads.FRONT_TIRES),
  backTires: () => _t(translations.ads.BACK_TIRES),
  runflat: () => _t(translations.ads.RUNFLAT),
  saison: () => _t(translations.ads.SAISON),
  type: () => _t(translations.ads.TYPE),
  spareWheel: () => _t(translations.ads.SPARE_WHEEL),
  controlTechnicalTo: () => _t(translations.ads.CONTROL_TECHNICAL_TO),
  controlTecniclaUntil: () => _t(translations.ads.CONTROL_TECHNICAL_UNTIL),
  maintenanceBook: () => _t(translations.ads.MAINTENANCE_BOOK),
  lastControlTechnicalAt: () => _t(translations.ads.LAST_CONTROL_TECHNICAL_AT),
  replaceBeltDistribution: () => _t(translations.ads.REPLACE_BELT_DISTRIBUTION),
  motor: () => _t(translations.ads.MOTOR),
  numberOfOwners: () => _t(translations.ads.NUMBER_OF_OWNERS),
  cylinderCc: () => _t(translations.ads.CYLINDER_CC),
  coupleNm: () => _t(translations.ads.COUPLE_NM),
  numberOfSpeeds: () => _t(translations.ads.NUMBER_OF_SPEEDS),
  powerKw: () => _t(translations.ads.POWER_KW),
  powerCv: () => _t(translations.ads.POWER_CV),
  powerCvFiscal: () => _t(translations.ads.POWER_CV_FISCAL),
  angleOfAttack: () => _t(translations.ads.ANGLE_OF_ATTACK),
  departAngle: () => _t(translations.ads.DEPART_ANGLE),
  tankVolume: () => _t(translations.ads.TANK_VOLUME),
  topSpeedKmH: () => _t(translations.ads.TOP_SPEED_KM_H),
  oTto100: () => _t(translations.ads.o_TTO_100_SECOND),
  carDifferentielLock: () => _t(translations.ads.CAR_DIFFERENTIEL_LOCK),
  euroStandard: () => _t(translations.ads.EURO_STANDARD),
  electric: () => _t(translations.ads.ELECTRIC),
  powerKwCharging: () => _t(translations.ads.POWER_KW_CHARGING),
  powerKwChargingMax: () => _t(translations.ads.POWER_KW_CHARGING_MAX),
  chargeDuration: () => _t(translations.ads.CHARGE_DURATION),
  batteryDurationHour: () => _t(translations.ads.BATTERY_DURATION_HOUR),
  batteryDurationHighwayKm: () => _t(translations.ads.BATTERY_DURATION_HIGHWAY_KM),
  batteryType: () => _t(translations.ads.BATTERY_TYPE),
  batteryDurationCityKm: () => _t(translations.ads.BATTERY_DURATION_CITY_KM),
  numberOfMotor: () => _t(translations.ads.NUMBER_OF_MOTOR),
  powerOfMotor1Kw: () => _t(translations.ads.POWER_OF_MOTOR_1_KW),
  powerOfMotor2Kw: () => _t(translations.ads.POWER_OF_MOTOR_2_KW),
  drivingWheel: () => _t(translations.ads.DRIVING_WHEEL),
  environment: () => _t(translations.ads.ENVIRONMENT),
  co2EmissionsGKm: () => _t(translations.ads.CO2_EMISSIONS_G_KM),
  standardPollution: () => _t(translations.ads.STANDARD_POLLUTION),
  consumptionCombinedIKm: () => _t(translations.ads.CONSUMPTION_COMBINED_I_KM),
  consumptionUrbanLKm: () => _t(translations.ads.CONSUMPTION_URBAN_L_KM),
  consumptionExtraUrbanLKm: () => _t(translations.ads.CONSUMPTION_EXTRA_URBAN_L_KM),
  euroNorm: () => _t(translations.ads.EURO_NORM),
  vehicleClassificationEnv: () => _t(translations.ads.VEHICLE_CLASSIFICATION_ENV),
  commercial: () => _t(translations.ads.COMMERCIAL),
  marketing: () => _t(translations.ads.MARKETING),
  sales: () => _t(translations.ads.SALES),
  workingHours: () => _t(translations.ads.WORKING_HOURS),
  close: () => _t(translations.ads.CLOSE),
  fotos: () => _t(translations.ads.FOTOS),
  moreInfos: () => _t(translations.ads.MORE_INFOS),
  numberOfKeys: () => _t(translations.ads.NUMBER_OF_KEYS),
  vehicleImported: () => _t(translations.ads.VEHICLE_IMPORTED),
  importedFrom: () => _t(translations.ads.IMPORTED_FROM),
  historyMaintenance: () => _t(translations.ads.HISTORY_MAINTENANCE),
  lastMaintenance: () => _t(translations.ads.LAST_MAINTENANCE),
  engineLightIsOn: () => _t(translations.ads.ENGINE_LIGHT_IS_ON),
  damageMotor: () => _t(translations.ads.DAMAGE_MOTOR),
  damageTransmission: () => _t(translations.ads.DAMAGE_TRANSMISSION),
  otherDamage: () => _t(translations.ads.OTHER_DAMAGE),
  classic: () => _t(translations.ads.CLASSIC),
  auction: () => _t(translations.ads.AUCTION),
  park: () => _t(translations.ads.PARK),
  parked: () => _t(translations.ads.PARKED),
  reservation: () => _t(translations.ads.RESERVATION),
  reserved: () => _t(translations.ads.RESERVED),
  cancel: () => _t(translations.ads.CANCEL),
  onSale: () => _t(translations.ads.ON_SALE),
  sold: () => _t(translations.ads.SOLD),
  edit: () => _t(translations.ads.EDIT),
  hidden: () => _t(translations.ads.HIDDEN),
  delete: () => _t(translations.ads.DELETE),
  report: () => _t(translations.ads.REPORT),
  youMustDecideBeforeTheEndOfTheCountdown: () => _t(translations.ads.YOU_MUST_DECIDE_BEFORE_THE_END_OF_THE_COUNTDOWN),
  target: () => _t(translations.ads.TARGET),
  rejected: () => _t(translations.ads.REJECTED),
  accepted: () => _t(translations.ads.ACCEPTED),
  vatRefund: () => _t(translations.ads.VAT_REFUND),
  vat: () => _t(translations.ads.VAT),
  vatIncluded: () => _t(translations.ads.VAT_INCLUDED),
  vatRefundable: () => _t(translations.ads.VAT_REFUNDABLE),
  vatNonRefundable: () => _t(translations.ads.VAT_NON_REFUNDABLE),
  removeFromAuction: () => _t(translations.ads.REMOVE_FROM_AUCTION),
  resetAll: () => _t(translations.ads.RESET_ALL),
  allowedVehicles: () => _t(translations.ads.ALLOWED_VEHICLES),
  allowedSeller: () => _t(translations.ads.ALLOWED_SELLER),
  closingOfRegistrations: () => _t(translations.ads.CLOSING_OF_REGISTRATIONS),
  bid: () => _t(translations.ads.BID),
  street: () => _t(translations.ads.STREET),
  startButton: () => _t(translations.ads.STARTBUTTON),
  numberOfCylinder: () => _t(translations.ads.NUMBER_OF_CYLINDER),
  engine: () => _t(translations.ads.ENGINE),
  myAds: () => _t(translations.ads.MY_ADS),
  live: () => _t(translations.ads.LIVE),
  actionRequired: () => _t(translations.ads.ACTION_REQUIRED),
  history: () => _t(translations.ads.HISTORY),
  vin: () => _t(translations.ads.VIN),
  licensePlate: () => _t(translations.ads.LPLATE),
  plate: () => _t(translations.ads.PLATE),
  ref: () => _t(translations.ads.REF),
  reference: () => _t(translations.ads.REFERENCE),
  goal: () => _t(translations.ads.GOAL),
  delivery: () => _t(translations.ads.DELIVERY),
  availalbe: () => _t(translations.ads.AVAILALBE),
  createClassicAd: () => _t(translations.ads.CREATE_CLASSIC_AD),
  publish: () => _t(translations.ads.PUBLISH),
  futureAuction: () => _t(translations.ads.FUTURE_AUCTION),
  firstRegister: () => _t(translations.ads.FIRST_REGISTER),
  registration: () => _t(translations.ads.REGISTRATION),
  exclVatPublic: () => _t(translations.ads.EXCL_VAT_PUBLIC),
  amountVat: () => _t(translations.ads.Amount_VAT),
  publicPriceAti: () => _t(translations.ads.PUBLIC_PRICE_ATI),
  warranty: () => _t(translations.ads.WARRANTY),
  typeOfSales: () => _t(translations.ads.TYPE_OF_SALES),
  save: () => _t(translations.ads.SAVE),
  createAuctionAd: () => _t(translations.ads.CREATE_AUCTION_AD),
  searchCustomer: () => _t(translations.ads.SEARCH_CUSTOMER),
  suggestedMemberCartobike: () => _t(translations.ads.SUGGESTED_MEMBER_CARTOBIKE),
  formWhichCountry: () => _t(translations.ads.FROM_WHICH_COUNTRY),
  end: () => _t(translations.myAdscreen.END),
  deleteAd: () => _t(translations.myAdscreen.DELETE_AD),
  bestBidIs: () => _t(translations.myAdscreen.THE_BEST_BID_IS),
  clickHere: () => _t(translations.ads.CLICK_HERE),
  clickHereToVerify: () => _t(translations.ads.CLICK_HERE_TO_VERIFY),
  checkverificationStatus: () => _t(translations.ads.CHECK_VERIFICATION_STATUS),
  makeABidConfirmation: () => _t(translations.ads.MAKE_A_BID),
  doNotAsk: () => _t(translations.ads.DO_NOT_ASK),
  confirm: () => _t(translations.ads.CONFIRM),
  draft: () => _t(translations.ads.DRAFT),
  bidCreated: () => _t(translations.ads.BID_CREATED),
  net: () => _t(translations.ads.NET),
  total: () => _t(translations.invoiceScreen.TOTAL),
  homeChargingTimeTo80: () => _t(translations.ads.HOME_CHARGING_TIME_TO_80),
  fastChargingTimeTo80: () => _t(translations.ads.FAST_CHARGING_TIME_TO_80),
  homeChargingTimeTo8011kw: () => _t(translations.ads.HOME_CHARGING_TIME_TO_80_11KW),
  publicFastChargingTimeTo80LimitPower: () => _t(translations.ads.PUBLIC_FAST_CHARGING_TIME_TO_80_LIMIT_POWER),
  previewOwner: () => _t(translations.ads.PREVIEW_OWNER),
  consumption: () => _t(translations.ads.CONSUMPTION),
  new: () => _t(translations.summaryVehicleScreen.NEW),
  used: () => _t(translations.summaryVehicleScreen.USED),
  upholstery: () => _t(translations.ads.UPHOLSTERY),
  version: () => _t(translations.ads.VERSION),
  send: () => _t(translations.PUBLICPROFILE.SUBMIT),
  toSaleIn: () => _t(translations.ads.TO_SALE_IN),
  allBrandOf: () => _t(translations.ads.ALL_BRAND_OF),
  metaDescriptionNext1: () => _t(translations.ads.METADESCRIPTIONTEXT1),
  publicAdSaleOrPurchase: () => _t(translations.ads.PUBLICADSSALEORPURCHASE),
  trophyModalInfoContent: () => _t(translations.ads.TROPHYMODALINFO),
  trophyModalInfoContentHeader: () => _t(translations.ads.TROPHYMODALINFOHEADER),
  bidInformation: () => _t(translations.ads.BIDINFO),
  keyInfo: () => _t(translations.ads.KEY_INFO),
  images: () => _t(translations.ads.IMAGES),
  changeEmailText: () => _t(translations.authScreens.CHANGEMAIL),
  classicAdsList: () => _t(translations.breadCrumb.CLASSIC_ADS_LIST),
  office: () => _t(translations.showCaseForm.OFFICE),
  closed: () => _t(translations.ads.CLOSED),
  monday: () => _t(translations.ads.MONDAY),
  tuesday: () => _t(translations.ads.TUESDAY),
  wednesday: () => _t(translations.ads.WEDNESDAY),
  thursday: () => _t(translations.ads.THURSDAY),
  friday: () => _t(translations.ads.FRIDAY),
  saturday: () => _t(translations.ads.SATURDAY),
  sunday: () => _t(translations.ads.SUNDAY),
  all: () => _t(translations.ads.ALL),
  to: () => _t(translations.ads.TO),
  about: () => _t(translations.ads.ABOUT),
  services: () => _t(translations.ads.SERVICES),
  stock: () => _t(translations.ads.STOCK),
  classicText: () => _t(translations.ads.CLASSICTEXT),
  auctionText: () => _t(translations.ads.AUCTIONTEXT),
  notFound: () => _t(translations.ads.NOT_FOUND),
  terms: () => _t(translations.ads.TERMS),
  privacyPolicy: () => _t(translations.ads.PRIVACYPOLICY),
  dataProtection: () => _t(translations.ads.DATAPROTECTION),
  legalNotice: () => _t(translations.ads.LEGALNOTICE),
  legalDocuments: () => _t(translations.ads.LEGALDOCUMENTS),
  firstUse: () => _t(translations.ads.FIRSTUSE),
  frameSize: () => _t(translations.ads.FRAMESIZE),
  until: () => _t(translations.ads.UNTIL),
  classicAds: () => _t(translations.ads.CLASSIC_ADS),
  ascPrice: () => _t(translations.ads.ASC_PRICE),
  dscPrice: () => _t(translations.ads.DSC_PRICE),
  ascBrand: () => _t(translations.ads.ASC_BRAND),
  dscBrand: () => _t(translations.ads.DSC_BRAND),
  deleteMember: () => _t(translations.ads.DELETEMEMBER),
};

