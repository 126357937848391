import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const lan = {
  vat: () => _t(translations.companyProfileScreen.VAT),
  switch: () => _t(translations.companyProfileScreen.SWITCHACCOUNT),
  members: () => _t(translations.companyProfileScreen.MEMBERS),
  roles: () => _t(translations.companyProfileScreen.ROLES),
  manageAppointment: () => _t(translations.companyProfileScreen.MANAGEAPPOINTMENT),
  subscription: () => _t(translations.companyProfileScreen.SUBSCRIPTION),
  changePlan: () => _t(translations.companyProfileScreen.CHANGEPLAN),
  privacy: () => _t(translations.companyProfileScreen.PRIVACY),
  setting: () => _t(translations.companyProfileScreen.SETTINGS),
  showcaseConfigure: () => _t(translations.companyProfileScreen.SHOWCASECONFIGURE),
  invoice: () => _t(translations.mobileSideBar.INVOICES),
  invoicePlan: () => _t(translations.mobileSideBar.INVOICESPLAN),
  companyProfile: () => _t(translations.formText.COMPANYPROFILE),
  privateAccount: () => _t(translations.companyProfileScreen.PRIVATEACCOUNT),
  shopLink: () => _t(translations.companyProfileScreen.SHOPLINK),
  copyLink: () => _t(translations.companyProfileScreen.COPYLINK),
};  