import { Box, Popover, Typography } from "@mui/material";
import { translations } from "locales/translations";
import { colorNew } from "styles/StyleConstants";
import { SvgSearchIcon } from "../svgicons/svgNew";
import { useTranslation } from "react-i18next";

export const CountryDropDownPopup = (props) => {
    const { t } = useTranslation();
    return (
        <Popover id={props.id} open={props.open} anchorEl={props.anchorEl} onClose={props.handleClose} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
        }}>
            <Box sx={{
                px: 3,
                maxWidth: 400,
                width: '87vw',
                '.header-country': {
                    borderBottom: `1px solid ${colorNew.colorGray}`,
                    p: 2,
                    'div': {
                        fontSize: 20,
                        marginBottom: 1
                    },
                    'span': {
                        fontSize: 13
                    }
                },
                '.search': {
                    p: 2,
                    position: 'relative',
                    'span': {
                        position: 'absolute',
                        zIndex: 1,
                        top: 27,
                        left: 28
                    },
                    'input': {
                        height: 50,
                        padding: '10px 15px 10px 40px',
                        width: '100%',
                        maxWidth: '100%'
                    }
                },
                'ul': {
                    maxHeight: 300,
                    overflow: 'auto',
                    px: 2,
                    pb: 2,
                    'li': {
                        width: '100%',
                        justifyContent: 'space-between',
                        listStyle: 'none',
                        marginBottom: '20px',
                        '.flag1': {
                            width: '100%',
                            display: 'flex',
                            overflow: "hidden",
                            'img': {
                                width: "50px",
                                height: "30px",
                                mr: 2
                            },
                        }
                    }
                }
            }}>
                <div className="header-country">
                    <div>{props.t(translations.summaryVehicleScreen.SUMMARYVEHICLESCREEN_SELECT_COUNTRY)}</div>
                    <span>{props.t(translations.summaryVehicleScreen.INCLUDE_MOBILE_NUMBER)}</span>
                </div>
                <div className="search">
                    <span>
                        <SvgSearchIcon />
                    </span>
                    <input type='text' placeholder={props.t(translations.ads.SEARCH)} value={props.searchedText} onChange={props.handleSearch} className="search" />
                </div>
                <ul>

                    {Object.keys(props.filteredCountry).length > 0 ?
                        <>
                            {Object.keys(props.filteredCountry).map((continent, index) =>
                                <>
                                    <Typography sx={{
                                        fontSize: "20px",
                                        fontWeight: '600',
                                        mb: 2
                                    }}>
                                        {props.filteredCountry?.[continent].length ? continent : null}
                                    </Typography>
                                    {props.filteredCountry?.[continent].map(country =>
                                        <li key={country.id} onClick={() => props.SelectCountry({
                                            id: country.id,
                                            name: country.country_name,
                                            flag: country.flag_thumbnail,
                                            code: country.prefix_call,
                                            fullname: country.fullname
                                        })}>

                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                minWidth: "235px"
                                            }}>
                                                <Box className="flag1">
                                                    <img src={country.flag_thumbnail} alt={country.country_name} />
                                                    <span className="_fullname">{country?.fullname}</span>
                                                </Box>
                                                <Box className='_code' sx={{
                                                    'span': {
                                                        whiteSpace: 'nowrap'
                                                    }
                                                }}>
                                                    <span>{country.prefix_call}</span>
                                                </Box>
                                            </Box>

                                        </li>)}
                                </>)}
                        </> :
                        <ul>
                            <li>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    minWidth: "235px"
                                }}>
                                    {t(translations.COMMON_TEXTS.COUNTRY_NOT_FOUND)}
                                </Box>
                            </li>
                        </ul>
                    }
                </ul>
            </Box>
        </Popover>);
}
