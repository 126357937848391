import { Box } from "@mui/material";
import { useWindowSize } from "app/Hooks/windowResize";
import UserHeader from "./components/UserHeader";
import UserSidebar from "./components/UserSidebar";
import { theme } from "config";
import { getUserDataRequest } from 'store/actions/user-actions'
import { getSubsidiaryStored, CountriesDrop1 } from "config/variables";
import { useEffect, useState } from "react";
import userPlaceholderImage from 'app/assets/Images/userplaceholder.png';
import { RootStateOrAny, connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { colorNew } from "styles/StyleConstants";
import { SvgchevronRight, SvgEditPencil } from "app/components/svgicons/svgNew";
import Sidebar from 'app/Component2/Layouts/AdsLayouts/Sidebar'
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { lan } from "./lan";
import Navbar from "../PublicLayout/Components/navbar";
import { HeaderWrapper } from "app/Component2/styled/HeaderWrapper";
import { routes } from "routes/routes";


const UserLayout = (props) => {
    const { profileUpdated, imageUpdated, userData } = props.user
    const { page, component1, title, titleCom, breadcrumb, onClickFunction, handleScroll, vatId, handleVat, totalMember, addMember, search, filter, profileLink } = props;
    const subsidiaryDetails = useSelector((state: RootStateOrAny) => state.company);
    const dispatch = useDispatch();
    const [userImage, _userImage] = useState<string>('')
    const [logo, _logo] = useState<string>('')
    const [countryFlag, _countryFlag] = useState<string>('')
    const [country, _country] = useState<number>(2)
    const [subsidiaryDetail, _subsidiaryDetail] = useState<any>()
    const [companyName, _companyName] = useState<string>('')
    const [isValid, _isValid] = useState<string>('')
    const [notificationCounts, _notificationCounts] = useState<number>(0)
    const { vatValidData } = useSelector((state: any) => state.company)
    const [isFixed, _isFixed] = useState<boolean>(false)
    const location = useLocation();


    const [mobile] = useWindowSize()
    const { t } = useTranslation();

    useEffect(() => {
        if (userData?.addresses) {
            _country(userData?.addresses[0].country_id)
        }
    }, [userData])

    const sub = getSubsidiaryStored();
    useEffect(() => {
        // getUserDataRequest();
    }, [profileUpdated, imageUpdated])
    useEffect(() => {
        if (subsidiaryDetails?.notificationCount) {
            _notificationCounts(subsidiaryDetails?.notificationCount?.data?.new_notification_count)
        } else {
            _notificationCounts(0)
        }
        if (sub && sub !== null) {
            _companyName(subsidiaryDetails?.subsidiaryDetails?.name)
        }
    }, [subsidiaryDetails, sub])

    useEffect(() => {
        _subsidiaryDetail(subsidiaryDetails?.subsidiaryDetails)
    }, [subsidiaryDetails])

    useEffect(() => {
        const sub = getSubsidiaryStored();
        if (sub && sub !== null) {
            _logo(subsidiaryDetail?.logo_url)
        } else if (userData?.profile_picture_url) {
            _userImage(userData?.profile_picture_url)
        } else {
            _userImage(userPlaceholderImage)
        }
    }, [userData, subsidiaryDetail, sub])

    useEffect(() => {
        CountriesDrop1().then(data => {
            const defaultCountry = data.filter(x => x.id === country)
            _countryFlag(defaultCountry[0]);
        })
    }, [country])


    return (
        <HeaderWrapper>
            <Helmet>
                {props.title ? <title>{props.title}</title> : null}
                {props.meta ? <meta property="og:title" content="Car to bike Ads Page" /> : null}
            </Helmet>
            {props.mobileTitle && mobile ? <Box
                sx={{
                    p: '15px 15px 16px'
                }}
            > {props.mobileTitle}  </Box> : null}
            {!mobile && !props.auctionPlace && <Navbar backButton={false} isFixed={isFixed} _isFixed={_isFixed} myAccount={true} />}
            <Box sx={{ backgroundColor: mobile ? "" : `${colorNew.colorGrayLight}` }}>
                <Box sx={{ display: 'flex' }}>
                    {!mobile ? props.auctionPlace ? <Sidebar /> : <UserSidebar userImage={userImage} page={page} logo={logo} /> : null}
                    <Box sx={{
                        width: '100%',
                        padding: props.auctionPlace ? "" : !mobile ? '20px 20px 0px 20px' : 'initial',
                        position: "relative",
                        '.profile_wrapper': {
                            'h2': {
                                fontWeight: '400',
                                fontSize: '18px',
                                lineHeight: '22px',
                                margin: '0',
                                color: '#000000',
                                paddingBottom: '16px',
                            },
                            'p': {
                                margin: '0',
                            },
                            '.profile_content': {
                                p: "24px 24px 32px 24px",
                                textAlign: 'center',

                                [theme.breakpoints.down('md')]: {
                                    textAlign: 'left',
                                    border: "none"
                                },
                                [theme.breakpoints.down('sm')]: {
                                    padding: "0",
                                }
                            }
                        }
                    }}>
                        {!mobile && props.auctionPlace && <UserHeader auctionPlace={props.auctionPlace} />}
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            textDecoration: "none",
                            alignItems: 'center',
                            minHeight: !mobile ? '35px' : "auto",
                            color: `${colorNew.colorBlack}`,
                            [theme.breakpoints.down('sm')]: {
                                flexWrap: 'wrap',
                            },
                            '.add_emp': {
                                [theme.breakpoints.down('sm')]: {
                                    order: '4',
                                    width: '100%',
                                    textAlign: 'right',
                                    borderTop: '1px solid #E5E5E5',
                                    marginTop: '13px',
                                    paddingTop: '16px',
                                    margin: 0
                                },
                                'a': {
                                    justifyContent: 'end',
                                    color: '#6579F4',
                                    display: 'flex',
                                    alignItems: 'center',
                                    columnGap: '10px',
                                }
                            },
                            '.mb_filte_icon': {
                                [theme.breakpoints.down('sm')]: {
                                    order: '3',
                                    width: '100%',
                                    textAlign: 'right',
                                    margin: '13px 0',
                                }
                            },
                            '.mobile_total_memb': {
                                [theme.breakpoints.down('lg')]: {
                                    ml: 2
                                },
                                [theme.breakpoints.down('sm')]: {
                                    width: '44%',
                                    fontSize: 14,
                                    pt: totalMember || search ? '14px' : 'auto'
                                },
                            },

                            'p': {
                                fontWeight: '400',
                                fontSize: '18px',
                                letterSpacing: '0.02em',
                                color: '#999999',
                                margin: '0',
                                '.green': {
                                    color: '#28A745',
                                },
                                '.red': {
                                    color: `${colorNew.colorRed}`,
                                }
                            },

                        }}
                        >

                            {!mobile && <Box
                                sx={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                    display: "flex",
                                    color: colorNew.colorBlack,
                                    'a': {
                                        color: colorNew.colorBlack,
                                        marginRight: 2,
                                        '.icon-right': {
                                            marginLeft: 1,
                                            'svg': {
                                                width: 15,
                                            }
                                        }
                                    }
                                }}
                            >
                                {!breadcrumb ? titleCom ? titleCom : title : ""}
                                {breadcrumb ? breadcrumb.map((option, index) => (
                                    <Box key={index}>
                                        {option.onClickFunction ? <span onClick={() => option.onClickFunction()}> { } {option.title} <span>{<SvgchevronRight />}</span></span>
                                            :
                                            <Link to={option.link ? option.link : ""}>
                                                {option.title}
                                                {index + 1 < breadcrumb.length ?
                                                    <span className="icon-right"> {<SvgchevronRight />}</span> : null
                                                }
                                            </Link>}

                                    </Box>
                                )) : null}
                            </Box>}

                            {vatId && vatId?.vat ?
                                <Box
                                    sx={{
                                        ml: 4,
                                        fontSize: mobile ? "16px" : "18px",
                                        fontWeight: "400",
                                        'svg': {
                                            "path": {
                                                fill: "#999999"
                                            }
                                        },
                                        '.editVat': {
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    {t(...lan.vatId())}: <span className={` ${vatId?.isVatValid ? 'green' : 'red'}`}>{vatId?.prefixVat} {vatId?.vat} <SvgEditPencil className="editVat" onClick={handleVat} /></span>
                                </Box>
                                : null}
                            {totalMember ? <Box className="mobile_total_memb">
                                {search ? t(...lan.totalMembers()) : t(...lan.total())}
                                <span style={{ color: "black" }}>{totalMember}</span>
                            </Box> : null}
                            {addMember}
                            {search}
                            {filter}
                            {component1 ?
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: mobile ? "flex-end" : "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    [theme.breakpoints.down('sm')]: {
                                        width: '100%',
                                        fontSize: '16px',
                                        borderTop: '1px solid #E5E5E5',
                                        paddingTop: '12px',
                                        textAlign: 'right',
                                        mr: 1
                                    }
                                }}
                                >
                                    {component1}
                                </Box> : null}
                        </Box>
                        <Box
                            className="scrollOnHover scrollBar"
                            sx={{
                                background: '#ffffff',
                                border: !mobile ? '1px solid #E5E5E5' : "none",
                                marginTop: '10px',
                                position: 'relative',
                                overflowY: {
                                    md: "auto"
                                },
                                pb: {
                                    md: 2
                                },
                                height: {
                                    md: profileLink ? "auto" : !mobile ? "calc(100vh - 195px)" : "calc(100vh - 70px)"
                                },
                                [theme.breakpoints.down('md')]: {
                                    px: 1,
                                    pb: 2
                                }
                            }}
                            onScroll={(e) => {
                                if (handleScroll) {
                                    handleScroll(e)
                                }
                            }}
                        >
                            {props.children}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </HeaderWrapper>
    )
}
UserLayout.propTypes = {
    title: PropTypes.any,
    vatId: PropTypes.object,
    handleVat: PropTypes.func,
    component1: PropTypes.any,
    mobileTitle: PropTypes.element,
    children: PropTypes.any,
    titleCom: PropTypes.any,
    handleScroll: PropTypes.func,
}

export default connect((state: any) => ({ ...state }), {
    getUserDataRequest,
})(UserLayout);