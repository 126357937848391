import { adsTypes, adsTypesAuction, vehicles, userTypes, myVehicleStatus } from "config/variables";
import { routes } from "routes/routes";
import { AccountConfirmationPage } from "../AccountConfirmationPage/Loadable";
import { AddVehiclePage } from "../AddVehiclePage/Loadable";
import { AdsAuctionDetailsPage } from "../AdsAuctionDetailsPage/Loadable";
import { AdsDetailsPage } from "../AdsDetailsPage/Loadable";
import { AdsHomePage } from "../AdsHomePage/Loadable";
import { AuctionPage } from "../AuctionPage/Loadable";
import { AuctionplacePage } from "../AuctionplacePage/Loadable";
import { ChangeEmailAccountConfirmationPage } from "../ChangeEmailAccountConfirmationPage/Loadable";
import { ChangePlanPage } from "../ChangePlanPage/Loadable";
import { CompanyPage } from "../CompanyPage/Loadable";
import { CompanyPrivacyPage } from "../CompanyPrivacy/Loadable";
import { CompanyProfilePage } from "../CompanyProfilePage";
import { CompanySettingPage } from "../CompanySettingPage/Loadable";
import { ConfirmSoldPage } from "../ConfirmSoldPage/Loadable";
import { CreateCompanyPage } from "../CreateCompanyPage/Loadable";
import { CreateRolePage } from "../CreateRolePage/Loadable";
import { DocumentPage } from "../DocumentPage/Loadable";
import { EditRolePage } from "../EditRolePage/Loadable";
import { EmployeeAddPage } from "../EmployeeAddPage/Loadable";
import { EmployeesPage } from "../EmployeesPage/Loadable";
import { FaqPage } from "../Faqs/Loadable";
import { ForgotPage } from "../ForgotPage/Loadable";
import { InvoicePage } from "../InvoicePage/Loadable";
import { LoginPage } from "../LoginPage/Loadable";
import { MyAdsPage } from "../MyAdsPage/Loadable";
import { MyProfilePage } from "../MyProfilePage/Loadable";
import { NotificationPage } from "../NotificationPage";
import { PasswordResetPage } from "../PasswordResetPage/Loadable";
import { PaymentMethodePage } from "../PaymentMethodePage/Loadable";
import { PrivacyPage } from "../PrivacyPage/Loadable";
import { ProfilePage } from "../ProfilePage/Loadable";
import { ProfileSettingPage } from "../ProfileSettingPage/Loadable";
import { PromoEmailingPage } from "../PromoEmailingPage/Loadable";
import { RegistrationPage } from "../RegistrationPage/Loadable";
import { RoleListPage } from "../RoleListPage/Loadable";
import { RoleDetailPage } from "../RoleDetailPage/Loadable";
import { VehicleSummaryPage } from "../VehicleSummaryPage/Loadable";
import { VarificationPage } from "../VerificationPage/Loadable";
import { VerifyInvitationPage } from "../VerifyInvitationPage/Loadable";
import { BecomePartner } from "../BecomePartner";
import ChatScreen from "../Chat";
import PublicProfileScreen from "../PublicProfile";
import LicensePlate from "../LicensePlatePage";
import { PublicAds } from "../PublicClassicAds";
import { PublicAuctionAds } from "../PublicAuctionAds";
import { MyVehicleSalePage } from "../MyVehicleSalePage";
import { RouteTypes } from "config/appConfig";
import ContactUs from "../ContactUsPage";
import { Shop } from "../Shop/Loadable";
import { ShopConfigure } from "../ShopConfigure/Loadable";
import { HotDeals } from "../HotDealsPage/Loadable";
import { HotDealsListing } from "../HotDealsListingPage/Loadable";
import { HotDealDetailsPage } from "../HotDealDetailsPage";
import CreditPage from "../CreaditPage";
import CreditPayment from "../CreditPayment";
import { HotDealsOrderReservation } from "../HotDealsOrderReservationPage/Loadable";
import MyWallet from "../MyWallet";
import PaymentSuccess from "app/components/PaymentResponse/PaymentSuccess";
import PaymentFailure from "app/components/PaymentResponse/PaymentFailure";
import InvoicPropCompoent from "../InvoicePage/components/InvoicPropCompoent";
import StripeConnectComponent from "app/Component2/GlobalComponent/StripeConnectComponent";
import SubscriptionPaymentResponse from "app/components/PaymentResponse/SubscriptionPaymentResponse";
import VehicleCommissionInvoiceDetail from "../InvoicePage/components/VehicleCommissionInvoiceDetail";

// ?/:model_id?/:brand_id
export const publicRouListing = [
  {
    path: routes.vehicleRoutes.publicAds + `/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})/:brand_id?/:model_id?/:country_id?`,
    component: PublicAds,
    type: RouteTypes.public
  },
  { path: routes.vehicleRoutes.confirmSold, component: ConfirmSoldPage, type: RouteTypes.public },
  { path: routes.vehicleRoutes.adsHome, component: AdsHomePage, type: RouteTypes.public },
  { path: routes.publicRoutes.shop + `/:ads_type?/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})?/:brand_id?/:model_id?`, component: Shop, type: RouteTypes.public },
  { path: routes.authRoutes.accountConfirmation, component: AccountConfirmationPage, type: RouteTypes.public },
  { path: routes.vehicleRoutes.addVehicle, component: AddVehiclePage, type: RouteTypes.public },
  { path: routes.vehicleRoutes.publicAds + "/:slug", component: AdsDetailsPage, type: RouteTypes.public },
  { path: routes.vehicleRoutes.auction + `/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})/:country_id?`, component: AuctionPage, type: RouteTypes.public },
  {
    path: routes.vehicleRoutes.auctionAd + `/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})/:id/:brand_id?/:model_id?`,
    component: PublicAuctionAds,
    type: RouteTypes.public
  },
  { path: routes.vehicleRoutes.auctionDetails, component: AdsAuctionDetailsPage, type: RouteTypes.public },
  { path: routes.publicRoutes.faq, component: FaqPage, type: RouteTypes.public },
  { path: routes.companyRoutes.invitationVerificationUser, component: VerifyInvitationPage, type: RouteTypes.public },
  { path: routes.publicRoutes.promoCodeEmailing, component: PromoEmailingPage, type: RouteTypes.public },
  { path: routes.publicRoutes.terms + "/:time?", component: DocumentPage, type: RouteTypes.public },
  { path: routes.publicRoutes.privacy_policy + "/:time?", component: DocumentPage, type: RouteTypes.public },
  { path: routes.publicRoutes.data_protection + "/:time?", component: DocumentPage, type: RouteTypes.public },
  { path: routes.publicRoutes.legal_notice + "/:time?", component: DocumentPage, type: RouteTypes.public },
  { path: routes.publicRoutes.become_partner, component: BecomePartner, type: RouteTypes.public },
  { path: routes.publicRoutes.legal_documents + "/:time?", component: DocumentPage, type: RouteTypes.public },
  { path: `/:userType(${userTypes.pro}|${userTypes.user})/:slug/:tab?`, component: PublicProfileScreen, type: RouteTypes.public },
  { path: routes.publicRoutes.contactUs, component: ContactUs, type: RouteTypes.public },
  { path: routes.publicRoutes.hotDeals + '/:country_id?', component: HotDeals, type: RouteTypes.public },
  { path: routes.publicRoutes.hotDealEvent + '/:deal?', component: HotDealsListing, type: RouteTypes.public },
  { path: routes.publicRoutes.hotDeal + '/:slug', component: HotDealDetailsPage, type: RouteTypes.public },

  // ]
  // export const PrivateRoutesListing = [
  // {path:locale+routes.userRoutes.userProfile, component: ProfilePage, type:privateRou},
  { path: routes.userRoutes.userProfileEdit, component: ProfilePage, type: RouteTypes.private },
  { path: routes.userRoutes.profileSetting, component: ProfileSettingPage, type: RouteTypes.private },
  { path: routes.companyRoutes.createCompany, component: CreateCompanyPage, type: RouteTypes.private },
  { path: routes.companyRoutes.company, component: CompanyPage, type: RouteTypes.private },
  { path: routes.userRoutes.userPrivacy, component: PrivacyPage, type: RouteTypes.private },
  { path: routes.userRoutes.myProfile, component: MyProfilePage, type: RouteTypes.private },
  { path: routes.userRoutes.idVerfication, component: VarificationPage, type: RouteTypes.private },
  { path: routes.userRoutes.changeEmailAccountConfirmation, component: ChangeEmailAccountConfirmationPage, type: RouteTypes.public },

  // ]
  // export const ProfessonalRoutesListing = [
  { path: routes.companyRoutes.companyProfile, component: CompanyProfilePage, type: RouteTypes.professional },
  { path: routes.companyRoutes.companySettings, component: CompanySettingPage, type: RouteTypes.professional },
  { path: routes.companyRoutes.createRole, component: CreateRolePage, type: RouteTypes.professional },
  { path: routes.companyRoutes.invoices, component: InvoicePage, type: RouteTypes.professionPrivate },
  { path: routes.companyRoutes.invoices + '/:invoice_id?', component: InvoicPropCompoent, type: RouteTypes.professionPrivate },
  { path: routes.companyRoutes.vehicleSalesInvoices + '/:invoice_id?', component: VehicleCommissionInvoiceDetail, type: RouteTypes.professionPrivate },
  { path: routes.companyRoutes.editRole + '/:id', component: EditRolePage, type: RouteTypes.professional },
  { path: routes.companyRoutes.EditWorkingHours, component: CompanySettingPage, type: RouteTypes.professional },
  { path: routes.companyRoutes.companyPrivacy, component: CompanyPrivacyPage, type: RouteTypes.professional },
  { path: routes.companyRoutes.paymentMethod, component: PaymentMethodePage, type: RouteTypes.professionPrivate },
  { path: routes.companyRoutes.changePlan, component: ChangePlanPage, type: RouteTypes.professional },
  { path: routes.professionalSettingsRoutes.employeesList, component: EmployeesPage, type: RouteTypes.professional },
  { path: routes.professionalSettingsRoutes.employeesAdd, component: EmployeeAddPage, type: RouteTypes.professional },
  { path: routes.showCaseRoutes.showcaseConfigure, component: ShopConfigure, type: RouteTypes.professional },
  { path: routes.companyRoutes.roleList, component: RoleListPage, type: RouteTypes.professional },
  { path: routes.companyRoutes.role + `/:id`, component: RoleDetailPage, type: RouteTypes.professional },
  { path: routes.companyRoutes.stripeConnect, component: StripeConnectComponent, type: RouteTypes.professional },
  // ]

  // export const authRoutersList = [
  { path: routes.authRoutes.register, component: RegistrationPage, type: RouteTypes.auth },
  { path: routes.authRoutes.login, component: LoginPage, type: RouteTypes.auth },
  { path: routes.authRoutes.forgotPassword, component: ForgotPage, type: RouteTypes.auth },
  { path: routes.authRoutes.passwordReset, component: PasswordResetPage, type: RouteTypes.auth },
  // ]
  // export const PrivateProfessionalRouteListing = [
  { path: routes.vehicleRoutes.myAds + `/:vehicle/:ads(${adsTypes.classic}|${adsTypes.auction}|${adsTypes.park}|${adsTypes.hotDeals})?/:auction_ads(${adsTypesAuction.live}|${adsTypesAuction.action}|${adsTypesAuction.history})?`, component: MyAdsPage, type: RouteTypes.professionPrivate },
  { path: routes.vehicleRoutes.auctionPlace + `/:auction_place_type`, component: AuctionplacePage, type: RouteTypes.professionPrivate },
  { path: routes.vehicleRoutes.vehicleSummary, component: VehicleSummaryPage, type: RouteTypes.professionPrivate, exact: 'false' },
  { path: routes.companyRoutes.notification, component: NotificationPage, type: RouteTypes.professionPrivate },
  { path: routes.publicRoutes.license_plate, component: LicensePlate, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.chat, component: ChatScreen, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.myVehicles + `/:my_vehicle_status(${myVehicleStatus.createOrder}|${myVehicleStatus.confirmSale})/:vehicle_id`, component: MyVehicleSalePage, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.credits, component: CreditPage, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.creditsPayment, component: CreditPayment, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.myWallet, component: MyWallet, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.creditBuySuccess, component: PaymentSuccess, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.creditBuyFail, component: PaymentFailure, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.vehiclePaymentSuccess, component: PaymentSuccess, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.vehiclePaymentFail, component: PaymentFailure, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.subscriptionBuySuccess, component: SubscriptionPaymentResponse, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.subscriptionBuyFail, component: SubscriptionPaymentResponse, type: RouteTypes.professionPrivate },
  { path: routes.userRoutes.orderReservation + '/:slug', component: HotDealsOrderReservation, type: RouteTypes.professionPrivate },
]