import React, { useEffect, useMemo } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import PaginationComponent from 'app/pages/PublicClassicAds/components/ad/Pagination';
import { translations } from 'locales/translations';
import { creditsHistoryRequest } from 'store/actions/user-actions';
import { routes } from 'routes/routes';
import { creditExpensType } from 'config/constants';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';
import { SvgRefundedCoins } from 'app/components/svgicons/svgNew';


function TableHeadRow(props) {
    return (<TableRow>
        <TableCell className='tableCellStyle'>{props.translatedTexts.date}</TableCell>
        <TableCell className='tableCellStyle' align="left">{props.translatedTexts.reason}</TableCell>
        <TableCell className='tableCellStyle' align="left">{props.translatedTexts.amount}</TableCell>
        <TableCell className='tableCellStyle' align="left">{props.translatedTexts.validUntil}</TableCell>
    </TableRow>);
}


const CreditHistory = () => {
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const currentPage = new URLSearchParams(location.search).get('page');

    // Redux states
    const { creditHistoryLastPage, creditHistoryList, loading } = useSelector((state: RootStateOrAny) => state.user);

    useEffect(() => {
        dispatch(creditsHistoryRequest({ Page: parseInt(currentPage) }));
    }, [currentPage, dispatch]);

    // Memoized translations
    const translatedTexts = useMemo(() => ({
        date: t(translations.HOT_DEALS.DATE),
        reason: t(translations.HOT_DEALS.REASON),
        amount: t(translations.HOT_DEALS.AMT_OF_CREDIT),
        noHistory: t(translations.HOT_DEALS.NO_HISTORY_AVAILABLE),
        expired: t(translations.HOT_DEALS.EXPIRED),
        purchased: t(translations.HOT_DEALS.PURCHASED),
        refunded: t(translations.HOT_DEALS.REFUNDED),
        licenseSearch: t(translations.HOT_DEALS.LICENSE_SEARCH),
        creditHistory: t(translations.HOT_DEALS.CREDIT_HISTORY),
        validUntil: t(translations.HOT_DEALS.VALID_UNTIL),
        creditsRefunded: t(translations.HOT_DEALS.CREDITS_REFUNDED)
    }), [t]);

    // Function to get the reason text
    const getReasonText = (type, invoiceId) => {
        switch (type) {
            case creditExpensType.EXPIRED:
                return translatedTexts.expired;
            case creditExpensType.PURCHASED:
                return <Link to={routes.companyRoutes.invoices + '/' + invoiceId}>
                    {translatedTexts.purchased}
                </Link>;
            case creditExpensType.LICENSESEARCH:
                return translatedTexts.licenseSearch;
            default:
                return null;
        }
    };

    const getTime = (time) => {
        // convertime (utc/CET ) into local time
        return moment(time).local().format('DD/MM/YYYY HH:mm')
    }

    // Table row component
    const TableRowComponent = ({ creditHistory, index }) => (

        <TableRow key={index + 'history'}>
            <TableCell className={`${parseInt(creditHistory.amount) < 0 ? 'tableRowShaded' : 'tableRow'} tableCellStyle dateStyle`}>
                {moment(creditHistory.created_at).format('DD/MM/YYYY')}
            </TableCell>
            <TableCell className={`${parseInt(creditHistory.amount) < 0 ? 'tableCellShaded' : ''} tableCellStyle ${creditExpensType.ADDED == creditHistory.type ? 'reason reason-style' : 'reason'}`} align="left">
                {getReasonText(creditHistory.type, creditHistory.invoice_id) ?
                    getReasonText(creditHistory.type, creditHistory.invoice_id) :
                    creditHistory?.reason_id ?
                        creditHistory?.reason?.name :
                        (
                            <Link to={routes.publicRoutes.hotDeal + '/' + creditHistory?.ad_vehicle?.slug}>
                                {creditHistory?.ad_vehicle?.owner_vehicle?.full_title || '-'}
                                {creditHistory.type === creditExpensType.REFUNDED ? <Tooltip title={translatedTexts.creditsRefunded}>
                                    <SvgRefundedCoins className='refundedicon' />
                                </Tooltip> : ""}
                            </Link>
                        )}
            </TableCell>
            <TableCell className={`${parseInt(creditHistory.amount) < 0 ? 'tableCellShaded' : ''} tableCellStyle`} align="left">
                {parseInt(creditHistory.amount)}
            </TableCell>
            <TableCell className={`${parseInt(creditHistory.amount) < 0 ? 'tableCellShaded' : ''} tableCellStyle`} align="left">
                {creditHistory.validity ? getTime(creditHistory.validity) : "-"}
            </TableCell>
        </TableRow>
    );

    return (
        <Box className='cartobike-history'>
            {loading ? <ContentLoader /> : null}
            <Box className='flexColumn'>
                <h2 className='credit-headers'>{translatedTexts.creditHistory}</h2>
            </Box>
            <Box>
                <TableContainer className='tableBody' component={Paper}>
                    <Table sx={{ minWidth: '100%', border: 'none' }} aria-label="simple table" className='table'>
                        {creditHistoryList.length > 0 ? (
                            <TableHead>
                                <TableHeadRow translatedTexts={translatedTexts}></TableHeadRow>
                            </TableHead>
                        ) : null}
                        <TableBody>
                            {creditHistoryList.length > 0 ? (
                                creditHistoryList.map((creditHistory, index) => (
                                    <TableRowComponent creditHistory={creditHistory} index={index} key={`history-${index}`} />
                                ))
                            ) : !loading && (
                                <Typography>{translatedTexts.noHistory}</Typography>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {creditHistoryLastPage > 1 ? <PaginationComponent lastPage={creditHistoryLastPage} /> : null}
            </Box>
        </Box>
    );
};

export default CreditHistory;

