import { Button, Tooltip, Typography } from '@mui/material';
import { SvgInfoIcon } from '../svgicons/svgNew';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import InfoModal from '../InfoModal/info';
import { useState } from 'react';
import { InfoPopup } from 'app/pages/VehicleSummaryPage/Component/Experties/InfoPopup';
import { localstorageKey } from 'config/constants';


const BlockedCounter = () => {

    const { t } = useTranslation()
    const [openPopup, setOpenPopup] = useState<boolean>(false)

    const PopupBody = () => {
        return (
            <Typography sx={{ lineHeight: '25px', padding: '0px 25px 10px 25px', color: '#999', textAlign: 'justify', fontWeight: 400, fontSize: '15px', fontFamily: 'Inter' }}>
                {t(translations.HOT_DEALS.RESERVATION_BLOCKED_INFO)}
            </Typography>
        )
    }

    return (
        <>
            <span className='reservationBlocked'>{t(translations.HOT_DEALS.RESERVATION_BLOCKED)}
                <InfoModal header={''} content={t(translations.HOT_DEALS.RESERVATION_BLOCKED_INFO, { time: localStorage.getItem(localstorageKey.purchaseBlockTime) })} />
            </span>
            <Button className="purchaseCredits" onClick={() => setOpenPopup(true)} >
                {t(translations.HOT_DEALS.RESERVATION_IN_PROGRESS)}
            </Button>
            <InfoPopup open={openPopup} handleClosePopup={() => setOpenPopup(false)} PopupBody={PopupBody} />
        </>

    )
}

export default BlockedCounter