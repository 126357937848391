import React, { useEffect } from 'react'
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { SvgChevronLeft, SvgChevronRight } from 'app/components/svgicons/svg';
import { colorNew } from 'styles/StyleConstants';
import { useDeviceType } from 'app/Hooks/useMediaQuery';


const List = styled('ul')({
    listStyle: 'none',
    padding: 2,
    margin: 2,
    display: 'flex',
});

const PaginationComponent = ({ lastPage }) => {

    const location = useLocation();
    let url = new URL(window.location.href);
    const queryParams = new URLSearchParams(location.search)
    const selectedPage = queryParams.has('page') && queryParams.get('page') !== null ? parseInt(queryParams.get('page')) : 1;
    const [isSmallDevice] = useDeviceType()

    const { items } = usePagination({
        count: lastPage,
        defaultPage: selectedPage,
        boundaryCount: 0
    });

    const newUrl = (page) => {
        if (url && url.search.includes('page')) {
            url.searchParams.set('page', page)
            return url.search
        }

        url.searchParams.append('page', page);
        return url.search
    }

    const nextUrl = (page) => {
        if (page && page < lastPage) {
            if (url && url.search.includes('page')) {
                url.searchParams.set('page', parseInt(page) + 1)
                return url.search
            }
        }


        url.searchParams.append('page', 2)
        return url.search

    }

    const previousUrl = (page) => {
        if (page > 1) {
            if (url && url.search.includes('page') && page > 1) {
                url.searchParams.set('page', page - 1)
                return url.search
            }

        }

    }


    const handleScroll = () => {
        let elem = document.getElementById('adsContainer')
        if (elem) {
            window.scrollTo(0, 0)

        }
    }

    useEffect(() => {
        handleScroll()
    }, [selectedPage])

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            'ul': {
                alignItems: 'center',
                gap: '12px',
            },
            'li': {
                mx: 1,
            },
            '.page': {
                alignItems: 'center',
                color: 'var(--lcui-black)',
                display: 'flex',
                justifyContent: 'center',
                outline: 'none',
                textDecoration: 'none',
                userSelect: 'none',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                border: '2px solid #F2F2F2',
                '.svg': {
                    'path': {
                        stroke: "#000 !important",
                        fill: '#000'
                    }
                }
            },
            '.active': {
                background: `${colorNew.primaryColor}`,
                color: '#fff',

            },
        }}
            className='pagination'
        >

            <List>
                {items.map(({ page, type, selected, ...item }, index) => {

                    let children = '';
                    if (type === 'start-ellipsis') {
                        children = (<Link {...item} className={`page`} to={newUrl(1)} key={page + type}>{1}</Link>)
                    } else if (type === 'end-ellipsis') {
                        children = <span className='page' key={page + type}> . . .</span>
                    } else if (type === 'page') {
                        children = (
                            <Link key={page + type} {...item} className={`page ${(page == selectedPage || (!selectedPage && page == 1)) ? 'active' : ''}`} to={newUrl(page)}>{page}</Link>
                        );
                    } else {
                        children = (
                            type !== 'next' ?
                                selectedPage == 1 ? '' : <Link {...item} className='page' aria-label={`Previous page`} rel={'prev'} to={() => previousUrl(selectedPage)} key={page + type}>
                                    <SvgChevronLeft />
                                </Link> :
                                selectedPage != lastPage ? <Link {...item} className='page' aria-label={`Next page`} rel={'next'} to={() => nextUrl(selectedPage)} key={page + type}>
                                    <SvgChevronRight />
                                </Link> : ''
                        );
                    }

                    return children;
                })}
            </List>
        </Box>
    );
}

export default PaginationComponent;

