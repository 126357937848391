import { lazyLoad } from 'utils/loadable';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';
export const RegistrationPage = lazyLoad(
  () => import('./index'),
  module => module.RegistrationPage,
  {
    fallback: (
      <ContentLoader />
    ),
  },
);
