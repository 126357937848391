import { SvgCloseIcon } from 'app/components/svgicons/svgNew'
import { translations } from 'locales/translations'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colorNew } from 'styles/StyleConstants'

const CookiePolicy = ({setShowpop}) => {
    const {t} = useTranslation()
    return (
        <Wrapper>
            <button onClick={() => setShowpop(false)}><SvgCloseIcon /></button>
            <h1>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TEXT)}</h1>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_ONE)}</p>
            <p>
                <Trans i18nKey={t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWO)} components={{ br: <br /> }} />
            </p>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_THREE)}</p>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_FOUR)}</p>
            <h2>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_FIVE)}</h2>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_SIX)}</p>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_SEVEN)}</p>
            <h2>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_EIGHT)}</h2>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_NINE)}</p>
            <h2>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TEN)}</h2>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_ELEVEN)}</p>
            <ol>
                <li>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWELVE)}</li>
                <li>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_THIRTEEN)}</li>
            </ol>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_FOURTEEN)}</p>
            <h2>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_FIFTEEN)}</h2>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_SIXTEEN)}</p>
            <h2>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_SEVENTEEN)}</h2>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_EIGHTEEN)}</p>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_NINETEEN)}</p>
            <h2>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTY)}</h2>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTYONE)}</p>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTYTWO)}</p>
            <h2>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTYTHREE)}</h2>
            <p>
                <Trans i18nKey={t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTYFOUR)} components={{ br: <br /> }} />
            </p>
            <ul>
                <li>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTYFIVE)}</li>
                <li>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTYSIX)}</li>
            </ul>
            <h2>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTYSEVEN)}</h2>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTYEIGHT)}</p>
            <h2>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_TWENTYNINE)}</h2>
            <p>{t(translations.PRIVACY_POLICY_SCREEN.PRIVACY_POLICY_SCREEN_THIRTY)}</p>
        </Wrapper>
    )
}

export default CookiePolicy
const Wrapper = styled.div`
    background-color: ${colorNew.colorWhite};
    padding: 15px;
    width:calc(100% - 30px);
    height:calc(100% - 30px);
    max-width: 700px;
    overflow-y: auto;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    h1{
        margin-bottom: 15px;
    }
    h2{
        margin-bottom: 0;
        padding-bottom: 0;
    }
    p{
        padding-top: 0;
        margin-top: 0;
    }
    button{
        background: #000;
        width: 35px;
        height: 35px;
        position: fixed;
        right: 0;
        top: 4px;
        cursor:pointer;
        z-index: 1;
            svg{
                path{
                    stroke:${colorNew.colorBlack};
                }
            }
    }
`