import React from 'react'
import styled from 'styled-components'
import DarkLogo from 'app/assets/Images/logo.43549134.png'
import { SvgCheck, SvgClose } from 'app/components/svgicons/svg'
import { color, colorNew } from 'styles/StyleConstants'
import NotFound from 'app/components/RepeatComponents/NoFound'
import userPlaceholderImage from 'app/assets/Images/userplaceholder.png'
import moment from 'moment'
import { SvgTrashIcon } from 'app/components/svgicons/svgNew'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { CircularLoader } from 'app/components/ContantLoader/ContentLoader'

const CartoBikeNotification = (props:any) => {
    const {notificationList} = props
    const {loading} = useSelector((state)=>state.company)
    const {t} = useTranslation()
    return (<>
        {loading ? <CircularLoader style={{}} /> :<Box  sx={{
            padding:'0 20px'
        }}>
            {props.notificationList && props.notificationList.length > 0 ? 
              props.notificationList.map((notification, i) => 
            <Wrapper>
                <div onClick={()=>props.handleOpenNotifications(notification)} className="item-all">
                    {moment(notification.created_at).isSame(moment(), 'day')?
                        <span className="d-inline-block notification-time">{moment(notification.created_at).format("h:mm a")}</span>
                        :
                        <span className="d-inline-block notification-time">{moment(notification.created_at).format("DD/MM/yyyy")}</span>
                    }
                    <div className="notification_box">
                        <img src={notification.image==null?userPlaceholderImage:notification.image} alt={'user'} width={45} height={45} className="align-self-start" />
                        <div className="notification-content px-2">
                            <div className="notification-content-text">
                                <h2>{notification.title}</h2>
                                <p>{notification.body}</p>
                            </div>                            
                        </div>
                    </div>
                </div>
                <button onClick={()=>props.deleteNotification(notification.id)}><SvgTrashIcon/></button>
            </Wrapper>
        )
        :<>                     
            <NotFound />
         </>
      }        
        </Box>}</>
    )
}
const Wrapper = styled.div`
    position: relative;
    .btns{
        display:flex;
        gap:10px;
    }
    &:hover{
        > button{
            opacity:1;
            width:50px;
        }
        /* .item-all{
            transform: translateX(-150px);
        } */
    }
    > button {
        background-color:#DC4040;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        font-weight: 400;       
        font-size: 14px;
        color:${color.colorWhite};
        border:0;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity:0;
        overflow:hidden;
        white-space: nowrap;
        padding: 16px;
        transition: opacity .2s ease-in-out, 
        width 0.3s ease-in-out;
        cursor: pointer;
        transform: translate(191%, -85%);        
    }
    .item-all{
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.04) ;
        border-radius: 6px; 
        z-index:1;
        transition: .3s ease-in-out;
        padding: 16px;
        margin-bottom: 20px;
        img{
            object-fit:cover;
            border-radius: 100%;
            min-height: 45px;
            height: 48px;
        }
        .notification-time{
            position:absolute;
            right: 15px;
            top:6px;
        }
        .notification-content{
            h2 {
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;
                letter-spacing: 0.02em;
                color: #000000;
                margin-bottom: 0;
            }
            p{
                color: ${colorNew.colorGrayOnButton};
            }
        }
        .notification-action{
            >div{
                button{
                    border-radius:8px;
                    transition:0.2s ease-in-out;
                    &:hover{
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
                    }
                    span{
                        width:20px;
                        height:20px;
                        border-radius:50px;
                    }
                    &.accept-btn{
                        padding:5px;
                        margin-right: 10px;
                        border:1px solid #42B72A;
                        span{
                            background:#42B72A;
                        }
                    }
                    &.cancel-btn{
                        border:1px solid #FF0000;
                        padding:5px;
                        span{
                            background:#FF0000;
                        }
                    }
                    svg{
                        width: 16px;
                        height: 14px;
                        path{
                            stroke:${color.colorWhite};
                        }
                    }
                }
            }
        }
    }
`

export default CartoBikeNotification
