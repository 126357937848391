import { Box } from '@mui/material'
import SearchDropdownForProduct from 'app/components/CustomFields/searchDropdownForProduct'
import { urlParamInterface } from 'config/interface'
import { adsTypes, adsTypesAuction, productTypes, productTypesInverse, vehicles } from 'config/variables'
import { translations } from 'locales/translations'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'

const SelectProduct = () => {

    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const { vehicle, ads, auction_ads, ads_type, slug }: urlParamInterface = useParams();

    const { loadingForce } = useSelector((state: RootStateOrAny) => state.vehicle)

    const [dropdownValues, _dropdownValues] = useState<Array<Object>>([])

    const [isMyAdsPage] = useState(() => location.pathname.includes(routes.vehicleRoutes.myAds))
    const [isShopPage] = useState(() => location.pathname.includes(routes.publicRoutes.shopURL))

    const [productType, _productType] = useState<number>(() => {
        if (vehicle === vehicles.step) {
            return productTypes.step
        }
        else if (vehicle === vehicles.bike) {
            return productTypes.bike
        }
        else if (vehicle === vehicles.motorcycles) {
            return productTypes.motorcycles
        }
        else {
            return productTypes.car
        }
    })

    // returns route name on basis of product ID
    const getProductName = (selectedProduct) => {
        if (selectedProduct == productTypes.step) {
            return `/${productTypesInverse[productTypes.step]}`
        }
        if (selectedProduct == productTypes.bike) {
            return `/${productTypesInverse[productTypes.bike]}`
        }
        if (selectedProduct == productTypes.motorcycles) {
            return `/${productTypesInverse[productTypes.motorcycles]}`
        }
        if (selectedProduct == productTypes.car) {
            return `/${productTypesInverse[productTypes.car]}`
        }
    }

    useEffect(() => {
        const productsObj = localStorage.getItem('vehicleTypeData')
        if (productsObj) {
            _dropdownValues(JSON.parse(productsObj).items)
        }
    }, [loadingForce])

    // Navigation to particular page based on product choosed by user in filters
    const handleNavigate = (value) => {
        if (isMyAdsPage) {
            const myAdsVehicleRoute = `${routes.vehicleRoutes.myAds}${getProductName(value)}`;
            const myAdsVehicleAuction = `${myAdsVehicleRoute}${routes.vehicleRoutes.auction}`;
            if (ads == adsTypes.park) {
                history.push(`${myAdsVehicleRoute}${routes.vehicleRoutes.park}`)
                return
            }
            if (ads == adsTypes.classic) {
                history.push(`${myAdsVehicleRoute}${routes.vehicleRoutes.classic}`)
                return
            }
            if (ads === adsTypes.hotDeals) {
                history.push(`${myAdsVehicleRoute}${routes.vehicleRoutes.hotDeals}`)
                return
            }
            if (ads == adsTypes.auction) {
                if (auction_ads == adsTypesAuction.live) {
                    history.push(`${myAdsVehicleAuction}${routes.vehicleRoutes.live}`)
                    return
                }
                if (auction_ads == adsTypesAuction.action) {
                    history.push(`${myAdsVehicleAuction}${routes.vehicleRoutes.action}`)
                    return
                }
                if (auction_ads == adsTypesAuction.history) {
                    history.push(`${myAdsVehicleAuction}${routes.vehicleRoutes.history}`)
                    return
                }
            }
        }
        if (isShopPage) {
            const shopRoute = `${routes.publicRoutes.shopURL}/${slug}`;
            if (ads_type == adsTypes.classic) {
                history.push(`${shopRoute}${routes.vehicleRoutes.classic}${getProductName(value)}`)
                return
            }
            if (ads_type == adsTypes.auction) {
                history.push(`${shopRoute}${routes.vehicleRoutes.auction}${getProductName(value)}`)
            }
        }
    }

    const handleProductType = (value) => {
        if (productType == value) {
            return
        }
        _productType(value)
        handleNavigate(value)
    }

    return (
        <Box sx={{ gap: '24px', mt: 1 }} className='flexColumn'>
            <SearchDropdownForProduct title={t(translations.FILTER_COMPONENT.SELECT_PRODUCT)} list={dropdownValues ?? []} value={productType} handleChange={(value) => handleProductType(value)} />
        </Box>
    )
}

export default SelectProduct