import { Box, Button } from '@mui/material'
import { SvgCartobikeCredit } from 'app/components/svgicons/svgNew'
import { translations } from 'locales/translations'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { creditsBalanceRequest } from 'store/actions/user-actions'
import LearnMorePopup from './LearnMorePopup'
import { handleBuyCredits } from 'config/utils'

const CreditBalance = () => {
    //redux states
    const { myWalletBalance } = useSelector((state: RootStateOrAny) => state.auth)

    // hooks
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(creditsBalanceRequest())
    }, [])

    const LearmMoreCreditContent = [
        <Trans
            i18nKey={t(translations.CTB_CREDIT.FLASH_SALES)}
            components={{
                bold: <strong className='bold-text' />,
            }}
        />,
        <Trans
            i18nKey={t(translations.CTB_CREDIT.NO_RESERVATION)}
            components={{
                bold: <strong className='bold-text' />,
            }}
        />,
        <Trans
            i18nKey={t(translations.CTB_CREDIT.LICENSE_PLATE_SEARCH)}
            components={{
                bold: <strong className='bold-text' />,
            }}
        />
    ]

    return (
        <>
            <Box className='flexRow justifyContentBetween alignItemsCenter cartobike-credit'>
                <Box className='flexColumn '>
                    <h1 className='credit-headers'>{t(translations.HOT_DEALS.CARTOBIKE_CREDIT)}</h1>
                    <span className='ctb-description'>{t(translations.HOT_DEALS.CARTOBIKE_CREDIT_DESCRIPTION)}</span>
                </Box>
                <Box className='learn-more'>
                    <LearnMorePopup header={t(translations.HOT_DEALS.CARTOBIKE_CREDIT)} contentList={LearmMoreCreditContent} buttonText={t(translations.HOT_DEALS.LEARN_MORE)} />
                </Box>
            </Box>
            <Box className='flexRow justifyContentBetween alignItemsCenter cartobike-balance'>
                <Box className={'credit-bal '}>
                    <h2 className='credit-headers'>{t(translations.HOT_DEALS.AVAIL_BALANCE)}</h2>
                    <span className='flexRow alignItemsCenter available-credits'>{myWalletBalance} <SvgCartobikeCredit /></span>
                </Box>
                <Box className='learn-more'>
                    <Button variant="contained" className='button-credits' onClick={() => handleBuyCredits(history, history.location.pathname)}>
                        {t(translations.HOT_DEALS.BUY_CREDITS)}
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default CreditBalance