import React from 'react'
import { Box, Skeleton } from '@mui/material'

const CreditSkeleton = ({ }) => {

    return (
        <Box className='credit-section' >
            <Box className='flexRow justifyContentBetween'>
                <Box>
                    <Skeleton variant="rectangular" sx={{ width: "150px" }} />
                    <Box className='' sx={{ py: 2, width: "150px" }}>
                        <Skeleton variant="rectangular" height='25px' />
                    </Box>
                </Box>
                <Box>
                    <Skeleton variant="rectangular" height='50px' width='50px' />
                </Box>
            </Box>
            <Box className='flexRow buy-now-btn' sx={{ mt: 1 }}>
                <Skeleton variant="rectangular" width='100%' height='25px' sx={{ ml: 0 }} />
            </Box>
        </Box>)
}

export default CreditSkeleton