import { Box, Modal } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components'
import { colorNew } from 'styles/StyleConstants'
import ButtonCustom from './ButtonCustom'
import { useTranslation } from 'react-i18next'
import { getCookie, setCookie } from 'config/variables'
import { translations } from 'locales/translations'
import CookiePolicy from './CookiePolicy';
import GoogleAnalytics from './GoogleAnalytics';
import LaunchingHotdealPopup from 'app/components/GlobalComponent/LaunchingHotdeal/LaunchingHotdealPopup';
import { Nullable } from 'app/pages/Chat/interface/interface';

const CookiesComponent = (props: any) => {

    const { t, i18n } = useTranslation();

    const [showCookieModal, _showCookieModal] = useState(false);

    const [trackingAllowed, _trackingAllowed] = useState<Nullable<boolean>>(() => {
        const cookies = getCookie('agree');
        return cookies !== '' ? true : null;
    });

    const [cookieResponse, _cookieResponse] = useState<boolean>(() => {
        const cookies = getCookie('agree');
        return cookies ? true : false;
    });

    const [showPop, setShowpop] = useState<boolean>(false);

    useEffect(() => {
        if (!cookieResponse) {
            setTimeout(() => _showCookieModal(true), 5000)
        }
    }, [])

    const handleCookie = (trackingAllowed) => {
        setCookie('agree', 'true', 10000)
        setCookie('language', i18n.language, 7)
        _cookieResponse(true)
        _showCookieModal(false)
        _trackingAllowed(trackingAllowed)
    }

    /**
     * A function that conditionally renders the GoogleAnalytics component based on the trackingAllowed state.
     *
     * @remarks
     * This function is a callback function passed to the useCallback hook. It checks if the trackingAllowed state is null.
     * If it is, the function returns null. Otherwise, it renders the GoogleAnalytics component with the trackingAllowed state as a prop.
     *
     * @param trackingAllowed - A boolean state indicating whether tracking is allowed.
     * @returns A React element (null or GoogleAnalytics component) based on the trackingAllowed state.
     */
    const callGoogleAnalyticsComponent = useCallback(() => {
        if (trackingAllowed === null) return null;
        return <GoogleAnalytics isAgree={trackingAllowed} />
    }, [trackingAllowed])



    return (
        <>
            {callGoogleAnalyticsComponent()}
            {cookieResponse && <LaunchingHotdealPopup />}
            {showCookieModal ? <CookieStyled>
                <h5>{t(translations.webTexts.COOKIE_POLICY)}</h5>
                <Box className='cols'>
                    <Box className="left">
                        {t(translations.webTexts.COOKIES_DESCRIPTION)} <button onClick={() => setShowpop(true)} className="link">{t(translations.webTexts.OUR_POLICY_LINK)}</button> .
                    </Box>
                    <Box
                        className='right'
                        sx={{
                            textAlign: 'right'
                        }}
                    >
                        <ButtonCustom clickFunction={() => { handleCookie(true) }} textBtn={t(translations.webTexts.ALLOW_COOKIES)} btnColor={"primary"}
                            style={{
                                minWidth: 100
                            }}
                        />
                        <ButtonCustom clickFunction={() => { handleCookie(false) }} textBtn={t(translations.webTexts.USE_NECESSARY_COOKIES)} btnColor={"primary-out-line"}
                            style={{
                                mr: 1
                            }}
                        />
                    </Box>
                </Box>
            </CookieStyled> : null}
            <Modal
                open={showPop}
                onClose={() => setShowpop(false)}
                sx={{ zIndex: 99999 }}
            >
                <>
                    <CookiePolicy setShowpop={setShowpop} />
                </>
            </Modal>
        </>
    )
}

export default CookiesComponent;

const CookieStyled = styled(Box)`
    position: fixed;
    background: ${colorNew.colorWhite};
    width: calc(100% - 50px);
    max-width: 1332px;
    color:${colorNew.colorBlack};
    padding: 15px 10px;
    border-radius: 6px;
    border:1px solid ${colorNew.colorGrayDark};
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    font-family: 'Inter', sans-serif;
    .link{
        background: transparent;
        border: 0;
        font-size: inherit;
        padding: 0;
        color: ${colorNew.primaryColor};
        cursor:pointer;
    }
    h5{
        font-size: 20px;
        text-align: center;
        margin:0 0 15px 0;
    }
    .cols{
        display: flex;
        .left{
            width: calc(100% - 265px);            
            text-align:justify;
        }
        .right{
            padding:0 15px;
            button{
                font-weight: 600;
                font-family: 'Inter', sans-serif;
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }
    .close{
        position: absolute;
        right: 15px;
        top: 5px;
        svg{
            path{
                stroke:${colorNew.colorWhite};
            }
        }
    }
    @media (max-width:820px){
        .cols{
            display: block !important;
            .left{
                width: 100%;
            }
            .right{
                margin-top: 30px;
            }
        }
    }
`