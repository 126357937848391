import { Box, Button } from '@mui/material'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { translations } from 'locales/translations'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { routes } from 'routes/routes'

const FooterLinks = () => {

    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()
    const { t } = useTranslation();

    return (
        <Box sx={{ borderTop: '1px solid #95959563', padding: '25px 0px 0px 0px' }} className={((isLargeDevice || isExtraLargeDevice) ? 'justifyContentEvenly' : 'justifyContentCenter') + ' flexRow  alignItemsCenter flexWrap'}>
            <Box className={((isSmallDevice || isMediumDevice) ? 'flexRow flexWrap justifyContentCenter' : '') + ' footerLinks'}>
                <Button
                    component={Link}
                    to={routes.publicRoutes.terms}
                >
                    {t(translations.FOOTER.FOOTER_TERMS)}
                </Button>
                <span className='seperator'>|</span>
                <Button
                    component={Link}
                    to={routes.publicRoutes.privacy_policy}
                >
                    {t(translations.FOOTER.FOOTER_PRIVACYPOLICY)}
                </Button>
                <span className='seperator'>|</span>
                <Button
                    component={Link}
                    to={routes.publicRoutes.data_protection}
                >
                    {t(translations.FOOTER.FOOTER_DATAPROTECTION)}
                </Button>
                <span className='seperator'>|</span>
                <Button
                    component={Link}
                    to={routes.publicRoutes.legal_notice}
                >
                    {t(translations.FOOTER.FOOTER_LEGALNOTICE)}
                </Button>
                <span className='seperator'>|</span>
                <Button
                    component={Link}
                    to={routes.publicRoutes.legal_documents}
                >
                    {t(translations.FOOTER.FOOTER_LEGALDOCUMENTS)}
                </Button>
            </Box>
            <Box className='footerLinks' sx={{
                marginBottom: (isSmallDevice) ? '75px !important' : 0,
            }}>
                <span>{t(translations.FOOTER.FOOTER_COPYRIGHT) + " " + new Date().getFullYear()}</span>
            </Box>
        </Box>
    )
}

export default FooterLinks