import { hreflang, languagesObject } from 'config/constants'
import { setCookie } from 'config/variables'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { changeLanguageRequest } from 'store/actions/auth-actions'

const LanguageSetup = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const { loggedIn, languageData, loggedInRedirect } = useSelector((state: any) => state.auth)
    const { userData } = useSelector((state: any) => state.user)


    useEffect(() => {
        checkUrlValidity()
    }, [userData?.language_code, loggedIn])


    useEffect(() => {
        changeLanguage()
        updateSiteInUsersLanguage()
    }, [userData?.language_code, loggedIn, languageData, loggedInRedirect])


    // Function that validates language in URL 
    const checkUrlValidity = () => {
        const currentPath = window.location.pathname.split('/');
        // Checking if there is a valid language present in URL or not (only supported languages on this site)
        const isValidLanguagePresentInUrl = hreflang.some((lang) => lang === currentPath?.[1]);

        // If there is invalid language present or not present at current
        if (!isValidLanguagePresentInUrl) {
            let newUrlWithLanguage = ''

            // Checking if the user is logged in
            if (loggedIn) {
                // Returning as user data was not loaded yet
                if (!userData || (userData && Object.keys(userData).length === 0)) {
                    return
                }
                // Assigning user choosen language to url
                if (userData.language_code) {
                    newUrlWithLanguage = window.location.pathname.replace(`/`, `/${userData.language_code}/`);
                    setCookie('language', userData.language_code, 10000)
                }
            }

            // Runs for guest user
            if (!loggedIn) {
                // Extracting language from browser 
                const browserLanguage = navigator.language
                // Checking if browser language is valid or not (only supported languages on this site)
                const isBrowserLanguageValid = hreflang.some((lang) => lang === browserLanguage);
                if (!isBrowserLanguageValid) {
                    // If browser language is 'en-GB'/'en-US' etc then we will set the language to en
                    if (browserLanguage.includes('en')) {
                        newUrlWithLanguage = window.location.pathname.replace(`/`, `/${languagesObject.en}/`);
                        setCookie('language', languagesObject.en, 10000)

                    } else {
                        // Default language will be used as French
                        newUrlWithLanguage = window.location.pathname.replace(`/`, `/${languagesObject.fr}/`);
                        setCookie('language', languagesObject.fr, 10000)

                    }
                }
            }

            // If no valid language found then default language will be used as French
            if (newUrlWithLanguage === '') {
                newUrlWithLanguage = window.location.pathname.replace(`/`, `/${languagesObject.fr}/`);
                setCookie('language', languagesObject.fr, 10000)
            }

            window.location.replace(`${newUrlWithLanguage}${location.search}`);

        }
    }


    // Function that updates language if selected language and user's account mismatches (when someone manually changes language in URL)
    const changeLanguage = () => {
        // Returning as languages were not loaded yet
        if (!languageData || (languageData && Object.keys(languageData).length === 0)) {
            return
        }

        if (!loggedIn) {
            return
        }

        if (loggedIn) {
            // Returning as user data was not loaded yet
            if (!userData || (userData && Object.keys(userData).length === 0)) {
                return
            }
            // Extracting selected language
            const defaultLanguage = i18n.language.split('-')[0]
            // If user language is not equal to selected language we will update the language
            if (userData.language_code !== i18n.language && userData.language_code !== defaultLanguage) {

                // Finding language in languages list
                const getLanguage = languageData.languages.find((language) => {
                    return language.code === defaultLanguage
                })

                // Requesting language change API
                dispatch(changeLanguageRequest({ language_id: getLanguage.id }))
            }
        }



    }

    // Function that reloads site into user's language when someone logins and sets cookies for the same language
    const updateSiteInUsersLanguage = () => {
        // Returning as languages were not loaded yet
        if (!languageData || (languageData && Object.keys(languageData).length === 0)) {
            return
        }

        if (!loggedInRedirect) {
            return
        }

        if (loggedIn && userData && Object.keys(userData).length > 0) {
            // Finding language in languages list
            const languageDetails = languageData.languages.find((language) => {
                return language.code === userData.language_code
            })

            if (languageDetails && Object.keys(languageDetails).length) {
                setCookie('language', languageDetails.code, 10000)
                if (!location.pathname.includes('add-vehicle')) {
                    // reload on same page
                    if (i18n.language !== userData.language_code) {
                        window.location.replace(window.location.pathname.replace(`/${i18n.language}`, `/${userData.language_code}`));
                        return
                    }
                    window.location.reload()
                }
            }
        }

    }

    return (
        <>
        </>
    )
}

export default LanguageSetup