import { ReactComponent as FaCalender } from './NewIcons/faCalender.svg';

import { ReactComponent as FaPhone } from './NewIcons/faPhone.svg';
import { ReactComponent as FaPlus } from './NewIcons/faPlus.svg';
import { ReactComponent as FaAut } from './NewIcons/faAut.svg';
import { ReactComponent as FaPump } from './NewIcons/faPump.svg';
import { ReactComponent as FaCalenderColor } from './NewIcons/faCalenderColor.svg';
import { ReactComponent as FakmMap } from './NewIcons/fakmMap.svg';
import { ReactComponent as FaKw } from './NewIcons/faKw.svg';
import { ReactComponent as FaGear } from './NewIcons/faGear.svg';
import { ReactComponent as FaHammerShadow } from './NewIcons/hammerShadow.svg';
import { ReactComponent as FaPhoneCircle } from './NewIcons/faPhoneCircle.svg';
import { ReactComponent as FaDeliveryTime } from './NewIcons/faDeliveryTime.svg';
import { ReactComponent as FaShortDownChar } from './NewIcons/faShortDownChar.svg';
import { ReactComponent as FaShortDownNum } from './NewIcons/faShortDownNum.svg';
import { ReactComponent as FaShortUpChar } from './NewIcons/faShortUpChar.svg';
import { ReactComponent as FaShortUpNum } from './NewIcons/faShortUpNum.svg';
import { ReactComponent as meterIconNew } from './NewIcons/meterIconNew.svg';
import { ReactComponent as gearIconNew } from './NewIcons/gearIconNew.svg';
import { ReactComponent as calenderIconNew } from './NewIcons/calenderIconNew.svg';
import { ReactComponent as pumpIconNew } from './NewIcons/pumpIconNew.svg';
import { ReactComponent as kmIconNew } from './NewIcons/kmIconNew.svg';
import { ReactComponent as FaSiteLogo } from './NewIcons/siteLogo.svg';
import { ReactComponent as FaVector } from './NewIcons/Vector.svg';
import { ReactComponent as FaHome } from './NewIcons/home.svg';
import { ReactComponent as FaThreeDot } from './NewIcons/ThreeDot.svg';
import { ReactComponent as FaWatch } from './NewIcons/watch.svg';
import { ReactComponent as FaCalenderActive } from './NewIcons/Calender.svg';
import { ReactComponent as FaCameraPlus } from './NewDesign/cameraPlus.svg';
import { ReactComponent as FaBorderYellow } from './NewDesign/borderYellow.svg';
import { ReactComponent as FaSecure } from './NewDesign/secure.svg';
import { ReactComponent as FaCalenderRed } from './NewDesign/calenderRed.svg';
import { ReactComponent as FaWatchRed } from './NewDesign/watchRed.svg';
import { ReactComponent as FakeepOnPark } from './NewDesign/keepOnPark.svg';
import { ReactComponent as FaHammerBlck } from './NewDesign/hammerblack.svg';
import { ReactComponent as FaSpeaker } from './NewDesign/speaker.svg';
import { ReactComponent as FaWarranty } from './NewDesign/warranty.svg';
import { ReactComponent as FaHistory } from './NewIcons/History.svg';
import { ReactComponent as FaHistoryColor } from './NewIcons/HistoryColor.svg';
import { ReactComponent as FaRating } from './NewIcons/Rating.svg';
import { ReactComponent as FaRatingColor } from './NewIcons/RatingColor.svg';
import { ReactComponent as FaExternalLink } from './NewIcons/ExternalLink.svg';
import { ReactComponent as FaShareIconWhite } from './NewIcons/ShareIcon.svg';
import { ReactComponent as FaFlagged } from './NewIcons/flagged.svg';
import { ReactComponent as FaWorld } from './NewIcons/World.svg';
import { ReactComponent as FaSort } from './NewIcons/Sort.svg';


//Chat Icons
import { ReactComponent as FaDelete } from './NewIcons/Delete.svg';
import { ReactComponent as FaBlock } from './NewIcons/block.svg';
import { ReactComponent as FaImport } from './NewIcons/import.svg';
import { ReactComponent as FaMail } from './NewIcons/mail.svg';
import { ReactComponent as FaAdd } from './NewIcons/plus.svg';
import { ReactComponent as FaMute } from './NewIcons/mute.svg';
import { ReactComponent as FaUnmute } from './NewIcons/unmute.svg';
import { ReactComponent as FaMuted } from './NewIcons/muted.svg';
import { ReactComponent as FaSwitchCam } from './NewIcons/switchCam.svg';

//Icons for steps  
import { ReactComponent as fatopSpeedIcon } from './NewIcons/topSpeed.svg';
import { ReactComponent as faNewBattery } from './NewIcons/faNewBattery.svg';
import { ReactComponent as faNewKm } from './NewIcons/faNewKm.svg';
import { ReactComponent as faNewMilage } from './NewIcons/faNewMilage.svg';
import { ReactComponent as AccountDisplay } from './NewIcons/accountPage.svg';
import { ReactComponent as AccountDisplayStore } from './NewIcons/accountPageStore.svg';
import { ReactComponent as UserImg } from './NewIcons/user.svg';
import { ReactComponent as expandedView } from './NewIcons/expand.svg';
import { ReactComponent as FaSend } from './NewIcons/send.svg';


export const SvgExpandedViewIcon = expandedView;
export const SvgFakeepOnPark = FakeepOnPark;
export const SvgAccountDisplay = AccountDisplay;
export const SvgUserImg = UserImg;
export const SvgAccountDisplayStore = AccountDisplayStore;
export const SvgTopSpeedIcon = fatopSpeedIcon;
export const SvgCalender = FaCalender;
export const SvgPhone = FaPhone;
export const SvgPlus = FaPlus;
export const SvgAut = FaAut;
export const SvgPump = FaPump;
export const SvgCalenderColor = FaCalenderColor;
export const SvgkmMap = FakmMap;
export const SvgKw = FaKw;
export const SvgGear = FaGear;
export const SvgHammerShadow = FaHammerShadow;
export const SvgPhoneCircle = FaPhoneCircle
export const SvgDeliveryTime = FaDeliveryTime
export const SvgShortDownChar = FaShortDownChar
export const SvgShortDownNum = FaShortDownNum
export const SvgShortUpChar = FaShortUpChar
export const SvgShortUpNum = FaShortUpNum
export const SvgMeterIconNew = meterIconNew;
export const SvgGearIconNew = gearIconNew;
export const SvgCalenderIconNew = calenderIconNew;
export const SvgPumpIconNew = pumpIconNew;
export const SvgKmIconNew = kmIconNew;
export const SvgNewBattery = faNewBattery;
export const SvgNewKm = faNewKm;
export const SvgNewMilage = faNewMilage;
export const SvgSiteLogo = FaSiteLogo;
export const SvgVector = FaVector;
export const SvgHome = FaHome;
export const SvgThreeDot = FaThreeDot;
export const SvgWatch = FaWatch;
export const SvgCalenderActive = FaCalenderActive;
export const SvgCameraPlus = FaCameraPlus;
export const SvgBorderYellow = FaBorderYellow;
export const SvgSecure = FaSecure;
export const SvgCalenderRed = FaCalenderRed;
export const SvgWatchRed = FaWatchRed;
export const SvgSpeaker = FaSpeaker;
export const SvgHammerBlck = FaHammerBlck;
export const SvgWarranty = FaWarranty;
export const SvgDelete = FaDelete;
export const SvgBlock = FaBlock;
export const SvgImport = FaImport;
export const SvgMail = FaMail;
export const SvgAdd = FaAdd;
export const SvgMute = FaMute;
export const SvgUnmute = FaUnmute;
export const SvgSend = FaSend;
export const SvgMuted = FaMuted;
export const SvgSwitchCam = FaSwitchCam;
export const SvgHistory = FaHistory;
export const SvgHistoryColor = FaHistoryColor;
export const SvgRating = FaRating;
export const SvgRatingColor = FaRatingColor;
export const SvgExternalLink = FaExternalLink;
export const SvgShareIconWhite = FaShareIconWhite;
export const SvgFlagged = FaFlagged;
export const SvgWorld = FaWorld;
export const SvgSort = FaSort;


