
import { Box, Button, CircularProgress } from '@mui/material'
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom'
import { getUtcTime } from 'app/Hooks/useCoundown'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import InfoModal from 'app/components/InfoModal/info'
import ToastMessageWithButton from 'app/components/ToastMessageWithButton'
import AutoBidModal from 'app/components/stylesComponents/Ads/AutoBidModal'
import ConfirmationBidding from 'app/components/stylesComponents/Ads/ConfirmationBidding'
import { AntSwitch } from 'app/components/stylesComponents/AntSwitch'
import { SvgInfoIcon } from 'app/components/svgicons/svgNew'
import { validation } from 'app/pages/VehicleSummaryPage/VehicleValidations'
import { bidTYpes, showAuctionsTo } from 'config/constants'
import { PERMISSIONS, hasPermissions } from 'config/permission'
import { handleDisplayVat } from 'config/utils'
import { AlertMessages, Strings, getSubsidiaryStored } from 'config/variables'
import { translations } from 'locales/translations'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes/routes'
import { Toaster } from 'services/Toaster'
import { createBidRequest } from 'store/actions/vehicle-actions'

const Bid = ({ adDetail, isActive, isFocused, _isFocused, bid, _bid, autoBid, _autoBid }) => {

    const { t } = useTranslation()
    const history = useHistory()
    const isSub = getSubsidiaryStored()
    const dispatch = useDispatch()
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    const { userData } = useSelector((state: RootStateOrAny) => state.user);
    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth);
    const { bidLoading } = useSelector((state: RootStateOrAny) => state.vehicle);
    const permissionList = useSelector((state: RootStateOrAny) => state.user.permissionData)

    const [openModalAutoBid, setOpenModalAutoBid] = useState<boolean>(false);

    const [selectedElement, _selectedElement] = useState<string>('')


    // 3 suggested bids
    const [bidSuggestion, _bidSuggestion] = useState<Array<number>>([])
    // Bid that user places ( either using input field or suggestion list)
    const [finalBid, _finalBid] = useState<number>(0)

    const [showConfirmPopup, _showConfirmPopup] = useState<boolean>(false)
    const [bidConfirmationBox, _bidConfirmationBox] = useState<boolean>(false)

    useEffect(() => {
        if (adDetail && Object.keys(adDetail).length > 0) {

            if (isFocused) {
                return
            }

            // if the bidding has started we will take the best bid and show suggestions on based on that
            if (adDetail.best_manual_bid_relation != null && adDetail.best_manual_bid_relation.amount != null) {

                // If best bid is not multiple of 100, we will make it ( 2998 will be 3000 )
                if (adDetail.best_manual_bid_relation.amount % 100 != 0) {
                    const difference = 100 - (adDetail.best_manual_bid_relation.amount % 100)
                    _bid(adDetail.best_manual_bid_relation.amount + difference)
                    return
                }

                // If best bid is multiple of 100,we will add 100 into it for suggestion
                _bid(adDetail.best_manual_bid_relation.amount + 100)
                return
            }

            // if the bidding has not started we will take the starting price and show suggestions on based on that
            if (adDetail.start_price) {

                // If starting price is not multiple of 100, we will make it ( 2998 will be 3000 ) 
                if (adDetail.start_price % 100 != 0) {
                    const difference = 100 - (adDetail.start_price % 100)
                    _bid(adDetail.start_price + difference)
                    return
                }

                // If starting price is multiple of 100,we will add 100 into it for suggestion
                _bid(adDetail.start_price + 100)
                return
            }

            // Else intial suggestions will be 100 ,200 ,300
            _bid(100)

        }

    }, [adDetail?.best_manual_bid_relation?.amount])

    useEffect(() => {
        if (!bidLoading) {
            _selectedElement('')
        }
    }, [bidLoading])


    // getting if user has confirmed to not ask for bidding confirmation
    useEffect(() => {
        let value = localStorage.getItem('bidHidePop')
        _bidConfirmationBox(value ? JSON.parse(value) : false)
    }, [])


    // API call for bid 
    const createBidConfirm = (newBid) => {
        if (userData?.profile_is_complete === false) {
            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.CHAT.COMPLETE_PROFILE_BUTTON)} message={Strings.completeProfileString} click={() => history.push(routes.userRoutes.userProfileEdit)} />, false)
        } else if (userData?.profile_is_verified === false && isSub === null) {
            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.validationAndPopText.CERTIFY_MY_ACCOUNT)} message={t(translations.validationAndPopText.VERIFY_ACCOUNT)} click={() => history.push(routes.userRoutes.idVerfication)} />, false)
        } else {
            dispatch(createBidRequest({ bid_amount: !bidConfirmationBox ? finalBid : newBid, type: autoBid ? bidTYpes.auto : bidTYpes.mannual, id: adDetail.id }))
        }
        _showConfirmPopup(false)
    }


    // Updates data in localstorage to show/hide confirmation box before bidding
    const bidHidePopHandler = (data) => {
        _bidConfirmationBox(data)
        localStorage.setItem('bidHidePop', JSON.stringify(data));
    }



    // Handling submit function if user has not disabled confirmation before bidding then displays confirmation box else bids directly
    const handleBidSubmit = (newBid) => {

        _isFocused(false)


        if (bidLoading) {
            return
        }

        if (bid == 0 || !bid) {
            Toaster.error(t(translations.validationAndPopText.BID_VALUE_ERROR))
            return;
        }

        if (!isActive) { // active auction
            Toaster.error(t(translations.validationAndPopText.INACTIVE_ADD_BIDING_ERROR, { time: moment(adDetail.auction.start).format('DD/MM/YYYY HH:mm') }))
            return;
        }

        // returing to login page is user haven't logged in
        if (!loggedIn) {
            history.push(routes.authRoutes.login)
            return
        }

        // if user does not has permission to bid
        if (!hasPermissions(permissionList, PERMISSIONS.FEATURES.BUYING, PERMISSIONS.ACTIONS.ADD)) {
            Toaster.error(AlertMessages.bidPermissionError)
            return;
        }

        // if auction end is finished
        const isAfterTime = getUtcTime().isBefore(moment(adDetail.end_auction));
        if (!isAfterTime) {
            Toaster.error(t(translations.PUBLIC_AUCTION_ADS_SCREEN.PUBLIC_AUCTION_ADS_SCREEN_BID_TIME_ERROR))
            return;
        }
        // If user hasn't disabled confirmation popup, then opening confirmation box for user if he want to bid or not
        if (!bidConfirmationBox) {
            _finalBid(newBid)
            _showConfirmPopup(true)
            return
        }

        // Creating Bid request
        createBidConfirm(newBid)
    }

    const showInfo = (adDetail) => {
        if (adDetail.display_for == showAuctionsTo.DEALER && adDetail.owner_vehicle.vehicle_deductible_vat) {
            return <InfoModal header={t(translations.ads.VAT_REFUNDABLE_TEXT)} content={t(translations.ads.VAT_REFUNDABLE_TEXT_DESC)} />
        }
        if (adDetail.display_for == showAuctionsTo.DEALER && !adDetail.owner_vehicle.vehicle_deductible_vat) {
            return <InfoModal header={t(translations.ads.VAT_NON_REFUNDABLE_TEXT)} content={t(translations.ads.VAT_NON_REFUNDABLE_TEXT_DESC)} />
        }
        return <InfoModal header={t(translations.ads.VAT_TAX_INCLUD)} content={t(translations.ads.VAT_TAX_INCLUD_DESC)} />
    }


    return (
        <>
            <Box className='bidContainer'>
                {(isSmallDevice || isMediumDevice) &&
                    <>
                        <Box className='flexRow justifyContentBetween'>
                            <span className='bidHeader'>{t(translations.ads.BID_NOW)}</span>
                            <Box className='flexRow alignItemsCenter' sx={{ gap: '5px' }}>
                                <span className='bidSubHeader'>{t(translations.webTexts.AUTOBID)}</span>
                                <AntSwitch name={"autobid"} checked={autoBid} onChange={() => _autoBid(!autoBid)} inputProps={{ 'aria-label': 'ant design' }} className="toggle_icon" />
                                <SvgInfoIcon className='pointer' onClick={() => setOpenModalAutoBid(true)} style={{ marginLeft: '5px' }} />
                            </Box>
                        </Box>
                        <Box sx={{
                            my: 1, display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between',
                            columnGap: '20px',
                            alignItems: 'center',

                        }}>
                            <Box className='bidInputBox flexRow alignItemsCenter' sx={{ flex: 1 }}>
                                <span className='currency'>€</span>
                                <input type="tel" value={bid} onChange={(e) => _bid(Number(e.target.value))} disabled={!isActive || bidLoading} onFocus={() => _isFocused(true)} />
                                <span className='taxInclusive' style={{ width: '100%', fontWeight: 400, fontSize: '11px', display: 'flex', justifyContent: 'flex-end', padding: '3px 0' }}>
                                    {handleDisplayVat(adDetail)}
                                    {showInfo(adDetail)}
                                </span>

                            </Box>
                            <Box className={`btn-wrapper ${bidSuggestion.length === 0 && 'disabled'}`} sx={{
                                'button': {
                                    textTransform: 'capitalize'

                                }
                            }}>
                                <ButtonCustom clickFunction={() => {
                                    {
                                        handleBidSubmit(bid)
                                        _selectedElement(`${adDetail.id}btn`)
                                    }
                                }} className={''} disabled={bidLoading}
                                    textBtn={bidLoading && selectedElement == `${adDetail.id}btn` ?
                                        <CircularProgress className='btnloader' /> :
                                        t(translations.PUBLICPROFILE.SUBMIT)
                                    }
                                    btnColor={'primary'}
                                />
                            </Box>
                        </Box>
                    </>
                }
                {(isLargeDevice || isExtraLargeDevice) &&
                    <>
                        <Box className='flexColumn'>
                            <Box className=' flexRow bidNow alignItemsCenter justifyContentBetween'>
                                <span className='bidHeader'>{t(translations.ads.BID_NOW)}</span>
                                <Box className='flexRow autoBid'>
                                    <span className='bidSubHeader'>{t(translations.webTexts.AUTOBID)}</span>
                                    <Box sx={{ margin: '2px' }} className='flexRow alignItemsCenter'>
                                        <AntSwitch name={"autobid"} checked={autoBid} onChange={() => _autoBid(!autoBid)} inputProps={{ 'aria-label': 'ant design' }} className="toggle_icon" />
                                        <SvgInfoIcon className='pointer' onClick={() => setOpenModalAutoBid(true)} style={{ marginLeft: '5px' }} />
                                    </Box>
                                </Box>
                            </Box>

                            <Box className='bidInputBox flexRow'>
                                <Box className='inputwrapper flexRow'>
                                    <span className='currency'>€</span>
                                    <input type="number" value={bid} onChange={(e) => _bid(parseInt(e.target.value))} disabled={!isActive || bidLoading} onFocus={() => _isFocused(true)} />
                                </Box>
                                <span className='taxInclusive'>
                                    {handleDisplayVat(adDetail)}
                                    {showInfo(adDetail)}
                                </span>
                            </Box>
                            <Button onClick={() => {
                                {
                                    handleBidSubmit(bid)
                                    _selectedElement(`${adDetail.id}btn`)
                                }
                            }} className={'submitBtn'} disabled={bidLoading}>
                                {bidLoading && selectedElement == `${adDetail.id}btn` ?
                                    <CircularProgress className='btnloader' /> :
                                    t(translations.PUBLICPROFILE.SUBMIT)
                                }
                            </Button>
                        </Box>
                    </>
                }
                <AutoBidModal openModal={openModalAutoBid} handleClose={() => { setOpenModalAutoBid(false) }} />
            </Box>
            <ConfirmationBidding
                showConfirmPopupHandle={() => _showConfirmPopup(false)}
                createBidConfirm={createBidConfirm}
                showConfirmPopup={showConfirmPopup}
                selectedPrice={finalBid}
                bidPopHide={bidConfirmationBox}
                bidHidePopHandler={bidHidePopHandler}
            />
        </>
    )
}

export default Bid
