import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { Link, NavLink } from 'react-router-dom'
import { UserSidebarStyle } from '../UserStyle/UserStyle'
import { SvgHomeIcon, SvgEditProfile, SvgCompanyIcon, SvgCarSettings, SvgverifiedBrand, SvgPrivacy, SvgPrivacyIcon, SvgSubscription, SvgRole, SvgPaymentMethod, SvgBill, SvglicensePlate, SvgCartobikeCredit } from "app/components/svgicons/svgNew";
import { routes } from 'routes/routes';
import { useWindowSize } from 'app/Hooks/windowResize';
import { SvgChevronRight } from 'app/components/svgicons/svg';
import { RootStateOrAny, useSelector } from 'react-redux';
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom';
import { getSubsidiaryStored, InvoiceTabs } from 'config/variables';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import ProfileImage from 'app/Component2/GlobalComponent/ProfileImage';
import { ApiPaths } from 'services/ApiPaths';
import { localstorageKey } from 'config/constants';

const subsidiarySidebarMenuList = (t) => {
  return [
    { link: routes.companyRoutes.changePlan, label: t(translations.webTexts.MANAGE_SUBSCRIPTION), icon: <SvgSubscription /> },
    { link: routes.professionalSettingsRoutes.employeesList, label: t(translations.companyProfileScreen.MEMBERS), icon: <SvgEditProfile /> },
    { link: routes.companyRoutes.roleList, label: t(translations.companyProfileScreen.ROLES), icon: <SvgRole /> },
    { link: routes.companyRoutes.companySettings, label: t(translations.profileSideBar.SETTINGS), icon: <SvgCarSettings /> },
    { link: `${routes.companyRoutes.invoices}?invoiceType=${InvoiceTabs.subscriptionInvoice}`, label: t(translations.invoiceScreen.INVOICES), icon: <SvgBill /> },
    { link: routes.companyRoutes.paymentMethod, label: t(translations.changePlanScreen.PAYMENT_METHOD), icon: <SvgPaymentMethod /> },
    { link: routes.companyRoutes.companyPrivacy, label: t(translations.companyProfileScreen.PRIVACY), icon: <SvgPrivacyIcon /> },
    { link: routes.publicRoutes.license_plate, label: t(translations.ads.LPLATE), icon: <SvglicensePlate /> },
    { link: routes.userRoutes.myWallet, label: t(translations.HOT_DEALS.CARTOBIKE_CREDIT), icon: <SvgCartobikeCredit /> },
  ]
}
const userSidebarMenu = (t) => {
  return [
    { link: routes.companyRoutes.company, label: t(translations.companyScreen.COMPANY), icon: <SvgCompanyIcon /> },
    { link: routes.userRoutes.profileSetting, label: t(translations.mobileSideBar.SETTINGS), icon: <SvgCarSettings /> },
    { link: `${routes.companyRoutes.invoices}?invoiceType=${InvoiceTabs.creditInvoice}`, label: t(translations.invoiceScreen.INVOICES), icon: <SvgBill /> },
    { link: routes.userRoutes.idVerfication, label: t(translations.profilePrivateSideBar.VERIFIED), icon: <SvgverifiedBrand /> },
    { link: routes.userRoutes.userPrivacy, label: t(translations.profilePrivateSideBar.PRIVACY), icon: <SvgPrivacy /> },
    { link: routes.publicRoutes.license_plate, label: t(translations.ads.LPLATE), icon: <SvglicensePlate /> },
    { link: routes.userRoutes.myWallet, label: t(translations.HOT_DEALS.CARTOBIKE_CREDIT), icon: <SvgCartobikeCredit /> },
    { link: routes.companyRoutes.paymentMethod, label: t(translations.changePlanScreen.PAYMENT_METHOD), icon: <SvgPaymentMethod /> },

  ]
}

const UserSidebar = (props: any) => {

  const { component } = props;

  // localstate
  const [profileRoute, setProfileRoute] = useState<string>('')
  const [profileComplete, setProfileComplete] = useState<boolean>(false)

  // fetch data from localstorage
  const userStringData = localStorage.getItem('user') || '{}';
  const token = localStorage.getItem('token') || '';
  const userProfileData = JSON.parse(userStringData);
  const subsidiaryId = getSubsidiaryStored();

  // redux states
  const { profileUpdated, userData } = useSelector((state: any) => state.user);
  const { subsidiaryDetails } = useSelector((state: RootStateOrAny) => state.company);

  // hooks
  const { t } = useTranslation();
  const [mobile] = useWindowSize();

  //
  const usersidebarlist = useMemo(() => userSidebarMenu(t), [subsidiaryId, userData.id])
  const subsidiarySidebarMenu = useMemo(() => subsidiarySidebarMenuList(t), [subsidiaryId, userData.id])


  useEffect(() => {
    if (subsidiaryId && subsidiaryId !== null) {
      setProfileRoute(routes.companyRoutes.companyProfile)
      return
    }
    setProfileRoute(routes.userRoutes.myProfile)

  }, [subsidiaryId])

  useEffect(() => {
    if (!userData?.profile_is_complete) {
      setProfileComplete(profileUpdated)
      return
    }
    setProfileComplete(userData?.profile_is_complete)
  }, [profileUpdated.profile_is_complete, userData.profile_is_complete])

  useEffect(() => {
    setProfileComplete(userProfileData?.profile_is_complete)
  }, [userProfileData])

  const SideBar = () => {
    const userSidebarMenuList = [...usersidebarlist]
    if (!userData.can_create_company) {
      delete userSidebarMenuList[0]
    }
    return (
      profileComplete ?
        <>
          {
            userSidebarMenuList.map((menu, index) => (
              <li key={index + 'userSidebar'}><NavLink to={menu.link}> {menu.icon}<span className='res_menu'>{menu.label}</span><span className='mobile_arrow'>{mobile ? <SvgChevronRight /> : null}</span></NavLink></li>))
          }
        </>
        : <></>

    )
  }

  return (
    <UserSidebarStyle>
      <Box className='cartobike_siderbar scrollBar'
        sx={{
          width: mobile ? "100%" : "234px",
          borderRight: mobile ? "none" : "1px solid #E5E5E5",
          borderBottom: mobile ? "none" : "1px solid #E5E5E5",
          'span.res_menu ': {
            width: '100%',
            textAlign: 'left',
          }
        }}
      >
        {!mobile && <Box className='home_profile'>
          <ul>
            <li className='home_des'> <Link to=""><SvgHomeIcon />{`${t(translations.createCompanyScreen.HOME)}`}</Link></li>
            <li className='profile_des active'>
              <Link to={profileRoute}>
                <ProfileImage classes="mobile_user" />  {`${t(translations.profilePrivateSideBar.MYPROFILE)}`}</Link></li>
          </ul>
        </Box>}
        {!subsidiaryId ?
          <Box className='sidebar_wrapper'>
            <ul>
              <li>
                <NavLink to={routes.userRoutes.userProfileEdit}><SvgEditProfile /> <span className='res_menu'>{`${t(translations.profileScreen.PROFILEEDIT)}`}</span><span className='mobile_arrow'>{mobile ? <SvgChevronRight /> : null}</span></NavLink>
              </li>
              <SideBar />
            </ul>
          </Box>
          :
          <>
            <Box className='sidebar_wrapper'>
              <ul>
                {subsidiarySidebarMenu.map((option, index) => (
                  <li key={index + 'subsidiarySidebar'} ><NavLink to={option.link}>{option.icon} <span className='res_menu'>{option.label}</span><span className='mobile_arrow'>{mobile ? <SvgChevronRight /> : null}</span></NavLink></li>
                ))}
              </ul>
              <Box
                sx={{
                  paddingTop: '20px',
                  borderTop: !subsidiaryId ? "none" : '1px solid #E5E5E5',
                }}
              >
                {/* link your bank account button */}
                {subsidiaryId ?
                  <ButtonCustom
                    clickFunction={() => { window.location.href = (`${process.env.REACT_APP_STRIPE_CONNECT}?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CONNECT_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_BASE_URL}/${localStorage.getItem(localstorageKey.i18nextLng)}${ApiPaths.common.stripeConnect}&subsidiary_id=${subsidiaryId}&web=${true}&state=${token}`) }}
                    textBtn={subsidiaryDetails?.stripe_connected ? t(translations.mobileSideBar.BANK_ACC_LINKED) : t(translations.mobileSideBar.LINK_YOUR_BANK_ACCOUNT)}
                    btnColor={subsidiaryDetails?.stripe_connected ? "light-green-btn" : "yellow"}
                    disable={subsidiaryDetails?.stripe_connected ? true : false}
                    tooltiptitle={!subsidiaryDetails?.stripe_connected ? t(translations.mobileSideBar.BANK_ACC_LINKED_TITLE) : null}
                    style={{
                      width: '100%',
                      padding: '10px 15px 10px',
                      fontSize: '15px',
                      borderRadius: '6px',
                      lineHeight: '18px',
                    }}
                  />
                  : null}
              </Box>
            </Box>
          </>}
        {component ? component : null}
        <Box
          sx={{
            paddingTop: '20px',
            borderTop: !subsidiaryId ? "none" : '1px solid #E5E5E5',
          }}
        >
          {!mobile && subsidiaryId ? <Link to={routes.showCaseRoutes.showcaseConfigure}>
            <ButtonCustom textBtn={t(translations.showCaseForm.CUSTOMIZE_SHOWCASE)} btnColor="primary"
              style={{
                width: '100%',
                padding: '10px 15px 10px',
                fontSize: '15px',
                borderRadius: '6px',
                lineHeight: '18px',
              }}
            />
          </Link> : null}
        </Box>


      </Box>
    </UserSidebarStyle>
  )
}
export default UserSidebar
