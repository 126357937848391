import styled from 'styled-components';
import { color } from 'styles/StyleConstants';
import { Link } from 'react-router-dom'

export const MainBtn = styled.button`
  border:1px solid ${color.colorGrayDark};
  background:transparent; 
  padding: 10px 15px 10px;
  border-radius:6px;
  display:inline-block;
  min-width:90px;
  font-size: 18px;
  color:${color.colorGrayDark};
  margin-top: 20px;
  /* background: ; */
  cursor: pointer;
  &.rounded-0{
    border-radius: 0;
  }  
  &.disabled{
    border:1px solid ${color.colorGray} !important;
    color:${color.colorGray} !important;
  }
  &:hover{
    svg{
      fill: inherit !important;
      color: inherit !important;
      path{
        fill: inherit !important;
        color: inherit !important; 
      }
    } 
  }
`;

export const PrimaryBtn = styled.button`
  background:transparent; 
  padding: 5px 25px;
  min-width:125px;
  border-radius:5px;
  display:inline-block;
  font-family: 'Source Sans Pro', sans-serif;
  color:${color.colorWhite};
  font-weight: 700;
  line-height:normal;
  &.rounded-0{
    border-radius: 0;
  }  
  &.disabled{
    border:1px solid ${color.colorGray} !important;
    color:${color.colorGray} !important;
    pointer-events: none;
  }
  &:hover{
    svg{
      fill: inherit !important;
      color: inherit !important;
      path{
        fill: inherit !important;
        color: inherit !important; 
      }
    } 
  }
  &.orange-bg{
    border:1px solid ${color.colorOrange};
    background: ${color.colorOrange};
  }
  &.primary-outline-danger{
    font-size:18px;
    border:1px solid ${color.colorRed};
    color: ${color.colorRed};
    &:hover{
      background: ${color.colorRed} !important;
      color: ${color.colorWhite};
    }
  }
  &.primary-outline-green{
    font-size:18px;
    border:1px solid ${color.colorGreen};
    color: ${color.colorGreen};
    &:hover{
      background: ${color.colorGreen} !important;
      color: ${color.colorWhite};
    }
  }
  &.primary-bg{
    font-size:18px;
    border:1px solid ${color.primaryColor};
    background: ${color.primaryColor};
    &:hover{
      color: ${color.primaryColor};
      background: ${color.colorWhite};
    }
  }
  &.orange-bg{
    font-size:18px;
    border:1px solid ${color.colorOrange};
    background: ${color.colorOrange};
    &:hover{
      color: ${color.colorOrange};
      background: ${color.colorWhite};
    }
  }
  &.primary-outline{
    font-size:18px;
    border:1px solid ${color.primaryColor};
    color: ${color.primaryColor};
    &:hover{
      color: ${color.primaryColor};
    }
  }
  &.shadow-bg{
    transition:0.2s box-shadow ease-in-out;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover{
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    }
  }
  &.sm-primary{
    font-size: 14px;
    min-width: 64px;
    padding: 5px;
  }
`

export const MainLinkBtn = styled(Link)`
  border:1px solid ${color.primaryColor};
  background:transparent; 
  padding: 8px 10px;
  border-radius:18px;
  display:inline-block;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  letter-spacing: 0.205em;
  &.dark-btn{
    color:#FFF;
    border-color:#FFF;
  }
  &.rounded-0{
    border-radius: 0;
  }  
`;
