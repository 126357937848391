import { Box } from '@mui/material';
import { translations } from 'locales/translations';
import React from 'react'
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';
import styled from 'styled-components';
import adNotFound from "app/components/svgicons/NewIcons/adNotFound.webp"


const AdNotFound = () => {

  const { t } = useTranslation()

  return (
    <Wrapper>
      <Box className='mainContainer'>
        <Box className='contentBox'>
          <h1 className='title'>
            {t(translations.COMMON_TEXTS.AD_NOT_FOUND)}
          </h1>
          <p className='desc'>
            <Trans
              i18nKey={t(translations.COMMON_TEXTS['WE_COULDN\'T_FIND_AD_YOU_WERE_LOOKING_FOR'])}
              components={{
                bold: <span className='blueText' />
              }}
            />
          </p>
        </Box>
        <img src={adNotFound} className='icon' />
      </Box>
      <p className='backtoHome'>
        <Trans
          i18nKey={t(translations.COMMON_TEXTS.GET_BACK_TO_HOME)}
          components={{
            link1: <Link to={routes.vehicleRoutes.adsHome} />,
            link2: <Link to={routes.publicRoutes.contactUs} />
          }}
        />
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 91px);
  padding: 16px;
  gap:60px;
  .sitelogo{
    margin-top: -80px;
    width: max-content;
    height: 60px;
  }
  p,h1{
    margin: 0;
  }
  .mainContainer{
    display: flex;
    flex-direction: row;
    gap:50px;
    align-items: center;
    .contentBox{
      display: flex;
      flex-direction: column;
      gap:10px;
    }
  }
  .title{
    font-family: Inter;
    font-size: 60px;
    font-weight: 600;
    line-height: 75px;
    color: #FE4340;
  }
  .desc{
    font-size: 21px;
    font-weight: 400;
    line-height: 31.47px;
    max-width: 470px;
    color: #5B5B5B;
    .blueText{
      font-weight: 700;
      color : #FE4340;
    }
  }
  .icon{
    width: 330px;
    height: auto;
    object-fit: contain;
  } 
  .backtoHome{
    margin-top: 40px;
    font-family: Inter;
    font-size: 21px;
    font-weight: 400;
    line-height: 31.47px;
    color: #5B5B5B;
    text-align: center;
    a{
      font-weight: 700;
      font-size: 21px;
      line-height: 31.47px;
      color : #617AF7;
      text-decoration: none;
    }
  }
  @media (max-width :992px){
    .sitelogo{
      margin-top: 0px;
      height: unset;
      width: unset;
    }
    .mainContainer{
      flex-direction: column;
      align-items: center;
    }
    .title{
      font-size: 40px;
      line-height: 50px;
      text-align: center;
    }
    .desc , .navBack{
      font-size: 18px;
      text-align: center;
      a, span{
        font-size: 18px;
      }
    }
    .icon{
      width: 90%;
      max-width: 500px;
      height: auto;
    }
  }
`;

export default AdNotFound