import PublicLayout from 'app/Component2/Layouts/PublicLayout'
import { CreditPageWrapper } from './style/CreditPageWrapper'
import CreditFeatuers from './components/CreditFeatuers'
import CreditPricing from './components/CreditPricing'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { SvgArrowBack, SvgMobileLeftArrow } from 'app/components/svgicons/svgNew'
import { Box } from '@mui/material'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { localstorageKey } from 'config/constants'
import { useEffect } from 'react'

const CreditPage = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [isSmallDevice, isMediumDevice] = useDeviceType()
    const getPreviousUrl = localStorage.getItem(localstorageKey.buyCreditPage)


    const handleGoBack = () => {
        if (getPreviousUrl) {
            history.replace(getPreviousUrl)
            return;
        }
        history.goBack();
    }


    return (
        <PublicLayout
            showNavbar={false}
            hideHeader={isSmallDevice || isMediumDevice}
            backButton={<span className='back-button' onClick={handleGoBack}><SvgMobileLeftArrow /></span>}
        >
            <CreditPageWrapper>
                {(isSmallDevice || isMediumDevice) &&
                    <Box className='flexRow justifyContentBetween alignItemsCenter pageHeader'>
                        <SvgArrowBack onClick={handleGoBack} />
                        <h2>{t(translations.HOT_DEALS.CARTOBIKE_CREDIT)}</h2>
                        <SvgArrowBack style={{ visibility: 'hidden' }} />
                    </Box>}
                <CreditFeatuers />
                <CreditPricing />
            </CreditPageWrapper>
        </PublicLayout>
    )
}

export default CreditPage