import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FieldWrapper } from './style'

const TextInput = ({ title, preText, postText, value, handleChange }) => {

    const [fieldValue, _fieldValue] = useState<string>('')

    const updateFilterValue = () => {
        handleChange(fieldValue)
    }

    useEffect(() => {
        _fieldValue(value)
    }, [value])

    return (
        <FieldWrapper>
            <Box className='field'>
                <span className='fieldHeader'>{title}</span>
                <Box className='flexRow textMultipleFieldContainer'>
                    {preText && <span>{preText}</span>}
                    <input type='number' style={{ width: '60%' }} value={fieldValue ? fieldValue : ''} onChange={(e) => _fieldValue(e.target.value)} onBlur={updateFilterValue} />
                    {postText && <span>{postText}</span>}
                </Box>
            </Box>
        </FieldWrapper>
    )
}

export default TextInput