import { getSubsidiaryStored } from 'config/variables';
import { useSelector } from 'react-redux';
import companyPlaceHolder from 'app/assets/Images/companyLogo.png';
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';
const ProfileImage = (props: any) => {
  const { classes, imageType } = props
  const { subsidiaryDetails, logoUrl } = useSelector((state: any) => state.company);
  const { updatedImage, userData, profileUpdatedData } = useSelector((state: any) => (state.user));
  const subSidary = getSubsidiaryStored();
  let images = "";
  if (subSidary && subSidary !== null) {
    images = companyPlaceHolder;
    if (Object.keys(subsidiaryDetails).length > 0) {
      if (logoUrl?.data?.avatar) {
        images = logoUrl?.data?.avatar;
      } else if (subsidiaryDetails?.media.length) {
        images = imageType === 'medium' ? subsidiaryDetails?.media[0].medium_url : subsidiaryDetails.logo_url;
      }
    }
  } else if (updatedImage?.data?.avatar) {
    images = updatedImage?.data?.avatar;
  } else if (userData?.profile_picture_url) {
    images = userData?.profile_picture_url;
  } else {
    images = userPlaceHolder;
  }
  return (
    <img src={images ? images : userPlaceHolder} alt="images-profile" className={classes} />
  )
}

export default ProfileImage;