import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const HotDealsDetailStyle = styled.div`
display: flex;
flex-direction: column;
min-height: 100vh;
h1, h2, h3 {
  margin: 0;
}
.mainContainer{
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 20px;
}
.vehicle-display{
  border: 1px solid #F5F5F5;
  padding :20px;
}
.alignCenter{
  display: flex;
  justify-content: center;
}
.popularCars{
  border: none;
}
.mainDetailContainer{  
  margin: auto 20px ;
}
/* Ad CSS starts here */
.dealDetails{
  overflow: auto;
  border: 1px solid rgba(242, 242, 242, 1);
  max-width: 1440px;
  width: 100%;
  margin: 0 20px 20px 20px ;
}
.adContainer{
  padding: 8px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.imageContainer{
  position : relative;
  max-width : 35%;
  width : 35%;
  min-width : 490px;
}
.adDetailsContainer{
 padding: 0 16px;
 display: flex;
 flex-direction: column;
 gap: 5px;
 width: 50%;
}
.ad-name{ 
  margin : 0px;
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 444;
  line-height: 26px;
  letter-spacing: 0.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.priceContainer{
  display: flex;
  flex-direction: row;
  gap:5px;
  align-items: center;
}
.priceContainer .price{
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  line-height: 25.41px;
  letter-spacing: 0.02em;
  color: #EF4444;
}
.priceContainer .vatText{
  font-size: 13px;
  font-weight: 400;
  line-height: 15.41px;
  letter-spacing: 0.02em;
  color: rgba(102, 102, 102, 1);
  .benefitInformationContainer{
    margin-left: 5px;
    display: inline;
  }
}
.actionContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .leftSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 20px;
    width: 48%;
  }
  .rightSection{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 48%;

  }
}
.rightSection .rightSubSection{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  p{
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    letter-spacing: 0.02em;
    color: rgba(153, 153, 153, 1);
    span{
      font-size: 15px;
      font-weight: 500;
      line-height: 18.15px;
      letter-spacing: 0.02em;
      color: #000;
    }
  }
}
.timer{
  width: 50%;
  min-width: 285px;
  div{
    padding: 0;
    margin-top: 0;
  }
}
.count {
  color: var(--text-deep-gray, #666);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
  position: absolute;
  top: 18px;
  line-height: normal;
}
.countText {
  color: var(--text-deep-gray, #666);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
}
.similarAdsLink{
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.16px;
  letter-spacing: 0.015em;
  color: #3A71FF;
  text-decoration: underline;
  display: flex;
  align-items: center;
  height: 45px;
}
.tabber{
  display: flex;
  margin: 0 auto;
}


.tabs-wrapper{
    margin-left: 16px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.36px;
    .MuiButtonBase-root {
      height: 40px;
      min-Height: 40px;
    }
    .Mui-selected{
      color: #fff ;
      background-color: ${colorNew.primaryColor};
      border-radius: 6px;
      height: 40px;
      border: none;
    }
    .MuiTabs-indicator{
      display: none;
    }
  }
 
.strikedprice{
  text-decoration: line-through;
  color:rgba(102,102,102,1);
  font-family: Rubik;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.41px;
  letter-spacing: 0.02em;
}
.tabsDetailsSection{
  margin: 0 15px;
}
.categoryWrapper {
  padding: 0 !important;
  overflow-x: auto !important;
  .cat-name-text{
    padding: 12px 16px;
  }
}
.vehcileDetailsTabs{
  margin: 0;
}
.MuiTab-root{
  padding: 0px 16px;
}
.breadCrumb{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px 20px;
  span{
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    letter-spacing: 0.02em;
    color: #999999;
  }
  .link{
    color: #384386;
    text-decoration: none;
  }
}
@media (max-width :1440px){
  padding: 16px;
  max-width: 1170px;
  .adDetailsContainer{
    width: 100%;
    padding-right: 0;
  }
}
 

@media (min-width :993px) and (max-width :1080px){
  .adContainer{
    min-width: 1050px;
  }
}

@media (max-width :992px){
  margin-top: unset;
  padding: 10px;
  .header{
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    padding:10px 0;
  }
  .header h2{
    font-family: Inter;
    font-size: 21px;
    font-weight: 500;
    line-height: 25.41px;
    letter-spacing: 0.02em;
  }
  .adContainer{
    flex-direction: column;
    gap: 10px;
  }
  .adDetailsContainer{
    padding: 0;
  }
  .imageContainer{
    margin: 0 auto;
  }
  .dealDetails{
    margin: 0 0 10px 0;
    width: auto;
  }
  .fixedActionContainer{
    width: calc(100vw - 20px);
    margin: 0 auto;
    background-color: white;
    border: 1px solid #F2F2F2;
    border-top: 3px solid #6579F4;
    padding: 10px;
    border-radius: 0 0 4px 4px;
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 2%);
    z-index: 2;
  }
  .tabs-wrapper{
    margin: 0;
  }
  .tabsDetailsSection{
    margin: 0;
  }
  .vehcileDetailsTabs{
    margin-left: 0;
  }
}

@media (max-width :768px){
  gap: 20px;
  .dealDetails{
    margin: -20px 0px 0;
  }
  .imageContainer{
    width:100%;
    max-width:100%;
    min-width:unset;
  }
  .ad-name{
    width :100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
  .leftSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
  }
  .rightSection .rightSubSection{
    flex-direction: row;
    justify-content: space-between;
    p{
      span{
        display: block;
      }
    }
  }
  .breadCrumb{
    margin-bottom: 60px;
  }
}

`