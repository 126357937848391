import { Box, Button} from '@mui/material'
import { SvgAdd} from 'app/components/svgicons/svg2'
import React, { useRef, useState } from 'react'
import { Toaster } from 'services/Toaster'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { SvgAddCompany } from 'app/components/svgicons/svgNew'

const ChooseAttachment = ({setAttachment,attachment,multipleFile}) => {
  const {t} = useTranslation()  
  const MAX_COUNT = 5;
 
  const handleFileChange = (event) => {
    let files = Array.prototype.slice.call(event.target.files);
    // Returning if files selected are more than 5  
    if(files.length > MAX_COUNT || attachment.length > MAX_COUNT || (files.length + attachment.length) > MAX_COUNT){
      //display error if more then 5 files are selected
      Toaster.error(t(translations.CHAT.MEDIA_SHARE_ERROR_MESSAGE))
      return false;
    }
    let flag = false
    // Creating array of files shared to check size of them
    let filesArr = []
    Object.values(event.target.files).map((item)=>{
      filesArr.push(item)
    })
    // Checking files for size
    for(let i=0 ; i < filesArr.length ; i++ ){
      var sizeInMB1 = (filesArr[i].size / (1024*1024)).toFixed(2)
      if(parseInt(sizeInMB1) >= MAX_COUNT){
        // Setting flag for Returning if size exceeds 5MB
        Toaster.error(t(translations.CHAT.FILE_SIZE_VALIDATION))
        flag = true
        break
      }
    }
    // Returning if flag gets true
    if(flag){
      return false
    }
    //set attachment array of all selected files
    let arr = [...attachment]       
    for (let i = 0; i < files.length; i++) {       
        arr.push(files[i])      
    }
    setAttachment(arr)
    setTimeout(()=>{
      let elem = document.getElementById('fileList')
      if(elem){
        elem.scrollLeft = elem.scrollWidth;
      }
    },200)
  }

  let addfilesButton = multipleFile ?  <SvgAddCompany className='pointer'/> : <SvgAdd className='pointer'/>
  return (<Box sx={{ 
        display:'flex'
      }}>
        <Button  sx={{ minWidth: '0px',padding:0, display:'flex' }}>
           <Box className='pointer attachmentOptions'>                      
           <label htmlFor="upload-files" className='pointer btn-position' >{addfilesButton}</label>
           <input hidden type="file" multiple id="upload-files"  onChange={(e) => { handleFileChange(e) }} className='upload-files' />
          </Box>     
        </Button>       
      </Box>
  )
}

export default ChooseAttachment