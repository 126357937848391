import { Box, Select } from "@mui/material";
import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const HeaderWrapper = styled(Box)`
.pagePath{
  color: black;
  display: inline;
  padding: 0;
  text-transform: none;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.02em;
}
.pagePath:hover{
  background-color: inherit;
}
.top-bar {
  padding: 0 15px;
  border-bottom: 1px solid #eaeaea;
  height: 75px;
}
.navbarContainer .site .back-button svg {
  height:20px;
  width: 20px; 
}
 .back-button {
  height:32px;
  width: 32px;
  margin-right: 10px;
  background-color: #eaeaea;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.site .back-button span {
  display: flex;
}
.ads-detail-page-header{
    position: fixed;
    width: -webkit-fill-available;
    z-index: 11;
    top: 0;
    background: white;
}

.addProduct{
  display : flex;
  width:100%;
  height:48px;
  align-items : center;
  justify-content : center;
  margin-right : 10px;
  cursor : pointer;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 12px 50px;
  border:none;
  text-transform:none;
  width:max-content;
  background: #000 !important;
}
.addProduct span{
    font-weight: 500;
    width:max-content;
    /* color : ${colorNew.primaryColor}; */
    color: #FFF;
    font-size: 18px;
}

.imageWrapper{
  position: relative;
  display: flex;
  align-items:center;
}
.imageWrapper .image-1{
  width :48px;
  height :48px;
  object-fit: cover;
  border-radius: 50%;
}
.imageWrapper .image-2{
  width: 18px;
  height: 18px;
  object-fit: cover;
  border-radius: 16px;
  position: absolute;
  right: 0px;
  bottom: 5px;
  background: #EEE;
}
.menuIcon{
  width:45px;
  height:35px;
  min-width: auto;
  /* border: 1px solid rgb(222, 222, 222); */
  background-color: transparent;
  margin-left: 25px;
}
.icon-dot {
    width: 8px !important;
    height: 8px !important;
    min-width: 8px !important;
    position: absolute;
    background: ${colorNew.colorRed};
    right: 6;
    top: 4;
    border-radius: 6px
}

.backButton-style-container span {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 0px 0px 10px;
    font-size: 20px;
  }
  .backButton-style-container svg {
    width: 18px;
 }
 .backButton-style {
    background: transparent;
    height: 40px;
    width: 40px;
    border: solid 1px ${colorNew.colorGrayOnButton};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
 }
 .invoiceContainer{
    max-width: 550px;
    margin: 0 auto 0;
    width: 100%;
 }
 .languageSwitch .flagIcon{
  width: 42px;
  height: 42px;
  border-radius: 100%;
  object-fit: cover;
} 
`


export const HeaderRightStyle = styled(Box)`

.popup-pointer{
  position: absolute;
  width: 0px;
  height: 0px;
  border-bottom: 15px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -10px;
  right: 15px;
}
.container {
  gap: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.MuiButton-root {
  min-width: auto;
  /* background-color: transparent; */
}

.messages {
  width: 10px;
  height: 10px;
  background: rgb(236, 28, 36);
  position: absolute;
  border-radius: 6px;
  right: 18px;
  top: 18px;
}

@media (max-width: 960px) {
  .MuiButton-root {
    width: 35px;
    height: 35px;
  }
}

.icon-dot {
  width: 10px;
  height: 10px;
  position: absolute;
  background: red; 
  right: 8px;
  top: 6px;
  border-radius: 6px;
}

.addProduct {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  background: #000;
  padding: 12px 50px;
  border-radius: 4px;
  width: max-content;
}

.addProduct span {
  font-weight: 500;
  color: #fff; 
  font-size: 18px;
}



/* Container styles */
.button-wrapper {
  display: flex;
  align-items: center;
  margin-left: 3px; /* For medium screens (md) */ 
}

/* Login button styles */
.loginBtn {
  padding: 6px 24px;
  white-space: nowrap;
  background-color: ${colorNew.primaryColor}; 
  border-radius: 4px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  color: #fff;
  height: 48px;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Image wrapper styles */
.imageWrapper {
  position: relative;
  display: inline-block;
}

.imageWrapper .image-1 {
  width: 48px; /* For medium screens (md) */  
  height: 48px; /* For medium screens (md) */  
  object-fit: cover;
  border-radius: 50%;
}
.user-info .text-link {
  font-size: 13px;
  font-weight: 400;
}

@media (max-width: 960px) {
  .imageWrapper .image-1  {    
  width: 35px; /* For extra small screens (xs) */  
  height: 35px; /* For extra small screens (xs) */
}
.button-wrapper  {
    margin-left: 1px; 
  }
}

.imageWrapper .image-2 {
  width: 17px;
  height: 17px;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #F3F3F3 ;
}

/* User info styles */
.user-info {
  margin-left: 8px;
}


.user-info .text-name {
  color: ${colorNew.colorBlack}; 
  display: block;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.user-info .text-link {
  color: ${colorNew.primaryColor}; 
  display: block;
  white-space: nowrap;
  cursor: pointer;
}
`