import { Box } from "@mui/material"
import { UserHeaderStyle } from "../UserStyle/UserStyle";
import { Link } from "react-router-dom";
import { SvgSiteLogo} from "app/components/svgicons/svgNew";
import { theme } from "config";
import { notificationRequest, sidebarOpenRequest } from "store/actions/component-actions";
import { useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import HeaderRight from "app/Component2/GlobalComponent/HeaderRight";
import { routes } from "routes/routes";
import { SvgVector } from "app/components/svgicons/svg2";
import { useWindowSize } from "app/Hooks/windowResize";
const UserHeader = (props: any) => {
    const { auctionPlace} = props;
    const [mobile] = useWindowSize()   
    const dispatch = useDispatch();
    const handleSwitchProfile = () => {
        dispatch(sidebarOpenRequest())
    }

    return (
        <UserHeaderStyle
            sx={{
                '.right_icon': {
                    [theme.breakpoints.down('sm')]: {
                        display: 'none',
                    }
                }
            }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '85px',
                px: 3,
                background: '#fff',
                ...props.style,
                '.tower': {
                    maxHeight: { xs: '30px', md: '30px' },
                    maxWidth: { xs: '15px', md: '25px' },
                    mx: '15px',
                    marginBottom: '3px',
                    cursor:"pointer",
                    'svg': {
                      width: { xs: 20, md: 25, lg: 40 }
                    }
                  },
            }}>
                <Box className="logo">
                    {props.auctionPlace ? null : <Link to={routes.vehicleRoutes.adsHome}>
                        <SvgSiteLogo />
                    </Link>}
                    <span className='tower'>
                       {!auctionPlace ? <SvgVector onClick={handleSwitchProfile}/> : null}
                    </span>                 
                </Box>
                {mobile ? null :<HeaderRight/> }
            </Box>
        </UserHeaderStyle>
    )
}
UserHeader.propTypes = {
    countryFlag: PropTypes.string,
    auctionPlace:PropTypes.bool,
}
export default UserHeader;
