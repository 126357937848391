import { getSubsidiaryStored } from 'config/variables';
import React, { memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { getSubsidiaryDetailsRequest } from 'store/actions/company-actions';

const SubsidiaryDetails = () => {

    const subsidiary = getSubsidiaryStored();
    const dispatch = useDispatch()

    const { subsidiaryDetails } = useSelector((state: RootStateOrAny) => state.company);

    useEffect(() => {
        if (subsidiary && (!subsidiaryDetails || Object.keys(subsidiaryDetails).length === 0)) {
            dispatch(getSubsidiaryDetailsRequest({ subsidiaryId: subsidiary }))
        }
    }, [])

    return (
        <></>
    )
}

export default memo(SubsidiaryDetails)