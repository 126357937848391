import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import CircularProgressBar from 'app/Component2/GlobalComponent/CircularProgressBar';
import { translations } from 'locales/translations';

const Timer = ({ days, hours, minutes, seconds }) => {
    const { t } = useTranslation();

    const isCritical = parseInt(days) === 0 && parseInt(hours) === 0 && parseInt(minutes) === 0 && parseInt(seconds) < 60;
    const color = isCritical ? '#EC1C24' : '#28A745';
    const blinkClass = isCritical && parseInt(seconds) > 0 ? 'blink' : '';

    const getValue = (max, current) => {
        const value = parseInt(current);
        return value === 0 ? 0 : (value / max) * 100;
    };

    const translation = useMemo(() => ({
        days: t(translations.HOME_SCREEN.DAYS),
        hours: t(translations.HOME_SCREEN.HOURS),
        minutes: t(translations.HOME_SCREEN.MIN),
        seconds: t(translations.HOME_SCREEN.SEC)
    }), [t]);

    return (
        <Box className={`${blinkClass} flexRow justifyContentBetween`} sx={{ py: '5px', mt: '5px' }}>
            {[{ label: translation.days, value: days, max: 15 },
            { label: translation.hours, value: hours, max: 60 },
            { label: translation.minutes, value: minutes, max: 60 },
            { label: translation.seconds, value: seconds, max: 60, color }]
                .map(({ label, value, max, color = '#28A745' }, index) => (
                    <Box key={index} className={`blinkingTimer flexColumn alignItemsCenter relative`}>
                        <CircularProgressBar color={color} size='56px' value={getValue(max, value)} />
                        <span className='count'>{value}</span>
                        <span className='countText'>{label}</span>
                    </Box>
                ))}
        </Box>
    );
};

export default Timer;
