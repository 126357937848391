import React, { useEffect, useState } from 'react'
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import { translations } from 'locales/translations'
import { Trans, useTranslation } from 'react-i18next'
import { SvgVerified } from '../svgicons/svgNew'
import { Link, useHistory } from 'react-router-dom'
import { useQueryStringParams } from 'app/Hooks/useParams'
import Api from 'services/axios'
import { ApiPaths } from 'services/ApiPaths'
import { localstorageKey } from 'config/constants'
import { Toaster } from 'services/Toaster'
import { routes } from 'routes/routes'
import { vehicles } from 'config/variables'
import { vehicleTypeContant } from 'config/appConfig'
import { colorNew } from 'styles/StyleConstants'
import { creditsBalanceRequest } from 'store/actions/user-actions'
import { useDispatch } from 'react-redux'
import { safeRedirect } from 'config/utils'

/**
 * PaymentSuccess component handles the display and logic after a successful payment.
 * It retrieves the payment ID from the URL, updates the session, and redirects the user
 * to the appropriate page based on the context of the purchase.
 */
const PaymentSuccess = () => {

    // getting payment Id from url
    const { payment_id: paymentId, is_mobile: isMobile } = useQueryStringParams()

    // hooks
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch()
    const getUrlFromStorage = localStorage.getItem(localstorageKey.buyCreditPage)
    const getPurchaseCreditPage = localStorage.getItem(localstorageKey.purchaseCredit)
    const getPurchaseCreditByHotdeal = localStorage.getItem(localstorageKey.purchaseCreditByHotdeal)

    const getProductType = localStorage.getItem(localstorageKey.productType) || ''


    /**
     * Determines the product type name based on the stored product type.
     * @returns {string} The name of the product type.
     */
    const getProductTypeName = () => {
        if (parseInt(getProductType) == vehicleTypeContant.MOTORBIKE_TYPE) {
            return vehicles.motorcycles
        }
        if (parseInt(getProductType) == vehicleTypeContant.BIKE_TYPE) {
            return vehicles.bike
        }
        if (parseInt(getProductType) == vehicleTypeContant.STEP_TYPE) {
            return vehicles.step
        }
        return vehicles.car
    }

    /**
     * Determines the URL to redirect to after a successful payment.
     * @param {string} redirectTo - The URL to redirect to.
     * @returns {string} The final URL to redirect to.
     */
    const redirectUrl = (redirectTo) => {

        if (getPurchaseCreditByHotdeal) {
            // while purchasing credits from hotdeal list / detail page 
            // redirect to corresponding page after purchase success
            localStorage.removeItem(localstorageKey.purchaseCreditByHotdeal)
            return getPurchaseCreditByHotdeal
        }

        if (getPurchaseCreditPage && getProductType && (getPurchaseCreditPage?.includes(routes.publicRoutes.hotDealEvent) || getPurchaseCreditPage?.includes(routes.publicRoutes.hotDeal))) {
            // while Booking ad from hotdeal list / detail page 
            // redirect to corresponding page after payment has been received
            localStorage.removeItem(localstorageKey.purchaseCredit)
            return getPurchaseCreditPage
        }
        if (redirectTo) {
            if (redirectTo.includes(routes.publicRoutes.hotDealEvent) || redirectTo.includes(routes.publicRoutes.hotDeal)) {
                // in case of buying vehicle
                return `${routes.vehicleRoutes.myAds}/${getProductTypeName()}${routes.vehicleRoutes.park}`
            }

            return redirectTo
        }

        return routes.vehicleRoutes.adsHome
    }

    // Use the function instead of directly setting window.location
    useEffect(() => {
        if (isMobile != 'false' && isMobile != null && isMobile != undefined) {
            //redirect to mobile app
            safeRedirect(process.env.REACT_APP_REDIRECT_ON_APP_LINK)
            return
        }
        if (paymentId) {
            // Api call for update checkout session after payment
            Api.post(ApiPaths.user.update_session, {
                payment_id: paymentId,
            }).then(response => {
                localStorage.removeItem(localstorageKey.buyCreditPage)
                localStorage.removeItem(localstorageKey.paymentFailure)
                localStorage.removeItem(localstorageKey.productType)
                localStorage.removeItem(localstorageKey.purchaseCreditByHotdeal)
                if (history.location.pathname.includes(routes.userRoutes.creditBuySuccess)) {
                    localStorage.removeItem(localstorageKey.makeCreditPaymentIntentId)
                }
                if (history.location.pathname.includes(routes.userRoutes.vehiclePaymentSuccess)) {
                    localStorage.removeItem(localstorageKey.hotdealReservationIntentId)
                }
            }).catch(error => {
                Toaster.error(error?.response?.data?.message)
            }).finally(() => {
                // redirect on last screen from where we start make payment
                // redirect on credit payment/ reserve vehicle page
                dispatch(creditsBalanceRequest())
                history.push(redirectUrl(getUrlFromStorage))
            })
            return
        }
        history.push(redirectUrl(getUrlFromStorage))
    }, [paymentId])

    return (
        <>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', my: 5 }}>
                    <Card sx={{ maxWidth: 345, minWidth: 300 }}>
                        <CardActionArea>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 5 }}>
                                <SvgVerified />
                            </Box>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography gutterBottom variant="h6" component="div">
                                    {t(translations.webTexts.YOUR_PAYMENT_IS_SUCCESSFUL)}
                                </Typography>

                                <Typography variant="body2" color="text.secondary">
                                    {t(translations.webTexts.THANK_YOU_FOR_YOUR_PAYMENT)}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>
                <Box sx={{
                    textAlign: 'center',
                    'a': {
                        color: colorNew.primaryColor
                    }
                }
                }>
                    <Trans
                        i18nKey={t(translations.CTB_CREDIT.GO_BACK_TO_WALLET)}
                        components={{
                            linkWallet: <Link to={redirectUrl(getUrlFromStorage)} />,
                        }}
                    />
                </Box>
            </Box >
        </>
    )
}

export default PaymentSuccess