import { createAction } from 'redux-actions';

export const getMyClassicAdsRequest = createAction('GET_MY_CLASSIC_ADS_REQUEST');
export const getMyClassicAdsSuccess = createAction('GET_MY_CLASSIC_ADS_SUCCESS');
export const getMyClassicAdsFailure = createAction('GET_MY_CLASSIC_ADS_FAILURE');

export const getMyParkedAdsRequest = createAction('GET_MY_PARKED_ADS_REQUEST');
export const getMyParkedAdsSuccess = createAction('GET_MY_PARKED_ADS_SUCCESS');
export const getMyParkedAdsFailure = createAction('GET_MY_PARKED_ADS_FAILURE');

export const getMyLiveAuctionAdsRequest = createAction('GET_MY_LIVE_AUCTION_ADS_REQUEST');
export const getMyLiveAuctionAdsSuccess = createAction('GET_MY_LIVE_AUCTION_ADS_SUCCESS');
export const getMyLiveAuctionAdsFailure = createAction('GET_MY_LIVE_AUCTION_ADS_FAILURE');

export const getMyActionRequiredAdsRequest = createAction('GET_MY_ACTION_REQUIRED_ADS_REQUEST');
export const getMyActionRequiredAdsSuccess = createAction('GET_MY_ACTION_REQUIRED_ADS_SUCCESS');
export const getMyActionRequiredAdsFailure = createAction('GET_MY_ACTION_REQUIRED_ADS_FAILURE');

export const getAuctionAdsHistoryRequest = createAction('GET_AUCTION_ADS_HISTORY_REQUEST');
export const getAuctionAdsHistorySuccess = createAction('GET_AUCTION_ADS_HISTORY_SUCCESS');
export const getAuctionAdsHistoryFailure = createAction('GET_AUCTION_ADS_HISTORY_FAILURE');

export const sellReserveVehicleRequest = createAction('SELL_RESERVE_VEHICLE_REQUEST');
export const sellReserveVehicleSuccess = createAction('SELL_RESERVE_VEHICLE_SUCCESS');
export const sellReserveVehicleFailure = createAction('SELL_RESERVE_VEHICLE_FAILURE');

export const getMyHotdealAdsRequest = createAction('GET_MY_HOT_DEAL_ADS_REQUEST');
export const getMyHotdealAdsSuccess = createAction('GET_MY_HOT_DEAL_ADS_SUCCESS');
export const getMyHotdealAdsFailure = createAction('GET_MY_HOT_DEAL_ADS_FAILURE');

export const selectCustomer = createAction('GET_AUCTION_ADS_HISTORY_FAILURE');
export const updateAdsStatus = createAction('UPDATE_ADS_ONLINE_OFFLINE_STATUS');

export const resetMyAds = createAction('RESET_MY_ADS_HISTORY');
