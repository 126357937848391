import { Box, Skeleton } from '@mui/material'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import React from 'react'
import styled from 'styled-components'

const AdSkeleton = () => {

    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    const Timer = () => {
        return <Box className={'priceContainerSkeleton counter' + (isLargeDevice || isExtraLargeDevice ? ' large ' : ' small ')}>
            {[1, 2, 3, 4].map((time) => {
                return <Box key={time}>
                    <Skeleton variant="circular" width={50} height={50} />
                    <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ mt: '5px' }} />
                </Box>
            })}
        </Box>
    }
    const BidSection = () => {
        return <>
            <Box className='priceContainerSkeleton'>
                <Skeleton variant="rectangular" width={150} height={15} />
                <Box className='price-wrap-skeleton '>
                    <Skeleton variant="rectangular" width={100} height={15} />
                    <Skeleton variant="rectangular" width={50} height={15} />
                    <Skeleton variant="rectangular" width={30} height={15} />
                </Box>
            </Box>
            <Box className='priceContainerSkeleton form'>
                <Skeleton className='input' variant="rectangular" width={170} height={35} />
                <Skeleton variant="rectangular" width={120} height={35} />
            </Box>
        </>
    }
    const SkeletonBottom = () => {
        return <>
            <Box className='priceMainContainerSkeleton'>
                <Box className='priceContainerSkeleton'>
                    <Skeleton variant="rectangular" width={150} height={15} />
                    <Box className='price-wrap-skeleton'>
                        <Skeleton variant="rectangular" width={150} height={15} />
                    </Box>
                </Box>
                {isSmallDevice || isMediumDevice && <BidSection />}
                {isSmallDevice || isMediumDevice && <Timer />}
                <Box className='user'>
                    <Skeleton variant="rectangular" width={170} height={20} />
                    <Box className='user-detail'>
                        <Skeleton variant="rectangular" width={40} height={40} />
                        <Box className='right'>
                            <Skeleton variant="rectangular" width={200} height={15} />
                            <Box className='rating-section'>
                                <Skeleton variant="rectangular" width={50} height={10} />
                                <Skeleton variant="rectangular" width={50} height={10} />
                            </Box>
                        </Box>
                        <Skeleton variant="rectangular" width={100} height={35} />
                    </Box>
                </Box>
            </Box>
        </>
    }
    return (
        <Wrapper className={`${isLargeDevice || isExtraLargeDevice ? 'flexRow' : 'flexColumn'} mainContainerSkeleton`}>
            <Box className={(isLargeDevice || isExtraLargeDevice ? 'flexRow adContainerLarge' : 'flexColumn') + ' adContainerSkeleton'}>
                <Box >
                    <Box className='imageContainerSkeleton'>
                        <Skeleton variant="rectangular" />
                    </Box>
                    <Box className='header'>
                        <Skeleton className='heading' variant="rectangular" />
                        <Box className='adDetailContainer'>
                            {[1, 2, 3, 4, 5].map((index) => {
                                return <Box sx={{ mr: 2 }} className='flexColumn alignItemsCenter' key={index}>
                                    <Skeleton variant="rectangular" width={60} height={10} />
                                </Box>
                            })}
                        </Box>
                        {!isSmallDevice && !isMediumDevice && <SkeletonBottom />}
                    </Box>
                </Box>
            </Box>
            {(isSmallDevice || isMediumDevice) && <SkeletonBottom />}
            {!isSmallDevice && !isMediumDevice && <Box>
                <BidSection />
                <Timer />
            </Box>}
        </Wrapper>

    )
}

const Wrapper = styled(Box)`
&.mainContainerSkeleton{
    border:1px solid #F2F2F2;
    padding:10px;
    border-radius:6px;
    margin-bottom:10px;
}
.adContainerLarge{
    .imageContainerSkeleton{
        max-width:300px;
        min-width:300px;
    }
    .header{
        max-width:500px;
    }
    .form{
        span.input{ 
              width:230px !important; 
        }
    }
}
.user{
    display:flex;
    width:100%;
    gap:10px;
    flex-direction:column;
    .user-detail{
        display:flex;
        width:100%;
        gap:5px;
        flex-direction: row;
        align-items:start;
        justify-content:space-between;
        .rating-section{
            display:flex;
            width:100%;
            gap:10px;
            margin-top:10px;
            flex-direction: row;
        }
    }
}
&.mainContainerSkeleton{
    margin:10px;
    width:calc( 100% - 20px ); 
}
.adContainerSkeleton{
    width:100%;
}
.adContainerSkeleton>div{
    display:flex;
    width:100%;
    flex-direction:row;
    .imageContainerSkeleton{
        width:40%;
        aspect-ratio:4/3;
        height:100%;
        .MuiSkeleton-root{
            height:100%;
        }
    }
    .header{
        padding:0 10px;
        display:flex;
        width:60%;
        flex-direction:column;
        gap:5px;
        .adDetailContainer{
            display:flex;
            width:100%;
            flex-direction:row; 
            gap:8px;  
            flex-wrap:wrap; 
        }
    }
}
.priceMainContainerSkeleton{
    display:flex;
    width:100%;
    flex-direction:column; 
    
}
.priceContainerSkeleton{ 
    margin:10px 0 5px 0;
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    .price-wrap-skeleton{
        display:flex;
        width:100%;
        flex-direction:row;
        gap:4px;
        justify-content:end;
    }
}
.counter{
    max-width:340px;
    display:flex;
    width:100%;
    flex-direction:row;
    gap:5px;
    justify-content:center;
    align-items:center;
    &.large {
        gap:10px;
        &>div{
            display:flex;
            width:100%;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            gap:5px;
        }
    }
}
@media(max-width: 992px){
    .priceContainerSkeleton{
        max-width: 100%;
    }
    .small{
        justify-content: space-around;
    }
}
`
export default AdSkeleton