/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from 'react';
import { GlobalStyle } from '../styles/global-styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { CountriesDropGroupWiseData, getSubsidiaryStored } from 'config/variables'
import 'styles/CssStyle/scss/main.scss'
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getUserDataRequest, getUsersPermissionsRequest } from 'store/actions/user-actions';
import { fetchToken } from 'firebasePushNotifications'; //push notification code
import { getNotificationCountRequest } from 'store/actions/company-actions';
import GlobalComponent from './components/GlobalComponent/GlobalComponent';
import { colorNew } from 'styles/StyleConstants';
import Mobilereference from './Component2/GlobalComponent/Mobilereference';
import { setFilters } from './Component2/Layouts/AdsLayouts/Filter/FilterComponent/FilterData';
import MainRouter from 'app/mainRouter';

import { CacheProvider } from '@emotion/react';
import createCache from "@emotion/cache";
import { localstorageKey } from 'config/constants';
import { getActiveLanguageSuccess, removeDeviceTokenRequest, saveDeviceTokenRequest } from 'store/actions/auth-actions';
import HandleChats from './components/HandleChats';
import FCMListener from './components/FCMListener';
import UnderMaintenance from './components/UnderMaintenance/underMaintenance';
import { PusherClient, PrivatePusherClient } from 'websocket';
import SubsidiaryDetails from './components/SubsidiaryDetails';
import LanguageSetup from './components/GlobalComponent/LanguageSetup/Index';
// Check if the user agent is Prerender. Speedy should be false if so.
const speedy = navigator.userAgent.toLowerCase().indexOf("prerender") === -1;

// Create an emotion cache
const emotionCache = createCache({
  key: "emotion-cache",
  speedy: speedy,
})


const theme = createTheme({
  palette: {
    primary: {
      main: colorNew.primaryColor,
    },
    secondary: {
      main: '#ff0000',
    },
  },
});


export function App(props: any) {

  const { i18n } = useTranslation();

  const [isTokenFound, setTokenFound] = useState(false);
  const { loggedIn } = useSelector((state: any) => state.auth)
  const dispatch = useDispatch();


  const [isSiteUnderMaintenance] = useState(() => JSON.parse(process.env.REACT_APP_UNDER_MAINTENANCE || 'false'))
  const [disableMaintenanceMode] = useState(() => JSON.parse(localStorage.getItem('disable_maintenance_mode') || 'false'))


  useEffect(() => {
    // store variable in localStorage for display launching hotdeal popup
    //store country data continent wise
    if (!localStorage.getItem(localstorageKey.groupedCountries)) {
      CountriesDropGroupWiseData();
    }
  }, [])

  React.useEffect(() => {
    // returning if site is in maintenance mode
    if (isSiteUnderMaintenance && !disableMaintenanceMode) {
      return
    }
    localStorage.removeItem('prevPath')
    localStorage.removeItem('adsId')
    const sub = getSubsidiaryStored()
    if (sub && sub !== null) {
      dispatch(getUsersPermissionsRequest({ subsidiaryId: sub }))
    } else {
      dispatch(getUsersPermissionsRequest({}))
    }
    if (loggedIn) {
      dispatch(getNotificationCountRequest())
      dispatch(getUserDataRequest())
    }
    setFilters(dispatch)
    if (localStorage.getItem(localstorageKey.activeLanguages)) {
      dispatch(getActiveLanguageSuccess(JSON.parse(localStorage.getItem(localstorageKey.activeLanguages) || '{}')))
    }
  }, [])

  useEffect(() => {
    fetchToken(setTokenFound, addRemoveFunction);
  }, [loggedIn])

  const addRemoveFunction = (deviceId, remove) => {
    if (remove) {
      dispatch(removeDeviceTokenRequest({
        device_id: deviceId,
        app_type: 2
      }))
      localStorage.removeItem('notificationTokenSaved')
    } else {
      const token = localStorage.getItem('notificationTokenSaved')
      if (loggedIn && !token) {
        dispatch(saveDeviceTokenRequest({
          device_id: deviceId,
          device_type: 'web',
          app_type: 2
        }))
      }
    }
  }

  React.useEffect(() => {
    if (loggedIn) {
      PrivatePusherClient();
      PusherClient();
    }
  }, [loggedIn])

  return <CacheProvider value={emotionCache}>
    <ThemeProvider theme={theme}>

      {/* Component appears if site is under maintenance */}
      {
        isSiteUnderMaintenance && !disableMaintenanceMode && <UnderMaintenance />
      }

      {/* Component appears if site is not under maintenance */}
      {
        (!isSiteUnderMaintenance || disableMaintenanceMode) &&
        <>

          <ToastContainer style={{ maxWidth: '350px', width: 'auto' }} />

          <LanguageSetup />

          <GlobalComponent />

          <Mobilereference />

          {loggedIn &&
            <>
              <SubsidiaryDetails />
              <HandleChats />
              <FCMListener />
            </>
          }
          <MainRouter />
          <GlobalStyle />
        </>
      }
    </ThemeProvider>
  </CacheProvider >
}
