import { useWindowSize } from 'app/Hooks/windowResize';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SvgCallIcon, SvgfluentIcon, SvgLocationIcon, SvgMailIcon, SvgVectorDownArrow, SvgHomeIcon2, SvgVectorDownArrowCircle } from 'app/components/svgicons/svgNew';
import { Box } from '@mui/material';
import { theme } from 'config';
import UserSidebar from 'app/Component2/Layouts/UserLayout/components/UserSidebar';
import { switchUserOpen } from 'store/actions/component-actions';
import { Helmet } from 'react-helmet-async';
import { getSubsidiaryStored } from 'config/variables';
import ProfileImage from 'app/Component2/GlobalComponent/ProfileImage';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { colorNew } from 'styles/StyleConstants';

const ProfileLayout = (props: any) => {
  const { number, profileLink, verified, component, callPrefix, street, locality, zip, country, email, phone, userImage, countryFlag, pseudoName, userData, postalCode, url } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [mobile, width] = useWindowSize();
  const { t } = useTranslation()
  const sub = getSubsidiaryStored();
  const handleSwitchProfile = () => {
    dispatch(switchUserOpen())
  }
  return (
    <>
      <Helmet>
        {props.title ? <title>{props.title}</title> : null}
        {props.meta ? <meta property="og:title" content="Car to bike Ads Page" /> : null}
      </Helmet>
      <Box
        sx={{
          pb: 'auto',
          '.profile_wrapper': {
            maxHeight: {
              md: "calc(100vh - 87px)"
            },
            minHeight: profileLink ? "auto" : "calc(100vh - 87px)",
            'h2': {
              [theme.breakpoints.down('md')]: {
                display: 'flex',
                columnGap: '70px',
                alignItems: 'center',
                fontSize: '21px',
                fontWeight: '500',
                [theme.breakpoints.down('sm')]: {
                  columnGap: '40px',
                },
                'span.m_left_arrow': {
                  height: '40px',
                  width: '40px',
                  border: 'solid 1px #666666',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '6px',
                  cursor: 'pointer',
                  alignItems: 'center',
                }
              }
            },
            '.profile_content': {
              p: '3px',
              'h5': {
                fontSize: '21px',
                lineHeight: '25px',
                margin: '0',
                paddingTop: '4px',
                fontWeight: '400',
                [theme.breakpoints.down('md')]: {
                  marginBottom: '0',
                },
                'svg': {
                  width: '20px',
                  height: '20px,'
                }
              },
              'ul': {
                margin: '0',
                padding: '0',
                listStyle: 'none',
                'li': {
                  color: '#666666',
                  display: "flex",
                  alignItems: "center",
                  [theme.breakpoints.down('md')]: {
                    display: 'block',
                    padding: '0',
                    margin: '20px 0',
                  },
                  padding: '0 16px',
                  verticalAlign: 'middle',
                  'a': {
                    fontWeight: '400',
                    fontSize: '18px',
                    lineHeight: '22px',
                    color: '#666666',
                    paddingLeft: '8px',
                  },
                  '.postal-code': {
                    fontWeight: '400',
                    fontSize: '18px',
                    lineHeight: '22px',
                    color: '#666666',
                    paddingLeft: '8px',
                  }
                }
              }
            }
          }
        }}
      >
        <Box className="profile_wrapper">
          <Box className="profile_content"
            sx={{
              [theme.breakpoints.down('sm')]: {
                padding: '3px',
              },
            }}
          >
            <Box className='user_icon'
              sx={width < 993 ? {
                display: 'flex',
                alignItems: 'center',
                columnGap: '20px',
                gridTemplateColumns: '10% 50% 30%',
                // boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.04)',
                border: `2px solid ${colorNew.colorGrayLight}`,
                borderRadius: '6px',
                padding: {
                  md: '16px 22px',
                  xs: '16px 10px'
                },
                margin: '35px 0 20px',

                '.mobile_arrow_down': {
                  width: '8%',
                  textAlign: 'center',
                  'span': {
                    'svg': {
                      width: '25px'
                    }
                  }
                }
              }
                :
                {}
              }
            >
              <Box sx={{
                width: mobile ? { xs: '80%', sm: '85%' } : { md: '100%' },
                display: "flex",
                flexDirection: mobile ? 'row' : 'column',
                alignItems: 'center',
                '.link h5': {
                  maxWidth: '100%',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }
              }}>
                <Box
                  sx={{
                    width: '90px',
                    margin: '0 auto 14px',
                    [theme.breakpoints.down('md')]: {
                      margin: '0',

                    },
                    [theme.breakpoints.down('md')]: {
                      width: '36px',
                      height: '36px',
                      margin: '0',
                    },
                    height: '90px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    'img.mobile_user': {
                      width: '90px',
                      height: '90px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      [theme.breakpoints.down('md')]: {
                        height: '36px',
                        width: '36px',
                      }
                    },
                    'img.user_flag': {
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      position: 'absolute',
                      right: '0',
                      bottom: '0',
                      [theme.breakpoints.down('md')]: {
                        height: '15px',
                        width: '15px',
                      }
                    }
                  }}
                >
                  <ProfileImage imageType="medium" classes="mobile_user" />
                  {countryFlag ? <img src={countryFlag} alt={"country_flag"} className='user_flag' /> : null}
                </Box>
                <Link to={url} style={{ color: '#000', margin: width > 992 ? '0 0 14px 0' : "0 0 0 10px", maxWidth: '85%' }} className='link'>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: width > 992 ? 'center' : 'start', width: '100%' }}>
                    <h5 className='pointer'>{props.name ? props.name : pseudoName}
                    </h5>{verified ? <span> <SvgfluentIcon style={{ marginLeft: '5px', marginTop: '5px' }} /> </span> : null}
                  </Box>
                  <span style={{ color: 'grey', fontSize: '15px' }}>{t(translations.webTexts.VIEW_YOUR_PROFILE)}</span>
                </Link>
              </Box>
              {mobile && <Box sx={{
                width: '2px',
                height: '30px',
                border: '1px solid #999'
              }}></Box>}
              {mobile && <div className='mobile_arrow_down'>
                <span> <SvgVectorDownArrowCircle onClick={handleSwitchProfile} /></span>
              </div>}
            </Box>
            {!mobile &&
              <ul
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: '8px'
                }}
              >
                <li>
                  <SvgLocationIcon />
                  <span className={"postal-code"}> {street ? street + ", " : null}{number ? number + ", " : null} {locality ? locality + ", " : null}<span>{country}</span></span>
                </li>
                <li>
                  <SvgMailIcon /><span className={"postal-code"}>{email}</span>
                </li>
                <li>
                  <SvgCallIcon width='25' height='25' />
                  <span className={"postal-code"}> {callPrefix}  {phone ? phone.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " ") : null}</span>
                </li>
                <li>
                  <SvgHomeIcon2 /><span className={"postal-code"}>{postalCode}</span>
                </li>
              </ul>}
          </Box>

          {mobile &&
            <>
              <UserSidebar component={component ? component : null} />
            </>
          }
        </Box>
      </Box>
    </>
  )
}

export default ProfileLayout;