import styled from 'styled-components';
import { colorNew } from 'styles/StyleConstants';

export const NavBarWrapper = styled.div`

  height: 50px;
  border-bottom: 1px solid #efefef;
.nav-btns{
  color: #000;
  font-family: Inter;
  font-size: 18px;
  letter-spacing: 0.36px;  
  padding: 10px 20px;
  display: flex;
  position:relative;
}
.nav-btns::after {
  content: '|';
  font-size: 15px;
  position: absolute;
  top: 15px;
  color: #bbb;
  line-height: 20px;
  right: -4px;
}
.menu:last-child .nav-btns::after {
  display: none;  
}
.menu {
  border-bottom: 2px solid #ffffff;
}
.menu:hover{    
  border-bottom: 2px solid #efefef;
  z-index:9;
}
.menu:hover .sub-menu-container{
  display: block;
}
.nav-btns:hover{
  color: ${colorNew.colorGrayOnButton};
}
.menu:last-child .nav-btns::after {
   display: none;  
}
.newTagContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.newTag{
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  background: var(--Color-Error-500, #EF4444);
  border-radius: 4px;
  color: white !important;
  padding: 4px 8px  !important;
  height: max-content;
  margin-left: 5px;
}
.auction-type{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap:20px;
  color: ${colorNew.primaryColor};
  font-weight: 500;
}
.auction-type a{
  color: ${colorNew.primaryColor};
  font-weight: 500;
}
.sub-menu-container{
  left: 0;
  top:125px;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  display: none;
  padding: 30px 35px 35px 35px;
  z-index: 1;
  border-bottom: 4px solid #e6e6e6;
}

.menuHeaderTab{
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  color : rgb(97, 122, 247);
}
.iconHeaderTab{
  color: #000 !important;
  font-family: Inter;
  font-size: 18px !important;
  font-style: normal ;
  font-weight: 400 !important;
  line-height: normal;
  letter-spacing: 0.315px !important;
}
.iconHeaderTabFaq{
  color: black !important;
  font-family: Inter;
  font-size: 18px !important;
  font-style: normal ;
  font-weight: 400 !important;
  line-height: normal;
  letter-spacing: 0.315px !important;
}
.iconTextTab{
  font-size: 13px;
}
.menuLink span{
  color: ${colorNew.primaryColor};
  font-weight: 500;
  max-width : 30%;
  width : 30%;
}
.menuLinkMobile span{
  color: ${colorNew.primaryColor};
  font-weight: 400;
  padding : 10px 0;
  letter-spacing: 0.315px !important;
}
.vehicleSvg path{
  fill : black
}
.menuLink .iconHeaderTab, .menuLinkMobile .iconHeaderTab{
  padding:10px 0;
  display : flex;
}
.closeIcon{
  width :19px !important;
  height :19px !important;
}
.closeIcon path{
  stroke : #000
}
.mobileMenu svg{
  width:25px;
  height:25px;
}
.logoSvg{
  width:30px !important;
  height : auto !important;
}

.show{
  opacity: 1 !important;
  overflow: hidden !important;
  max-height: 220px !important;
  height: auto !important;
  transition: max-height .5s, opacity .5s ease-in;
}

.toggle{
  transition: max-height .5s, opacity .5s ease-out; 
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
}
.accordianIcon{
  width:16px !important;
}
.accordianIcon path{
  fill: ${colorNew.colorGrayOnButton};
}
.loginBtn{
  padding: 6px 24px;
  white-space: nowrap;
  background-color: ${colorNew.primaryColor} !important;
  border-radius: 4px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  color: rgb(255, 255, 255);
  height: 48px;
  display: flex;
  align-items: center;
  gap:'10px';
} 

// ---------------------------------------------------- Media Queries ----------------------------------------------------


@media (min-width: 768px) and (max-width: 1092px) {
  .addProduct {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
  }
  .addProductIcon {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    background: rgb(97, 122, 247);
    border-radius: 50%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;
}
.addProduct span {
  color: rgb(101, 121, 244);
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.42px;
}
  .imageWrapper{
    position: relative;
    display: flex;
    align-items:center;
  }
  .imageWrapper .image-1{
    width :40px;
    height :40px;
    object-fit: cover;
    border-radius: 50%;
  }
  .imageWrapper .image-2{
    width: 15px;
    height: 15px;
    object-fit: cover;
    border-radius: 16px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .text-name{
    margin: 0 30px 0 8px;
    color: ${colorNew.colorBlack};
    display: block;
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.54px;
  }
  .switchProfile{
    display:flex;
    align-items : center;
    justify-content : center;
    width: 40px;
    height: 40px;
    border-radius : 50%;
    background : #F2F2F2;
  }
  .menuHeaderTab{
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    color : rgb(97, 122, 247);
  }
  .iconHeaderTab{
    color: #000 !important;
    font-family: Inter;
    font-size: 18px !important;
    font-style: normal ;
    font-weight: 400 !important;
    line-height: normal;
    letter-spacing: 0.315px !important;
  }
  .iconHeaderTabFaq{
    color: black !important;
    font-family: Inter;
    font-size: 18px !important;
    font-style: normal ;
    font-weight: 400 !important;
    line-height: normal;
    letter-spacing: 0.315px !important;
  }
  .iconTextTab{
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .site svg{
    width: 220px;
    height : auto;
  }
}

@media (min-width: 1201px) {
  .site svg{
    width: 273px;
    height : auto;
  }
}
`;