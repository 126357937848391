import { Box } from '@mui/material';
import { SvgInfoIcon } from 'app/components/svgicons/svgNew';
import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { color, colorNew } from 'styles/StyleConstants'

export const ProgressBar = (props) => {
  const { filledPercent, handleOpenProgress, classes, positionIcon } = props;
  const [color, setColor] = useState<string>('')
  const colorCode = () => {
    if (filledPercent && filledPercent !== null && filledPercent <= 65) {
      setColor(colorNew.colorRed)
    } else if (filledPercent && filledPercent > 33 && filledPercent <= 99) {
      setColor(colorNew.colorYellow2)
    } else {
      setColor(colorNew.colorGreenDark)
    }
  }
  useEffect(() => {
    colorCode()
  }, [filledPercent])
  return (
    <Wrapper className={`progressBar ${classes ? classes : ''}`}
      sx={{
        display: 'flex',
        alignItems: 'center',
        '.text-center': {
          display: 'flex',
        }
      }}
    >
      {positionIcon === 'left' ?
        <sup onClick={() => handleOpenProgress ? handleOpenProgress() : ''}
          style={{
            marginRight: '9px',
            cursor: "pointer",
          }}
        >
          <SvgInfoIcon />
        </sup>
        : null
      }
      <div className="text-center">
        <p className="d-inline-block" style={{
          backgroundColor: color,
          width: filledPercent + '%',
          maxWidth: '100%',
          margin: '-1px',
        }} >
        </p>
      </div>
      {positionIcon === 'right' ?
        <sup onClick={handleOpenProgress}
          style={{
            marginLeft: '5px',
            cursor: "pointer",
          }}
        >
          <SvgInfoIcon />
        </sup>
        : null
      }
    </Wrapper>
  )
}


export default ProgressBar;

const Wrapper = styled(Box)`
    /* .progressBar{ */
      background-color: transparent !important;
      justify-self: stretch;
      /* justify-content: end !important;      */
      div{
        max-width: 127px;
        width: 100%;
        height: 7px;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        span{
          background:#FF7C04;
          height: 6px;
        }
      }
      sup{
        top : 0;
        margin-right: 5px;
      }
      .d-inline-block{
        overflow: hidden !important;
        height: 7px;
        border-radius: 50rem!important;
      }
    /* } */
`