import { Box, Button, Modal } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { HotdealPopupStyle } from './HotdealPopupStyle';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { SvgClose } from 'app/components/svgicons/svg';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { localstorageKey } from 'config/constants';
import { routes } from 'routes/routes';


const LaunchingHotdealPopup = () => {
    // states and hooks
    const [showPop, setShowpop] = useState<boolean>(false);
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()
    const { t } = useTranslation();
    const history = useHistory()

    // display popup only when there is no key (displayHotdealPopup) stored in localStorage
    const isHotdealPopupDisplay = useMemo(() => !localStorage.getItem(localstorageKey.displayHotdealPopup), [])

    useEffect(() => {
        // open popup window after 10 seconds when cookie accepted
        setTimeout(() => {
            setShowpop(true);
        }, 10000);

    }, []);

    if (!isHotdealPopupDisplay) {
        //return null  when there is key displayHotdealPopup false in local storage
        return null
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isSmallDevice || isMediumDevice ? 300 : 815,
        maxHeight: 525,
        width: '100%',
        p: isSmallDevice || isMediumDevice ? '20px ' : 0
    };

    const handlePopupClose = () => {
        localStorage.setItem(localstorageKey.displayHotdealPopup, 'false')
        setShowpop((prevState) => !prevState)
    }

    return (
        <>
            <Modal
                open={showPop}
                onClose={handlePopupClose}
                sx={{ zIndex: 101 }}
                disableAutoFocus={true}
            >
                <HotdealPopupStyle>
                    <style>
                        {` @import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@400;500;600;700&display=swap');`}
                    </style>
                    <Box sx={style}>
                        <Box className='relative'>
                            <SvgClose className='closeIcon' onClick={handlePopupClose} />
                        </Box>
                        <Box className='flexRow justifyContentCenter'>
                            {(isLargeDevice || isExtraLargeDevice) &&
                                <Box>
                                    <img className='image-style' src={'/cartobike-hot-deal.webp'} alt='Cartobike Hot Deal' />
                                </Box>}
                            <Box sx={{ padding: isLargeDevice || isExtraLargeDevice ? '24px' : '16px', background: "#fff", marginLeft: "-1px", borderRadius: isLargeDevice || isExtraLargeDevice ? "0px  12px 12px 0px" : "12px" }}>
                                <span className='display-text' style={{ marginTop: isSmallDevice ? '5px' : '30px' }}> {t(translations.HOT_DEALS.HOT_DEAL_WELCOME)} </span>
                                <span className='display-text'> {t(translations.HOT_DEALS.HOT_DEAL_TO)} </span>
                                <Box className='hotdeal-img'><span className='hot-deal-text'>{t(translations.HOT_DEALS.HOT_DEAL)}</span></Box>
                                <span className='launch-header'>{t(translations.HOT_DEALS.HOT_DEAL_POPUP_HEADER)}</span>
                                <Box className='description'>
                                    {t(translations.HOT_DEALS.HOT_DEAL_POPUP_DESCRIPTION)}
                                </Box>
                                <Box className='flexRow justifyContentCenter' sx={{ mt: '24px' }}>
                                    <Button className='hot-deal-btn pointer' onClick={() => {
                                        history.push(routes.publicRoutes.hotDeals)
                                        setShowpop((prevState) => !prevState)
                                    }}>
                                        {t(translations.HOT_DEALS.HOT_DEAL_ALL_HOTDEALS)}
                                    </Button></Box>
                                <a className='learn-more pointer' href={process.env.REACT_APP_YOUTUBE_SOCIAL_LINK} onClick={() => handlePopupClose()} target='_blank'>{t(translations.HOT_DEALS.HOT_DEAL_LEARN_MORE)}</a>
                            </Box>
                        </Box>
                    </Box>
                </HotdealPopupStyle>
            </Modal >

        </>
    )
}

export default LaunchingHotdealPopup