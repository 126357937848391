import { Box } from '@mui/material';
import { useWindowSize } from 'app/Hooks/windowResize';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';
import MobileNavBottom from 'app/components/NavBar/MobileNavBottom';
import { PublicProfileWrapper } from './style/publicProfileStyle';
import UserInformation from './Component/UserInformation';
import HistoryAndReview from './Component/HistoryAndReviewSection';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Toaster } from 'services/Toaster';
import { addReviewsFailure, followUnfollowFailure, followersListFailure, followingListFailure, getProfileFailure, getProfileRequest, getReviewsFailure, getReviewsRequest, resetPublicProfile, updateReviewsFailure } from 'store/actions/publicProfile-action';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import * as publicProfileConstants from "config/publicProfileConfig"
import { getSubsidiaryStored } from 'config/variables';
import { localstorageKey } from 'config/constants';
import PublicLayout from "app/Component2/Layouts/PublicLayout";
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import StaticSmallDeviceHeader from 'app/components/Header/StaticSmallDeviceHeader';
import moment from 'moment';

const PublicProfileScreen = () => {

  const [mobile] = useWindowSize()
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()
  const { userType, slug } = useParams()
  const { userData } = useSelector((state: any) => state.user)
  const { reviewsListError, loadingReviews, addReviewLoading, addReviewFailureData, updateReviewFailureData, profileLoading, profileFailureData, flagReviewLoading, followUnfollowLoading, followUnfollowFailureData, followersListError, followingListError, flagReviewFailureData } = useSelector((state: RootStateOrAny) => state.publicProfile)
  const { loadingChat } = useSelector((state: RootStateOrAny) => state.chat)
  const { profileSuccessData } = useSelector((state: RootStateOrAny) => state.publicProfile)
  const shareUrl = window.location.origin + '/' + localStorage.getItem(localstorageKey.i18nextLng) + "/" + userType + "/" + slug;
  const parentScrollRef: any = useRef(null)
  const [isNavFixedTop, _isNavFixedTop] = useState(false);
  const [isSmallDevice, isMediumDevice] = useDeviceType()

  useEffect(() => {
    if (localStorage.getItem('prevPath') != location.pathname) {
      dispatch(resetPublicProfile())
    }
    window.scrollTo(0, 0);
  }, [])

  // Requesting for public profile details 
  useEffect(() => {
    // Requesting API call only if user haven't came back to same profile
    if (localStorage.getItem('prevPath') != location.pathname) {
      let payload = {
        slug: slug
      }
      if (userType == publicProfileConstants.profile.pro) {
        payload['type'] = publicProfileConstants.profileType.subsidiary
      }
      if (userType == publicProfileConstants.profile.user) {
        payload['type'] = publicProfileConstants.profileType.user
      }
      dispatch(getProfileRequest(payload))
    }
  }, [slug, getSubsidiaryStored()])


  useEffect(() => {
    if (reviewsListError && Object.keys(reviewsListError).length > 0) {
      if (reviewsListError.data) {
        Toaster.error(Object.values(reviewsListError.data).toString())
        dispatch(getReviewsFailure({}))
        return
      }
      Toaster.error(reviewsListError.message)
      dispatch(getReviewsFailure({}))
    }
  }, [reviewsListError])

  useEffect(() => {
    if (addReviewFailureData && Object.keys(addReviewFailureData).length > 0) {
      if (addReviewFailureData.data) {
        Toaster.error(Object.values(addReviewFailureData.data).toString())
        dispatch(addReviewsFailure({}))
        return
      }
      Toaster.error(addReviewFailureData.message)
      dispatch(addReviewsFailure({}))
    }
  }, [addReviewFailureData])

  useEffect(() => {
    if (updateReviewFailureData && Object.keys(updateReviewFailureData).length > 0) {
      if (updateReviewFailureData.data) {
        Toaster.error(Object.values(updateReviewFailureData.data).toString())
        dispatch(updateReviewsFailure({}))
        return
      }
      Toaster.error(updateReviewFailureData.message)
      dispatch(updateReviewsFailure({}))
    }
  }, [updateReviewFailureData])

  useEffect(() => {
    if (profileFailureData && Object.keys(profileFailureData).length > 0) {
      if (profileFailureData.data) {
        Toaster.error(Object.values(profileFailureData.data).toString())
        dispatch(getProfileFailure({}))
        return
      }
      // Toaster.error(profileFailureData.message)
      dispatch(getProfileFailure({}))
    }
  }, [profileFailureData])

  useEffect(() => {
    if (followUnfollowFailureData && Object.keys(followUnfollowFailureData).length > 0) {
      if (followUnfollowFailureData.data) {
        Toaster.error(Object.values(followUnfollowFailureData.data).toString())
        dispatch(followUnfollowFailure({}))
        return
      }
      Toaster.error(followUnfollowFailureData.message)
      dispatch(followUnfollowFailure({}))
    }
  }, [followUnfollowFailureData])

  useEffect(() => {
    if (followersListError && Object.keys(followersListError).length > 0) {
      if (followersListError.data) {
        Toaster.error(Object.values(followersListError.data).toString())
        dispatch(followersListFailure({}))
        return
      }
      Toaster.error(followersListError.message)
      dispatch(followersListFailure({}))
    }
  }, [followersListError])

  useEffect(() => {
    if (followingListError && Object.keys(followingListError).length > 0) {
      if (followingListError.data) {
        Toaster.error(Object.values(followingListError.data).toString())
        dispatch(followingListFailure({}))
        return
      }
      Toaster.error(followingListError.message)
      dispatch(followingListFailure({}))
    }
  }, [followingListError])


  useEffect(() => {
    if (flagReviewFailureData && Object.keys(flagReviewFailureData).length > 0) {
      if (flagReviewFailureData.data) {
        Toaster.error(Object.values(flagReviewFailureData.data).toString())
        dispatch(followingListFailure({}))
        return
      }
      Toaster.error(flagReviewFailureData.message)
      dispatch(followingListFailure({}))
    }
  }, [flagReviewFailureData])

  const [scrollFlagRev, _scrollFlagRev] = useState<boolean>(true);
  const [pageRev, setPageRev] = useState<number>(1)
  const { reviewsListLastPage } = useSelector((state: RootStateOrAny) => state.publicProfile)

  //Function request for next page data when scrolled to bottom
  const handleReviewScroll = (e) => {
    // 60px is the height of downloadApp banner , so fix the profile navbar just before scrolling that much height(60px).
    if (e.target.scrollTop >= 60)
      _isNavFixedTop(true);

    if (e.target.scrollTop < 5) {
      _isNavFixedTop(false);
    }
    // bottom return boolean value and Math.abs to get absolute value 
    const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
    if (bottom && scrollFlagRev && pageRev < reviewsListLastPage) {
      setPageRev(pageRev + 1)
      dispatch(getReviewsRequest({
        page: pageRev + 1,
        model_type: userType === publicProfileConstants.profile.pro ? publicProfileConstants.profileTypeString.subsidiary : userType,
        model: profileSuccessData.id
      }))
      _scrollFlagRev(false)
      return;
    } else {
      _scrollFlagRev(true)
    }
  }

  const getPrivateUserMetaTitle = useMemo(() => {
    if (userType == publicProfileConstants.profile.pro) {
      return t(translations.META_AND_H1_FOR_SEO.PUBLIC_PROFILE_SUBSIDIARY_USER_META_TITLE, { companyName: (profileSuccessData.pseudo || profileSuccessData.name) })
    } else {
      return t(translations.META_AND_H1_FOR_SEO.PUBLIC_PROFILE_PRIVATE_USER_META_TITLE, { userName: (profileSuccessData.pseudo || profileSuccessData.name) })
    }
  }, [userType, profileSuccessData, profileSuccessData])

  const getPrivateUserMetaDescription = useMemo(() => {
    if (userType == publicProfileConstants.profile.pro) {
      return t(translations.META_AND_H1_FOR_SEO.PUBLIC_PROFILE_SUBSIDIARY_USER_META_DESCRIPTION, { companyName: (profileSuccessData.pseudo || profileSuccessData.name) })
    } else {
      return t(translations.META_AND_H1_FOR_SEO.PUBLIC_PROFILE_PRIVATE_USER_META_DESCRIPTION, { userName: (profileSuccessData.pseudo || profileSuccessData.name) })
    }
  }, [userType, profileSuccessData, profileSuccessData])

  let schemaData = {
    "@context": "https://schema.org",
    "@type": 'AutoDealer',
    "name": profileSuccessData?.name || '',
    "image": profileSuccessData?.medium_profile_picture_url || '',
    "description": getPrivateUserMetaDescription || '',
    "url": window.location.href,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": profileSuccessData?.limited_address?.locality || '',
      "country": profileSuccessData?.country || ''
    },
  }

  if (profileSuccessData?.phone_principal || profileSuccessData?.phone) {
    schemaData['telephone'] = `${profileSuccessData?.limited_address?.prefix_call}${profileSuccessData?.phone_principal ?? profileSuccessData?.phone}`
  }

  let schemaForRating = {
    "@context": "https://schema.org",
    "@type": 'AutoDealer',
    "name": profileSuccessData?.name || '',
    "image": profileSuccessData?.medium_profile_picture_url || '',
    "description": getPrivateUserMetaDescription || '',
    "url": window.location.href,
  }

  if (profileSuccessData?.total_ratings_count) {
    schemaForRating["aggregateRating"] = {
      "@type": "AggregateRating",
      "ratingValue": profileSuccessData?.average_ratings || '',
      "reviewCount": profileSuccessData?.total_ratings_count || ''
    }
  }



  return (
    <>
      <PublicLayout
        title={getPrivateUserMetaTitle}
        meta={getPrivateUserMetaTitle}
        description={getPrivateUserMetaDescription}
        image={profileSuccessData.medium_profile_picture_url}
        shareUrl={shareUrl}
        type="Public Profile"
        showNavbar={true}
        hideFooter={true}
        hideHeader={isSmallDevice || isMediumDevice ? true : false}
        schemaData={schemaData}
        schemaForRating={schemaForRating}
      >
        {(loadingReviews || addReviewLoading || profileLoading || loadingChat || followUnfollowLoading || flagReviewLoading) && <ContentLoader />}
        {isSmallDevice || isMediumDevice ? <StaticSmallDeviceHeader title={t(translations.PUBLICPROFILE.PROFILE)} /> : null}

        <PublicProfileWrapper>
          <Box
            sx={{
              backgroundColor: '#fff'
            }}
            onScroll={(e) => handleReviewScroll(e)}
            ref={parentScrollRef}
          >
            <Box className={mobile ? 'flexColumn' : 'flexRow'}>
              <UserInformation isNavFixedTop={isNavFixedTop} />
              <HistoryAndReview _scrollFlagRev={_scrollFlagRev} scrollFlagRev={scrollFlagRev} setPageRev={setPageRev} pageRev={pageRev} />
            </Box>
          </Box>
        </PublicProfileWrapper>
      </PublicLayout>
    </>
  );
};

export default PublicProfileScreen;
