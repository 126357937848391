import { Box } from '@mui/material'
import { UserAvatarInterface } from '../../interface/interface'
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const UserAvatar = ({ hideStatus = false, hideFlag = false, userDetails, active }: UserAvatarInterface) => {

    const { blockedByUsers, blockedBySubs } = useSelector((state: RootStateOrAny) => state.chat)

    const [isBlocked, _isBlocked] = useState<boolean>(false)

    useEffect(() => {
        if (userDetails && Object.keys(userDetails).length > 0) {
            if ((userDetails.subsidiary_id && blockedBySubs.some(o => o == userDetails.subsidiary_id)) || (!userDetails.subsidiary_id && userDetails.id && blockedByUsers.some(o => o == userDetails.id))) {
                _isBlocked(true)
            } else {
                _isBlocked(false)
            }
        }
    }, [userDetails, blockedByUsers, blockedBySubs])

    return (
        <Box sx={{
            position: 'relative'
        }}>
            <Box className='userProfileView'>
                <img src={userDetails?.profile_picture_url && !isBlocked ? userDetails.profile_picture_url : userPlaceHolder} alt="User Image" />
            </Box>
            {!hideFlag && (userDetails?.limited_address?.flag_thumbnail || userDetails?.flag) && <img className="activeFlag" src={userDetails?.limited_address ? userDetails?.limited_address?.flag_thumbnail : userDetails?.flag} />}
            {!hideStatus && active && <span className='activeDot'></span>}
        </Box>
    )
}

export default UserAvatar