import { handleActions } from 'redux-actions';
import {
  getVehicleBrandRequest,
  getVehicleBrandSuccess,
  getVehicleBrandFailure,

  getVehicleYearRequest,
  getVehicleYearSuccess,
  getVehicleYearFailure,

  getVehicleTypeRequest,
  getVehicleTypeSuccess,
  getVehicleTypeFailure,

  getVehicleVersionRequest,
  getVehicleVersionSuccess,
  getVehicleVersionFailure,

  getVehicleModelListRequest,
  getVehicleModelListSuccess,
  getVehicleModelListFailure,

  getPowerListRequest,
  getPowerListSuccess,
  getPowerListFailure,

  getFuelListRequest,
  getFuelListSuccess,
  getFuelListFailure,

  getGearBoxListRequest,
  getGearBoxListSuccess,
  getGearBoxListFailure,

  getVehicleBodyListRequest,
  getVehicleBodyListSuccess,
  getVehicleBodyListFailure,

  getVehicleValidateRequest,
  getVehicleValidateSuccess,
  getVehicleValidateFailure,
  getVehicleValidateReset,


  getVehicleEquipmentRequest,
  getVehicleEquipmentSuccess,
  getVehicleEquipmentFailure,

  getVehicleInteriorRequest,
  getVehicleInteriorSuccess,
  getVehicleInteriorFailure,

  getVehicleConditionsRequest,
  getVehicleConditionsSuccess,
  getVehicleConditionsFailure,

  getVehicleSeasonRequest,
  getVehicleSeasonSuccess,
  getVehicleSeasonFailure,

  getVehicleTiersRequest,
  getVehicleTiersSuccess,
  getVehicleTiersFailure,

  getVehicleColorsRequest,
  getVehicleColorsSuccess,
  getVehicleColorsFailure,

  getVehicleWheelsRequest,
  getVehicleWheelsSuccess,
  getVehicleWheelsFailure,

  updateVehicleDataRequest,
  updateVehicleDataSuccess,
  updateVehicleDataFailure,
  updateVehicleDataReset,

  getVehicleFrameSizeRequest,
  getVehicleFrameSizeSuccess,
  getVehicleFrameSizeFailure,

  createAddsRequest,
  createAddsSuccess,
  createAddsFailure,
  createAddsReset,

  getPublicAddsRequest,
  getPublicAddsSuccess,
  getPublicAddsFailure,

  getVehicleMaterialRequest,
  getVehicleMaterialSuccess,
  getVehicleMaterialFailure,

  getVehicleBatteryPositionRequest,
  getVehicleBatteryPositionSuccess,
  getVehicleBatteryPositionFailure,

  getVehicleDriveTypesRequest,
  getVehicleDriveTypesSuccess,
  getVehicleDriveTypesFailure,

  getVehicleSuspensionTypesRequest,
  getVehicleSuspensionTypesSuccess,
  getVehicleSuspensionTypesFailure,

  getVehicleBrakeTypesRequest,
  getVehicleBrakeTypesSuccess,
  getVehicleBrakeTypesFailure,

  getVehicleEnginePositionsRequest,
  getVehicleEnginePositionsSuccess,
  getVehicleEnginePositionsFailure,

  getVehicleEnginesRequest,
  getVehicleEnginesSuccess,
  getVehicleEnginesFailure,

  getVehicleComponentsRequest,
  getVehicleComponentsSuccess,
  getVehicleComponentsFailure,

  getVehicleSexRequest,
  getVehicleSexSuccess,
  getVehicleSexFailure,

  getAuctionRequest,
  getAuctionSuccess,
  getAuctionFailure,


  getPublicAddsDetailRequest,
  getPublicAddsDetailSuccess,
  getPublicAddsDetailFailure,
  getPublicAddsDetailReset,

  createBidRequest,
  createBidSuccess,
  createBidFailure,

  uploadVehicleImagesRequest,
  uploadVehicleImagesSuccess,
  uploadVehicleImagesFailure,

  bidAcceptRejectRequest,
  bidAcceptRejectSuccess,
  bidAcceptRejectFailure,

  vehicleSoldReserveRequest,
  vehicleSoldReserveSuccess,
  vehicleSoldReserveFailure,

  getVehicleDetailRequest,
  getVehicleDetailSuccess,
  getVehicleDetailFailure,
  getVehicleDetailReset,

  editAdRequest,
  editAdSuccess,
  editAdFailure,
  editAdReset,

  getAllCustomersRequest,
  getAllCustomersSuccess,
  getAllCustomersFailure,

  vehicleExpertiseSaveRequest,
  vehicleExpertiseSaveSuccess,
  vehicleExpertiseSaveFailure,

  vehicleExpertiseUpdateRequest,
  vehicleExpertiseUpdateSuccess,
  vehicleExpertiseUpdateFailure,

  vehicleExpertiseFetchRequest,
  vehicleExpertiseFetchSuccess,
  vehicleExpertiseFetchFailure,

  deleteVehicleRequest,
  deleteVehicleSuccess,
  deleteVehicleFailure,
  deleteVehicleReset,

  deleteClassicAuctionAdRequest,
  deleteClassicAuctionAdSuccess,
  deleteClassicAuctionAdFailure,
  deleteClassicAuctionAdReset,

  getVehicleTargetAudienceRequest,
  getVehicleTargetAudienceSuccess,
  getVehicleTargetAudienceFailure,

  getAdDetailRequest,
  getAdDetailSuccess,
  getAdDetailFailure,
  getAdDetailReset,

  cancelReservationRequest,
  cancelReservationSuccess,
  cancelReservationFailure,

  confirmVehiclePurchaseRequest,
  confirmVehiclePurchaseSuccess,
  confirmVehiclePurchaseFailure,
  confirmVehiclePurchaseReset,

  rejectVehiclePurchaseRequest,
  rejectVehiclePurchaseSuccess,
  rejectVehiclePurchaseFailure,
  rejectVehiclePurchaseReset,

  callRequest,
  callSuccess,
  callFailure,


  getAuctionAdsCountRequest,
  getAuctionAdsCountSuccess,
  getAuctionAdsCountFailure,

  getEuroRequest,
  getEuroSuccess,
  getEuroFailure,

  removeAdFromFavouriteFailure,
  removeAdFromFavouriteSuccess,
  removeAdFromFavouriteRequest,

  addAdInFavouriteFailure,
  addAdInFavouriteSuccess,
  addAdInFavouriteRequest,

  updateOnBidChange,

  getCarFilterRequest,
  getCarFilterSuccess,
  getCarFilterFailure,

  getMotorCycleFilterRequest,
  getMotorCycleFilterSuccess,
  getMotorCycleFilterFailure,

  getBikeFilterRequest,
  getBikeFilterSuccess,
  getBikeFilterFailure,

  getStepFilterRequest,
  getStepFilterSuccess,
  getStepFilterFailure,
  getPublicAuctionAddsRequest,
  getPublicAuctionAddsSuccess,
  getPublicAuctionAddsFailure,
  getPublicAddsShopRequest,
  getPublicAddsShopSuccess,
  getPublicAddsShopFailure,
  getAuctionHomeRequest,
  getAuctionHomeSuccess,
  getAuctionHomeFailure,
  getHomePublicAddsRequest,
  getHomePublicAddsSuccess,
  getHomePublicAddsFailure,
  getAuctionReset,
  cancelReservationReset,
  createBidReset,
  removeLoading,
  setLoading,
  bidAcceptRejectReset,
  setSelectedProductCategory,
  setSelectedVehicleType,
  putAdsIntoAuctionRequest,
  putAdsIntoAuctionSuccess,
  putAdsIntoAuctionFailure,
  putAdsIntoAuctionReset,
  resetBidChange,
  getHotDealRequest,
  getHotDealSuccess,
  getHotDealFailure,
  getHotDealReset,
  putAdsIntoHotDealRequest,
  putAdsIntoHotDealSuccess,
  putAdsIntoHotDealFailure,
  putAdsIntoHotDealReset,
  updateAuctionAdsCount
} from '../actions/vehicle-actions';
import { ProductCategories } from 'config/constants';

const initialState = {
  loading: false,
  filterLoad: false,
  adsCount: {},
  adsCountError: {},
  vehicles: {},
  validateData: {},
  validateDataError: {},
  validateDataNew: {},
  vehicleTypes: [],
  vehicleBrands: [],
  brandYears: [],
  brandYearsStatus: false,
  fuelList: [],
  modelList: [],
  modelRequested: false,
  powerList: [],
  gearBoxList: [],
  bodyTypeList: [],
  versionList: [],
  vehicleEquipmentList: [],
  vehicleEquipmentCatList: [],
  vehicleInteriorList: [],
  vehicleConditionList: [],
  vehicleSeasonList: [],
  vehicleTiersList: [],
  vehicleColorsList: [],
  vehicleWheelsList: [],
  vehicleFrameSizeList: [],
  createAddData: {},
  createAddError: [],
  publicAddsList: [],
  publicAddsListHome: [],
  publicAddsListShop: [],
  publicAddsAuctionList: [],
  publicAddsListPagination: [],
  publicAddData: [],
  publicAddDataHome: {},
  publicAddDataShop: [],
  publicAddAuctionData: [],
  publicAddsListError: {},
  publicAddsListShopError: {},
  publicAddsListAuctionError: {},
  vehicleMaterialList: [],
  vehicleBatteryPositionList: [],
  vehicleDriveTypesList: [],
  vehicleSuspensionList: [],
  vehicleBrakeTypeList: [],
  vehicleEnginePositionsList: [],
  vehicleEnginesList: [],
  vehicleComponentsList: [],
  vehicleSexList: [],
  updateVehicleSuccess: false,
  updateExpertiseSuccess: false,
  updateVehicleError: {},
  auctionList: [],
  auctionListHome: [],
  auctionListData: {},
  auctionListDataHome: {},
  auctionListError: {},
  publicAddDetail: {},
  publicAddDetailError: {},
  bidSuccess: false,
  bidError: {},
  uploadImageError: {},
  uploadImageSuccess: false,
  bidAcceptRejectStatus: {},
  bidAcceptRejectError: false,
  detailSuccess: false,
  editAddData: [],
  editAddError: {},
  allCustomers: [],
  customerData: {},
  allCustomerLastPage: null,
  loadingCustomers: false,
  allCustomersError: {},
  deleteVehicleStatus: {},
  deleteVehicleError: {},
  deleteClassicAuctionAdStatus: {},
  deleteClassicAuctionAdError: {},
  vehicleTargetList: [],
  adData: {},
  adDataError: {},
  cancelReservation: {},
  cancelReservationError: {},
  expertiseSections: [],
  expertiseSectionSuccess: [],
  vehicleSoldReservedError: {},
  callSuccessData: {},
  soldReserveStatus: {},
  addAddFavouriteStatus: {},
  addAddFavouriteError: {},
  removeAddFavouriteStatus: {},
  removeAddFavouriteError: {},
  bidNew: {},
  bidUpdate: {},
  bidLoading: false,
  expertiseSectionError: {},
  euroStandards: [],
  adLoader: false,
  carFilterList: {},
  motorCycleFilterList: {},
  bikeFilterList: {},
  stepFilterList: {},
  loadingForce: false,
  loadFilter: false,
  confirmVehiclePurchase: {},
  confirmVehicleSuccess: false,
  confirmVehiclePurchaseError: {},
  rejectVehiclePurchase: {},
  rejectVehicleSuccess: false,
  rejectVehiclePurchaseError: {},
  selectedProductCategory: ProductCategories.vehicle,
  selectedVehicleType: null,
  putIntoAuctionSuccess: false,
  putIntoAuctionFailure: {},
  hotDealListData: {},
  hotDealList: [],
  hotDealListError: {},
  putIntoHotDealSuccess: false,
  putIntoHotDealFailure: {},
}

const vehicle = handleActions(
  {
    [setSelectedProductCategory]: (state, { payload }) => ({
      ...state,
      selectedProductCategory: payload
    }),
    [setSelectedVehicleType]: (state, { payload }) => ({
      ...state,
      selectedVehicleType: payload
    }),
    [getVehicleTypeRequest]: state => ({
      ...state,
      loading: true,
      brandYearsStatus: false,
      vehicleTypes: [],
    }),
    [getVehicleTypeSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      brandYearsStatus: false,
      vehicleTypes: payload.items
    }),
    [getVehicleTypeFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleTypes: []
    }),
    [getEuroRequest]: state => ({
      ...state,
      loading: true,
      euroStandards: [],
    }),
    [getEuroSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      euroStandards: payload
    }),
    [getEuroFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      euroStandards: []
    }),

    [getVehicleBrandRequest]: state => ({
      ...state,
      loading: true,
      brandYearsStatus: false,
      vehicleBrands: []
    }),
    [getVehicleBrandSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      brandYearsStatus: false,
      vehicleBrands: payload.items
    }),
    [getVehicleBrandFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleBrands: []
    }),

    [getVehicleYearRequest]: state => ({
      ...state,
      loading: true,
      brandYearsStatus: false,
      brandYears: []
    }),
    [getVehicleYearSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      brandYearsStatus: true,
      brandYears: payload.items
    }),
    [getVehicleYearFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      brandYears: []
    }),

    [getVehicleVersionRequest]: state => ({
      ...state,
      loading: true,
      versionList: []
    }),
    [getVehicleVersionSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      versionList: payload.version_car.data
    }),
    [getVehicleVersionFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      versionList: []
    }),

    [getVehicleModelListRequest]: state => ({
      ...state,
      loading: true,
      modelRequested: false
      // modelList: []
    }),
    [getVehicleModelListSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      modelList: payload.items,
      modelRequested: true
    }),
    [getVehicleModelListFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      modelList: [],
      modelRequested: true
    }),

    [getPowerListRequest]: state => ({
      ...state,
      loading: true,
      powerList: []
    }),
    [getPowerListSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      powerList: payload.items
    }),
    [getPowerListFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      powerList: []
    }),

    [getFuelListRequest]: state => ({
      ...state,
      loading: true,
      fuelList: [],
    }),
    [getFuelListSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      fuelList: payload.items,
    }),
    [getFuelListFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      fuelList: [],
    }),

    [getGearBoxListRequest]: state => ({
      ...state,
      loading: true,
      gearBoxList: []
    }),
    [getGearBoxListSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      gearBoxList: payload.items
    }),
    [getGearBoxListFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      gearBoxList: []
    }),

    [getVehicleBodyListRequest]: state => ({
      ...state,
      loading: true,
      bodyTypeList: []
    }),
    [getVehicleBodyListSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      bodyTypeList: payload.items,
    }),
    [getVehicleBodyListFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      bodyTypeList: []
    }),

    [getVehicleValidateRequest]: state => ({
      ...state,
      loading: true,
      validateError: {},
      validateData: {},
      validateDataNew: {}
    }),
    [getVehicleValidateSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      validateData: payload.item,
      validateDataNew: payload.item
    }),
    [getVehicleValidateFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      validateError: payload,
    }),
    [getVehicleValidateReset]: (state, { payload }) => ({
      ...state,
      validateDataNew: {},
      loading: false,
    }),

    //step 9
    [getVehicleEquipmentRequest]: state => ({
      ...state,
      loading: true,
      vehicleEquipmentList: [],
      vehicleEquipmentCatList: []
    }),
    [getVehicleEquipmentSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleEquipmentList: payload.equipments,
      vehicleEquipmentCatList: payload.categories
    }),
    [getVehicleEquipmentFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleEquipmentList: [],
      vehicleEquipmentCatList: []
    }),

    [getVehicleInteriorRequest]: state => ({
      ...state,
      loading: true,
      vehicleInteriorList: [],
    }),
    [getVehicleInteriorSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleInteriorList: payload.items
    }),
    [getVehicleInteriorFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleInteriorList: []
    }),

    [getVehicleConditionsRequest]: state => ({
      ...state,
      loading: true,
      vehicleConditionList: [],
    }),
    [getVehicleConditionsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleConditionList: payload.items
    }),
    [getVehicleConditionsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleConditionList: []
    }),

    [getVehicleSeasonRequest]: state => ({
      ...state,
      loading: true,
      vehicleSeasonList: [],
    }),
    [getVehicleSeasonSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleSeasonList: payload.items
    }),
    [getVehicleSeasonFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleSeasonList: []
    }),

    [getVehicleTiersRequest]: state => ({
      ...state,
      loading: true,
      vehicleTiersList: [],
    }),
    [getVehicleTiersSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleTiersList: payload.items
    }),
    [getVehicleTiersFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleTiersList: []
    }),

    [getVehicleColorsRequest]: state => ({
      ...state,
      loading: true,
      vehicleColorsList: [],
    }),
    [getVehicleColorsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleColorsList: payload.items
    }),
    [getVehicleColorsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleColorsList: []
    }),

    [getVehicleWheelsRequest]: state => ({
      ...state,
      loading: true,
      vehicleWheelsList: [],
    }),
    [getVehicleWheelsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleWheelsList: payload.items
    }),
    [getVehicleWheelsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleWheelsList: []
    }),

    [getVehicleFrameSizeRequest]: state => ({
      ...state,
      loading: true,
      vehicleFrameSizeList: [],
    }),
    [getVehicleFrameSizeSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleFrameSizeList: payload.items
    }),
    [getVehicleFrameSizeFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleFrameSizeList: []
    }),

    [updateVehicleDataRequest]: state => ({
      ...state,
      loading: true,
      updateVehicleSuccess: false,
    }),
    [updateVehicleDataSuccess]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        updateVehicleError: {},
        updateVehicleSuccess: true,
        validateData: { ...state.validateData, ...payload.item }
      }),
    [updateVehicleDataFailure]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        updateVehicleError: payload.data
      }),
    [updateVehicleDataReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      updateVehicleSuccess: false
    }),

    //create add
    [createAddsRequest]: state => ({
      ...state,
      loading: true,
      createAddData: {},
      createAddError: []
    }),
    [createAddsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      createAddData: payload.ad
    }),
    [createAddsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      createAddError: payload.data
    }),
    [createAddsReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      createAddData: {},
      createAddError: []
    }),

    [getPublicAddsRequest]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        return {
          ...state,
          adLoader: true,
          loading: true,
          publicAddsList: [],
          publicAddData: {},
          publicAddsListError: {},
        }
      } else {
        return {
          ...state,
          loading: false,
          adLoader: true,
          publicAddData: {},
          publicAddsListError: {},
        }
      }
    },
    [getPublicAddsSuccess]: (state, { payload }) => {
      if (payload?.ads?.current_page === 1) {
        return {
          ...state,
          loading: false,
          adLoader: false,
          publicAddData: payload,
          publicAddsList: payload.ads.data,

        }
      } else {
        if (payload?.isAppend) {
          state.publicAddsList = payload?.ads?.data;
        } else {
          state.publicAddsList = state.publicAddsList.concat(payload?.ads?.data);
        }
        return {
          ...state,
          loading: false,
          adLoader: false,
          publicAddData: payload,
          publicAddsListPagination: payload?.ads?.data,
          // publicAddsList: payload.ads.data
          // publicAddsList: [...state.publicAddsList, ...payload.ads.data]
        }
      }
    },
    [getPublicAddsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      adLoader: false,
      publicAddsListError: payload,
    }),

    [getHomePublicAddsRequest]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        return {
          ...state,
          adLoader: true,
          loading: true,
          publicAddsListHome: [],
          publicAddDataHome: {},
          publicAddsListError: {}
        }
      } else {
        return {
          ...state,
          loading: false,
          adLoader: true,
          publicAddDataHome: {},
          publicAddsListError: {}
        }
      }
    },
    [getHomePublicAddsSuccess]: (state, { payload }) => {
      if (payload.ads.current_page === 1) {
        return {
          ...state,
          loading: false,
          adLoader: false,
          publicAddDataHome: payload,
          publicAddsListHome: payload.ads.data

        }
      } else {
        state.publicAddsListHome = state.publicAddsListHome.concat(payload.ads.data);
        return {
          ...state,
          loading: false,
          adLoader: false,
          publicAddsListHome: payload,
        }
      }
    },
    [getHomePublicAddsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      adLoader: false,
      publicAddsListError: payload
    }),

    [getPublicAddsShopRequest]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        return {
          ...state,
          adLoader: true,
          loading: true,
          publicAddsListShop: [],
          publicAddDataShop: {},
          publicAddsListShopError: {}
        }
      } else {
        return {
          ...state,
          loading: false,
          adLoader: true,
          publicAddDataShop: {},
          publicAddsListShopError: {}
        }
      }
    },
    [getPublicAddsShopSuccess]: (state, { payload }) => {
      if (payload.ads.current_page === 1) {
        return {
          ...state,
          loading: false,
          adLoader: false,
          publicAddDataShop: payload,
          publicAddsListShop: payload.ads.data

        }
      } else {
        state.publicAddsListShop = state.publicAddsListShop.concat(payload.ads.data);
        return {
          ...state,
          loading: false,
          adLoader: false,
          publicAddDataShop: payload,
          publicAddsListPagination: payload.ads.data,
          // publicAddsList: payload.ads.data
          // publicAddsList: [...state.publicAddsList, ...payload.ads.data]
        }
      }
    },
    [getPublicAddsShopFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      adLoader: false,
      publicAddsListShopError: payload
    }),

    [getPublicAuctionAddsRequest]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        return {
          ...state,
          adLoader: true,
          loading: true,
          //  publicAddsList :[],
          publicAddAuctionData: {},
          publicAddsListAuctionError: {}
        }
      } else {
        return {
          ...state,
          loading: false,
          adLoader: true,
          publicAddAuctionData: {},
          publicAddsListAuctionError: {}
        }
      }
    },
    [getPublicAuctionAddsSuccess]: (state, { payload }) => {
      if (payload.ads.current_page === 1) {
        return {
          ...state,
          loading: false,
          adLoader: false,
          publicAddAuctionData: payload,
          publicAddsAuctionList: payload.ads.data

        }
      } else {
        state.publicAddsAuctionList = state.publicAddsAuctionList.concat(payload.ads.data);
        return {
          ...state,
          loading: false,
          adLoader: false,
          publicAddAuctionData: payload,
          publicAddsListPagination: payload.ads.data,
          // publicAddsList: payload.ads.data
          // publicAddsList: [...state.publicAddsList, ...payload.ads.data]
        }
      }
    },
    [getPublicAuctionAddsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      adLoader: false,
      publicAddsListAuctionError: payload
    }),


    [getVehicleMaterialRequest]: state => ({
      ...state,
      loading: true,
      vehicleMaterialList: [],
    }),
    [getVehicleMaterialSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleMaterialList: payload.items
    }),
    [getVehicleMaterialFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleMaterialList: []
    }),

    [getVehicleBatteryPositionRequest]: state => ({
      ...state,
      loading: true,
      vehicleBatteryPositionList: [],
    }),
    [getVehicleBatteryPositionSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleBatteryPositionList: payload.items
    }),
    [getVehicleBatteryPositionFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleBatteryPositionList: []
    }),

    [getVehicleDriveTypesRequest]: state => ({
      ...state,
      loading: true,
      vehicleDriveTypesList: [],
    }),
    [getVehicleDriveTypesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleDriveTypesList: payload.items
    }),
    [getVehicleDriveTypesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleDriveTypesList: []
    }),

    [getVehicleSuspensionTypesRequest]: state => ({
      ...state,
      loading: true,
      vehicleSuspensionList: [],
    }),
    [getVehicleSuspensionTypesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleSuspensionList: payload.items
    }),
    [getVehicleSuspensionTypesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleSuspensionList: []
    }),

    [getVehicleBrakeTypesRequest]: state => ({
      ...state,
      loading: true,
      vehicleBrakeTypeList: [],
    }),
    [getVehicleBrakeTypesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleBrakeTypeList: payload.items
    }),
    [getVehicleBrakeTypesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleBrakeTypeList: []
    }),

    [getVehicleEnginePositionsRequest]: state => ({
      ...state,
      loading: true,
      vehicleEnginePositionsList: [],
    }),
    [getVehicleEnginePositionsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleEnginePositionsList: payload.items
    }),
    [getVehicleEnginePositionsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleEnginePositionsList: []
    }),

    [getVehicleEnginesRequest]: state => ({
      ...state,
      loading: true,
      vehicleEnginesList: [],
    }),
    [getVehicleEnginesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleEnginesList: payload.items
    }),
    [getVehicleEnginesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleEnginesList: []
    }),

    [getVehicleComponentsRequest]: state => ({
      ...state,
      loading: true,
      vehicleComponentsList: [],
    }),
    [getVehicleComponentsSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleComponentsList: payload.items
    }),
    [getVehicleComponentsFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleComponentsList: []
    }),

    [getVehicleSexRequest]: state => ({
      ...state,
      loading: true,
      vehicleSexList: [],
    }),
    [getVehicleSexSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleSexList: payload.items
    }),
    [getVehicleSexFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleSexList: []
    }),


    //get auction
    [getAuctionRequest]: state => ({
      ...state,
      loading: true,
      auctionListData: {}

    }),
    [getAuctionSuccess]: (state, { payload }) => {
      if (payload.current_page == 1) {
        return {
          ...state,
          loading: false,
          auctionListData: payload,
          auctionList: payload.data
        }
      } else {
        return {
          ...state,
          loading: false,
          auctionListData: payload,
          auctionListError: {},
          auctionList: [...state.auctionList, ...payload.data]
        }
      }
    },
    [getAuctionFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      auctionListData: {},
      auctionListError: payload.data
    }),
    [getAuctionReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      auctionListData: {},
      auctionList: [],
      auctionListError: {}
    }),


    //get hotdeals
    [getHotDealRequest]: state => ({
      ...state,
      loading: true,
      hotDealListData: {}

    }),
    [getHotDealSuccess]: (state, { payload }) => {
      if (payload.current_page == 1) {
        return {
          ...state,
          loading: false,
          hotDealListData: payload,
          hotDealList: payload.data
        }
      } else {
        return {
          ...state,
          loading: false,
          hotDealListData: payload,
          hotDealListError: {},
          hotDealList: [...state.hotDealList, ...payload.data]
        }
      }
    },
    [getHotDealFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      hotDealListData: {},
      hotDealListError: payload
    }),
    [getHotDealReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      hotDealListData: {},
      hotDealList: [],
      hotDealListError: {}
    }),

    [getAuctionHomeRequest]: state => ({
      ...state,
      loading: false,
      auctionListData: {}

    }),
    [getAuctionHomeSuccess]: (state, { payload }) => {
      if (payload.current_page == 1) {
        return {
          ...state,
          loading: false,
          auctionListDataHome: payload,
          auctionListHome: payload.data
        }
      } else {
        return {
          ...state,
          loading: false,
          auctionListDataHome: payload,
          auctionListError: {},
          auctionListHome: [...state.auctionListHome, ...payload.data]
        }
      }
    },
    [getAuctionHomeFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      auctionListData: {},
      auctionListError: payload
    }),


    /*auction place ads count*/
    [getAuctionAdsCountRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    [getAuctionAdsCountSuccess]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        adsCount: payload,
        adsCountError: {}
      }),

    [getAuctionAdsCountFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      adsCount: {},
      adsCountError: payload
    }),
    [updateAuctionAdsCount]: (state) => {
      const countCopy = { ...state.adsCount }
      countCopy['active'] = countCopy['active'] > 0 ? countCopy['active'] - 1 : 0
      return {
        ...state,
        adsCount: countCopy
      }
    },

    [getPublicAddsDetailRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      adLoader: true,
      publicAddDetail: {}
    }),
    [getPublicAddsDetailSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      adLoader: false,
      publicAddDetail: payload,
      publicAddDetailError: {}
    }),

    [getPublicAddsDetailFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      adLoader: false,
      publicAddDetail: {},
      publicAddDetailError: payload
    }),
    [getPublicAddsDetailReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      adLoader: false,
      publicAddDetail: {},
      publicAddDetailError: payload
    }),

    [createBidRequest]: state => ({
      ...state,
      loading: true,
      bidLoading: true,
      bidSuccess: false,
      bidNew: {},
      bidError: {}
    }),
    [createBidSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      bidLoading: false,
      bidNew: payload.data,
      bidSuccess: true,
    }),
    [createBidFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      bidLoading: false,
      bidError: payload.data
    }),
    [createBidReset]: (state, { payload }) => ({
      ...state,
      bidNew: {},
      bidSuccess: false,
      loading: false,
      bidError: {}
    }),

    [uploadVehicleImagesRequest]: state => ({
      ...state,
      loading: true,
      uploadImageError: {},
      uploadImageSuccess: false,
    }),
    [uploadVehicleImagesSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      validateData: { ...state.validateData, ...payload },
      uploadImageSuccess: true,
    }),
    [uploadVehicleImagesFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      uploadImageError: payload.data.data,
      uploadImageSuccess: false,
    }),

    [bidAcceptRejectRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      bidAcceptRejectStatus: {},
      bidAcceptRejectError: false
    }),
    [bidAcceptRejectSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      bidAcceptRejectStatus: payload
    }),

    [bidAcceptRejectFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      bidAcceptRejectError: true
    }),

    [bidAcceptRejectReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      bidAcceptRejectStatus: {},
      bidAcceptRejectError: false
    }),

    [vehicleSoldReserveRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      soldReserveStatus: {},
      vehicleSoldReservedStatus: false,
      vehicleSoldReservedError: false
    }),
    [vehicleSoldReserveSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      soldReserveStatus: payload,
      vehicleSoldReservedStatus: true
    }),
    [vehicleSoldReserveFailure]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        vehicleSoldReservedError: payload.data
      }),
    [getVehicleDetailRequest]: state => ({
      ...state,
      loading: true,
      validateData: {},
      validateDataError: {},
      detailSuccess: false
    }),
    [getVehicleDetailSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      validateDataError: {},
      validateData: payload.item,
      detailSuccess: true
    }),
    [getVehicleDetailFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      validateData: {},
      validateDataError: payload,
      detailSuccess: false
    }),

    [getVehicleDetailReset]: (state, { payload }) => ({
      ...state,
      validateData: {},
      detailSuccess: false
    }),

    [editAdRequest]: state => ({
      ...state,
      loading: true,
      editAddData: [],
      editAddError: {}
    }),
    [editAdSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      editAddData: payload.item,

    }),
    [editAdFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      editAddError: payload.data,
    }),
    [editAdReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      editAddData: [],
      detailSuccess: false,
      editAddError: {}
    }),

    [getAllCustomersRequest]: state => ({
      ...state,
      loading: true,
      customerData: [],
      allCustomersError: {},
      allCustomerLastPage: null,
      loadingCustomers: true
    }),

    [getAllCustomersSuccess]: (state, { payload }) => {
      if (payload.current_page == 1) {
        return {
          ...state,
          loading: false,
          allCustomers: payload.data,
          customerData: payload,
          allCustomerLastPage: payload.last_page,
          loadingCustomers: false
        }
      } else {
        return {
          ...state,
          loading: false,
          customerData: payload,
          allCustomers: [...state.allCustomers, ...payload.data],
          allCustomerLastPage: payload.last_page,
          loadingCustomers: false
        }
      }
    },
    [getAllCustomersFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      allCustomersError: payload.data,
      loadingCustomers: false
    }),

    //delete park vehicle
    [deleteVehicleRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      deleteVehicleStatus: {},
      deleteVehicleError: {},
    }),
    [deleteVehicleSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteVehicleStatus: payload,
      deleteVehicleError: {},
    }),

    [deleteVehicleFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteVehicleError: payload,
      deleteVehicleStatus: {},
    }),

    [deleteVehicleReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteVehicleStatus: {},
      deleteVehicleError: {},
    }),

    //delete classic and auction vehicle
    [deleteClassicAuctionAdRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      deleteClassicAuctionAdStatus: {},
      deleteClassicAuctionAdError: {},
    }),
    [deleteClassicAuctionAdSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteClassicAuctionAdStatus: payload,
      deleteClassicAuctionAdError: {},
    }),

    [deleteClassicAuctionAdFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteClassicAuctionAdError: payload,
      deleteClassicAuctionAdStatus: {},
    }),

    [deleteClassicAuctionAdReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      deleteClassicAuctionAdStatus: {},
      deleteClassicAuctionAdError: {},
    }),

    [getVehicleTargetAudienceRequest]: state => ({
      ...state,
      loading: true,
      vehicleTargetList: [],
    }),
    [getVehicleTargetAudienceSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleTargetList: payload.items
    }),
    [getVehicleTargetAudienceFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      vehicleTargetList: []
    }),

    [getAdDetailRequest]: state => ({
      ...state,
      adLoader: true,
      adData: {},
      adDataError: {}
    }),
    [getAdDetailSuccess]: (state, { payload }) => ({
      ...state,
      adLoader: false,
      adData: payload.item,
    }),
    [getAdDetailFailure]: (state, { payload }) => ({
      ...state,
      adLoader: false,
      adData: {},
      adDataError: payload.data
    }),
    [getAdDetailReset]: (state, { payload }) => ({
      ...state,
      adLoader: false,
      adData: {},
      adDataError: {}
    }),
    //cancel reservation
    [cancelReservationRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      cancelReservation: {},
      cancelReservationError: {},
    }),
    [cancelReservationSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      cancelReservation: payload,
      cancelReservationError: {},
    }),

    [cancelReservationFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      cancelReservation: {},
      cancelReservationError: payload.data,
    }),
    [cancelReservationReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      cancelReservation: {},
      cancelReservationError: {},
    }),

    //accept vehicle purchase
    [confirmVehiclePurchaseRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      confirmVehiclePurchase: {},
      confirmVehiclePurchaseError: {},
    }),
    [confirmVehiclePurchaseSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      confirmVehiclePurchase: payload,
      confirmVehicleSuccess: true,
      confirmVehiclePurchaseError: {},
    }),

    [confirmVehiclePurchaseFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      confirmVehiclePurchase: {},
      confirmVehicleSuccess: false,
      confirmVehiclePurchaseError: payload.data,
    }),
    [confirmVehiclePurchaseReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      confirmVehiclePurchase: {},
      confirmVehicleSuccess: false,
      confirmVehiclePurchaseError: {},
    }),

    //reject vehicle purchase
    [rejectVehiclePurchaseRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      rejectVehiclePurchase: {},
      rejectVehiclePurchaseError: {},
    }),
    [rejectVehiclePurchaseSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      rejectVehiclePurchase: payload,
      rejectVehicleSuccess: true,
      rejectVehiclePurchaseError: {},
    }),

    [rejectVehiclePurchaseFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      rejectVehiclePurchase: {},
      rejectVehicleSuccess: false,
      rejectVehiclePurchaseError: payload.data,
    }),
    [rejectVehiclePurchaseReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      rejectVehiclePurchase: {},
      rejectVehicleSuccess: false,
      rejectVehiclePurchaseError: {},
    }),

    [vehicleExpertiseSaveRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      expertiseSectionError: {},
      updateExpertiseSuccess: false,
      expertiseSectionSuccess: [],
      updateVehicleSuccess: false,
    }),
    [vehicleExpertiseSaveSuccess]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        expertiseSectionError: {},
        updateVehicleSuccess: false,
        updateExpertiseSuccess: true,
        expertiseSectionSuccess: payload.expertise,
        validateData: { ...state.validateData, expertise: payload },
      }),

    [vehicleExpertiseSaveFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      expertiseSectionError: payload,
      expertiseSectionSuccess: []
    }),

    [vehicleExpertiseUpdateRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      expertiseSectionError: {},
      expertiseSectionSuccess: [],
      updateExpertiseSuccess: false,
      updateVehicleSuccess: false,
    }),
    [vehicleExpertiseUpdateSuccess]: (state, { payload }) => (
      {
        ...state,
        loading: false,
        updateVehicleSuccess: false,
        updateExpertiseSuccess: true,
        expertiseSectionSuccess: payload.expertise,
        validateData: { ...state.validateData, expertise: payload },
      }),

    [vehicleExpertiseUpdateFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      expertiseSectionError: payload,
      expertiseSectionSuccess: payload
    }),

    [vehicleExpertiseFetchRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      expertiseSections: []
    }),
    [vehicleExpertiseFetchSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      expertiseSections: payload.expertiseSections
    }),

    [vehicleExpertiseFetchFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      expertiseSections: []
    }),
    [callRequest]: (state, { payload }) => (
      {
        ...state,
        loading: true,
        callSuccessData: {}
      }),
    [callSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      callSuccessData: payload
    }),
    [callFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      callSuccessData: {}
    }),
    [addAdInFavouriteRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      likeDislikeProcessing: true,
      addAddFavouriteStatus: {},
      addAddFavouriteError: {},
    }),

    [addAdInFavouriteSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      addAddFavouriteStatus: payload,
      deleteVehicleError: {},
      likeDislikeProcessing: false,
    }),

    [addAdInFavouriteFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      addAddFavouriteError: payload?.data ?? {},
      addAddFavouriteStatus: {},
      likeDislikeProcessing: false,
    }),

    [removeAdFromFavouriteRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      removeAddFavouriteStatus: {},
      removeAddFavouriteError: {},
      likeDislikeProcessing: true
    }),
    [removeAdFromFavouriteSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      removeAddFavouriteStatus: payload,
      removeAddFavouriteError: {},
      likeDislikeProcessing: false,
    }),

    [removeAdFromFavouriteFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      removeAddFavouriteError: payload?.data ?? {},
      removeAddFavouriteStatus: {},
      likeDislikeProcessing: false,
    }),
    [updateOnBidChange]: (state, { payload }) => ({
      ...state,
      loading: false,
      bidUpdate: payload,
    }),
    [resetBidChange]: (state, { payload }) => ({
      ...state,
      bidUpdate: {},
    }),
    [getCarFilterRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      filterLoad: true,
      loadingForce: true,
      carFilterList: {},
    }),
    [getCarFilterSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      loadingForce: false,
      filterLoad: false,
      carFilterList: payload
    }),
    [getCarFilterFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      filterLoad: false,
      loadingForce: true,
      carFilterList: {}
    }),
    [getMotorCycleFilterRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      filterLoad: true,
      loadingForce: true,
      motorCycleFilterList: {},
    }),
    [getMotorCycleFilterSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      loadingForce: false,
      filterLoad: false,
      motorCycleFilterList: payload
    }),
    [getMotorCycleFilterFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      filterLoad: false,
      loadingForce: true,
      motorCycleFilterList: {}
    }),
    [getBikeFilterRequest]: state => ({
      ...state,
      loading: true,
      filterLoad: true,
      loadingForce: true,
      bikeFilterList: {},
    }),
    [getBikeFilterSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      filterLoad: false,
      loadingForce: false,
      bikeFilterList: payload
    }),
    [getBikeFilterFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      filterLoad: false,
      loadingForce: false,
      bikeFilterList: {}
    }),
    [getStepFilterRequest]: state => ({
      ...state,
      loading: true,
      filterLoad: true,
      loadingForce: true,
      stepFilterList: {},
    }),
    [getStepFilterSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      filterLoad: false,
      loadingForce: false,
      stepFilterList: payload
    }),
    [getStepFilterFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      filterLoad: false,
      loadingForce: false,
      stepFilterList: {}
    }),
    [putAdsIntoAuctionRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      putIntoAuctionSuccess: false,
      putIntoAuctionFailure: {}
    }),
    [putAdsIntoAuctionSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      putIntoAuctionSuccess: true,
      putIntoAuctionFailure: {}
    }),
    [putAdsIntoAuctionFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      putIntoAuctionSuccess: false,
      putIntoAuctionFailure: payload
    }),
    [putAdsIntoAuctionReset]: (state) => ({
      ...state,
      loading: false,
      putIntoAuctionSuccess: false,
      putIntoAuctionFailure: {}
    }),
    [putAdsIntoHotDealRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      putIntoHotDealSuccess: false,
      putIntoHotDealFailure: {}
    }),
    [putAdsIntoHotDealSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      putIntoHotDealSuccess: true,
      putIntoHotDealFailure: {}
    }),
    [putAdsIntoHotDealFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      putIntoHotDealSuccess: false,
      putIntoHotDealFailure: payload
    }),
    [putAdsIntoHotDealReset]: (state) => ({
      ...state,
      loading: false,
      putIntoHotDealSuccess: false,
      putIntoHotDealFailure: {}
    }),
    [setLoading]: (state, { payload }) => ({
      ...state,
      loadingForce: true,
    }),
    [removeLoading]: (state, { payload }) => ({
      ...state,
      loadingForce: false,
    }),
  },
  initialState,
)

export default vehicle;