import { Button } from '@mui/material'
import { hotDealAdStatus, localstorageKey, showAuctionsTo } from 'config/constants'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'
import { Wrapper } from './style'
import moment from 'moment'
import { Toaster } from 'services/Toaster'
import { getUtcTime } from 'app/Hooks/useCoundown'
import { RootStateOrAny, useSelector } from 'react-redux'
import BlockedCounter from './blockedCounter'
import { Strings, getSubsidiaryStored } from 'config/variables'
import ToastMessageWithButton from '../ToastMessageWithButton'

const HotDealActionButton = ({ ad }) => {

    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const subsidiary = getSubsidiaryStored()

    const { loggedIn, myWalletBalance } = useSelector((state: RootStateOrAny) => state.auth);
    const { userData } = useSelector((state: RootStateOrAny) => state.user);


    const handleBuy = () => {
        if (userData?.profile_is_complete === false) {
            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.CHAT.COMPLETE_PROFILE_BUTTON)} message={Strings.completeProfileString} click={() => history.push(routes.userRoutes.userProfileEdit)} />, false)
            return
        }
        const isBeforeTime = getUtcTime().isBefore(moment(ad.start_hotdeal));
        if (isBeforeTime) {
            Toaster.error(t(translations.HOT_DEALS.INACTIVE_BOOKING_ERROR, { date: moment(ad.start_hotdeal).format('DD/MM/YYYY'), time: moment(ad.start_hotdeal).format('HH:mm') }))
            return
        }
        if (ad.display_for == showAuctionsTo.PRIVATE && subsidiary) {
            Toaster.error(t(translations.HOT_DEALS.PRIVATE_USER_VALIDATION))
            return
        }
        if (ad.display_for == showAuctionsTo.DEALER && !subsidiary) {
            Toaster.error(t(translations.HOT_DEALS.DEALER_ACCOUNT_VALIDATION))
            return
        }
        localStorage.setItem('myadsURL', location.pathname)
        localStorage.setItem(localstorageKey.buyCreditPage, history.location.pathname)
        localStorage.setItem(localstorageKey.productType, ad.product_type_id)
        history.push(`${routes.userRoutes.orderReservation}/${ad.slug}`)
    }


    const showButton = (status, blockedUntil) => {
        if (status === hotDealAdStatus.sold) {
            return <Button className="soldOut noCursor mt-10">
                {t(translations.HOT_DEALS.SOLD)}
            </Button>
        }

        if (!loggedIn && (status === hotDealAdStatus.available || !status)) {
            return <Button className="buyNow mt-10" onClick={() => {
                history.push(`${routes.authRoutes.login}?redirectTo=${encodeURIComponent(location.pathname + location.search)}`)
            }}>
                {t(translations.HOT_DEALS.BOOK_NOW)}
            </Button>
        }

        if (loggedIn && myWalletBalance < ad?.credit && (!status || status != hotDealAdStatus.eventEnded)) {
            return <Button className="purchaseCredits mt-10" onClick={() => {
                localStorage.setItem(localstorageKey.purchaseCreditByHotdeal, history.location.pathname)
                localStorage.setItem(localstorageKey.productType, ad?.product_type_id)
                history.push(routes.userRoutes.credits)
            }}
            >
                {t(translations.HOT_DEALS.BUY_CREDITS_TO_BOOK)}
            </Button>
        }

        if (blockedUntil !== null && getUtcTime().isBefore(moment(blockedUntil))) {
            return <BlockedCounter />
        }

        if (loggedIn && status === hotDealAdStatus.available) {
            return <Button className="buyNow mt-10" onClick={handleBuy}>
                {t(translations.HOT_DEALS.BOOK_NOW)}
            </Button>
        }

        if (status === null) {
            return <Button className="buyNow mt-10" onClick={handleBuy}>
                {t(translations.HOT_DEALS.AVAILABLE_SOON)}
            </Button>
        }

        return <Button className="disabled mt-10 soldOut">
            {t(translations.HOT_DEALS.EXPIRED_AD)}
        </Button>
    }

    return (
        <Wrapper>
            {showButton(ad.ad_status, ad.blocked_until)}
        </Wrapper>
    )
}

export default HotDealActionButton