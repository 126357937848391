import { createAction } from 'redux-actions';

//  Get Chat list Action
export const getChatRequest = createAction('CHAT_REQUEST');
export const getChatSuccess = createAction('CHAT_SUCCESS');
export const getChatFailure = createAction('CHAT_FAILURE');

//  Get Archived Chat list Action
export const getArchivedChatRequest = createAction('ARCHIVE_CHAT_REQUEST');
export const getArchivedChatSuccess = createAction('ARCHIVE_CHAT_SUCCESS');
export const getArchivedChatFailure = createAction('ARCHIVE_CHAT_FAILURE');

//  Get Blocked Chat list Action
export const getBlockedChatRequest = createAction('BLOCKED_CHAT_REQUEST');
export const getBlockedChatSuccess = createAction('BLOCKED_CHAT_SUCCESS');
export const getBlockedChatFailure = createAction('BLOCKED_CHAT_FAILURE');

//  Get User list Action
export const getUserListRequest = createAction('USER_LIST_REQUEST');
export const getUserListSuccess = createAction('USER_LIST_SUCCESS');
export const getUserListFailure = createAction('USER_LIST_FAILURE');
export const resetUserList = createAction('RESET_USER_LIST');

//  create chat / store  Action
export const chatStoreRequest = createAction('CHAT_STORE_REQUEST');
export const chatStoreSuccess = createAction('CHAT_STORE_SUCCESS');
export const chatStoreFailure = createAction('CHAT_STORE_FAILURE');

//  Show Particular Chat
export const particularChatRequest = createAction('PARTICULAR_CHAT_REQUEST');
export const particularChatSuccess = createAction('PARTICULAR_CHAT_SUCCESS');
export const particularChatFailure = createAction('PARTICULAR_CHAT_FAILURE');
export const lastMessagesState = createAction('LAST_MESSAGES_STATE')

//  Block Particular Chat
export const blockParticularChatRequest = createAction('BLOCK_PARTICULAR_CHAT_REQUEST');
export const blockParticularChatSuccess = createAction('BLOCK_PARTICULAR_CHAT_SUCCESS');
export const blockParticularChatFailure = createAction('BLOCK_PARTICULAR_CHAT_FAILURE');

//  Archive Particular Chat
export const archiveParticularChatRequest = createAction('ARCHIVE_PARTICULAR_CHAT_REQUEST');
export const archiveParticularChatSuccess = createAction('ARCHIVE_PARTICULAR_CHAT_SUCCESS');
export const archiveParticularChatFailure = createAction('ARCHIVE_PARTICULAR_CHAT_FAILURE');

//  Delete Particular Chat
export const deleteParticularChatRequest = createAction('DELETE_PARTICULAR_CHAT_REQUEST');
export const deleteParticularChatSuccess = createAction('DELETE_PARTICULAR_CHAT_SUCCESS');
export const deleteParticularChatFailure = createAction('DELETE_PARTICULAR_CHAT_FAILURE');

//  Unread Particular Chat
export const unreadParticularChatRequest = createAction('UNREAD_PARTICULAR_CHAT_REQUEST');
export const unreadParticularChatSuccess = createAction('UNREAD_PARTICULAR_CHAT_SUCCESS');
export const unreadParticularChatFailure = createAction('UNREAD_PARTICULAR_CHAT_FAILURE');

//  Mute Particular Chat
export const muteParticularChatRequest = createAction('MUTE_PARTICULAR_CHAT_REQUEST');
export const muteParticularChatSuccess = createAction('MUTE_PARTICULAR_CHAT_SUCCESS');
export const muteParticularChatFailure = createAction('MUTE_PARTICULAR_CHAT_FAILURE');

//  Unread Particular Chat
export const unreadAllChatRequest = createAction('UNREAD_ALL_CHAT_REQUEST');
export const unreadAllChatSuccess = createAction('UNREAD_ALL_CHAT_SUCCESS');
export const unreadAllChatFailure = createAction('UNREAD_ALL_CHAT_FAILURE');

//  Mute Particular Chat
export const muteAllChatRequest = createAction('MUTE_ALL_CHAT_REQUEST');
export const muteAllChatSuccess = createAction('MUTE_ALL_CHAT_SUCCESS');
export const muteAllChatFailure = createAction('MUTE_ALL_CHAT_FAILURE');

//  Send Message
export const sendMessageRequest = createAction('SEND_MESSAGE_REQUEST');
export const sendMessageSuccess = createAction('SEND_MESSAGE_SUCCESS');
export const sendMessageFailure = createAction('SEND_MESSAGE_FAILURE');

// Make user as Receptionist Action
export const makeReceptionistRequest = createAction('MAKE_RECEPTIONIST_REQUEST');
export const makeReceptionistSuccess = createAction('MAKE_RECEPTIONIST_SUCCESS');
export const makeReceptionistFailure = createAction('MAKE_RECEPTIONIST_FAILURE');

// Reset chat states on switch accounts
export const resetChatRequest = createAction('CHAT_RESET_REQUEST');
export const resetChatSuccess = createAction('CHAT_RESET_SUCCESS');
export const resetChatFailure = createAction('CHAT_RESET_FAILURE');

//group by chat messages action
export const messageGroupByDateRequest = createAction("MESSAGE_GROUP_BY_DATE_REQUEST")
export const messageGroupByDateSuccess = createAction("MESSAGE_GROUP_BY_DATE_SUCCESS")

//Get new message notification
export const getNewMessageNotification = createAction("NEW_MESSGAE_NOTIFICATION")

//Open chat popUP
export const setChatPopUpState = createAction("CHAT_POPUP_STATE")


//Action for getting suggested  member list for group
export const getsubsidiaryActiveMemberListRequest = createAction('SUGGESTED_MEMBER_REQUEST');
export const getsubsidiaryActiveMemberListSuccess = createAction('SUGGESTED_MEMBER_SUCCESS');
export const getsubsidiaryActiveMemberListFailure = createAction('SUGGESTED_MEMBER_FAILURE');

// Add member in group
export const addMemberRequest = createAction('ADD_MEMBER_REQUEST');
export const addMemberSuccess = createAction('ADD_MEMBER_SUCCESS');
export const addMemberFailure = createAction('ADD_MEMBER_FAILURE');

// remove member and update title in group
export const updateGroupDetailRequest = createAction('UPDATE_GROUP_DETAILS_REQUEST');
export const updateGroupDetailSuccess = createAction('UPDATE_GROUP_DETAILS_SUCCESS');
export const updateGroupDetailFailure = createAction('UPDATE_GROUP_DETAILS_FAILURE');




// Blocked by users/subsidiaries
export const blockedByRequest = createAction('BLOCKED_BY_REQUEST');
export const blockedBySuccess = createAction('BLOCKED_BY_SUCCESS');
export const blockedByFailure = createAction('BLOCKED_BY_FAILURE');
export const blockChatLoadedState = createAction('BLOCKED_CHAT_LOADED_STATE_UPDATE')
