import { Backdrop, Box, CircularProgress, LinearProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { color, colorNew } from 'styles/StyleConstants';
import { LoadingIndicator } from '../LoadingIndicator';

export const ContentLoader = () => {
  return (
      <Box sx={{
          position:'fixed',
          top:0, 
          width:1,
          bottom:0,
          left:0, 
          right:0,
          height:'5px',
          zIndex: (theme) => theme.zIndex.drawer + 1 
      }}>
          <LinearProgress />
      </Box>
   );
};

export const ContentLoaderOver = () => {
  return (
    <ContentLoaderWrapper className="over">
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </ContentLoaderWrapper>
  );
};

export const CircularLoader = ({style}) => {
  const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
    
  return (
    <>
           <LoadingWrapper style={{...style}}>
             <LoadingIndicator />
           </LoadingWrapper>
      </>
  );
};



const ContentLoaderWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  &.over{
    background: rgba(0, 0, 0, 0.3);
  }
  .loading {
    display: flex;
    justify-content: center;
  }
  .loading div {
    width: 1rem;
    height: 1rem;
    margin: 2rem 0.3rem;
    background: ${color.primaryColor};
    border-radius: 50%;
    animation: 0.9s bounce infinite alternate;
  }
  .loading div:nth-child(2) {
    animation-delay: 0.3s;
  }
  .loading div:nth-child(3) {
    animation-delay: 0.6s;
  }
  @keyframes bounce {
    to {
      opacity: 0.3;
      transform: translate3d(0, -1rem, 0);
    }
  }
`;
