export const companyRoutes = {
  company: '/company',
  createCompany: '/create-company',
  companyProfile: '/company-profile',
  roleList: '/role-list',
  role: '/role',
  planDetails: '/create-company/:step',
  myBills: '/my-bills',
  companySettings: '/company-settings',
  createRole: '/create-role',
  editRole: '/edit-role',
  roles: '/roles',
  invoices: '/invoices',
  EditWorkingHours: '/edit-workinghours',
  companyPrivacy: '/company-privacy',
  paymentMethod: '/payment-method',
  subscriptions: '/subscriptions',
  invitationVerificationUser: '/verify_invitation/:role_user_sub/:hash',
  confirmPaymentPage: '/confirm',
  handleInvitationUser: '/verify_invitation/:role_user_sub/:hash',
  changePlan: '/change-plan',
  notification: '/notification',
  reSubscribe: '/re-subscribe',
  stripeConnect: '/stripe-connect',
  vehicleSalesInvoices: "/vehicle_sales_invoices"
}
