import { createAction } from 'redux-actions';

export const sidebarOpenRequest = createAction('SIDEBAROPEN');
export const sidebarOpenReset = createAction('SIDEBARCLOSE');

export const notificationRequest = createAction('NOTIFICATION_REQUEST');
export const notificationReset = createAction('NOTIFICATION_RESET');

export const getWebSideMenuOpenRequest = createAction('GET_WEB_SIDE_MENU');
export const getWebSideMenuOpenReset = createAction('GET_WEB_SIDE_MENU_RESET');

export const getOpenNavbar = createAction('OPEN_NAVBAR_MENU');
export const getCloseNavbar = createAction('CLOSE_NAVBAR_MENU');

export const getOpenFilters = createAction('OPEN_FILTER');
export const getCloseFilters = createAction('CLOSE_FILTER');

export const langugageSwitchOpen = createAction('LANGUAGE_SWITCH_OPEN');
export const langugageSwitchReset = createAction('LANGUAGE_SWITCH_RESET');

export const switchUserOpen = createAction('SWITCH_USER_OPEN');
export const switchUserClose = createAction('SWITCH_USER_RESET');

export const findDealerOpen = createAction('FIND_DEALER_OPEN');
export const findDealerClose = createAction('FIND_DEALER_RESET');