import React from 'react'
import { Box, Modal } from '@mui/material';
import { colorNew } from 'styles/StyleConstants';
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom';
import { lan } from 'locales/multiPageTranslation/adsLan'
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

const  TrophyInfoModal = (props:any) => {
 const {openModal,handleClose} = props
 const { t } = useTranslation();
  return (
    <>
          <Modal
             open={openModal}
             onClose={handleClose}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
             >
            <Box 
             sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 375,
                width:'calc(100% - 20px)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 5,
                overflow:'hidden',
                color:`${colorNew.colorGrayOnButton}`,
                fontFamily:"Inter", 
                letterSpacing:"0.02em",                
                '.Header-box':{   
                    padding: '13px',
                    textAlign:"center",
                    fontSize:16,
                    fontWeight:400, 
                    'span':{
                        fontWeight:500,
                        fontSize:18,
                    }                  
                },
                '.body-box':{ 
                    p:2,                    
                    fontWeight:400,
                    fontSize:16, 
                    '.paragraph':{
                        p:2
                      }
                },
                
            }}>
                <div className='Header-box'>
                    <span>{t(...lan.bidInformation())}</span>                  
                </div>  
                <Box className='body-box'>
                <div className="paragraph">{t(...lan.trophyModalInfoContentHeader())}</div>
                <div className="paragraph">{t(...lan.trophyModalInfoContent())}</div>
                <ButtonCustom clickFunction={handleClose} textBtn={(t(translations.webTexts.DONE))} btnColor={'primary'} style={{maxWidth:"343px",width:"100%" , height:"56px" , fontSize:"21px", fontWeight:500}}/>
                </Box>
            </Box>
          </Modal>
          </>)
}

export default TrophyInfoModal;