import { lazyLoad } from 'utils/loadable';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';

export const HotDealsOrderReservation = lazyLoad(
  () => import('./index'),
  module => module.HotDealsOrderReservation,
  {
    fallback: (
      <ContentLoader />
    ),
  },
);