import { Box, Button, Fade, Tooltip } from "@mui/material"
import PublicLayout from "app/Component2/Layouts/PublicLayout"
import ImageCarousel from "app/components/imageCarousel"
import { Link, useHistory, useParams } from "react-router-dom"
import { BikeStepIcon, CarIcon } from "app/Component2/Layouts/AdsLayouts/component/AdsListing/VehicleTypeIcon"
import { translations } from "locales/translations"
import { useTranslation } from "react-i18next"
import VehicleView from "app/Component2/Layouts/AdsLayouts/component/Details/InnerDetails/VehicleView"
import { HotDealsDetailStyle } from "./style/HotDealsDetailStyle"
import TabberSwiper from "../AdsDetailsPage/Component/TabberSwiper"
import React, { Suspense, useEffect, useRef, useState } from "react"
import { chatCases, createUserSubchat } from "config/chatConfig"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { useDeviceType } from "app/Hooks/useMediaQuery"
import { adsTypes, CountriesDrop1, getProductTypeName, imageMeta, numberFormat, productTypes } from "config/variables"
import { SvgInfoIcon, SvgMobileLeftArrow } from "app/components/svgicons/svgNew"
import UserDetails from "app/components/UserDetails"
import Header from "./components/header"
import { colorNew } from "styles/StyleConstants"
import TabParent from "app/Component2/Layouts/AdsLayouts/component/AdsListing/TabParent"
import DetailsView from "app/Component2/Layouts/AdsLayouts/component/Details/Details"
import { ContentLoader } from "app/components/ContantLoader/ContentLoader"
import { imagesGroup, setDetailExpertise } from 'app/pages/VehicleSummaryPage/Feature/expertiesObj';
import { urlParamInterface } from "config/interface"
import { addAdInFavouriteSuccess, getAdDetailFailure, getAdDetailRequest, getAdDetailReset, removeAdFromFavouriteSuccess } from "store/actions/vehicle-actions"
import Counter from "./components/Counter"
import AuctionEndAnimation from "app/Component2/GlobalComponent/auctionEndingAnimation"
import AdNotFound from "app/Component2/GlobalComponent/AdNotFound"
import { routes } from "routes/routes"
import { getURL, isAdFavourite } from "config/appConfig"
import HotDealActionButton from "app/components/HotDealActionButton"
import { adsTypesNum, hotDealAdStatus } from "config/constants"
import { AdInterface } from "./interface/interface"
import ZoomImage from "app/components/GlobalComponent/ZoomImage"
import { PusherClient } from "websocket"
import { Toaster } from "services/Toaster"
import HotDealStatus from "app/components/HotdealStatus"
import BreadCrumb from "./components/BreadCrumb"
import InfoModal from "app/components/InfoModal/info"
import moment from "moment"

const EquipmentsComponent = React.lazy(() => import('../AdsDetailsPage/Component/Equipments/EquipmentsComponent'))
const ComponentTabView = React.lazy(() => import('app/Component2/Layouts/AdsLayouts/component/Details/InnerDetails/vehicleViewComponent/ComponentTabView'))
const ContactCommercial = React.lazy(() => import('app/Component2/Layouts/AdsLayouts/component/Contact/ContactCommercial'))
const ElectricDetailsBikeSteps = React.lazy(() => import('app/Component2/Layouts/AdsLayouts/component/Details/InnerDetails/ElectricDetailsBikeSteps'))
const ExpertiesDetailTab = React.lazy(() => import('../AdsDetailsPage/Experties/ExpertiesDetail'))

export const HotDealDetailsPage = () => {

    // hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory()
    const { slug }: urlParamInterface = useParams();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()
    const isMounted = useRef(false)

    const { loggedIn, myWalletBalance } = useSelector((state: RootStateOrAny) => state.auth);
    const { adData, adLoader, addAddFavouriteStatus, removeAddFavouriteStatus, likeDislikeProcessing, adDataError } = useSelector((state: RootStateOrAny) => state.vehicle)
    const { loadingChat } = useSelector((state: RootStateOrAny) => state.chat)

    //Local states
    const [adDetail, _adDetail] = useState<AdInterface>({})
    const [images, _images] = useState<Array<Object>>([])
    const [open, setOpen] = useState<number>(0);


    const [valueTab, setValueTab] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [dealer, _dealer] = useState<string>('')
    const [expertiseTab, setExpertiseTab] = React.useState(0);
    const [selectedElement, _selectedElement] = useState<Object>({})
    const [showVehicleDamageDescription, setShowVehicleDamageDescription] = useState<boolean>(false)
    const [countryName, _countryName] = useState<String>('')

    const [isContestEnding, _isContestEnding] = useState<boolean>(false)
    const [contestEndingMessage, _contestEndingMessage] = useState<string>('')

    const [isFavourite, _isFavourite] = useState<number>()

    // Here we setting initial states for our page like dealer id(if ad posted by dealer) and is ad favorite
    // Also getting country deatils from where the Vehcile is imported 
    useEffect(() => {
        if (adData && Object.keys(adData).length > 0) {
            isMounted.current = true
            _dealer(adData.owner_vehicle?.current_sub_id)
            _isFavourite(adData.is_favorite)
            if (adData?.owner_vehicle?.imported_country_id !== null) {
                getCountry(adData?.owner_vehicle?.imported_country_id)
            }
            _adDetail(adData)
            const slides = adData?.owner_vehicle?.media?.map((image) => {
                return { src: image.original_url }
            })
            _images(slides)
        }
    }, [adData.id])


    useEffect(() => {
        return () => {
            dispatch(getAdDetailReset())
        }
    }, [])

    useEffect(() => {
        if (adDataError && Object.keys(adDataError).length > 0) {
            isMounted.current = true
            Toaster.error(adDataError.message)
            dispatch(getAdDetailFailure({}))
        }
    }, [adDataError])

    const shareUrl = Object.keys(adDetail).length > 0 ? getURL(routes.publicRoutes.hotDeal + "/" + adDetail.slug) : '';

    const title = Object.keys(adDetail).length > 0 ?
        `${adDetail.owner_vehicle.full_title}\n` +
        `${t(translations.HOT_DEALS.FIRST_USE) + ':'} ${adDetail.owner_vehicle.new_vehicle ? t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_NEW) : adDetail.owner_vehicle.vehicle_first_register_month && adDetail.owner_vehicle.vehicle_first_register_year ? " " + adDetail.owner_vehicle.vehicle_first_register_month + '/' + adDetail.owner_vehicle.vehicle_first_register_year : ""} \n` +
        `${t(translations.HOT_DEALS.OLD_PRICE) + ": € " + numberFormat(adDetail.total_price)} \n` +
        `${t(translations.HOT_DEALS.PROMOTIONAL_PRICE)}: € ${numberFormat(adDetail.total_discounted_price)} \n` +
        `${t(translations.HOT_DEALS.KM)}: ${numberFormat(adDetail.owner_vehicle.vehicle_mileage)} km \n` +
        `${t(translations.HOT_DEALS.COUNTRY)}: ${adDetail?.hotdeal?.hotdeal_event?.country?.fullname} \n\n` +
        `${t(translations.HOT_DEALS.AD_AVAILABILITY, { start_date: moment(adDetail.start_hotdeal).local().format('DD/MM/YYYY'), time: moment(adDetail.start_hotdeal).local().format('HH:mm'), end_date: moment(adDetail.end_hotdeal).local().format('DD/MM/YYYY') })}\n\n` : '';

    const imageAltText = Object.keys(adDetail).length > 0 ? adDetail.owner_vehicle.full_title : ''

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (adDetail && Object.keys(adDetail).length > 0 && addAddFavouriteStatus && Object.keys(addAddFavouriteStatus).length > 0) {
            if (adDetail.id == addAddFavouriteStatus.item.model_id) {
                _isFavourite(isAdFavourite.true)
                dispatch(addAdInFavouriteSuccess({}))
            }
        }
    }, [addAddFavouriteStatus, adDetail?.id])

    useEffect(() => {
        if (adDetail && Object.keys(adDetail).length > 0 && removeAddFavouriteStatus && Object.keys(removeAddFavouriteStatus).length > 0) {
            if (adDetail.id == removeAddFavouriteStatus.id) {
                _isFavourite(isAdFavourite.false)
                dispatch(removeAdFromFavouriteSuccess({}))
            }
        }
    }, [removeAddFavouriteStatus, adDetail?.id])

    useEffect(() => {
        if (slug) {
            dispatch(getAdDetailRequest({ id: slug }))
        }
    }, [slug])

    useEffect(() => {
        if (adDetail && Object.keys(adDetail).length > 0) {
            setDetailExpertise(adDetail.owner_vehicle.expertise)
        }
    }, [adDetail.id])


    const isCar = adDetail?.owner_vehicle?.vehicle?.product_category_type?.product_type_id === productTypes.car ?? false;
    const isMotorCycle = adDetail?.owner_vehicle?.vehicle?.product_category_type?.product_type_id === productTypes.motorcycles ?? false;

    const handleExpertiseChange = (event: React.SyntheticEvent, newValue: number) => {
        setExpertiseTab(newValue);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const handleChat = () => {
        const title = adDetail?.owner_vehicle?.full_title ?? ""
        if (adDetail.ad_status === hotDealAdStatus.sold) {
            Toaster.error(t(translations.HOT_DEALS.SOLD_VEHICLE_CHAT_VALIDATION, { title }))
            return
        }
        if (loggedIn) {
            createUserSubchat(adDetail.id, adDetail.owner_vehicle_id, chatCases.ADS_DETAIL_PAGE_CHAT, dispatch, adsTypesNum.hotDealAds)
        }
    }

    const displayDamageDescriptionFunction = (key, colorCode, classes) => {
        setShowVehicleDamageDescription(true)
        _selectedElement({
            id: imagesGroup[key].id,
            key: key,
            name: imagesGroup[key].name,
            colorCode: colorCode,
            fillStoke: classes,
            classes: imagesGroup[key].classes,
            Icon: imagesGroup[key].url,
            description: imagesGroup[key].description,
            media: imagesGroup[key].media
        })
    }


    const getCountry = (id) => {
        CountriesDrop1().then(data => {
            data.map((country, i) => {
                if (country.id == id) {
                    _countryName(country.fullname)
                }
            });
        })
    }

    const handleGoBack = () => {
        if (history.location.key) {
            history.goBack()
            return
        }
        history.push('/')
    }

    useEffect(() => {
        if (adDetail?.id) {
            const connectPusher = () => {

                const pusherClientInstance = PusherClient();
                const channel = pusherClientInstance?.subscribe('ctb_public');

                channel?.bind("vehicle_unblocked", (message) => {
                    if (message.payload.id == adDetail?.id) {
                        updateTimer(null)
                    }

                })
                channel?.bind("vehicle_blocked", (message) => {
                    if (message.payload.id == adDetail?.id) {
                        updateTimer(message.payload.blocked_until)
                    }
                })
                channel?.bind("vehicle_sold", (message) => {
                    if (message.payload.id == adDetail?.id) {
                        updateStatus(hotDealAdStatus.sold)
                    }
                })
            }

            connectPusher()
        }
    }, [adDetail?.id])


    const updateTimer = (time) => {
        _adDetail((prevValues) => {
            return {
                ...prevValues,
                blocked_until: time
            }
        })
    }
    const updateStatus = (status) => {
        if (adDetail.ad_status === hotDealAdStatus.sold) {
            return
        }
        _adDetail((prevValues) => {
            return {
                ...prevValues,
                ad_status: status
            }
        })
    }

    // click on ad image to open zoomed view using index
    const handleImageClick = (e, index) => {
        e.preventDefault();
        if (images?.length > 0) {
            setOpen(index + 1)
        }
    }

    const getSimilarAdsUrl = (detail) => {
        let str = routes.vehicleRoutes.publicAds + '/' + getProductTypeName[detail?.owner_vehicle?.vehicle?.product_category_type?.product_type_id]
        if (detail?.owner_vehicle) {
            if (detail.owner_vehicle?.model?.brand_car) {
                str += `/${detail.owner_vehicle.model.brand_car?.slug}/${detail.owner_vehicle.model?.slug}`
            }
            if (detail.owner_vehicle?.vehicle_first_register_year) {
                str += `?registration_start=${detail?.owner_vehicle?.vehicle_first_register_year}`
            }
            else if (detail.owner_vehicle?.new_vehicle) {
                str += `?new_vehicle=1`
            }
        }
        return str
    }

    return (
        <PublicLayout
            hideFooterNavBar={true}
            hideNaveBar={true}
            meta={`${adDetail?.owner_vehicle?.full_title ?? ""} - Cartobike`}
            description={''}
            image={imageMeta(adDetail)}
            type="website"
            showNavbar={false}
            hideHeader={isSmallDevice || isMediumDevice}
            backButton={<span className="back-button" onClick={handleGoBack}><SvgMobileLeftArrow /></span>}
            hideFooter={true}
        >
            {(adLoader || loadingChat || likeDislikeProcessing) && <ContentLoader />}


            <HotDealsDetailStyle>

                {
                    (!adLoader && Object.keys(adDetail).length > 0) ?
                        <>
                            {(isSmallDevice || isMediumDevice) && <Header handleGoBack={handleGoBack} />}

                            {(isLargeDevice || isExtraLargeDevice) && <BreadCrumb />}

                            {/* vehicle display */}
                            <Box className='dealDetails'>
                                <Box className='adContainer'>
                                    <Box className='imageContainer'>
                                        <ImageCarousel
                                            images={adDetail?.owner_vehicle?.media ?? []}
                                            imageAltText={imageAltText}
                                            kmCount={adDetail?.owner_vehicle?.battery_range_max ?? 0}
                                            isFavourite={isFavourite}
                                            adId={adDetail.id}
                                            title={title}
                                            linkToShare={shareUrl}
                                            showLikeButton={(!adDetail.ad_status || adDetail.ad_status === hotDealAdStatus.available)}
                                            showShareButton={true}
                                            showCount={isLargeDevice || isExtraLargeDevice}
                                            handleImageClick={handleImageClick}
                                            zoom={true}
                                            myAd={true}
                                            adType={adsTypes.hotDeals}
                                        />
                                    </Box>
                                    <Box className='adDetailsContainer'>

                                        <Tooltip title={adDetail?.owner_vehicle?.full_title ?? "-"} placement='top-start'>
                                            <h1 className='ad-name pointer '> {adDetail?.owner_vehicle?.full_title ?? "-"}</h1>
                                        </Tooltip>

                                        <Box sx={{ mb: '10px' }}>
                                            {isCar ?
                                                <CarIcon
                                                    regiterDate={
                                                        adDetail?.owner_vehicle?.new_vehicle ? t(translations.summaryVehicleScreen.NEW)
                                                            : adDetail?.owner_vehicle?.new_vehicle === false && adDetail?.owner_vehicle?.vehicle_first_register_month != null ? adDetail?.owner_vehicle?.vehicle_first_register_month + "/" + adDetail?.owner_vehicle?.vehicle_first_register_year : ""
                                                    }
                                                    milege={
                                                        adDetail?.owner_vehicle?.vehicle_mileage !== null ? `${adDetail?.owner_vehicle?.vehicle_mileage}` : null
                                                    }
                                                    gearBox={
                                                        adDetail?.owner_vehicle?.gearbox?.name
                                                    }
                                                    power={
                                                        adDetail?.owner_vehicle?.power_cv ? adDetail?.owner_vehicle?.power_cv + ' CV' : null
                                                    }
                                                    fuel={
                                                        adDetail?.owner_vehicle?.fuel?.name
                                                    }
                                                />
                                                :
                                                <BikeStepIcon
                                                    regiterDate={
                                                        adDetail?.owner_vehicle?.new_vehicle ? t(translations.summaryVehicleScreen.NEW)
                                                            : adDetail?.owner_vehicle?.new_vehicle === false && adDetail?.owner_vehicle?.vehicle_first_register_month != null ? adDetail?.owner_vehicle?.vehicle_first_register_month + "/" + adDetail?.owner_vehicle?.vehicle_first_register_year : ""
                                                    }
                                                    gearBox={adDetail?.owner_vehicle?.gearbox?.name ? adDetail?.owner_vehicle?.gearbox?.name : ""}
                                                    topSpeed={adDetail?.owner_vehicle?.top_speed ? adDetail?.owner_vehicle?.top_speed + ' km/h' : ''}
                                                    isNew={adDetail?.owner_vehicle?.new_vehicle ? t(translations.summaryVehicleScreen.NEW) : adDetail?.owner_vehicle?.vehicle_first_register_month || adDetail?.owner_vehicle?.vehicle_first_register_year ? t(translations.summaryVehicleScreen.USED) : null}
                                                    fuel={adDetail?.owner_vehicle?.is_electric ? t(translations.summaryVehicleScreen.ELECTRIC) : ''}
                                                    betterType={adDetail?.owner_vehicle?.battery_charging_time ? adDetail?.owner_vehicle?.battery_charging_time : ""}
                                                    power={adDetail?.owner_vehicle?.power_cv ? adDetail?.owner_vehicle?.power_cv + ' CV' : null}
                                                    frameSize={adDetail?.owner_vehicle?.frame_size ? adDetail?.owner_vehicle?.frame_size.name : ""}
                                                    bodyVehicle={adDetail?.owner_vehicle?.body_vehicle ? adDetail?.owner_vehicle?.body_vehicle?.name : ""}
                                                    milege={adDetail?.owner_vehicle?.vehicle_mileage || null}
                                                    isMotorCycle={isMotorCycle}
                                                    motoBikefuel={adDetail?.owner_vehicle?.fuel?.name || ""}

                                                />
                                            }
                                        </Box>

                                        <Box className='priceContainer'>
                                            <span className='price'>{('€' + numberFormat(Math.trunc(adDetail.cartobike_total_price ?? adDetail.total_discounted_price)) ?? 0)}</span>
                                            {adDetail?.owner_vehicle?.vehicle_deductible_vat ? <span className='vatText'>{t(translations.ads.VAT_INCLUDED)}
                                                <InfoModal header={t(translations.HOT_DEALS.HOTDEAL_VAT_HEAD)} content={t(translations.HOT_DEALS.HOTDEAL_VAT_DESC)} />
                                            </span> : null}
                                            <span className='strikedprice'>€ {numberFormat(Math.trunc(adDetail.total_price)) ?? 0}</span>
                                        </Box>

                                        <UserDetails isDisableBtn={adDetail.ad_status == hotDealAdStatus.sold || adDetail.ad_status == hotDealAdStatus.eventEnded} userDetail={adDetail.user_data} adId={adDetail.id} handleChat={handleChat} showOnlyMessage={true} />

                                        {isSmallDevice && <Box className='leftSection'>
                                            {
                                                !(adDetail.ad_status == hotDealAdStatus.sold || adDetail.ad_status == hotDealAdStatus.eventEnded) ?
                                                    <>
                                                        <HotDealStatus adDetail={adDetail} />
                                                        <Counter adDetail={adDetail} endAd={() => updateStatus(hotDealAdStatus.eventEnded)} makeAdAvailable={() => updateStatus(hotDealAdStatus.available)} _isContestEnding={_isContestEnding} _contestEndingMessage={_contestEndingMessage} />
                                                    </> :
                                                    <Link to={getSimilarAdsUrl(adDetail)} className='similarAdsLink'>
                                                        {t(translations.webTexts.VIEW_SIMILAR_ADS)}
                                                    </Link>
                                            }

                                        </Box>}

                                        {!isSmallDevice &&
                                            <>
                                                <Box className='actionContainer'>
                                                    <Box className='leftSection'>
                                                        {
                                                            !(adDetail.ad_status == hotDealAdStatus.sold || adDetail.ad_status == hotDealAdStatus.eventEnded) ?
                                                                <>
                                                                    <HotDealStatus adDetail={adDetail} />
                                                                    <Counter adDetail={adDetail} endAd={() => updateStatus(hotDealAdStatus.eventEnded)} makeAdAvailable={() => updateStatus(hotDealAdStatus.available)} _isContestEnding={_isContestEnding} _contestEndingMessage={_contestEndingMessage} />
                                                                </> :
                                                                <Link to={getSimilarAdsUrl(adDetail)} className='similarAdsLink'>
                                                                    {t(translations.webTexts.VIEW_SIMILAR_ADS)}
                                                                </Link>
                                                        }
                                                    </Box>
                                                    <Box className='rightSection'>
                                                        {
                                                            !(adDetail.ad_status == hotDealAdStatus.sold || adDetail.ad_status == hotDealAdStatus.eventEnded) &&
                                                            <Box className='rightSubSection'>
                                                                <p>{t(translations.HOT_DEALS.PARTICIPATE_FOR)} {adDetail.ad_status !== hotDealAdStatus.sold ? <span> {t(translations.HOT_DEALS.CREDITS, { credits: adDetail?.hotdeal?.hotdeal_event?.credit ?? 0, creditText: adDetail?.hotdeal?.hotdeal_event?.credit > 1 ? t(translations.invoiceScreen.CREDITS) : t(translations.HOT_DEALS.CREDIT) })}</span> : '-'}</p>
                                                                {loggedIn &&
                                                                    <p>{myWalletBalance < adDetail.credit ? t(translations.HOT_DEALS.INSUFFICIENT_BALANCE) : t(translations.HOT_DEALS.AVAILABLE_CREDIT)}
                                                                        <span className={myWalletBalance < adDetail.credit ? 'redText' : ''}> {myWalletBalance} </span>
                                                                    </p>
                                                                }
                                                            </Box>
                                                        }
                                                        <HotDealActionButton ad={adDetail} />
                                                    </Box>

                                                </Box>

                                            </>
                                        }

                                    </Box>

                                    {/* Animation appears when an hotdeal is ending */}
                                    {
                                        isContestEnding && adDetail.ad_status === hotDealAdStatus.available && <AuctionEndAnimation endingMessage={contestEndingMessage} />
                                    }

                                </Box>
                            </Box>

                            {/* tabs  */}
                            <Box className="tabs-wrapper" >
                                <TabberSwiper
                                    setIsHovered={setIsHovered}
                                    type={adDetail?.owner_vehicle?.vehicle?.product_category_type?.product_type_id}
                                    handleChange={handleChange}
                                    valueTab={valueTab}
                                />
                            </Box>
                            <Box className="tabsDetailsSection" >
                                {/* ==== code For details tab ==== */}
                                <TabParent value={valueTab} index={0}>
                                    <Fade in={true}>
                                        <Box sx={{ background: colorNew.colorWhite, }}  >
                                            {adDetail?.owner_vehicle?.vehicle?.product_category_type?.product_type_id === productTypes.car
                                                ?
                                                <DetailsView item={adDetail} />
                                                : <VehicleView item={adDetail} />}
                                        </Box>
                                    </Fade>
                                </TabParent>
                                {/* ==== code For option / components tab ==== */}
                                <TabParent value={valueTab} index={1}>
                                    <Fade in={true}>
                                        <Box>
                                            {isCar ?
                                                <>
                                                    {isHovered && (
                                                        <Suspense fallback={<div><ContentLoader /></div>}>
                                                            <EquipmentsComponent item={adDetail} />
                                                        </Suspense>
                                                    )}
                                                </>
                                                :
                                                <>
                                                    {isHovered && (
                                                        <Suspense fallback={<div><ContentLoader /></div>}>
                                                            <ComponentTabView item={adDetail} />
                                                        </Suspense>
                                                    )}
                                                </>
                                            }
                                        </Box>
                                    </Fade>
                                </TabParent>

                                {/* ==== code For Electric / contact tab ==== */}

                                <TabParent value={valueTab} index={2}>
                                    <Fade in={true}>
                                        <Box>
                                            {isCar || isMotorCycle ?
                                                <>
                                                    {/* //Contact tab in car detail if user is dealer */}
                                                    {isHovered && (
                                                        <Suspense fallback={<div><ContentLoader /></div>}>
                                                            <ContactCommercial item={adDetail} dealer={dealer} />
                                                        </Suspense>
                                                    )}
                                                </>

                                                :
                                                //else Electric view in step and bike detail view
                                                <>
                                                    {isHovered && (
                                                        <Suspense fallback={<div><ContentLoader /></div>}>
                                                            <ElectricDetailsBikeSteps item={adDetail} />
                                                        </Suspense>
                                                    )}
                                                </>
                                            }
                                        </Box>
                                    </Fade>
                                </TabParent>
                                {/* ==== code For Expertise/ contact tab ==== */}
                                <TabParent value={valueTab} index={3}>
                                    {isCar ?
                                        <>
                                            {isHovered && (
                                                <Suspense fallback={<div><ContentLoader /></div>}>
                                                    {
                                                        adDetail?.owner_vehicle?.external_url ?
                                                            <Box className='scrollBar' sx={{ width: '100%' }}>
                                                                <iframe
                                                                    title="Embedded Web Page"
                                                                    width="100%"
                                                                    height="500px"
                                                                    src={adDetail?.owner_vehicle?.external_url}
                                                                    allowFullScreen > </iframe>
                                                            </Box>
                                                            :
                                                            <ExpertiesDetailTab
                                                                expertiseTab={expertiseTab}
                                                                handleExpertiseChange={handleExpertiseChange}
                                                                selectedElement={selectedElement}
                                                                setSelectedElement={_selectedElement}
                                                                imagesGroup={imagesGroup}
                                                                displayDamageDescriptionFunction={displayDamageDescriptionFunction}
                                                                itemData={adDetail}
                                                                countryName={countryName}
                                                                showVehicleDamageDescription={showVehicleDamageDescription}
                                                                setShowVehicleDamageDescription={setShowVehicleDamageDescription} />
                                                    }
                                                </Suspense>
                                            )}
                                        </>
                                        :
                                        <>
                                            {isHovered && (
                                                <Suspense fallback={<div><ContentLoader /></div>}>
                                                    <ContactCommercial item={adDetail} dealer={dealer} />
                                                </Suspense>
                                            )}
                                        </>
                                    }
                                </TabParent>
                            </Box>

                            {/* Fixed container that appears after in mobile view */}
                            {
                                isSmallDevice && <Box className="rightSection fixedActionContainer">
                                    {
                                        !(adDetail.ad_status == hotDealAdStatus.sold || adDetail.ad_status == hotDealAdStatus.eventEnded) &&
                                        <Box className='rightSubSection'>
                                            <p>{t(translations.HOT_DEALS.PARTICIPATE_FOR)} {adDetail.ad_status !== hotDealAdStatus.sold ? <span> {t(translations.HOT_DEALS.CREDITS, { credits: adDetail?.hotdeal?.hotdeal_event?.credit ?? 0, creditText: adDetail?.hotdeal?.hotdeal_event?.credit > 1 ? t(translations.invoiceScreen.CREDITS) : t(translations.HOT_DEALS.CREDIT) })}</span> : '-'}</p>
                                            {loggedIn &&
                                                <p>{myWalletBalance < adDetail.credit ? t(translations.HOT_DEALS.INSUFFICIENT_BALANCE) : t(translations.HOT_DEALS.AVAILABLE_CREDIT)}
                                                    <span className={myWalletBalance < adDetail.credit ? 'redText' : ''}> {myWalletBalance} </span>
                                                </p>
                                            }
                                        </Box>
                                    }
                                    <HotDealActionButton ad={adDetail} />
                                </Box>
                            }

                            {(isMediumDevice || isSmallDevice) && <BreadCrumb />}
                        </>
                        :
                        (!adLoader && isMounted.current) && <AdNotFound />
                }

            </HotDealsDetailStyle>
            <ZoomImage slides={images} open={open} setOpen={setOpen} />
        </PublicLayout >
    )
}