import { Box, Popper, Typography } from '@mui/material'
import ProfileImage from 'app/Component2/GlobalComponent/ProfileImage'
import { LayoutWrapper } from '../../Style/layoutWrapper'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import SwitchAccountNew from 'app/components/SwitchAccount/SwitchAccountNew'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { routes } from 'routes/routes'
import { Link } from 'react-router-dom'
import { getSubsidiaryStored } from 'config/variables'
import { logoutRequest } from 'store/actions/auth-actions'
import { langugageSwitchOpen } from 'store/actions/component-actions'
import { SvgArrowDown, SvgHelpCenter, SvgLanguageChangeIcon, SvgLogoutIcon, SvgMyAccountIcon } from 'app/components/svgicons/svgNew'
import { localstorageKey } from 'config/constants'
import { useEffect } from 'react'
import { getUserSubsidiaryRequest } from 'store/actions/company-actions'
import AccountVerifiedIcon from 'app/Component2/GlobalComponent/AccountVerifiedIcon '

const ProfilePopup = ({ handlePopper, openProfile, setOpenProfile, open, id, countryFlag, userName }) => {
    //Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // getting values from localstorage
    const subsidiaryId = getSubsidiaryStored();
    const getCurrentLaunguageCode = localStorage.getItem(localstorageKey.i18nextLng)
    const { subsidiaryData } = useSelector((state: RootStateOrAny) => state.company)
    // Redux States
    const { userData } = useSelector((state: any) => state.user);
    const { subsidiaryDetails } = useSelector((state: RootStateOrAny) => state.company);

    const { languageData } = useSelector((state) => state.auth)

    // Functions
    const getCurrentLaunguage = (currentLanguage) => {
        const selectedLanguage = languageData?.languages?.filter((language) => language.code == currentLanguage)
        if (selectedLanguage) {
            return selectedLanguage[0].name
        }
    }

    const profileUrl = (user, subsidiaryDetails) => {
        if (subsidiaryId) {
            return `${routes.publicRoutes.public_profile_subisidiary}/${subsidiaryDetails.slug}`
        }
        return `${routes.publicRoutes.public_profile_user}/${user.user_name}`
    }

    useEffect(() => {
        if (subsidiaryData.length < 1) {
            dispatch(getUserSubsidiaryRequest())
        }
    }, [])




    return (
        <>
            <Box className='flexRow' sx={{ ml: 2 }} onClick={handlePopper}>
                <Box className='imageWrapper pointer'>
                    <ProfileImage classes="image-1" />
                    {countryFlag ? <SvgArrowDown className='image-2' /> : null}
                </Box>
            </Box>
            <Popper
                open={open}
                id={id}
                anchorEl={openProfile}
                disablePortal={true}
                placement="bottom-end"
                sx={{
                    zIndex: 10
                }}
                PopperProps={{ style: { top: '15px' } }}
            >
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                    <Box className='popup-pointer' />
                </Box>
                <LayoutWrapper>
                    <Box className='popup-wraper' sx={{ boxShadow: 24 }} >
                        {/* Profile Image */}
                        <Box className='flexRow profile-container'>
                            <Box className='imageWrapper'>
                                <ProfileImage classes="image-1" />
                                {countryFlag ? <img src={countryFlag} className="image-2" /> : null}
                            </Box>
                            <Box>
                                <div className="flexColumn user-info">
                                    <Box sx={{ display: 'flex' }}>
                                        <span className="text-name">{userName ? userName : t(translations.profileScreen.PSEUDONAME)}</span>
                                        <AccountVerifiedIcon isVerified={(!subsidiaryId && userData.profile_is_verified) || (subsidiaryId && subsidiaryDetails.profile_is_verified)} iconStyles={{ marginTop: '5px' }} />
                                    </Box>
                                    <Link to={profileUrl(userData, subsidiaryDetails)} className="text-link" onClick={() => { }}>{`${t(translations.webTexts.VIEW_YOUR_PROFILE)}`}</Link>
                                </div>
                            </Box>
                        </Box>
                        <Box className='scrollBar' sx={{
                            maxHeight: '70vh',
                            overflowY: 'auto'
                        }}>
                            {/* subsidiaries Profile*/}
                            {subsidiaryData.length > 0 && <Box className='other-profile-container'>
                                <Typography className='other-profile-text'>{t(translations.profileScreen.OTHER_PROFILE)}</Typography>
                                <Box className='scrollBar other-profiles'>
                                    <SwitchAccountNew setOpenProfile={setOpenProfile} isFromHeader={true} />
                                </Box>
                            </Box>}
                            {/* other options */}
                            <Box className='my-account-container'>
                                {/* My Account */}
                                <Link to={subsidiaryId ? routes.companyRoutes.companyProfile : routes.userRoutes.myProfile}>
                                    <span className='other-option border'> <SvgMyAccountIcon />{t(translations.profileScreen.MY_ACCOUNT)}</span>
                                </Link>
                            </Box>
                            <Box className='other-links'>
                                {/* Switch language option */}
                                <button className='other-option' onClick={() => {
                                    setOpenProfile(null)
                                    dispatch(langugageSwitchOpen())

                                }}><SvgLanguageChangeIcon />{getCurrentLaunguage(getCurrentLaunguageCode)}</button>

                                {/* Help center */}
                                <Link to={routes.publicRoutes.contactUs} style={{ marginBottom: '15px' }}>
                                    <span className='other-option'> <SvgHelpCenter />{t(translations.profileScreen.HELP_CENTER)}</span>
                                </Link>

                                {/* Logout */}
                                <button className='other-option' onClick={() => {
                                    setOpenProfile(null)
                                    dispatch(logoutRequest())
                                }}><SvgLogoutIcon />{t(translations.profileScreen.LOG_OUT)}</button>
                            </Box>
                        </Box>


                    </Box>
                </LayoutWrapper>
            </Popper ></>
    )
}

export default ProfilePopup