import { memo, useState } from 'react'
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { SvgSecure } from 'app/components/svgicons/svg2'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import AvailablePromoList from './AvailablePromoList'
import { numberFormat } from 'config/variables'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { creditsPaymentSuccess } from 'store/actions/user-actions'
import { ApiPaths } from 'services/ApiPaths'
import Api from 'services/axios'
import { Toaster } from 'services/Toaster'
import CheckoutForm from './CheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { i18n } from 'locales/i18n'
import { localstorageKey } from 'config/constants'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)

const MakeCreditPayment = () => {
    // hooks
    const [isSmallDevice] = useDeviceType()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    // local states
    const [code, setCode] = useState<string>('')
    const [codeError, setCodeError] = useState<string>('')
    const [codeApplied, setCodeApplied] = useState<boolean>(false)
    const [codeAppliedLoading, setCodeAppliedLoading] = useState<boolean>(false)
    const { total } = useSelector((state: RootStateOrAny) => state.user.creditPayment)

    //decode price Id from url
    const queryParamPriceId = atob(new URLSearchParams(location.search).get('pid'))
    const queryParamStripePriceId = atob(new URLSearchParams(location.search).get('st_pr_id'))

    const handleOnChange = (event) => {
        setCode(event.target.value)
        setCodeError('')
    }

    // Function to apply promotional codes
    const handleApplyCode = (code) => {

        if (!code) {
            // error if user try to apply empty code
            setCodeError(t(translations.webTexts.PLEASE_ENTER_PROMO_CODE))
            return
        }
        setCodeAppliedLoading(true)
        // API call for appy code and getting updated prices
        Api.post(ApiPaths.user.getTotals, {
            code: code,
            price_id: queryParamPriceId
        }).then((res) => {
            if (res.data.data.order.discount_applied) {
                // if code applied successfully then display toaster
                setCodeApplied(true)
                setCodeAppliedLoading(false)
                Toaster.success(t(translations.validationAndPopText.PROMO_CODE_CONFIRMATION))
            }
            dispatch(creditsPaymentSuccess(res.data.data))

        }).catch((error) => {
            // If any error
            setCodeApplied(false)
            setCodeAppliedLoading(false)
            const resp = error.response;
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {
                    Object.keys(resp.data.errors).map(
                        (error, index) => (error_message = resp.data.errors[error][0])
                    );
                }
            } else if (resp.data.data?.code !== undefined) {
                error_message = resp.data.data.code[0];
            } else if (resp.data?.error !== undefined) {
                error_message = resp.data.error
            } else {
                error_message = resp.data.message
            }
            // then set error state 
            setCodeError(error_message)
        })
    }

    //    function to clear promo code input
    const clearCode = (code) => {
        // API call for remove code and getting actual prices
        setCodeAppliedLoading(true)

        Api.post(`${ApiPaths.user.getTotals}`, {
            price_id: queryParamPriceId
        }).then((res) => {
            if (!res.data.data.order.discount_applied) {
                // if code removed successfully then display toaster
                setCode("");
                setCodeApplied(false)
                setCodeAppliedLoading(false)

            }
            dispatch(creditsPaymentSuccess(res.data.data))

        }).catch((error) => {
            // If any error
            setCodeApplied(false)
            setCodeAppliedLoading(false)

            const resp = error.response;
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {
                    Object.keys(resp.data.errors).map(
                        (error, index) => (error_message = resp.data.errors[error][0])
                    );
                }
            } else if (resp.data.data?.code !== undefined) {
                error_message = resp.data.data.code[0];
            } else if (resp.data?.error !== undefined) {
                error_message = resp.data.error
            } else {
                error_message = resp.data.message
            }
            // then set error state 
            setCodeError(error_message)
        })
    }
    const option = {
        mode: 'payment',
        amount: 10,
        hidePostalCode: true,
        currency: 'eur',
        locale: i18n.language,
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false
        }
    };

    return (
        <Box className='make-payment plan-payment-wrapper'>
            <Box sx={{ mb: '24px' }}>
                <Typography className='make-payment-header'>{t(translations.HOT_DEALS.MAKE_PAYMENT)}</Typography>
            </Box>

            {/* ========Apply promo code section ======= */}
            <Box className='flexRow justifyContentBetween'>
                <TextField
                    className='promo-code'
                    id="outlined-basic"
                    label={t(translations.CTB_CREDIT.PROMO_CODE)}
                    value={code}
                    onChange={(e) => handleOnChange(e)}
                    disabled={codeApplied}
                    variant="outlined" />
                <Button
                    className={`apply-btn`}
                    color={codeApplied ? 'error' : 'primary'}
                    disableElevation={true}
                    onClick={() => codeApplied ? clearCode(code) : handleApplyCode(code)}
                    variant={'contained'}>{codeAppliedLoading ? <CircularProgress color='inherit' size='20px' /> : codeApplied ? t(translations.CTB_CREDIT.REMOVE) : t(translations.createCompanyScreen.APPLY)}</Button>
            </Box>
            {codeError && <Typography className='promo-error' >{codeError}</Typography>}

            <Box className='flexRow justifyContentBetween amount' >
                <Box className='flexColumn payment-header-description' >
                    {!isSmallDevice && <span>{t(translations.HOT_DEALS.CURRENT_PAYABLE_AMOUNT)}</span>}
                    {!isSmallDevice && <span className='payable-amount'>€{total ? numberFormat((total / 100).toFixed(2)) : numberFormat((0).toFixed(2))}</span>}
                </Box>
                <AvailablePromoList codeApplied={codeApplied} setCode={setCode} setCodeError={setCodeError} code={code} handleApplyCode={handleApplyCode} />
            </Box>


            {/* ========= payment checkout and  input section ==========*/}
            <Box className='flexRow justifyContentEnd' sx={{ my: '21px' }}>
                <Box className='flexRow alignItemsCenter'>
                    <SvgSecure style={{ marginRight: '4px' }} />
                    <span className='secure-payment' style={{ color: '#07bc0c', fontWeight: '400' }}>
                        {t(translations.webTexts.SECURE_CHECKOUT)}
                    </span>
                </Box>
            </Box>
            <Box className='make-payment-wrapper'>
                <Elements stripe={stripePromise} options={option}>
                    <CheckoutForm codeApplied={codeApplied} localstorageKeys={localstorageKey.makeCreditPaymentIntentId} priceId={queryParamStripePriceId} code={code} btnText={t(translations.HOT_DEALS.MAKE_PAYMENT)} />
                </Elements>
            </Box>
        </Box>
    )
}

export default memo(MakeCreditPayment)