import { Box, Modal } from '@mui/material';
import { useWindowSize } from 'app/Hooks/windowResize';
import { SVGCross } from 'app/components/svgicons/svgNew';
import { PublicProfileWrapper } from 'app/pages/PublicProfile/style/publicProfileStyle';
import React, { useState } from 'react';
import FollowersAndFollowing from './followersAndFollowing';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import * as publicProfileConstants from "config/publicProfileConfig"
import { RootStateOrAny, useSelector } from 'react-redux';
import { followInterface } from 'app/pages/PublicProfile/interface/interface';

const Followers = ({followers , following , selfProfile} : followInterface) => {

  const { t } = useTranslation()
  const [mobile] = useWindowSize()

  const { profileSuccessData } = useSelector((state:RootStateOrAny) => state.publicProfile)
  
  const [open, setOpen] = useState<boolean>(false);

  const [selectedTab,setSelectedTab] = useState<number>(publicProfileConstants.FollowerTabs.Followers)

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
      setOpen(false);
  }


  return (
    <Box>
      <Box className="userDetailsSubContainers flexRow justifyContentCenter alignItemsCenter pointer" onClick={handleOpen}>
        <span className="folowers link" style={{ textDecoration: 'underline'}} onClick={(()=>setSelectedTab(publicProfileConstants.FollowerTabs.Followers))}> {publicProfileConstants.convertFollowersCount(followers) || 0} { followers == 1 ? t(translations.PUBLICPROFILE.FOLLOWER) : t(translations.PUBLICPROFILE.FOLLOWERS)}</span>
        <span className="greyDot"></span>
        <span className="folowers link" style={{ textDecoration: 'underline'}} onClick={(()=>setSelectedTab(publicProfileConstants.FollowerTabs.Following))}> {publicProfileConstants.convertFollowersCount(following) || 0} {t(translations.PUBLICPROFILE.FOLLOWING)}</span>
      </Box>
      <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <PublicProfileWrapper>
                <Box className={mobile ? 'openMobileModal' : 'openModal'}>
                  <Box className='flexRow justifyContentBetween'>
                    <span className='userName'>
                      {profileSuccessData.pseudo || profileSuccessData.name}
                    </span>
                    <SVGCross onClick={handleClose} className='pointer closeModal' />
                  </Box>
                  <FollowersAndFollowing selectedTab={selectedTab} setOpen={setOpen} followersCount={followers} followingCount={following}/>
                </Box>
                </PublicProfileWrapper>
            </Modal>
    </Box>
  );
};

export default Followers;
