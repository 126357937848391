import { handleActions } from 'redux-actions';
import { findDealerClose, findDealerOpen, getCloseFilters, getCloseNavbar, getOpenFilters, getOpenNavbar, getWebSideMenuOpenRequest, getWebSideMenuOpenReset, langugageSwitchOpen, langugageSwitchReset, notificationRequest, notificationReset, sidebarOpenRequest, sidebarOpenReset, switchUserClose, switchUserOpen } from 'store/actions/component-actions';
const initialState = {
  sidebarCheck: false,
  notification: false,
  languageOpen: false,
  switchUserDrawer: false,
  webMenuOpen: false,
  navBarMenu: false,
  findDealerModal: false,
  filters: false
}

const componentReducers = handleActions(
  {
    [sidebarOpenRequest]: state => ({
      ...state,
      sidebarCheck: true,
    }),
    [sidebarOpenReset]: state => ({
      ...state,
      sidebarCheck: false,
    }),
    [notificationRequest]: state => ({
      ...state,
      notification: true,
    }),
    [notificationReset]: state => ({
      ...state,
      notification: false,
    }),
    [langugageSwitchReset]: state => ({
      ...state,
      languageOpen: false,
    }),
    [langugageSwitchOpen]: state => ({
      ...state,
      languageOpen: true,
    }),
    [switchUserOpen]: state => ({
      ...state,
      switchUserDrawer: true,
    }),
    [switchUserClose]: state => ({
      ...state,
      switchUserDrawer: false,

    }),
    [getWebSideMenuOpenRequest]: state => ({
      ...state,
      webMenuOpen: true,
    }),
    [getWebSideMenuOpenReset]: state => ({
      ...state,
      webMenuOpen: false,
    }),
    [getOpenNavbar]: state => ({
      ...state,
      navBarMenu: true,
    }),
    [getCloseNavbar]: state => ({
      ...state,
      navBarMenu: false,
    }),
    [getOpenFilters]: state => ({
      ...state,
      filters: true,
    }),
    [getCloseFilters]: state => ({
      ...state,
      filters: false,
    }),
    [findDealerOpen]: state => ({
      ...state,
      findDealerModal: true,
    }),
    [findDealerClose]: state => ({
      ...state,
      findDealerModal: false,
    }),
  },
  initialState,
)

export default componentReducers;