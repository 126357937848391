import { Box, Divider, Skeleton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import AddReview from './addReview'
import Reviews from './reviews'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { addReviewsSuccess, getReviewsRequest, resetLoading, updateReviewsSuccess } from 'store/actions/publicProfile-action'
import moment from 'moment'
import { ReviewsInterface, userReviewInterface } from 'app/pages/PublicProfile/interface/interface'
import { useLocation, useParams } from 'react-router-dom'
import { getSubsidiaryStored } from 'config/variables'
import * as publicProfileConstants from "config/publicProfileConfig"
import { theme } from 'config'
import { SvgRatingColor } from 'app/components/svgicons/svg2'

const ReviewComponent = ({ _scrollFlag, scrollFlag, setPage, page }) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const scrollRef: any = useRef(null)

    const { userType, slug } = useParams()

    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth)
    const { userData } = useSelector((state: RootStateOrAny) => state.user)
    const { reviewsList, reviewsListLastPage, addReviewSuccessData, loadingReviews, updateReviewSuccessData, profileSuccessData, flagReviewSuccessData } = useSelector((state: RootStateOrAny) => state.publicProfile)

    // const [scrollFlag, _scrollFlag] = useState<boolean>(true);
    // const [page,setPage] = useState<number>(1)

    const [allReviewsList, setAllReviewsList] = useState<Array<ReviewsInterface>>([])

    const [showOptions, _showOptions] = useState<boolean>(true)

    const [review, _review] = useState<userReviewInterface>({})
    const [reviewChanged, _reviewChanged] = useState<boolean>(false)
    const [reviewGiven, _reviewGiven] = useState<boolean>(false)


    useEffect(() => {
        setAllReviewsList(reviewsList)
    }, [reviewsList])

    useEffect(() => {
        if (loggedIn && profileSuccessData && Object.keys(profileSuccessData).length > 0) {
            _review({})
            if (profileSuccessData.rating && Object.keys(profileSuccessData.rating).length > 0) {
                _review(profileSuccessData.rating)
            }

            // Comparing id's of logged in user and public profile page's id when logged in as private user
            if (userData.id == profileSuccessData.id && !getSubsidiaryStored()) {
                _showOptions(false)
                return
            }
            // Comparing id's of logged in subsidiary and public profile page's id when logged in as subsidiary user
            if (getSubsidiaryStored() && getSubsidiaryStored() == profileSuccessData.id) {
                _showOptions(false)
                return
            }
            _showOptions(true)
        }
    }, [profileSuccessData.id, slug, getSubsidiaryStored()])


    // Prepending newly added review at top of list
    useEffect(() => {
        if (addReviewSuccessData && Object.keys(addReviewSuccessData).length > 0) {
            let oldReviewByUser = { ...review }
            oldReviewByUser['id'] = addReviewSuccessData.id
            oldReviewByUser['rating'] = addReviewSuccessData.rating
            oldReviewByUser['review'] = addReviewSuccessData.review
            oldReviewByUser['created_at'] = addReviewSuccessData.created_at
            _review(oldReviewByUser)
            _reviewChanged(true)

            let newReview = [addReviewSuccessData, ...allReviewsList]
            setAllReviewsList(newReview)
            dispatch(addReviewsSuccess({}))
            _reviewGiven(true)
        }
    }, [addReviewSuccessData])


    // Update edited review 
    useEffect(() => {
        if (updateReviewSuccessData && Object.keys(updateReviewSuccessData).length > 0) {
            // Updating data which appears at header position
            let oldReviewByUser = { ...review }
            oldReviewByUser['id'] = updateReviewSuccessData.id
            oldReviewByUser['rating'] = updateReviewSuccessData.rating
            oldReviewByUser['review'] = updateReviewSuccessData.review
            oldReviewByUser['created_at'] = updateReviewSuccessData.created_at
            _review(oldReviewByUser)
            _reviewChanged(true)

            // Updating data which appears in list
            let oldReviews = [...allReviewsList]
            let existence = oldReviews.findIndex(o => o.id == updateReviewSuccessData.id)
            if (existence != -1) {
                oldReviews[existence] = updateReviewSuccessData
                setAllReviewsList(oldReviews)
            }
            dispatch(updateReviewsSuccess({}))
        }
    }, [updateReviewSuccessData])


    useEffect(() => {
        if (reviewChanged) {
            _reviewChanged(false)
        }
    }, [reviewChanged])


    // Update edited review if flagged 
    useEffect(() => {
        if (flagReviewSuccessData && Object.keys(flagReviewSuccessData).length > 0) {
            let oldReviews = [...allReviewsList]
            let existence = oldReviews.findIndex(o => o.id == flagReviewSuccessData.data.id)
            if (existence != -1) {
                oldReviews[existence].is_flagged = publicProfileConstants.isFlagged.true
                setAllReviewsList(oldReviews)
            }
            dispatch(updateReviewsSuccess({}))
        }
    }, [flagReviewSuccessData])



    // Request for first pages response when rendered
    useEffect(() => {
        // Requesting API call only if user haven't came back to same profile
        if (localStorage.getItem('prevPath') != location.pathname) {
            setAllReviewsList([])
            if (profileSuccessData && profileSuccessData.id) {
                // request only if url username/slug matches the slug/username in profile response data  
                if ((profileSuccessData.slug && profileSuccessData.slug.toLowerCase() == slug.toLowerCase()) || (profileSuccessData.user_name && profileSuccessData.user_name.toLowerCase() == slug.toLowerCase())) {
                    dispatch(getReviewsRequest({
                        page: 1,
                        model_type: userType === publicProfileConstants.profile.pro ? publicProfileConstants.profileTypeString.subsidiary : userType,
                        model: profileSuccessData.id
                    }))
                    return
                }
            }
            dispatch(resetLoading())
        }
    }, [profileSuccessData.id, getSubsidiaryStored()])

    //Function request for next page data when scrolled to bottom
    const handleScroll = (e) => {
        // bottom return boolean value and Math.abs to get absolute value 
        const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
        if (bottom && scrollFlag && page < reviewsListLastPage) {
            setPage(page + 1)
            dispatch(getReviewsRequest({
                page: page + 1,
                model_type: userType === publicProfileConstants.profile.pro ? publicProfileConstants.profileTypeString.subsidiary : userType,
                model: profileSuccessData.id
            }))
            _scrollFlag(false)
            return;
        } else {
            _scrollFlag(true)
        }
    }

    const getTime = (time) => {
        if (!moment.isMoment(time)) {
            time = moment(time);
        }
        if (time.isSame(moment(), 'day')) {// if chat of same day then display time
            const duration = moment.duration(moment().diff(time));
            const hours = duration.asHours();
            const minutes = duration.asMinutes();
            if (minutes < 1) {
                return t(translations.TIME.FEW_SECONDS_AGO)
            } else {
                return hours > 1 ? `${Math.round(hours) + " " + (Math.round(hours) > 1 ? t(translations.TIME['HOURS AGO']) : t(translations.TIME['HOUR AGO']))}` : t(translations.TIME.FEW_MINUTES_AGO)
            }
        } else if (time.isSame(moment().subtract(1, 'd'), 'day')) {// if chat from last day (yesterday)
            return t(translations.CHAT.YESTERDAY);
        } else if (time.isSame(moment().subtract(7, 'days'), 'week')) { // if chat last week then display (week day name)
            return time.format('dddd');
        } else {
            return time.format('DD/MM/YYYY');  // if chat is 1 week or more days ago then display date
        }

    }
    return (
        <Box>
            {/* Renders only if user has opened other users profile */}
            {showOptions && <Box className='flexColumn borderBottom'>
                {
                    // If user has given rating in last 6 months
                    (profileSuccessData.rating_given_in_6months || reviewGiven) && Object.keys(review).length > 0 ?
                        <AddReview edit={true} showEditText={true} recId={review.id} userRating={review.rating} userReview={review.review} key={reviewChanged} /> :

                        // If user has given rating nut before 6 months
                        !profileSuccessData.rating_given_in_6months && Object.keys(review).length > 0 ?
                            <AddReview edit={false} showEditText={true} userRating={review.rating} /> :

                            // if user hasn't given ratigs yet
                            <AddReview edit={false} showEditText={true} />
                }
            </Box>}

            <span className='componentHeader'>
                <SvgRatingColor /> {t(translations.PUBLICPROFILE.REVIEW)}
            </span>
            <Divider sx={{ mb: '5px' }} />
            <Box className='flexColumn reviewMainContainer' onScroll={(e) => handleScroll(e)} ref={scrollRef} sx={{
                maxHeight: !showOptions ? 'calc( 100vh - 235px) !important' : 'calc( 100vh - 330px) !important',
                [theme.breakpoints.down('md')]: {
                    overflow: 'unset !important',
                    overflowY: 'unset !important',
                }
            }}>
                {/* Renders when data has been loaded and has data */}
                {allReviewsList && allReviewsList.length > 0 && allReviewsList.map((review) => {
                    return <Reviews recId={review.id} title={review.title} userImage={review.thumb_url} time={getTime(review.created_at)} flag={review.flag_thumbnail} review={review.review} rating={review.rating} editable={review.can_edit} flaggable={review.can_flag} isflagged={review.is_flagged} key={review.id} allData={review} />
                })}

                {/* Renders when data has been loaded and has no data */}
                {(allReviewsList.length == 0 && !loadingReviews) &&
                    <Box className="UserDetailsContainer flexColumn width-100">
                        <Box className='noDataFound'>
                            {t(translations.PUBLICPROFILE.NO_REVIEWS)}
                        </Box>
                    </Box>
                }

                {/* Renders when data is being loaded */}
                {(allReviewsList.length == 0 && loadingReviews) &&
                    [1, 2, 3, 4, 5].map((item) => {
                        return <Box className="flexColumn width-100" sx={{ marginBottom: 1, p: 1 }} key={`Reviewskeleton${item}`}>
                            <Box className='flexRow' sx={{ padding: ' 0px 10px' }}>
                                <Box sx={{ paddingY: 1 }}>
                                    <Skeleton variant="circular" width='40px' height='40px' />
                                </Box>
                                <Box className='flexColumn justifyContentBetween reviewerName' sx={{ padding: '10px 0 !important' }}>
                                    <p className='flexRow' style={{ alignItems: 'end' }}> <Skeleton variant="rectangular" width='90px' height='15px' /> <span> <Skeleton variant="rectangular" width='60px' height='12px' sx={{ marginLeft: '5px' }} /> </span></p>
                                    <Box>
                                        <Skeleton variant="rectangular" width='110px' height='15px' />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='mt-10'>
                                <Skeleton variant="rectangular" width='100%' height='70px' />
                            </Box>
                        </Box>
                    })}
            </Box>
        </Box >
    )
}

export default ReviewComponent