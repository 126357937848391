import { Box, Button, Drawer } from '@mui/material'
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { SvgCheckDark, SvgSearchLite } from 'app/components/svgicons/svg';
import { SvgFillerIcon, SvgMobileLeftArrow, SvgMobileSort } from 'app/components/svgicons/svgNew';
import { translations } from 'locales/translations';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Filter from "app/components/Filters";
import { FilterWrapper } from '../Filters/style';
import { routes } from 'routes/routes';
import { urlParamInterface } from 'config/interface';
import { useDispatch } from 'react-redux';
import { getCloseFilters, getOpenFilters } from 'store/actions/component-actions';
import { SvgDelete } from 'app/components/svgicons/svg'
import { getParamQueries, updateURL } from 'config/appConfig';
import AppliedFilters from '../Filters/components/appliedFilters';
import { isValidQuery, replaceSpaceAndAmpersand } from 'config/utils';
import { MyAdsWrapper } from 'app/pages/MyAdsPage/Style/MyAdsWrapper';


const Header = ({ setSearch, setSort, sort, search, isAuction, auctionName, searched, _searched, title }) => {

    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()

    const { vehicle, id }: urlParamInterface = useParams();
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()

    const [open, setOpen] = useState<boolean>(false)
    const [filterOpen, setfilterOpen] = useState<boolean>(false)
    const [keyword, setkeyword] = useState<string>(search)

    const [currentUrl, _currentUrl] = useState<string>('')
    const [currentQueries, _currentQueries] = useState<string>('')

    // Sets values got from url to fields (runs only when page is rendered for the first time)
    useEffect(() => {
        let payload = getParamQueries(location.search)
        if (payload.keyword) {
            let str = decodeURIComponent(payload.keyword)
            if (str.includes('+')) {
                _searched(true)
                setkeyword(str.replaceAll("+", ' '))
                return
            }
            _searched(true)
            setkeyword(str)
            return

        }
        setkeyword('')
    }, [location.search])

    useEffect(() => {
        // If user hasn't changed in search field anything we won't update URL
        if (!searched) {
            return
        }
        const debounce = setTimeout(() => {
            setSearch(keyword)
            updateURL('keyword', keyword, history)
        }, 500)
        return () => clearTimeout(debounce)
    }, [keyword])

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setOpen(open);
            };

    const handleSort = (value) => {
        setOpen(false)
        setSort(value)
    }

    const list = () => {
        return <MyAdsWrapper>
            <Box className='mobileOptionsHeader'>
                <span >{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_SORT_BY)}</span>
            </Box>
            <Box className='myAdsOptionContainer flexColumn'>
                <Box className='flexRow justifyContentBetween alignItemsCenter'>
                    <span className='myAdsOption' onClick={() => handleSort("-created_at")}>{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_RECENT_ADS)}</span>
                    {sort === '-created_at' && <SvgCheckDark className='marginRight-10' />}
                </Box>
                <Box className='flexRow justifyContentBetween alignItemsCenter'>
                    <span className='myAdsOption' onClick={() => handleSort("created_at")}>{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_OLDEST_ADS)}</span>
                    {sort === 'created_at' && <SvgCheckDark className='marginRight-10' />}
                </Box>
                <Box className='flexRow justifyContentBetween alignItemsCenter'>
                    <span className='myAdsOption' onClick={() => handleSort("total_price")}>{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_ASC_PRICE)}</span>
                    {sort === 'total_price' && <SvgCheckDark className='marginRight-10' />}
                </Box>
                <Box className='flexRow justifyContentBetween alignItemsCenter'>
                    <span className='myAdsOption' onClick={() => handleSort("-total_price")}>{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_DSC_PRICE)}</span>
                    {sort === '-total_price' && <SvgCheckDark className='marginRight-10' />}
                </Box>
                <Box className='flexRow justifyContentBetween alignItemsCenter'>
                    <span className='myAdsOption' onClick={() => handleSort("brand_id")}>{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_ASC_BRAND)}</span>
                    {sort === 'brand_id' && <SvgCheckDark className='marginRight-10' />}
                </Box>
                <Box className='flexRow justifyContentBetween alignItemsCenter'>
                    <span className='myAdsOption' onClick={() => handleSort("-brand_id")}>{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_DSC_BRAND)}</span>
                    {sort === '-brand_id' && <SvgCheckDark className='marginRight-10' />}
                </Box>
            </Box>
        </MyAdsWrapper>
    }


    const toggleFilterDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setfilterOpen(open);
            };

    const openFilters = () => {
        _currentUrl(location.pathname)
        _currentQueries(location.search)
        dispatch(getOpenFilters())
        setfilterOpen(true)
    }

    const handleBackFromFilters = () => {
        history.push({
            pathname: currentUrl,
            search: currentQueries
        })
        dispatch(getCloseFilters())
        setfilterOpen(false)
    }

    const handleClearFilter = () => {
        if (location.pathname.includes(routes.vehicleRoutes.auctionAd)) {
            history.push(routes.vehicleRoutes.auctionAd + '/' + vehicle + "/" + id)
        }
        if (location.pathname.includes(routes.vehicleRoutes.publicAds)) {
            history.push(routes.vehicleRoutes.publicAds + '/' + vehicle)
        }
        dispatch(getCloseFilters())
        setfilterOpen(false)
    }

    const handleChange = (e) => {
        // DO NOT INCLUDE '%' IN SEARCH STRING , it will create problem during encoding and decoding the search string in url
        if (e.target.value.includes('%')) return;
        _searched(true)
        setkeyword(e.target.value)
    }
    const FiltersComponent = () => {
        return <FilterWrapper style={{ height: '100vh' }}>
            <Box>
                <Filter keyword={keyword} handleChangeSearch={handleChange} handleBackFromFilters={handleBackFromFilters} />
                <Box className='flexRow closeFilters'>
                    <Button className='deleteButton' onClick={handleClearFilter}><SvgDelete /></Button>
                    <Button className='applyButton' onClick={() => {
                        dispatch(getCloseFilters())
                        setfilterOpen(false)
                    }}>{t(translations.FILTER_COMPONENT.FILTER_APPLY)}</Button>

                </Box>
            </Box>
        </FilterWrapper>
    }
    const handleGoBack = () => {
        // go back on home page , if there is no previous url in history related to cartobike,
        // length <= 2 means one is current page , one is google home page, if there more page than there two page , than redirect on previous page from those pages 
        if (history.length <= 2) {
            history.push('/')
        }
        else history.goBack();
    }


    return (
        <>
            <Box
                sx={{
                    px: (isSmallDevice || isMediumDevice) ? 0 : 2,
                    py: (isSmallDevice || isMediumDevice) ? 0 : 1,
                    position: isSmallDevice || isMediumDevice ? '' : 'sticky',
                    top: isSmallDevice || isMediumDevice ? '0px' : '125px',
                    background: '#fff',
                    zIndex: '3',
                }}>

                {(isLargeDevice || isExtraLargeDevice) &&
                    <Box className='flexColumn' sx={{ width: '100%' }}>

                        <Box className='flexRow justifyContentBetween' style={{ width: '100%', alignItems: 'flex-end' }}>
                            <Box className='flexColumn alignItemsEnd'>
                                {isAuction ? <h1 className='header'>{t(translations.ads.AUCTIONTEXT)} - {auctionName}</h1>
                                    :
                                    <h1 className='header'>{title.replace("- Cartobike", "")}</h1>
                                }

                            </Box>
                            <Box className='flexRow alignItemsCenter'>
                                <Box sx={{ mr: 3 }} className='searchBox flexRow alignItemsCenter'>
                                    <input type='text' placeholder={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SEARCH)} onChange={(e) => handleChange(e)} value={replaceSpaceAndAmpersand(keyword)} />
                                    <SvgSearchLite />
                                </Box>
                                {!isAuction && <Box className='flexRow sortBox' sx={{
                                    'pointer': {
                                        cursor: 'pointer'
                                    }
                                }}>
                                    <span>
                                        {t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_SORT_BY)}
                                    </span>
                                    <select name="sort" id="sort" onChange={(e) => {
                                        setSort(e.target.value)
                                        updateURL('page', '', history)
                                    }} value={sort}>
                                        {/* appears only in auction */}
                                        {/* <option value="-last_action">{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_RECENT_ACTION)}</option> */}
                                        <option className='pointer' value="-created_at">{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_RECENT_ADS)}</option>
                                        <option className='pointer' value="created_at">{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_OLDEST_ADS)}</option>
                                        <option className='pointer' value="total_price">{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_ASC_PRICE)}</option>
                                        <option className='pointer' value="-total_price">{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_DSC_PRICE)}</option>
                                        <option className='pointer' value="brand_id">{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_ASC_BRAND)}</option>
                                        <option className='pointer' value="-brand_id">{t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_DSC_BRAND)}</option>
                                    </select>
                                </Box>}
                            </Box>
                        </Box>
                        <AppliedFilters />                </Box>
                }
            </Box>

            {(isSmallDevice || isMediumDevice) &&
                <>  <Box className='flexRow justifyContentBetween alignItemsCenter' sx={{ p: 1, background: "#fff", 'svg': { width: '30px' } }}>
                    <SvgMobileLeftArrow onClick={handleGoBack} />
                    <Box className='flexColumn' sx={{ textAlign: 'center' }}>
                        {isAuction ?
                            <span className='header'>{t(translations.formText.AUCTIONS)}</span> :
                            <h1 className='header-mobile'>{title.replace("- Cartobike", "")}</h1>
                        }
                        {isAuction && <span className='headerCount'>{auctionName}</span>}
                    </Box>
                    <Box>
                        <SvgMobileSort style={{ opacity: !isAuction ? '100%' : 0 }} onClick={() => !isAuction ? setOpen(true) : null} />
                    </Box>
                </Box>
                    <Box className='filter-container' style={{ position: 'sticky', top: 0, zIndex: 9, backgroundColor: '#FFF' }}>


                        <Box className='flexRow justifyContentBetween alignItemsCenter' sx={{ padding: '10px' }}>
                            <AppliedFilters />
                            <Box className='flexRow justifyContentEnd'>
                                {isSmallDevice && <Box className='filterIcon'>
                                    <SvgFillerIcon onClick={openFilters} />
                                </Box>}
                            </Box>
                        </Box>
                        <Drawer
                            anchor='bottom'
                            open={open}
                            onClose={toggleDrawer(false)}
                            sx={{
                                '.MuiPaper-root': {
                                    borderRadius: '6% 6% 0 0'
                                }
                            }}
                        >
                            {list()}
                        </Drawer>
                        <Drawer
                            anchor='bottom'
                            open={filterOpen}
                            onClose={toggleFilterDrawer(false)}
                        >
                            {FiltersComponent()}
                        </Drawer>
                    </Box></>}
        </>
    )
}

export default Header