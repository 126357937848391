/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';
import auth from './reducers/auth-reducer';
import user from './reducers/user-reducer';
import company from './reducers/company-reducer';
import componentReducers from './reducers/component-reducer';
import showcase from './reducers/showcase-reducer';
import publicReducer from './reducers/public-reducer';
import vehicle from './reducers/vehicle-reducer';
import chat from './reducers/chat-reducer';
import licensePlate from './reducers/licensePlate-reducer';
import publicProfile from './reducers/publicProfile-reducer';
import onlineUsers from './reducers/onlineUser-reducer';
import myAds from './reducers/myAds-reducers';
// import {rootReducer} from './reducers';
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return state => state;
  } else {
    return combineReducers({
      auth,
      user,
      company,
      componentReducers,
      showcase,
      publicReducer,
      vehicle,
      chat,
      licensePlate,
      publicProfile,
      onlineUsers,
      myAds
    });
  }
}
