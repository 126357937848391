import { Box } from '@mui/material';
import { SvgIconBell } from 'app/components/svgicons/svgNew';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

const Notification = (props: any) => {
  const { loggedIn } = useSelector((state: any) => state.auth);
  const [countNotifications, _countNotifications] = useState<number>();
  const { notificationCount } = useSelector((state) => state.company);
  useEffect(() => {
    if (notificationCount && notificationCount !== null) {
      if (Object.keys(notificationCount).length > 0) {
        if ('data' in notificationCount) {
          _countNotifications(notificationCount.data.new_notification_count);
        }
      }
    }
  }, [notificationCount])
  return (
    <Box sx={{
      position: 'relative',
      'svg circle': {
        fill: (countNotifications && countNotifications > 0) ? '' : 'lightgray'
      }
    }}>
      <Link to={loggedIn ? routes.companyRoutes.notification : routes.authRoutes.login}>
        <span>
          <SvgIconBell />
        </span>
      </Link>
    </Box>
  )
}

export default Notification