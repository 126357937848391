import { Box } from '@mui/material'
import React from 'react'
import { SVGPlayBtn } from '../Assets/NavigationHeaderSvg'
import { Link, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { vehicles } from 'config/variables'
import { useDispatch } from 'react-redux'
import { handleResetOnPageChange } from 'config/utils'

const Vehicle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { vehicle } = useParams();

  return (
    <>
      <Box className='sub-menu-container'>
        <Box sx={{
          borderBottom: '1px solid #efefef',
          '.myCar-text': { margin: '10px 0', fontSize: '15px', color: '#666', fontWeight: 400 },
          '.header': { fontSize: '21px', color: '#000', fontWeight: 500 }
        }}>
          <span className='header'>{t(translations.ads.VEHICLES)}</span>
          <Box className='myCar-text'>{t(translations.NAVBAR.EXPLORE_VEHICLE)}</Box>
        </Box>
        <Box className='auction-type'
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            letterSpacing: '0.36px'
          }}
        >
          <Link to={{
            pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.car}`,
            state: { redirect: true }
          }}>
            <span onClick={() => handleResetOnPageChange(dispatch, vehicle, vehicles.car)} className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.CARS)}</span>
          </Link>
          <Link to={{
            pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.motorcycles}`,
            state: { redirect: true }
          }}>
            <span onClick={() => handleResetOnPageChange(dispatch, vehicle, vehicles.motorcycles)} className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.MOTORCYCLES)}</span>
          </Link>
          <Link to={{
            pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.bike}`,
            state: { redirect: true }
          }}>
            <span onClick={() => handleResetOnPageChange(dispatch, vehicle, vehicles.bike)} className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.BIKES)}</span>
          </Link>
          <Link to={{
            pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.step}`,
            state: { redirect: true }
          }}>
            <span onClick={() => handleResetOnPageChange(dispatch, vehicle, vehicles.step)} className='pointer flexRow alignItemsCenter'><SVGPlayBtn style={{ marginRight: '5px' }} />{t(translations.NAVBAR.SCOOTERS)}</span>
          </Link>
        </Box>
      </Box>
    </>
  )
}

export default Vehicle