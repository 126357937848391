import React from 'react'
import { Box } from '@mui/material';
import { SvgMobileLeftArrow, SvgsideBarToggler } from 'app/components/svgicons/svgNew'
import { useWindowSize } from 'app/Hooks/windowResize';
import { DetailNavbarStyle, StatusBar } from '../AdsStyledSlice'
import { routes } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import TopBarShareLikeComponent from './TopBarShareLikeComponent';

const TopBar = (props: any) => {
    const { onClick, component, show, popup, fixedHeader, itemData } = props;
    const [mobile] = useWindowSize();
    const history = useHistory();
    const previousPath = history.location.state?.referrer || '';

    return (
        <>
            {(mobile || show || popup) && !fixedHeader ?
                <StatusBar className="topBar" style={{ background: 'white' }}>
                    <>
                        <span className='toggler-icon'>
                            {props.icons ?
                                <Box className='icon-style pointer'>
                                    {props.icons}
                                </Box>
                                :
                                <span onClick={() => onClick()}>
                                    {props.iconsFunction ?
                                        <Box className='icon-style pointer'
                                        // sx={{ border: "solid 1px #666666" }}
                                        >
                                            {props.iconsFunction}
                                        </Box>
                                        :
                                        (
                                            // when we come on ads page from user profile , than show a back button on topbar rather than a hamburger   
                                            (previousPath.includes(routes.publicRoutes.public_profile_subisidiary) || previousPath.includes(routes.publicRoutes.public_profile_user)) ?
                                                <div onClick={(e) => { e.stopPropagation(); history.goBack() }}><SvgMobileLeftArrow /></div>
                                                :
                                                <SvgsideBarToggler />
                                        )

                                    }
                                </span>
                            }
                        </span>
                        <span className="title">
                            {props.title}
                        </span>
                        {component ? <div className="right-bar">
                            {component}
                        </div> : null}
                    </>
                </StatusBar>
                : <DetailNavbarStyle>
                    {fixedHeader ?
                        <TopBarShareLikeComponent timer={props.timer} previousPath={previousPath} icons={props.icons} iconsFunction={props.iconsFunction} onClick={onClick} title={props.title} adType={props.adType} component={component} itemData={itemData} />
                        : null}</DetailNavbarStyle>
            }



        </>
    )
}

export default TopBar