import { localstorageKey } from "config/constants"
import { productTypes, vehicles } from "config/variables"

export const Filters = {
  NONE: 0,
  KEY_DATA: 1,
  PRICE: 2,
  EQUIPMENT: 3,
  PERFORMANCE: 4,
  HISTORY: 5,
  SELLER_AND_WARRANTY: 6,
  COUNTRY: 7,
  ELECTRIC: 8,
  PRODUCT: 9
}

export const vehicleTypeStatus = {
  USED: 0,
  NEW: 1
}

export const powerTypeStatus = {
  KW: 0,
  HP: 1
}

export const seller = {
  PRIVATE: 'private_seller',
  DEALER: 'dealer_seller'
}
export const sellerSelected = 1

export const isVAtRefundable = {
  false: 0,
  true: 1
}

export const withPicture = {
  false: 0,
  true: 1
}

export const isServiceHistory = {
  false: 0,
  true: 1
}

export const isVehicleElectric = {
  false: 0,
  true: 1
}

export const MAX_NO_OF_SEATS = 20

export const MAX_NO_OF_BIKE_SEATS = 5

export const MAX_NO_OF_OWNERS = 20

export const MINIMUM_YEAR = 1901

export const MAX_HOURS = 50

export const MAX_MINUTES = 60

export const equimentCategories = {
  'Confort': 1,
  'Security': 2,
  'Media': 3,
  'Luxury': 4
}

export const stringToArray = (state, value) => {
  if (value !== null) {
    if (value.includes(',')) {
      state(value.split(','))
      return
    }
    state([value])
    return
  }
  state([])
}

export const convertSeconds = (hourState, minuteState, ms) => {
  if (ms) {
    let minutes = ms / (60)
    hourState(parseInt(minutes / 60))
    minuteState(minutes % 60)
    return
  }
  hourState(null)
  minuteState(null)
}

export const removeIDfromSlug = (str) => {
  if (str) {
    let arrayofString = str.split("-")
    if (arrayofString.length > 1) {
      arrayofString.pop()
    }
    return arrayofString.join("-")
  }
  return str
}

export const checkKey = (value, product, key) => {

  if (key === 'brand') {
    let brands = []

    if (product == productTypes.car) {
      const filterDataCar = JSON.parse(localStorage.getItem(localstorageKey.carFilterdata) || '{}')
      brands = filterDataCar?.brandList?.items ?? []
    }
    if (product == productTypes.motorcycles) {
      const filterDataMotorbike = JSON.parse(localStorage.getItem(localstorageKey.motorCycleFilterdata) || '{}')
      brands = filterDataMotorbike?.brandList?.items ?? []
    }
    if (product == productTypes.bike) {
      const filterDataBike = JSON.parse(localStorage.getItem(localstorageKey.bikeFilterdata) || '{}')
      brands = filterDataBike?.brandList?.items ?? []
    }
    if (product == productTypes.step) {
      const filterDataStep = JSON.parse(localStorage.getItem(localstorageKey.stepFilterdata) || '{}')
      brands = filterDataStep?.brandList?.items ?? []
    }

    if (brands && brands.length > 0) {
      const existence = brands.findIndex((brand) => removeIDfromSlug(brand.slug) == removeIDfromSlug(value) || removeIDfromSlug(brand.slug) == value)
      if (existence !== -1) {
        return {
          name: 'brand_slug',
          slug: brands[existence].slug
        }
      }
    }
  }


  if (key === "model") {

    const models = JSON.parse(localStorage.getItem('modelList') || '[]')

    if (models && models.length > 0) {
      const existence = models.findIndex((models) => removeIDfromSlug(models.slug) == removeIDfromSlug(value) || removeIDfromSlug(models.slug) == value)
      if (existence !== -1) {
        return {
          name: 'model_slug',
          slug: models[existence].slug
        }
      }
    }

  }

  const countries = JSON.parse(localStorage.getItem('countries') || '[]')

  if (countries && countries.length > 0) {
    const existence = countries.findIndex((country) => country.slug == value)
    if (existence !== -1) {
      return {
        name: 'country_slug',
        slug: countries[existence].slug
      }
    }
  }
}