import { createAction } from 'redux-actions';

// reset states
export const resetPublicProfile = createAction('RESET_REQUEST');
export const resetLoading = createAction('RESET_LOADING');

//  Get Reviews list Action
export const getReviewsRequest = createAction('REVIEWS_REQUEST');
export const getReviewsSuccess = createAction('REVIEWS_SUCCESS');
export const getReviewsFailure = createAction('REVIEWS_FAILURE');

//  Add Reviews Action
export const addReviewsRequest = createAction('ADD_REVIEWS_REQUEST');
export const addReviewsSuccess = createAction('ADD_REVIEWS_SUCCESS');
export const addReviewsFailure = createAction('ADD_REVIEWS_FAILURE');

//  Flag Reviews Action
export const flagReviewsRequest = createAction('FLAG_REVIEWS_REQUEST');
export const flagReviewsSuccess = createAction('FLAG_REVIEWS_SUCCESS');
export const flagReviewsFailure = createAction('FLAG_REVIEWS_FAILURE');

//  Update Reviews Action
export const updateReviewsRequest = createAction('UPDATE_REVIEWS_REQUEST');
export const updateReviewsSuccess = createAction('UPDATE_REVIEWS_SUCCESS');
export const updateReviewsFailure = createAction('UPDATE_REVIEWS_FAILURE');
export const updateRating = createAction('UPDATE_RATING');

//  Get profile details Action
export const getProfileRequest = createAction('GET_PROFILE_REQUEST');
export const getProfileSuccess = createAction('GET_PROFILE_SUCCESS');
export const getProfileFailure = createAction('GET_PROFILE_FAILURE');

//  Follow Action
export const followUnfollowRequest = createAction('FOLLOW_UNFOLLOW_REQUEST');
export const followUnfollowSuccess = createAction('FOLLOW_UNFOLLOW_SUCCESS');
export const followUnfollowFailure = createAction('FOLLOW_UNFOLLOW_FAILURE');

//  Followers list Action
export const followersListRequest = createAction('FOLLOWERS_LIST_REQUEST');
export const followersListSuccess = createAction('FOLLOWERS_LIST_SUCCESS');
export const followersListFailure = createAction('FOLLOWERS_LIST_FAILURE');

//  Following list Action
export const followingListRequest = createAction('FOLLOWING_LIST_REQUEST');
export const followingListSuccess = createAction('FOLLOWING_LIST_SUCCESS');
export const followingListFailure = createAction('FOLLOWING_LIST_FAILURE');
