import React from 'react'
import { Modal } from '@mui/material'
import styled from 'styled-components';
import { PrimaryBtn } from 'app/components/Button/MainBtn';
import { SwitchButton } from 'app/components/stylesComponents/SwitchButton';
import { color, colorNew } from 'styles/StyleConstants';
import { useTranslation } from 'react-i18next';
import { lan } from 'locales/multiPageTranslation/adsLan'
import { numberFormat } from 'config/variables';


function ConfirmationBidding(props: any) {
    const { showConfirmPopupHandle, createBidConfirm, showConfirmPopup, selectedPrice, bidPopHide, bidHidePopHandler } = props;
    const { t } = useTranslation()

    return (
        <>
            <Modal
                open={showConfirmPopup}
                onClose={showConfirmPopupHandle}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <PopWrapper className="rounded bg-white">
                    <div className="body-pop">
                        <p className="text-center"> {t(...lan.makeABidConfirmation())}:</p>
                        <h3 className="price"> € {numberFormat(selectedPrice)}</h3>
                        <div className="button-s">
                            <PrimaryBtn onClick={showConfirmPopupHandle} className="primary-outline fw-normal pointer">{t(...lan.cancel())}</PrimaryBtn>
                            <PrimaryBtn onClick={() => createBidConfirm(selectedPrice)} className="primary-bg fw-normal pointer">{t(...lan.confirm())}</PrimaryBtn>
                        </div>
                    </div>
                    <div className="footer-pop">
                        <SwitchButton className="sm me-2">
                            <input type="checkbox" id="carDifferentiellock" checked={bidPopHide} onChange={(e) => {
                                bidHidePopHandler(!bidPopHide)

                            }} />
                            <span className="slider round"></span>
                        </SwitchButton>
                        <span className="">{t(...lan.doNotAsk())}.</span>
                    </div>
                </PopWrapper>
            </Modal>
        </>
    )
}
export default ConfirmationBidding;
export const PopWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 255px;
    background-color: ${colorNew.colorWhite};
    border-radius: 8px;
    text-align: center;
    .body-pop{
        padding: 20px 15px;
        .price{
            color: ${colorNew.primaryColor};
            font-size: 38px;
            margin: 0 0 15px 0;
        }
        .button-s{
            display: flex;
            justify-content: center;
            gap: 20px;
        }
    }
    .footer-pop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding: 20px 15px;
        gap: 20px;
        border-top: 1px solid ${colorNew.colorGrayDark}
    }
    .footer-pop span{
        text-align: left;
    }
    /* bgcolor: background.paper;
    border: 2px solid #fff;
    boxShadow: 24;
    borderRadius: 8px;
    padding: "20px 15px";
    .border-top{
        border-top: 1px solid ${color.colorGray};
    } */
    
    @media (max-width: 598px) {
        .button-s{
           flex-direction:column;
           gap:5px;
        }
        .footer-pop{
            label{
                width:63px !important;
            }
        }
      }
`