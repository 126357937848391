import { Box } from '@mui/material'
import FooterLinks from './footerLinks'
import FooterMenu from './footerMenu'
import LanguageSwitch from '../Components/languageSwitch'

const Footer = () => {

    return (
        <Box sx={{
            borderTop: '1px solid #1F2F95',
            background: 'linear-gradient(180deg, rgb(17 29 109) 6.06%, rgb(39, 53, 144) 65.47%);',
        }}>
            <LanguageSwitch />
            <FooterMenu />
            <FooterLinks />
        </Box>
    )
}

export default Footer