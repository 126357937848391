import { Box } from '@mui/material'
import useAuctionEnd from 'app/Hooks/useAuctionEnd';
import { getUtcTime, useCountdown } from 'app/Hooks/useCoundown';
import Timer from 'app/components/AuctionTimer/timer';
import { hotDealAdStatus } from 'config/constants';
import { animationType, auctionEndAnimationTime } from 'config/variables';
import moment from 'moment';
import React, { useEffect, useState } from 'react'

const Counter = ({ adDetail, endAd, makeAdAvailable, _isContestEnding, _contestEndingMessage }) => {

  const [timer, _timer] = useState<string>('')
  const [updateTimer, _updateTimer] = useState<boolean>(false)

  const [days, hours, minutes, seconds] = useCountdown(timer);

  const _isHotDealEnding = () => {
    return adDetail?.hotdeal?.start && adDetail?.end_hotdeal && (!getUtcTime().isBefore(moment(adDetail.end_hotdeal.start))) && (!getUtcTime().isBefore(moment(adDetail.end_hotdeal)));
  }

  const isAuctionEnding = _isHotDealEnding();
  const [contestEnding, endingMessage] = useAuctionEnd(days, hours, minutes, seconds, adDetail, isAuctionEnding, endAd, auctionEndAnimationTime.endingTime, auctionEndAnimationTime.endedTime, animationType.hotdeal);


  // Setting counter time and check if auction is active or not
  useEffect(() => {
    if (adDetail && Object.keys(adDetail).length > 0) {
      if (adDetail.ad_status === hotDealAdStatus.sold) {
        _timer(null)
        return
      }
      const startin = new Date(adDetail.start_hotdeal).getTime();
      const endTimeTemp = adDetail.end_hotdeal;
      const currentTime = new Date().getTime();

      // initially counter will be of start time
      let counterTime = adDetail.start_hotdeal;

      // Sets active as true and counter time as end auction time if auction has started ( start is greater than current time )
      if (currentTime > startin) {
        counterTime = endTimeTemp;
        if (adDetail.ad_status === null) {
          makeAdAvailable()
        }
      }
      _timer(counterTime)
    }
  }, [adDetail.end_hotdeal, updateTimer])

  useEffect(() => {
    // Function that changes timer from hotdeal start to hotdeal end when counter ends
    const resetTimer = () => {
      if (days == '00' && hours == '00' && minutes == '00' && seconds == '00') {
        if (adDetail && Object.keys(adDetail).length > 0) {
          const time = new Date(timer).getTime();
          setTimeout(() => {
            const currentTime = new Date().getTime();
            if (currentTime > time) {
              _updateTimer(true)
            }
          }, 1000)
        }
      }
    }
    resetTimer()
  }, [seconds, hours, minutes, days])



  useEffect(() => {
    _contestEndingMessage(endingMessage)
  }, [endingMessage])

  useEffect(() => {
    _isContestEnding(contestEnding)
  }, [contestEnding])

  return (
    <Box className='timer'>
      <Timer days={days} hours={hours} minutes={minutes} seconds={seconds} />
    </Box>
  )
}

export default Counter