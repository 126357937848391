import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const lan = {
  home: () =>_t(translations.createCompanyScreen.HOME),
  myProfile: () => _t(translations.profilePrivateSideBar.MYPROFILE),
  editProfile: () => _t(translations.profileScreen.PROFILEEDIT),
  vatId: () => _t(translations.profileScreen.VATID),
  totalMembers: () => _t(translations.profileScreen.TOTALMEMBERS),
  total: () => _t(translations.profileScreen.TOTAL),
}