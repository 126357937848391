import { handleActions } from 'redux-actions';
import { createLicensePlateFailure, createLicensePlateRequest,createLicensePlateSuccess, deleteLicensePlateFailure, deleteLicensePlateRequest, deleteLicensePlateSuccess, findableForPublicFailure, findableForPublicRequest, findableForPublicSuccess, getVehicleLicensePlateListFailure, getVehicleLicensePlateListRequest, getVehicleLicensePlateListSuccess, getVehicleListFailure, getVehicleListRequest, getVehicleListSuccess } from 'store/actions/licensePlate-action';
const initialState = {
  licensePlateloading:false,
  licensePlateData:{},
  licensePlateError:{},
  vehicleList:[],
  vehicleListLoading:false,
  vehicleListLastPage:0,
  vehicleListError:{},
  VehicleLicensePlateListLoading:false,
  VehicleLicensePlateList:[],
  VehicleLicensePlateError:{},
  VehicleLicensePlateListLastPage:0,
  deletePlateError:{},
  deletePlateLoading:false,
  deletePlateResponse:{},
  searchableLoading:false,
  findableForPublicError:{},
  findableForPublicResponse:{}
}
const licensePlate = handleActions({
 // redux for create license plate number
  [createLicensePlateRequest]: state => ({
    ...state,
    licensePlateloading: true,
  }),
  [createLicensePlateSuccess]: (state, { payload }) => ({
    ...state,
    licensePlateloading:false,
    licensePlateData: payload?.response ? payload.response.item : {},
  }),
  [createLicensePlateFailure]: (state, { payload }) => ({
    ...state,
    licensePlateloading:false,
    licensePlateError: payload.data ? payload.data : {},
  }),
   // Get vehicle list withOut license plate 
  [getVehicleListRequest]: state => ({
    ...state,
    vehicleListLoading: true,
  }),
  [getVehicleListSuccess]: (state, { payload }) => ({
    ...state,
    vehicleListLoading:false,
    vehicleList: payload.data.current_page === 1 ? payload.data.data : [...state.vehicleList, ...payload.data.data],
    vehicleListLastPage:payload.data.last_page
  }),
  [getVehicleListFailure]: (state, { payload }) => ({
    ...state,
    vehicleListLoading:false,
    vehicleListError: payload.data,
  }), 
  // Get vehicle list with license plate 
  [getVehicleLicensePlateListRequest]: state => ({
    ...state,
    VehicleLicensePlateListLoading: true,
  }),
  [getVehicleLicensePlateListSuccess]: (state, { payload }) => ({
    ...state,
    VehicleLicensePlateListLoading:false,
    VehicleLicensePlateList: payload.current_page === 1 ? payload.data : [...state.VehicleLicensePlateList, ...payload.data],
    VehicleLicensePlateListLastPage:payload.last_page
  }),
  [getVehicleLicensePlateListFailure]: (state, { payload }) => ({
    ...state,
    VehicleLicensePlateListLoading:false,
    VehicleLicensePlateError: payload.data,
  }),

 // redux for licenseplate searchable or not
 [findableForPublicRequest]: state => ({
  ...state,
  searchableLoading: true,
}),
[findableForPublicSuccess]: (state, { payload }) => ({
  ...state,
  searchableLoading:false,
  findableForPublicResponse: payload ? payload : {},
}),
[findableForPublicFailure]: (state, { payload }) => ({
  ...state,
  searchableLoading:false,
  findableForPublicError: payload.data,
}),

// Delete License plate
[deleteLicensePlateRequest]: state => ({
  ...state,
  deletePlateLoading: true,
}),
[deleteLicensePlateSuccess]: (state, { payload }) => ({
  ...state,
  deletePlateLoading:false,
  deletePlateResponse: payload,
}),
[deleteLicensePlateFailure]: (state, { payload }) => ({
  ...state,
  deletePlateLoading:false,
  deletePlateError: payload.data,
}),

},initialState);

export default licensePlate;
