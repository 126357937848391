import { Box, Typography } from '@mui/material'
import React from 'react'


// component which shows auction end popup
function AuctionEndAnimation({ endingMessage }) {
    return (
        <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255,255,255,0.8)',
            textAlign: "center",
            padding: "8px"
        }}>
            <Typography sx={{ color: 'black', fontSize: '30px', fontWeight: 500 }}>{endingMessage}</Typography>
        </Box>
    )
}

export default AuctionEndAnimation