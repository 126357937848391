import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const PublicAdsWrapper = styled.div`
span{
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #000;
}
input:focus{
  outline: none;
}
.filterBox{
  width : 20%;
  position: fixed;
  height: calc(100vh - 155px);
  display : block;
}
.listContainer{
  width : 80%;
  /* max-width : calc(100% - 292px); */
  margin-left: 20%;
}
.adsListing > div:last-child{
  margin-bottom:75px;
}
.endTime span{
  color: #EC1C24;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.startTime span{
  color: #000;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.startTime .time{
  margin-left : 10px;
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.endTime .time{
  margin-left : 10px
}
.homeMainContainer{
  max-width: 2760px;
  width: 100%;
  margin: auto;
}
.scrollTop{
  position : fixed;
  background : #e3e3e3;
  width : 50px;
  height : 50px;
  bottom : 90px;
  right : 25px;
  border-radius : 5px;
  display: flex;
  align-items: center;
  justify-content:center;
}
.scrollTop svg{
  transform: rotate(90deg);
}
// -------------------------------------------------Header-------------------------------------------------
.top-heading {
  height: 45px;
  position: relative;
  align-items: center;
  padding: 0 10px;

}
.top-heading > .back-to-page {
  position: absolute;
  left: 15px;
  top: 20px;
}
.header{
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.42px;
  margin : 0;
  width: 100%;
}
.headerCount{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin: 0;
}
.searchBox{
  border-bottom :1px solid #CCC;
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.searchBox svg{
  width : 15px
}
.searchBox input{
  color: var(--text-deep-gray, #666);
  font-size: 16px;
  border: none;
  min-height: 40px;
  max-width: 150px;
}
.sortBox{
  display : flex;
  align-items: center;
}
.sortBox span{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-right:5px;
}
.sortBox select{
  cursor: pointer;
  color: var(--text-deep-gray, #666);
  border: 1px solid #CCC;
  background : white;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  min-height: 40px;
  max-width: 150px;
}
.filterIcon{
  margin-left: 5px;
  width: 30px;
  height: 30px;
  background : #6579F4;
  padding :2px;
  border-radius : 5px;
}
.filterIcon svg path{
  fill : white;
}
.mobileSearchBox{
  width : 100%;
  gap: 5px;
}
.mobileSearchBox svg{
  height : 20px;
}
.mobileSearchBox span{
  color : ${colorNew.colorGrayOnButton};

}
.mobileSearchBox input{
  width : 100%;
  height: 40px;
  border : none;
  font-size: 18px;
  background: #F8F8F8;
  color : ${colorNew.colorGrayOnButton};
}

// -------------------------------------------------Ads-------------------------------------------------
.adDetailContainer{
  width: 100%;
  padding:0 10px;
  display:flex;
  flex-direction:column;
  justify-content:start;
  gap:8px;
}
.imageContainer{
  position : relative;
  max-width : 285px;
  min-width : 285px;
  img{
    border-radius:4px;
  }
}
.adsListing{
  overflow:auto;  
  padding: 0px 15px;
}
.mainContainer{
  position : relative;
  width : 100%; 
  margin-top : 15px;
  border : 1px solid #F2F2F2;
  border-radius:6px;
  padding:25px;
  justify-content : space-between;
  min-width:1024px;
}

.adContainer{
  width : 70%; 
  min-width:70%;
  margin-top:16px;
 
}
.taxInclusive{ 
  color:#666;
  width:max-content !important; 
  min-width:max-content !important;
  text-align: center; 
  font-size: 13px; 
  font-weight: 400; 
  display:flex;
  align-items:center;
  svg{
    margin-left:5px;
  }
}
.bidContainer{ 
  padding: 10px;
  height:fit-content; 
  .heading{
    font-size:18px;
    font-weight:600;
    padding-top:0;
  }
  .permission-text{
    font-size:15px;
    font-weight:400;
    color:#666;
  }
}
.bidTimer{
  border-left:1px solid #E5E5E5;
  padding:0 0 0 8px; 
  &.switchProfile{
    margin-bottom:10px;
  }
}
.adContainerLoader{
  max-width : 70%;
  min-width: 700px;
  text-align : center;
}
.adContainerLoader svg circle{
  stroke : #6579F4;
}
.ad-name{ 
  margin : 0px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-wrap:wrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.priceMainContainer{
  max-width : 470px

}
.priceContainer{
  display:flex; 
  flex-wrap:wrap;
  gap:5px;
  align-items:start; 
  .text-center{
    width:90px; 
  }  
  .price-wrap{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:start;
    gap:5px;
    flex-wrap:wrap;
  }
}
.myBid{
  display:flex;
  flex-direction:row;
  align-items:center;
  gap:5px;
  padding:0 10px ;
  width:max-content;
  background:#999999;
  color:white;
  
  span{
    width:max-content;
    color:white; 
    font-size:15px;
    font-weight:400;
  }
  height:24px;
}
.trophy
{
  display:flex;
  svg{
    margin-right:5px;
    width:16px;
  }
} 
.priceHeader{
  color: var(--text-deep-gray, #666);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.price{
  display: flex;
  align-items: center;
  color: #363B39;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.42px;
  margin : 5px 0
}
.hiddenPrice{
  font-size: 12px;
  margin-bottom: 3px;
  margin-left: 5px;
}


// --------------------------------------Bid Component --------------------------------------- 
.bidContainer{
  min-width : 180px;
}
.bidHeader{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
}
.bidInputBox{
  margin : 12px 0; 
  align-items:end;
}
.bidInputBox span{
  color: var(--text-medium-gray, #999);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px; 
}
.bidInputBox .inputwrapper{
  border-bottom : 1px solid #E5E5E5;
  align-items:end;  
}

.bidInputBox input{
  width : 100%; 
  height : 30px; 
  border:none;
  color: var(--text-medium-gray, #666);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  margin-left : 10px; 
  background:white;
}
.currency{
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  padding:5px 0 !important;
}
.disabledBtn{
  background : rgb(158, 158, 158) !important;
  cursor : not-allowed !important;
  pointer-events: auto !important;
}
.disabledText{
  color : rgb(158, 158, 158) !important;
  cursor : not-allowed !important;
}
.submitBtn{
  height: 40px;
  font-size: 15px;
  color: #fff;
  font-weight:500;
  background : ${colorNew.primaryColor};
  text-transform : none; 
}
 
.autoBid{
  width : 40%
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  gap:8px;
}
.bidSubHeader{
  color: var(--text-deep-gray, #666);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width:max-content;
  width:max-content !important;
  letter-spacing: 0.3px;
}
.btnloader{
  height : 20px !important;
  width : 20px !important;
  color: #fff !important;
}
.loader{
  height : 15px !important;
  width : 15px !important;
  color: rgb(97, 122, 247) !important;
}
.bids{
  cursor : pointer;
  color: #617AF7;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  border-radius: 29px;
  background: #F7F7F7;
  padding : 5px;
  margin : 2px 0;
}
.toggle_icon span{
  color : white;
}
.autoBidText{
  white-space: nowrap;
  background: rgb(97, 122, 247);
  color: rgb(255, 255, 255);
  font-size: 13px;
  padding: 2px 5px;
  border-radius: 2px;
  height:fit-content;
  margin:0 5px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnText{
  color : white;
}


.hideTime {
  transition: 0.4s ease-in-out;
    transform: scaleY(0);
    visibility: hidden;
    left: 0;
    transform-origin: 0 0;
    position: absolute !important;
    right: 0;
    top: 60px;
    z-index: -999;
    transform: translateY(-300px);
    opacity: 0;
   
}

.showTime {
  transition: 0.4s ease-in-out;
    // position: absolute !important;
    right: 0;
   top: 60px;
    left:0;
    transform: scaleY(1);
    z-index:99999;
     transform-origin: 0 0;
    background:#ffffff;
    z-index: -999;
    opacity: 1;
    /* padding-bottom:30px; */
}
 

// --------------------------------------Media Query ---------------------------------------
@media (min-width :992px) and (max-width :1200px){
  .imageContainer {
    max-width: 300px;
    min-width: 300px;
  }
  .filterBox{
    width : 26%;
  }
  .listContainer{
    width : 74%;
    margin-left: 26%;
  }
  .adContainer {
    width: 90%;
    min-width: 600px;
  }
  .timer div {
    padding: 0px 6px;
  }
  .timer div span{
    font-weight :500;
  }
  .counter {
    top: 156px;
}
}
@media (min-width :769px) and (max-width :992px){
  .filterBox{
    position: sticky;
  }
  .listContainer{
    margin-left: 0 !important;
  }
}
@media (max-width :992px){
  .adsListing {padding: 0}
  .header {text-align: center}
  .bidTimer{
    border-left:none;
    padding-left:0;
    &.switchProfile{
      margin-bottom:0;
    } 
  }
  .mainContainer{
    flex-wrap : wrap;
    min-width:auto;
    padding:10px 0 0 0 !important; 
    &>div{
      padding:0 10px;
    }
  }
  .filterBox{
    width : 40%;
  }
  .listContainer{
    width : 60%;    
    margin-left: 40%;

  }
  .adContainer{
    align-items: start;
    width : 100%;
    max-width : 100%;
    min-width : 100%;
    flex-direction:row;
    &>.imageContainer{
      min-width:40%;
      max-width:40%;
    }
    .adDetailContainer{
      min-width:60%;
      max-width:60%;
    }
  }
  .trophy svg{
    width:13px;
  }
  .autoBidText{
    font-size:11px;
    height:max-content;
  }
  .divider{
    margin-top:10px;
    width:100%;
    background:#6579F4;
  }
  .bidHeader{
    font-size:15px;
    font-weight:700;
    color:black;
  }
  .imageContainer{
    min-width:100%;
    max-width:100%;
  }
  
  .adContainerLoader{
    width : 100%;
    max-width : 100%;
    min-width : 100%;
  }
  .adDetailContainer{
    width : 100%;
    padding:0 10px ;
  }
  .currentPrice{
    color:#666;
    font-size:15px;
    font-weight:400;
  }
  .share-icon{ 
    bottom:6px  !important;
    right:6px  !important; 
    width:26px !important;
    height:26px !important;
    padding:0 3px !important;
  }
  .myBid{
    flex-direction:column;
    background:white; 
    gap:0;
    padding:0;
    .priceHeader{
      color:#666666;
    }
    .price{
      color:#363B39;
    }
  }
  .bidContainer{
    width : 100%; 
  }
  .bidInputBox{
    width : calc(100% - 90px);
    border-bottom:1px solid #e5e5e5;
    input{
      border-bottom:none;
    }
  }

  .ad-name{
    width:100%;
    font-size:15px;
    font-weight:400;
    -webkit-line-clamp: 3;
  }
   
  .bidList{
    display :flex;
    justify-content : space-between;
  } 
  .bids{
    width : 30%;
  }
  .auctionTimerMobileContainer{
    display : flex;
    justify-content : space-between;
    padding: 10px 16px;
  }
  .submitBtn{
    width :78px;
    font-size: 12px;
    color: #fff;
    background : ${colorNew.primaryColor};
    text-transform : none; 
  }
  .priceContainer{
    gap:0;
    &>* {
      display:flex;
      align-items:center;
      gap:5px;
    }
  }
  .priceMainContainer{
    max-width : 100%;
    display: flex;
    justify-content: space-between; 
    width: 100%;
    margin-top: 10px;
  }
  .btn-wrapper button{
    padding:12px; 
    height:36px;
  }
  .price-wrap{
    .price{
      font-size:21px;
      font-weight:600;
    }
  }
  .counter {
    top: 156px;
}
}
@media (max-width :768px){
  
  .adContainer:first-child{
     margin-top:0;
  }
  .adsListing {
    padding: 0 10px;
  }
  .filterBox{
    width : 0%;
    min-width:0px;
    display: none;
  } 
  .listContainer{
    width : 100%;
    max-width: 100%;
    margin-left: 0px;   
  }
  .ad-name{
    width:100%;
  }
  .bidContainer{
    margin-bottom:0;
    padding:0;
    padding-top:10px;
  }
  .auctionActionBtn{
    margin-bottom:20px;
  }
  .userInfoContainer{
    margin-top:16px;
    border:none;
    background: #FBFBFB;
    border-radius: 6px;
    padding:10px 0;
    .profileBadge{
      left:0;
      top:-20px;
    }
    .name{
      font-size:13px;
      font-weight:400;
    }
    .button-wrapper button{
      height:36px !important;
      padding:5px;
    }
  }
  .mainContainer{
    flex-wrap : wrap;
    min-width:auto;
    padding:10px 0 0 0 !important;
  }
  .imageContainer {
    max-width: 300px;
    min-width: 300px;
    img{
      border-radius:2px;
    }
  }
  .listContainer{
    width : 100%;
    max-width: 100%;
  }
  .timer div {
    padding: 0px 6px;
    width: 34px;
    height: 33px;
  }
  .timer div span{
    font-weight :500;
  }
  .counter {
    top: 156px;
  }
  .auctionTimerMobileContainer div span{
    display : block;
  }
  .time{
    margin-left : 0px !important;
  }
  .bids{
    font-size : 12px;
  }
  .loader{
    height : 12px !important;
    width : 12px !important;
  }
}

@media (max-width :575px){
  .imageContainer {
    max-width: 100%;
    min-width: 100%;
  }
  .counter{
    top: inherit !important;
    bottom: 10px !important;
  }
}
`