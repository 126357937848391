import { handleActions } from 'redux-actions';
import { getAuctionAdsHistoryFailure, getAuctionAdsHistoryRequest, getAuctionAdsHistorySuccess, getMyActionRequiredAdsFailure, getMyActionRequiredAdsRequest, getMyActionRequiredAdsSuccess, getMyClassicAdsFailure, getMyClassicAdsRequest, getMyClassicAdsSuccess, getMyHotdealAdsFailure, getMyHotdealAdsRequest, getMyHotdealAdsSuccess, getMyLiveAuctionAdsFailure, getMyLiveAuctionAdsRequest, getMyLiveAuctionAdsSuccess, getMyParkedAdsFailure, getMyParkedAdsRequest, getMyParkedAdsSuccess, resetMyAds, selectCustomer, sellReserveVehicleFailure, sellReserveVehicleRequest, sellReserveVehicleSuccess, updateAdsStatus } from 'store/actions/myAds-actions';

const initialState = {
  myClassicAdsList: [],
  myClassicAdsCount: 0,
  myClassicAdsListCurrentPage: 1,
  myClassicAdsListLastPage: null,
  myClassicAdsListError: {},
  myParkedAdsList: [],
  myParkedAdsCount: 0,
  myParkedAdsListCurrentPage: 1,
  myParkedAdsListLastPage: null,
  myParkedAdsListError: {},
  myLiveAdsList: [],
  myLiveAdsCount: 0,
  myLiveAdsListCurrentPage: 1,
  myLiveAdsListLastPage: null,
  myLiveAdsListError: {},
  myActionRequiredAdsList: [],
  myActionRequiredAdsCount: 0,
  myActionRequiredAdsListCurrentPage: 1,
  myActionRequiredAdsListLastPage: null,
  myActionRequiredAdsListError: {},
  myAdsHistoryList: [],
  myAdsHistoryCount: 0,
  myAdsHistoryListCurrentPage: 1,
  myAdsHistoryListLastPage: null,
  myAdsHistoryListError: {},
  myHotdealAdsList: [],
  myHotdealAdsCount: 0,
  myHotdealAdsListCurrentPage: 1,
  myHotdealAdsListLastPage: null,
  myHotdealAdsListError: {},
  loadingMyAds: true,
  selectedCustomer: {},
  vehicleTradeSuccess: {},
  vehicleTradeFailure: {},
}

const myAds = handleActions(
  {
    [getMyClassicAdsRequest]: state => (
      {
        ...state,
        loadingMyAds: true
      }),
    [getMyClassicAdsSuccess]: (state, { payload }) => (
      {
        ...state,
        myClassicAdsListLastPage: payload.my_ads.last_page,
        myClassicAdsListCurrentPage: payload.my_ads.current_page,
        myClassicAdsCount: payload.my_ads.total,
        myClassicAdsList: payload.my_ads.current_page === 1 ? payload.my_ads.data : [...state.myClassicAdsList, ...payload.my_ads.data],
        loadingMyAds: false
      }),
    [getMyClassicAdsFailure]: (state, { payload }) => (
      {
        ...state,
        myClassicAdsListError: payload.data,
        loadingMyAds: false
      }),
    [getMyParkedAdsRequest]: state => (
      {
        ...state,
        loadingMyAds: true
      }),
    [getMyParkedAdsSuccess]: (state, { payload }) => (
      {
        ...state,
        myParkedAdsListLastPage: payload.owner_vehicles.last_page,
        myParkedAdsListCurrentPage: payload.owner_vehicles.current_page,
        myParkedAdsCount: payload.owner_vehicles.total,
        myParkedAdsList: payload.owner_vehicles.current_page === 1 ? payload.owner_vehicles.data : [...state.myParkedAdsList, ...payload.owner_vehicles.data],
        loadingMyAds: false
      }),
    [getMyParkedAdsFailure]: (state, { payload }) => (
      {
        ...state,
        myParkedAdsListError: payload.data,
        loadingMyAds: false
      }),
    [getMyLiveAuctionAdsRequest]: state => (
      {
        ...state,
        loadingMyAds: true
      }),
    [getMyLiveAuctionAdsSuccess]: (state, { payload }) => (
      {
        ...state,
        myLiveAdsListLastPage: payload.my_ads.last_page,
        myLiveAdsListCurrentPage: payload.my_ads.current_page,
        myLiveAdsCount: payload.my_ads.total,
        myLiveAdsList: payload.my_ads.current_page === 1 ? payload.my_ads.data : [...state.myLiveAdsList, ...payload.my_ads.data],
        loadingMyAds: false
      }),
    [getMyLiveAuctionAdsFailure]: (state, { payload }) => (
      {
        ...state,
        myLiveAdsListError: payload.data,
        loadingMyAds: false
      }),
    [getMyActionRequiredAdsRequest]: state => (
      {
        ...state,
        loadingMyAds: true
      }),
    [getMyActionRequiredAdsSuccess]: (state, { payload }) => (
      {
        ...state,
        myActionRequiredAdsListLastPage: payload.my_ads.last_page,
        myActionRequiredAdsListCurrentPage: payload.my_ads.current_page,
        myActionRequiredAdsCount: payload.my_ads.total,
        myActionRequiredAdsList: payload.my_ads.current_page === 1 ? payload.my_ads.data : [...state.myActionRequiredAdsList, ...payload.my_ads.data],
        loadingMyAds: false
      }),
    [getMyActionRequiredAdsFailure]: (state, { payload }) => (
      {
        ...state,
        myActionRequiredAdsListError: payload.data,
        loadingMyAds: false
      }),
    [getAuctionAdsHistoryRequest]: state => (
      {
        ...state,
        loadingMyAds: true
      }),
    [getAuctionAdsHistorySuccess]: (state, { payload }) => (
      {
        ...state,
        myAdsHistoryListLastPage: payload.my_ads.last_page,
        myAdsHistoryListCurrentPage: payload.my_ads.current_page,
        myAdsHistoryCount: payload.my_ads.total,
        myAdsHistoryList: payload.my_ads.current_page === 1 ? payload.my_ads.data : [...state.myAdsHistoryList, ...payload.my_ads.data],
        loadingMyAds: false
      }),
    [getAuctionAdsHistoryFailure]: (state, { payload }) => (
      {
        ...state,
        myAdsHistoryListError: payload.data,
        loadingMyAds: false
      }),
    [getMyHotdealAdsRequest]: state => (
      {
        ...state,
        loadingMyAds: true
      }),
    [getMyHotdealAdsSuccess]: (state, { payload }) => (
      {
        ...state,
        myHotdealAdsListLastPage: payload.last_page,
        myHotdealAdsListCurrentPage: payload.current_page,
        myHotdealAdsCount: payload.total,
        myHotdealAdsList: payload.current_page === 1 ? payload.data : [...state.myHotdealAdsList, ...payload.data],
        loadingMyAds: false
      }),
    [getMyHotdealAdsFailure]: (state, { payload }) => (
      {
        ...state,
        myHotdealAdsListError: payload.data,
        loadingMyAds: false
      }),
    [sellReserveVehicleRequest]: state => (
      {
        ...state,
        loadingMyAds: true
      }),
    [sellReserveVehicleSuccess]: (state, { payload }) => (
      {
        ...state,
        vehicleTradeSuccess: payload,
        loadingMyAds: false
      }),
    [sellReserveVehicleFailure]: (state, { payload }) => (
      {
        ...state,
        vehicleTradeFailure: payload.data,
        loadingMyAds: false
      }),
    [selectCustomer]: (state, { payload }) => (
      {
        ...state,
        selectedCustomer: payload
      }),
    [updateAdsStatus]: (state, { payload }) => {
      const classicAds = [...state.myClassicAdsList]
      const existence = classicAds.findIndex((vehicle) => vehicle.id == payload.id)
      if (existence !== -1) {
        classicAds[existence].latest_ad_vehicle.is_online = payload.status
      }
      return {
        ...state,
        myClassicAdsList: classicAds
      }
    },
    [resetMyAds]: (state, { payload }) => (
      {
        ...state,
        ...initialState
      })
  },
  initialState,
)

export default myAds;