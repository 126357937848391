import { Box, Popover } from '@mui/material'
import { SvgBlock, SvgImport, SvgMail, SvgMute, SvgUnmute } from 'app/components/svgicons/svg2';
import { SvgFillerIcon } from 'app/components/svgicons/svgNew';
import { translations } from 'locales/translations';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { ChatWrapper } from '../../style/chatStyle';
import { Nullable, chatList } from '../../interface/interface';
import { type } from 'os';
import { SvgCheckDark } from 'app/components/svgicons/svg';
import * as ChatConstants from "config/chatConfig"
import { getArchivedChatRequest, getBlockedChatRequest, muteAllChatRequest, particularChatSuccess, unreadAllChatRequest } from 'store/actions/chat-action';
import { useDispatch, useSelector } from 'react-redux';


const MoreOptions = ({ chatRef, setChatType, chatType, isAllReadStatus, isAllMuteStatus,setSelectedChatID ,setChatId }:chatList) => {

  const { t } = useTranslation()

  const { archivedChatLoaded, blockedChatLoaded } = useSelector((state) => state.chat)

  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLButtonElement>>(null);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dispatch = useDispatch()


  const handleChatType = (chatType) => {
    setChatId(null)
    dispatch(particularChatSuccess({}))
    if(chatType===ChatConstants.chatType.BLOCKED && !blockedChatLoaded){
      dispatch(getBlockedChatRequest({ page: 1 }))
    }
    setSelectedChatID(false)
    setChatType(chatType)
    handleClose()
  }

  // Actions
  const handleReadUnread =() =>{
    let data = {
      is_read: isAllReadStatus
  }
    dispatch(unreadAllChatRequest(data))
    handleClose()
  }

  const handleMuteUnmute =() =>{
    let data = {
      is_muted: isAllMuteStatus
  }
    dispatch(muteAllChatRequest(data))
    handleClose()
  }

  return (
    <>
      <Box>
          <span onClick={handleClick}><SvgFillerIcon className='pointer' /></span>
          {chatType!==ChatConstants.chatType.ALL_MESSAGES&&<span className='icon-dot'></span>}
      </Box>
      
      <Popover
        className='PopOver'
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => handleClose()}
        sx={{
          '.MuiPopover-paper': {
            top: '208px !important',
            left: '106px !important',
            width: chatRef.current ? chatRef.current.offsetWidth : "28.5% !important"
          }
        }}
      >
        <ChatWrapper>
          <Box
            className='option-wrapper'
          >
            {chatType === ChatConstants.chatType.ALL_MESSAGES && <Box className='chatOptionsMobileOptionsContainer flexColumn'>
              <Box className='flexRow optionContainer' onClick={handleReadUnread}>
                <Box className='iconContainer'>
                  <SvgMail className='pointer' />
                </Box>
                <Box className='filterTextContainer'>
                  <span>{!isAllReadStatus?t(translations.CHAT.MARK_ALL_AS_UNREAD):t(translations.CHAT.MARK_ALL_AS_READ)}</span>
                </Box>
              </Box>
              <Box className='flexRow optionContainer' onClick={handleMuteUnmute}>
                <Box className='iconContainer'>
                  {!isAllMuteStatus?<SvgUnmute className='pointer' />:<SvgMute className='pointer' />}
                </Box>
                <Box className='filterTextContainer'>
                  <span>{!isAllMuteStatus?t(translations.CHAT.UNMUTE_ALL):t(translations.CHAT.MUTE_ALL)}</span>
                </Box>
              </Box>
            </Box>}
            <Box className='chatOptionsMobileOptionsContainer flexColumn'>
              <Box className='flexRow optionContainer' onClick={() => handleChatType(ChatConstants.chatType.ALL_MESSAGES)}>
                <Box className='iconContainer'>
                  <SvgMail className='pointer' />
                </Box>
                <Box className='filterTextContainer'>
                  <span> {t(translations.CHAT.ALL_MESSAGE)}</span>
                  {chatType === ChatConstants.chatType.ALL_MESSAGES && <SvgCheckDark />}
                </Box>
              </Box>
              <Box className='flexRow optionContainer' onClick={() => {
                handleChatType(ChatConstants.chatType.ARCHIVED)
                }}>
                <Box className='iconContainer'>
                  <SvgImport className='pointer' />
                </Box>
                <Box className='filterTextContainer'>
                  <span> {t(translations.CHAT.ARCHIVED)}</span>
                  {chatType === ChatConstants.chatType.ARCHIVED && <SvgCheckDark />}
                </Box>
              </Box>
              <Box className='flexRow optionContainer' onClick={() => {
                handleChatType(ChatConstants.chatType.BLOCKED)}}>
                <Box className='iconContainer'>
                  <SvgBlock className='pointer' />
                </Box>
                <Box className='filterTextContainer'>
                  <span> {t(translations.CHAT.BLOCKED)}</span>
                  {chatType === ChatConstants.chatType.BLOCKED && <SvgCheckDark />}
                </Box>
              </Box>
            </Box>
          </Box>
        </ChatWrapper>
      </Popover>
    </>
  )
}

export default MoreOptions