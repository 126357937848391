import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const MyVehicleSaleWrapper = styled.div`
span{
  font-family: Inter;
  font-style: normal;
  line-height: normal;
}
.container{
  padding :0 16px 0 16px;
}
.rightContainer{
  padding :16px;
  height :100%;
}
.pageHeader{
  height: 55px;
  display: flex;
  justify-content: space-between;
}
.pageHeader h1{
  color: #000;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.42px;
}
.userAndVehicleDetails{
  width : 30%;
}
.pricingAndSalesDetails{
  width : calc(70% - 16px);
  border: 1px solid #F2F2F2;
  border-radius: 6px;
}
.userDetailsContainer{
  width : 100%;
  border-radius: 6px;
  box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.04);
  padding : 16px;
  display : flex;
  flex-direction :row;
  justify-content : space-between;
}
.userDetails{
  width : 80%;
}
.userImages{
  position:relative;
}
.userInfo{
  padding : 0 16px;
  gap:5px;
}
.userImage{
  width: 40px;
  height: 40px;
  border-radius : 50%;
}
.userFlag{
  width: 15px;
  height: 15px;
  border-radius : 50%;
  position: absolute;
  top: 27px;
  left: 27px;
}
.userName{
  color: #000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.userContact{
  color: #666;
  font-size: 15px;
  font-weight: 440;
  letter-spacing: 0.525px;
}  
.switchCustomer{
  color: #384386;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
}
.vehicleImage{
  border: 1px solid #F2F2F2;
  width : 100%;
  border-radius: 6px;
  margin-top:16px;
}
.vehicleImage img{
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: contain
}
.control-arrow{
  height: 50px !important;
  top: 40% !important;
}
.carousel.carousel-slider .control-arrow {
  background: rgb(0 0 0 / 47%);
  opacity : 1;
}
.vehcileDetails{
  display:flex;
  flex-direction : column;
  gap :10px;
}
.disabled{
  cursor: not-allowed;
}
// --------------------------------------------Price Section--------------------------------------------

.pricingAndSalesDetails .pricingContainer, .pricingAndSalesDetails .actionContainer{
  padding : 16px;
}
.pricingContainer{
  width : 50%;
  border-right : 1px solid #E5E5E5;
  gap :25px;
}
.vehicleName{
  color: #6579F4;
  font-size: 21px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.42px;
}
.details p{
  color: #000;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  min-width : 150px;
  margin : 0px;
}
.details span{
  color: #999;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.price{
  color: #000;
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.42px;
}
.priceValue{
  color: #000 ;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.vat{
  color: #000;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
  letter-spacing: 0.26px;
}
.vatPercent{
  color: #999;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
  letter-spacing: 0.26px;
}
.fieldLabel{
  color: #000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.fieldLabelPrice{
  color: #000;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.borderBottom{
  border-bottom:1px solid #E5E5E5
}
.orderButtons{
  height: 56px;
  border-radius: 6px;
}
.confirmSaleContainer{
  width: 100%;
  padding-bottom: 175px;
}
.confirmSaleContainer .reservedButton{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 110px;
}
.soldButton, .confirmSale{
  background: #6579F4;
  color: #fff;
  font-family: Inter;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.36px;
  text-transform : none;
}
.soldButton{
  width : 45%;
}
.confirmSale{
  width : 100%;
}
.reservedButton{
  background: #f5f6ff;
  width : 45%;
  color: #6579F4;
  font-family: Inter;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.36px;
  text-transform : none;
}

// --------------------------------------------Action Section--------------------------------------------

.actionContainer{
  width : 50%;
}


// --------------------------------------------Media Queries--------------------------------------------

@media (max-width :992px){
  .pageHeader{
    display :flex;
    flex-direction :row !important;
    justify-content : space-between;
    align-items: center;
    margin-top: 0;
  }
  .userAndVehicleDetails, .pricingAndSalesDetails{
    width :100%;
    border :none;
  }
  .userDetailsContainer{
    display :flex;
    justify-content : space-between;
  }
  .userDetails{
    width : 75%;
  }
  .vehicleImage{
    max-width :500px;
    margin : 20px auto 0;
    border-radius: 6px;
    border: 1px solid #E5E5E5;
    background: #FFF;
  }
  .container{
    flex-direction : column;
  }
  .container .pricingContainer , .container .actionContainer{
    width :100%;
    padding : 0px;
    border :none;
  }
  .pricingContainer{
    border-bottom:1px solid #E5E5E5 !important;
    padding-bottom :16px !important;
  }
  .rightContainer{
    padding : 0px;
    border :none;
  }
  .details{
    display : flex;
    justify-content : space-between;
  }
  .actionContainer{
    padding :16px 0 !important;
    margin-bottom : 60px;
  }
}
`