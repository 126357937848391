import React, { useEffect, useState } from 'react'
import UserLayout from 'app/Component2/Layouts/UserLayout/UserLayout'
import CreateLicensePlate from './Component/CreateLicensePlate'
import { Box } from '@mui/material'
import LicensePlateList from './Component/LicensePlateList'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { LicPlateWrapper } from './style/licensePlateStyle'
import { useWindowSize } from 'app/Hooks/windowResize'
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom'
import TopBar from 'app/Component2/Layouts/AdsLayouts/component/TopBar'
import { SvgMobileLeftArrow } from 'app/components/svgicons/svgNew'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes/routes'
import { getSubsidiaryStored } from 'config/variables'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { createLicensePlateFailure, createLicensePlateSuccess, deleteLicensePlateFailure, findableForPublicFailure, getVehicleLicensePlateListRequest, getVehicleListRequest } from 'store/actions/licensePlate-action'
import { Toaster } from 'services/Toaster'
import ToastMessageWithButton from 'app/components/ToastMessageWithButton'
import { LicensePlateslistI } from './Interface/interface'

const LicensePlate = () => {

  const [mobile, width] = useWindowSize()
  // localstates
  const [openCreateLicenseScreen, setOpenCreateLicenseScreen] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [list, setList] = useState<Array<LicensePlateslistI>>([])
  const [isDownloadAppOpen, _isDownloadAppOpen] = useState(true);
  //  Redux State
  const { VehicleLicensePlateList, licensePlateData, licensePlateError, deletePlateError, findableForPublicError } = useSelector((state: RootStateOrAny) => state.licensePlate)
  const { userData } = useSelector((state: RootStateOrAny) => state.user)
  const { isAppBarOpen } = useSelector((state: any) => state.publicReducer);
  // Hooks
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    _isDownloadAppOpen(isAppBarOpen)
  }, [isAppBarOpen])

  useEffect(() => {
    // set vehicle list with license plate
    if (VehicleLicensePlateList) {
      setList(VehicleLicensePlateList)
    }
  }, [VehicleLicensePlateList])


  useEffect(() => {
    //  error handling while creating License plate
    if (licensePlateError && Object.keys(licensePlateError).length) {
      if (userData.profile_is_verified && licensePlateError.data) {
        Toaster.error(Object.values(licensePlateError.data).toString())
        dispatch(createLicensePlateFailure({}))
        return
      }
      Toaster.error(<ToastMessageWithButton type={'error'} buttonText={t(translations.ads.CLICK_HERE_TO_VERIFY)} message={licensePlateError.message} click={() => { history.push(routes.userRoutes.idVerfication) }} />, false)
      dispatch(createLicensePlateFailure({}))
      return
    }

    //  error handling while deleting License plate
    if (deletePlateError && Object.keys(deletePlateError).length) {
      if (deletePlateError.data) {
        Toaster.error(Object.values(deletePlateError.data).toString())
        dispatch(deleteLicensePlateFailure({}))
        return
      }
      Toaster.error(deletePlateError.message)
      dispatch(deleteLicensePlateFailure({}))
      return
    }

    //  error handling for enable/disable findable toggle
    if (findableForPublicError && Object.keys(findableForPublicError).length) {
      if (findableForPublicError.data) {
        Toaster.error(Object.values(findableForPublicError.data).toString())
        dispatch(findableForPublicFailure({}))
        return
      }
      Toaster.error(findableForPublicError.message)
      dispatch(findableForPublicFailure({}))
    }

  }, [licensePlateError, deletePlateError, findableForPublicError])

  useEffect(() => {
    if (licensePlateData && Object.keys(licensePlateData).length && Object.keys(licensePlateData.license_plate).length) {
      // Prepending newly added LicensePLate at top of list
      setList([licensePlateData, ...list])
      setOpenCreateLicenseScreen(false)
      Toaster.success(t(translations.LICENSEPLATE.LICENSE_PLATE_CREATED_SUCCESSFULLY))
      dispatch(createLicensePlateSuccess({}))
      return
    }
  }, [licensePlateData])

  //  Functions
  const handleCreateLicense = () => {
    setOpenCreateLicenseScreen(true)
  }

  return (
    <UserLayout title={t(translations.ads.LPLATE)} >
      <Box sx={width < 767 ? { display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: isDownloadAppOpen ? '75vh' : '84vh' } : {}}>
        <Box>
          {/*====================top left arrow button for go back functionality============= */}
          <TopBar
            title={openCreateLicenseScreen ? t(translations.LICENSEPLATE.CREATE_LIC_PLATE) : t(translations.ads.LPLATE)}
            icons={<span onClick={() => openCreateLicenseScreen ? setOpenCreateLicenseScreen(false) : history.goBack()}>
              <SvgMobileLeftArrow />
            </span>}
            onClick={() => setOpenCreateLicenseScreen(false)} />
          <LicPlateWrapper>

            {/*===================create License plate Form====================*/}
            {((width > 767 && width < 992) || (width > 767) || (width < 767 && openCreateLicenseScreen)) &&
              <Box className='lplate-form' ><CreateLicensePlate hideItems={false} /></Box>
            }

            {/*===================code for list of vehicles with license plate==================*/}
            {!openCreateLicenseScreen &&
              <Box className='lplate-list'>
                <LicensePlateList setList={setList} list={list} page={page} setPage={setPage} setOpenCreateLicenseScreen={setOpenCreateLicenseScreen} />
              </Box>
            }
          </LicPlateWrapper>
        </Box>
        {/* ===================Create License Button================ */}
        {width < 767 && !openCreateLicenseScreen &&
          <Box sx={{ mt: 2, width: '100%', padding: '0 10px 0 10px' }}>
            <ButtonCustom clickFunction={handleCreateLicense} textBtn={t(translations.LICENSEPLATE.CREATE_LIC_PLATE)} btnColor={'primary'} style={{ width: "100%" }} />
          </Box>}
      </Box>
    </UserLayout>
  )
}

export default LicensePlate