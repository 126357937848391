import { handleActions } from 'redux-actions';
import { addReviewsFailure, addReviewsRequest, addReviewsSuccess, flagReviewsFailure, flagReviewsRequest, flagReviewsSuccess, followUnfollowFailure, followUnfollowRequest, followUnfollowSuccess, followersListFailure, followersListRequest, followersListSuccess, followingListFailure, followingListRequest, followingListSuccess, getProfileFailure, getProfileRequest, getProfileSuccess, getReviewsFailure, getReviewsRequest, getReviewsSuccess, resetLoading, resetPublicProfile, updateRating, updateReviewsFailure, updateReviewsRequest, updateReviewsSuccess } from 'store/actions/publicProfile-action';

const initialState = {
  reviewsList: [],
  reviewsListLastPage: null,
  reviewsListError: {},
  loadingReviews: true,
  addReviewSuccessData: {},
  addReviewFailureData: {},
  updateReviewSuccessData: {},
  updateReviewFailureData: {},
  addReviewLoading: false,
  flagReviewLoading: false,
  flagReviewSuccessData: {},
  flagReviewFailureData: {},
  profileSuccessData: {},
  profileFailureData: {},
  profileLoading: false,
  followUnfollowSuccessData: {},
  followUnfollowFailureData: {},
  followUnfollowLoading: false,
  loadingFollowers: false,
  followersList: [],
  followersLastPage: null,
  followersListError: {},
  followingList: [],
  followingLastPage: null,
  followingListError: {},
}

const removeItem = () => {
  localStorage.removeItem('vehicleHistoryId')
  localStorage.removeItem('prevPath')
}
const publicProfile = handleActions({

  // Reset Statess

  [resetPublicProfile]: state => (
    removeItem(),
    {
      ...initialState,
      profileSuccessData: {},
      reviewsList: [],
      reviewsListLastPage: null,
      followersList: [],
      followersLastPage: null,
      followingList: [],
      followingLastPage: null,
    }),
  //  Get Reviews list
  [getReviewsRequest]: state => ({
    ...state,
    loadingReviews: true
  }),
  [getReviewsSuccess]: (state, { payload }) => (
    {
      ...state,
      reviewsListLastPage: payload.data.reviews.last_page,
      reviewsList: payload.data.reviews.current_page === 1 ? payload.data.reviews.data : [...state.reviewsList, ...payload.data.reviews.data],
      loadingReviews: false
    }),
  [getReviewsFailure]: (state, { payload }) => (
    {
      ...state,
      reviewsListError: payload?.data ?? {},
      loadingReviews: false
    }),
  [resetLoading]: (state, { payload }) => (
    {
      ...state,
      loadingReviews: false
    }),

  // Add Review
  [addReviewsRequest]: state => ({
    ...state,
    addReviewLoading: true
  }),
  [addReviewsSuccess]: (state, { payload }) => (
    {
      ...state,
      addReviewSuccessData: payload.data,
      addReviewLoading: false
    }),
  [addReviewsFailure]: (state, { payload }) => (
    {
      ...state,
      addReviewFailureData: payload?.data ?? {},
      addReviewLoading: false
    }),

  // Update Review
  [updateReviewsRequest]: state => ({
    ...state,
    addReviewLoading: true
  }),
  [updateReviewsSuccess]: (state, { payload }) => (
    {
      ...state,
      updateReviewSuccessData: payload.data,
      addReviewLoading: false
    }),
  [updateReviewsFailure]: (state, { payload }) => (
    {
      ...state,
      updateReviewFailureData: payload?.data ?? {},
      addReviewLoading: false
    }),

  // Add Review
  [flagReviewsRequest]: state => ({
    ...state,
    flagReviewLoading: true
  }),
  [flagReviewsSuccess]: (state, { payload }) => (
    {
      ...state,
      flagReviewSuccessData: payload,
      flagReviewLoading: false
    }),
  [flagReviewsFailure]: (state, { payload }) => (
    {
      ...state,
      flagReviewFailureData: payload?.data ?? {},
      flagReviewLoading: false
    }),

  // Get profile data
  [getProfileRequest]: state => ({
    ...state,
    profileLoading: true
  }),
  [getProfileSuccess]: (state, { payload }) => (
    {
      ...state,
      profileSuccessData: payload.data.item,
      profileLoading: false
    }),
  [getProfileFailure]: (state, { payload }) => (
    {
      ...state,
      profileFailureData: payload?.data ?? {},
      profileLoading: false,
      loadingReviews: false,
    }),

  [updateRating]: (state, { payload }) => {
    const profileDetails = structuredClone(state.profileSuccessData)
    profileDetails['average_ratings'] = payload.data.target_profile.average_ratings
    profileDetails['total_ratings_count'] = payload.data.target_profile.total_ratings_count
    return {
      ...state,
      profileSuccessData: profileDetails
    }
  },

  [followUnfollowRequest]: state => ({
    ...state,
    followUnfollowLoading: true
  }),
  [followUnfollowSuccess]: (state, { payload }) => (
    {
      ...state,
      followUnfollowSuccessData: payload,
      followUnfollowLoading: false
    }),
  [followUnfollowFailure]: (state, { payload }) => (
    {
      ...state,
      followUnfollowFailureData: payload?.data ?? {},
      followUnfollowLoading: false
    }),

  //  Get Followers list
  [followersListRequest]: state => ({
    ...state,
    loadingFollowers: true
  }),
  [followersListSuccess]: (state, { payload }) => (
    {
      ...state,
      followersLastPage: payload.last_page,
      followersList: payload.current_page === 1 ? payload.data : [...state.followersList, ...payload.data],
      loadingFollowers: false
    }),
  [followersListFailure]: (state, { payload }) => (
    {
      ...state,
      followersListError: payload?.data ?? {},
      loadingFollowers: false
    }),

  //  Get Following list
  [followingListRequest]: state => ({
    ...state,
    loadingFollowers: true
  }),
  [followingListSuccess]: (state, { payload }) => (
    {
      ...state,
      followingLastPage: payload.last_page,
      followingList: payload.current_page === 1 ? payload.data : [...state.followingList, ...payload.data],
      loadingFollowers: false
    }),
  [followingListFailure]: (state, { payload }) => (
    {
      ...state,
      followingListError: payload?.data ?? {},
      loadingFollowers: false
    })

}, initialState,)

export default publicProfile;