import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { SvgMobileLeftArrow, SvgsideBarToggler } from 'app/components/svgicons/svgNew';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useCountdown } from 'app/Hooks/useCoundown';
import { useDeviceType } from 'app/Hooks/useMediaQuery';

const TopBarShareLikeComponent = ({ timer, itemData, previousPath, icons, iconsFunction, onClick, title, adType, component }) => {
    //  states and hooks
    const history = useHistory();
    const [isSmallDevice] = useDeviceType()




    const [days, hours, minutes, seconds] = useCountdown(timer ? new Date(timer).getTime() : new Date().getTime());

    return (
        <>
            <Box className='detail-Bar-wraper'>
                <span className='toggler-icon'>
                    {icons ?
                        <Box className='icon-style pointer'>
                            {icons}
                        </Box>
                        :
                        <span onClick={() => onClick()}>
                            {iconsFunction ?
                                <Box className='icon-style pointer' sx={{ border: "solid 1px #666666" }}>
                                    {iconsFunction}
                                </Box>
                                :
                                (
                                    // when we come on ads page from user profile , than show a back button on topbar rather than a hamburger   
                                    (previousPath.includes(routes.publicRoutes.public_profile_subisidiary) || previousPath.includes(routes.publicRoutes.public_profile_user)) ?
                                        <div onClick={(e) => { e.stopPropagation(); history.goBack() }}><SvgMobileLeftArrow /></div>
                                        :
                                        <SvgsideBarToggler />
                                )

                            }
                        </span>
                    }
                </span>
                <Box className='ad-title-container'>
                    <Box className={isSmallDevice && 'ad-title-ellipsis'} >
                        {title}
                    </Box>
                    <Box className='like-share-container'>
                        <Box className='ad-type'>
                            {itemData && Object.keys(itemData).length > 0 ?
                                // Display auction timer in auction ad detail 
                                <Box className='header-timer'> {days + ':' + hours + ':' + minutes + ':' + seconds}</Box>
                                :
                                // display auction type in classic ads
                                adType
                            }
                        </Box>
                        <Box className='like-share-icons'>
                            {component}
                        </Box>
                    </Box>
                </Box>
            </Box ></>
    )
}

export default TopBarShareLikeComponent