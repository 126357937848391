import { useDispatch } from "react-redux"
import { chatStoreRequest, sendMessageSuccess } from "store/actions/chat-action"
import { getSubsidiaryStored } from "./variables"
import moment from "moment"
import { adsTypesNum } from "./constants"

export const chatType = {
  ALL_MESSAGES: 1,
  ARCHIVED: 2,
  BLOCKED: 3,
}

export const chat_type = {
  SIMPLECHAT: 1,
  GROUPCHAT: 2,
}

export const BLOCK_TYPE = {
  USER: 1,
  SUBSIDIARY: 2
}

export const ACTION_TYPE = {
  DELETE: 1,
  BLOCK: 2
}

export const attachmentType = {
  TEXT: 1,
  FILE_OR_IMAGE: 2,
}
export const readStatus = {
  unRead: 0,
  read: 1,
}
export const archivedStatus = {
  archive: 1,
  unArchive: 0,
}
export const blockedStatus = {
  blocked: 1,
  unBlocked: 0,
}
export const muteStatus = {
  unMute: 0,
  mute: 1,
}

export const receptionistChat = {
  true: 1,
  false: 0,
}

export const chatCases = {
  PERSONAL_CHAT: 1,
  ADS_DETAIL_PAGE_CHAT: 2,
  USER_TO_SUBSIDIARY_MEMBER: 3,
}

export const TYPE_OF_CHAT = {
  ONE_TO_ONE: 1,
  GROUP_CHAT: 2
}

export const Profile_verification_type = {
  VERIFIED: 1,
}

export const MESSAGE_TYPE = {
  TEXT: 1,
  ATTACHMENT: 2,
  TOPIC: 3
}

export const CHAT_TABS = {
  USERS: 'users',
  LICENSE_PLATE: "licPlate",
}
// Comparison and filter unique data of two arrays
export const suggestedMembers = (array1, array2) => {
  return array1?.filter(object1 => {
    return !array2?.some(object2 => {
      return object1.id === object2.id && object1.pivot.subsidiary_id === object2.pivot.subsidiary_id;
    });
  });
}

// Move chat from all chat list to Archive or blockde list
export const moveChatItem = (from, to, chatId) => {
  let updateFromData = [...from]
  let updateToData = [...to]
  let index = updateFromData.findIndex(o => o.id === chatId)
  if (index !== -1) {
    updateFromData.splice(index, 1)
    updateToData.push(from[index])
  }
}


// handle create/Store chat function for case 1 (PERSONAL_CHAT )
export const createStorechat = (receiverId, chatCase, dispatch, accountType) => {
  let payload: any = {
    case: chatCase,

  };
  if (accountType === 'user') {
    payload.receiver_user_id = receiverId;
  }
  if (accountType === 'subsidiary' || accountType === 'pro') {
    payload.receiver_subsidiary_id = receiverId;
  }
  if (getSubsidiaryStored()) {
    payload.subsidiary_id = getSubsidiaryStored()
  }
  dispatch(chatStoreRequest(payload))
}
// create/Store chat function for case 2 (ADS_DETAIL_PAGE_CHAT)
export const createUserSubchat = (adId, ownerVehicleId, chatCase, dispatch, adType) => {
  let payload: any = {
    case: chatCase,
    owner_vehicle_id: ownerVehicleId,
    ad_id: adId
  };
  if (getSubsidiaryStored()) {
    payload.subsidiary_id = getSubsidiaryStored()
  }
  if (adType && adType === adsTypesNum.hotDealAds) {
    payload.ad_type = adType
  }
  dispatch(chatStoreRequest(payload))
}

// create/Store chat function for case 3 (USER_TO_SUBSIDIARY_MEMBER)
export const createUserSubMemberChat = (subId, userId, chatCase, dispatch) => {
  let payload: any = {
    case: chatCase,
    receiver_subsidiary_id: subId,
    receiver_user_id: userId,
  };
  if (getSubsidiaryStored()) {
    payload.subsidiary_id = getSubsidiaryStored()
  }
  dispatch(chatStoreRequest(payload))
}

// Group message dateWise
export const groupedDays = (messages) => {
  return messages.reduce((msg, data, i) => {
    const messageDay = moment(data.created_at).format('YYYY-MM-DD');
    if (msg[messageDay]) {
      return { ...msg, [messageDay]: msg[messageDay].concat([data]) };
    }
    return { ...msg, [messageDay]: [data] };
  }, {});
}

export const detectWebcam = (callback) => {
  let md = navigator.mediaDevices;
  if (!md || !md.enumerateDevices) return callback(false);
  md.enumerateDevices().then(devices => {
    callback(devices.some(device => 'videoinput' === device.kind));
  })
}

export const nameTemplate = (subsidiaryDetails, sub, subsidiaryName, profileUpdatedData, userData) => {
  if (sub && sub !== null) {
    if (subsidiaryDetails && Object.keys(subsidiaryDetails).length > 0) {
      if (Object.keys(subsidiaryName).length > 0) {
        return subsidiaryName.data.data.subsidiary.name
      } else {
        if (subsidiaryDetails?.name) {
          return subsidiaryDetails?.name
        }
      }
    }
  } else {
    if (profileUpdatedData && Object.keys(profileUpdatedData).length > 0) {
      if (profileUpdatedData?.data?.user?.first_name && profileUpdatedData?.data?.user?.last_name) {
        return profileUpdatedData?.data?.user?.first_name + " " + profileUpdatedData?.data?.user?.last_name
      } else if (profileUpdatedData?.data?.user?.first_name) {
        return profileUpdatedData?.data?.user?.first_name
      } else if (profileUpdatedData?.data?.user?.last_name) {
        return profileUpdatedData?.data?.user?.last_name
      }
    } else {
      if (userData && Object.keys(userData).length) {
        if (userData?.first_name && userData?.last_name) {
          return userData?.first_name + " " + userData?.last_name
        } else if (userData?.first_name) {
          return userData?.first_name
        } else if (userData?.last_name) {
          return userData?.last_name
        }
      }
    }

  }
}

export const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) ||
      (bottom > 0 && bottom < innerHeight)) &&
    ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};


