import { handleActions } from 'redux-actions';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  registerRequest,
  registerFailure,
  registerSuccess,
  activateAccountRequest,
  activateAccountSuccess,
  activateAccountFailure,
  forgotRequest,
  forgotSuccess,
  forgotFailure,
  resetPasswordRequest,
  resetPasswordFailure,
  resetPasswordSuccess,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  resendEmailRequest,
  resendEmailSuccess,
  resendEmailFailure,
  verifyIdRequest,
  verifyIdSuccess,
  verifyIdFailure,
  verifiedIdentityRequest,
  verifiedIdentitySuccess,
  verifiedIdentityFailure,
  verifiedIdentityReset,
  getActiveLanguageRequest,
  getActiveLanguageSuccess,
  getActiveLanguageFailure,
  changeLanguageRequest,
  changeLanguageSuccess,
  changeLanguageFailure,
  updateMyWalletBalance
} from '../actions/auth-actions';

let data = localStorage.getItem('token');
let userData = localStorage.getItem('user');

const initialState = {
  loading: false,
  loggedIn: data ? true : false,
  logout: false,
  loginError: {},
  registerError: {},
  userDetails: null,
  registerSuccess: false,
  accountActivatedSuccess: {},
  forgotSuccess: {},
  forgotFailure: {},
  resetPasswordSuccess: false,
  user: userData ? JSON.parse(userData) : {},
  activateAccountError: false,
  resetPasswordFailure: {},
  emailResendError: {},
  emailResendSuccess: {},
  verifyIdData: {},
  verifyIdError: {},
  verifiedData: {},
  loadingLanguage: false,
  languageData: {},
  languageError: {},
  languageChangedData: false,
  languageChangedError: {},
  myWalletBalance: 0
};

const auth = handleActions(
  {
    [loginRequest]: state => ({
      ...state,
      loading: true,
    }),
    [loginSuccess]: (state, { payload }) => ({
      ...state,
      loggedIn: true,
      loggedInRedirect: true,
      loading: false,
      user: payload.user,
    }),
    [loginFailure]: (state, { payload }) => ({
      ...state,
      loggedIn: false,
      loading: false,
      loginError: payload.data,
    }),
    [logoutRequest]: state => ({
      ...state,
      loading: true,
      logout: false
    }),
    [logoutSuccess]: (state, { payload }) => ({
      ...state,
      loggedIn: false,
      loading: false,
      logout: true
    }),
    [logoutFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      logout: false
    }),

    [registerRequest]: state => ({
      ...state,
      loading: true,
      registerSuccess: false,
      registerError: {}
    }),
    [registerSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      userDetails: payload,
      registerSuccess: true
    }),
    [registerFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      registerError: payload.data,
      registerSuccess: false,
    }),
    [activateAccountRequest]: (state) => ({
      ...state,
      loading: true,
      accountActivatedSuccess: {},
      activateAccountError: false
    }),
    [activateAccountSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      accountActivatedSuccess: payload
    }),
    [activateAccountFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      activateAccountError: true
    }),
    [forgotRequest]: (state) => ({
      ...state,
      loading: true,
      forgotSuccess: {},
      forgotFailure: {}
    }),
    [forgotSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      forgotSuccess: payload
    }),
    [forgotFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      forgotFailure: payload
    }),
    [resetPasswordRequest]: (state) => ({
      ...state,
      loading: true,
    }),
    [resetPasswordSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      resetPasswordSuccess: true
    }),
    [resetPasswordFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      resetPasswordFailure: payload
    }),

    //resend email
    [resendEmailRequest]: state => ({
      ...state,
      loading: true,
      emailResendError: {},
      emailResendSuccess: {}
    }),
    [resendEmailSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      emailResendSuccess: payload
    }),
    [resendEmailFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      emailResendError: payload
    }),
    [verifyIdRequest]: (state, { payload }) => ({
      ...state,
      loading: false,
      verifyIdData: {}
    }),
    [verifyIdSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      verifyIdData: payload,
    }),

    [verifyIdFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      verifyIdError: payload
    }),
    [verifiedIdentityRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      verifiedData: {},
      verifiedError: {}
    }),
    [verifiedIdentitySuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      verifiedData: payload,
      verifiedError: {}
    }),

    [verifiedIdentityFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      verifiedData: {},
      verifiedError: payload
    }),
    [verifiedIdentityReset]: (state, { payload }) => ({
      ...state,
      loading: false,
      verifiedData: {},
      verifiedError: {}
    }),
    [getActiveLanguageRequest]: (state, { payload }) => ({
      ...state,
      loadingLanguage: true,
      languageData: {},
      languageError: {}
    }),

    [getActiveLanguageSuccess]: (state, { payload }) => (
      {
        ...state,
        loadingLanguage: false,
        languageData: payload.languages ? payload : payload.data ? payload.data : {},
        languageError: {}
      }),
    [getActiveLanguageFailure]: (state, { payload }) => ({
      ...state,
      loadingLanguage: false,
      languageData: {},
      languageError: { payload }
    }),
    [changeLanguageRequest]: (state, { payload }) => ({
      ...state,
      loading: true,
      loadingLanguage: true,
      languageChangedData: false,
      languageChangedError: {}
    }),

    [changeLanguageSuccess]: (state, { payload }) => ({
      ...state,
      loading: false,
      loadingLanguage: false,
      languageChangedData: payload,
      languageChangedError: {}
    }),
    [changeLanguageFailure]: (state, { payload }) => ({
      ...state,
      loading: false,
      loadingLanguage: false,
      languageChangedData: {},
      languageChangedError: { payload }
    }),
    [updateMyWalletBalance]: (state, { payload }) => ({
      ...state,
      myWalletBalance: payload
    }),
  },
  initialState,
);

export default auth;
