import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { PublicProfileWrapper } from '../../../style/publicProfileStyle';
import { Skeleton, TextField } from '@mui/material';
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom';
import { SvgMobileLeftArrow } from 'app/components/svgicons/svgNew';
import { Toaster } from 'services/Toaster';
import { useWindowSize } from 'app/Hooks/windowResize';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { addReviewsRequest, updateReviewsRequest } from 'store/actions/publicProfile-action';
import { ReviewsEditInterface } from 'app/pages/PublicProfile/interface/interface';
import { useHistory, useParams } from 'react-router-dom';
import * as publicProfileConstants from "config/publicProfileConfig"
import { routes } from 'routes/routes';
import ToastMessageWithButton from 'app/components/ToastMessageWithButton';
import { Strings, getSubsidiaryStored } from 'config/variables';
import { lan } from 'locales/multiPageTranslation/adsLan';


const AddReview = ({ edit, recId, showEditText, userRating, userReview }: ReviewsEditInterface) => {

    const { addReviewLoading, profileSuccessData, addReviewSuccessData } = useSelector((state: RootStateOrAny) => state.publicProfile)
    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth)
    const { userData } = useSelector((state: RootStateOrAny) => state.user)

    const { t } = useTranslation()
    const [mobile] = useWindowSize()
    const dispatch = useDispatch()
    const history = useHistory()

    const { userType, slug } = useParams()

    const [open, setOpen] = useState<boolean>(false);
    const [reviewGiven, _reviewGiven] = useState<boolean>(false);

    const [rating, setRating] = useState<number>(0)
    const [review, setReview] = useState<string>('')

    // Setting initial value if review has been given
    useEffect(() => {
        if (profileSuccessData && Object.keys(profileSuccessData).length > 0) {
            _reviewGiven(profileSuccessData.rating_given_in_6months)
        }
    }, [profileSuccessData])


    // Setting value to true if user adds review
    useEffect(() => {
        if (addReviewSuccessData && Object.keys(addReviewSuccessData).length > 0) {
            _reviewGiven(true)
        }
    }, [addReviewSuccessData])

    const handleOpen = () => {

        // Runs while editing review
        // opening edit modal
        if (edit) {
            setOpen(true)
            return
        }


        // Runs if adding new review
        // redirects to login page if not loggedin
        if (!loggedIn) {
            history.push(routes.authRoutes.login)
            return;
        }

        // asks to verify account if user is not verified and haven't bought any vehicle yet
        if (!userData.profile_is_verified && !profileSuccessData.vehicle_bought && !getSubsidiaryStored()) {
            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(...lan.clickHereToVerify())} message={Strings.verifyString} click={() => history.push(routes.userRoutes.idVerfication)} />, false)
            return
        }

        setOpen(true)
    };

    const handleClose = () => {
        setRating(0);
        setReview('')
        setOpen(false);
    }

    useEffect(() => {
        if (edit) {
            setRating(userRating || 0);
            setReview(userReview || '')
        }
    }, [])

    const handleReview = (e) => {
        if (e.target.value.replace(/\n/g, '  ').length > 500) {
            Toaster.error(t(translations.PUBLICPROFILE.REVIEW_CANT_EXCEED_500_CHARS))
            return;
        }
        setReview(e.target.value)
    }

    const handleRating = (rate: number) => {
        setRating(rate)
    }

    const handleSubmit = () => {
        if (rating == 0) {
            Toaster.error(t(translations.PUBLICPROFILE.STAR_RATING_IS_REQUIRED))
            return;
        }
        let payload = {
            rating: rating
        }
        if (edit) {
            payload['id'] = recId
        }
        if (review) {
            payload['review'] = review
        }
        if (userType == publicProfileConstants.profile.user) {
            payload['target_user_id'] = profileSuccessData.id
        }
        else if (userType == publicProfileConstants.profile.pro) {
            payload['target_subsidiary_id'] = profileSuccessData.id
        }
        if (edit) {
            dispatch(updateReviewsRequest(payload))
            handleClose()
            return
        }
        dispatch(addReviewsRequest(payload))
        handleClose()
    }

    return (
        <>
            <Box className={showEditText ? 'giveRatingBox flexColumn alignItemsCenter justifyContentCenter' : ""} sx={{ mt: 1 }}>
                {/* Renders add review component in case of adding new*/}
                {showEditText ?
                    <>
                        <span> {t(translations.PUBLICPROFILE.YOUR_REVIEW_REMAINS_BASED_ON_OVERALL_EXPERIENCE)} </span>
                        <Box className='giveRatingIcon pointer' onClick={handleOpen}>
                            <Rating initialValue={userRating || 0} readonly size={20} />
                        </Box>
                    </> :
                    <Box className='pointer flexColumn justifyContentCenter' onClick={handleOpen}>
                        {/* Renders add review component in case of adding new*/}
                        {t(translations.PUBLICPROFILE.EDIT_REVIEW)}
                    </Box>
                }
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <PublicProfileWrapper>
                    <Box className={mobile ? 'openMobileModal' : 'openModal'}>
                        <Box className='flexRow justifyContentBetween alignItemsCenter' sx={{ marginBottom: '10px' }}>
                            <SvgMobileLeftArrow onClick={handleClose} className='pointer' />
                            <span style={{ fontSize: '18px' }}>{profileSuccessData.pseudo || profileSuccessData.name || <Skeleton variant="rectangular" width={150} height={32} />} </span>
                            <span>{" "}</span>
                        </Box>
                        <Box className='giveRatingBox flexColumn alignItemsCenter justifyContentCenter'>
                            <span> {t(translations.PUBLICPROFILE.YOUR_REVIEW_REMAINS_BASED_ON_OVERALL_EXPERIENCE)} </span>
                            <Box className='giveRatingIcon pointer'>
                                <Rating initialValue={rating} onClick={handleRating} size={20} />
                            </Box>
                        </Box>
                        <TextField
                            className='reviewField scrollBar'
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            placeholder={t(translations.PUBLICPROFILE.WRITE_YOUR_COMMENT)}
                            value={review}
                            onChange={(e) => handleReview(e)}
                        />
                        <ButtonCustom textBtn={t(edit ? translations.PUBLICPROFILE.UPDATE_REVIEW : translations.PUBLICPROFILE.SUBMIT_REVIEW)} btnColor="primary" style={{ marginTop: '20px', width: '100%' }} clickFunction={handleSubmit} disable={addReviewLoading} />
                    </Box>
                </PublicProfileWrapper>
            </Modal>
        </>
    )
}

export default AddReview