import { Box } from '@mui/material';
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom';
import { lan } from '../lan';
import { translations } from 'locales/translations';
import { Toaster } from 'services/Toaster';
import { routes } from 'routes/routes';
import { useStripe } from '@stripe/react-stripe-js';
import { numberFormat } from 'config/variables';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { invoiceStatus } from 'config/constants';

const PayNowButton = ({ setLoading, invoiceData, clientSecret, paymentMethod, invoiceDetail }) => {

    const stripe: any = useStripe()
    const { t } = useTranslation();
    const history = useHistory();

    // function  for  making payment
    const confirmOrder = async (clientSecret, paymentMethod) => {
        try {
            const paymentResult = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod,
            });
            const { paymentIntent } = paymentResult;
            if (paymentResult.error) {
                setLoading(false)
                if (Object.keys(invoiceData).length > 0) {
                    if ('paymentIntent' in invoiceData) {
                        if (invoiceData.paymentIntent.item.status == "requires_payment_method") {
                            history.push({
                                pathname: routes.companyRoutes.paymentMethod,
                                state: { paymentRequired: 'paymentMethodrequired' }
                            })
                        }
                    }
                }
                if (paymentResult.error.payment_intent.last_payment_error) {
                    Toaster.error(paymentResult.error.payment_intent.last_payment_error.message);
                } else {
                    Toaster.error(paymentResult.error.message);
                }
            } else {
                if (paymentResult.paymentIntent.status === "succeeded") {
                    Toaster.success(t(translations.validationAndPopText.PAYMENT_SUCCEEDED));
                    history.push({
                        pathname: routes.companyRoutes.invoices,
                    })
                }
            }
        } catch (e) {
            setLoading(false)
        }
    }
    return (

        <Box className="invoice-pay-btn bg-white d-flex justify-content-between p-3">
            {invoiceDetail.status === invoiceStatus.PAID ? <ButtonCustom textBtn={t(...lan.paid())} btnColor="green" clickFunction={() => { }} disable={true} />
                :
                invoiceDetail.status === invoiceStatus.UNCOLLECTIBLE ? <ButtonCustom textBtn={t(translations.webTexts.UNCOLLECTIBLE)} btnColor="red_white" clickFunction={() => { }} />
                    :
                    <ButtonCustom textBtn={t(translations.webTexts.PAY_NOW)} btnColor="primary" clickFunction={() => confirmOrder(clientSecret, paymentMethod)} />}
            <Box className='bill-amount-box'>
                <Box className='text-center bill-amount-amount'>
                    <h3>{t(translations.webTexts.AMMOUNT_DUE)}</h3>
                    {/* here amount_due is total amount a customer have to pay and amount_paid is amount paid by customer */}
                    <p>€{numberFormat((((invoiceDetail.amount_due || 0) / 100) - ((invoiceDetail.amount_paid || 0) / 100)).toFixed(2)) || numberFormat((0).toFixed(2))}</p>
                </Box>
            </Box>
        </Box>

    );
}

export default PayNowButton