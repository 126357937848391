import { Box, Modal } from '@mui/material'
import { SvgInfoIcon } from 'app/components/svgicons/svgNew'
import { HomePageWrapper } from 'app/pages/AdsHomePage/style/HomepageWrapper';
import React from 'react'
import { SvgClose } from '../svgicons/svg';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

const InfoModal = ({ header, content }) => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box className='benefitInformationContainer'>
            <SvgInfoIcon className='infoIcon pointer' onClick={handleOpen} style={{ marginBottom: '2px ' }} />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ outline: 0 }}
            >
                <HomePageWrapper>
                    <Box sx={style} className='infoModal'>
                        <Box className='relative'>
                            <SvgClose onClick={handleClose} className='closeIcon' />
                        </Box>
                        {header && <span className='benefitsSubHeader modalHeader'>{header}</span>}
                        <span className='benefitsContent modalContent'>{content}</span>
                    </Box>
                </HomePageWrapper>
            </Modal>
        </Box>
    )
}

export default InfoModal