import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import ChatOptionsMobile from './ChatOptionsMobile'
import ChatOptionsWeb from './ChatOptionsWeb'
import { useWindowSize } from 'app/Hooks/windowResize'
import { ChatOptionInterface, Nullable, chatDataInterface } from '../../interface/interface'
import * as ChatConstants from "config/chatConfig"
import { useDispatch, useSelector } from 'react-redux'
import { archiveParticularChatRequest, blockParticularChatRequest, chatStoreSuccess, getChatRequest, getChatSuccess, muteParticularChatRequest, unreadParticularChatRequest } from 'store/actions/chat-action'

const ChatOptions = ({ popup, chatType, chatId, showOptions, _showOptions,setUnread } : ChatOptionInterface) => {

    const { chatList, archivedChatList, blockedChatList } = useSelector((state) => state.chat)

    const [mobile] = useWindowSize();

    const dispatch = useDispatch()

    const [chatData, setChatdata] = useState<chatDataInterface>({})

    const [open, setOpen] = useState<boolean>(false)

    // Getting values for current active Chat
    useEffect(() => {
        if (chatList && chatList.length>0 && chatType === ChatConstants.chatType.ALL_MESSAGES) {
            setChatdata(chatList.find((o) => o.id === chatId))
        }
        else if (blockedChatList && blockedChatList.length>0 && chatType === ChatConstants.chatType.BLOCKED) {
            setChatdata(blockedChatList.find((o) => o.id === chatId))
        } else if (archivedChatList && archivedChatList.length>0 && chatType === ChatConstants.chatType.ARCHIVED) {
            setChatdata(archivedChatList.find((o) => o.id === chatId))
        }
    }, [chatList, archivedChatList, blockedChatList, chatId,chatType])


    //----------------------------------- Action Functions-----------------------------------

    const handleArchive = () => {
        localStorage.setItem('chat_id',null)
        //If chat is already archived, we will send opposite of it to arhcive it 
        let data = {
            id: chatData.id,
            data: chatData.is_archived === ChatConstants.archivedStatus.unArchive ? ChatConstants.archivedStatus.archive : ChatConstants.archivedStatus.unArchive
        }
        dispatch(chatStoreSuccess({}))
        dispatch(archiveParticularChatRequest(data))
        // if(popup){
        //     dispatch(getChatSuccess({ data: {}, append: false }))
        //     dispatch(getChatRequest({ page: 1 }))
        //   }
        setOpen(false)
        _showOptions(false)
    }

    const handleUnread = () => {
         //If chat is already read, we will send opposite of it to unread it 
        let data = {
            id: chatData.id,
            is_read: chatData.is_read === ChatConstants.readStatus.read ? ChatConstants.readStatus.unRead : ChatConstants.readStatus.read
        }
        dispatch(unreadParticularChatRequest(data))
        setUnread(true)
        setOpen(false)
        _showOptions(false)
    }

    const handleBlock = () => {
        localStorage.setItem('chat_id',null)
        let blockedUser: Nullable<number> = null;
        let blockedSubsidiary: Nullable<number> = null;

        blockedUser = chatData.chat_user_info.id;

        blockedSubsidiary = chatData.chat_user_info.subsidiary_id;


        const blockedEntity = blockedSubsidiary ?? blockedUser;
        
        let data = {
            id: blockedEntity,
            isblocked: chatType===ChatConstants.chatType.BLOCKED?true:false,
            type: blockedSubsidiary === null ? ChatConstants.BLOCK_TYPE.USER : ChatConstants.BLOCK_TYPE.SUBSIDIARY
        }
        dispatch(chatStoreSuccess({}))
        dispatch(blockParticularChatRequest(data))
        setOpen(false)
        _showOptions(false)
    }

    const handleMute = () => {
        //If chat is already muted, we will send opposite of it to unmute it 
        let data = {
            id: chatData.id,
            data: chatData.is_muted === 0 ? 1 : 0
        }
        dispatch(muteParticularChatRequest(data))
        setOpen(false)
        _showOptions(false)
    }

    return (
        <Box className="chatOptions" >
            <Box>
                {mobile ?
                    //Chat option drawer that opens from bottom in mobile view
                    <ChatOptionsMobile chatData={chatData} handleArchive={handleArchive} handleBlock={handleBlock} handleMute={handleMute} handleUnread={handleUnread} open={open} setOpen={setOpen} chatType={chatType} key={chatData}/>
                    :
                    //Chat option that opens in web view 
                    <ChatOptionsWeb showOptions={showOptions} _showOptions={_showOptions} chatData={chatData} handleArchive={handleArchive} handleBlock={handleBlock} handleMute={handleMute} handleUnread={handleUnread} open={open} setOpen={setOpen} chatType={chatType} key={chatData}/>}
            </Box>
        </Box>
    )
}

export default ChatOptions