import { color, colorNew } from 'styles/StyleConstants';
import styled from "styled-components";
export const InvoiceWrapper = styled.div`   
   max-width: 550px;
   margin: 40px auto;
   
  .bill-header-section{
   .bill_banner{
        display: flex;
        align-items: center;
        justify-content:space-between;
        margin-bottom: 30px;
    }
      .bill-left-icon{
          svg{
              stroke: white;
              path{
                  stroke: white;
              }
          }
          span{
            font-weight: 400;
            font-size: 21px;
            line-height: 25px;
            text-align: center;
            letter-spacing: 0.02em;
          }
      }
      .bill-right-icon{
          position: absolute;
          right: 5px;
          top: 0px;
      }
      @media(max-width:767px){
          border-radius: 0;
      }   
  }
  .invoice-detail-body{
      position: relative;
      z-index: 1;   
      .action-box{
          .date{
            font-size: 20px;
            padding-bottom: 12px;
            display: inline-block;
          }
          .invoice-pay-btn{
            box-shadow: 0 0 17px #ccc;
            padding: 20px;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3{
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;
                text-align: center;
                color:${color.colorGrayDark};
                margin: 0;
                text-align: center;
            }
            p{
                text-align: center;
                margin: 0;
            }
              button{
                  // border-radius: 6px; 
                  text-wrap:nowrap;
                  align-self: center;
                  padding: 7px 12px 6px;
                  &:not(.paid-btn){
                      background: #607AFC;
                      border: 1px solid #7589EC;
                  }
                  &.paid-btn{
                      background: #6579F4;
                      border-radius: 6px;
                      padding: 16px;
                      font-size: 18px;
                      border: none;
                      color: #ffffff;
                  }
              }
              .bill-amount-label{
                  font-size: 13px;
                  color: #979797;
              }
              .bill-amount-amount{
                  font-size: 18px;
              }
          }
      }
      .address-vat{
            h2{
             margin: 0;
             font-size: 21px;
             font-weight: 500;
             text-align: center;
             padding: 20px 0 15px;
            }
          .invoice-address{
             color: ${colorNew.colorGrayOnButton};
             display: flex;
             justify-content: space-between;
          }
          .invoice-vat {
                margin-top: 20px;
            }
      }
      .bill-item-wrapper{
        padding: 20px 0px;
        margin-top: 20px;
        border-top: solid 1px #cccccc57;
        border-bottom: solid 1px #cccccc57;
        .bill-item{
            color:#808080;
            width:70%;
        }
        .plan_details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                gap:12px;
            }
          .bill-item-validity-and-name{
              label{
                  color: #6F6E70;
                  display: block;
                  b{
                      color: #000000;
                  }
              }
          }
          .bill-item-amount{
              font-size: 20px;
              text-wrap: nowrap;
          }
      }
      .principal-total-wrapper{
            border-radius: 8px;
            margin-top: 20px;
            border-bottom: solid 1px #cccccc57;
          >div{
              label{
                  color: #808080;
                  font-size: 14px;
                  display: inline-block;
                  padding: 5px 0;
                  width: 50%;
              }
              span{
                width: 50%;
                display: inline-block;
                text-align: right;
              }
          }
      }
      .grand-total {
        margin: 10px 0;
        .ammount_box {
                display: flex;
                justify-content: space-between;
            }
            .text-success {
            color: green;
            }
            .text-error{
                color: red;
            }
      }
  }
  @media (max-width :598px){
.invoice-detail-body {
    .bill-item {
        width: 70%;
    }
    .principal-total-wrapper {
        width: 100%;
    }
    .bill-item-wrapper{
        padding: 0px;
      }
}
  }
`