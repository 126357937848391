import { Box, Button, InputAdornment, Stack, TextField } from '@mui/material'
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom'
import { useWindowSize } from 'app/Hooks/windowResize'
import { AntSwitch } from 'app/components/stylesComponents/AntSwitch'
import { translations } from 'locales/translations'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SuggestedVehiclesList from './SuggestedVehiclesList'
import DeleteConfirm from 'app/components/stylesComponents/Ads/DeleteConfirm'
import { createLicensePlateRequest } from 'store/actions/licensePlate-action'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import CountryByContinent from 'app/components/GlobalComponent/CountryByContinent'
import { CreateLicensePlateI, SelectedVehicleI, countryI } from '../Interface/interface'
import { SvgChevronRight } from 'app/components/svgicons/svg'
import { getSubsidiaryStored, spaceValidationRegex } from 'config/variables'
import { validation } from 'app/pages/VehicleSummaryPage/VehicleValidations'
import { localstorageKey } from 'config/constants'
import { SvgInfoIcon, SvgmemSearch } from 'app/components/svgicons/svgNew'
import ToastMessageWithButton from 'app/components/ToastMessageWithButton'
import { routes } from 'routes/routes'
import { useHistory } from 'react-router-dom'
import { Toaster } from 'services/Toaster'
import { handleBuyCredits } from 'config/utils'

const CreateLicensePlate = ({ hideItems, setLicensePlateAndCountry, handleSearchForLicensePlate }: CreateLicensePlateI) => {
    //Local states
    const [selectedVehicle, setSelectedVehicle] = useState<SelectedVehicleI>({})
    const [selectedVehicleError, setSelectedVehicleError] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [countryError, setCountryError] = useState<string>('')
    const [licensePlateNumber, setLicensePlateNumber] = useState<string>('')
    const [licensePlateNumberError, setLicensePlateNumberError] = useState<string>('')
    const [filteredCountry, setfilteredCountry] = useState<object>(() => JSON.parse(localStorage.getItem(localstorageKey.groupedCountries)) ? JSON.parse(localStorage.getItem(localstorageKey.groupedCountries)) : {})
    const [open, setOpen] = useState<boolean>(false)
    const [isFindable, setIsFindable] = useState<boolean>(true)
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false)
    const [selectedCountry, setSelectedCountry] = useState<countryI>({});
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    // Hooks
    const { t } = useTranslation()
    const history = useHistory()
    const countryPopupRef = useRef();
    const dispatch = useDispatch();
    const open1 = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const { licensePlateData } = useSelector((state: RootStateOrAny) => state.licensePlate)
    const { userData } = useSelector((state: RootStateOrAny) => state.user)
    const { subsidiaryDetails } = useSelector((state: any) => state.company)
    const { myWalletBalance } = useSelector((state: any) => state.auth)

    useEffect(() => {
        //by default user country
        if (Object.keys(subsidiaryDetails).length > 0) {
            setCountry(subsidiaryDetails?.limited_address?.country || '')
        }
        else if (userData.limited_address) {
            setCountry(userData.limited_address?.country || "")
        }

    }, [userData, subsidiaryDetails])

    // Reseting fields only if user successfully created any license field or subsidiary is changed
    useEffect(() => {
        if ((licensePlateData && Object.keys(licensePlateData).length > 0) || (getSubsidiaryStored() || Object.keys(userData).length > 0)) {
            setSelectedVehicle({})
            setLicensePlateNumber('')
            setCountry(userData?.limited_address?.country || '')
            if (Object.keys(subsidiaryDetails).length > 0) {
                setCountry(subsidiaryDetails?.limited_address?.country || '')
            }
            setIsFindable(true)
        }
    }, [licensePlateData, getSubsidiaryStored()])


    // Functions for handling select Vehicles
    const handleChangeVehicle = () => {
        setOpen(true)
        setSelectedVehicleError('')
    }

    // Functions for handling License Plate Number
    const handleLicensePlateNumber = (event) => {
        let val = event.target.value;
        if (spaceValidationRegex.exp.test(val)) {
            if (val.length <= validation.licensePlate.max) {
                setLicensePlateNumber(event.target.value as string)
                setLicensePlateNumberError('')
                return
            }
            // setLicensePlateNumberError('Only 12 characters are allowed')
        }
    }

    //Funtion for creating license Plate
    const saveLicensePlate = () => {
        if (selectedVehicle && !Object.keys(selectedVehicle).length) {
            // Show error vehicle is not seleced while create License plate
            setSelectedVehicleError(t(translations.validationAndPopText.REQUIRED_FEILD))
        }
        if (!licensePlateNumber) {
            // Error to display that LicensePlate field is required while create License plate
            setLicensePlateNumberError(t(translations.validationAndPopText.REQUIRED_FEILD))
        }
        if (!country) {
            // Error to display that country is required while create License plate 
            setCountryError(t(translations.validationAndPopText.REQUIRED_FEILD))
        }
        if ((selectedVehicle && !Object.keys(selectedVehicle).length) || !licensePlateNumber || !country) {
            return
        }
        let newLicensePlate = {
            owner_vehicle_id: selectedVehicle.id,
            license_plate: licensePlateNumber,
            country_id: selectedCountry.id ? selectedCountry.id : userData?.limited_address?.country_id,
            searchable: isFindable ? 1 : 0,
        }
        dispatch(createLicensePlateRequest({ newLicensePlate }))
    }


    // Funtion for setting findable for 
    const handleFindable = () => {
        if (isFindable) {
            setOpenConfirmationPopup(true)
            return
        }
        setIsFindable(prevState => !prevState)
    }
    // handle searchable License plate for public
    const hideLicensePlateFromPublic = () => {
        setIsFindable(prevState => !prevState)
        setOpenConfirmationPopup(false)
    }
    // Function for selecting country from drop down
    const selectCountry = (data) => {
        setSelectedCountry(data)
        setCountry(data.fullname)
        setAnchorEl(null)
    }

    // function to handle Popup(dropdown for select country)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setCountryError('')
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        // Set country id and license plate for filter user
        if (setLicensePlateAndCountry) {
            setLicensePlateAndCountry({ country: selectedCountry.id ? selectedCountry.id : userData?.limited_address?.country_id, LicensePlate: licensePlateNumber })
        }
    }, [country, licensePlateNumber])

    const truncateStringFromMiddle = (fullStr, strLen, middleStr = '...',) => {
        if (fullStr.length <= strLen) return fullStr;
        const midLen = middleStr.length;
        const charsToShow = strLen - midLen;
        const frontChars = Math.ceil(charsToShow / 2);
        const backChars = Math.floor(charsToShow / 2);
        return (
            fullStr.substr(0, frontChars) +
            middleStr +
            fullStr.substr(fullStr.length - backChars)
        );
    };


    return (
        <Box>
            {!hideItems && <h4>{t(translations.LICENSEPLATE.CREATE_LIC_PLATE)}</h4>}
            <Box sx={{
                ".grey-text": {
                    color: '#999999',
                    fontSize: '15px',
                    fontWeight: 400,
                    textTransform: 'none',
                    marginRight: '10px',
                },
                ".vin": {
                    color: 'rgb(25, 25, 25)',
                    textTransform: 'none'
                },
                ".right-arrow": {
                    'path': {
                        stroke: '#000000',
                        fill: '#fff',
                    }
                }
            }}>
                {/*Select vehicle (cars only)  */}
                {!hideItems && <Box sx={{
                    border: '1px solid rgb(229, 229, 229);',
                    borderRadius: '5px'
                }}>
                    <TextField
                        sx={{
                            zIndex: 1,
                            "& fieldset": { border: 'none' },
                            "& label": {
                                background: 'white',
                                padding: '0 10px',
                            },
                        }}
                        fullWidth
                        value={(selectedVehicle && selectedVehicle.full_title) ? truncateStringFromMiddle(selectedVehicle?.full_title, 30, '......') : t(translations.webTexts.NOT_SELECTED)}
                        label={t(translations.webTexts.SELECT_VEHICLE)}
                        onClick={(e) => handleChangeVehicle(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment className='pointer right-arrow' position="start" onClick={(e) => handleChangeVehicle(e)}><SvgChevronRight /></InputAdornment>,
                        }}
                    />
                    {selectedVehicle && Object.keys(selectedVehicle).length > 0 && <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, flexWrap: 'wrap' }}>
                        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap" }}>
                            <span className='grey-text'>{t(translations.ads.VIN)} : </span> <span className='vin'>{selectedVehicle.vin ? selectedVehicle.vin : "-"}</span>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap" }}>
                            <span className='grey-text'>{t(translations.ads.YEAR)} : </span> <span className='vin'>{selectedVehicle.vehicle_first_register_year ? selectedVehicle.vehicle_first_register_year : t(translations.webTexts.NEWW)}</span>
                        </Box>
                    </Box>}

                </Box>}

                {selectedVehicleError ? <span className='error'>{selectedVehicleError}</span> : null}
                {/*input for  Select Country */}
                <TextField
                    className='scrollbar countryField'
                    ref={countryPopupRef}
                    sx={{
                        fieldset: {
                            border: '1px solid rgb(229, 229, 229)'
                        },
                        mt: 2,
                        '& input': {
                            cursor: "pointer"
                        }

                    }}
                    fullWidth
                    value={country ? country : t(translations.webTexts.NOT_SELECTED)}
                    label={t(translations.profileScreen.COUNTRY)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onClick={(e) => handleClick(e)}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment className='pointer right-arrow' position="start" ><SvgChevronRight /></InputAdornment>,
                    }}
                />
                {countryError ? <span className='error'>{countryError}</span> : null}

                <Box className='flexRow'>
                    {/* input for License plate number  */}
                    <TextField
                        className='countryField'
                        sx={{
                            fieldset: {
                                border: '1px solid rgb(229, 229, 229)'
                            },
                            mt: 2
                        }}
                        fullWidth
                        value={licensePlateNumber.toUpperCase()}
                        label={t(translations.ads.LPLATE)}
                        onChange={(e) => handleLicensePlateNumber(e)}
                    />

                    {/* Search appears in chats only */}
                    {hideItems && <SvgmemSearch onClick={() => {
                        if (myWalletBalance < 1) {
                            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.HOT_DEALS.BUY_NOW)} message={t(translations.HOT_DEALS.LOW_CREDITS)} click={() => handleBuyCredits(history, routes.userRoutes.chat)} />, false)
                            return;
                        }
                        handleSearchForLicensePlate()
                    }} className='licensePlateSearchButton pointer' />}
                </Box>
                {licensePlateNumberError ? <span className='error'>{licensePlateNumberError}</span> : null}
                {hideItems && <span className='creditChargeNote'><SvgInfoIcon /> {t(translations.HOT_DEALS.CREDIT_CHARGE_ON_SEARCH, { amount: 1 })}</span>}

                {/* toggle button for findable for public  */}
                {!hideItems &&
                    <Box sx={{ mt: 2 }}>
                        <Stack direction="row" spacing={1} alignItems="center" gap='10px'>
                            <AntSwitch name={"findable"} checked={isFindable} onChange={() => handleFindable()} inputProps={{ 'aria-label': 'ant design' }} className="toggle_icon" />
                            {t(translations.LICENSEPLATE.FINDABLE_FOR_PUBLIC)}
                        </Stack>
                    </Box>
                }
            </Box>
            {/* create license plate  button */}
            <Box className='save-btn'>
                {!hideItems && <ButtonCustom clickFunction={saveLicensePlate} textBtn={t(translations.profileScreen.SAVE)} btnColor={'primary'} style={{ mt: 2, mb: 2, width: "100%", height: '53px', fontSize: '18px' }} />}
            </Box>
            {/* Confirmation Box */}
            <DeleteConfirm open={openConfirmationPopup} setOpen={setOpenConfirmationPopup} confirmBtn={t(translations.ads.CONFIRM)} deleteFunc={hideLicensePlateFromPublic} message={t(translations.LICENSEPLATE.FINDABLE_CONFIRMATION)} />
            {/* list of suggested ads to create License plate */}
            <SuggestedVehiclesList setSelectedVehicle={setSelectedVehicle} selectedVehicle={selectedVehicle} openPopup={open} setOpen={setOpen} />
            {/* PopOver Country list to create License-plate */}
            <CountryByContinent countryPopupRef={countryPopupRef} id={id} open1={open1} anchorEl={anchorEl} handleClose={handleClose} filteredCountry={filteredCountry} SelectCountry={selectCountry} selectedCountry={country} />
        </Box>
    )
}

export default CreateLicensePlate