import { Autocomplete, Box, Drawer, Radio, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FieldWrapper } from './style'
import { SvgMobileLeftArrow, SvgchevronIcon } from '../svgicons/svgNew'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { urlParamInterface } from 'config/interface'
import { useParams } from 'react-router-dom'


// Code for list of dropdown elements
const ListElement = ({ list, isSelected, handleChange }) => {
    return (
        <Box className='optionsMainContainer' >
            <Box className='flexColumn optionListContainer scrollBar'>
                {
                    (list && list.length > 0) &&
                    list.map((option) => {
                        return <Box key={option.item_name + option.id} className='optionContainer' sx={{ backgroundColor: isSelected(option.id) ? 'aliceblue' : '' }} onClick={() => { handleChange(option.id) }}>
                            <Box className='flexRow alignItemsCenter nameContainer'>
                                <span className='optionValue'>{option.item_name}</span>
                            </Box>
                            <span className={(isSelected(option.id) ? 'selected' : '') + ' selectDiv'}></span>

                        </Box>
                    })

                }
            </Box>
        </Box>
    )
}

const SearchDropdownForProduct = ({ title, value, list, handleChange }) => {

    const { t } = useTranslation()
    const { vehicle }: urlParamInterface = useParams();

    const [openDropdown, _openDropdown] = useState<boolean>(false)
    const [showValue, _showValue] = useState<string>('')
    const [isSmallDevice] = useDeviceType()

    // Generating text to show as selected value in field
    useEffect(() => {
        _openDropdown(false)
        if (value !== null && value !== undefined && value !== '' && list && list.length > 0) {
            let existence = list.findIndex((option) => option.id == value)
            if (existence != -1) {
                _showValue(list[existence].item_name)
            }
        }
    }, [value, list, vehicle])


    const isSelected = (id) => {
        if (value != '' && value) {
            if (value == id) {
                return true
            }
            return false
        }
    }

    const selectedValue = React.useMemo(
        () => {
            return list.find(option => option.id == value) ?? null
        },
        [list, value]
    );


    return (
        <FieldWrapper>
            {isSmallDevice &&
                <Box className='field flexRow alignItemsCenter'>
                    <span className='fieldHeader'>{title}</span>
                    <Box className='inputBox flexRow justifyContentBetween alignItemsCenter pointer' sx={{ px: 2, width: '100%', 'svg': { 'path': { stroke: '#666' } } }} onClick={() => _openDropdown((previousState) => !previousState)}>
                        <span className='selectedValue'>
                            {(value == null || value == undefined || value == '') ? t(translations.webTexts.NOT_SELECTED) : showValue}
                        </span>
                        <SvgchevronIcon />
                    </Box>

                    <Drawer
                        anchor='bottom'
                        open={openDropdown}
                        onClose={() => _openDropdown(false)}
                        sx={{
                            '.MuiPaper-root': {
                                maxHeight: "70%",
                                borderRadius: '6% 6% 0 0',
                                overflowY: 'inherit'
                            },
                        }}>
                        <FieldWrapper>
                            <Box className='drawerTitleStyle'>
                                <SvgMobileLeftArrow onClick={() => _openDropdown(false)} />
                                <span>{title}</span>
                                <SvgMobileLeftArrow style={{ opacity: 0 }} />
                            </Box>
                            <ListElement list={list} handleChange={handleChange} isSelected={isSelected} />
                        </FieldWrapper>
                    </Drawer>
                </Box>
            }
            {!isSmallDevice &&
                <Autocomplete
                    popupIcon={<SvgchevronIcon />}
                    getOptionLabel={(option) => option.item_name}
                    id="checkboxes-tags-demo"
                    options={list}
                    open={openDropdown}
                    onOpen={() => _openDropdown(true)}
                    onClose={() => _openDropdown(false)}
                    renderInput={(params) => <TextField {...params} label={title} placeholder={!openDropdown ? t(translations.webTexts.NOT_SELECTED) : null} InputLabelProps={{ shrink: true }} className='autocompleteInputField' />}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ paddingLeft: '10px' }} className="flexRow justifyContentBetween alignItemsCenter filterDropDownOption pointer" key={option.name + option.id}>
                            <span className='optionValue'>{option.item_name}</span>
                            <Radio
                                checked={selected}
                            />
                        </li>
                    )}
                    value={selectedValue}
                    onChange={(event: any, newValue: string | null) => {
                        if (newValue?.id) {
                            handleChange(newValue.id);
                        }
                    }}
                    disablePortal
                    disableClearable={true}
                />
            }
        </FieldWrapper>
    )
}

export default SearchDropdownForProduct