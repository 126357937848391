import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next'
import { colorNew } from 'styles/StyleConstants';
import { SvgSearchLite } from 'app/components/svgicons/svg';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { createLicensePlateSuccess, deleteLicensePlateRequest, deleteLicensePlateSuccess, findableForPublicRequest, findableForPublicSuccess, getVehicleLicensePlateListRequest, getVehicleListRequest } from 'store/actions/licensePlate-action';
import { Toaster } from 'services/Toaster';
import DeleteConfirm from 'app/components/stylesComponents/Ads/DeleteConfirm';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';
import { LicensePlateListI, LicensePlateslistI } from '../Interface/interface';
import LicensePlate from './LicensePlate';
import { useWindowSize } from 'app/Hooks/windowResize';
import { routes } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { getSubsidiaryStored } from 'config/variables';


const LicensePlateList = ({ setList, list, page, setPage, setOpenCreateLicenseScreen }: LicensePlateListI) => {

  // local States 
  const [licensePlatelist, setLicensePlatelist] = useState<Array<LicensePlateslistI>>([])
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [scrollFlag, _scrollFlag] = useState<boolean>(true);
  const [index, setIndex] = useState<number>();
  const [lPlateId, setlPlateId] = React.useState<number>();
  const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
  const { userData } = useSelector((state: RootStateOrAny) => state.user)
  const subsidiary = getSubsidiaryStored();
  // Hooks  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [mobile, width] = useWindowSize();
  const scrollRef: any = useRef(null);
  // Redux states
  const { findableForPublicResponse, VehicleLicensePlateListLoading, licensePlateData, VehicleLicensePlateListLastPage, vehicleListLastPage, deletePlateResponse } = useSelector((state: any) => state.licensePlate)

  //Function request for next page data when scrolled to bottom
  const handleScroll = (e) => {
    // bottom return boolean value and Math.abs to get absolute value 
    const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;

    if (bottom && scrollFlag && page < VehicleLicensePlateListLastPage) {
      setPage(page + 1)
      dispatch(getVehicleLicensePlateListRequest({ page: page + 1 }));
      _scrollFlag(false)
      return;
    }
    _scrollFlag(true)
  }

  useEffect(() => {
    if (list) {
      setLicensePlatelist(list)
      return
    }
    setLicensePlatelist([])
  }, [list])

  useEffect(() => {
    if (licensePlateData && Object.keys(licensePlateData).length > 0) {
      // Prepending newly added LicensePLate at top of list
      setLicensePlatelist([licensePlateData, ...licensePlatelist])
      dispatch(createLicensePlateSuccess({}))
      setOpenCreateLicenseScreen(false)
    }
  }, [licensePlateData])

  useEffect(() => {
    // Search License Plate
    if (searchKeyWord != undefined) {
      const delayDebounceFn = setTimeout(() => {
        // if search vehicle by keyword    
        dispatch(getVehicleLicensePlateListRequest({ keyword: searchKeyWord }));
        return
      }, 500)
      return () => clearTimeout(delayDebounceFn)
    }
    // setLicensePlatelist(list || [])
  }, [searchKeyWord, subsidiary])

  useEffect(() => {
    //handle delete license plate response
    if (deletePlateResponse && Object.keys(deletePlateResponse).length > 0 && deletePlateResponse.data.value) {
      let licensePlates = [...licensePlatelist]
      Toaster.success(t(translations.LICENSEPLATE.LICENSE_PLATE_DELETED_SUCCESSFULLY))
      let removeLicensePlate = licensePlates.filter((licensePlate) => licensePlate.license_plate?.id !== lPlateId)
      // setLicensePlatelist(Remove)
      setList(removeLicensePlate)
      dispatch(deleteLicensePlateSuccess({}))
    }
  }, [deletePlateResponse, lPlateId])

  // handle searchablility of license plate
  const handleFindable = (PlateId, indx) => {
    setIndex(indx)
    dispatch(findableForPublicRequest({
      licensePlate: PlateId,
      searchable: licensePlatelist[indx].license_plate.searchable == 0 ? 1 : 0
    }))
  }

  useEffect(() => {
    // code for enable/disable findable toggle on license plate list
    if (findableForPublicResponse && Object.keys(findableForPublicResponse).length && findableForPublicResponse.success && (index == 0 || index)) {
      Toaster.success(findableForPublicResponse.message)
      let prevData = [...licensePlatelist]
      // update findable status
      prevData[index].license_plate.searchable = prevData[index].license_plate.searchable == 0 ? 1 : 0
      setLicensePlatelist(prevData)
      dispatch(findableForPublicSuccess({}))
    }
  }, [findableForPublicResponse])

  // handle search keywords
  const handleKeyPress = (event) => {
    setSearchKeyWord(event.target.value);
  }

  // handle Delete LicensePLate
  const handleRemove = (licensePlateId) => {
    dispatch(deleteLicensePlateRequest({ licensePlate: licensePlateId }))
    setOpenConfirmation(false)
  }
  //handle open Confirmation box before removing licensePlate
  const handleRemovePopOver = (licensePlateId) => {
    if (licensePlateId) {
      setOpenConfirmation(true)
      setlPlateId(licensePlateId)
    }
  }

  function convertBetweenAsterisksToLink(inputString) {
    const regex = /(.*)\*(.*?)\*(.*)/;
    const match = inputString.match(regex);

    if (match && match.length === 4) {
      return {
        beforeLinkString: match[1].trim(),
        LinkString: match[2].trim(),
        afterLinkString: match[3].trim(),
      };
    }

    return null;
  }
  // Changes text to hyperlink if any of the link exists for text 
  const renderTagLine = (item) => {
    const extratedParts = convertBetweenAsterisksToLink(item);
    if (extratedParts) {
      return <>
        {extratedParts.beforeLinkString}
        <span className='link pointer no-padding no-border' style={{ color: 'blue' }} onClick={() => {
          history.push(routes.userRoutes.idVerfication)
          return
        }}
        >
          {" " + extratedParts.LinkString + " "}
        </span>
        {extratedParts.afterLinkString}
      </>

    }
    return item
  }
  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Box className='flex-div'>
        <h4>{t(translations.LICENSEPLATE.LIST_LIC_PLATE)}</h4>
        <Box
          sx={{
            alignItems: 'center',
            mr: {
              md: 'initial',
              xs: 1
            },
            maxWidth: {
              sm: "230px",
              xs: "130px"
            },
            display: 'flex',
            flexDirection: 'row'
          }}
          onScroll={(e) => handleScroll(e)} ref={scrollRef}
          className='vehicle_search'>
          {/* search input box on License Plate list page */}
          {width > 767 && <TextField
            variant="standard"
            type="text"
            placeholder={t(translations.ads.SEARCH) + " " + t(translations.showCaseForm.TITLE) + ', VIN, Refr.'}
            onChange={handleKeyPress}
            value={searchKeyWord}
            InputProps={{
              endAdornment: <InputAdornment position="end"><SvgSearchLite style={{ marginRight: '5px' }} />
              </InputAdornment>,
            }}
          />}
        </Box>
      </Box>
      {/* License plate listing */}
      <Box className='scrollBar' sx={{
        zIndex: 100,
        overflowY: 'auto',
        height: mobile ?
          document.getElementById('openApp') !== null ?
            navigator.platform === 'iPhone' && (navigator.userAgent.includes('Mozilla') || navigator.userAgent.includes('Chrome')) ? 'calc(100vh - 350px) !important'
              : 'calc(100vh - 320px) !important'
            : navigator.platform === 'iPhone' && (navigator.userAgent.includes('Mozilla') || navigator.userAgent.includes('Chrome')) ? 'calc(100vh - 290px) !important'
              : 'calc(100vh - 260px) !important'
          : 'calc(100vh - 285px) !important'
      }} onScroll={(e) => handleScroll(e)} ref={scrollRef}>
        {licensePlatelist?.length ?
          <>
            {licensePlatelist.map((licensePlate, index) => {
              return <Box key={licensePlate.license_plate.id} sx={{ mt: 2, mb: 2 }}>
                <LicensePlate
                  vehicleDetail={licensePlate}
                  handleFindable={handleFindable}
                  handleRemove={handleRemovePopOver}
                  searchable={licensePlate.license_plate.searchable}
                  recIndex={index}
                />
              </Box>
            })

            }
          </>
          : VehicleLicensePlateListLoading ? <ContentLoader /> : <Typography sx={{ color: `${colorNew.colorGrayDark}` }}>{searchKeyWord !== '' ? t(translations.ads.NOT_FOUND) : t(translations.LICENSEPLATE.NOT_LICENSE_PLATE)}</Typography>}
      </Box>
      <DeleteConfirm open={openConfirmation} setOpen={setOpenConfirmation} deleteFunc={() => handleRemove(lPlateId)} message={((!subsidiary && userData.profile_is_verified) || subsidiary) ? t(translations.LICENSEPLATE.DELETE_LICENSE_PLATE_CONFIRMATION) : renderTagLine(t(translations.LICENSEPLATE.DELETE_LICENSE_PLATE_CONFIRMATION_FOR_NON_VERIFIED_USERS))} confirmBtn={t(translations.CHAT.REMOVE_FROM_GROUP)} />
    </Box>
  )
}

export default LicensePlateList