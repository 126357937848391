import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react'
import { PublicProfileWrapper } from '../../../style/publicProfileStyle';
import { TextField } from '@mui/material';
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom';
import { SvgMobileLeftArrow } from 'app/components/svgicons/svgNew';
import { Toaster } from 'services/Toaster';
import { useWindowSize } from 'app/Hooks/windowResize';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import DeleteConfirm from 'app/components/stylesComponents/Ads/DeleteConfirm';
import { useDispatch } from 'react-redux';
import { flagReviewsRequest } from 'store/actions/publicProfile-action';


const FlagComment = ({recId}) => {

    const { t } = useTranslation()
    const [mobile] = useWindowSize()
    const dispatch = useDispatch()

    const [open, setOpen] = useState<boolean>(false);
    const [openCofirmationBox, setOpenCofirmationBox] = useState<boolean>(false);

    const [comment,setComment] = useState<string>('')

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setComment('')
        setOpen(false);
    }

    const handleComment = (e) => {    
        if(e.target.value.replace(/\n/g, '  ').length>500){
            Toaster.error(t(translations.PUBLICPROFILE.COMMENT_CANT_EXCEED_500_CHARS))
            return ;
        }
        setComment(e.target.value)
    }

    const handleSubmit = () =>{
        let payload = {
            id : recId,
            comment : comment
        }
        dispatch(flagReviewsRequest(payload))
        setOpenCofirmationBox(false)
        setOpen(false)
    }

    return (
        <>
            <Box className="pointer " onClick={handleOpen}>
                <span>{t(translations.PUBLICPROFILE.REPORT_REVIEW)}</span>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <PublicProfileWrapper>
                <Box className={mobile ? 'openMobileModal' : 'openModal'}>
                    <Box>
                        <Box className='flexRow justifyContentBetween alignItemsCenter' sx={{marginBottom:'10px'}}>
                            <SvgMobileLeftArrow onClick={handleClose} className='pointer'/>
                            <span style={{fontSize: '18px'}}>{t(translations.PUBLICPROFILE.FLAG_COMMENT)}</span>
                            <span>{" "}</span>
                        </Box>
                        <TextField
                            className='reviewField scrollBar'
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            placeholder={t(translations.PUBLICPROFILE.REASON)}
                            value={comment}
                            onChange={(e)=>handleComment(e)}
                            />
                    </Box>
                        <ButtonCustom textBtn={t(translations.PUBLICPROFILE.SUBMIT_REQUEST)} btnColor="primary" style={{ marginTop: '20px', width: '100%' }} clickFunction={() => {
                            if (comment == '') {
                                Toaster.error(t(translations.PUBLICPROFILE.COMMENT_CANT_BE_BLANK))
                                return;
                            }
                            setOpenCofirmationBox(true)
                        }}/>
                </Box>
                </PublicProfileWrapper>
            </Modal>
            <DeleteConfirm open={openCofirmationBox} setOpen={setOpenCofirmationBox} deleteFunc={handleSubmit} confirmBtn={t(translations.PUBLICPROFILE.SUBMIT)} message={t(translations.PUBLICPROFILE.ARE_YOU_SURE_TO_FLAG_THIS_USER)} />
        </>
    )
}

export default FlagComment