import { localstorageKey } from 'config/constants';
import { channelName, pusherName } from 'config/utils';
import { getSubsidiaryStored } from 'config/variables';
import Pusher from 'pusher-js';
import { getAcceptLanguage } from 'config/variables';

const isSub = getSubsidiaryStored();
const isLoggedIn = localStorage.getItem(localstorageKey.user);

const window: any = {};
window.Pusher = Pusher;
Pusher.logToConsole = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test';

let configGration = {};
let pusherKey;
pusherKey = process.env.REACT_APP_PUSHER_KEY;
configGration['cluster'] = process.env.REACT_APP_PUSHER_CLUSTER;

let pusherInstance = {};
let PrivatePusherClientInstance = {};

// Public pusher
export const PusherClient = () => {

  if (!Object.keys(pusherInstance).length) {
    pusherInstance = new Pusher(pusherKey, configGration);
  }
  return pusherInstance;
};

// Private Pusher
export const PrivatePusherClient = () => {
  if (!Object.keys(PrivatePusherClientInstance).length) {
    PrivatePusherClientInstance = new Pusher(pusherKey, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(localstorageKey.token),
          "Access-Control-Allow-Origin": "*",
          'Accept': 'application/json',
          'Accept-Language': getAcceptLanguage()
        }
      }
    });
  }
  return PrivatePusherClientInstance;
};

if (isLoggedIn) {
  const instance = PusherClient();
  instance?.subscribe(channelName(isSub));
}

