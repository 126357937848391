import { Typography } from '@mui/material'
import { DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice'
import { lan } from 'locales/multiPageTranslation/adsLan'
import { useTranslation } from 'react-i18next'
import { colorNew } from 'styles/StyleConstants'
import sanitizeHtml from 'sanitize-html';

const VehicleDescription = ({ item }) => {
    const { t } = useTranslation();

    return (
        <>
            {item?.owner_vehicle.vehicle_description ?
                <>
                    <DetailsTitle sx={{ ml: '15px' }}>{t(...lan.description())}</DetailsTitle>
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: colorNew.colorGrayDark,
                            lineHeight: '20px',
                            ml: '15px'
                        }}
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(item?.owner_vehicle?.vehicle_description?.replace(/\n/g, '<br />')) }}
                    >
                    </Typography></> : null}

        </>
    )
}

export default VehicleDescription