import { put, takeEvery } from 'redux-saga/effects';
import { getUserDataSuccess } from 'store/actions/user-actions';
import {
  checkVatRequest,
  checkVatSuccess,
  checkVatFailure,
  checkSlugRequest,
  checkSlugSuccess,
  checkSlugFailure,
  createParentRequest,
  createParentSuccess,
  createParentFailure,
  createCompanyRequest,
  createCompanySuccess,
  createCompanyFailure,
  getSubsidiaryDetailsRequest,
  getSubsidiaryDetailsSuccess,
  getSubsidiaryDetailsFailure,
  createRoleRequest,
  createRoleSuccess,
  createRoleFailure,
  getRoleDetailsRequest,
  getRoleDetailsSuccess,
  getRoleDetailsFailure,
  editRoleRequest,
  editRoleSuccess,
  editRoleFailure,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleFailure,
  getRolesRequest,
  getRolesSuccess,
  getRolesFailure,
  getUserSubsidiaryRequest,
  getUserSubsidiarySuccess,
  getUserSubsidiaryFailure,
  getSubscriptionPlansRequest,
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFailure,
  getSubscriptionPlanDetailsRequest,
  getSubscriptionPlanDetailsSuccess,
  getSubscriptionPlanDetailsFailure,
  getFeaturesRequest,
  getFeaturesSuccess,
  getFeaturesFailure,
  getPermissionsRequest,
  getPermissionsSuccess,
  getPermissionsFailure,
  createSubscriptionRequest,
  createSubscriptionSuccess,
  createSubscriptionFailure,
  getSubsidiarySubscriptionStatusRequest,
  getSubsidiarySubscriptionStatusSuccess,
  getSubsidiarySubscriptionStatusFailure,
  getMembersRequest,
  getMembersSuccess,
  getMembersFailure,
  changeStatusSubsidiaryMemberRequest,
  changeStatusSubsidiaryMemberSuccess,
  changeStatusSubsidiaryMemberFailure,
  deleteSubsidiaryMemberRequest,
  deleteSubsidiaryMemberSuccess,
  deleteSubsidiaryMemberFailure,
  editSubsidiaryMemberRequest,
  editSubsidiaryMemberSuccess,
  editSubsidiaryMemberFailure,
  updateSubsidiaryMemberRolesRequest,
  updateSubsidiaryMemberRolesSuccess,
  updateSubsidiaryMemberRolesFailure,
  getSuggestedMembersRequest,
  getSuggestedMembersSuccess,
  getSuggestedMembersFailure,
  addSubsidiaryMembersRequest,
  addSubsidiaryMembersSuccess,
  addSubsidiaryMembersFailure,
  getDepartmentsRequest,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  getSubsidiaryDepartmentsRequest,
  getSubsidiaryDepartmentsSuccess,
  getSubsidiaryDepartmentsFailure,
  addSubsidiaryDepartmentsRequest,
  addSubsidiaryDepartmentsSuccess,
  addSubsidiaryDepartmentsFailure,
  deleteSubsidiaryDepartmentsRequest,
  deleteSubsidiaryDepartmentsSuccess,
  deleteSubsidiaryDepartmentsFailure,
  getDepartmentSchedulesRequest,
  getDepartmentSchedulesSuccess,
  getDepartmentSchedulesFailure,
  addDepartmentSchedulesRequest,
  addDepartmentSchedulesSuccess,
  addDepartmentSchedulesFailure,
  updateSubsidiaryPrivacyRequest,
  updateSubsidiaryPrivacySuccess,
  updateSubsidiaryPrivacyFailure,
  getAllCardsRequest,
  getAllCardsSuccess,
  getAllCardsFailure,
  addCardRequest,
  addCardSuccess,
  addCardFailure,
  deleteCardRequest,
  deleteCardSuccess,
  deleteCardFailure,
  updateCardRequest,
  updateCardSuccess,
  updateCardFailure,
  createStripeCustomerRequest,
  createStripeCustomerSuccess,
  createStripeCustomerFailure,
  getCheckoutTotalRequest,
  getCheckoutTotalSuccess,
  getCheckoutTotalFailure,
  addAppointmentPeriodRequest,
  addAppointmentPeriodSuccess,
  addAppointmentPeriodFailure,
  getDepartmentAvailabilityRequest,
  getDepartmentAvailabilitySuccess,
  getDepartmentAvailabilityFailure,
  deleteSubsidiaryRequest,
  deleteSubsidiarySuccess,
  deleteSubsidiaryFailure,
  getUserSubsidiariesRequest,
  getUserSubsidiariesSuccess,
  getUserSubsidiariesFailure,
  updateVatRequest,
  updateVatSuccess,
  updateVatFailure,
  editSubsidiaryRequest,
  editSubsidiarySuccess,
  editSubsidiaryFailure,
  getInvoiceRequest,
  getInvoiceSuccess,
  getInvoiceFailure,
  getInvoiceDetailRequest,
  getInvoiceDetailSuccess,
  getInvoiceDetailFailure,
  addSubsidiaryLogoRequest,
  addSubsidiaryLogoSuccess,
  addSubsidiaryLogoFailure,
  handleInvitationUserRequest,
  handleInvitationUserSuccess,
  handleInvitationUserFailure,
  downloadPdfRequest,
  downloadPdfSuccess,
  downloadPdfFailure,
  reactivateSubscriptionRequest,
  reactivateSubscriptionSuccess,
  reactivateSubscriptionFailure,
  cancelStripeScheduleRequest,
  cancelStripeScheduleSuccess,
  cancelStripeScheduleFailure,
  previewPriceRequest,
  previewPriceSuccess,
  previewPriceFailure,
  changePlanSuccess,
  changePlanRequest,
  changePlanFailure,
  subscriptionPlanDetailRequest,
  subscriptionPlanDetailSuccess,
  subscriptionPlanDetailFailure,
  cancelUpcomingSubscriptionRequest,
  cancelUpcomingSubscriptionSuccess,
  cancelUpcomingSubscriptionFailure,
  getNotificationsRequest,
  getNotificationsFailure,
  getNotificationsSuccess,
  readNotificationRequest,
  readNotificationFailure,
  readNotificationSuccess,
  readAllNotificationsRequest,
  readAllNotificationsFailure,
  readAllNotificationsSuccess,
  deleteNotificationRequest,
  deleteNotificationFailure,
  deleteNotificationSuccess,
  respondNotificationRequest,
  respondNotificationFailure,
  respondNotificationSuccess,
  openNotificationsRequest,
  openNotificationsFailure,
  openNotificationsSuccess,
  getNotificationCountRequest,
  getNotificationCountFailure,
  getNotificationCountSuccess,
  createIntentRequest,
  createIntentFailure,
  createIntentSuccess,
  storeNewCardSuccess,
  storeNewCardRequest,
  showLoaderRequest,
  hideLoaderRequest,
  getNotificationsSuccessInvitation,
  getNotificationsSuccessCartobike,
  getSubsidiaryDetailsChange,
  privateSwitchSuccess,
  privateSwitchRequest,
  createSubscriptionErrorMessage,

} from '../actions/company-actions';

import { ApiPaths } from './../../services/ApiPaths';
import Api from './../../services/axios';
import { updateMyWalletBalance } from 'store/actions/auth-actions';
import { getSubsidiaryStored } from 'config/variables';

export const saveSubsidiaryToLocal = (params: any) => {
  localStorage.setItem('subsidiary', JSON.stringify(params));
}

export const savePlanIdToLocal = (params: any) => {
  localStorage.setItem('planId', JSON.stringify(params))
}

export const saveUserDataToLocal = (params: any) => {
  localStorage.setItem('user', JSON.stringify(params));
}

export const saveActiveSubsidiaryToLocal = (params: any) => {
  localStorage.setItem('activeSubsidiary', params);
}

export const getActiveSubsidiaryStored = () => {
  return localStorage.getItem('activeSubsidiary');
}

export const removeActiveSubsidiaryFromLocal = () => {
  localStorage.removeItem('activeSubsidiary');
}


function* _checkVatRequest({ payload }) {
  try {
    const uri = ApiPaths.company.checkVat;
    const { data } = yield Api.post(uri, {
      "country_id": payload.countryId,
      "vat_number": payload.vatNumber
    });
    const row = data.data;
    yield put(checkVatSuccess(row));
  } catch (e: any) {
    yield put(checkVatFailure(e.response));
  }
}

function* _checkSlugRequest({ payload }) {
  try {
    const uri = ApiPaths.company.checkSlug;
    const { data } = yield Api.get(uri, {
      params: {
        name: payload.name,
        slug: payload.slug,
      }
    });
    const row = data.data
    yield put(checkSlugSuccess(row));
  } catch (e: any) {
    yield put(checkSlugFailure());
  }
}

function* _createParentRequest({ payload }) {
  try {
    let parentUri = ApiPaths.company.createParent;
    const { data } = yield Api.post(parentUri, {
      vat_number: payload.vatNumber,
      prefix_vat: payload.countryCode
    });
    const row = data.data
    yield put(createParentSuccess(row));
    let userData = yield getUserRequest();
    yield saveUserDataToLocal(userData.data.user);
  } catch (e: any) {
    yield put(createParentFailure(e.response));
  }
}

function* _createCompanyRequest({ payload }) {
  try {
    let id = payload.parentId;
    let uri = ApiPaths.company.createParent + "/" + id + ApiPaths.company.subsidiary;
    let payloadHit1 = {
      slug: payload.slug,
      name: payload.companyName,
      email_principal: payload.email,
      phone_principal: payload.phone,
      display_phone: true,
      display_address: true,
    }
    if (payload?.year && payload.year !== null) {
      payloadHit1['foundation_date'] = payload.year
    }
    let subsidiaryData = yield Api.post(uri, payloadHit1)

    let subsidiaryId = subsidiaryData.data.data.subsidiary.id;
    uri = ApiPaths.company.addSubsidiaryAddress + "?subsidiary_id=" + subsidiaryId;
    let payloadHit = {
      label_address: payload.addressType,
      street_number: payload.street_number,
      route: payload.route,
      postal_code: payload.postal_code,
      locality: payload.locality,
      lat: payload.lat,
      long: payload.long,
      country_id: payload.countryId,
      addressId: payload.addressId,
      formatted_address: payload.formatted_address,
      google_place_id: payload.place_id,
      adm_area_l1_region: payload.administrative_area_level_1,
      adm_area_l2_province: payload.administrative_area_level_2
    }
    yield Api.post(uri, payloadHit);
    const formdata = new FormData();
    if (payload.logo != undefined) {
      formdata.append('logo', payload.logo)
      uri = ApiPaths.company.logoChange + "/" + subsidiaryId + "/logo";
      yield Api.post(uri, formdata)
    }
    saveActiveSubsidiaryToLocal(subsidiaryId);
    yield put(createCompanySuccess());
    let userData = yield getUserRequest();
    yield saveUserDataToLocal(userData.data.user);
  } catch (e: any) {
    yield put(createCompanyFailure(e.response.data));
  }
}


function* _getSubsidiaryDetailsRequest(payloadObject: any) {
  let payload = payloadObject;
  if ("payload" in payloadObject) {
    payload = payloadObject.payload;
  }
  try {
    let subsidiaryId = payload.subsidiaryId;
    let uri = ApiPaths.company.subsidiary + "/" + subsidiaryId;
    if (payload?.subsidiary_Id) {
      uri += '?subsidiary_id=' + payload?.subsidiary_Id
    }
    if ("saveSubsidiary" in payload) {
      if (payload.saveSubsidiary) {
        saveSubsidiaryToLocal(JSON.parse(payload.subsidiaryId));
      }
    }
    const { data } = yield Api.get(uri, {});
    showLoaderRequest()
    if (data.data.subsidiary.plan_id) {
      savePlanIdToLocal(data.data.subsidiary.plan_id)
    }
    const row = data.data
    // if("saveSubsidiary" in payload) {
    //   if(payload.saveSubsidiary) {
    //     yield saveSubsidiaryToLocal(row.subsidiary.id);
    //   }
    // }
    const changed = payload.saveSubsidiary ? true : false;
    yield put(getSubsidiaryDetailsChange(changed));
    yield put(getSubsidiaryDetailsSuccess(row));
    if (getSubsidiaryStored()) {
      yield put(updateMyWalletBalance(data?.data?.subsidiary?.wallet_balance ?? 0))
    }
    hideLoaderRequest()
  } catch (e: any) {
    yield put(getSubsidiaryDetailsFailure());
  }
}

function* _privateSwitchRequest(payload) {
  yield put(privateSwitchSuccess(payload))
}

function* _createRoleRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.roles;
    const { data } = yield Api.post(uri, {
      role_name: payload.roleName,
      description: payload.description,
      permission_ids: payload.permissionIds
    });
    const row = data.data
    yield put(createRoleSuccess(row));
  } catch (e: any) {
    yield put(createRoleFailure(e.response));
  }
}

function* _getRoleDetailsRequest({ payload }) {
  try {
    let roleId = payload.roleId;
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.roles + "/" + roleId;
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getRoleDetailsSuccess(row));
  } catch (e: any) {
    yield put(getRoleDetailsFailure());
  }
}

function* _editRoleRequest({ payload }) {
  try {
    let roleId = payload.roleId;
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.roles + "/" + roleId;
    const { data } = yield Api.post(uri, {
      role_name: payload.roleName,
      description: payload.description,
      permission_ids: payload.permissionIds,
      _method: "PUT"
    });
    const row = data.data;
    yield put(editRoleSuccess(row));
  } catch (e: any) {
    yield put(editRoleFailure(e.response));
  }
}
function* _deleteRoleRequest({ payload }) {
  try {
    const uri = ApiPaths.company.roles + '/' + payload.roleId
    const { data } = yield Api.delete(uri, {});
    const row = data.data;
    yield put(deleteRoleSuccess(row));
  } catch (e: any) {
    // const response = handleError(e.response);
    yield put(deleteRoleFailure(e.response));
  }
}

function* _getRolesRequest({ payload }) {
  try {
    const uri = ApiPaths.company.subsidiaryRoles;
    const { data } = yield Api.get(uri, {
      params: {
        user_count: payload.userCount
      }
    });
    const row = data.data;
    yield put(getRolesSuccess(row));
  } catch (e: any) {
    yield put(getRolesFailure(e.response));
  }
}

function* _getUserSubsidiaryRequest() {
  try {
    const uri = ApiPaths.company.userSubsidiaries;
    const { data } = yield Api.get(uri, {});
    yield put(getUserSubsidiarySuccess(data.data));

  } catch (e: any) {
    yield put(getUserSubsidiaryFailure());
  }
}

function* _getSubscriptionPlansRequest({ payload }) {
  try {
    let planType = payload.planType;
    const uri = ApiPaths.company.subscriptionPlans;
    const { data } = yield Api.get(uri, {});
    const row = data.data;
    yield put(getSubscriptionPlansSuccess(row));
  } catch (e: any) {
    yield put(getSubscriptionPlansFailure());
  }
}

function* _getSubscriptionPlanDetailsRequest({ payload }) {
  try {
    let planId = payload.planId;
    const uri = ApiPaths.company.subscriptionPlans + "/" + planId;
    const { data } = yield Api.get(uri, {});
    const row = data.data;

    yield put(getSubscriptionPlanDetailsSuccess(row));
  } catch (e: any) {
    yield put(getSubscriptionPlanDetailsFailure());
  }
}

function* _getFeaturesRequest({ payload }) {
  try {
    const uri = ApiPaths.company.features;
    const { data } = yield Api.get(uri, {});
    const row = data.data;
    let tempArray = row.features.map(feature => Object.assign(feature, { isChecked: false }))
    yield put(getFeaturesSuccess(tempArray));
  } catch (e: any) {
    yield put(getFeaturesFailure());
  }
}

function* _getPermissionsRequest({ payload }) {
  try {
    const uri = ApiPaths.company.getPermissions;
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getPermissionsSuccess(row));
  } catch (e: any) {
    yield put(getPermissionsFailure());
  }
}

function* _createSubscriptionRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId
    const uri = ApiPaths.user.subscriptionNew + "?subsidiary_id=" + subsidiaryId;
    const { data } = yield Api.post(uri, payload);
    const row = data.data;
    yield put(createSubscriptionErrorMessage(data.message))
    yield put(createSubscriptionSuccess(row));
  } catch (e: any) {
    yield put(createSubscriptionFailure(e.response));
  }
}

function* _getSubsidiarySubscriptionStatusRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.getSubscriptionStatus;
    const { data } = yield Api.get(uri);
    const row = data.data;
    yield put(getSubsidiarySubscriptionStatusSuccess(row));
  } catch (e: any) {
    yield put(getSubsidiarySubscriptionStatusFailure(e.response));
  }
}

function* _getMembersRequest({ payload }) {
  let subsidiaryId = payload.subsidiaryId;
  let params = {};
  if (payload?.filter && Object.keys(payload?.filter).length > 0) {
    params["filter"] = payload.filter;
  }
  if ("search" in payload) {
    params["search"] = payload.search;
  }
  try {
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.users;
    const { data } = yield Api.get(uri, { params });
    const row = data.data;
    yield put(getMembersSuccess(row));
  } catch (e: any) {
    yield put(getMembersFailure(e.response));
  }
}

function* _changeStatusSubsidiaryMemberRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.users + "/" + payload.userId + "?_method=patch";
    const { data } = yield Api.post(uri, { status: payload.status });
    yield put(changeStatusSubsidiaryMemberSuccess(data));
  } catch (e: any) {
    yield put(changeStatusSubsidiaryMemberFailure());
  }
}

function* _deleteSubsidiaryMemberRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.users + "/" + payload.userId;
    const { data } = yield Api.delete(uri);
    yield put(deleteSubsidiaryMemberSuccess(data));
  } catch (e: any) {
    yield put(deleteSubsidiaryMemberFailure(e.response));
  }
}

function* _editSubsidiaryMemberRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    if (payload.profileExists != null) {
      let uri = ApiPaths.company.contactFields + '/' + payload.contactId + "?&_method=patch";
      const { data } = yield Api.post(uri, {
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        phone: payload.phone,
        country_id: payload.country_id,
        rus_id: payload.rus_id
      });
      yield put(editSubsidiaryMemberSuccess(data));
    } else {
      let uri = ApiPaths.company.contactFields + "?subsidiary_id=" + subsidiaryId;
      let { data } = yield Api.post(uri, {
        role_id: payload.roleId,
        first_name: payload.first_name,
        last_name: payload.last_name,
        label: "professional_data",
        email: payload.email,
        phone: payload.phone,
        country_id: payload.country_id,
        rus_id: payload.rus_id
      });
      yield put(editSubsidiaryMemberSuccess(data));
    }
  } catch (e: any) {
    yield put(editSubsidiaryMemberFailure(e.response));
  }
}

function* _updateSubsidiaryMemberRolesRequest({ payload }) {
  let subsidiaryId = payload.subsidiaryId;
  let uri = ApiPaths.company.role + ApiPaths.company.users + "/" + payload.userId + "?_method=patch";
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.role + ApiPaths.company.users + "/" + payload.userId + "?_method=patch";
    const { data } = yield Api.post(uri, {
      department_id: payload.departmentId,
      role_id: payload.roleId,
    });
    const row = data.data;
    yield put(updateSubsidiaryMemberRolesSuccess());
  } catch (e: any) {
    yield put(updateSubsidiaryMemberRolesFailure(e.response));
  }
}

function* _getSuggestedMembersRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.suggestedMembers;
    const { data } = yield Api.get(uri, {
      params: {
        subsidiary_id: subsidiaryId,
        search: payload.search
      }
    });
    const row = data.data;
    yield put(getSuggestedMembersSuccess(row));
  } catch (e: any) {
    yield put(getSuggestedMembersFailure());
  }
}


function* _getDepartmentsRequest({ payload }) {
  try {
    const uri = ApiPaths.company.departments;
    let params = {};
    if ("search" in payload) {
      params["search"] = payload.search;
    }
    const { data } = yield Api.get(uri, { params });
    const row = data.data;
    yield put(getDepartmentsSuccess(row));
  } catch (e: any) {
    yield put(getDepartmentsFailure());
  }
}

function* _getSubsidiaryDepartmentsRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.departments;
    const { data } = yield Api.get(uri, {});
    const row = data.data;
    yield put(getSubsidiaryDepartmentsSuccess(row));
  } catch (e: any) {
    yield put(getSubsidiaryDepartmentsFailure(e.response));
  }
}

function* _addSubsidiaryDepartmentsRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.departments;
    const { data } = yield Api.post(uri, {
      department_id: payload.departmentId,
    });
    const row = data.data;
    yield put(addSubsidiaryDepartmentsSuccess());
    yield _getSubsidiaryDepartmentsRequest({ payload });
  } catch (e: any) {
    yield put(addSubsidiaryDepartmentsFailure(e.response));
  }
}

function* _deleteSubsidiaryDepartmentsRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.departments;
    const { data } = yield Api.delete(uri, {
      params: {
        department_id: payload.departmentId,
      }
    });
    const row = data.data;
    yield put(deleteSubsidiaryDepartmentsSuccess(row));
    yield _getSubsidiaryDepartmentsRequest({ payload });
  } catch (e: any) {
    yield put(deleteSubsidiaryDepartmentsFailure(e.response));
  }
}

function* _getDepartmentSchedulesRequest({ payload }) {
  try {
    const uri = ApiPaths.company.schedules;
    const { data } = yield Api.get(uri, {
      params: {
        department_subsidiary_id: payload.departmentSubsidiaryId,
        "filter[type]": payload.type
      }
    });
    const row = data.data;
    yield put(getDepartmentSchedulesSuccess(row));
  } catch (e: any) {
    yield put(getDepartmentSchedulesFailure(e.response));
  }
}

function* _addDepartmentSchedulesRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.schedules;
    const { data } = yield Api.post(uri, {
      department_subsidiary_id: payload.departmentSubsidiaryId,
      schedules: payload.schedules,
      type: payload.type
    });
    yield put(addDepartmentSchedulesSuccess());
  } catch (e: any) {
    yield put(addDepartmentSchedulesFailure(e.response));
  }
}

function* _updateSubsidiaryPrivacyRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + "?_method=patch"
    const { data } = yield Api.post(uri, payload);
    yield put(updateSubsidiaryPrivacySuccess());
  } catch (e: any) {
    yield put(updateSubsidiaryPrivacyFailure(e.response));
  }
}

function* _addSubsidiaryMembersRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    let uri = ApiPaths.company.subsidiary + "/" + subsidiaryId + ApiPaths.company.users
    const { data } = yield Api.post(uri, {
      department_id: payload.departmentId,
      role_id: payload.roleId,
      user_id: payload.userId
    });
    if (payload.email || payload.phone) {
      let id = data.data.members.id;
      uri = ApiPaths.company.contactFields;
      let result = yield Api.post(uri, {
        role_id: payload.roleId,
        label: "professional_data",
        email: payload.email,
        phone: payload.phone,
        country_id: payload.countryId,
        rus_id: id
      });
    }
    yield put(addSubsidiaryMembersSuccess(data));
  } catch (e: any) {
    yield put(addSubsidiaryMembersFailure(e.response));
  }
}

function* _getAllCardsRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    let uri = ApiPaths.company.paymentMethods + "/list"

    if (subsidiaryId) {
      uri = ApiPaths.company.paymentMethods + "/" + subsidiaryId + "/list"
    }
    const { data } = yield Api.get(uri, payload);
    const row = data.data;
    yield put(getAllCardsSuccess(row));
  } catch (e: any) {
    yield put(getAllCardsFailure(e.response));
  }
}

function* _addCardRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.paymentMethods + ApiPaths.company.create
    const { data } = yield Api.post(uri, payload);
    yield put(addCardSuccess(data.data));
  } catch (e: any) {
    yield put(addCardFailure(e.response));
  }
}

function* _deleteCardRequest({ payload }) {
  try {
    let paymentId = payload.paymentId;
    const uri = ApiPaths.company.paymentMethods + "/" + paymentId;
    const { data } = yield Api.delete(uri, payload);
    yield put(deleteCardSuccess(paymentId));
  } catch (e: any) {
    yield put(deleteCardFailure(e.response));
  }
}

function* _updateCardRequest({ payload }) {
  try {
    const uri = ApiPaths.company.paymentMethods + "/" + payload.id + "?_method=patch&subsidiary_id=" + payload.subsidiaryId;
    let payloadkeys = {
      subsidiary_id: payload.subsidiaryId,
      default_method: payload.default_methode,      
    }
    if(payload.exp_year){
      payloadkeys['exp_year']=payload.exp_year
    } 

    if(payload.exp_Month){
      payloadkeys['exp_month']=payload.exp_Month
    } 

    const { data } = yield Api.post(uri, payloadkeys);
    yield put(updateCardSuccess(payload));
  } catch (e: any) {
    yield put(updateCardFailure(e.response));
  }
}

function* _createStripeCustomerRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.stripeCustomers + "?subsidiary_id=" + subsidiaryId;
    const { data } = yield Api.post(uri, payload);
    yield _getSubsidiaryDetailsRequest({ subsidiaryId: subsidiaryId });
    yield put(createStripeCustomerSuccess());
  } catch (e: any) {
    yield put(createStripeCustomerFailure(e.response));
  }
}

function* _getCheckoutTotalRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.checkoutTotal + "?subsidiary_id=" + subsidiaryId;
    const { data } = yield Api.post(uri, payload);
    const row = data.data;
    yield put(getCheckoutTotalSuccess(row));
  } catch (e: any) {
    yield put(getCheckoutTotalFailure(e.response));
  }
}

function* _addAppointmentPeriodRequest({ payload }) {
  try {
    let departmentSubsidiaryId = payload.departmentSubsidiaryId;
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.departmentSubsidiaries + "/" + departmentSubsidiaryId + ApiPaths.company.availability;
    const { data } = yield Api.post(uri, {
      appointment_period_minute: payload.periodAppointment,
      number_appointment_per_period: payload.appointmentPerPeriod
    });
    yield put(addAppointmentPeriodSuccess());
  } catch (e: any) {
    yield put(addAppointmentPeriodFailure(e.response));
  }
}

function* _getDepartmentAvailabilityRequest({ payload }) {
  try {
    const uri = ApiPaths.company.schedules;
    const { data } = yield Api.get(uri, {
      params: {
        department_subsidiary_id: payload.departmentSubsidiaryId,
        "filter[type]": payload.type
      }
    });
    const row = data.data;
    yield put(getDepartmentAvailabilitySuccess(row));
  } catch (e: any) {
    yield put(getDepartmentAvailabilityFailure(e.response));
  }
}

function* _deleteSubsidiaryRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    const uri = ApiPaths.company.subsidiary + "/" + subsidiaryId;
    const { data } = yield Api.delete(uri);
    yield put(deleteSubsidiarySuccess());
    let activeSubsidiary = yield getActiveSubsidiaryStored();
    if (payload.subsidiaryId == activeSubsidiary) {
      yield removeActiveSubsidiaryFromLocal();
    }
  } catch (e: any) {
    yield put(deleteSubsidiaryFailure(e.response));
  }
}

function* getUserRequest() {
  try {
    const uri = ApiPaths.auth.getUser;
    const { data } = yield Api.get(uri);
    return data;
  } catch (e: any) {
  }
}

function* _getUserSubsidiariesRequest() {
  try {
    const uri = ApiPaths.auth.getUser;
    const { data } = yield Api.get(uri);
    yield put(getUserDataSuccess(data))
    yield put(getUserSubsidiariesSuccess(data.data));
    yield saveUserDataToLocal(data.data.user);
  } catch (e: any) {
    yield put(getUserSubsidiariesFailure(e.response));
  }
}

function* _updateVatRequest({ payload }) {
  try {
    let parentId = payload.parentId;
    const uri = ApiPaths.company.createParent + "/" + parentId + "?_method=patch";
    const { data } = yield Api.post(uri, {
      vat_number: payload.vatNumber
    });
    yield put(updateVatSuccess(data.data));
  } catch (e: any) {
    yield put(updateVatFailure(e.response));
  }
}

function* _editSubsidiaryRequest({ payload }) {
  try {
    let subsidiaryAddress;
    let subsidiaryId = payload.subsidiaryId;
    let uri = ApiPaths.company.subsidiary + '/' + subsidiaryId + "?_method=patch";
    let payload1 = {
      slug: payload.slug,
      name: payload.companyName,
      email_principal: payload.email,
      phone_principal: payload.phone
    }
    if (payload.year && payload.year !== null) {
      payload1['foundation_date'] = payload.year
    }
    let subsidiaryData = yield Api.post(uri, payload1);

    if (payload.addressId == "") {
      uri = ApiPaths.company.addSubsidiaryAddress + "?subsidiary_id=" + subsidiaryId;
      subsidiaryAddress = yield Api.post(uri, {
        label_address: payload.addressType,
        street_number: payload.street_number,
        route: payload.route,
        postal_code: payload.postal_code,
        locality: payload.locality,
        lat: payload.lat,
        long: payload.long,
        country_id: payload.countryId,
        addressId: payload.addressId,
        formatted_address: payload.formatted_address,
        google_place_id: payload.place_id,
        adm_area_l1_region: payload.administrative_area_level_1,
        adm_area_l2_province: payload.administrative_area_level_2
      })
    } else {
      uri = ApiPaths.user.saveAddress + "/" + payload.addressId + "?subsidiary_id=" + subsidiaryId;
      subsidiaryAddress = yield Api.post(uri, {
        street_number: payload.street_number,
        route: payload.route,
        postal_code: payload.postal_code,
        locality: payload.locality,
        lat: payload.lat,
        long: payload.long,
        addressId: payload.addressId,
        formatted_address: payload.formatted_address,
        google_place_id: payload.place_id,
        adm_area_l1_region: payload.administrative_area_level_1,
        adm_area_l2_province: payload.administrative_area_level_2,
        _method: "PATCH"
      })
    }
    const dataForm = subsidiaryData;
    dataForm.data.data.subsidiary = subsidiaryData.data.data.subsidiary;
    dataForm.data.data.subsidiary['address_principal'] = subsidiaryAddress.data.data.address
    yield put(getSubsidiaryDetailsSuccess(dataForm.data.data))
    yield put(editSubsidiarySuccess(subsidiaryData));
  } catch (e: any) {
    yield put(editSubsidiaryFailure(e.response));
  }
}

function* _getInvoiceRequest({ payload }) {
  try {
    let uri = ApiPaths.company.invoice;
    if (payload.type) {
      uri += `?type=${payload.type}`
    }
    const { data } = yield Api.get(uri, {});
    yield put(getInvoiceSuccess(data));
  }
  catch (e: any) {
    yield put(getInvoiceFailure(e.response));
  }
}
function* _addSubsidiaryLogoRequest({ payload }) {
  try {
    const formdata = new FormData();
    formdata.append('logo', payload.logo)
    const uri: any = ApiPaths.company.logoChange + "/" + payload.subsidiaryId + "/logo";
    const data = yield Api.post(uri, formdata)
    yield put(addSubsidiaryLogoSuccess(data));
  } catch (e: any) {
    yield put(addSubsidiaryLogoFailure(e.response))
  }
}

function* _handleInvitationUserRequest({ payload }) {
  try {
    let subsidiaryId = payload.subsidiaryId;
    let params = "email=" + payload.email + "&expires=" + payload.expires + "&status=" + payload.status + "&subsidiary_id=" + payload.subsidiaryId + "&signature=" + payload.signature + "&_method=PATCH";
    const uri = ApiPaths.company.subsidiary + ApiPaths.company.verifyInvitation + "/" + payload.role_user_sub + "/" + payload.hash + "?" + params;
    const { data } = yield Api.post(uri);
    yield put(handleInvitationUserSuccess(data));
  }
  catch (e: any) {
    yield put(handleInvitationUserFailure(e.response));
  }
}

function* _getInvoiceDetailRequest({ payload }) {
  try {
    const uri = ApiPaths.company.invoice + '/' + payload.id;
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(getInvoiceDetailSuccess(row));
  } catch (e: any) {
    yield put(getInvoiceDetailFailure(e.response));
  }
}

function* _downloadPdfRequest({ payload }) {
  try {
    const uri = ApiPaths.company.invoice + '/' + payload.id + "/download";
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(downloadPdfSuccess(row));
  } catch (e: any) {
    yield put(downloadPdfFailure(e.response));
  }
}

function* _reactivateSubscriptionRequest({ payload }) {
  try {
    let uri = ApiPaths.company.subscriptions + '/' + payload.subsidiary + ApiPaths.company.resume + "?_method=patch";
    const { data } = yield Api.post(uri, {});
    const row = data
    yield put(reactivateSubscriptionSuccess(row));
  } catch (e: any) {
    yield put(reactivateSubscriptionFailure(e.response));
  }
}

function* _cancelStripeScheduleRequest({ payload }) {
  try {
    let uri = ApiPaths.company.subscriptions + "/" + payload.id;
    const { data } = yield Api.delete(uri, {});
    yield put(cancelStripeScheduleSuccess(data));
  } catch (e: any) {
    yield put(cancelStripeScheduleFailure(e.response));
  }
}

function* _previewPriceRequest({ payload }) {
  try {
    // let subsidiaryId = subsidiaryInfo.id;
    let uri = ApiPaths.company.priceChange + '/' + payload.subsidairy;

    let requestPayload = "?price=" + payload.priceId;

    // coupon code
    if (payload.code) {

      requestPayload += "&code=" + payload.code;
    }
    uri += requestPayload;

    const { data } = yield Api.get(uri, {});
    const row = data.data.response
    yield put(previewPriceSuccess(row));
  }
  catch (e: any) {
    yield put(previewPriceFailure(e.response));
  }
}

function* _changePlanRequest({ payload }) {
  try {
    let uri;
    if (payload.code) {
      uri = ApiPaths.company.subscriptions + '/' + payload.id + "?code=" + payload.code + "&_method=PATCH";
    } else {
      uri = ApiPaths.company.subscriptions + '/' + payload.id + "?_method=PATCH";
    }

    const { data } = yield Api.post(uri, {
      price: payload.priceId
    });
    const row = data
    yield put(changePlanSuccess(row));
  }
  catch (e: any) {
    yield put(changePlanFailure(e.response));
  }
}
function* _subscriptionPlanDetailRequest({ payload }) {
  try {
    let uri = ApiPaths.company.subscriptions;
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(subscriptionPlanDetailSuccess(row));
  }
  catch (e: any) {
    yield put(subscriptionPlanDetailFailure(e.response));
  }
}

function* _cancelUpcomingSubscriptionRequest({ payload }) {
  try {
    // let subsidiaryId = subsidiaryInfo.id;
    let uri = ApiPaths.company.subscriptionSchedules
    const { data } = yield Api.delete(uri, {});
    const row = data
    yield put(cancelUpcomingSubscriptionSuccess(row));
  } catch (e: any) {
    yield put(cancelUpcomingSubscriptionFailure(e.response));
  }
}

function* _getNotificationsRequest({ payload }) {
  try {
    let uri = ApiPaths.company.notifications;

    if ('userId' in payload) {
      uri += '?user_id=' + payload.userId
    }
    if ('page' in payload) {
      uri += '&page=' + payload.page
    }
    if ('type' in payload) {
      uri += '&type=' + payload.type;
    }
    const { data } = yield Api.get(uri);
    const row = data.data;
    if (payload.type === "cartobike") {
      yield put(getNotificationsSuccessCartobike(row));
    } else if (payload.type === "invitation") {
      yield put(getNotificationsSuccessInvitation(row));
    } else {
      yield put(getNotificationsSuccess(row));
    }
  }
  catch (e: any) {
    yield put(getNotificationsFailure(e.response));
  }
}

function* _readNotificationRequest({ payload }) {
  try {
    const uri = ApiPaths.company.notifications + '/' + payload.notificationId + ApiPaths.company.readNotification + "?_method=patch";
    const { data } = yield Api.post(uri);
    const row = data.data;
    yield put(readNotificationSuccess(row));
  }
  catch (e: any) {
    yield put(readNotificationFailure(e.response));
  }
}

function* _readAllNotificationsRequest({ payload }) {
  try {
    const uri = ApiPaths.company.notifications + '/' + payload.notificationId + ApiPaths.company.readNotifications + "?_method=patch";
    const { data } = yield Api.post(uri);
    const row = data.data;
    yield put(readAllNotificationsSuccess(row));
  }
  catch (e: any) {
    yield put(readAllNotificationsFailure(e.response));
  }
}

function* _deleteNotificationRequest({ payload }) {
  try {
    const uri = ApiPaths.company.notifications + '/' + payload.notificationId;
    const { data } = yield Api.delete(uri);
    // const row = data.data;
    yield put(deleteNotificationSuccess(data));
  }
  catch (e: any) {
    yield put(deleteNotificationFailure(e.response));
  }
}

function* _respondNotificationRequest({ payload }) {

  try {
    const uri = ApiPaths.company.notifications + '/' + payload.notificationId + ApiPaths.company.respondNotifications + "?_method=patch";
    const { data } = yield Api.post(uri, {
      status: payload.status,
      type: payload.type
    });
    yield put(respondNotificationSuccess(data));
  }
  catch (e: any) {
    yield put(respondNotificationFailure(e.response));
  }
}

function* _openNotificationsRequest({ payload }) {
  try {
    const uri = ApiPaths.company.notifications + '/' + payload.userId + ApiPaths.company.lastOpened + "?_method=patch";
    const { data } = yield Api.post(uri);
    yield put(openNotificationsSuccess(data));
  }
  catch (e) {
    yield put(openNotificationsFailure(e.response));
  }
}

function* _getNotificationCountRequest() {
  try {
    const uri = ApiPaths.company.notifications + ApiPaths.company.count;
    const { data } = yield Api.get(uri);
    yield put(getNotificationCountSuccess(data));
  }
  catch (e) {
    yield put(getNotificationCountFailure(e.response));
  }
}
function* _createIntentRequest({ payload }) {
  try {
    const uri = ApiPaths.company.paymentMethods + ApiPaths.company.setupIntent;
    const { data } = yield Api.post(uri, {
      payment_method_id: payload.payment_method_id,
      subsidiary_id: payload.subsidiary_id,
      default_method: payload.default_method
    })

    yield put(createIntentSuccess(data));
  }
  catch (e: any) {
    yield put(createIntentFailure(e.response));
  }
}

function* _storeNewCardRequest({ payload }) {
  try {
    const uri = ApiPaths.company.paymentMethods + '/' + ApiPaths.company.storeNew;
    let requestInfo = {
      'subsidiary_id': payload.subsidiary_id,
      'default_method': payload.default_method,
      'payment_method_id': payload.payment_method_id
    }
    const { data } = yield Api.post(uri, requestInfo);
    yield put(storeNewCardSuccess(data));
  }
  catch (e) {

  }
}








export const companySagas = [
  takeEvery(checkVatRequest, _checkVatRequest),
  takeEvery(checkSlugRequest, _checkSlugRequest),
  takeEvery(createParentRequest, _createParentRequest),
  takeEvery(createCompanyRequest, _createCompanyRequest),
  takeEvery(getSubsidiaryDetailsRequest, _getSubsidiaryDetailsRequest),
  takeEvery(createRoleRequest, _createRoleRequest),
  takeEvery(getRoleDetailsRequest, _getRoleDetailsRequest),
  takeEvery(editRoleRequest, _editRoleRequest),
  takeEvery(deleteRoleRequest, _deleteRoleRequest),
  takeEvery(getRolesRequest, _getRolesRequest),
  takeEvery(getUserSubsidiaryRequest, _getUserSubsidiaryRequest),
  takeEvery(getSubscriptionPlansRequest, _getSubscriptionPlansRequest),
  takeEvery(getSubscriptionPlanDetailsRequest, _getSubscriptionPlanDetailsRequest),
  takeEvery(getFeaturesRequest, _getFeaturesRequest),
  takeEvery(getPermissionsRequest, _getPermissionsRequest),
  takeEvery(createSubscriptionRequest, _createSubscriptionRequest),
  takeEvery(getSubsidiarySubscriptionStatusRequest, _getSubsidiarySubscriptionStatusRequest),
  takeEvery(getMembersRequest, _getMembersRequest),
  takeEvery(changeStatusSubsidiaryMemberRequest, _changeStatusSubsidiaryMemberRequest),
  takeEvery(deleteSubsidiaryMemberRequest, _deleteSubsidiaryMemberRequest),
  takeEvery(editSubsidiaryMemberRequest, _editSubsidiaryMemberRequest),
  takeEvery(updateSubsidiaryMemberRolesRequest, _updateSubsidiaryMemberRolesRequest),
  takeEvery(getSuggestedMembersRequest, _getSuggestedMembersRequest),
  takeEvery(getDepartmentsRequest, _getDepartmentsRequest),
  takeEvery(getSubsidiaryDepartmentsRequest, _getSubsidiaryDepartmentsRequest),
  takeEvery(addSubsidiaryDepartmentsRequest, _addSubsidiaryDepartmentsRequest),
  takeEvery(deleteSubsidiaryDepartmentsRequest, _deleteSubsidiaryDepartmentsRequest),
  takeEvery(getDepartmentSchedulesRequest, _getDepartmentSchedulesRequest),
  takeEvery(addDepartmentSchedulesRequest, _addDepartmentSchedulesRequest),
  takeEvery(updateSubsidiaryPrivacyRequest, _updateSubsidiaryPrivacyRequest),
  takeEvery(addSubsidiaryMembersRequest, _addSubsidiaryMembersRequest),
  takeEvery(getAllCardsRequest, _getAllCardsRequest),
  takeEvery(addCardRequest, _addCardRequest),
  takeEvery(deleteCardRequest, _deleteCardRequest),
  takeEvery(updateCardRequest, _updateCardRequest),
  takeEvery(createStripeCustomerRequest, _createStripeCustomerRequest),
  takeEvery(getCheckoutTotalRequest, _getCheckoutTotalRequest),
  takeEvery(addAppointmentPeriodRequest, _addAppointmentPeriodRequest),
  takeEvery(getDepartmentAvailabilityRequest, _getDepartmentAvailabilityRequest),
  takeEvery(deleteSubsidiaryRequest, _deleteSubsidiaryRequest),
  takeEvery(getUserSubsidiariesRequest, _getUserSubsidiariesRequest),
  takeEvery(updateVatRequest, _updateVatRequest),
  takeEvery(editSubsidiaryRequest, _editSubsidiaryRequest),
  takeEvery(getInvoiceRequest, _getInvoiceRequest),
  takeEvery(addSubsidiaryLogoRequest, _addSubsidiaryLogoRequest),
  takeEvery(handleInvitationUserRequest, _handleInvitationUserRequest),
  takeEvery(getInvoiceDetailRequest, _getInvoiceDetailRequest),
  takeEvery(downloadPdfRequest, _downloadPdfRequest),
  takeEvery(reactivateSubscriptionRequest, _reactivateSubscriptionRequest),
  takeEvery(cancelStripeScheduleRequest, _cancelStripeScheduleRequest),
  takeEvery(previewPriceRequest, _previewPriceRequest),
  takeEvery(changePlanRequest, _changePlanRequest),
  takeEvery(subscriptionPlanDetailRequest, _subscriptionPlanDetailRequest),
  takeEvery(cancelUpcomingSubscriptionRequest, _cancelUpcomingSubscriptionRequest),
  takeEvery(getNotificationsRequest, _getNotificationsRequest),
  takeEvery(readNotificationRequest, _readNotificationRequest),
  takeEvery(readAllNotificationsRequest, _readAllNotificationsRequest),
  takeEvery(deleteNotificationRequest, _deleteNotificationRequest),
  takeEvery(respondNotificationRequest, _respondNotificationRequest),
  takeEvery(openNotificationsRequest, _openNotificationsRequest),
  takeEvery(getNotificationCountRequest, _getNotificationCountRequest),
  takeEvery(createIntentRequest, _createIntentRequest),
  takeEvery(storeNewCardRequest, _storeNewCardRequest),
  takeEvery(privateSwitchRequest, _privateSwitchRequest)
];