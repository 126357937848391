import React from 'react'
import { Box, Modal } from '@mui/material'
import {SvgClose } from 'app/components/svgicons/svg';
import { SwitchButton } from 'app/components/stylesComponents/SwitchButton';
import { colorNew } from 'styles/StyleConstants';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

function AutoBidModal(props:any) {
   const {openModal,handleClose} = props;
   const {t} = useTranslation();
  return (
    <>
        <Modal
             open={openModal}
             onClose={handleClose}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
             >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 400,
                width:'calc(100% - 20px)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 5,
                overflow:'hidden',
                '.Header-box':{
                    borderBottom: `1px solid ${colorNew.colorGrayDark}`,
                    padding: '13px',
                    display:'flex',
                    justifyContent:'space-between',
                    'span':{
                        fontWeight:600
                    }
                },
                '.body-box':{
                    p:2,
                    display:'flex',
                    fontSize:14,
                    '.toggler':{
                        minWidth:80,
                        textAlign:'center',
                        alignSelf:'center',
                        'label':{

                        }
                    },
                    '.text-box':{
                        
                    }
        
                }
            }}>
                <div className='Header-box'>
                    <span className='fs-4 fw-bold'>{t(translations.ads.AUTO_BID)}</span>
                    <span onClick={handleClose}> <SvgClose /></span>
                </div>
                <div className='body-box'>
                    <span
                        className="toggler"
                    >
                     <SwitchButton className="sm me-2">
                        <input type="checkbox" defaultChecked={true} checked={true} value={"1"}/>
                        <span className="slider round"></span>
                     </SwitchButton>  
                    </span>        
                    <span className='text-box'>{t(translations.webTexts.AUTO_BID_TEXT)}</span>
                </div>  
                <div className='body-box'>
                    <span
                        className="toggler"
                    >
                     <SwitchButton className="sm me-2">
                                    <input type="checkbox" defaultChecked={false} checked={false} value={"1"}/>
                                    <span className="slider round"></span>
                     </SwitchButton>         
                    </span>        
                <span className='text-box'>{t(translations.webTexts.AUTO_BID_TEXT2)}</span>
                </div>         
                <div className='body-box'>
               <p className='text-gray-dark fw-bold'>
               {t(translations.webTexts.AUTO_BID_TEXT3)}
               </p>
                </div> 
            </Box>
          </Modal>
    </>
  )
}

export default AutoBidModal;