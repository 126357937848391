import styled from "styled-components";
import { color, colorNew } from "styles/StyleConstants";
export const MobileHeaderWrapper = styled.div`
  .text-notification{
    text-align:center;
    font-weight:600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color:${colorNew.colorGrayOnButton};
  }
    li{
    list-style: none;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color:${colorNew.colorGrayOnButton};
    margin:10px 0px;
  }
  li span{
    padding-left: 8px;
  }
  .manage_wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    width: 100%;
    max-width: 750px;
    margin: 20px auto 0;
}
.upper-Box{
    width: 50%;
}
  .yearly_Plan_details {
    /* box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.04); */
    border-radius: 6px;
    padding: 16px 0;
    margin-top: 20px;
    column-gap:16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.change_plan{
    width: 60%;
    padding: 20px 10px;
    border-radius: 6px;
    background: #617af763;
    position: relative;
}
.change_plan button.popularPlan {
    background: #fff;
    border: solid 1px #f5f5f5;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 50%;
    font-size: 18px;
    
}
.plan-yearly  span.select-plan button {
    border: 1px solid #607AFC; 
    padding: 10px 15px 10px;
    border-radius: 6px;
    display: inline-block;
    min-width: 90px;
    font-size: 18px;
    color: #617AF7;
    margin-top: 20px;
    background: transparent;
    cursor: pointer;
    width: 47%;
    margin-right: 10px;
}
.yearly_Plan_details h5.cardType {
    margin: 0;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;

}
.switchedPlan h5.cardType {
    margin-top: 15px !important;
}
.yearly_Plan_details h5.cardType span.card_Pri{
  display: block;
  text-align: center;
}
.yearly_Plan_details h5.cardType span.card_btn{
  cursor: pointer;
  margin-top: 20px;
  display:block;
}
p.offer {
    margin: 0;
    text-align: center;
}
span.card_btn p{ 
  display: block; 
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
} 
.card_details tr th {
    width:11%;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 0 0px 10px 0;
    font-weight: 400;
    color: ${colorNew.colorGrayDark4};
}
.card_details tr td{
width:11%;
}
.lowerBox {
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 20px;
    width: 340px;
    background: #FBFBFF;
}
.lowerBox h5 {
    margin: 0;
    color: ${colorNew.colorGrayOnButton};
    font-size: 21px;
    font-weight: 500;
    line-height: 25px;
}
.card_details {
    margin-top: 20px;
    text-align: left;
}
@media (max-width: 767px){
    .manage_wrapper {
      display: block;
  } 
.lowerBox {
    width: 100%;
    margin-top: 30px;
}
.profile_wrapper h2 {
    display: flex;
    align-items: center;
    column-gap: 70px;
    font-size: 21px;
    font-weight:500;
}
.profile_wrapper span.m_left_arrow {
    height: 40px;
    width: 40px;
    border: solid 1px ${colorNew.colorGrayOnButton};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor:pointer;
}
.yearly_Plan_details h5.cardType {
    column-gap: 20px;
}
.upper-Box{
  width: 100%;
}
.yearly_Plan_details h5.cardType span.card_Pri{
  max-width: 50%;
}
.yearly_Plan_details h5.cardType span.card_btn{
  width:50%;
}
}
@media (max-width: 375px){
.card_details tr th {
    font-size: 13px;
    padding: 0 0px 10px 0;
  }
  .profile_wrapper h2 {
    column-gap: 40px;
}
.plan-yearly  span.select-plan button {
    width: 46%;
}
}
@media (min-width:768px) and (max-width:1024px){
  .upper-Box{
  width: 60%;
}
.manage_wrapper {
    column-gap: 10px;
}
.card_details tr th {
    font-size: 15px;
    padding: 0 0px 10px 0;
  }
}
`;