import { Box, Skeleton } from '@mui/material'
import React from 'react'
import { colorNew } from 'styles/StyleConstants';
import UserDetails from './UserDetails';
import { SvgmemSearch } from 'app/components/svgicons/svgNew';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import { FollowersList } from 'app/pages/PublicProfile/interface/interface';
import * as publicProfileConstants from "config/publicProfileConfig"


const UserList = ({list, keyword ,setKeyword ,setPage , scrollFunc , refFunc , listType ,setOpen , updateUserList} : FollowersList) => {

    const { t } = useTranslation();
    const { loadingFollowers } = useSelector((state:RootStateOrAny) => state.publicProfile)

    //Search users from api
    const handleSearchUser = (event: any) => {
        setKeyword(event.target.value)
        setPage(1)
    };

    return (
        <>
            <div className="input-group">
                <span>
                    <SvgmemSearch />
                </span>
                <input
                    type="text"
                    className="form-control"
                    placeholder={t(translations.webTexts.SEARCH_MEMBER)}
                    aria-label="search"
                    aria-describedby="search"
                    value={keyword}
                    onChange={(e) => handleSearchUser(e)}
                />
            </div>
            <Box
                className='userDataList scrollBar' onScroll={(e) => scrollFunc(e)} ref={refFunc}
                sx={{
                    background: colorNew.colorWhite,
                    width: '100%'
                }}
            >
                {/* Renders when data has been loaded and has data */}
                {list && list.length > 0 && list.map((user) => {
                    return <UserDetails name={user.name} profilePic={user.profile_picture_url} flag={user.flag} verified={user.profile_is_verified} followers={user.followers_count} source={user.source} slug={user.user_name} followed={user.is_follow} recID={user.id} setOpen={setOpen} list={list} updateUserList={updateUserList}/>
                })}

                {/* Renders when data has been loaded and has no data */}
                {(list && list.length == 0 && !loadingFollowers) &&
                <Box className="UserDetailsContainer flexColumn width-100">
                    <Box className='noDataFound'>
                         { listType == publicProfileConstants.FollowerTabs.Followers ?
                        t(translations.PUBLICPROFILE.NO_FOLLOWERS) :
                        t(translations.PUBLICPROFILE.NO_FOLLOWING)}
                    </Box>
                </Box>
                }

                {/* Renders when data is being loaded */}
                {(list && list.length == 0 && loadingFollowers) &&
                [1,2,3].map((item)=>{
                    return <Box className="flexRow width-100" sx={{marginBottom:1, p:1}}>
                    <Skeleton variant="rectangular" width='100%' height='80px' />
                </Box>
                })}
            </Box>
        </>
    )
}

export default UserList