import { theme } from 'config';
import { breakpoints } from 'styles/media';
import { Box, TextField } from "@mui/material"
import styled from "styled-components"
import { colorNew } from "styles/StyleConstants"
import Typography from "@mui/material"

export const UserHeaderStyle = styled(Box)`
  color:${colorNew.primaryColor};
  p{
    color:${colorNew.colorGray2};
  }
  .cartobike_wrapper {
      background: #fff;
      border: 1px solid #EFEFEF;
      padding: 24px 15px;
  .cartobike_header {
        display: flex;
        justify-content: space-between;
        column-gap: 30px;
    .right_icon{
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li{
              display: inline-block;
              padding-left: 24px;
              vertical-align: middle;
              button{
                height: 48px;
                width: 48px;
                border: 1px solid #DEDEDE;
                background: #FBFBFF;
                border-radius: 6px;
                padding: 13px;
                min-width: auto !important;
                span.notit_dot {
                    display: inline-block;
                    background: #FF001F;
                    height: 8px;
                    width: 8px;
                    border-radius: 100%;
                    position: absolute;
                    top: 12px;
                    right: 12px;
                }
              }
              .user_wrapper {
                display: flex;
                align-items: center;
                column-gap: 10px;
                  .user_details {
                    h5 {
                    margin: 0;
                    font-weight: 400;
                    font-size: 21px;
                    line-height: 25px;
                    letter-spacing: 0.03em;
                    color: #000;
                    }
                    p{
                      color: #384386;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 19px;
                      letter-spacing: 0.03em;
                      margin: 0;
                    }
                    .country_bg {
                        background: #F2F2F2;
                        display: flex;
                        padding: 8px;
                        border-radius: 4px;
                    }
                  }
              }
            }
        }
      }
    }
  }
`
export const UserSidebarStyle = styled(Box)`
.cartobike_siderbar {
	  background: #fff;
	  /* border-right: 1px solid ${colorNew.colorGrayButton}; */
	  /* width: 234px;    */
	  height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
    overflow:auto;
	  padding: 20px 15px;
  .home_profile{
      ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding: 0px 0 24px;
        &.profile_des.active {
          border-bottom: 1px solid ${colorNew.colorGrayButton};
          margin-bottom: 24px;
          position: relative;
        }
        &.profile_des{
          .mobile_user{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit:cover;
          }
        }
        ::before{
            content: "";
            background: #617AF7;
            position: absolute;
            left: -15px;
            top: 0;
            height: 28px;
            border-radius: 6px;
            width: 6px;
          }
        a {
          font-weight: 400;
          font-size: 18px;
          color: #000 !important;
          line-height: 22px;
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
        svg{
          width:24px;
        }
      }
   }
}
.sidebar_wrapper {
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			padding: 0px 0 24px;
			a {
				font-weight: 400;
				font-size: 18px;
				color: ${colorNew.colorGrayOnButton};
				line-height: 22px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        &.active {
            color: #6579F4 !important;
            svg{
              stroke: #6579F4 !important;
            }
          }
         
			}
      svg{
        width:24px;
      }
		}
	}
}
}
@media (max-width :598px){
  .cartobike_siderbar {
	  height: auto;
	}
}
`
export const BodyTitle = styled.h2`
 .profile_wrapper {
    padding: 20px;
}
`
export const TextFieldCustom = styled(TextField)`
  .MuiInputLabel-root{
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color:${colorNew.colorGrayOnButton};
  }
  .MuiOutlinedInput-root{
    /* background: #FBFBFF; */
    /* border: 1px solid ${colorNew.colorGrayButton}; */
    border-radius: 4px;
  }

  fieldset{
    border: 1px solid #E5E5E5 !important;
  }
  
  #country_pointer{
    cursor: pointer;
  }
  .disable{
    cursor: not-allowed;
    background: #f2f2f2 ;
  }
  
* {
    font-family: 'Roboto',sans-serif;
}
`
export const UserStatusBar = styled(Box)`
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              justify-content: space-between;
              h2 {
                padding-bottom: 0 !important;
              }

                span.filter {
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 22px;
                  letter-spacing: 0.02em;
                  color: ${colorNew.colorGrayDark4};
                }
                span.count-user {
                  color: #000000 !important;
                  padding-left: 10px;
                }


                span.filter {
                  a {
                    color: #6579F4 !important;
                    background: none;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing:0.02em;
                    padding: 0;
                    &:hover {
                      border: none !important;
                    }
                    span {
                      padding-right: 10px;
                    }
                  }
                }

              .memb_search {
                position: relative;
                button {
                  position: absolute;
                  right: -16px;
                  color: ${colorNew.colorGrayDark4};
                }
              }
             @media (max-width :598px){
                .memb_head{
                  flex-wrap: wrap;
                  row-gap: 25px;
                  margin-top: 40px;
                  margin-bottom: 20px;
                    .add_emp {
                    order: 4;
                    width: 100%;
                    border-top: 1px solid ${colorNew.colorGrayButton};
                    padding-top: 16px;
                    text-align: right;  
                  }
                  .memb_search {
                    order: 2;
                    width: 50%;
                  }
                  .mb_filte_icon {
                      order: 3;
                      width: 100%;
                      text-align: right;
                    }
                                  .total_member {
                  order: 1;
                }
                }
              }
`
export const UserAddMember = styled(Box)`
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    .required-self{
          color: red;
        } 
    .suggestList {    
        .userPrfiledp {
          position: relative;
          height: 90px;
          width: 90px;
          margin: 0 auto;
          img{
            height: 90px;
            width: 90px;
            object-fit: cover;
            border-radius: 50%;
            &.add_mem_flag{
              height: 30px;
              width: 30px;
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }  
               
        .namePartners{
            h6{
              margin: 0;
              font-size: 18px;
              line-height: 21px;
              font-weight: 400;
              text-align: center;
              padding: 20px 0 10px 0;
            }
            p{
              color: ${colorNew.colorGrayOnButton};
              font-size: 15px;
              font-weight: 400;
              text-align: center;
              margin: 0;
            }
          }
    }
    .member_field {
              margin-top: 20px;
              .contactNumber{
                display: flex;
                column-gap: 13px;
              }
          }
@media (max-width :598px){
  .suggestList {
      display: flex;
      column-gap: 20px;
      background: #fff;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      padding: 16px;
      .userPrfiledp {
          margin: 0;
      }
    }
}
`
export const UserDutyTime = styled(Box)`
.duty_time_wrapper{
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 13px 16px;
    display: flex;
    font-size: 18px;
    color: #808080;
    justify-content: space-between;
    .duty_date{
      span {
          padding: 3px;
          display: inline-block;
          vertical-align: middle;
      }
    }
    .duty_day {
        width:
         50%;
      }
      .duty_add {
          display: flex;
          align-items: end;
          a{
            color: #6579F4;
            font-size: 18px;
            font-weight: 400;
          }
      }
    }
    @media (max-width :598px){
      .duty_time_wrapper{
        font-size: 16px;
        .duty_day {
            width:
            auto !important;
          }
    }
    }
`
export const UserCardWrapper = styled(Box)`
    h2{
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    .card_itme {
        .card_popup{
          .MuiFormControl-root {
              width: 100%;
              margin-top: 24px;
          }
        } 
      }
      .close_toggle {
        display: flex;
        align-items: center;
        column-gap: 30px;
        margin-top: 20px;
        span {
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
        }
      }
    }
`