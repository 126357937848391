import styled from 'styled-components';
import { colorNew } from 'styles/StyleConstants';

export const PublicProfileWrapper = styled.div`

//  ------------------------------------------------- Common Classes ---------------------------------------------------
span, p {
  font-style: normal;
  font-weight: 400;
  font-family: Inter !important;
} 
.flexRow{
  display:flex;
  flex-direction:row
}
.flexColumn{
  display:flex;
  flex-direction:column
}
.flexWrap{
  flex-wrap:wrap;
}
.alignItemsCenter{
  align-items : center
}
.justifyContentCenter{
  justify-content : center
}
.justifyContentBetween{
  justify-content : space-between
}
.flexWrap{
  flex-wrap:wrap
}
.no-margin{
  margin : 0px !important;
}
.no-padding{
  padding : 0px !important; 
}
.no-border{
  border : none !important; 
}
.no-border::before{
  border : none !important; 
}
.no-border::after{
  border : none !important; 
}
.width-100{
  width:100%
}
.width-auto{
  width:auto;
}
.width-75{
  width:75%;
}
.width-25{
  width:25%;
}
.mb-10{
  margin-bottom:10px
}
.mb-15{
  margin-bottom:15px
}
.mb-20{
  margin-bottom:20px
}
.mt-10{
  margin-top:10px
}
.mt-20{
  margin-top:20px
}
.relative{
  position:relative;
}
.absolute{
  position:absolute;
}
.noDataFound{
  padding:10px;
  color:#979797;
}
.userName{
  font-size:22px;
  font-weight:600; 
}

//  -------------------------------------------------Page Division ---------------------------------------------------

.userDetailsContainer{
  width: 30vw
}
.historyAndReviewConatiner{
  width: 40vw
}

//  ------------------------------------------------- User data Section ---------------------------------------------------
 
.userDetailsSubContainers{
  padding : 8px 0;
}
.userImageBox{
  position : relative;
}
.userImage{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.reviewMainContainer{
  max-height: calc( 100vh - 230px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  // overflow: hidden;
  // overflow-y: scroll;
}
.userFlag{
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  bottom: 5px;
  right: 10px;
}
.reviewerImage{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.reviewerFlag{
  width:15px;
  height: 15px;
  position:absolute;
  bottom: 8px;
  right: 2px;
  border-radius:50px;
}
.link{
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #384386 !important;
}
.folowers{
  font-size:15px;
  color: #979797;
}
.greyDot{
  width:10px;
  height:10px;
  background:#979797;
  border-radius:50%;
  margin : 0 10px;
}
.componentHeader{
  font-weight: 500;
  color: ${colorNew.colorGrayOnButton};
  font-size: 21px;
  padding: 15px;
  display:block;
  width:100%
}
.reviewsContainer{
  border: 1px solid rgb(239 239 239);
  border-radius: 4px;
  padding: 15px 0;
}
.reviewContentBox{
  padding:10px;
  margin-top:7px;
}
.reviewerName{
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-left: 7px;
}
.reviewerName p{
  font-size:17px;
  margin : 0px 0px -5px 0px;
}
.reviewerName span{
  margin : 0px;
  color: #979797;
  font-size: 13px;
  text-wrap:nowrap;
}
.reviewerName p span{
  margin : 0px
  font-weight: 200;
  color: #979797;
  font-size: 13px;
}
.reviewerRating{
}
.reviewerRatingCount{
  margin-left: 5px;
  margin-bottom: -5px;
  color: rgb(255, 188, 11)
}
.giveRatingBox{
  padding:5px;
  text-align:center;
}
.giveRatingBox span{
  font-weight : 600
}
.giveRatingIcon{
  padding: 0px 5px 5px 5px;
  border: 1px solid rgb(239 239 239);
  margin-top: 10px;
}
.reviewField{
  margin-top:30px;
  width: 100%;
  border: 1px solid rgb(239 239 239);
}
.borderBottom{
  border-bottom: 1px solid rgb(239 239 239);
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.reviewOptions{
  padding:5px 10px;
}
.reviewOptions:hover{
  background: #979797;
  color: white;
}
.phoneIcon{
  height: 42px;
  background: #28a745;
  display: flex;
  align-items: center;
  width: 64px;
  justify-content: center;
  border-radius: 5px;
}
.phoneIcon svg{
  width: 24px;
  height: 24px;
}

.navFixed{
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
  background: white; 
  transition: top 0.4s ease-in-out;
}

// -------------------------------------Share Profile Component---------------------------
.shareBtn{
  border: 1px solid rgb(197, 197, 197);
  width :64px;
  height :42px;
}
.shareContainer{
  display: flex;
  flex-direction: column;
  min-width: 250px;
  text-align: left;
  align-items: flex-start;
  padding: 20px;
  gap: 6px;
  position: relative;
}


// ------------------------------------------------------Followers------------------------------------------
.input-group {
  position: relative;
  margin: 20px 0px 26px;
}
.input-group span {
  position: absolute;
  left: 15px;
  top: 12px;
  border-right: 1px solid rgb(102, 102, 102);
  padding-right: 10px;
}
.input-group input {
  width: 100%;
  height: 52px;
  border: none;
  background: rgb(248, 248, 248);
  padding-left: 55px;
  font-size: 18px;
}
.userDataList{
  max-height: 38vh;
  overflow-y: scroll;
}
.UserDetailsBox{
  width:68%
}
.userProfileView {
  height: 40px;
  width: 40px;
  position : relative;
}
.userProfileView img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  bottom: -5px;
  right: 0px;
}
.userProfileView .flag {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  object-fit: cover;
}

.UserDetailsContainer{
  border-bottom : 1px solid ${colorNew.colorGrayButton};
  background: rgb(255, 255, 255);
  padding: 10px;
  margin-bottom: 16px;
  display: flex;
  column-gap: 15px;
  -webkit-box-align: center;
  align-items: center;
}
.userDetails{
  margin-left:10px;
  width:78%;
}
.userDetails p{
  font-size: 18px;
  width: fit-content;
  font-weight: 400;
  line-height: 148.02%;
  letter-spacing: 0.02em;
  overflow:hidden;
  text-overflow:ellipsis;
  max-width:80%;
  white-space:nowrap;
}
.userDetails span ,followBtn ,.unfollowBtn{
  font-size: 14px;
  color: #979797;
}
.followBtn{
  color: rgb(97, 122, 247);
}
.readMore{
  padding: 0 5px;
  color: rgb(97, 122, 247);
}



// -----------------------------------------------------History ------------------------------------------------------

.toggle{
  transition: max-height .5s, opacity .75s ease-out; 
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
}


.show{
  opacity: 1;
  overflow: hidden;
  max-height: 1200px;
  height: auto;
  transition: max-height .75s, opacity .5s ease-in;
}

.open{
  transform: rotate(-180deg);
  transition: all 0.35s ease-in-out 0s;
}
.close{
  transform: rotate(0deg);
  transition: all 0.35s ease-in-out 0s;
}

.vehicleImage{
  display:flex;
  margin-top:10px;
  // align-items: center;
  // justify-content: center;
  min-height :90px;
  margin-right: 10px;
}
.vehicleImage img{
  max-width :120px;
  width :100%;
  height :90px;
  object-fit:cover;
  border-radius:5px;
}
.vehicleName{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 100px);
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
  
}
.inSale{
  text-align:center;
  width:auto;
  min-width:78px;
  background: #28A745;
  padding: 0 10px;
  color: white;
  border-radius: 2px;
  font-weight:450px;
  font-size:13px;
}
.sold{
  text-align:center;
  width:auto;
  min-width:78px;
  background:${colorNew.colorGrayButton};
  padding: 0 10px;
  color: ${colorNew.colorGrayOnButton};
  border-radius: 2px;
  font-weight:450px;
  font-size:13px;
}
.parked{
  text-align:center;
  width:auto;
  min-width:78px;
  background: #FBC623;
  padding: 0 10px;
  border-radius: 2px;
  font-weight:450px;
  font-size:13px;
}
.reserved{
  text-align:center;
  width:auto;
  min-width:78px;
  color:white;
  background: #617af7;
  padding: 0 10px;
  border-radius: 2px;
  font-weight:450px;
  font-size:13px;
}
.dropdownIcon{
  background:${colorNew.colorGrayButton};  
  stroke:black;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  right: 30px;
  bottom: 25px;
  cursor:pointer;
}
.dropdownIcon svg{
  width:13px;
}
.dropdownIcon svg path{
  stroke:black;
}


// ---------------ProgressBar------------------

.stepper{
  margin-top:10px;
}
.stepper svg{
  width:8px;
}
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 9px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 9px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.active .step-counter{
  outline: 3px solid #4bb543;
  outline-offset:2px;
  width: 12px;
  height: 12px;
  background:#4bb543;
  margin-top:3px;
}

.active .step-counter svg{
  opacity : 0%;
}

.stepper-item.completed .step-counter {
  background-color: #4bb543;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  width: 100%;
  top: 9px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

// =-----------------------------Progress bar with status

.events li { 
  display: flex; 
  color: ${colorNew.colorGrayDark4};
}

.events p { 
  font-size:12px;
  position: relative;
  padding: 0 1.5em;
  text-align:right;
  min-width: 40%;
  color: #666666
}
.events p strong{  
  line-height: 8px;
}
.removeAfterBeforeElems::before {
  content: none !important;
}

.events p::after { 
  content: "";
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  border-radius: 50%;
  background: #fff;
  width: 11px;
  height: 11px;
  background: rgb(181 192 249);;
  outline: 3px solid #e1e4eb;
  outline-offset: 2px;
}


.events span {
  padding: 0 1.5em 1.5em 1.5em;
  position: relative;
  color: ${colorNew.colorGrayOnButton};
  font-size:13px;
}

.events span::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: -1px;
  height: 100%;
  border-left: 2px #e1e4eb solid;
}
.events strong{
  display: block;
  font-weight: 400;
}

.events .currentStatus {
   display: block;
   font-weight: bolder;
   line-height: 8px;
   color:black;
  font-size:15px;
}

.events { margin: 1em; }
.events, 
.events *::before, 
.events *::after { box-sizing: border-box; font-family: arial; }



// ----------------------------------------------------- MOdal --------------------------------------------------------
.openModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 35%;
  border-radius: 8px;
}
.openMobileModal{
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: white; 
  height: 100vh;
  padding: 20px;
} 
.closeModal path{
  fill :#6F6F70;
}


// ----------------------------------------------------- Media Queries --------------------------------------------------------


@media (max-width: 991px) {
  
  .userDetailsContainer{
    width: 100vw
  }
  .historyAndReviewConatiner{
    width: 100vw
  }
  .userDataList{
    max-height: ${navigator.platform === 'iPhone' && navigator.userAgent.includes('Mozilla') ? '60vh' : '68vh'};
  }
  .reviewMainContainer{
    padding:5px;
    max-height: unset !important;
  }
  .vehicleImage img{ 
    border-radius:0;
  }
}
@media (max-width: 600px) {
  .events{
    padding: 0px;
  }
}
@media (max-width: 500px) {
  .vehicleImage{
    min-width :100px;
    min-height :62px;
  }
  .vehicleImage img{
    width :100px;
    height :62px;
    object-fit:cover;
    border-radius:0;
  }
}
@media (max-width:370px) {
  .vehicleName{
    width:100px;
  }
}
@media (min-width: 992px) {
  .userDetailsContainer {
    position: fixed
  }
}
`;
