import { Box } from '@mui/material'
import { DetailListing, DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice'
import { ElectricDetailsI } from 'app/pages/AdsAuctionDetailsPage/AuctionDetailInterface/AuctionInterface'
import { lan } from 'locales/multiPageTranslation/adsLan'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DetailsWrapper } from '../Details'
import { useDeviceType } from 'app/Hooks/useMediaQuery'

const EnvironmentDetail = ({ item }: ElectricDetailsI) => {
    const { t } = useTranslation();
    const [isSmallDevice] = useDeviceType();

    return (
        <DetailsWrapper> <Box
            className={`${!isSmallDevice && 'grid_color_odd'}`}
            sx={{
                'span': {
                    fontWeight: 500,
                    display: "inline-block",
                    whiteSpace: "nowrap"
                },
            }}
        >
            <DetailsTitle sx={{ ml: '12px' }}>{t(...lan.environment())}</DetailsTitle>
            <Box>
                <DetailListing
                    className='vehicle-detail-label'
                >
                    <label>{t(...lan.co2EmissionsGKm())}</label>
                    <span>{item?.owner_vehicle?.co2_emission ? item?.owner_vehicle?.co2_emission + " g/km" : "-"}</span>
                </DetailListing>
                <DetailListing
                    className='vehicle-detail-label'
                >
                    <label>{t(...lan.consumptionCombinedIKm())}</label>
                    <span>{item?.owner_vehicle?.fuel_combined ? item?.owner_vehicle?.fuel_combined + " l/100km" : "-"}</span>
                </DetailListing>
                <DetailListing
                    className='vehicle-detail-label'
                >
                    <label>{t(...lan.consumptionUrbanLKm())}</label>
                    <span>{item?.owner_vehicle?.fuel_urban_liter ? item?.owner_vehicle?.fuel_urban_liter + " l/100km" : "-"}</span>
                </DetailListing>
                <DetailListing
                    className='vehicle-detail-label'
                >
                    <label>{t(...lan.consumptionExtraUrbanLKm())}</label>
                    <span>{item?.owner_vehicle?.fuel_extra_urban_liter ? item?.owner_vehicle?.fuel_extra_urban_liter + " l/100km" : "-"}</span>
                </DetailListing>
            </Box>
        </Box></DetailsWrapper>
    )
}

export default EnvironmentDetail