import { Box, Fade, Tab, Tabs } from '@mui/material'
import { translations } from 'locales/translations';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { colorNew } from 'styles/StyleConstants';
import TabParent from '../AdsListing/TabParent';
import ConditionMaintenance from './InnerDetails/ConditionMaintenance';
import EngineEnviroment from './InnerDetails/EngineEnviroment';
import SizeWeigth from './InnerDetails/SizeWeigth';
import VehicleView from './InnerDetails/VehicleView';
import { productTypes } from 'config/variables';
import { useDeviceType } from 'app/Hooks/useMediaQuery';

const DetailsView = (props: any) => {
    const { t } = useTranslation()
    const { item } = props
    const [valueTab, setValueTab] = useState<number>(0);
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };
    return (
        <>{item?.owner_vehicle?.vehicle?.product_category_type?.product_type_id === productTypes.car && <Box

        >
            <Tabs
                value={valueTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className='vehcileDetailsTabs'
                sx={{
                    borderBottom: '1px solid rgb(239, 239, 255)',
                    mb: 1,
                    ml: isSmallDevice || isMediumDevice ? 2 : 1,
                    'button': {
                        fontSize: "15px !important",
                        fontWeight: 400,
                        padding: '0px',
                        minWidth: "auto",
                        marginRight: "15px",
                        height: '40px',
                        minHeight: '40px',
                        textTransform: 'uppercase !important'
                    }
                }}
            >

                <Tab label={t(translations.addVehicleScreen.VEHICLE)} />
                <Tab label={t(translations.webTexts.SIZE_AND_WEIGHT)} />
                <Tab label={t(translations.ads.CONDITION_MAINTENANCE)} />
                <Tab label={t(translations.ads.ENGINE_ENVIRONMENT)} />

            </Tabs>
            <TabParent value={valueTab} index={0}>
                <Fade in={true}>
                    <Box sx={{ background: colorNew.colorWhite }} >
                        <VehicleView item={item} hideVat={props.hideVat} />
                    </Box>
                </Fade>
            </TabParent>
            <TabParent value={valueTab} index={1}>
                <Fade in={true}>
                    <Box sx={{ background: colorNew.colorWhite }} >
                        <SizeWeigth item={item} />
                    </Box>
                </Fade>
            </TabParent>
            <TabParent value={valueTab} index={2}>
                <Fade in={true}>
                    <Box sx={{ background: colorNew.colorWhite }} >
                        <ConditionMaintenance item={item} />
                    </Box>
                </Fade>
            </TabParent>
            <TabParent value={valueTab} index={3}>
                <Fade in={true}>
                    <Box sx={{ background: colorNew.colorWhite }}  >
                        <EngineEnviroment item={item} />
                    </Box>
                </Fade>
            </TabParent>
        </Box>}</>
    )
}

export default DetailsView;