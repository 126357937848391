import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { ContentLoader } from '../ContantLoader/ContentLoader';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { SvgVerified } from '../svgicons/svgNew';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PrivatePusherClient } from 'websocket';
import { getActiveSubsidiaryStored, removeActiveSubsidiaryStored, setSubsidiaryToLocal } from 'config/variables';
import { addCardReset, getSubsidiaryDetailsRequest, storeNewCardReset } from 'store/actions/company-actions';
import { blockedByRequest, getArchivedChatRequest, getBlockedChatRequest, getChatRequest, getsubsidiaryActiveMemberListRequest, resetChatRequest } from 'store/actions/chat-action';
import { translations } from 'locales/translations';
import { useQueryStringParams } from 'app/Hooks/useParams';
import { safeRedirect } from 'config/utils';

const SubscriptionPaymentResponse = () => {

  // hooks
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch()

  // pusher instance
  const PrivatePusherClientInstance = PrivatePusherClient();

  // Get active subsidiary from local storage
  let activeSubsidiary: string | null = getActiveSubsidiaryStored();

  // check if payment is successful or not
  const isSuccessPayment = location.pathname.includes(routes.userRoutes.subscriptionBuySuccess)

  // data from redux
  const { user } = useSelector((state: any) => state.auth)

  const { is_mobile: isMobile } = useQueryStringParams()



  useEffect(() => {
    if (isMobile != 'false' && isMobile != null && isMobile != undefined) {
      // redirect on mobile app
      safeRedirect(process.env.REACT_APP_REDIRECT_ON_APP_LINK)
      return
    }

    // After successful payment, you should redirect to your payment success page.
    if (isSuccessPayment) {
      if (activeSubsidiary && activeSubsidiary !== null) {
        setTimeout(() => {
          setSubsidiaryToLocal(JSON.parse(activeSubsidiary));
          removeActiveSubsidiaryStored();
          dispatch(addCardReset())
          dispatch(storeNewCardReset())
          PrivatePusherClientInstance?.unsubscribe(`private-inbox.user.${user.id}`)
          dispatch(resetChatRequest())
          dispatch(getChatRequest({ page: 1 }))//chatlist
          dispatch(getArchivedChatRequest({ page: 1 }))//archived chat list
          dispatch(getBlockedChatRequest({ page: 1 })) //blocked chat list
          dispatch(blockedByRequest())//list of users who have blocked loggedIn user
          dispatch(getsubsidiaryActiveMemberListRequest({ subsidiary_id: activeSubsidiary }))
          dispatch(getSubsidiaryDetailsRequest({ subsidiary_Id: activeSubsidiary, saveSubsidiary: true, subsidiaryId: activeSubsidiary }))
          // Redirect to newly created company profile page
          history.push(routes.companyRoutes.companyProfile)

        }, 2000)
      }

      return
    }
    // If not successful, redirect to payment failure page
    history.push(routes.companyRoutes.company)
  }, []);


  return (
    <>
      {isSuccessPayment ? <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', my: 5 }}>
          <Card sx={{ maxWidth: 345, minWidth: 300 }}>
            <CardActionArea>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 5 }}>
                <SvgVerified />
              </Box>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography gutterBottom variant="h6" component="div">
                  {t(translations.webTexts.YOUR_PAYMENT_IS_SUCCESSFUL)}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  {t(translations.webTexts.THANK_YOU_FOR_YOUR_PAYMENT)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Box> :
        <ContentLoader />}
    </>
  )
}

export default SubscriptionPaymentResponse