import styled from "styled-components";

export const SwitchButton = styled.label`
    position: relative;
    display: inline-block;
    width: 43px !important;
    height: 23.5px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
    position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
  }
  input:checked + .slider {
    background-color: #607afc;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #607afc;
  }
  input:checked + .slider:before {
    transform: translateX(20px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  &.sm{
    width: 40px !important;
    height: 20px;
    .slider{
      &:before{
        height: 16px;
        width: 16px;
      }
    }
    input:checked + .slider:before{
      transform: translateX(19px);
    }
  }
`