import { Box } from '@mui/material'
import { colorNew } from 'styles/StyleConstants';
import ReviewComponent from '../ChildComponents/Review';
import { useWindowSize } from 'app/Hooks/windowResize';

const HistoryAndReview = ({ scrollFlagRev, _scrollFlagRev, setPageRev, pageRev }) => {
  const [mobile, width] = useWindowSize()

  return (
    <Box className='historyAndReviewConatiner flexColumn alignItemsCenter'sx={{ marginLeft:mobile ? 'unset' : '30%'}}>
      <Box
        sx={{
          background: colorNew.colorWhite,
          width: '100%'
        }}
      >
        <ReviewComponent _scrollFlag={_scrollFlagRev} scrollFlag={scrollFlagRev} setPage={setPageRev} page={pageRev} />
      </Box>
    </Box>
  )
}

export default HistoryAndReview