import { Box } from '@mui/material'
import { urlParamInterface } from 'config/interface'
import { getProductTypeId, vehicles } from 'config/variables'
import { translations } from 'locales/translations'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { checkKey } from '../filterConfig'
import SearchDropdownForCountry from 'app/components/CustomFields/searchDropdownForCountry'

const Country = ({ updateURL }) => {

    const { t } = useTranslation()
    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const { vehicle, brand_id, model_id, country_id }: urlParamInterface = useParams();

    const productType = useMemo(() => getProductTypeId(vehicle), [vehicle])

    const [countries, _countries] = useState<Object>({})

    const [countryID, _countryID] = useState<Object>({})

    // Updating response of model list API (changing keyname only from model_name to name)
    useEffect(() => {
        const countriesObj = localStorage.getItem('groupedCountries')
        if (countriesObj) {
            _countries(JSON.parse(countriesObj))
        }
    }, [localStorage.getItem('groupedCountries')])

    // Selects Country when url is opened directly and sets into field from grouped countries list
    useEffect(() => {
        if (Object.keys(countries).length > 0) {
            let selectedCountry = ''
            // checking if only there is country value in URL which is at place of brand 
            if (brand_id && (checkKey(brand_id, productType, 'country')?.name == 'country_slug')) {
                selectedCountry = brand_id
            }
            // checking if only there is country value in URL which is at place of model 
            if (model_id && (checkKey(model_id, productType, 'country')?.name == 'country_slug')) {
                selectedCountry = model_id
            }
            // checking if only there is correct value of country 
            if (country_id && (checkKey(country_id, productType, 'country')?.name == 'country_slug')) {
                selectedCountry = country_id
            }
            Object.keys(countries).forEach((country) => {
                let existence = countries[country].find((country) => country.slug == selectedCountry)
                if (existence !== undefined && Object.keys(existence).length > 0) {
                    _countryID(existence)
                    return
                }
            })
        }
    }, [countries, brand_id, model_id, country_id])

    useEffect(() => {
        if (model_id && !country_id && (checkKey(model_id, productType, 'country')?.name != 'country_slug')) {
            _countryID({})
            return
        }
        if (brand_id && !model_id && !country_id && (checkKey(brand_id, productType, 'country')?.name != 'country_slug')) {
            _countryID({})
            return
        }
        if (!country_id && !model_id && !brand_id) {
            _countryID({})
        }

    }, [brand_id, model_id, country_id])

    const handleCountry = (option) => {
        if (countryID && countryID.slug == option.slug) {
            let url = location.pathname + location.search
            let newUrl = url.replace(`/${countryID.slug}`, '')
            history.replace(newUrl)
            return
        }
        _countryID(option)
        adCountrySlug(option.slug)
    }

    const adCountrySlug = (slug) => {

        if (checkKey(brand_id, productType, 'country')?.name == 'country_slug') {
            const path = generatePath(
                routes.vehicleRoutes.publicAds + `/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})/:brand_id?/:model_id?/:country_id?`,
                {
                    ...params,       // <-- shallow copy in the existing param values
                    brand_id: slug,
                },
            );
            history.replace(path + location.search)
            return
        }
        if (checkKey(model_id, productType, 'country')?.name == 'country_slug') {
            const path = generatePath(
                routes.vehicleRoutes.publicAds + `/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})/:brand_id?/:model_id?/:country_id?`,
                {
                    ...params,       // <-- shallow copy in the existing param values
                    model_id: slug,
                },
            );
            history.replace(path + location.search)
            return
        }
        const path = generatePath(
            routes.vehicleRoutes.publicAds + `/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})/:brand_id?/:model_id?/:country_id?`,
            {
                ...params,       // <-- shallow copy in the existing param values
                country_id: slug,
            },
        );
        history.replace(path + location.search)

    }

    return (
        <Box sx={{ gap: '24px', mt: 1 }} className='flexColumn'>
            <SearchDropdownForCountry title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_COUNTRY)} list={countries ?? {}} value={countryID} handleChange={(option) => handleCountry(option)} />
        </Box>
    )
}

export default Country