import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Typography } from '@mui/material'
import { SvgEnvelop } from 'app/components/svgicons/svg'
import { SvgCloseIcon, SvgMobileLeftArrow, SvgPhoneIcon } from 'app/components/svgicons/svgNew'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { routes } from 'routes/routes'
import { colorNew } from 'styles/StyleConstants'

const UserContactsPopUp = (props) => {
    const { open, setOpen, phone, email, btn } = props
    const { t } = useTranslation()
    const { loggedIn } = useSelector((state: any) => state.auth)
    return (
        <>
            <Box>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogContent
                        sx={{
                            '.MuiDialogContentText-root': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '20px 20px 0px 20px',
                            },
                            '.close-icon': {
                                position: 'absolute',
                                right: '15px',
                                top: '20px',
                                'svg': {
                                    'path': {
                                        stroke: colorNew.colorGrayDark,
                                        fill: colorNew.colorGrayDark
                                    }
                                }
                            },
                        }}>
                        <div style={{ marginBottom: '5px' }}>
                            <Typography sx={{
                                fontWeight: "600px",
                                fontSize: "21px",
                                lineHeight: "25px",
                                color: `${colorNew.colorBlack}`,
                                width: '100%',
                            }}
                                component="span">{btn == 'call' ? t(translations.ads.CALL) : t(translations.verificationScreen.EMAIL)}</Typography>
                            <span className="close-icon pointer"><SvgCloseIcon onClick={() => setOpen(false)} /></span>
                        </div>
                        <Divider />
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Box
                                sx={{
                                    '.user-personal-icon': {
                                        ml: 2,
                                        display: 'flex',
                                        alignSelf: 'flex-end',
                                        '.mailBox': {
                                            width: '100px',
                                            height: 30,
                                            borderRadius: 15,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            'svg': {
                                                width: 18,
                                                height: 18,
                                                'path': {
                                                    fill: colorNew.colorWhite
                                                }
                                            },
                                            '&.phoneIcon': {
                                                backgroundColor: colorNew.colorGreenDark,
                                                mr: '5px'
                                            },
                                            '&.emailIcon': {
                                                backgroundColor: colorNew.primaryColor
                                            }
                                        },
                                    },
                                    '.icon': {
                                        position: 'absolute',
                                        bottom: 7,
                                        right: 0,
                                        'span': {
                                            fontSize: 14,
                                            mr: 1,
                                            'svg': {
                                                mr: '5px',
                                                width: 24,
                                                height: 20
                                            },
                                        }
                                    },
                                }}
                            >
                                <div className="user-personal-icon">
                                    {phone ?
                                        <Box sx={{
                                            display: btn == 'call' ? 'flex' : 'none',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '20px',
                                        }}
                                        >
                                            <Typography variant="subtitle1" component="div">
                                                {t(translations.verificationScreen.PHONE)} : {props?.callPrefix ? props?.callPrefix + " " + phone : phone}
                                            </Typography>
                                            <a href={`tel: ${props?.callPrefix ? props.callPrefix + " " + phone : phone}`} className="mailBox phoneIcon">
                                                <SvgPhoneIcon />
                                            </a>
                                        </Box>
                                        : null
                                    }
                                    {email ?
                                        <Box sx={{
                                            display: btn == 'email' ? 'flex' : 'none',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '20px',
                                        }}>
                                            <Typography variant="subtitle1" component="div">
                                                {t(translations.verificationScreen.EMAIL)} : {email}
                                            </Typography>
                                            <a href={`mailto:${email ? email : null}`} className="mailBox emailIcon">
                                                <SvgEnvelop />
                                            </a>
                                        </Box>
                                        : null
                                    }
                                </div>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Box>
                        </Box>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}

export default UserContactsPopUp