import { Box, Typography } from '@mui/material'
import { numberFormat } from 'config/variables'
import { translations } from 'locales/translations'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useSelector } from 'react-redux'

const SelectedCreditPlan = () => {
    const { t } = useTranslation();
    const { credit_details, discount_applied, discounts = 0, items, subtotal = 0, taxes = 0, total = 0 } = useSelector((state: RootStateOrAny) => state.user.creditPayment)

    return (
        <Box className='selecte-plan plan-payment-wrapper'>
            <Box className='credit-header'>
                {t(translations.HOT_DEALS.SELECTED_PLAN)}
            </Box>
            {/* Remaining validity of promo code */}
            <Box className='credits-info flexRow '>
                <Box sx={{ ml: 2 }} >
                    <Typography className='credits'>{t(translations.HOT_DEALS.CREDITS, { credits: credit_details?.credit_points || 0, creditText: credit_details?.credit_points > 1 ? t(translations.invoiceScreen.CREDITS) : t(translations.HOT_DEALS.CREDIT) })}</Typography>
                    <Box className='validity'>{t(translations.HOT_DEALS.VALIDITY)}: <span className='validity-days'> {t(translations.HOT_DEALS.DAYS, { days: credit_details?.validity_in_days || 0 })}</span></Box>
                </Box>
                <Box className='total-credit'>€{numberFormat((total / 100).toFixed(2)) || 0}</Box>
            </Box>


            {/* Credit promo code expiration time */}
            <Box className='credits-info flexRow credit-sub-total'>
                <Box className='' sx={{ ml: 2 }} >
                    <Typography className='text-style-left'>{t(translations.HOT_DEALS.CREDIT_EXPIRATION)}</Typography>
                </Box>
                <Box className='text-style-right'>{moment(credit_details?.credit_expiry_date).format('DD/MM/YYYY')}</Box>
            </Box>

            {/* New credits after buying new credit */}
            <Box className='credits-info flexRow credit-sub-total'>
                <Box className='' sx={{ ml: 2 }} >
                    <Typography className='text-style-left'>{t(translations.HOT_DEALS.YOUR_NEW_CREDIT_WILL_BE)}</Typography>
                </Box>
                <Box className='text-style-right'>{credit_details?.new_credits}</Box>
            </Box>

            <Box sx={{ border: '1px solid #f2f2f2' }}></Box>

            {items?.unit_amount ? <Box className='sub-total-info flexRow '>

                <Typography className='text-style'>{t(translations.ads.PRICE)} </Typography>

                <Box className='text-style-right'>€{numberFormat((items?.unit_amount / 100).toFixed(2)) || 0}</Box>
            </Box> : null}
            {discounts?.total_discount ? <Box className='sub-total-info flexRow '>

                <Typography className='text-style'>{t(translations.invoiceScreen.DISCOUNT)} {discounts?.percent_off ? `(${parseInt(discounts.percent_off)}%)` : ''}</Typography>

                <Box className='text-style-right'>€{numberFormat((discounts.total_discount / 100).toFixed(2)) || 0}</Box>
            </Box> : null}
            <Box className='sub-total-info flexRow '>

                <Typography className='text-style'>{t(translations.webTexts.SUBTOTAL)}</Typography>

                <Box className='text-style-right'>€{numberFormat((subtotal / 100).toFixed(2)) || 0}</Box>
            </Box>

            {/* VAT */}
            <Box className='sub-total-info flexRow '>
                <Typography className='text-style'>{`${t(translations.ads.VAT)}.(${parseInt(taxes?.percentage) || 0}% ${t(translations.CTB_CREDIT.INCLUSIVE)})`}</Typography>

                <Box className='text-style-right'>€{numberFormat(((taxes?.tax_amount || 0) / 100).toFixed(2)) || 0}</Box>
            </Box>

            <Box sx={{ border: '1px solid #f2f2f2' }}></Box>

            {/* Total amount payable */}
            <Box className='sub-total-info flexRow '>

                <Typography className='total-style'>{t(translations.invoiceScreen.TOTAL)}</Typography>

                <Box className='total-style-right'>€{numberFormat((total / 100).toFixed(2)) || 0}</Box>
            </Box>
        </Box>
    )
}

export default React.memo(SelectedCreditPlan)