import React, { useEffect, useState } from 'react'
import StyleWrapper from './style'
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';
import { vehicles } from 'config/variables';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { BacklinkBrandType } from 'config/interface';
import { useSelector } from 'react-redux';

function BacklinkWithBrands() {

    const { t } = useTranslation()
    const [brands, _brands] = useState([]);
    const { loadingForce } = useSelector((state: any) => state.vehicle)

    useEffect(() => {
        const brandList = JSON.parse(localStorage.getItem('carFilterdata') || '{}')?.brandList?.items || []
        _brands(brandList)
    }, [loadingForce])
    if (brands.length == 0) return <></>;
    return (
        <StyleWrapper className=' filterWrapper usedCars'>
            <h2>{t(translations.ads.USED_CARS_BY_BRANDS)} </h2>
            <Grid className='filterContainer scrollBar scrollOnHover' container spacing={2}  >
                {
                    brands.map((brand: BacklinkBrandType) => {
                        const name = brand.name
                        return <Grid key={'brand' + (brand.id)} item xs={6} sm={6} md={3} lg={2} xl={2} className='brandBox'>
                            <Link className='link' to={{
                                pathname: `${routes.vehicleRoutes.publicAds}/${vehicles.car}/${brand.slug}`,
                                search: "new_vehicle=0"
                            }}
                                aria-label={t(translations.META_AND_H1_FOR_SEO.CAR_META_TITLE_WITH_BRAND, { brandName: name })}
                            >
                                {brand.name}
                            </Link>
                        </Grid>
                    })
                }
            </Grid>
        </StyleWrapper>
    )
}

export default BacklinkWithBrands
