import moment from 'moment';
import { useEffect, useState } from 'react';

const useCountdown =  (targetDate: any) => {
 
  var updatedTraget:any;
  updatedTraget =  new Date(targetDate).toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});

  const countDownDate = new Date(updatedTraget).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);
  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))>0?Math.floor(countDown / (1000 * 60 * 60 * 24)):'00';
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )>0?Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  ):'00';
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))>0?Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)):'00';
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)>0?Math.floor((countDown % (1000 * 60)) / 1000):'00';
  if(isNaN(countDown)){
    return ['-', '-', '-', '-'];
  }else{
    return [('0'+ days).slice(-2), ('0' + hours).slice(-2), ('0' + minutes).slice(-2), ('0' + seconds).slice(-2)];
  }
};

export { useCountdown };


export const getUtcTime = () => {
  return moment.utc()
}
