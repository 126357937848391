import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { ChatWrapper } from '../../style/chatStyle'
import { SvgMobileLeftArrow, SvgmemSearch } from 'app/components/svgicons/svgNew'
import { translations } from 'locales/translations'
import AvatarandDetails from './AvatarandDetails'
import { useTranslation } from 'react-i18next'
import { CreateNewChat } from '../../interface/interface'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { chatCases, chatType, createStorechat } from 'config/chatConfig'
import { getChatSuccess, getUserListRequest, particularChatSuccess, resetUserList } from 'store/actions/chat-action'
import * as ChatConstants from "config/chatConfig"
import CreateLicensePlate from 'app/pages/LicensePlatePage/Component/CreateLicensePlate'
import * as publicProfileConstants from "config/publicProfileConfig"


const CreateNewChatMobile = ({ setSearchText, setLicensePlateAndCountry, setChatType, resetFunc, setChatId, page, setPage, handleSearchUser, allUserList, searchText, handleSearchForLicensePlate, searched }: CreateNewChat) => {

    const { t } = useTranslation()
    const dispatch = useDispatch();
    const scrollRef: any = useRef(null)

    const { chatStoreData, chatList, chatListAllData, loadingUsers, userListLastPage } = useSelector((state: RootStateOrAny) => state.chat)

    const [scrollFlag, _scrollFlag] = useState<boolean>(true);
    const [selectedTab, _selectedTab] = React.useState(ChatConstants.CHAT_TABS.USERS);

    const handleCreatechat = (receiverId, accountType) => {
        createStorechat(receiverId, chatCases.PERSONAL_CHAT, dispatch, accountType)
    }

    useEffect(() => {
        if (chatStoreData && Object.keys(chatStoreData).length && chatStoreData.success) {
            let chatListData = [...chatList]
            const findUser = chatListData.some((o) => o.id === chatStoreData.data.item.id)
            dispatch(particularChatSuccess({}))
            // If chat is initiated with blocked user
            if (chatStoreData.data.is_blocked) {
                setChatType(chatType.BLOCKED)
                setChatId(chatStoreData.data.item.id)
                return;
            }
            // if chat initiated with archived user then
            if (chatStoreData.data.item.is_archived == ChatConstants.archivedStatus.archive) {
                // user appended in archived list and chat type updated
                setChatType(chatType.ARCHIVED)
                setChatId(chatStoreData.data.item.id)
                return;
            }
            if (!findUser) {
                let chatListObject = { ...chatListAllData }
                chatListData = [chatStoreData.data.item, ...chatList]
                chatListObject.data.data = chatListData
                dispatch(getChatSuccess({ data: chatListObject, append: false }))
            }
            localStorage.setItem("chat_id", chatStoreData.data.item.id)
            setChatId(chatStoreData.data.item.id)
            resetFunc(false)
        }
    }, [chatStoreData])

    //Function request for next page data when scrolled to bottom
    const handleScroll = (e) => {
        // bottom return boolean value and Math.abs to get absolute value 
        const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
        if (bottom && scrollFlag && page < userListLastPage) {
            setPage(page + 1)
            let data = {
                page: page + 1
            }
            if (searchText && searchText !== '') {
                data['keyword'] = searchText
            }
            dispatch(getUserListRequest(data));
            _scrollFlag(false)
            return;
        } else {
            _scrollFlag(true)
        }
    }
    // function for handling tab change 
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        _selectedTab(newValue);
    };
    const handlLicensePlate = () => {
        setSearchText('')
        dispatch(resetUserList())
    }
    const handleUsers = () => {
        dispatch(getUserListRequest({ keyword: '' }))
        setLicensePlateAndCountry({
            country: '',
            LicensePlate: ''
        })
    }

    const showListing = () => {
        if (selectedTab === ChatConstants.CHAT_TABS.USERS) {
            return true
        }
        if (selectedTab === ChatConstants.CHAT_TABS.LICENSE_PLATE && searched) {
            return true
        }
        return false
    }

    return (
        <ChatWrapper>
            <Box className='intiateChat flexColumn'>
                <Box className='flexRow alignItemsCenter backButton'>
                    <SvgMobileLeftArrow onClick={() => resetFunc(false)} />
                    <span>{t(translations.CHAT.CREATE_PERSONAL_CHAT)}</span>
                    <span>{" "}</span>
                </Box>

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', 'button': { fontSize: "15px", fontWeight: 500, textTransform: 'none' } }}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        aria-label="wrapped"
                        sx={{ width: '100%' }}
                    >
                        <Tab value={ChatConstants.CHAT_TABS.USERS} label={t(translations.CHAT.USERS)} onClick={handleUsers} sx={{ width: '50%', textTransform: 'none' }} />
                        <Tab value={ChatConstants.CHAT_TABS.LICENSE_PLATE} label={t(translations.ads.LPLATE)} onClick={handlLicensePlate} sx={{ width: '50%', textTransform: 'none' }} />
                    </Tabs>
                </Box>
                <Box className="invite-member" >
                    {selectedTab == ChatConstants.CHAT_TABS.USERS ? <div className="input-group">
                        <span>
                            <SvgmemSearch />
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t(translations.webTexts.SEARCH_MEMBER)}
                            aria-label="search"
                            aria-describedby="aearch"
                            onChange={(e) => handleSearchUser(e)}
                            autoFocus
                        />
                    </div>
                        :
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <CreateLicensePlate setLicensePlateAndCountry={setLicensePlateAndCountry} hideItems={true} handleSearchForLicensePlate={handleSearchForLicensePlate} />
                        </Box>}


                    {showListing() &&
                        <>
                            <Box className="suggestedMember">
                                <h6 className="suggesttitle">
                                    {selectedTab == ChatConstants.CHAT_TABS.USERS ? t(translations.webTexts.SUGESTION_MEMBER_CARTOBIKE) : t(translations.LICENSEPLATE.OWNERS)}
                                </h6>
                            </Box>
                            <Box className="intiateChatUserListMobile" onScroll={(e) => handleScroll(e)} ref={scrollRef} style={{ maxHeight: selectedTab == ChatConstants.CHAT_TABS.USERS ? 'calc( 100vh - 290px)' : 'calc( 100vh - 385px)' }}>

                                {/* Showing list of data */}
                                {allUserList && allUserList.length > 0 ? allUserList.map((user, index) => (
                                    <Box className='select_user pointer flexRow alignItemsCenter justifyContentBetween' key={user.id} style={{ position: 'relative' }}
                                        onClick={() => handleCreatechat(user.id, user.source)}>
                                        <AvatarandDetails userInfo={user} hidebackButton={true} hideStatus={true} hideFlag={false} newChat={true} userInfo={user} text={`${publicProfileConstants.convertFollowersCount(user.followers_count)}  ${user.followers_count == 1 ? t(translations.PUBLICPROFILE.FOLLOWER) : t(translations.PUBLICPROFILE.FOLLOWERS)}`} followersCount={true} />
                                        {user.source === "subsidiary" && <Box sx={{ position: 'absolute', right: "15px" }}>
                                            <span className="dealerText">{t(translations.webTexts.DEALER)}</span>
                                        </Box>}
                                    </Box>
                                ))
                                    :
                                    !loadingUsers &&
                                    <>
                                        {
                                            selectedTab === ChatConstants.CHAT_TABS.USERS && <Box className='select_user pointer'>
                                                <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>{t(translations.CHAT.USER_NOT_FOUND)}</Typography>
                                            </Box>
                                        }
                                        {
                                            searched && selectedTab === ChatConstants.CHAT_TABS.LICENSE_PLATE && <Box className='select_user pointer'>
                                                <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>{t(translations.LICENSEPLATE.USER_NOT_FOUND)}</Typography>
                                            </Box>
                                        }
                                    </>
                                }

                                {/* Loader appears while loading data */}
                                {loadingUsers &&
                                    <Box className='select_user pointer alignItemsCenter justifyContentCenter'>
                                        <CircularProgress />
                                    </Box>
                                }
                            </Box>
                        </>
                    }
                </Box>
            </Box>
        </ChatWrapper>
    )
}

export default CreateNewChatMobile