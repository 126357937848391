import {createAction} from 'redux-actions';



export const createLicensePlateRequest = createAction('CREATE_LICENSE_REQUEST');
export const createLicensePlateSuccess = createAction('CREATE_LICENSE_SUCCESS');
export const createLicensePlateFailure = createAction('CREATE_LICENSE_FAILURE');

export const getVehicleListRequest = createAction('GET_VEHICLE_LIST_REQUEST');
export const getVehicleListSuccess = createAction('GET_VEHICLE_LIST_SUCCESS');
export const getVehicleListFailure = createAction('GET_VEHICLE_LIST_FAILURE');

export const getVehicleLicensePlateListRequest = createAction('GET_LICENSED_VEHICLE_LIST_REQUEST');
export const getVehicleLicensePlateListSuccess = createAction('GET_LICENSED_VEHICLE_LIST_SUCCESS');
export const getVehicleLicensePlateListFailure = createAction('GET_LICENSED_VEHICLE_LIST_FAILURE');

export const findableForPublicRequest = createAction('FINDABLE_FOR_PUBLIC_REQUEST');
export const findableForPublicSuccess = createAction('FINDABLE_FOR_PUBLIC_SUCCESS');
export const findableForPublicFailure = createAction('FINDABLE_FOR_PUBLIC_FAILURE');

export const deleteLicensePlateRequest = createAction('DELETE_LICENSE_PLATE_REQUEST');
export const deleteLicensePlateSuccess = createAction('DELETE_LICENSE_PLATE_SUCCESS');
export const deleteLicensePlateFailure = createAction('DELETE_LICENSE_PLATE_FAILURE');