export const publicRoutes = {
  shop: '/shop/:slug',
  shopURL: '/shop',
  shopURLAbout: '/about',
  shopURLContact: '/contact',
  shopURLServices: '/service',
  faq: '/faqs',
  promoCodeEmailing: '/promo_code/emailing/:code',
  ourCookiesPolicy: '/our-cookies-policy',
  terms: "/terms",
  privacy_policy: "/privacy-policy",
  data_protection: "/data-protection",
  legal_notice: "/legal-notice",
  legal_documents: "/legal-documents",
  become_partner: "/become-partner",
  public_profile_user: "/user",
  public_profile_subisidiary: "/pro",
  license_plate: "/license-plate",
  contactUs: "/contact-us",
  hotDeals: "/hot-deals",
  hotDeal: "/hot-deal",
  hotDealEvent: "/hot-deals-event",
};
