import React, { useEffect, useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom'
import { StripePaymentConstant } from 'config/variables'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes/routes'
import { switchUserOpen } from 'store/actions/component-actions'
import { showAuctionsTo } from 'config/constants'
import moment from 'moment'
import { SvgInfoIcon } from 'app/components/svgicons/svgNew'
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import Paper from '@mui/material/Paper';
import { SvgClose } from 'app/components/svgicons/svg';


const getTextOnButton = (userData, subsidiaryId, t, loggedIn, subsidiaryData, isProfileComplete) => {
    if (!loggedIn) {
        return t(translations.authScreens.LOGIN);
    }

    if (!isProfileComplete) {
        return t(translations.CHAT.COMPLETE_PROFILE_BUTTON);
    }

    if (subsidiaryId || subsidiaryData.length > 0) {
        // if user loggedIn as subsidary then display swwitch profile or user have subsidiaries
        return t(translations.authScreens.SWITCHPROFILE);
    }

    if (userData && userData.parent_companies && userData.parent_companies.length > 0) {
        // if userdata  have  subsidiaries 

        if (userData.parent_companies[0].subsidiaries.length == 1) {
            // if a user have only one company then
            if (userData.parent_companies[0].subsidiaries[0]?.subscriptions.length == 0 || (userData.parent_companies[0].subsidiaries[0].subscriptions && userData.parent_companies[0].subsidiaries[0].subscriptions[0].stripe_status !== StripePaymentConstant.PaymentActive)) {
                // if payment incomplete then display create company
                return t(translations.AD_DETAIL_PAGE.CREATE_PRO_ACCOUNT)
            }
            return t(translations.authScreens.SWITCHPROFILE);

        }

        if (userData.parent_companies[0].subsidiaries.length > 1) {
            // if a user have more than one company then
            const findActiveSub = userData.parent_companies[0].subsidiaries.findIndex((subsidiary) => (subsidiary.subscriptions[0].stripe_status == StripePaymentConstant.PaymentActive))
            if (findActiveSub != -1) {
                return t(translations.authScreens.SWITCHPROFILE);
            }

        }

    }

    return t(translations.AD_DETAIL_PAGE.CREATE_PRO_ACCOUNT)

}

const getMessageHeading = (userData, subsidiaryId, t, loggedIn, subsidiaryData, adDetail, isProfileComplete) => {
    if (!loggedIn) {
        return t(translations.authScreens.LOGIN);
    }

    if (!isProfileComplete) {
        return t(translations.CHAT.COMPLETE_PROFILE_BUTTON);
    }

    if (adDetail.display_for == showAuctionsTo.DEALER && !subsidiaryId) {
        if (userData && userData.parent_companies && userData.parent_companies.length > 0 && userData.parent_companies[0].subsidiaries.length == 1) {
            // if a user have only one company then
            if (userData.parent_companies[0].subsidiaries[0]?.subscriptions.length == 0 || (userData.parent_companies[0].subsidiaries[0].subscriptions && (userData.parent_companies[0].subsidiaries[0].subscriptions[0].stripe_status == StripePaymentConstant.PaymentIncomplete || userData.parent_companies[0].subsidiaries[0].subscriptions[0].stripe_status == StripePaymentConstant.PaymentCanceled))) {
                // if payment incomplete then display company required
                return t(translations.AD_DETAIL_PAGE.EXCLUSIVE_SALE)
            }
        }
        if (userData && userData.parent_companies && userData.parent_companies.length == 0 && subsidiaryData.length == 0) {
            // If no subsidiary then display company required
            return t(translations.AD_DETAIL_PAGE.EXCLUSIVE_SALE)
        }
        //otherwise display auction for dealer
        return t(translations.ads.AUCTION_FOR_DEALERS)
    }
    if (adDetail.display_for == showAuctionsTo.PRIVATE && subsidiaryId) {
        return t(translations.ads.AUCTION_FOR_PRIVATE)
    }
}

const getMessage = (isActive, subsidiaryId, t, adDetail, loggedIn, userData, subsidiaryData, isProfileComplete) => {

    if (!loggedIn) {
        return t(translations.AD_DETAIL_PAGE.LOGIN_TO_ACCESS_BID_SECTION)
    }

    if (!isProfileComplete) {
        return t(translations.validationAndPopText.COMPLETE_PROFILE_FOR_AUCTION);
    }

    if (subsidiaryData.length == 0) {
        // If no subsidiary then display company required
        return t(translations.AD_DETAIL_PAGE.COMPANY_REQUIRED_TEXT, { ChangeProfile: t(translations.AD_DETAIL_PAGE.CHANGE_PROFILE), Profile: t(translations.AD_DETAIL_PAGE.PROFILE), Company: t(translations.AD_DETAIL_PAGE.COMPANY) })
    }
    if (adDetail.display_for == showAuctionsTo.DEALER && !subsidiaryId) {
        return t(translations.AD_DETAIL_PAGE.EXCLUSIVE_BID_FOR_PROFESSIONALS)
    }

    if (adDetail.display_for == showAuctionsTo.PRIVATE && subsidiaryId) {
        return t(translations.AD_DETAIL_PAGE.EXCLUSIVE_BID_FOR_PRIVATE)
    }

    if (adDetail.display_for == showAuctionsTo.ALL || (adDetail.display_for == showAuctionsTo.DEALER && subsidiaryId) || (adDetail.display_for == showAuctionsTo.PRIVATE && !subsidiaryId)) {
        return t(translations.validationAndPopText.INACTIVE_ADD_BIDING_ERROR, { time: moment(adDetail.auction.start).format('DD/MM/YYYY HH:mm') })
    }

}

const AuctionSwitchProfile = ({ subsidiaryId, adDetail, isActive, fixedBottom }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()
    const { profileUpdated, userData } = useSelector((state: any) => state.user)
    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth)
    const { subsidiaryData } = useSelector((state: RootStateOrAny) => state.company)

    const [isProfileComplete, _isProfileComplete] = useState<boolean>(false)
    const [open, setOpen] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            let data = userData?.profile_is_complete ?? false;
            _isProfileComplete(data);
        }
    }, [userData])


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };


    const handleClickAction = () => {
        if (!loggedIn) {
            // redirect on login page if not logged in
            history.push(routes.authRoutes.login)
            return
        }

        if (!isProfileComplete) {
            // redirect on complete profile screen
            history.push(routes.userRoutes.userProfileEdit)
            return
        }
        if (subsidiaryData.length > 0) {
            dispatch(switchUserOpen())
            return
        }

        if (!subsidiaryId) {
            if (userData && userData.parent_companies && userData.parent_companies.length == 0 && subsidiaryData.length == 0) {
                // If no subsidiary then redirect on create company 
                history.push(routes.companyRoutes.createCompany)
                return
            }
            if (userData && userData.parent_companies[0] && userData.parent_companies[0].subsidiaries.length > 1) {
                // if user have more then 1 subsidiaries then open switch profile drawer
                const findActiveSub = userData.parent_companies[0].subsidiaries.findIndex((subsidiary) => (subsidiary.subscriptions[0].stripe_status == StripePaymentConstant.PaymentActive))
                if (findActiveSub != -1) {
                    dispatch(switchUserOpen())
                    return
                }
                history.push(routes.companyRoutes.company)
                return
            }
            if (userData && userData.parent_companies[0] && userData.parent_companies[0].subsidiaries.length == 1) {
                // if user have only one company
                if (userData.parent_companies[0]?.subsidiaries[0]?.subscriptions?.length == 0 || (userData.parent_companies[0]?.subsidiaries[0]?.subscriptions[0]?.stripe_status == StripePaymentConstant.PaymentIncomplete || userData.parent_companies[0]?.subsidiaries[0]?.subscriptions[0]?.stripe_status == StripePaymentConstant.PaymentPastDue || userData.parent_companies[0].subsidiaries[0].subscriptions[0].stripe_status == StripePaymentConstant.PaymentCanceled)) {
                    //  redirect to my company page if not completed    
                    history.push(routes.companyRoutes.company)
                    return
                }
                // open switch profile drawer if completed
                dispatch(switchUserOpen())
                return
            }

            // redirect on create company page if there is no company yet
            history.push(routes.companyRoutes.createCompany)
            return
        }


        // open switch profile drawer if user loggedin as company
        dispatch(switchUserOpen())
        return
    }

    return (
        <Box className='bidContainer'
            sx={((isSmallDevice || isMediumDevice) && fixedBottom) ? {} :
                {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 0',
                }}
        >
            <div style={{ display: 'flex', alignItems: 'baseline', gap: '5px' }}>
                <h3 className='heading' style={{ margin: '0px' }}>
                    {getMessageHeading(userData, subsidiaryId, t, loggedIn, subsidiaryData, adDetail, isProfileComplete)}
                </h3>
                {(isSmallDevice || isMediumDevice) ? <Button aria-describedby={id} onClick={() => handleClick('bottom')} sx={{ minWidth: '0px' }}><SvgInfoIcon /></Button> : null}
            </div>

            {(isLargeDevice || isExtraLargeDevice) && <Typography className='permission-text'>{getMessage(isActive, subsidiaryId, t, adDetail, loggedIn, userData, subsidiaryData, isProfileComplete)}</Typography>}
            <ButtonCustom
                btnColor={"primary"}
                classes={'auctionActionBtn'}
                clickFunction={() => handleClickAction()}
                textBtn={getTextOnButton(userData, subsidiaryId, t, loggedIn, subsidiaryData, isProfileComplete)}
                style={{ color: '#fff !important', backgroundColor: '#2da745 !important', width: '100%', margin: 0, height: '40px', mt: 2, fontSize: '18px', textTransform: 'none !important' }}
            />
            {(isSmallDevice || isMediumDevice) && <Popper id={id} open={open} anchorEl={anchorEl} transition sx={{
                border: '1px solid #fbfbfb',
                borderRadius: '5px',
                boxShadow: 2,
                maxWidth: 'fit-content',
                margin: '0px 20px !important',
                zIndex: '99',
                position: 'fixed',
                bottom: '125px !important',
                top: '40% !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'max-content',
                width: 'calc(100% - 40px )',
            }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={3}>
                        <Paper>
                            <Box sx={{ display: 'flex', gap: '5px', padding: '10px' }}>

                                <Typography>{getMessage(isActive, subsidiaryId, t, adDetail, loggedIn, userData, subsidiaryData, isProfileComplete)}</Typography>
                                <span className='pointer' onClick={() => setOpen(false)}><SvgClose /></span>
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>}

        </Box>
    )
}

export default AuctionSwitchProfile