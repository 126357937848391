import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import * as ChatConstants from "config/chatConfig"
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { archiveParticularChatRequest, blockParticularChatRequest, getChatRequest, getChatSuccess, particularChatSuccess } from 'store/actions/chat-action'
import { useDispatch, useSelector } from 'react-redux'
import { useWindowSize } from 'app/Hooks/windowResize'
import { Nullable, chatDataInterface } from '../../interface/interface'
import { getSubsidiaryStored } from 'config/variables'
import DeleteConfirm from 'app/components/stylesComponents/Ads/DeleteConfirm'


const ArchiveAndBlockedText = ({popup,chatType,chatId,setChatId}) => {

  const { chatList, archivedChatList, blockedChatList } = useSelector((state) => state.chat)
  
  const [confirmPopup,setConfirmPopup] = useState<boolean>(false)

  const { user } = useSelector((state) => state.auth)

  const [chatData, setChatdata] = useState<chatDataInterface>({})

  // Getting values for current active Chat
  useEffect(() => {
      if (chatList && chatList.length>0 && chatType === ChatConstants.chatType.ALL_MESSAGES) {
          setChatdata(chatList.find((o) => o.id === chatId))
      }
      else if (blockedChatList && blockedChatList.length>0 && chatType === ChatConstants.chatType.BLOCKED) {
          setChatdata(blockedChatList.find((o) => o.id === chatId))
      } else if (archivedChatList && archivedChatList.length>0 && chatType === ChatConstants.chatType.ARCHIVED) {
          setChatdata(archivedChatList.find((o) => o.id === chatId))
      }
  }, [chatList, archivedChatList, blockedChatList, chatId,chatType])

  const [mobile] = useWindowSize();

  const dispatch = useDispatch()

  const {t} = useTranslation()

  const handleArchive = () => {
    localStorage.setItem('chat_id',null)
    //We will send 0 as we need to unarchive this chat
    let data = {
        id: chatId,
        data: 0
    }
    dispatch(particularChatSuccess({}))
    dispatch(archiveParticularChatRequest(data))

    if(popup){
      // dispatch(getChatSuccess({ data: {}, append: false }))
      dispatch(getChatRequest({ page: 1 }))
    }
}

  const handleBlock = () => {
    localStorage.setItem('chat_id',null)
    let blockedUser: Nullable<number> = null;
    let blockedSubsidiary: Nullable<number> = null;

    blockedUser = chatData.chat_user_info.id;

    blockedSubsidiary = chatData.chat_user_info.subsidiary_id;

    const blockedEntity = blockedSubsidiary ?? blockedUser;

    let data = {
        id: blockedEntity,
        isblocked: chatType===ChatConstants.chatType.BLOCKED?true:false,
        type: blockedSubsidiary === null ? ChatConstants.BLOCK_TYPE.USER : ChatConstants.BLOCK_TYPE.SUBSIDIARY
    }
    dispatch(particularChatSuccess({}))
    dispatch(blockParticularChatRequest(data))
  }


  const getWarningMessage = () => {
        
    let msg = t(translations.CHAT.ARE_YOU_SURE_TO_BLOCK_THIS_USER)

    if(chatType === ChatConstants.chatType.ALL_MESSAGES && Object.keys(chatData).length > 0 && chatData.chat_user_info.subsidiary_id){
            msg = t(translations.CHAT.ARE_YOU_SURE_TO_BLOCK_THIS_SUBSIDIARY)
    }

    if(chatType === ChatConstants.chatType.BLOCKED){
        msg = t(translations.CHAT.ARE_YOU_SURE_TO_UNBLOCK_THIS_USER)
        if (Object.keys(chatData).length > 0 && chatData.chat_user_info.subsidiary_id){
            msg = t(translations.CHAT.ARE_YOU_SURE_TO_UNBLOCK_THIS_SUBSIDIARY)
        }
    }
    
    return msg
  }

  return (
    <>
    <Box className='accessMessage'>
        {chatType === ChatConstants.chatType.BLOCKED ? <p>
            {t(translations.CHAT.BLOCKED_VALIDATION_TEXT)}, <span className='pointer' onClick={()=>setConfirmPopup(true)}>{t(translations.CHAT.UNBLOCK)}</span>
        </p>:<p>
        {t(translations.CHAT.ARCHIVED_VALIDATION_TEXT)}, <span className='pointer' onClick={handleArchive}>{t(translations.CHAT.MOVE_IT_INBOX)}</span>
        </p>}
    </Box>
    <DeleteConfirm open={confirmPopup} setOpen={setConfirmPopup} deleteFunc={handleBlock} confirmBtn={t(translations.ads.CONFIRM)} message={getWarningMessage()} />
    </>
  )
}

export default ArchiveAndBlockedText