import { Box, Button, Modal } from '@mui/material';
import { translations } from 'locales/translations';
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { PromoWrapper } from '../style/CreditPaymentWrapper';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { getPromoCodeRequest } from 'store/actions/user-actions';
import { useLocation } from 'react-router-dom';
import { numberFormat } from 'config/variables';

const AvailablePromoList = ({ setCode, code, handleApplyCode, setCodeError, codeApplied }) => {

    // hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    //decoding stripe_product_id from url 
    const queryParamStripeId = atob(new URLSearchParams(location.search).get('sp_Id')) // stripe_product_id

    //local states
    const [openPopup, setOpenPopup] = useState<boolean>(false)

    //reduxstates
    const { allPromoCodes } = useSelector((state: RootStateOrAny) => state.user)

    useEffect(() => {
        // API call for getting promo code list
        if (queryParamStripeId)
            dispatch(getPromoCodeRequest({ stripe_product_id: queryParamStripeId }));
    }, [queryParamStripeId])

    return (
        <>
            {allPromoCodes?.items?.length > 0 && <Box className='payment-header-description check-promos pointer' onClick={() => setOpenPopup(true)} >
                {t(translations.HOT_DEALS.AVAILABLE_PROMOS)}
            </Box>}

            <Modal
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                sx={{
                    zIndex: 9,
                }}
            >

                <PromoWrapper className='scrollBar'>
                    {allPromoCodes?.items?.length ?
                        <Box className="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t(translations.promoEmailingScreen.PROMO_CODE)}</th>
                                        <th>{t(translations.createCompanyScreen.DISCOUNT)}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPromoCodes?.items?.map((item, index) => (
                                        <tr key={item.id + 'credit'}>
                                            <td>{item.code}</td>
                                            <td>{item.coupon.amount_off ? `flat ${numberFormat(item.coupon.amount_off / 100)}  €` : `${item.coupon.percent_off} %`}</td>
                                            <td
                                                className={`apply-btn ${codeApplied && code == item.code ? "text-blue" : ""}`}
                                                onClick={() => {
                                                    if (item.code == code)
                                                        return
                                                    setCodeError('')
                                                    handleApplyCode(item.code);
                                                    setCode(item.code)
                                                    setOpenPopup(false)
                                                }}><Button
                                                    variant='outlined'
                                                    disabled={item.code == code && codeApplied}
                                                    sx={{
                                                        textTransform: 'capitalize',
                                                        minWidth: '85px',
                                                        cursor: item.code == code && codeApplied ? null : 'pointer'
                                                    }} >
                                                    {item.code == code && codeApplied ? t(translations.webTexts.APPLIED) : t(translations.createCompanyScreen.APPLY)}
                                                </Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Box> : <Box>
                            {t(translations.CTB_CREDIT.NO_PROMO_CODE)}
                        </Box>}
                </PromoWrapper>

            </Modal>
        </>
    )
}

export default memo(AvailablePromoList);
