import styled from '@emotion/styled'
import React from 'react'
import { mediaMax, sizes } from 'styles/media'

export const PopWrapper = (props: any) => {
  return (
    <>
    <Wrapper
      className={`modal custom-modal modal fade show ${props.classes ? props.classes : ''}`}
      id="actionModal"
      tabIndex={-1}
      aria-labelledby="filterModalLabel"
      aria-hidden="true"
      style={{display: 'block'}}
  >
    <div className="modal-dialog popup modal-dialog-bottom">
      <div className="modal-content">
        {props.title || props.popAction ? 
          <div className="modal-header">
          {props.title && 
            <h5 className="modal-title department_title" id="filterModallLabel">
              {props.title}
            </h5>
          }
          {/* <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.popAction()}
          ></button> */}
        </div>
        : null
        }
        
        <div className="modal-body text-center">
          {props.children}
        </div>
      </div>
    </div>
  </Wrapper> 
  <div className="modal-backdrop fade show"></div>
  </>
  )
}

const Wrapper = styled.div`
  &.w-100{
    @media(max-width:${sizes.sx}px){
      >.popup{
        width: 100% !important;
        left: 0;
        max-width: 100%;
      }
    }
  }
  .department_title{
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.02em;
    margin: 0;
    padding-bottom: 20px;
  }
`