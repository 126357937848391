import { createAction } from 'redux-actions';

export const getShowCaseRequest = createAction('GET_SHOWCASE_REQUEST');
export const getShowCaseSuccess = createAction('GET_SHOWCASE_SUCCESS');
export const getShowCaseFailure = createAction('GET_SHOWCASE_FAILURE');

export const updateShowCaseRequest = createAction('UPDATE_SHOWCASE_REQUEST');
export const updateShowCaseSuccess = createAction('UPDATE_SHOWCASE_SUCCESS');
export const updateShowCaseFailure = createAction('UPDATE_SHOWCASE_FAILURE');

export const updateShowCaseItemServicesRequest = createAction('UPDATE_SHOWCASE_SERVICES_REQUEST');
export const updateShowCaseItemServicesSuccess = createAction('UPDATE_SHOWCASE_SERVICES_SUCCESS');
export const updateShowCaseItemServicesFailure = createAction('UPDATE_SHOWCASE_SERVICES_FAILURE');

export const updateShowCaseItemRequest = createAction('UPDATE_SHOWCASE_ITEM_REQUEST');
export const updateShowCaseItemSuccess = createAction('UPDATE_SHOWCASE_ITEM_SUCCESS');
export const updateShowCaseItemFailure = createAction('UPDATE_SHOWCASE_ITEM_FAILURE');

export const getShowCaseItemRequest = createAction('GET_SHOWCASE_ITEM_REQUEST');
export const getShowCaseItemSuccess = createAction('GET_SHOWCASE_ITEM_SUCCESS');
export const getShowCaseItemFailure = createAction('GET_SHOWCASE_ITEM_FAILURE');

export const getShowCaseItemServiceRequest = createAction('GET_SHOWCASE_ITEM_SERVICES_REQUEST');
export const getShowCaseItemServiceSuccess = createAction('GET_SHOWCASE_ITEM_SERVICES_SUCCESS');
export const getShowCaseItemServiceFailure = createAction('GET_SHOWCASE_ITEM_SERVICES_FAILURE');

export const getActiveDealerRequest = createAction('GET_ACTIVE_DEALER_REQUEST');
export const getActiveDealerSuccess = createAction('GET_ACTIVE_DEALER_SUCCESS');
export const getActiveDealerFailure = createAction('GET_ACTIVE_DEALER_FAILURE');

export const getShowcaseContactRequest = createAction('GET_SHOWCASE_CONTACT_REQUEST');
export const getShowcaseContactSuccess = createAction('GET_SHOWCASE_CONTACT_SUCCESS');
export const getShowcaseContactFailure = createAction('GET_SHOWCASE_CONTACT_FAILURE');

export const getServicesRequest = createAction('GET_SERVICES_REQUEST');
export const getServicesSuccess = createAction('GET_SERVICES_SUCCESS');
export const getServicesFailure = createAction('GET_SERVICES_FAILURE');

export const resetShowCaseStates = createAction('RESET_SUCCESS_STATES');