import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";


export const MyWallerWrapper = styled.div`
  padding: 20px ;
  max-width: 1440px;
  margin: 0 auto;

  .credit-headers{
  font-size: 21px;
  font-weight: 500;
  line-height: 24.89px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 6px;
  margin-top: 0px;
  }
 .ctb-description{
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-align: left;
  color:#666666;
 }

  .cartobike-credit{
   margin-bottom: 36px;
  }
 .learn-more{
  margin-right: 50px;
  max-width: 212px;
 }
  .cartobike-balance{
  flex-wrap: wrap;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 7px;
  margin-bottom: 24px;
 }
 .table{
    border-collapse: separate;
    border-spacing: 0 8px;
    min-width: 400px;
}
 .tableCellStyle{
  border:transparent;
  font-size: 18px;
  font-weight: 400;
 }
 .reason{
   font-weight: 350;
   color: ${colorNew.primaryColor}; 
   max-width: 200px;
   a{
     cursor : pointer;
     color: ${colorNew.primaryColor}; 
     text-decoration: underline;
   }
   }
   
   .reason-style{
    text-decoration:none;
    text-transform: capitalize;
    cursor: default;
 } 
 .tableRowShaded{
  background-color: #f5f5f5;
  border-left: 3px solid ${colorNew.primaryColor};
 }

 .tableCellShaded{
  background-color: #f5f5f5;
 }

 .tableRow{
  border-left: 3px solid ${colorNew.colorBlack};
 }

.tableBody{
  column-gap: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  margin-bottom: 16px;
}
.button-credits{
  background-color: ${colorNew.colorBlack};
  min-width: 212px;
  text-transform: capitalize;
  font-size: 15px;
}
.learn-more-btn{
  min-width: 212px;
  text-transform: capitalize;
  font-size: 15px;
}

.available-credits{  
  color: #28A745;
  svg{
    path{
      fill:#28A745;
    }
  }
}
.credit-bal{
  display: flex;
  flex-direction: column;
}

.pageHeader{
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
      padding: 10px 0;
  }

  .dateStyle{
    width: 200px;
  }
  .refundedicon{
    width: 18px;
    height: 18px;
    margin-left: 5px;
    margin-bottom: 3px;
  }

  @media (max-width:1440px) {
    max-width: 1170px;
  }

  @media (max-width:500px) {
    .dateStyle{
    width: 100px;
  }
  .reason{
    width: 150px;
 }
  }
@media (max-width:992px) {
  padding: 0 8px;
  .pageHeader {
    margin-top: -10px;
    h2{
      font-family: Inter;
      font-size: 21px;
      font-weight: 500;
      line-height: 25.41px;
      letter-spacing: 0.02em;
      margin-top: -10px;
      margin: 0;
    }
  }
  .cartobike-credit{
    flex-wrap: wrap;
  }
  .button-credits{
    min-width: 100%;
  }
  .learn-more-btn{
    width: 100%;
    margin-top: 12px;
  }
  .learn-more{
    margin-right: 0px;
    max-width: 100%;
    width: 100%;
  }
  .available-credits{  
    flex-direction: row;
    svg{
    display: none;
    }
  }
  .credit-bal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
  }
  .tableCellStyle{
    min-width: 135px;
  }
  .pagination{
    max-width: 100%;
    ul{
      max-width: 100%;
      .page{
        width: 35px;
        height: 35px;
        font-size: 15px;
      }
      .active{
        border: 1px solid #6579f4;
      }
    }
  }
}
 
 `;