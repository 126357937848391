import React from 'react'
import { Box, Modal } from '@mui/material'
import {SvgClose } from 'app/components/svgicons/svg';
import { colorNew } from 'styles/StyleConstants';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

function Information(props:any) {
    const {t} = useTranslation();
    const {openModalProgress,handleCloseProgress} = props;
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 400,
        width:'calc(100% - 20px)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 5,
    };
  return (
    <>
    <Modal
        open={openModalProgress}
        onClose={handleCloseProgress}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
            '.popUp-proGress':{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 400,
                width:'calc(100% - 20px)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 5,
                '.popUp-proGress-header':{
                    borderBottom:`1px solid ${colorNew.colorGrayDark}`,
                    padding:2,
                    fontSize:'20px',
                    fontWeight:600,
                    display:'flex',
                    justifyContent:'space-between',
                    
                },
                '.footer-progress':{
                    padding: '0 15px',
                    fontSize:14,
                    fontWeight:300,
                },
                '.text-row':{
                    display:'flex',
                    fontSize:14,
                    fontWeight:300,
                    alignItems:'center',
                    padding: '0 15px',
                    '.text-row-color':{
                        width:'50%',
                        border:`1px solid ${colorNew.colorGrayDark}`,
                        borderRadius:'6px',
                        'div':{
                            width:'100%',
                            padding:'5px',
                            '&.bg-danger':{
                                width:'33%',
                                background: colorNew.colorRed,
                                borderRadius:'6px'
                            },
                            '&.bg-warning':{
                                width:'66%',
                                background: '#FFBD12',
                                borderRadius:'6px'
                            },
                            '&.bg-success':{
                                width:'100%',
                                background: colorNew.colorGreenDark,
                                borderRadius:'6px'
                            }
                        }
                    },
                    '.text-container':{
                        padding: 1,
                        width:'50%'
                    }

                }
            }
        }}
>
    <Box className="popUp-proGress">
        <div className='popUp-proGress-header'>
            <span className='fs-4 fw-bold'>{t(translations.webTexts.PROGRESS_BAR)}</span>
            <span onClick={handleCloseProgress} className='pointer'> <SvgClose /></span>
        </div>
        <div className='text-row'>
            <span className="text-row-color">
                <div className="bg-danger" />
            </span>
            <span className='text-container'>{t(translations.webTexts.PROGRESS_BAR1)}</span>
        </div>
        <div className='text-row'>
            <span className="text-row-color">
                <div className="bg-warning" />
            </span>
            <span className='text-container'>{t(translations.webTexts.PROGRESS_BAR2)}</span>
        </div>
        <div className='text-row'>
            <span className="text-row-color">
                <div className="bg-success"/>
            </span>
            <span className='text-container'>{t(translations.webTexts.PROGRESS_BAR3)}
            </span>
        </div>
        <div className='footer-progress'>
            <p className='text-gray-dark fw-bold'>
                {t(translations.webTexts.PROGRESS_BAR4)}
            </p>
        </div>
    </Box>
   </Modal></>
  )
}

export default Information