import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const LicPlateWrapper = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;

 .lplate-form{
    width: 27%;
    display: flex;
    flex-direction  : column ;    
    padding: 10px;
    position:relative;
  }

  .lplate-form::after {
    content: '';
    background: ${colorNew.colorGrayButton};
    width: 1px;
    height: calc(100% - 120px);
    position: absolute;
    right: 0;
    top: 60px;
}
  .lplate-list{
    width: 73%;  
    display: flex;
    padding: 0 0 0 18px;
  }
  .licence-plate-heading{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .licence-plate-heading{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    .lplate-list::-webkit-scrollbar{
width:3px;
  }
      .lplate-list::-webkit-scrollbar-thumb{
background-color: ${colorNew.colorGray2};
  }
  .plateList {
    display: flex;
    justify-content: space-between;
  }
  .suggested-vehicles{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 700px;
    width:calc(100% - 20px);
    overflow: hidden;
    background-color: ${colorNew.colorWhite};
    box-shadow: 24;
    border-radius: 6px;
    padding:20px;
  } 
  .listContainer{
    max-height: 50vh;
  } 

  .vehicle-description{
    display: none;
  }
  .error{
    color: ${colorNew.colorRed};
  }
  .flex-div {   
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 18px 0px 0px;    
  }
  .platList-div{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 15px;
        font-weight: 400
  }
  .flag-img{   
        display : flex; 
        font-size: 15px;
        color: ${colorNew.colorGrayDark4};
        text-transform: uppercase;
  }
  .flag-img img {
        width: 32px;
        height: 24px;
        margin-right: 10px;
        border-radius: 4px 0px 0px 4px
  }
  .findable{
    display: flex;
    column-gap: 10px;
    align-items: center;
    font-size: 18px;
  }
  .closePopup{
    cursor:pointer;
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .closePopup svg{
    width:18px;
    height:18px;
  }
  .closePopup svg path{
    stroke:black;
  }
  .verticalThreeDots path{
    fill : black
  }
  
 .vehicle-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px   
  }
   @media(max-width : 992px) {
    .lplate-form{
    width: 100%;
    display: block;
   }
   .lplate-list{
    width: 100%;
    display: block;   
  }
  }
  @media (max-width : 1200px)  {
    .lplate-list > div{
      width:100% !important;
    }
  }
  @media(max-width :1023px) {
    .vehicle-description{
    display: block;
    margin-top: 10px;
  }
  .plateLists{
    display: none;
  }
  }
`;