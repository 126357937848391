import { Autocomplete, Box, Drawer, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FieldWrapper } from './style'
import { SvgMobileLeftArrow, SvgchevronIcon } from '../svgicons/svgNew'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { useDeviceType } from 'app/Hooks/useMediaQuery'

// Code for list of dropdown elements
const ListElement = ({ list, isSelected, handleChange, title }) => {

    const [isSmallDevice] = useDeviceType()

    return (
        <Box className='optionsMainContainer'>
            <Box className='flexColumn optionListContainer scrollBar' sx={{
                maxHeight: !isSmallDevice ? '300px' : 'unset'
            }}>
                {
                    (list && Object.keys(list).length > 0) &&
                    Object.keys(list).map((option) => {
                        return <Box key={option}>
                            <span className='continentName'>{option}</span>
                            {
                                list[option] && list[option].map((countryName) => {
                                    return <Box key={title + countryName.id} className='optionContainer' sx={{ backgroundColor: isSelected(countryName.slug) ? 'aliceblue' : '' }} onClick={() => { handleChange(countryName) }}>
                                        <Box className='nameContainer'>
                                            <span className='optionValue'>{countryName.fullname}</span>
                                        </Box>
                                        <span className={(isSelected(countryName.slug) ? 'selected' : '') + ' selectDiv'}></span>
                                    </Box>

                                })
                            }
                        </Box>
                    })

                }
            </Box>
        </Box>
    )
}

const SearchDropdownForCountry = ({ title, value, list, handleChange }) => {

    const { t } = useTranslation()

    const [openDropdown, _openDropdown] = useState<boolean>(false)
    const [showValue, _showValue] = useState<string>('')
    const [isSmallDevice] = useDeviceType()

    // Generating text to show as selected value in field
    useEffect(() => {
        if (isSmallDevice) {
            _openDropdown(false)
            if (value && Object.keys(value).length > 0) {
                _showValue(value.fullname)
            }
        }
    }, [value])

    const isSelected = (slug) => {
        if (value && Object.keys(value).length > 0) {
            if (value.slug == slug) {
                return true
            }
            return false
        }
    }

    const options = () => {
        const countryList = []
        if (list)
            Object.keys(list).map((continent) => {
                list[continent].map((country) => {
                    countryList.push({
                        continent: continent,
                        ...country,
                    })
                })
            });
        return countryList
    }

    const optionList = React.useMemo(
        () => {
            return options()
        },
        [list]
    )

    const selectedValue = React.useMemo(
        () => {
            if (value && Object.keys(value).length > 0) {
                return optionList.find(option => option.id == value.id) ?? null
            }
            return null
        },
        [optionList, value]
    );


    return (
        <FieldWrapper className='countryFilter'>

            {isSmallDevice &&
                <Box className='field flexRow alignItemsCenter'>
                    <span className='fieldHeader'>{title}</span>
                    <Box className='inputBox flexRow justifyContentBetween alignItemsCenter pointer' sx={{ p: '0 8px 0 16px', width: '100%', 'svg': { 'path': { stroke: '#666' } } }} onClick={() => _openDropdown((previousState) => !previousState)}>
                        <span className='selectedValue'>
                            {(value == null || value == undefined || Object.keys(value).length == 0) ? t(translations.webTexts.NOT_SELECTED) : showValue}
                        </span>
                        <SvgchevronIcon />
                    </Box>
                    <Drawer
                        anchor='bottom'
                        open={openDropdown}
                        onClose={() => _openDropdown(false)}
                        sx={{
                            '.MuiPaper-root': {
                                height: "70vh",
                                borderRadius: '6% 6% 0 0'
                            },
                        }}>
                        <FieldWrapper>
                            <Box className='drawerTitleStyle'>
                                <SvgMobileLeftArrow onClick={() => _openDropdown(false)} />
                                {title}
                                <SvgMobileLeftArrow style={{ opacity: 0 }} />
                            </Box>
                            <ListElement list={list} handleChange={handleChange} isSelected={isSelected} title={title} />
                        </FieldWrapper>
                    </Drawer>
                </Box>
            }

            {!isSmallDevice && <Autocomplete
                id="grouped-demo"
                open={openDropdown}
                onOpen={() => _openDropdown(true)}
                onClose={() => _openDropdown(false)}
                popupIcon={<SvgchevronIcon />}
                options={optionList}
                disableClearable={true}
                groupBy={(option) => option.continent}
                getOptionLabel={(option) => option.fullname}
                renderInput={(params) => <TextField {...params} label={title} placeholder={!openDropdown ? t(translations.webTexts.NOT_SELECTED) : ''} InputLabelProps={{ shrink: true }} className='autocompleteInputField' />}
                renderGroup={(params) => (
                    <li key={params.key} className='filterDropDownOption'>
                        <span className='continentName'>{params.group}</span>
                        <span className='optionValue'>{params.children}</span>
                    </li>
                )}
                onChange={(event: any, newValue: string | null) => {
                    handleChange(newValue)
                }}
                disablePortal
                value={selectedValue}
            />}
        </FieldWrapper>
    )
}

export default SearchDropdownForCountry