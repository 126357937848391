import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';
import { lazyLoad } from 'utils/loadable';

export const InvoicePage = lazyLoad(
  () => import('./index'),
  module => module.InvoicePage,
  {
    fallback: (
      <ContentLoader />
    ),
  },
);