import styled from "styled-components";
import { color } from "styles/StyleConstants";

export const ConfirmationPopWrapper = styled.div`
  .disable-confirmation-pop{
    p{
        font-size: 12px;
        color: #F63E3E;
    }
    .disable-confirmation-button{
        padding-top:20px;
        text-align: center;
        button{
            ;
            line-height: initial;
            font-size: 12px;
            padding: 4px 10px;
            border-radius: 7px;
            margin: 5px 10px;
            &.button-yes{
                border: 1px solid #F63E3E;
                color: #F63E3E;
            }
            &.button-no{
                border: 1px solid ${color.primaryColor};
                color: ${color.colorWhite};
                background-color: ${color.primaryColor};
            }
        }
    }
  }
`