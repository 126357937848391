import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const HomePageWrapper = styled.div`
.scrollBar::-webkit-scrollbar{
  width: 0px;
  height: 0px;
}
.brandsList{
  overflow: scroll;
  padding-bottom:5px ;
}
span{
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #000;
}
.homeMainContainer{
  max-width: 1440px;
  width: 100%;
  margin: auto;
}
.homepageContentSection{
  display : flex;
  flex-direction : column;
  gap: 32px;
}
.sectionBorderDiv{
  border: 4px solid #6579F4;
  border-radius: 0px 4px 4px 0px;
}
.sectionHeader{
  margin: 0;
  color: #000;
  font-size: 23px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.02em;
}
.sectionHeader::before {
  content: '|';
  display: inline-block;
  height: 30px;
  width: 4px;
  background-color: red;
  color: transparent;
  margin-right: 7px;
}
.sectionDescription{
  margin-left:16px;
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.sectionSubHeader{
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.36px;
}
.sectionLink{
  margin : 0 0 10px 0;
  cursor : pointer;
  color: var(--Shade-1, #384386);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
}
.adListContainer{
  overflow-x: visible;
  overflow-y: auto !important;
  gap:15px;
}
.adContainer{
  max-width: 24%;
  width: 24%;
  min-width : 300px;
  border: 1px solid #F2F2F2;
  display: flex;
  flex-direction:column; 
  flex:1;
}
.adContainer .image-container img{ 
  border: none;
  border-bottom: 1px solid #E5E5E5;
}
.ad-name{
  margin : 0px;
  color: #000;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.currentPriceContainer{
  border: 2px dashed #E5E5E5;
  padding :5px;
}
.currentPriceText{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.currentPrice{
  margin-top: auto;
  color: #363B39;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.42px;
}
.hiddenPrice{
  font-size: 12px;
  margin-bottom: 3px;
  margin-left: 5px;
}
.detailPageLink{
  cursor : pointer;
  color: var(--Shade-1, #384386);
  font-size: 16px;
  font-weight: 500;
}
.auctionNameLink{
  color: #000;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.allAdsLink{
  text-decoration: underline;
  margin-top:3px;
  cursor : pointer;
  color: var(--Shade-1, #384386);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.countryFlag{
  width:40px;
  height:40px;
  border-radius:50%;
  border: 2px solid var(--text-medium-light-gray, #E5E5E5);
}
.auctionContainer{
  position : relative;
  border-radius: 6px;
  border: 1px solid ${colorNew.colorGrayButton};
}
.counterValueContainer{
  width: 56px;
  height: 56px;
  border-radius: 2px;
  border: 1px solid ${colorNew.colorGrayButton};
  background: #FFF;
  border-radius: 50%;
}
.count{
  color: var(--text-deep-gray, #666);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
  position: absolute;
  top: 18px;
}
.countText{
  color: var(--text-deep-gray, #666);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
}
.CurrentHeader, .StartSoonHeader{
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.03em;
  padding: 3px 12px;
  position: absolute;
  top: -10px;
}
.CurrentHeader{
  background: #28A745;
  color : white;
}
.StartSoonHeader{
  background: #FBC623;
  color : black;
}
.auctionName{
  font-size: 17px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.02em;
  color : black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.auctionStatusText{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.02em;
}
.endingStatus{
  color: red;
}
.auctionFlag{
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid var(--text-medium-light-gray, #E5E5E5);
}
.auctionFooterText{
  margin-left:5px;
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.dot{
  border-radius:50%;
  margin : 0 10px;
  display: block;
  width: 4px;
  height: 4px;
  background: #D9D9D9;
}
.partnerImage {
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  object-fit: contain;
  max-height: 250px;
  border: 1px solid #F2F2F2;
  border-radius: 6px;
}
.partnerName{
  color: #000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.partnerDesc{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.02em;
}
.btnText{
  color : white;
}
.react-multi-carousel-track {
  gap:10px;
}
.react-multiple-carousel__arrow--left{
  top:35%;
  left:10px;
  z-index: 9;
}
.react-multiple-carousel__arrow--right{
  top:35%;
  right:10px;
  z-index: 9;
}

// -------------------------------------New Design Banner And filter -------------------------------------
.bannerSkeleton{
  aspect-ratio: 3/1;
  max-height: 600px;
}
.bannerContainer {
  width : 100%;
  position : relative;
  aspect-ratio: 3/1;
}

.bannerContainer .bannerLink{
  display: block;
}
.bannertext{
  position : absolute;
  z-index: 1;
  top: 10%;
  left: 10%;
}
.bannertext .textHeader{
  color: #FFF;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0.96px;
}
.bannertext .textContent{
  color: #FFF;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.48px;
  max-width: 550px;
}
.bannerContainer img{
  width: 100%;
  max-height: 600px;
  height: auto;
  // object-fit: cover;
}
.filterSection{
  z-index:1;
  border : 1px solid #ECEFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  width : 100%;
  margin-top: -85px;
  position :relative;
}
.tabSection{
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  max-width : 90%;
  min-width : 55%;
  margin-top: -50px;
  padding : 16px;
  gap :10px;
  min-height : 105px;
  overflow : auto;

}
.productLoader{
  width: 100%;
  align-items: center;
}
.productLoader span{
 color : ${colorNew.primaryColor}
}
.productTab{
  min-width: max-content;
  border-radius: 4px;
  padding : 14px 0;
  border: 2px solid #efefef;
  width : 215px;
  text-align :center;
}
.productTab span{
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  margin-left : 10px;
}
.selectedProduct{
  border: 2px solid #191919;
}
.fieldContainer{
  padding : 26px 0;
  width : calc(100% - 60px);
  display : flex;
  justify-content : space-between;
}
.fieldContainerElem{
  width: calc(20% - 15px);
}
.searchButton{
  border-radius: 4px;
  background: #28A745;
  color: #FFF;
  text-transform : none;
  font-family: Inter;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.42px;
  display : block;
}
.searchButton svg{
  width: 28px;
  height: 28px;
}
.searchButton svg path{
  fill: white;
}
.disabled{
  cursor : not-allowed;
  opacity : 60%;
}
.disabled div{
  cursor : not-allowed;
}

.dropDownIcon{
  margin-right :10px;
}
.dropDownIcon{
  margin-right :10px;
  width : 20px;
  height : 20px;
}
.dropDownIcon path{
  stroke : #000
}

.MuiPaper-rounded {
  padding : 0;
}


// ---------------------------------------Homepage new design ---------------------------------------
.classicAdsSection{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding : 16px;
  border: 1px solid #CFD6FF;
  border-radius : 6px;
}
.dealWrapperElement{
  padding: 0 !important;
}
.content{
  font-size: 18px;
  font-weight: 350;
  line-height: 29.16px;
  letter-spacing: 0.02em;
  color: black;
  margin: 0;
  width:75%;
  .boldText{
   font-weight: bold;
    color: black;
  }
  .readMore{
    text-wrap:nowrap;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}
.linkText{
    text-decoration: none;
    color: #6579F4;
    cursor: pointer;
}
.hotDealTabs {
  margin: 0;
  border-bottom: 1px solid #EDEDED;
  list-style-type: none;
  padding: 0;
  gap: 20px;
  overflow: auto;
  width: 100%;
}
.hotDealTab{
  font-family: Rubik;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.78px;
  letter-spacing: 0.02em;
  color: #666666;
  padding-bottom: 5px;
  cursor: pointer;
  min-width: max-content;
}
.selectedTab{
  border-bottom: 2px solid #EC1C24;
  color : #EC1C24
}
.nodealSection{
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 60px 0;
  .nodealHeader{
    font-size: 23px;
    font-weight: 500;
    line-height: 27.26px;
  }
  .nodealContent{
    font-family: Rubik;
    font-size: 19px;
    font-weight: 340;
    line-height: 22.52px;
    text-align: justified;
  }
  .youtubeLink{
    display: flex;
    align-items: center;
    gap:5px;
    background-color: red;
    border-radius: 0;
    color: white;
    margin-top: 10px;
    border-radius: 4px;
    text-transform: none;
    width: 350px;
  }
}
.startBusinessContainer{
  padding: 42px 16px;
  border: 1px solid #CFD6FF;
  border-radius: 6px;
  display : flex;
  flex-direction :row;
}
.startBusinessImageContainer{
  width :40%;
  display : flex;
  align-items : center;
  justify-content : center;
}
.startBusinessImageContainer svg{
  width : 234px;
  height : 240px;
}
.startBusinessTextContainer{
  width :60%;
  display : flex;
  flex-direction : column;
  gap : 20px;
}
.professionalText{
  background: #E8FFEE;
  color: #28A745;
  padding : 7px 12px;
  border-radius: 3px;
  margin-left: 10px;
  font-size: 16px;
}
.startBusinessText{
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  max-width : 480px;
  display : block;
  margin: 0px;
  font-family: Inter;
}
.professionalButton{
  background : #28A745;
  color : #fff;
  text-transform : none;
  width : 350px;
  font-size : 18px;
  font-weight : 500;
  line-height : 25.2px;
  max-width : 80%;
  height : 56px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.professionalButton span{ 
  color: white;
}
.auctionSectionHeaderContainer{
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}
.headerDashedBorder{
  position: absolute;
  border-bottom: 2px dashed #CCCCCC;
  width: 100%;
  margin-top: 25px;
}
.auctionSectionHeader{
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  font-family: 'Inter';
  width: 70%;
  margin: 0 auto;
}
h2.auctionSectionHeader::before, .auctionSectionHeader::after {
  content: " ";
  flex: 1 0 0%;
  height: 2px;
  background: linear-gradient(to right, transparent 50%, rgb(204, 204, 204) 50%);
  background-size: 15px 2px, 100% 2px;
  margin: 10px;
}
.linkSection{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkSection a{
  background-color: #000;
  height: 48px;
  max-width: 100%;
  width : 457px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkSection a span{
  color : white;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}
.joinNowSection{
  padding : 16px 0 0 16px;
  border: 1px solid #CFD6FF;
  border-radius : 6px;
  display: flex;
  justify-content: space-between;
}
.joinNowTextSection{
  padding : 16px 16px 32px 0;
  width : calc(100% - 400px);
  gap : 20px;
  display: flex;
  flex-direction: column;
}
.textHeader-1{
  font-size: 23px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #384386;
  margin: 0;
}
.textHeader-2{
  font-size: 23px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.02em;
  margin: 0px
}
.eventText{
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #666666;
  padding: 10px 0;
  margin: 0;
}
.joinNowImageSection{
  display: flex;
  align-items: end;
}
.ctbDescSection{
  padding : 16px;
  border-radius : 6px;
  display: flex;
  justify-content: space-between;
}
.ctbDescTextSection{
  width: calc( 100% - 482px);
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 16px 0 0 ;
}
.ctbDescTextSection .textHeader-2{
    font-size: 32px;
    line-height: 40px;
  }
  .ctbDescTextSection .eventText{
    line-height: 31.5px;
  }
.ctbDescImageSection{
  width: 482px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.ctbDescImageSection img{
  max-width: 100%;
  width: auto;
  height: fit-content;
}
.professionalSectionHeader .sectionHeader{
  font-size: 27px;
  font-weight: 700;
}
.professionalHeader{
  width: max-content;
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%);
  padding-top: 5px;
  background: #FFEAB6;
  margin: 0 0 5px 0;
}
.benefitsHeader{
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #666666;
  border-bottom: 3px solid #F2F2F2;
  padding-bottom: 4px;
}
.benefitsSection{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding-top: 32px;
}
.benefits{
  width : 23%;
  padding: 16px;
}
.benefitsIcon{
  position: absolute;
  top: -35px;
}
.stockBorder{
  border: 1px solid #E7FFEC;
}
.showcaseBorder{
  border: 1px solid #ECEEFF;
}
.privateAccessBorder{
  border: 1px solid #FFF0FD;
}
.adsBorder{
  border: 1px solid #ECEEFF;
}
.benefitsSubHeader{
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
}
.modalHeader{
  text-align: center;
  display: block;
}
.benefitsSubHeader > h3 {
  display: inline-flex;
  font-size: 18px;
}
.benefitsContent{
  font-weight: 400;
  letter-spacing: 0em;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-family: 'Rubik';
}

.drawer-container{
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}

.bold-text{
  color:#000 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}
span.color-red{
  color:#EC1C24 !important;
}

.benefitsSubHeader{
  margin: 10px 0px;
}
.modalContent{
  line-height: 25px;
  text-align: justify;
  max-height: 50vh;
  overflow-x: auto;
  padding-right: 5px;
}
.buttons{
  display: flex;
  justify-content: space-between;
}
.buttons .contactUsButton{
  width: 24%;
  border: 1px solid #F2F2F2;
  background-color: #ECEFFF;
  color: #666666;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.buttons .contactUsButton span{
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
}
.buttons .professionalButton{
  background-color: #6579F4;
  width: 74%;
}
/* / Set the initial width of the scrollbar / */
.brandsList::-webkit-scrollbar {
  height: 5px !important;
  background-color: transparent;
}
/* / Style the scrollbar track / */
.brandsList::-webkit-scrollbar-track {
  background-color: transparent;
}
/* / Style the scrollbar thumb (the draggable part) / */
.brandsList::-webkit-scrollbar-thumb {
  background-color: transparent;
}
/* / Handle the hover state to increase the width / */
.brandsList:hover::-webkit-scrollbar {
  background-color: #9b9999;
}
/* / Style the scrollbar track / */
.brandsList:hover::-webkit-scrollbar-track {
  background-color: #dddddd;

}
/* / Style the scrollbar thumb (the draggable part) / */
.brandsList:hover::-webkit-scrollbar-thumb {
  background-color: #9b9999;
}

.brand{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  min-width: 220px;
  border : 1px solid #F2F2F2;
  padding: 20px 0;
}
.brandName{
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.02em;
  margin-top: 10px;
}
.benefitInformationContainer{

}
.benefitInformationContainer .infoIcon{
  margin-bottom: 2px;
  margin-left: 5px;
  cursor: pointer;
}
.infoModal{
    display: flex;
    flex-direction: column;
    border-radius: 6px;
  }
.infoModal .benefitsSubHeader {
  margin-bottom: 20px !important;
}

.closeIcon{
  position: absolute;
  right: -10px;
  top: -8px;
  cursor: pointer;
  width: 18px;
}

.close-Icon-style{
    left: -20px;
    top: 30px;
}

// --------------------------------------Media Query ---------------------------------------
@media (max-width :1440px){
  .homeMainContainer{
  max-width: 1180px;
  }
}

@media (max-width: 1365px) {
  .bannerContainer {
    width: calc(100% - 60px);
    margin: 0 auto;
  }
  .filterSection {
    margin-top: -20px;
  }
}
@media (min-width: 1366px) {
  .bannerContainer {
    width: calc(100% - 150px);
    margin: 0 auto;
  }
}
@media (max-width: 1280px) {
  .bannerContainer {
    width: 100%;
  }
}

@media (max-width: 1200px){
  .homeMainContainer{
    padding: 0 16px ;
  }
}

@media (max-width: 992px) {
  .content{
    width: 100%;
  }
  .nodealSection{
    width: 100%;
    padding: 40px 0;
  }
}


@media (min-width :769px) and (max-width :992px){
  .ctbDescTextSection .textHeader-2{
    font-size: 30px;
    line-height: 35px;
  }
  .contant-wrapper{
    margin: 0 16px;
  }
}
@media (max-width :768px){
  h2.auctionSectionHeader{
    width: 100%;
  }
  .content{
    font-family: Rubik;
    font-size: 13px;
    font-weight: 350;
    line-height: 20.8px;
    letter-spacing: 0.02em;
  }
  .youtubeLink{
    width: 100%;
  }
  
  .bannerContainer, .homeMainContainer {
    padding: 0 10px;
  }
  .currentPrice{
    font-size: 18px;
  }
  .bannerContainer img {
    border-radius: 6px;
  }
  .carousel-cell {
    height: 107px;
  }
  .carousel-cell.flickity-cell.is-selected {
    top: 0;
    height: 140px;
  }
  .flickity-viewport {
    height: 140px !important
  }
  .sectionHeader{
    font-size: 21px;
    font-weight: 500;
  }
  .ad-name{
    font-size: 17px;
  }
  .sectionSubHeader{
    margin-right:10px;
  }
  .sectionLink {
    font-size:15px;
    display:flex;
    align-items:center;
    min-width:max-content;
  }
  .bannerContainer img{
    aspect-ratio :3/1
  }
  .bannerSkeleton{
    aspect-ratio: 3/1;
    /* max-height: 600; */
  }
  .sectionLink svg {
    width:24px;
  }
  .tabSection, .filterSection{
    margin-top: 0px;
  }
  .tabSection{
    border:none;
    box-shadow : none;
    max-width : 100%;
    min-width : 100%;
    padding: 12px 0px 8px 0px;
    min-height : 80px;
    justify-content : space-between;
  }
  .bannerContainer{
    width: 100%;
    margin: 15px 0px;
    border-radius: 6px;
    overflow: hidden;
  }
  .filterSection{
    width: 100%;
    padding: 0px 10px;
    border: 1px solid #ECEFFF;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 7px 15px -5px rgba(0,0,0,0.3)
  }
  .productTab{
    min-width: max-content;
    display : flex;
    align-items :center;
    padding: 0px 14px;
    max-height: 60px;
  }
  .productTab img{
    width: 30px;
    height: 30px;
  }
  .fieldContainer{
    flex-wrap:wrap;
  }
  .fieldContainerElem{
    width : calc(50% - 7px);
    margin-bottom : 22px
  }
  .fieldContainer .searchButton{
    width :100%;
    height: 56px;
    margin-bottom : 5px
  }
  .bannertext{
    left : 16px;
  }
  .bannertext .textHeader{
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.96px;
    line-height :30px;
  }
  .bannertext .textContent{
    font-size: 15px;
    font-weight: 400;
    line-height :20px;
    max-width: 250px;
  }

  .fieldContainer{
    border-top: 1px solid #F2F2F2;
    padding: 12px 0px;
    width: 100%;
  }
  .fullWidth{
    width : 100% !important;
  }
  .homepageContentSection{
    width: 100%;
    margin : 0 auto;
  }
  .startBusinessContainer{
    background: #F6F6F6;
    border: none;
  }
  .startBusinessImageContainer{
    display : none;
  }
  .startBusinessTextContainer{
    width : 100%;
  }
  .startBusinessTextContainer svg{
    height :33px;
    width: auto;
  }
  .professionalText{
    background: #E8FFEE;
    color: #28A745;
    padding : 3px 8px;
    border-radius: 3px;
    margin-left: 10px;
    font-size : 16px;
  }
  .startBusinessText{
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    max-width : 460px;
    display : block;
  }
  .startBusinessText{
    max-width : 100%;
  }
  .professionalButton{
    width : 100%;
    max-width : 100%;
  }
  .auctionSectionHeaderContainer{
    max-width: 100%;
  }

  .auctionSectionHeader{
    font-size: 27px;
    text-align: center;
    line-height: 47px;
    letter-spacing: 0.02em;
    padding: 3px 0px;
    background: #fff;
    z-index: 1;
  }
  .joinNowTextSection{
    width: 100%;
  }
  .joinNowImageSection{
    width: 0%;
    display: none;
  }
  .ctbDescSection{
    padding : 16px;
    border-radius : 6px;
    display: flex;
    justify-content: space-between;
    background: #F6F6F6;
  }
  .ctbDescTextSection{
    width: 100%;
  }
  .ctbDescTextSection .textHeader-2{
    font-size: 28px;
    line-height: 30.5px;
  }
  .ctbDescTextSection .eventText{
    line-height: 26.5px;
  }
  .ctbDescImageSection{
    width: 0%;
    display: none;
  }
  .benefits{
    width: 100%;
    margin-bottom: 35px;
  }
  .buttons{
    flex-direction: column;
    gap: 24px;
  }
  .buttons .contactUsButton, .buttons .professionalButton{
    width: 100%;
  }
  .brand{
    width: 160px;
    min-width: 160px;
  }
  .infoModal{
    max-width: 80%;
  }
  .react-multi-carousel-item{
    width: 250px !important;

  }
  .partnerImage {
    width: 250px;
    max-height: 250px;
  }
  .partnerName{
  font-size: 15px;
  }
  .partnerDesc{
    font-size: 12px;
  }
}
@media (min-width :769px) and (max-width :992px){
  .carousel-cell {
    height: 160px;
  }
  .carousel-cell.flickity-cell.is-selected {
    top: 0;
    height: 240px;
  }
  .flickity-viewport {
    height: 240px !important
  }
  .bannertext .textHeader{
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.96px;
    line-height :40px;
  }
  .bannertext .textContent{
    font-size: 18px;
    font-weight: 400;
    line-height :30px;
    max-width: 450px;
  }
  .productTab{
    padding : 8px;
  }
  .selectedProduct{
    padding : 8px;
  }
  .startBusinessContainer{
    display : flex;
    flex-direction :column;
  }
  .startBusinessImageContainer{
    width : 100%;
  }
  .startBusinessTextContainer{
    width : 100%;
    margin : 10px auto;
    max-width: 460px;
    align-items: center;
    text-align: center;
  }
  .benefits{
    width: 48%;
  }
}
@media (max-width :400px){
  .startBusinessTextContainer svg {
    height: 33px;
    width: 62%;
  }
  .startBusinessText{
    font-size: 22px;
  }
  .professionalText{
    padding : 3px 8px;
    font-size : 12px;
  }
  .drawer-container{
  max-width:100%
}
}
`