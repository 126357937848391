import { Box } from '@mui/material'
import { SvgClose } from 'app/components/svgicons/svg'
import styled from 'styled-components'
import { useWindowSize } from 'app/Hooks/windowResize'
import { useEffect, useState } from 'react'
import ButtonCustom from './ButtonCustom'
import { colorNew } from 'styles/StyleConstants';
import toasterLogo from '../../assets/Images/toasterLogo.png';
import { translations } from 'locales/translations'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux'
import { setAppBarOpen } from 'store/actions/public-actions'

const Mobilereference = () => {
    const [mobile, width] = useWindowSize()
    const { t } = useTranslation()
    const { isAppBarOpen } = useSelector((state: any) => state.publicReducer);
    const dispatch = useDispatch()

    const location = useLocation();
    const appLink = navigator.userAgent.includes('Android') ? process.env.REACT_APP_ANDROID_LINK : process.env.REACT_APP_IOS_LINK


    return (
        <>
            {mobile && isAppBarOpen && !location.pathname.includes(routes.userRoutes.chat) ?
                <Wrapper id='openApp'>
                    <span className="close" onClick={() => dispatch(setAppBarOpen(false))}>
                        <SvgClose />
                    </span>
                    <span className='text'>
                        <div className='toaster-logo'>
                            <img src={toasterLogo} alt="mobile-app" width={40} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {width > 500 ? t(translations.webTexts.OPEN_IN_APP)
                                : <>
                                    <Trans i18nKey="test">
                                        <p className="content-text">{t(translations.webTexts.FOR_BETTER_EXP)} <span className='open-link' onClick={() => { window.open(AppLink, "_blank") }}>{t(translations.webTexts.OPEN_IN_APP_TEXT)}</span></p>
                                    </Trans>
                                </>}
                        </div>
                    </span>
                    <ButtonCustom
                        textBtn={t(translations.webTexts.OPEN_APP)}
                        btnColor={"white-btn"}
                        clickFunction={() => {
                            window.open(appLink, "_blank")
                        }}
                        style={{
                            whiteSpace: 'nowrap',
                            px: 1,
                            minWidth: 'auto'
                        }}
                    />
                </Wrapper> : null}
        </>
    )
}

export default Mobilereference;

const Wrapper = styled(Box)`
    display:flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colorNew.primaryColor};
    color: ${colorNew.colorWhite};
    padding: 10px;
    position: relative;
    z-index: 99;
    .close{
        position: absolute;
        left: 12px;
        top: 14px;
        svg{
            path{
                stroke: ${colorNew.colorWhite};
            }
        }
    }
    .text{
        padding: 0 30px;
        display:flex;
    }
    .toaster-logo{
        display:flex;
        justify-content:center;
        align-items:center;
        img{
            margin-right:5px;
        }
    }
   
    @media (max-width:500px){
        .close{
            top:5px;
            left: 7px;
        }
        .content-text{
            margin: 0;
            font-size: 14px;
        }
        .text{
            padding: 0 15px;
            display:flex;
        }
        button{
            /* display:none; */
        }
    }
`