import { Box, Grid } from '@mui/material'
import { productTypes } from 'config/variables'
import { lan } from 'locales/multiPageTranslation/adsLan'
import { useTranslation } from 'react-i18next'
import { DetailListing } from '../../../AdsStyledSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { getVehicleWheelsRequest } from 'store/actions/vehicle-actions'
import EngineDetails from './engineAndEnvironmentComponents/EngineDetails'
import ElectricDetails from './engineAndEnvironmentComponents/ElectricDetails'
import EnvironmentDetail from './engineAndEnvironmentComponents/EnvironmentDetail'
import { useDeviceType } from 'app/Hooks/useMediaQuery'



const EngineEnviroment = (props: any) => {
    const { item } = props
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { vehicleWheelsList } = useSelector((state: any) => state.vehicle)
    useEffect(() => {
        if (vehicleWheelsList.length < 1) {
            dispatch(getVehicleWheelsRequest({ product_type_id: productTypes.car }))
        }
    }, [])

    const drivingName = () => {
        if (vehicleWheelsList.length < 1) {
            return null
        }
        const obj = vehicleWheelsList.find(x => x.id === item.owner_vehicle.driving_wheel_id)
        return obj?.name
    }

    const nameDriveVehilce = useMemo(() => drivingName(), [vehicleWheelsList])
    const [isSmallDevice, isMediumDevice] = useDeviceType();

    return (
        <Box sx={{
            color: "#666",
            fontSize: "18px"
        }}>
            {item?.owner_vehicle?.vehicle?.product_category_type?.product_type_id === productTypes.car ?
                <Grid container spacing={2}>

                    {/* Engine Details */}
                    <Grid item xs={12} sm={isMediumDevice || isSmallDevice ? 12 : 6} lg={4}>
                        <EngineDetails item={item} nameDriveVehilce={nameDriveVehilce} />
                    </Grid>

                    {/* Electric Details */}
                    <Grid item xs={12} sm={6} lg={4}>
                        <ElectricDetails item={item} />
                    </Grid>

                    {/* Environment Details */}
                    <Grid item xs={12} sm={6} lg={4}>
                        <EnvironmentDetail item={item} />
                    </Grid>
                </Grid> :
                <Grid container spacing={2}>
                    {(item?.owner_vehicle?.front_tire?.width || item?.owner_vehicle?.front_tire?.height || item?.owner_vehicle?.front_tire?.diameter) && <Grid item xl={4} sm={6} xs={12}>
                        <Box
                            sx={{
                                'span': {
                                    fontWeight: 400
                                }
                            }}
                        >
                            <DetailListing className='vehicle-detail-label'>
                                <label>{t(...lan.frontTires())} {item?.owner_vehicle?.body_vehicle?.product_type_id === productTypes.step ? '(INCH")' : '(ETRO)'}</label>
                                <span>
                                    {
                                        item?.owner_vehicle?.body_vehicle?.product_type_id !== productTypes.step ? item?.owner_vehicle?.front_tire?.width ? item?.owner_vehicle?.front_tire?.width + ' / ' : null : null
                                    }
                                    {
                                        item?.owner_vehicle?.body_vehicle?.product_type_id !== productTypes.step ? item?.owner_vehicle?.front_tire?.height ? item?.owner_vehicle?.front_tire?.height + ' / ' : null : null
                                    }
                                    {
                                        item?.owner_vehicle?.front_tire?.diameter ? item?.owner_vehicle?.front_tire?.diameter + '"' : null
                                    }
                                </span>
                            </DetailListing>
                        </Box>
                    </Grid>}
                    {(item?.owner_vehicle?.back_tire?.width || item?.owner_vehicle?.back_tire?.height || item?.owner_vehicle?.back_tire?.diameter) && <Grid item xl={4} sm={6} xs={12}>
                        <Box
                            sx={{
                                'span': {
                                    fontWeight: 500
                                }
                            }}
                        >

                            <DetailListing className='vehicle-detail-label'>
                                <label>{t(...lan.backTires())}  {item?.owner_vehicle?.body_vehicle?.product_type_id === 7 ? '(INCH")' : '(ETRO)'}</label>
                                <span>
                                    {
                                        item?.owner_vehicle?.body_vehicle?.product_type_id !== 7 ? item?.owner_vehicle?.back_tire?.width ? item?.owner_vehicle?.back_tire?.width + ' / ' : null : null
                                    }
                                    {
                                        item?.owner_vehicle?.body_vehicle?.product_type_id !== 7 ? item?.owner_vehicle?.back_tire?.height ? item?.owner_vehicle?.back_tire?.height + ' / ' : null : null
                                    }
                                    {
                                        item?.owner_vehicle?.back_tire?.diameter ? item?.owner_vehicle?.back_tire?.diameter + '"' : null
                                    }
                                </span>
                            </DetailListing>
                        </Box>
                    </Grid>}
                    <Grid item xl={4} sm={6} xs={12}>
                        <Box
                            sx={{
                                'span': {
                                    fontWeight: 500
                                }
                            }}
                        >
                            <DetailListing className='vehicle-detail-label' >
                                <label>{t(...lan.runflat())}</label>
                                <span>{item?.owner_vehicle?.tire_runflat === 1 ? t(...lan.yes()) : t(...lan.no())}</span>
                            </DetailListing>
                        </Box>
                    </Grid>
                </Grid>
            }
        </Box>
    )
}

export default EngineEnviroment;