import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Popper } from '@mui/material'
import { SvgAddNewIcon, SvgEpMenu, SvgNotificationIconHeader, SvgUserIcon } from 'app/components/svgicons/svgNew'
import { CountriesDrop1, getSubsidiaryStored } from 'config/variables';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { langugageSwitchOpen, notificationRequest } from 'store/actions/component-actions';
import { colorNew } from 'styles/StyleConstants';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import english from 'styles/img/english.png';
import france from 'styles/img/france.png';
import deLanguage from 'app/AssetNew/Images/de.png';
import esLanguage from 'app/AssetNew/Images/es.png';
import nlLanguage from 'app/AssetNew/Images/nl.png';
import { theme } from 'config';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import ChatIcon from 'app/pages/Chat/Component/ChildComponents/ChatIcon';
import { nameTemplate } from 'config/chatConfig';
import { useWindowSize } from 'app/Hooks/windowResize';
import { setCreateAdModalOpen } from 'store/actions/public-actions';
import useOutsideAlerter from 'app/Hooks/outSideDiv';
import ProfilePopup from '../Layouts/PublicLayout/NavigationHeader/NavigationBarComponent/ProfilePopup';
import Features from 'app/pages/LoginPage/Features';
import { HeaderRightStyle } from '../styled/HeaderWrapper';

const HeaderRight = (props: any) => {
    const { hideUser, languageSwitch } = props;
    const { userData, profileUpdatedData, updatedImage, profileUpdated } = useSelector((state: RootStateOrAny) => state.user)
    const { loggedIn, loginError } = useSelector((state: RootStateOrAny) => state.auth);
    const { subsidiaryName, notificationCount } = useSelector((state: RootStateOrAny) => state.company)
    const { subsidiaryDetails, logoUrl } = useSelector((state: RootStateOrAny) => state.company);

    // Hooks
    const dispatch = useDispatch()
    const subsidiary = getSubsidiaryStored();
    const history = useHistory();
    const { t, i18n } = useTranslation()
    const [mobile, width] = useWindowSize()
    const location = useLocation()
    const ref = useRef()

    //Local states
    const [countryFlag, _countryFlag] = useState<string>('');
    const [userName, _userName] = useState<string>("")
    const [countNotifications, _countNotifications] = useState<number>();
    const [dispalyChatIcon, setDispalyChatIcon] = useState<boolean>(true);
    const [openProfile, setOpenProfile] = useState<HTMLButtonElement | null>(null);
    const [openLogin, setOpenLogin] = useState<HTMLButtonElement | null>(null);

    // Reference id for  login popup
    const openLoginPopup = Boolean(openLogin);
    const loginRefId = openLoginPopup ? 'simple-popover' : undefined;

    // refrenece Id for profile popper
    const openPopup = Boolean(openProfile);
    const id = openPopup ? 'simple-popover' : undefined;

    // hook that closes dropdown when clicked outside the target
    useOutsideAlerter(ref, () => {
        setOpenProfile(null)
        setOpenLogin(null)
    })

    useEffect(() => {
        if (history.location.pathname.includes(routes.userRoutes.chat)) {
            setDispalyChatIcon(false)
        }
    }, [])

    useEffect(() => {
        if (notificationCount && notificationCount !== null) {
            if (Object.keys(notificationCount).length > 0) {
                if ('data' in notificationCount) {
                    _countNotifications(notificationCount.data.new_notification_count);
                }
            }
        }
    }, [notificationCount])


    useEffect(() => {
        if (loggedIn) {
            if (subsidiary && subsidiary !== null && subsidiaryDetails?.address_principal?.country_id) {
                getCountry(subsidiaryDetails.address_principal.country_id)
            } else {
                if (!!userData.addresses && userData.addresses.length) {
                    getCountry(userData.addresses[0].country_id)
                }
            }
        }
    }, [subsidiaryDetails, userData])

    const handleNotification = () => {
        dispatch(notificationRequest())
    }
    const getCountry = (id: number) => {
        CountriesDrop1().then(data => {
            if (data && data.length > 0) {
                data.map((country, i) => {
                    if (country.id == id) {
                        _countryFlag(country?.flag_thumbnail)
                    }
                });
            }
        })
    }

    useEffect(() => {
        const name = nameTemplate(subsidiaryDetails, subsidiary, subsidiaryName, profileUpdatedData, userData)
        _userName(name)
    }, [subsidiaryDetails, getSubsidiaryStored(), userData, logoUrl, subsidiaryName, updatedImage, profileUpdated, profileUpdatedData])

    const FlatImage = () => {
        let imageSrc = ""
        if (i18n?.language === 'en' || i18n?.language === 'en-GB') {
            imageSrc = english
        } else if (i18n?.language === 'fr') {
            imageSrc = france
        } else if (i18n?.language === 'de') {
            imageSrc = deLanguage
        } else if (i18n?.language === 'es') {
            imageSrc = esLanguage
        } else if (i18n?.language === 'nl') {
            imageSrc = nlLanguage
        } else {
            imageSrc = english
        }
        return <img src={imageSrc} alt="flagIcon" />
    }

    const handlePopper = (event: React.MouseEvent<HTMLElement>) => {
        setOpenProfile(openProfile ? null : event.currentTarget);
    };

    const handleLoginPopper = (event: React.MouseEvent<HTMLElement>) => {
        setOpenLogin(openLogin ? null : event.currentTarget);
    };

    useEffect(() => {
        if (loggedIn && Object.keys(loginError).length < 1) {
            setOpenLogin(null)
        }
    }, [loggedIn])


    return (
        <HeaderRightStyle>

            <Box ref={ref}
                sx={{
                    display: 'flex',
                    pl: 1,
                    alignItems: {
                        md: 'unset',
                        xs: 'center',
                    },
                    ...props.style
                }}
            >
                {width > 768 &&
                    <Box className='container'>
                        {!location.pathname.includes(routes.vehicleRoutes.addVehicle) && loggedIn ?
                            <Box>
                                <Box className='addProduct' sx={{ gap: '10px' }} onClick={() => dispatch(setCreateAdModalOpen(true))}>
                                    <SvgAddNewIcon /> <span>{`${t(translations.webTexts.START_SELL)}`}</span>
                                </Box>
                            </Box> :
                            <Box onClick={handleLoginPopper}>
                                <Box className='addProduct' sx={{ gap: '10px' }}>
                                    <SvgAddNewIcon /> <span>{`${t(translations.webTexts.START_SELL)}`}</span>
                                </Box>
                            </Box>
                        }
                        {/* code for Notification */}
                        {loggedIn ?
                            <Button onClick={handleNotification} sx={{ mr: 1 }} disableRipple>
                                <SvgNotificationIconHeader />
                                {countNotifications > 0 && <span className='icon-dot-header messages'></span>}
                            </Button>
                            :
                            <Button onClick={handleLoginPopper} sx={{ mr: 1 }} disableRipple>
                                <SvgNotificationIconHeader />
                            </Button>
                        }
                        {/*code for chat Icon*/}

                        {loggedIn && dispalyChatIcon ?
                            <Button sx={{ padding: 0 }} disableRipple>
                                <ChatIcon />
                            </Button>
                            :
                            <Button sx={{ padding: 0 }} onClick={handleLoginPopper} disableRipple>
                                <ChatIcon ads={'logout'} />
                            </Button>}
                    </Box>}

                {!hideUser ?
                    width > 1024 ?

                        // Appears in desktop View
                        <Box
                            className='button-wrapper'

                        >
                            {loggedIn ? <ProfilePopup handlePopper={handlePopper} open={openPopup} id={id} userName={userName} setOpenProfile={setOpenProfile} openProfile={openProfile} countryFlag={countryFlag} />
                                :
                                <Box className='flexRow alignItemsCenter pointer' sx={{ ml: 2 }}>
                                    <span className='loginBtn' style={{ gap: '10px' }} onClick={handleLoginPopper}>
                                        <SvgUserIcon />{t(translations.formText.SIGNIN)}
                                    </span>
                                </Box>
                            }
                        </Box> :
                        // Appears in tab View which will open navigation menu 
                        <Button sx={{
                            width: '48px',
                            height: '48px',
                            minWidth: 'auto',
                            border: '1px solid rgb(222, 222, 222)',
                            backgroundColor: 'transparent',
                            marginLeft: '25px',
                            [theme.breakpoints.down('md')]: {
                                width: 35,
                                height: 35,
                            }
                        }}>
                            <SvgEpMenu />
                        </Button>
                    : null
                }
                {languageSwitch ?
                    <Box
                        onClick={() => dispatch(langugageSwitchOpen())}
                        className='pointer'
                        sx={{
                            borderLeft: `1px solid ${colorNew.colorGray2}`,
                            alignSelf: 'center',
                            pl: '20px',
                            'span': {
                                display: 'flex',
                                alignItems: 'center',
                                'img': {
                                    mr: 1,
                                    width: 36,
                                    height: 36,
                                    borderRadius: '100%'
                                },
                            },
                        }}
                    >
                        <span>
                            <FlatImage />
                            <ExpandMoreIcon
                                sx={{ color: `${colorNew.colorGrayDark}` }}
                            />
                        </span>
                    </Box>
                    : null
                }
                {/* Login popup in header */}
                <Popper
                    open={openLoginPopup}
                    id={loginRefId}
                    anchorEl={openLogin}
                    disablePortal={true}
                    placement="bottom-end"
                    PopperProps={{ style: { zIndex: 999 } }}
                >     {/* Customized arrow CSS */}
                    <Box sx={{ position: 'relative', zIndex: 1, top: '15px' }}>
                        <Box className='popup-pointer' />
                    </Box>
                    <Box sx={{ position: 'relative', top: '15px', borderRadius: '6px', boxShadow: 24 }}>
                        <Features isHeader={true} setOpenLogin={setOpenLogin} />
                    </Box>
                </Popper>
            </Box>
        </HeaderRightStyle>
    )
}

export default HeaderRight;