import { MobileHeaderWrapper } from 'app/components/stylesComponents/MobileHeaderWrapper'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { color } from 'styles/StyleConstants'
import AllNotification from 'app/pages/NotificationPage/Components/AllNotification'
import InvitationNotification from '../Components/InvitationNotification'
import CartoBikeNotification from '../Components/CartoBikeNotification'
import {
    getNotificationsRequest,
    deleteNotificationRequest,
    respondNotificationRequest,
    openNotificationsRequest,
    getUserSubsidiaryRequest,
    getSubsidiaryDetailsRequest,
    getNotificationCountRequest
} from 'store/actions/company-actions';
import { connect, useDispatch, useSelector } from 'react-redux'
import { SpinnerWrapper } from '../Components/style/SpinnerWrapper'
import { Box, CircularProgress } from '@mui/material'
import { Toaster } from 'services/Toaster'
import { onMessageListener } from 'firebasePushNotifications';
import { useWindowSize } from 'app/Hooks/windowResize'
import { Link, useHistory } from 'react-router-dom'
import { routes } from 'routes/routes';
import { notificationReset } from 'store/actions/component-actions'
import { commandText, getSubsidiaryStored, notifications, productTypes, vehicles } from 'config/variables'
import TopBar from 'app/Component2/Layouts/AdsLayouts/component/TopBar'
import { SvgMobileLeftArrow } from 'app/components/svgicons/svgNew'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'

const Feature = (props: any) => {
    const { t } = useTranslation();
    const [tabScreen, _tabScreen] = useState<number>(1)
    const [page, _page] = useState<number>(1)
    const [pageCtb, _pageCtb] = useState<number>(1)
    const [pageInvit, _pageInvit] = useState<number>(1)
    const [nextPage, _nextPage] = useState<any>();
    const [scrollFlag, _scrollFlag] = useState<boolean>(true);
    const [filter, _filter] = useState<string>("");
    const [adsAuditionId, _adsAuditionId] = useState<any>()
    const [bottomScreen, _setBottomScreen] = useState<boolean>(false);
    const [getNotificationSub, _getNotificationSub] = useState<any>()
    const [notificationTab, _notificationTab] = useState<Array<any>>([
        { id: 1, text: t(translations.ads.ALL), active: true },
        { id: 2, text: 'Cartobike', active: false },
        { id: 3, text: t(translations.webTexts.INVITATIONS), active: false },
    ]);


    const [notificationList, _notificationList] = useState<Array<any>>([]);
    const [cartobikeNotificationsList, _cartobikeNotificationsList] = useState<Array<any>>([]);
    const [invitationsNotificationsList, _invitationsNotificationsList] = useState<Array<any>>([]);
    const [addsPagination, _addsPagination] = useState<boolean>(false);
    const { userData } = useSelector((state: any) => state.user);
    const { subsidiaryData, subsidiaryDetails, notificationSent, notificationOpened } = useSelector((state: any) => state.company);
    const { loggedIn } = useSelector((state: any) => state.auth);
    const [mobile, width] = useWindowSize()
    const scrollRef: any = useRef(null)
    const isMounted = useRef(false);
    const history = useHistory();
    const dispatch = useDispatch()

    //props
    const {
        getNotificationsRequest,
        deleteNotificationRequest,
        respondNotificationRequest,
        openNotificationsRequest,
        notificationData,
        notificationDataCartobike,
        notificationDataInvitation,
        notificationListing,
        notificationListingCartobike,
        notificationListingInvitation,
        notificationDeleted,
        deleteNotificationError,
        notificationResponse,
        notificationResponseError,
        loading,
        loadingNotification
    } = props;
    // Hooks and effects
    useEffect(() => {
        getNotifications();
        openNotifications();
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            if (loggedIn) {
                if (notificationOpened) {
                    dispatch(getNotificationCountRequest());
                }
            }
        }
    }, [notificationOpened])

    useEffect(() => {
        if (notificationSent) {
            dispatch(getUserSubsidiaryRequest())
        }
    }, [notificationSent])

    useEffect(() => {
        if (Object.keys(notificationData).length > 0 && tabScreen === notifications.allNotification) {
            _nextPage(notificationData.next_page_url)
        }
        if (Object.keys(notificationDataCartobike).length > 0 && tabScreen === notifications.carTopoBikeNotification) {
            _nextPage(notificationDataCartobike.next_page_url)
        }
        if (Object.keys(notificationDataInvitation).length > 0 && tabScreen === notifications.invitationNotification) {
            _nextPage(notificationDataInvitation.next_page_url)
        }
    }, [notificationData, notificationDataCartobike, notificationDataInvitation]);

    useEffect(() => {
        _notificationList(notificationListing);
        _cartobikeNotificationsList(notificationListingCartobike)
        _invitationsNotificationsList(notificationListingInvitation)
    }, [notificationListing, notificationListingCartobike, notificationListingInvitation]);

    useEffect(() => {
        if (isMounted.current) {
            if (addsPagination) {
                _notificationList(notificationListing);
                _cartobikeNotificationsList(notificationListingCartobike)
                _invitationsNotificationsList(notificationListingInvitation)
                _addsPagination(false)
            } else {
                if (scrollRef.current) {
                    // scrollRef.current.scrollTo(0, 0);
                }
                _notificationList(notificationListing);
                _cartobikeNotificationsList(notificationListingCartobike)
                _invitationsNotificationsList(notificationListingInvitation)
                _addsPagination(false)
            }
        }
    }, [notificationListing, notificationListingCartobike, notificationListingInvitation])


    useEffect(() => {
        if (Object.keys(notificationDeleted).length > 0) {
            getNotificationsRequest({ userId: userData.id, page: 1, type: filter });
        }
    }, [notificationDeleted]);

    useEffect(() => {
        //code to be added
    }, [deleteNotificationError]);

    useEffect(() => {
        if (Object.keys(notificationResponse).length > 0) {
            getNotificationsRequest({ userId: userData.id, page: 1, type: filter });
        }
    }, [notificationResponse]);

    useEffect(() => {
        if (page > 1 || pageCtb > 1 || pageInvit > 1) {
            getNotifications()
        }
    }, [page, pageCtb, pageInvit]);

    useEffect(() => {
        //code to be added
        if (isMounted.current) {
            if ('message' in notificationResponseError) {
                Toaster.error(notificationResponseError.message)
                getNotifications()
            }
        }
    }, [notificationResponseError]);

    useEffect(() => {
        if (isMounted.current) {
            if (Object.keys(subsidiaryDetails).length > 0) {
                // history.push({
                //     pathname: routes.vehicleRoutes.auctionAds+'/'+adsAuditionId,
                //     state:{id:adsAuditionId}
                // })
            }
        }
    }, [subsidiaryDetails])

    useEffect(() => {
        if (isMounted.current) {
            if (subsidiaryData && subsidiaryData.length > 0 && adsAuditionId) {
                let data = subsidiaryData.filter((x) => x.id === getNotificationSub);
                if (data.length > 0) {
                    dispatch(getSubsidiaryDetailsRequest({ subsidiaryId: getNotificationSub, saveSubsidiary: true }))
                }
            }
        } else {
            isMounted.current = true;
        }
    }, [subsidiaryData])

    const switchTab = (id) => {
        scrollRef.current.scrollTo(0, 0)
        _tabScreen(id)
        const switched = notificationTab.map((tab) => {
            if (id === tab.id) {
                tab.active = true;
                if (id == notifications.allNotification) {
                    // || Object.keys(notificationListing).length < 1 && bottomScreen
                    _page(1);
                    _filter("")
                    if (Object.keys(notificationListing).length < 1) {
                        getNotificationsRequest({ userId: userData.id, type: "", page: 1 });
                    }
                }
                if (id == notifications.carTopoBikeNotification) {
                    _pageCtb(1);
                    _filter("cartobike")
                    if (Object.keys(notificationListingCartobike).length < 1) {
                        getNotificationsRequest({ userId: userData.id, type: "cartobike", page: 1 });
                    }
                }
                if (id == notifications.invitationNotification) {
                    _pageInvit(1);
                    _filter("invitation")
                    if (Object.keys(notificationListingInvitation).length < 1) {
                        getNotificationsRequest({ userId: userData.id, type: "invitation", page: 1 });
                    }
                }
                return tab;
            }
            else {
                tab.active = false
                return tab;
            }
        })
        _notificationTab(switched)
    }

    const getNotifications = () => {
        if (Object.keys(userData).length > 0) {
            if (tabScreen === notifications.allNotification) {
                getNotificationsRequest({ userId: userData.id, page: page, type: filter });
            }
            if (tabScreen === notifications.carTopoBikeNotification) {
                getNotificationsRequest({ userId: userData.id, page: pageCtb, type: filter });
            }
            if (tabScreen === notifications.invitationNotification) {
                getNotificationsRequest({ userId: userData.id, page: pageInvit, type: filter });
            }
        }
    }

    const openNotifications = () => {
        if (Object.keys(userData).length > 0) {
            openNotificationsRequest({ userId: userData.id });
        }
    }

    const deleteNotification = (notificationId) => {
        deleteNotificationRequest({ notificationId })
    }

    const respondNotification = (notificationId, status, type) => {
        if (!loading) {
            respondNotificationRequest({ status, notificationId, type })
        }
    }
    const checkNotification = async (notificationData) => {
        let subsidary = getSubsidiaryStored()
        _adsAuditionId(notificationData?.datas?.id)
        _getNotificationSub(notificationData.datas.subsidiary_id)
        if (notificationData.notification_type.title === commandText.bidLost || notificationData.notification_type.title === commandText.auctionOver) {
            if (notificationData.ad_vehicle === null) {
                if (subsidary == notificationData.datas.subsidiary_id) {
                    dispatch(notificationReset())
                } else {
                    dispatch(getUserSubsidiaryRequest())
                }
            } else {
                if ('datas' in notificationData && !!notificationData.ad_vehicle) {
                    if (subsidary == notificationData.datas.subsidiary_id) {
                        history.push({
                            pathname: routes.vehicleRoutes.auctionAds + '/' + notificationData.datas.slug,
                            state: { id: notificationData.datas.id, auctionStatus: commandText.auctionOver }
                        })
                        dispatch(notificationReset())
                    } else {
                        dispatch(getUserSubsidiaryRequest())
                    }
                }
            }
        } else if (notificationData.notification_type.title === commandText.vehicleReserved) {
            if ('datas' in notificationData) {
                if (subsidary == notificationData.datas.subsidiary_id) {
                    dispatch(notificationReset())
                } else {
                    dispatch(getUserSubsidiaryRequest())
                }
            }
        } else if (notificationData.notification_type.title === commandText.vehicleUnReserved) {
            if ('datas' in notificationData) {
                let vehicleType;
                if (!!notificationData.owner_vehicle && notificationData.owner_vehicle.vehicle.product_category_type_id) {
                    vehicleType = vehicles.car;
                    if (notificationData.owner_vehicle.vehicle.product_category_type.product_type_id === productTypes.bike) {
                        vehicleType = vehicles.bike;
                    } else if (notificationData.owner_vehicle.vehicle.product_category_type.product_type_id === productTypes.step) {
                        vehicleType = vehicles.step;
                    }
                }
                if (subsidary == notificationData.datas.subsidiary_id) {
                    history.push({
                        pathname: routes.vehicleRoutes.myAds + `/${vehicleType}/park`,
                        state: { id: notificationData.datas.id, auctionStatus: commandText.vehicleUnReserved }
                    })
                    dispatch(notificationReset())
                } else {
                    dispatch(getUserSubsidiaryRequest())
                }
            }
        } else if (notificationData.notification_type.title === commandText.SellerTakeDecision) {
            if ('datas' in notificationData) {
                let vehicleType;
                if (!!notificationData.ad_vehicle && !!notificationData.ad_vehicle.owner_vehicle && notificationData.ad_vehicle.owner_vehicle.vehicle.product_category_type_id) {
                    vehicleType = vehicles.car;
                    if (notificationData.ad_vehicle.owner_vehicle.vehicle.product_category_type.product_type_id === productTypes.bike) {
                        vehicleType = vehicles.bike;
                    } else if (notificationData.ad_vehicle.owner_vehicle.vehicle.product_category_type.product_type_id === productTypes.step) {
                        vehicleType = vehicles.step;
                    }
                }
                if (subsidary == notificationData.datas.subsidiary_id) {
                    history.push({
                        pathname: routes.vehicleRoutes.myAds + `/${vehicleType}/auction/action`,
                        state: { id: notificationData.datas.id, auctionStatus: commandText.SellerTakeDecision }
                    })
                    dispatch(notificationReset())
                } else {
                    dispatch(getUserSubsidiaryRequest())
                }
            }
        } else if (notificationData.notification_type.title === commandText.verificationStatus) {
            if ('datas' in notificationData) {
                if (subsidary == notificationData.datas.subsidiary_id) {
                    history.push({
                        pathname: routes.userRoutes.idVerfication,
                        state: { id: notificationData.datas.id }
                    })
                    dispatch(notificationReset())
                } else {
                    dispatch(getUserSubsidiaryRequest())
                }
            }
        } else if (notificationData.notification_type.title === commandText.hotdealPurchased) {
            history.push({
                pathname: routes.publicRoutes.hotDeal + '/' + notificationData.datas.slug

            })
            dispatch(notificationReset())
        } {

        }
    }


    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            _setBottomScreen(bottom)
        }
        if (bottom) {
            if (scrollFlag) {
                if (nextPage != null) {
                    _addsPagination(true)
                    if (tabScreen === notifications.allNotification) {
                        let increamenValue = page + 1;
                        _page(increamenValue)
                    }
                    if (tabScreen === notifications.carTopoBikeNotification) {
                        let increamenValue = pageCtb + 1;
                        _pageCtb(increamenValue)
                    }
                    if (tabScreen === notifications.invitationNotification) {
                        let increamenValue = pageInvit + 1;
                        _pageInvit(increamenValue)
                    }
                    _scrollFlag(false)
                }
            }
        } else {
            _scrollFlag(true)
        }
    }
    return (
        <>
            {/* {loading && <SpinnerWrapper>
                    <CircularProgress />
                </SpinnerWrapper>} */}
            <MobileWrapperUi>
                <MobileHeaderWrapper className='px-3 text-wrapper'>
                    {mobile && width < 767 ?
                        <TopBar
                            title={<div className='text-notification'>{t(translations.webTexts.NOTIFICATIONS)}</div>}
                            icons={<Box onClick={() => history.goBack()}><SvgMobileLeftArrow /></Box>}
                        />
                        :
                        <ul className="row profile-header text-center pt-3">
                            <li className="col-md-12 p-0 text-center text-uppercase">{t(translations.webTexts.NOTIFICATIONS)}</li>
                        </ul>
                    }
                </MobileHeaderWrapper>
                <TabberWrapper className="px-4">
                    <ul className="">
                        {
                            notificationTab.map((tab, index) => (
                                <li className={`text-center fw-bold ${tab.active && 'active'} pointer`} key={index}
                                    onClick={() => { switchTab(tab.id) }}
                                >
                                    {tab.text}
                                </li>
                            ))
                        }
                    </ul>
                </TabberWrapper>
                <SrollWrapper className="Allsection" onScroll={(e) => handleScroll(e)} ref={scrollRef}>
                    {
                        tabScreen === notifications.allNotification ?
                            <AllNotification
                                notificationList={notificationList}
                                deleteNotification={deleteNotification}
                                respondNotification={respondNotification}
                                // handleOpenNotifications={handleOpenNotifications}
                                handleOpenNotifications={checkNotification}
                                loadingNotification={loadingNotification}
                            /> :
                            tabScreen === notifications.carTopoBikeNotification ? <CartoBikeNotification
                                notificationList={cartobikeNotificationsList}
                                deleteNotification={deleteNotification}
                                respondNotification={respondNotification}
                                handleOpenNotifications={checkNotification}
                            /> :
                                tabScreen === notifications.invitationNotification ?
                                    <InvitationNotification
                                        notificationList={invitationsNotificationsList}
                                        deleteNotification={deleteNotification}
                                        respondNotification={respondNotification}
                                    /> :
                                    null
                    }
                </SrollWrapper>
            </MobileWrapperUi>
        </>
    )
}

const MobileWrapperUi = styled.div`
    max-width:767px;
    margin:0 auto;
    position:relative;
    .text-wrapper{
        padding: 16px 8px;
      }
`

const SrollWrapper = styled.div`
    max-height: calc(100vh - 130px);
    overflow: auto;
    overflow-x: hidden;
    @media(max-width:991px){
        max-height: calc(100vh - 170px);
    }
`

const TabberWrapper = styled.div`
    ul{
        padding: 0;
        margin: 0 10px 10px 10px;
        word-break:normal;
        li{
            padding: 5px 10px;
            display: inline-block;
            list-style: none;
            border:1px solid transparent;
            border-radius: 20px;
            width: 33.33333%;
            text-align: center;
            &.active{
                border:1px solid ${color.primaryColor};
                color: ${color.colorWhite};
                background: ${color.primaryColor};;
            }

        }
    }
    @media(max-width:400px){
        ul{
            display:flex;
            justify-content:space-evenly;
            li{
                width:auto;
            }
        }
    }
`
export default connect((state: any) => ({ ...state.company }), {
    getNotificationsRequest,
    deleteNotificationRequest,
    respondNotificationRequest,
    openNotificationsRequest
})(Feature);
