import { Box, Grid } from '@mui/material'
import SizeDetail from './sizeAndWeightComponent/SizeDetail'
import WeightDetail from './sizeAndWeightComponent/WeightDetail'
import TyresDetail from './sizeAndWeightComponent/TyresDetail'
import { DetailsWrapper } from './Details'

const style = {
    mb: '4px',
    display: 'flex',
    justifyContent: 'space-between'
}
const SizeWeigth = (props: any) => {
    const { item } = props
    return (
        <DetailsWrapper>
            <Box sx={{
                color: '#666',
                fontSize: "18px"
            }}>
                <Grid container spacing={2} direction='row'>
                    {/* =======Vehicle Size details========== */}
                    <SizeDetail item={item} />

                    {/* =======Vehicle Weight details========== */}
                    <WeightDetail item={item} />

                    {/* ======= vehicle Tyres details========== */}
                    <TyresDetail item={item} />
                </Grid>
            </Box>
        </DetailsWrapper>
    )
}

export default SizeWeigth