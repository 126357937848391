import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicleTypeRequest } from 'store/actions/vehicle-actions';
import { useEffect, useState } from 'react';
import { SvgArrow } from 'app/components/svgicons/svgNew';
import { colorNew } from 'styles/StyleConstants';
import { ProductCategories, localstorageKey } from 'config/constants';
import { useHistory } from 'react-router-dom';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { setCreateAdModalOpen } from 'store/actions/public-actions';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';
import { useDeviceType } from 'app/Hooks/useMediaQuery';

const ChooseVehicleWrapper = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > div': {
    width: '100%',
    maxWidth: '932px',
    height: '415px',
  },
  '.modal-wrapper': {
    display: 'flex',
    padding: 0,
    flexDirection: 'row'
  },
  '.sidebar': {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    borderRight: `2px solid #F1F1F9`
  },
  '.sidebar > .head': {
    marginLeft: '20px',
    fontSize: '15px',
    fontWeight: '400',
    color: colorNew.primaryColorDark
  },
  '.sidebar> div': {
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: '14px',
    alignItems: 'center',
    '&.selected > div': {
      width: '8px',
      height: 'inherit',
      borderRadius: '0px 3px 3px 0px',
      backgroundColor: colorNew.primaryColor
    },
    '&.not-selected > span ': {
      paddingLeft: '12px'
    },
    '&.type > span': {
      fontSize: '18px',
      fontWeight: '400',
      color: colorNew.colorGrayDark4
    },
    '&.selected > span': {
      color: `${colorNew.primaryColor} !important`
    },
  },
  '.body-content': {
    width: '75%',
    padding: '24px 36px',
    'div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      '.vehicle-name': {
        display: 'flex',
        flexDirection: 'row',
        gap: '25px'
      },
      'span': {
        fontSize: '15px',
        fontWeight: 400,
        color: colorNew.primaryColorDark
      },
      'button': {
        maxWidth: '283px',
        height: '78px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '6px 0',
        gap: '24px',
        'svg': {
          width: '60px',
          height: '60px'
        },
        'span': {
          width: '-webkit-fill-available',
          textAlign: 'start',
          fontSize: '21px',
          textTransform: 'none',
          color: '#000'
        },
        'img': {
          width: '52px',
          height: '52px'
        }
      }

    },
  },
  '.iconButton': {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
    zIndex: '100',
  },

  '@media (max-width:768px)': {
    '.MuiDialog-container > div': {
      height: 'max-content',
      paddingBottom: '10px'
    },
    '.modal-wrapper': {
      'flexDirection': 'column',
      "&>div": {
        width: '100%'
      },
      '.body-content': {
        padding: '24px',
        "&>div": {
          gap: '12px',
          '&>div': {
            flexDirection: 'row',
            'button': {
              flexDirection: 'column',
              gap: '12px',
              'span': {
                fontSize: '15px',
                textAlign: 'center'
              },
              'svg': {
                display: 'none'
              }
            }
          }
        }
      }
    }
  },
  '@media (max-width:360px)': {
    '.btn-wrapper': {
      gap: '12px !important'
    },
    '.body-content': {
      'button': {
        'img': {
          width: '40px !important',
          height: '40px !important'
        },
        'span': {
          fontSize: '13px !important'
        }
      }
    },
  }
}));

const ChooseVehicleMobileWrapper = styled(Box)(({ theme }) => ({
  '.body-content': {
    'button': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      color: '#000',
      background: '#fff',
      marginBottom: '10px'
    }
  },
  'svg': {
    'path': {
      fill: '#000'
    }
  },
  '.vehicle-name': {
    display: 'flex',
    gap: '30px',
    alignItems: 'center'
  },
  '.iconButton': {
    position: 'absolute',
    right: 8,
    top: 8,
    zIndex: '100',
  }
}))

const creatAdPopup = (isSmallDevice, handleClose, productType, t, vehicleTypes, handleVehicleSelect, handleProductCategorySelect) => {
  return <>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      className='iconButton'>
      <CloseIcon />
    </IconButton>
    <DialogContent className='modal-wrapper'>
      {
        !isSmallDevice && <>
          <Box className='sidebar'>
            <Box className='head'>{t(translations.addVehicleScreen.CATEGORIES)}</Box>
            <Box className={`type ${productType === ProductCategories.vehicle ? ' selected ' : ' not-selected '}`} onClick={() => { handleProductCategorySelect(ProductCategories.vehicle) }}>
              <Box className=''></Box>
              <span>{t(translations.addVehicleScreen.VEHICLE)}</span>
            </Box>
          </Box>
        </>
      }
      <Box className='body-content'>
        <span>{t(translations.addVehicleScreen.Choose_PRODUCT)}</span>
        {
          productType === ProductCategories.vehicle &&
          <Box className='btn-wrapper'>
            {
              vehicleTypes && vehicleTypes.length > 0 ? vehicleTypes.map((vehicle) => {
                return <Button key={`ads-${vehicle.id}`} variant='text' onClick={() => handleVehicleSelect(vehicle)}>
                  <Box className='vehicle-name'>
                    <img src={vehicle.media[0].original_url} alt='icon' />
                    <span>{vehicle.item_name}</span>
                  </Box>
                  <SvgArrow />
                </Button>
              }) : <span>{t(translations.addVehicleScreen.NO_VEHICLE_TYPE)}</span>
            }
          </Box>
        }
      </Box>
    </DialogContent>
  </>
}

export default function ChooseVehiclePopup() {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { isCreateAdModalOpen } = useSelector((state) => state.publicReducer)
  const { vehicleTypes, loading } = useSelector((state: any) => state.vehicle)
  const [productType, setProductType] = useState(() => ProductCategories.vehicle)

  const [isSmallDevice] = useDeviceType()
  const handleClose = () => {
    dispatch(setCreateAdModalOpen(false))
  };

  useEffect(() => {
    if (vehicleTypes.length === 0)
      dispatch(getVehicleTypeRequest())
  }, [])

  const handleVehicleSelect = (vehicle) => {
    localStorage.setItem(localstorageKey.selectedVehicle, vehicle.id)
    history.push('/add-vehicle')
    dispatch(setCreateAdModalOpen(false))
  }

  // select category like vehicle , tire or parts
  const handleProductCategorySelect = (type) => {
    setProductType(type)
    localStorage.setItem(localstorageKey.selectedProductCategory, type)
  }

  if (loading) return <ContentLoader />
  return (<>
    {isSmallDevice ?
      <Drawer
        anchor='bottom'
        open={isCreateAdModalOpen}
        onClose={() => dispatch(setCreateAdModalOpen(false))}
        sx={{
          '.MuiPaper-root': {
            maxHeight: "70%",
            borderRadius: '6% 6% 0 0',
            overflowY: 'inherit',
            background: '#f1f2f7'
          },
        }}
      >
        <ChooseVehicleMobileWrapper>
          {creatAdPopup(isSmallDevice, handleClose, productType, t, vehicleTypes, handleVehicleSelect, handleProductCategorySelect)}
        </ChooseVehicleMobileWrapper>
      </Drawer > :
      <ChooseVehicleWrapper
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isCreateAdModalOpen}
      >
        {creatAdPopup(isSmallDevice, handleClose, productType, t, vehicleTypes, handleVehicleSelect, handleProductCategorySelect)}
      </ChooseVehicleWrapper>}
  </>
  );
}

