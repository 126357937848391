import styled from "styled-components";

export const ShareAdWrapper = styled.div`
.width-100{
  width:100%
}
.contentBox{
  display:flex;
  align-items:center;
  justify-content:center;
  backdrop-filter: blur(2px);
  height: 33px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.90);
  background: rgba(0, 0, 0, 0.10);
  padding:0;
  overflow:hidden;
}
.share-icon{
  width:35px;
  height:35px;
  max-width:35px;
  min-width:auto;
  bottom:55px;
  right:10px;
  cursor : pointer
}

.share-icon svg path, .withoutlike svg path{
  fill : rgba(255, 255, 255, 0.90);
}
.shareBtn{
  border: 1px solid rgb(197, 197, 197);
  width :64px;
  height :42px;
}
.shareContainer{
  display: flex;
  flex-direction: column;
  min-width: 250px;
  text-align: left;
  align-items: flex-start;
  padding: 20px;
  gap: 6px;
  position: relative;
}

.withoutlike{
  width:35px;
  height:35px;
  max-width:35px;
  min-width:35px;
  bottom:12px;
  right:10px;
  cursor : pointer
}
@media (max-width:768px){
 .share-icon{
  width:34px;
  height:33px;
  bottom:60px;
  right:12px;
  cursor : pointer
}
}
`