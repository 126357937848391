import { put, select, takeEvery } from 'redux-saga/effects';
import { featuresSuccess } from 'store/actions/ctb-action';
import {
  userProfileSaveRequest,
  userProfileSaveFailure,
  userProfileSaveSuccess,
  userAddressSaveRequest,
  userAddressSaveFailure,
  userAddressSaveSuccess,
  changePasswordRequest,
  changePasswordFailure,
  changePasswordSuccess,
  changeEmailRequest,
  changeEmailSuccess,
  changeEmailFailure,
  changeEmailActivateAccountRequest,
  changeEmailActivateAccountSuccess,
  changeEmailActivateAccountFailure,
  userProfileImageRequest,
  userProfileImageSuccess,
  userProfileImageFailure,
  updateUserPrivacyRequest,
  updateUserPrivacySuccess,
  updateUserPrivacyFailure,
  getUserDataRequest,
  getUserDataSuccess,
  getUsersPermissionsSuccess,
  getUsersPermissionsFailure,
  getUsersPermissionsRequest,
  deleteAccountRequest,
  deleteAccountSuccess,
  deleteAccountFailure,
  getCreditPlansFailure,
  getCreditPlansSuccess,
  getCreditPlansRequest,
  getPromoCodeRequest,
  getPromoCodeSuccess,
  getPromoCodeFailure,
  creditsHistoryRequest,
  creditsHistoryFailure,
  creditsHistorySuccess,
  creditsBalanceFailure,
  creditsBalanceSuccess,
  creditsBalanceRequest,
  creditsPaymentSuccess,
  creditsPaymentFailure,
  creditsPaymentRequest
  // getUserDataFailure
} from '../actions/user-actions';
import { ApiPaths } from './../../services/ApiPaths';
import Api from './../../services/axios';
import { getIdFromCode, getSubsidiaryStored } from 'config/variables';
import { updateMyWalletBalance } from 'store/actions/auth-actions';

export const saveUserDataToLocal = (params: any) => {
  localStorage.setItem('user', JSON.stringify(params));
}

function* _deleteAccountRequest() {
  try {
    const uri = ApiPaths.user.saveProfile + ApiPaths.user.deleteAccount;
    const { data } = yield Api.delete(uri, {});
    yield put(deleteAccountSuccess(data));
  }
  catch (e) {
    yield put(deleteAccountFailure(e.response))
  }
}

function* _userProfileSaveRequest({ payload }) {
  try {
    const uri = ApiPaths.user.saveProfile;
    if (!payload?.userData?.profile_is_verified) {
      payload['first_name'] = payload.firstName;
      payload['last_name'] = payload.lastName;
      payload['phone'] = payload.phone;
    }
    payload['pseudo'] = payload.pseudoName
    delete payload['pseudoName']
    delete payload['firstName']
    delete payload['lastName']
    const { data } = yield Api.post(uri, {
      ...payload,
      "_method": "PATCH"
    });
    yield put(userProfileSaveSuccess(data));
  } catch (e) {
    yield put(userProfileSaveFailure(e.response.data));
  }
}

function* _userAddressSaveRequest({ payload }) {
  try {
    const uri = ApiPaths.user.saveAddress + "/" + payload.addressId;
    const resp = yield Api.post(uri, {
      "addressId": payload.addressId,
      "route": payload.route,
      "street_number": payload.street_number,
      "postal_code": payload.postal_code,
      "sub_locality": payload.sub_locality,
      "locality": payload.locality,
      "adm_area_l1_region": payload.administrative_area_level_1,
      "adm_area_l2_province": payload.administrative_area_level_2,
      "formatted_address": payload.formatted_address,
      "lat": payload.lat,
      "long": payload.long,
      "google_place_id": payload.place_id,
      "country_id": payload.country_id,
      "_method": "PATCH"
    });
    yield put(userAddressSaveSuccess(resp?.data?.data?.address || {}));
  } catch (e: any) {
    yield put(userAddressSaveFailure(e.response.data));
  }
}

function* _changePasswordRequest({ payload }) {
  try {
    const uri = ApiPaths.user.changePassword;
    const { data } = yield Api.post(uri, {
      "password": payload.currentPassword,
      "new_password": payload.newPassword,
      "new_password_confirmation": payload.newPasswordConfirmation,
    });
    yield put(changePasswordSuccess(data));
  } catch (e: any) {
    yield put(changePasswordFailure(e.response.data));
  }
}

function* _changeEmailRequest({ payload }) {
  try {
    const uri = ApiPaths.user.changeEmail;
    yield Api.post(uri, {
      "new_email": payload.newEmail,
      "new_email_confirmation": payload.newEmailConfirmation,
      "old_email": payload.currentEmail,
      "password": payload.password
    });
    yield put(changeEmailSuccess());
  } catch (e: any) {
    yield put(changeEmailFailure(e.response.data));
  }
}

function* _getUsersPermissionsRequest({ payload }) {
  try {
    let permissions = [];
    if (payload.subsidiaryId && payload.subsidiaryId !== null) {
      const uri = ApiPaths.user.saveProfile + '/' + payload.subsidiaryId + ApiPaths.user.permission;
      const { data } = yield Api.get(uri, {});
      permissions = data.data.items;
    } else {
      const uri = ApiPaths.company.features;
      const { data } = yield Api.get(uri, {});
      permissions = data.data.features;
      yield put(featuresSuccess(permissions));
    }
    yield put(getUsersPermissionsSuccess(permissions));
  } catch (e) {
    yield put(getUsersPermissionsFailure(e.response));
  }
}

function* _changeEmailActivateAccountRequest({ payload }) {
  try {
    const uri = ApiPaths.user.changeEmailActivateAccount + "/" + payload.id + "/" + payload.hash;
    const { data } = yield Api.post(uri, {
      email: payload.email,
      expires: payload.expires,
      signature: payload.signature,
      "_method": "patch"
    });
    yield put(changeEmailActivateAccountSuccess(data));
  } catch (e) {
    yield put(changeEmailActivateAccountFailure(e.response));
  }
}

function* _userProfileImageRequest({ payload }) {
  try {
    const uri = ApiPaths.user.saveImage;
    const formdata = new FormData();
    formdata.append('avatar', payload.image);
    const { data } = yield Api.post(uri, formdata);
    yield put(userProfileImageSuccess(data));
    let userData = yield getUserRequest();
    yield saveUserDataToLocal(userData.data.user);
  } catch (e) {
    yield put(userProfileImageFailure(e.response.data));
  }
}

function* _getUserDataRequest() {
  const user = yield select(state => state.auth);
  if (user.loggedIn) {
    try {
      const uri = ApiPaths.auth.getUser;
      const { data } = yield Api.get(uri);
      yield saveUserDataToLocal(data.data.user);
      yield put(getUserDataSuccess(data))
      if (!getSubsidiaryStored()) {
        yield put(updateMyWalletBalance(data?.data?.user?.wallet_balance ?? 0))
      }
    } catch (e) {

    }
  }
}

function* getUserRequest() {
  try {
    const uri = ApiPaths.auth.getUser;
    const { data } = yield Api.get(uri);
    return data;
  } catch (e) {

  }
}



function* _updateUserPrivacyRequest({ payload }) {
  try {
    const uri = ApiPaths.user.saveProfile + "?_method=patch";
    const { data } = yield Api.post(uri, payload);
    yield put(updateUserPrivacySuccess(data));
  } catch (e) {
    yield put(updateUserPrivacyFailure(e.response));
  }
}


function* _creditsHistoryRequest({ payload }) {
  try {
    let uri = ApiPaths.user.credits
    if (payload.Page) {
      uri += '?page=' + payload.Page
    }
    const { data } = yield Api.get(uri);
    yield put(creditsHistorySuccess(data));
  } catch (e) {
    yield put(creditsHistoryFailure(e.response));
  }
}

function* _creditsBalanceRequest() {
  try {
    const uri = ApiPaths.user.wallet_balance
    const { data } = yield Api.get(uri);
    yield put(creditsBalanceSuccess(data));
    yield put(updateMyWalletBalance(data?.data?.item || '0'));

  } catch (e) {
    yield put(creditsBalanceFailure(e.response));
  }
}

function* _getCreditPlansRequest() {
  try {
    const uri = ApiPaths.user.creditPlans;
    const { data } = yield Api.get(uri);

    yield put(getCreditPlansSuccess(data.data));
  } catch (e) {
    yield put(getCreditPlansFailure(e.response));
  }
}
function* _getPromoCodeRequest({ payload }) {
  try {
    let uri = ApiPaths.user.promoCodes
    if (payload.stripe_product_id) {
      uri += '?stripe_product_id=' + payload.stripe_product_id
    }
    const { data } = yield Api.get(uri, {});
    yield put(getPromoCodeSuccess(data.data));
  } catch (e) {
    yield put(getPromoCodeFailure(e.response));
  }
}

function* _creditsPaymentRequest({ payload }) {
  try {
    const uri = ApiPaths.user.getTotals;
    const { data } = yield Api.post(uri, payload);
    yield put(creditsPaymentSuccess(data.data));
  } catch (e) {
    yield put(creditsPaymentFailure(e.response));
  }
}




export const userSagas = [
  takeEvery(userProfileSaveRequest, _userProfileSaveRequest),
  takeEvery(userAddressSaveRequest, _userAddressSaveRequest),
  takeEvery(changePasswordRequest, _changePasswordRequest),
  takeEvery(changeEmailRequest, _changeEmailRequest),
  takeEvery(changeEmailActivateAccountRequest, _changeEmailActivateAccountRequest),
  takeEvery(userProfileImageRequest, _userProfileImageRequest),
  takeEvery(updateUserPrivacyRequest, _updateUserPrivacyRequest),
  takeEvery(getUserDataRequest, _getUserDataRequest),
  takeEvery(getUsersPermissionsRequest, _getUsersPermissionsRequest),
  takeEvery(deleteAccountRequest, _deleteAccountRequest),
  takeEvery(getCreditPlansRequest, _getCreditPlansRequest),
  takeEvery(getPromoCodeRequest, _getPromoCodeRequest),
  takeEvery(creditsHistoryRequest, _creditsHistoryRequest),
  takeEvery(creditsBalanceRequest, _creditsBalanceRequest),
  takeEvery(creditsPaymentRequest, _creditsPaymentRequest)
];
