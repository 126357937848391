const FEATURES = {
  RENT_AD: 'rent_ad',
  ADS:"ads",
  DEPARTMENT_SUBSIDIARY: 'department_subsidiary',
  FINANCE: 'finance',
  MEMBER: 'member',
  ROLE: 'role',
  SCHEDULE: 'schedule',
  SETTING_STORE: 'setting_store',
  SUBSCRIPTION: 'subscription',
  AUCTION_EVENT: 'auction_event',
  PAYMENT_METHOD: 'payment_method',
  PURCHASE: 'purchase',
  INVOICE_SUBSCRIPTION: 'invoice_subscription',
  VEHICLE:"vehicle",
  BUYING:"buying",
  SELLING:"selling"
};

const ACTIONS = {
  ADD: 'add',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  VIEW: 'view',
};

export const PERMISSIONS = {FEATURES, ACTIONS};

export const hasPermissions = (allowedPermissions: Array<object>, feature: string, permission: string): boolean => {
  let findItem: any = allowedPermissions.find((i: any) => i.slug === feature);
  if (findItem) {
    let findSubItem = findItem.permissions.find((x: any) =>
      permission === ACTIONS.VIEW ? Object.values(ACTIONS).includes(x.slug) : x.slug === permission,
    );
    if (findSubItem) return true;
    return false;
  }
  return false;
};


