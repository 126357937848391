import { Box } from '@mui/system';
import { SvgfluentIcon } from 'app/components/svgicons/svgNew';

interface IUseIcon {
    isVerified:boolean, 
    iconStyles?:object,
    classname?:string,
}

const AccountVerifiedIcon = ({isVerified, iconStyles, classname}:IUseIcon) => {
  return (
    <>
      {isVerified ? (
        <Box sx={{ marginLeft:'5px',
          'svg':{
            height:'15px',
            width:'15px'
          },
         ...iconStyles
          }} className={classname}>
            <SvgfluentIcon />
        </Box>
      ): null}
    </>
  );
};

export default AccountVerifiedIcon;
