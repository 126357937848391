import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './de/translation.json';
import en from './en/translation.json';
import es from './es/translation.json';
import fr from './fr/translation.json';
import nl from './nl/translation.json';
import { convertLanguageJsonToObject } from './translations';

export const translationsJson = {
  en: {
    // English
    translation: en,
  },
  fr: {
    // French
    translation: fr,
  },
  de: {
    // German
    translation: de,
  },
  es: {
    // Spainish
    translation: es,
  },
  nl: {
    // Dutch
    translation: nl,
  },
};

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(en);

export const i18n:any = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    fallbackLng: 'fr',
    whitelist: ['en', 'fr', 'de', 'es', 'nl'],
    saveMissing: true,
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      // checkWhitelist: true
    },
    // lng:localStorage.getItem('i18nextLng') || "en",
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: '.'
    }
  });