import { lazyLoad } from 'utils/loadable';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';

export const EmployeeAddPage = lazyLoad(
  () => import('./index'),
  module => module.EmployeeAddPage,
  {
    fallback: (
     <ContentLoader />
    ),
  },
);