import { Box, Checkbox, FormControlLabel } from '@mui/material'
import TextInput from 'app/components/CustomFields/textInput'
import { Nullable } from 'app/pages/PublicProfile/interface/interface'
import React, { useEffect, useState } from 'react'
import { seller, sellerSelected, withPicture } from '../filterConfig'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { getParamQueries } from 'config/appConfig'
import { useLocation } from 'react-router-dom'

const SellerAndWarranty = ({ updateURL }) => {

    const { t } = useTranslation()
    const location = useLocation()


    const [onlyWithPicture, _onlyWithPicture] = useState<boolean>(false)
    const [sellerType, _sellerType] = useState<Nullable<string>>('')
    const [warranty, _warranty] = useState<Nullable<number>>()

    // Sets values got from url to fields (runs only when page is rendered for the first time)
    useEffect(() => {
        let payload = getParamQueries(location.search)
        // !!+ converts 1 or 0 to boolean
        _onlyWithPicture(payload.with_media ? !!+(payload.with_media) : false)
        _sellerType(null)
        if (payload.private_seller) {
            _sellerType(seller.PRIVATE)
        }
        if (payload.dealer_seller) {
            _sellerType(seller.DEALER)
        }
        _warranty(payload.warranty_start ?? null)

    }, [location.search])

    const handleSellerType = (type) => {
        _sellerType(type)
        if (type == seller.DEALER) {
            updateURL('dealer_seller', sellerSelected)
            updateURL('private_seller', '')
        }
        if (type == seller.PRIVATE) {
            updateURL('dealer_seller', '')
            updateURL('private_seller', sellerSelected)
        }
    }

    const handleWarranty = (value) => {
        _warranty(value)
        updateURL('warranty_start', value)
    }

    const handlePictureFilter = () => {
        _onlyWithPicture(!onlyWithPicture)
        updateURL('with_media', onlyWithPicture ? false : withPicture.true)
    }

    return (
        <Box sx={{ gap: '24px' }} className='flexColumn'>
            <Box className='flexRow justifyContentBetween alignItemsCenter'>
                <span className='vatCheckBoxLabel'>{t(translations.FILTER_COMPONENT.ONLY_WITH_PICTURE)}</span>
                <Checkbox checked={onlyWithPicture} onChange={handlePictureFilter} />
            </Box>
            <Box className='flexRow justifyContentBetween'>
                <FormControlLabel control={<Checkbox checked={sellerType == seller.PRIVATE} onChange={() => handleSellerType(seller.PRIVATE)} />} label={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_PRIVATE)} sx={{ mr: 0 }} className='filterCheckBox' />
                <FormControlLabel control={<Checkbox checked={sellerType == seller.DEALER} onChange={() => handleSellerType(seller.DEALER)} />} label={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_SELLER_DEALER)} sx={{ mr: 0 }} className='filterCheckBox' />
            </Box>
            <TextInput title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_WARRANTY)} postText={t(translations.FILTER_COMPONENT.UNTIL_MONTH)} preText={""} value={warranty} handleChange={(value) => handleWarranty(value)} />
        </Box>
    )
}

export default SellerAndWarranty