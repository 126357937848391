import { Box } from "@mui/material"
import { SvgHeartIcon, SvgPhoneIcon, SvgClickIcon, SvgMyAdChat } from "app/components/svgicons/svgNew"
import { IconBedge } from "../../AdsStyledSlice";
import { lan } from 'locales/multiPageTranslation/adsLan'
import { useTranslation } from "react-i18next";
import { colorNew } from "styles/StyleConstants";
import { numberFormat } from "config/variables";
import { useDeviceType } from "app/Hooks/useMediaQuery";

export const BikeStepIcon = (props: any) => {
    const { motoBikefuel, milege, isMotorCycle, fuel, regiterDate, frameSize, bodyVehicle } = props;
    const [isSmallDevice, isMediumDevice] = useDeviceType()

    return <Box className={'scrollBar characteristicsContainer'} sx={
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            maxWidth: (isSmallDevice || isMediumDevice) ? '100%' : '470px',
            alignItems: 'center',
            flexWrap: 'wrap',
            '.line': {
                width: '1px',
                height: '10px',
                background: '#E5E5E5'
            }
        }}  >
        {regiterDate && <>
            <IconBedge>
                <span>{regiterDate} </span>
            </IconBedge>
            <Box className='line' ></Box>
        </>
        }
        {fuel && !isMotorCycle && <>
            <IconBedge>
                <span> {fuel} </span>
            </IconBedge>
            <Box className='line' ></Box>
        </>}
        {motoBikefuel && isMotorCycle && <>
            <IconBedge>
                <span> {motoBikefuel} </span>
            </IconBedge>
            <Box className='line' ></Box>
        </>}

        {frameSize && <>
            <IconBedge>
                <span> {frameSize} </span>
            </IconBedge>
            <Box className='line' ></Box>
        </>}

        {bodyVehicle && <><IconBedge>
            <span> {bodyVehicle} </span>
        </IconBedge>
        </>
        }

        {milege && isMotorCycle && <>
            <Box className='line' ></Box>
            <IconBedge>
                <span> {milege + ' km'} </span>
            </IconBedge></>}
    </Box>
}
export const CarIcon = (props: any) => {
    const { regiterDate, milege, fuel, gearBox, power } = props;
    const [isSmallDevice, isMediumDevice] = useDeviceType()
    const milegeText = () => {
        if (milege !== undefined && Number(milege) < 1) {
            return `${milege}`
        }
        return numberFormat(milege)
    }
    return (<Box className={'scrollBar characteristicsContainer'} sx={
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            maxWidth: (isSmallDevice || isMediumDevice) ? '100%' : '470px',
            alignItems: 'center',
            flexWrap: 'wrap',
            '.line': {
                width: '1px',
                height: '10px',
                background: '#E5E5E5'
            }
        }
    } >
        <IconBedge>
            <span> {regiterDate} </span>
        </IconBedge>
        <Box className='line' ></Box>
        <IconBedge>
            <span> {fuel} </span>
        </IconBedge>
        <Box className='line' ></Box>
        <IconBedge className="power">
            <span>{power} </span>
        </IconBedge>
        <Box className='line' ></Box>
        <IconBedge  >
            <span> {milegeText() + ' km'} </span>
        </IconBedge>
        <Box className='line' ></Box>
        <IconBedge>
            <span> {gearBox} </span>
        </IconBedge>
    </Box >)
}

export const VehicleIdentity = (props: any) => {
    const { licenseNumber, refrance, vinNumber } = props
    const { t } = useTranslation()
    return (
        <Box
            sx={{
                fontSize: '15px',
                fontFamily: 'inter',
                '> span': {
                    margin: '2px',
                    marginRight: '5px',
                    display: 'inline-block',
                    'label': {
                        marginRight: '5px',
                    },
                    'span': {
                        opacity: 0.5,
                        marginLeft: '5x',
                        minWidth: 50,
                        display: 'inline-block'
                    }

                }

            }}
        >
            {licenseNumber && <span>
                <label>{t(...lan.licensePlate())}</label> : <span> {licenseNumber} </span>
            </span>}
            {refrance && <span>
                <label>{t(...lan.reference())}</label> : <span> {refrance} </span>
            </span>}
            {vinNumber && <span>
                <label
                    style={{
                        textTransform: 'uppercase'
                    }}
                >{t(...lan.vin())}</label> : <span> {vinNumber} </span>
            </span>}
        </Box>
    )
}

export const MyadsQueryIcon = (props: any) => {
    const { clickCount, phoneCount, chatCount, FavCount } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                color: colorNew.colorGrayDark,
                backgroundColor: colorNew.colorGray,
                maxWidth: 443,
                padding: 1,
                borderRadius: '5px',
                justifyContent: 'space-between',
                'div.parent-wrapper': {
                    width: 100,
                    backgroundColor: colorNew.colorWhite,
                    borderRadius: 'inherit',
                    padding: '5px',
                    'span': {
                        fontSize: '13px',
                        fontWeight: 600,
                    },
                    'svg': {
                        height: 17,
                        width: 17,
                        marginRight: 2
                    }
                },

            }}
        >
            <div className="parent-wrapper">
                <div>
                    <SvgHeartIcon /> <span>{FavCount}</span>
                </div>
            </div>
            <div className="parent-wrapper">
                <div>
                    <SvgPhoneIcon /> <span>{phoneCount}</span>
                </div>
            </div>
            <div className="parent-wrapper">
                <div>
                    <SvgMyAdChat /> <span>{chatCount}</span>
                </div>
            </div>
            <div className="parent-wrapper">
                <SvgClickIcon /> <span>{clickCount}</span>
            </div>
        </Box>
    )
}