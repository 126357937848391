import { Box } from '@mui/material'
import { SvgSort, SvgWorld } from 'app/components/svgicons/svg2'
import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { langugageSwitchOpen } from 'store/actions/component-actions'

const LanguageSwitch = () => {

    const dispatch = useDispatch()

    const [language, setLanguage] = useState<String>('')
    const { languageData, languageChangedData } = useSelector((state: RootStateOrAny) => state.auth)

    useEffect(() => {
        if (languageData && Object.keys(languageData) && languageData.languages) {
            const currLanguage = localStorage.getItem('i18nextLng')
            const getLanguage = languageData.languages.find(language => language.code == currLanguage)
            let setNewLanguage = 'fr';
            if (getLanguage?.name) {
                setNewLanguage = getLanguage.name;
            }
            setLanguage(setNewLanguage)
        }
    }, [languageChangedData, languageData])

    return (
        <Box sx={{ backgroundColor: "#1B2A8D", p: 2 }} className='flexRow alignItemsCenter justifyContentCenter'>
            <Box onClick={() => dispatch(langugageSwitchOpen())} className='pointer flexRow alignItemsCenter languageContainer' sx={{ p: 2, gap: '10px' }}>
                <SvgWorld />
                <span className='languageName'>{language}</span>
                <SvgSort />
            </Box>
        </Box>
    )
}

export default LanguageSwitch