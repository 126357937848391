import { Box } from '@mui/material';
import { useWindowSize } from 'app/Hooks/windowResize';
import { SvgMobileLeftArrow, SvgmemSearch } from 'app/components/svgicons/svgNew';
import { translations } from 'locales/translations';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { updateGroupDetailRequest } from 'store/actions/chat-action';
import { colorNew } from 'styles/StyleConstants';
import AvatarandDetails from './AvatarandDetails';
import { SvgPlusLite, SvgRemoveIcon } from 'app/components/svgicons/svg';
import { getSubsidiaryStored } from 'config/variables';
import { UserInterface, groupMemberListI } from '../../interface/interface';
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';
import AccountVerifiedIcon from 'app/Component2/GlobalComponent/AccountVerifiedIcon ';

const GroupMemberList = ({ setGroupTitle, subsidiaryDetails, chatId, removedMemberList, groupTitle, removeMembersFromGroup, allUserList, setAddNewMember, setShowInitiateChatModal,chatData, groupUpdated , setGroupUpdated, resetGroupMember } : groupMemberListI) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [mobile] = useWindowSize()
    const [filteredData, setFilteredData] = useState<Array<UserInterface>>(allUserList ? allUserList :[])
    const [removeMemberClass, setRemoveMemberClass] = useState<string>('disabled')

    const { userData } = useSelector((state: RootStateOrAny) => state.user)

    const { groupUpdateLoading } = useSelector((state: RootStateOrAny) => state.chat)

    const [text,setText] = useState<string>('')
    
    // handle searched user function
    const handleSearchUser = (event) => {
        setText(event.target.value)
        let findMembers = allUserList.filter(item => {
            return item.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
        setFilteredData(findMembers)
    }
     
    // handle group title
    const handleGroupTitle = (event) => {
        if(event.target.value !== null && event.target.value !== undefined){           
           setRemoveMemberClass('')
           setGroupTitle(event.target.value)
        }
        if(event.target.value === ''){
            setRemoveMemberClass('disabled')
        }
    }

    useEffect(() => {
        setFilteredData(allUserList)
    }, [allUserList])

    useEffect(() => {
        if (removedMemberList.length > 0 || groupUpdated) {
            setRemoveMemberClass('')
        }
    }, [removedMemberList,groupUpdated])

    const handleUpdateGroup = () => {
        dispatch(updateGroupDetailRequest({ chat_id: chatId, title: groupTitle, remove_member_ids: removedMemberList }))
    }

    // Removes user from filtered list when any users gets removed from group
    const updateFilteredData = (id) =>{
        let groupUsers = [...filteredData]
        let index = groupUsers.findIndex((o)=>o.id === id)
        if(index !== -1){
            groupUsers.splice(index,1)
            setFilteredData(groupUsers)
        }
    }


    return <>
        {<>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                ".creatGroup": {
                    color: colorNew.primaryColor,
                    
                },
                ".error": {
                    color: colorNew.colorRed,
                    'svg': {
                        'path': {
                            fill: colorNew.colorRed
                        }
                    }
                },
                ".Add-Member": {
                    fontSize: "21px",
                    fontWeight: "400",

                },
                '.svgStyle': {
                    'svg': {
                        'path': {
                            stroke: colorNew.colorBlack
                        }
                    }
                },
                '.disabled': {
                    color: colorNew.colorGrayDark,
                }
            }}>
                <span className={"Add-Member"}>{mobile && <SvgMobileLeftArrow className='pointer' style={{ marginRight: '10px' }} onClick={()=>setShowInitiateChatModal(false)}/>}{t(translations.CHAT.MANAGE_GROUP)}</span>
                {!mobile && <span className={"Add-Member pointer svgStyle flexRow alignItemsCenter"} onClick={() => {
                    setAddNewMember(true)
                    resetGroupMember()
                    }}><SvgPlusLite style={{marginRight:'5px'}}/>{t(translations.formText.ADD_NEW_MEMBER)}</span>}
                {/* <span className='error Add-Member'> <SvgDelete />{"Delete Group"}</span> */}
                <h2 className={`creatGroup pointer ${removeMemberClass} `} onClick={() => {
                    if ((removedMemberList.length > 0 || (groupTitle !=="" && groupTitle !== chatData.title)) && !groupUpdateLoading) {
                        handleUpdateGroup()
                        setShowInitiateChatModal(false)
                        return
                    }
                    if(groupUpdated){
                        setGroupUpdated(false)
                        setShowInitiateChatModal(false)
                        return
                    }                   
                }}>{t(translations.ads.SAVE)}</h2>
            </Box>
            <Box>
                <Box
                    className='input-group title'
                    sx={{
                        'input': {
                            paddingLeft: '15px !important',
                        }
                    }} >
                    <input
                        value={groupTitle}
                        type="text"
                        className="form-control title"
                        placeholder={t(translations.CHAT.GROUP_TITLE)}
                        aria-label="search"
                        aria-describedby="search"
                        onChange={(e) => handleGroupTitle(e)}
                    />
                </Box>
            </Box>
            {mobile && <span className={"Add-Member pointer svgStyle flexRow alignItemsCenter"} onClick={() => {
                setAddNewMember(true)
                resetGroupMember()
                }}><SvgPlusLite style={{ marginRight: '10px' }}/>{t(translations.formText.ADD_NEW_MEMBER)}</span>}
            <Box>
                <div className="input-group">
                    <span>
                        <SvgmemSearch />
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t(translations.webTexts.SEARCH_MEMBER)}
                        aria-label="search"
                        aria-describedby="search"
                        onChange={(e) => handleSearchUser(e)}
                    />
                </div>
            </Box>
        </>
        }
        <Box>
            <Box className="suggestedMember">
                <h6 className="suggesttitle">
                    {t(translations.CHAT.GROUP_MEMBER)}
                </h6>
            </Box>
        </Box>
        <Box className="intiateChatUserList intiateChatUserListPopup scrollBar" sx={{
          maxHeight: window.innerWidth < 992 ?'calc(100vh - 410px) !important' : "39vh"
        }}>
            {(text===""|| subsidiaryDetails.name.toLowerCase().includes(text.toLowerCase())) ?
            <Box className='select_user pointer'
                sx={{
                    justifyContent: "space-between"
                }}
            >
                    <Box className='avtar-detail-wrapper' sx={{
                        maxWidth: '78%',
                        width: '78%',
                    }}>
                        <Box sx={{
                            position: 'relative'
                        }}>
                            <Box className='userProfileView'>
                                <img src={subsidiaryDetails && subsidiaryDetails.logo_url ? subsidiaryDetails.logo_url : userPlaceHolder} alt="User Image" />
                            </Box>
                            {subsidiaryDetails && subsidiaryDetails.limited_address && <img className="activeFlag" src={subsidiaryDetails.limited_address.flag_thumbnail} />}
                        </Box>
                        <Box className='user-details'>
                            <span className='name read' style={{ display:'flex' }}>
                                {
                                subsidiaryDetails ?
                                    !chatData.is_receptionist_chat ?
                                        userData.pseudo  + " - " +  subsidiaryDetails.name :
                                        subsidiaryDetails.name :
                                t(translations.CHAT.ANONYMOUS)
                                }
                                <AccountVerifiedIcon isVerified={subsidiaryDetails.profile_is_verified} />
                            </span>
                        </Box>

                    </Box>
                    <Box>
                        <span className="dealerText">{t(translations.CHAT.ADMIN)}</span>
                    </Box>
                </Box>
                :''}
            {filteredData && filteredData.length > 0 ? filteredData.map((item, index) => (

                <Box className='select_user' key={index}
                    sx={{
                        justifyContent: "space-between"
                    }}
                >
                    <AvatarandDetails hidebackButton={true}  description={true} userInfo={item} hideStatus={true} hideFlag={false} newChat={true} popup={true} hidebackButton={true}/>
                    {(item.pivot?.subsidiary_id !== null && (item.pivot?.subsidiary_id == getSubsidiaryStored())) &&
                        <Box className='pointer'
                        onClick={() => {
                            if (item.pivot?.subsidiary_id !== null && item.pivot?.subsidiary_id == getSubsidiaryStored()) {
                                updateFilteredData(item.id)
                                removeMembersFromGroup(item.id , item.pivot?.subsidiary_id)
                            }
                        }}>
                            <SvgRemoveIcon style={{ marginRight: '10px' }} />
                        </Box>}
                </Box>
            ))
                :
                    text !== '' && !subsidiaryDetails.name.toLowerCase().includes(text.toLowerCase()) && <Box className='select_user pointer'>
                        {t(translations.CHAT.USER_NOT_FOUND)}
                    </Box>
            }
        </Box>
    </>
}

export default GroupMemberList
