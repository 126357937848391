
import { authRoutes } from 'routes/route_names/authroutes';
import { userRoutes } from 'routes/route_names/userroutes';
import { companyRoutes } from 'routes/route_names/companyroutes';
import { professionalSettingsRoutes } from 'routes/route_names/professionalsettingsroutes';
import { dashboardRoutes } from 'routes/route_names/dashboardroutes';
import { showCaseRoutes } from 'routes/route_names/showsaseroutes';
import { publicRoutes } from 'routes/route_names/publicroutes';
import { vehicleRoutes } from 'routes/route_names/vehicleroutes';
export const routes = {
  authRoutes,
  userRoutes,
  companyRoutes,
  professionalSettingsRoutes,
  dashboardRoutes,
  showCaseRoutes,
  publicRoutes,
  vehicleRoutes,
};
