import { Box, Button } from '@mui/material'   
import ErrorIcon from '@mui/icons-material/Error';
// This is a custom toast message with actionable button 
 const ToastMessageWithButton   = ({type , message, click,buttonText}) =>{ 
    return  <div> 
                <Box sx={{display:'flex' ,color:'black' , alignItems:'center' }}>
                  {getIcon(type)}
                  <p>{message}</p>
                </Box>
                <Button onClick={click} sx={{margin:'6px', width:'calc(100% - 12px)', fontSize:'16px'  }} size='medium' variant='contained' >{buttonText}</Button>
            </div>
  }
 
// custom error icon according to the message type
  const getIcon = (type:any) =>{
    if(type === 'error'){
      return <ErrorIcon sx={{color:'rgba(255,0,49,255)' , margin:'16px 10px 16px 0', fontSize:'38px'}}/>
    }
  }
export default ToastMessageWithButton  