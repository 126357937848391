export enum StyleConstants {
  // NAV_BAR_HEIGHT = '4rem',
  NAV_BAR_HEIGHT = 'auto',
}

export const color = {
  primaryColor: '#607AFC',
  colorGray: '#E0E0E0',
  colorGray2: 'rgba(0,0,0,0.5)',
  colorGrayDark: '#808080',
  colorGrayFade: '#F7F7F7',
  colorBlack: '#000000',
  colorWhite: '#FFFFFF',
  colorSkyBlue: 'rgba(93, 120, 255, 0.14)',
  colorGreen: '#5ACD00',
  colorOrange: '#FF912C',
  colorRed: '#FF0000'
};
export const colorNew = {
  primaryColor: '#6579F4',
  primaryColorDark: '#384386',
  primaryColorFade: '#DCE1F4',
  colorYellow: '#FFB753',
  colorYellowLite: '#FBC623',
  colorRed: '#EC1C24',
  colorRedFade: '#FDE1E3',
  colorGrayDark: '#9E9E9E',
  colorGrayDark2: '#263238',
  colorGrayDark3: '#4B4B4C',
  colorGrayDark4: '#999',
  colorGray: '#F7F7F7',
  colorLightBlue: '#F1F1F9',
  colorGreenDark: '#28A745',
  colorGreen: '#7ABE44',
  colorWhite: '#FFFFFF',
  colorBlack: '#000000',
  colorFadedBlue: "#F0F2FE",
  colorGray2: '#DEDEDE',
  colorFadedGray: '#f3f3f3',
  colorFadedGray2: '#F2F2F2',
  colorYellow2: '#FBC623',
  colorGrayLight: '#F9FAFB',
  colorGrayButton: '#E5E5E5',
  colorGrayOnButton: '#666666',
  colorDisableFeild: '#edebe9',
  lightPrimaryColor: '#F1F1F9',
  lightGreenColor: '#71af3a',
  orangeColor: 'rgb(255, 145, 44)'
};

export const fontSizeName = {
  AdTitle: '20px'
};

export const fontSizeFamily = {
  FontFamilyAdTitle: 'roboto'
};


export const typography = {
  xstx: '12px',
  detx: '14px',
  smtx: '16px',
  h2tx: '32px',
  SFProDisplay: 'sfProDisplay',
  RobotFont: 'Roboto',
};
export const typographyTheme = {
  font7: '7px',
  font8: '8px',
  font9: '9px',
  font10: '10px',
  font11: '11px',
  font12: '12px',
  font13: '13px',
  font14: '14px',
  font15: '14px',
  font16: '16px',
  font18: '18px',
  font20: '20px',
  font25: '25px',
  font28: '28px',
  font30: '30px',
  font32: '32px',
  SFProDisplay: 'sfProDisplay',
  RobotFont: 'Roboto',
};

export const confirmPopStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 320,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
}
