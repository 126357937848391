import { Box } from '@mui/material';
import { ContentLoaderOver } from 'app/components/ContantLoader/ContentLoader'
import { getSubsidiaryStored } from 'config/variables';
import { translations } from 'locales/translations';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { routes } from 'routes/routes';
import { ApiPaths } from 'services/ApiPaths';
import { Toaster } from 'services/Toaster';
import Api from 'services/axios';
import { getSubsidiaryDetailsRequest } from 'store/actions/company-actions';

const StripeConnectComponent = () => {
    // hooks
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // current subsidiary id
    const subId = getSubsidiaryStored();

    // params from url 
    const scope = new URLSearchParams(location.search).get('scope');
    const code = new URLSearchParams(location.search).get('code');
    const subsidiaryId = new URLSearchParams(location.search).get('subsidiary_id');
    const error = new URLSearchParams(location.search).get('error');
    const errorDescription = new URLSearchParams(location.search).get('error_description');

    useEffect(() => {
        if (code !== undefined && !error) {
            // API call to connect stripe account with subsidiary_id 
            Api.post(ApiPaths.company.stripeConnect, {
                code: code,
                subsidiary_id: subId
            }).then((response) => {
                // redirect to company profile screen
                dispatch(getSubsidiaryDetailsRequest({ subsidiaryId: subId }))
                history.push(routes.companyRoutes.companyProfile)

            }).catch((error) => {
                // error handling
                const resp = error?.response;
                let error_message = '';
                if (resp.data?.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error
                } else {
                    error_message = resp?.data?.message
                }
                Toaster.error(error_message)
                history.push(routes.companyRoutes.companyProfile)

            });
        }

        if (error && errorDescription) {
            // if error in url from stripe then disply error toaster and redirect on company profile page
            const description = errorDescription.replace(/\+/g, ' ');
            if (error == 'access_denied') {
                // if error type access denied then display static message (only for translation purpose)
                // as we getting error message like (the+ user+denied +your+access)
                Toaster.error(t(translations.companyProfileScreen.USER_ACESS_DENIED))
                history.push(routes.companyRoutes.companyProfile)
                return
            }

            Toaster.error(description)
            history.push(routes.companyRoutes.companyProfile)
        }

    }, [])

    return (
        <Box>
            <ContentLoaderOver />
        </Box>

    )
}

export default StripeConnectComponent