import styled from "styled-components";

export const Wrapper = styled.div`
  Button{
    text-transform: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 29.16px;
    letter-spacing: 0.015em;
    padding: 8px;
    width: 100%;
  }
  .purchaseCredits{
    background-color: #000;
    color: white;
  }
  .soldOut{
    background-color: #CCCCCC;
    color: #666666;
  }
  .buyNow{
    background-color: #6579f4;
    color: white;
  }
  .reservationBlocked{
    margin: 5px 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    letter-spacing: 0.02em;
    color: #EC1C24;
    svg{
      margin-left: 5px;
      cursor: pointer;
      path{
        fill:#EC1C24;
      }
    }
    div{
      display: inline;
    }
  }
  .mt-10{
    margin-top: 24px;
  }
`