import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4";
import { envVariables } from "config/appConfig";

const useGaTracker = (isAgree) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    const googleAnalticsKey = envVariables.googleAnalyticKey;
    // Multiple products (previously known as trackers)
    ReactGA.initialize(googleAnalticsKey);
    setInitialized(true)
    if (!isAgree) {
      ReactGA.set({ anonymizeIp: true })
    }
    // }
  }, [])

  useEffect(() => {
    if (initialized) {
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [initialized, location])
}

export default useGaTracker