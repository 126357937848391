import 'locales/i18n';
import i18n from 'i18next';
export const validation = {
    //Dimension and weight
    length_number_cm: { min: 1000, max: 7000, required: false },
    width_number_cm: { min: 500, max: 3000, required: false },
    height_cm: { min: 500, max: 5000, required: false },
    wheelbase_cm: { min: 500, max: 5000, required: false },
    ground_clearance_cm: { min: 100, max: 1000, required: false },
    weight_empty_kg: { min: 50, max: 5000, required: false },
    weight_gross_kg: { min: 50, max: 5000, required: false },
    bike_weight_gross_kg: { min: 0, max: 50, required: false },
    payload_kg: { min: 50, max: 5000, required: false },
    roofload_kg: { min: 1, max: 500, required: false },
    //Body and Interior
    number_of_doors: { min: 1, max: 5, required: false },
    number_of_airbags: { min: 0, max: 15, required: false },
    number_of_seats: { min: 1, max: 20, required: false },
    number_of_seats_bike: { min: 1, max: 5, required: false },
    // trunk_volume_max_liter: { min: 100, max: 1000, required: false },
    // trunk_volume_min_liter: { min: 0 , required: false },

    // Condition and maintenance
    owner_number: { min: 0, max: 20, required: false },
    last_maintenance_km: { min: 0, max: 1200, required: false },

    // Register
    catalog_price: { min: 0, max: 9000000, required: false },
    ecological_bonus: { min: 0, max: 100000, required: false },

    // Engine and Environment
    vehicle_mileage: { min: 0, max: 9999999, required: true },
    cylinder_cc: { min: 1, max: 9999, required: false },
    couple_nm: { min: 10, max: 3000, required: false },
    number_of_speeds: { min: 1, max: 15, required: false },
    power_kw: { min: 0, max: 1471, required: false }, // here 1471 ( cv / 1.36 )
    power_cv: { min: 0, max: 2000, required: true },
    power_cv_fiscal: { min: 0, max: 99, required: false },
    angle_of_attack: { min: -50, max: 80, required: false },
    departure_angle: { min: 1, max: 90, required: false },
    tank_volume_liter: { min: 5, max: 1000, required: false },
    top_speed: { min: 20, max: 500, required: false },
    top_speed_bike: { min: 1, max: 125, required: false },
    "0_100": { min: 1, max: 20, required: false },
    number_of_cylinder: { min: 1, max: 20, required: false },

    power_kw_charging: { min: 1, max: 250, required: false },
    power_kw_charging_max: { min: 1, max: 250, required: false },
    charge_duration: { min: 1, max: 15000, required: false },
    battery_duration_hour: { min: 1, max: 1500, required: false },
    // standard_pollution: { min: 0, max: 32767, required: false },
    fuel_combined: { min: 1, max: 100, required: false },
    fuel_urban_liter: { min: 1, max: 100, required: false },
    fuel_extra_urban_liter: { min: 1, max: 1000, required: false },
    co2_emission: { min: 0, max: 350, required: false },
    number_of_motor: { min: 1, max: 6, required: false },
    // vehicle_classification_env: { min: 1, max: 9, required: false },
    battery_duration_km_highway: { min: 1, max: 5000, required: false },
    battery_duration_km_city: { min: 1, max: 5000, required: false },
    charge_duration_home_80: { min: 1, max: 181080, required: false },
    charge_duration_fast_80: { min: 1, max: 181080, required: false },
    charge_duration_battery: { min: 60, max: 180000, required: false },
    consumption_wh_per_km: { min: 0.01, max: 0.50, required: false },
    trunk_volume_liter: { min: 10, max: 1000, required: false },
    motor_power_watt: { min: 100, max: 1000, required: false },
    // Packing for bike
    packaging_width: { min: 10, max: 300, required: false },
    packaging_length: { min: 10, max: 300, required: false },
    packaging_height: { min: 10, max: 300, required: false },

    //step Body and Frame
    handlebar_height: { min: 73, max: 112, required: false },
    max_weight_allowed: { min: 10, max: 250, required: false },
    exclusive_tax_price: { min: 1, max: 99999999, required: false },

    // Battery
    number_of_battery: { min: 1, max: 5, required: false },
    battery_range_min: { min: 50, required: false },
    battery_range_max: { min: 1, max: 500, required: false },
    battery_range_max_car: { min: 1, max: 2000, required: false },
    battery_weight_kg: { min: 0.1, max: 20, required: false },
    capacity_battery_wh: { min: 50, max: 900, required: false },
    battery_charge_time_min: { min: 1, required: false },
    battery_charge_time_max: { max: 1000, required: false },
    public_price: { min: 1, max: 99999999, required: true },
    frame_material_id: { required: true },
    vehicle_last_control_tech_year: { min: 1901 },
    vehicle_first_register_year: { min: 1901 },

    //field counters
    vin: { min: 17 },
    licensePlate: { max: 12 },
    reference: { min: 17 },
    title: { min: 0, max: 60, required: true },
    ean: { max: 13 },
    discription: { max: 2000 },
    bidLimit: { max: 99999999 }
}

export const validationString = {
    length_number_cm: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.length_number_cm.min} ${i18n.t('ads.TO')} ${validation.length_number_cm.max}`,
    width_number_cm: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.width_number_cm.min} ${i18n.t('ads.TO')} ${validation.width_number_cm.max}`,
    height_cm: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.height_cm.min} ${i18n.t('ads.TO')} ${validation.height_cm.max}`,
    wheelbase_cm: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.wheelbase_cm.min} ${i18n.t('ads.TO')} ${validation.wheelbase_cm.max}`,
    ground_clearance_cm: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.ground_clearance_cm.min} ${i18n.t('ads.TO')} ${validation.ground_clearance_cm.max}`,
    weight_empty_kg: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.weight_empty_kg.min} ${i18n.t('ads.TO')} ${validation.weight_empty_kg.max}`,
    weight_gross_kg: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.weight_gross_kg.min} ${i18n.t('ads.TO')} ${validation.weight_gross_kg.max}`,
    bike_weight_gross_kg: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.bike_weight_gross_kg.min} ${i18n.t('ads.TO')} ${validation.bike_weight_gross_kg.max}`,
    max_weight_allowed: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.max_weight_allowed.min} ${i18n.t('ads.TO')} ${validation.max_weight_allowed.max}`,
    payload_kg: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.payload_kg.min} ${i18n.t('ads.TO')} ${validation.payload_kg.max}`,
    roofload_kg: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.roofload_kg.min} ${i18n.t('ads.TO')} ${validation.roofload_kg.max}`,
    number_of_doors: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.number_of_doors.min} ${i18n.t('ads.TO')} ${validation.number_of_doors.max}`,
    number_of_airbags: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.number_of_airbags.min} ${i18n.t('ads.TO')} ${validation.number_of_airbags.max}`,
    number_of_seats: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.number_of_seats.min} ${i18n.t('ads.TO')} ${validation.number_of_seats.max}`,
    number_of_seats_bike: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.number_of_seats_bike.min} ${i18n.t('ads.TO')} ${validation.number_of_seats_bike.max}`,
    // trunk_volume_max_liter: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.trunk_volume_max_liter.min} to ${validation.trunk_volume_max_liter.max}`,
    // trunk_volume_min_liter: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.trunk_volume_min_liter.min} to ${validation.trunk_volume_max_liter.max}`,
    owner_number: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.owner_number.min} ${i18n.t('ads.TO')} ${validation.owner_number.max}`,
    last_maintenance_km: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.last_maintenance_km.min} ${i18n.t('ads.TO')} ${validation.last_maintenance_km.max}`,
    catalog_price: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.catalog_price.min} ${i18n.t('ads.TO')} ${validation.catalog_price.max}`,
    ecological_bonus: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.ecological_bonus.min} ${i18n.t('ads.TO')} ${validation.ecological_bonus.max}`,
    vehicle_mileage: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.vehicle_mileage.min} ${i18n.t('ads.TO')} ${validation.vehicle_mileage.max}`,
    cylinder_cc: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.cylinder_cc.min} ${i18n.t('ads.TO')} ${validation.cylinder_cc.max}`,
    couple_nm: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.couple_nm.min} ${i18n.t('ads.TO')} ${validation.couple_nm.max}`,
    number_of_speeds: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.number_of_speeds.min} ${i18n.t('ads.TO')} ${validation.number_of_speeds.max}`,
    power_kw: ` ${i18n.t('FILTER_COMPONENT.FILTER_COMPONENT_KW')} ${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.power_kw.min} ${i18n.t('ads.TO')} ${validation.power_kw.max}`,
    power_cv: ` ${i18n.t('webTexts.POWER_CV_ERROR_MESSAGE')}`,
    power_cv_fiscal: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.power_cv_fiscal.min} ${i18n.t('ads.TO')} ${validation.power_cv_fiscal.max}`,
    angle_of_attack: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.angle_of_attack.min} ${i18n.t('ads.TO')} ${validation.angle_of_attack.max}`,
    departure_angle: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.departure_angle.min} ${i18n.t('ads.TO')} ${validation.departure_angle.max}`,
    tank_volume_liter: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.tank_volume_liter.min} ${i18n.t('ads.TO')} ${validation.tank_volume_liter.max}`,
    top_speed: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.top_speed.min} ${i18n.t('ads.TO')} ${validation.top_speed.max}`,
    top_speed_bike: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.top_speed_bike.min} ${i18n.t('ads.TO')} ${validation.top_speed_bike.max}`,
    "0_100": `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation["0_100"].min} ${i18n.t('ads.TO')} ${validation["0_100"].max}`,
    number_of_cylinder: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.number_of_cylinder.min} ${i18n.t('ads.TO')} ${validation.number_of_cylinder.max}`,
    power_kw_charging: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.power_kw_charging.min} ${i18n.t('ads.TO')} ${validation.power_kw_charging.max}`,
    power_kw_charging_max: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.power_kw_charging_max.min} ${i18n.t('ads.TO')} ${validation.power_kw_charging_max.max}`,
    charge_duration: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.ecological_bonus.min} ${i18n.t('ads.TO')} ${validation.ecological_bonus.max}`,
    battery_duration_hour: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.battery_duration_hour.min} ${i18n.t('ads.TO')} ${validation.battery_duration_hour.max}`,
    // standard_pollution: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.standard_pollution.min} to ${validation.standard_pollution.max}`,
    fuel_combined: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.fuel_combined.min} ${i18n.t('ads.TO')} ${validation.fuel_combined.max}`,
    fuel_urban_liter: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.fuel_urban_liter.min} ${i18n.t('ads.TO')} ${validation.fuel_urban_liter.max}`,
    fuel_extra_urban_liter: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.fuel_extra_urban_liter.min} ${i18n.t('ads.TO')} ${validation.fuel_extra_urban_liter.max}`,
    co2_emission: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.co2_emission.min} ${i18n.t('ads.TO')} ${validation.co2_emission.max}`,
    number_of_motor: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.number_of_motor.min} ${i18n.t('ads.TO')} ${validation.number_of_motor.max}`,
    // vehicle_classification_env: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.vehicle_classification_env.min} to ${validation.vehicle_classification_env.max}`,
    battery_duration_km_highway: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.battery_duration_km_highway.min} to ${validation.battery_duration_km_highway.max}`,
    battery_duration_km_city: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.battery_duration_km_city.min} ${i18n.t('ads.TO')} ${validation.battery_duration_km_city.max}`,
    consumption_wh_per_km: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.consumption_wh_per_km.min} ${i18n.t('ads.TO')} ${validation.consumption_wh_per_km.max.toPrecision(2)}`,
    trunk_volume_liter: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.trunk_volume_liter.min} ${i18n.t('ads.TO')} ${validation.trunk_volume_liter.max}`,
    battery_weight_kg: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.battery_weight_kg.min} ${i18n.t('ads.TO')} ${validation.battery_weight_kg.max}`,
    capacity_battery_wh: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.capacity_battery_wh.min} ${i18n.t('ads.TO')} ${validation.capacity_battery_wh.max}`,
    exclusive_tax_price: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.exclusive_tax_price.min} ${i18n.t('ads.TO')} ${validation.exclusive_tax_price.max}`,
    motor_power_watt: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.motor_power_watt.min} ${i18n.t('ads.TO')} ${validation.motor_power_watt.max}`,
    charge_duration_home_80: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} 1 minute ${i18n.t('ads.TO')} 50 hours`,
    charge_duration_fast_80: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} 1 minute ${i18n.t('ads.TO')} 50 hours`,
    charge_duration_battery: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')}  1 minute ${i18n.t('ads.TO')} 50 hours`,
    packaging_width: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.packaging_width.min} ${i18n.t('ads.TO')} ${validation.packaging_width.max}`,
    packaging_length: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.packaging_length.min} ${i18n.t('ads.TO')} ${validation.packaging_length.max}`,
    packaging_height: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.packaging_height.min} ${i18n.t('ads.TO')} ${validation.packaging_height.max}`,
    number_of_battery: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.number_of_battery.min} ${i18n.t('ads.TO')} ${validation.number_of_battery.max}`,
    battery_charge_time_min: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.battery_charge_time_min.min} ${i18n.t('ads.TO')} ${validation.battery_charge_time_max.max}`,
    battery_range_max: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.battery_range_max.min} ${i18n.t('ads.TO')} ${validation.battery_range_max.max}`,
    battery_range_max_car: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.battery_range_max_car.min} ${i18n.t('ads.TO')} ${validation.battery_range_max_car.max}`,
    battery_range_min: `${i18n.t('webTexts.VALUE_NOT_GREATER_THEN')} ${validation.battery_range_min.min}`,
    public_price: `${i18n.t('webTexts.VALUE_MUST_BE_BETWEEN')} ${validation.public_price.min} ${i18n.t('ads.TO')} ${validation.public_price.max}`,
    vehicle_last_control_tech_year: `${i18n.t('webTexts.VALUE_MUST_BE_GREATER_THAN')} ${validation.vehicle_last_control_tech_year.min}`,
    vehicle_first_register_year: `${i18n.t('webTexts.VALUE_MUST_BE_GREATER_THAN')} ${validation.vehicle_last_control_tech_year.min}`,
}
