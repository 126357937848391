/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Switch, Route, useLocation, useRouteMatch, matchPath, useParams, Redirect } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useSelector } from 'react-redux';
import { getSubsidiaryStored, removeLocalStorageScreen } from 'config/variables';
import { publicRouListing } from './pages/Routers/RouterListing';
import { NotFoundPage } from './pages/NotFoundPage';
import CookiesComponent from './Component2/GlobalComponent/CookiesComponent';
import ChooseVehiclePopup from './Component2/GlobalComponent/ChooseVehiclePopup';
import { RouteTypes, getParamQueries } from 'config/appConfig';
import { useDeviceType } from './Hooks/useMediaQuery';

const MainRouter = (props: any) => {
  const loggedIn = useSelector((state: any) => state.auth.loggedIn);
  const company = useSelector((state: any) => state.company);
  const location = useLocation();
  const { isCreateAdModalOpen } = useSelector((state: any) => state.publicReducer)
  const getSubsidiary = getSubsidiaryStored();

  React.useEffect(() => {
    if (location.pathname !== routes.vehicleRoutes.vehicleSummary) {
      localStorage.removeItem('validateData');
      localStorage.removeItem('vehicleId');
    }

    // Match Public And Auction Detail Page
    const publicAds: any = matchPath(location.pathname, {
      path: [
        routes.vehicleRoutes.publicAds + '/:slug',
        routes.vehicleRoutes.auctionDetails
      ],
    })
    const publicAds1: any = matchPath(location.pathname, {
      path: [
        routes.vehicleRoutes.adsDetails,
        routes.vehicleRoutes.publicAds
      ],
    })
    if (publicAds1 === null) {
      localStorage.removeItem('search');
    }
    if (publicAds === null) {
      removeLocalStorageScreen();
    }

  }, [location.pathname]);


  const getConditionalComponent = (item, loggedIn) => {
    let returnComponent = <item.component />;
    if (
      loggedIn &&
      item.type === RouteTypes.professional &&
      getSubsidiary &&
      getSubsidiary !== null
    ) {
      returnComponent = <item.component />;
    }
    else if (
      loggedIn &&
      (!getSubsidiary || getSubsidiary === undefined) &&
      item.type === RouteTypes.private
    ) {
      returnComponent = <item.component />;
    }
    else if (loggedIn && item.type === RouteTypes.professionPrivate) {
      returnComponent = <item.component />;
    }
    else if (item.type === RouteTypes.public) {
      returnComponent = <item.component />;
    }
    else if (!loggedIn && item.type === RouteTypes.auth) {
      returnComponent = <item.component />;
    }
    // Redirecting to login page if user tries to access private pages without logging in
    else if (!loggedIn && item.type !== RouteTypes.auth && publicRouListing.filter((routeObj) => routeObj.path.includes(location.pathname.split("/")[1])).length > 0) {
      returnComponent = <Redirect to={`${routes.authRoutes.login}?redirectTo=${encodeURIComponent(location.pathname)}`} />;
    }
    // Redirecting to page from where user was redirected to login page
    else if (loggedIn && item.type === RouteTypes.auth) {
      const payload = getParamQueries(location.search)
      returnComponent = <Redirect to={payload.redirectTo ? decodeURIComponent(payload.redirectTo) : "/"} />;
    }
    else if (!company.loading) {
      returnComponent = <NotFoundPage authrized={true} />;
    }
    return returnComponent;
  };
  const [isSmallDevice] = useDeviceType();
  return (
    <>
      <Switch>

        {/* {/ Redirect routes with en-GB in the middle to en /} */}
        <Route
          path="/:locale(en-GB|en-US|en-us|en-gb)/:rest*"
          render={({ match }) => {
            const { params } = match;
            const newPath = `/${params.rest || ''}`;
            return <Redirect to={newPath} />;
          }}
        />
        {publicRouListing.map((item: any, i: number) => (
          <Route key={item.path} exact path={item.path}>
            {getConditionalComponent(item, loggedIn)}
            {isCreateAdModalOpen ? <ChooseVehiclePopup /> : null}

          </Route>
        ))}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
      <CookiesComponent />
    </>
  );
};

export default MainRouter;
