import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";
import playBtn from '../NavigationHeader/Assets/playbuttonsvg.svg'

export const LayoutWrapper = styled.div`
position:relative;

/* css for profile popup */

.Login-form-container {
  left: 70%;
  top:-20px;
  position: absolute;
  width: 400px;
  background-color: #ffffff;
  padding: 20px 25px 25px 25px;
  z-index: 99;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  max-width: 400px;
  margin-left: auto;
  right: 10px;
  background: #fff;
  padding: 16px;
  height:400px;

}
.Login-form-container::after{
  content: "";
  position: absolute;
  top: -10px;
  left: 180px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
  z-index: 1,
}
.popup-pointer{
  position: absolute;
  width: 0px;
  height: 0px;
  border-bottom: 15px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -10px;
  right: 15px;
}

.popup-wraper{
  position: relative;
  max-width: 380px;
  min-width: 380px;
  margin-left: auto;
  background:#fff;
  border-Radius: 6px;
  //padding: 15px 0 0 0;
  top: 10px;
}
.profile-container{
  padding: 15px;
  border-bottom: 1px solid #eee 
}
.other-profile-container {
  padding: 0 15px;
  border-bottom: 1px solid #eee;
}
.other-profile-text{
  color: #666;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0
}
.other-profiles{
  max-height: 200px;
  overflow: scroll;
  color: #000;
}
.my-account-container,
.other-links{
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0px 15px;
  
}
.my-account-container {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}
.border{
      border-bottom: '1px solid #eee';
      padding: '16px'
}

.other-option {
  padding: 0px 0px 15px 0;
    cursor: pointer;
    background: transparent;
    border: none;
    text-align: left;
    font-size: 15px;
    color: #000;

}
.other-option svg{    
        margin-right: 5px
}

.main-nav{
  width:100%;
  background : white; 
  z-index: 100;
  top: 0;
  position: sticky;
}
.top-bar {
  padding: 0 15px;
  border-bottom: 1px solid #eaeaea;
  height: 75px;
}
.main-container {
  margin-top: 0px;
}
.site-logo{
  display: flex;
  justify-content: center;
  align-items:center;
}
.navbarContainer .site-logo .back-button svg {
  height:20px;
  width: 20px; 
}
.site-logo a svg {
  width: 200px; height: 50px;
}
 .back-button {
  height:32px;
  width: 32px;
  margin-right: 10px;
  background-color: #eaeaea;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.site-logo .back-button span {
  display: flex;
}

.loginBtn{
  padding: 6px 24px;
  white-space: nowrap;
  background-color: ${colorNew.primaryColor} !important;
  border-radius: 4px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  color: rgb(255, 255, 255);
  height: 48px;
  display: flex;
  align-items: center;
  gap:'10px !important';
}
.addProduct{
  display : flex;
  width:100%;
  height:48px;
  align-items : center;
  justify-content : center;
  margin-right : 10px;
  cursor : pointer;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 12px 50px;
  border:none;
  text-transform:none;
  width:max-content;
  background: #000 !important;
  color: #fff;
}
.addProduct span{
    font-weight: 500;
    width:max-content;
    /* color : ${colorNew.primaryColor}; */
    color: #FFF;
    font-size: 18px;
}
.imageWrapper{
  position: relative;
  display: flex;
  align-items:center;
}
.imageWrapper .image-1{
  width :48px;
  height :48px;
  object-fit: cover;
  border-radius: 50%;
}
.imageWrapper .image-2{
  width: 18px;
  height: 18px;
  object-fit: cover;
  border-radius: 16px;
  position: absolute;
  right: 0px;
  bottom: 5px;
  background: #EEE;
}
.user-info {
  margin-left: 8px;
}
.user-info .text-name{
    color: ${colorNew.colorBlack};
    display: block;
    white-space: nowrap;
    max-width: 250px;
    font-size:21px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}
.user-info .text-link{
    color: ${colorNew.primaryColor};
    display: block;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: underline;
    font-size:13px;
    font-weight:400;
}
.menuIcon{
  // width:45px;
  // height:35px;
  min-width: auto;
  /* border: 1px solid rgb(222, 222, 222); */
  background-color: transparent;
  margin-left: 25px;
  padding: 0
}
.languageContainer{
  border-radius: 4px;
  border: 1px solid var(--Shade-1, #384386);
  background: #132074;
}
.languageName{
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
}
.footerLinks a, .footerLinks span{
  text-transform :none;
  color : ${colorNew.colorWhite};
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  padding: 0px 8px 4px;
}
.footerLinks{
  margin-bottom :20px;
}
.seperator{
  color: #797979 !important;
}
.footerLogoContainer{
  max-width:400px;
}
.footerLogoContainer span{
  color: #FFF;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.26px;
}
.footerListContainer{
  min-width : 190px;
}
footerList{
  min-width: 60%;
  margin-left: 32px;
}
.footerMenu ul{
  margin :10px 0 0 0;
  padding :0 20px;
}
.footerMenu li{
  margin :10px 0 0 0;
  color: #CCC;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
  list-style : url(${playBtn});
  cursor : pointer;
}
.listHeader{
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}
ul li a span{
  margin :10px 0 0 0;
  color: #CCC;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
  list-style : url(${playBtn});
  cursor : pointer;
}
.footer-social{
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: flex-start;
}
.footer-justify{
  justify-content: space-between;
  max-width: 1440px;
}
.socialIcons{
  background :white;
  border-radius :5px;
  margin:0 20px 10px 0px;
  margin-bottom: 30px;
  cursor : pointer;
}
.socialIcons a{
  min-width : 0px;
  width: 45px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.checkbox{
   width:max-content; 
   color:${colorNew.colorGrayOnButton};
   height:56px;
   display:flex;
   align-items:center;
   span{
    font-size:18px;
   }
}
.checkbox svg{
   margin-right:7px;
}
.languageSwitch .flagIcon{
  width: 42px;
  height: 42px;
  border-radius: 100%;
  object-fit: cover;
} 
// ---------------------------------------------------- Media Queries ----------------------------------------------------

@media (max-width: 1300px) {
  .footer-social{
    justify-content: center;
  }
  .footer-justify{
    justify-content: space-around;
  }
  .footerLogoContainer{
    max-width:100%;
    align-items:center;
    text-align:center;
  }
  
}
@media (max-width: 300px) {
  .site svg{
    width: 188px;
    height : auto;
  }
}

@media (max-width: 768px) {
  .footerMenu{
    padding: 0 10px ;
  }
  .top-bar {
    height: 55px;
  }
}
@media (min-width: 767px) {
  .footerMenu{
  width: calc(100% - 60px);
}
}
@media (min-width: 301px) and (max-width: 992px) {
  .site svg{
    width: 220px;
    height : auto;
  }
  .imageWrapper .image-1{
    width :35px;
    height :35px;
    object-fit: cover;
    border-radius: 50%;
  }
  .imageWrapper .image-2{
    width: 13px;
    height: 13px;
  }
  .user-info .text-name{
    font-size:15px
  }
  .user-info .text-link{
    font-size:15px
  }
  .main-container {
  margin-bottom: 70px;
}  
}

@media (max-width : 425px ){
  .footer-social > .socialIcons {
     margin:0 8px 8px 8px;
     margin-bottom:25px;
  }
} 
@media (min-width: 1201px) {
  .site svg{
    width: 273px;
    height : auto;
  }
}
@media (max-width: 500px) {
  .footerList{
    width: 100%;
    margin-left : 0px;
  }
  .footerListContainer{
    min-width: 150px;
    width: 150px;
  }
}
`