import { useStripe } from '@stripe/react-stripe-js';
import { Box, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Strings, getSubsidiaryStored } from 'config/variables';
import Api from 'services/axios';
import { ApiPaths } from 'services/ApiPaths';
import { Toaster } from 'services/Toaster';
import { PERMISSIONS, hasPermissions } from 'config/permission';
import { RootStateOrAny, useSelector } from 'react-redux';
import { localstorageKey, paymentType } from 'config/constants';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from 'react';
import ToastMessageWithButton from 'app/components/ToastMessageWithButton';
import { routes } from 'routes/routes';


const CheckoutForm = ({ priceId, code = '', adId, _shouldUpdate, isPaymentNotAllowed, btnText, setAcceptanceError, localstorageKeys = '', hotdealAdId, codeApplied }) => {

    // hooks
    const stripe = useStripe();
    const { t } = useTranslation();
    const subsidiaryId = getSubsidiaryStored();
    const location = useLocation();
    const history = useHistory();

    //local states
    const [loading, setLoading] = useState<boolean>(false)

    // redux states
    const permissionList = useSelector((state: any) => state.user.permissionData)
    const { userData } = useSelector((state: RootStateOrAny) => state.user);
    const getPaymentIntentFromStorageCredit = localStorage.getItem(localstorageKey.makeCreditPaymentIntentId)
    const getPaymentIntentFromStorageReservation = localStorage.getItem(localstorageKey.hotdealReservationIntentId)

    // hooks
    useEffect(() => {
        if (getPaymentIntentFromStorageCredit && location.pathname.includes(routes.userRoutes.creditsPayment)) {
            // if paymnet intent in storage then 
            // request updated payment session
            Api.post(ApiPaths.user.update_session, {
                canceled_intent: getPaymentIntentFromStorageCredit,
            }).then(response => {
            }).catch(error => {
                // if error then dispay toaster
                Toaster.error(error?.response?.data?.message)
            }).finally(() => {
                // remove payment intent id from local storage
                localStorage.removeItem(localstorageKey.makeCreditPaymentIntentId)
            });

        }
        if (getPaymentIntentFromStorageReservation && location.pathname.includes(routes.userRoutes.orderReservation) && localStorage.getItem(localstorageKey.hotdealAdId) == hotdealAdId) {
            // if paymnet intent in storage then 
            // request updated payment session
            Api.post(ApiPaths.user.update_session, {
                canceled_intent: getPaymentIntentFromStorageReservation,
            }).then(response => {
            }).catch(error => {
                // if error then dispay toaster
                Toaster.error(error?.response?.data?.message)
            }).finally(() => {
                // remove payment intent id from local storage
                localStorage.removeItem(localstorageKey.hotdealReservationIntentId)
                localStorage.removeItem(localstorageKey.hotdealAdId)
            });

        }
    }, []);


    const handleMakePayment = (event) => {
        event.preventDefault();

        if (!stripe || loading) {
            // api request response is loading then retun
            return
        }

        if (isPaymentNotAllowed) {
            setAcceptanceError(true)
            return
        }

        if ((subsidiaryId && !hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.ADD))) {
            // display error toaster when user does not have sufficient permissions to make payment
            Toaster.error(t(translations.HOT_DEALS.INSUFFICIENT_PERMISSIONS))
            return true
        }

        if (userData?.profile_is_complete === false) {
            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.CHAT.COMPLETE_PROFILE_BUTTON)} message={Strings.completeProfileString} click={() => history.push(routes.userRoutes.userProfileEdit)} />, false)
            return
        }
        if (loading) {
            return
        }
        let url = location.pathname;
        if (location?.search) {
            url += location?.search;
        }
        localStorage.setItem(localstorageKey.paymentFailure, url)
        let uri = ApiPaths.user.create_session;
        let param = {};
        if (priceId) {
            // send price id or credit plans while purchasing new credits
            param['payment_type'] = paymentType.BUYCREDIT;
            param['price_id'] = priceId
        }
        if (code && codeApplied) {
            // discout coupons only when purchasing credits 
            param['code'] = code
        }
        if (adId) {
            // send ad id for reservation of hotdeals ad
            param['payment_type'] = paymentType.RESERVE_VEHICLE;
            param['ad_vehicle_id'] = adId
        }
        setLoading(true)
        if (_shouldUpdate) {
            _shouldUpdate(false)
        }
        // API call for create checkout session 
        Api.post(uri, param).then(res => {
            setLoading(false)
            if (res.data.data.id) {
                localStorage.setItem(localstorageKeys, res.data.data.id)
            }
            if (hotdealAdId) {
                localStorage.setItem(localstorageKey.hotdealAdId, hotdealAdId)
            }
            const result = stripe?.redirectToCheckout({
                sessionId: res.data.data.id,
            });
            if (result?.error) {
                Toaster.error(result.error.message);
            }
        })
            .catch(function (error) {
                setLoading(false);
                _shouldUpdate(true)
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                    if (resp.data.data.message) {
                        error_message = resp.data.data.message
                    } else {
                        {
                            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                        }
                    }
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message;
                }
                Toaster.error(error_message);
            });
    };
    const handleDisable = () => {
        // if subsidiary then check whether that subsidiary have permission for create payment or not
        if ((subsidiaryId && !hasPermissions(permissionList, PERMISSIONS.FEATURES.FINANCE, PERMISSIONS.ACTIONS.ADD)) || !stripe) {
            return true
        }

        return false
    }

    return (
        <form onSubmit={handleMakePayment} >
            <Button className={`payment-btn ${handleDisable() ? 'disable-payment-btn' : ''}`} variant='contained' type="submit" disabled={handleDisable()} >
                {loading ?
                    <>
                        <CircularProgress color='inherit' size='20px' sx={{ marginLeft: '10px' }} />
                    </>
                    :
                    <>
                        {btnText} <ArrowForwardIosIcon sx={{ height: '15px', marginLeft: '5px' }} /></>}
            </Button>
        </form >
    );
};
export default CheckoutForm;