import React, { useRef, useState } from 'react'
import { FieldWrapper } from './style'
import { Box, Drawer } from '@mui/material'
import { SvgMobileLeftArrow, SvgchevronIcon } from '../svgicons/svgNew'
import useOutsideAlerter from 'app/Hooks/outSideDiv'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { useDeviceType } from 'app/Hooks/useMediaQuery'

// Code for list of dropdown elements
const ListElement = ({ containerClass, listfirstField, firstFieldValue, listsecondField, secondFieldValue, handleFirstInput, handleSecondInput }) => {
    return (
        <>
            <Box className={`${containerClass} flexRow justifyContentBetween`} >
                <Box sx={{ width: '50%', px: 2 }} className='flexColumn OptionsSubContainer scrollBar' >
                    {listfirstField && listfirstField.length > 0 && listfirstField.map((option) => {
                        return <Box key={option} className='optionContainer nameContainer' sx={{ backgroundColor: firstFieldValue == option ? 'aliceblue' : '' }} onClick={() => handleFirstInput(option)}>
                            <span className='optionValue'>{option}</span>
                            <span className={(firstFieldValue == option ? 'selected' : '') + ' selectDiv'}></span>
                        </Box>
                    })}
                </Box>
                <Box sx={{ width: '50%', px: 2 }} className='flexColumn OptionsSubContainer scrollBar' >
                    {listsecondField && listsecondField.length > 0 && listsecondField.map((option) => {
                        return <Box key={option} className='optionContainer nameContainer' sx={{ backgroundColor: secondFieldValue == option ? 'aliceblue' : '' }} onClick={() => handleSecondInput(option)}>
                            <span className='optionValue'>{option}</span>
                            <span className={(secondFieldValue == option ? 'selected' : '') + ' selectDiv'}></span>
                        </Box>
                    })}
                </Box>
            </Box>
        </>
    )
}

const SearchDropdownMultipleInput = ({ title, labelFirstField, labelSecondField, listfirstField, listsecondField, handleFirstField, handleSecondField, firstFieldValue, secondFieldValue }) => {

    const { t } = useTranslation()

    const [openDropdown, _openDropdown] = useState<boolean>(false)

    const ref = useRef()
    const [isSmallDevice] = useDeviceType()

    // hook that closes dropdown when clicked outside the target
    useOutsideAlerter(ref, () => _openDropdown(false))

    const handleFirstInput = (val) => {
        handleFirstField(val)
        if (secondFieldValue) {
            _openDropdown(false)
        }
    }

    const handleSecondInput = (val) => {
        handleSecondField(val)
        if (firstFieldValue) {
            _openDropdown(false)
        }
    }

    return (
        <FieldWrapper>
            <Box className='field' sx={{ p: '0 8px 0 16px' }} ref={ref}>
                <span className='fieldHeader'>{title}</span>
                <Box
                    className='flexRow alignItemsCenter multipleDropdownMainContainer justifyContentBetween pointer'
                    onClick={() => _openDropdown((previousState) => !previousState)}
                    sx={{
                        'svg': {
                            'path': {
                                stroke: '#666'
                            }
                        }
                    }}
                >
                    <Box className='flexRow searchMultipleFieldContainer alignItemsCenter multipleDropdownContainer ' sx={{ minHeight: '50px' }} >
                        {
                            openDropdown || (firstFieldValue !== undefined && firstFieldValue !== null) || (secondFieldValue !== undefined && secondFieldValue !== null) ?
                                <>
                                    <Box>
                                        <span>{firstFieldValue ?? labelFirstField}</span>
                                    </Box>
                                    {((firstFieldValue !== undefined && firstFieldValue !== null) || (secondFieldValue !== undefined && secondFieldValue !== null)) && <span>to</span>}
                                    <Box>
                                        <span>{secondFieldValue ?? labelSecondField}</span>
                                    </Box>
                                </> :
                                <span className='selectedValue' style={{ fontSize: '18px' }}>{t(translations.webTexts.NOT_SELECTED)}</span>
                        }
                    </Box>
                    <SvgchevronIcon />
                </Box>
                {isSmallDevice ?
                    <Drawer
                        anchor='bottom'
                        open={openDropdown}
                        onClose={() => _openDropdown(false)}
                        sx={{
                            '.MuiPaper-root': {
                                height: "70vh",
                                borderRadius: '6% 6% 0 0',
                                overflow: 'hidden',
                            },
                        }}
                    >
                        <FieldWrapper>
                            <Box className='drawerTitleStyle'>
                                <SvgMobileLeftArrow onClick={() => _openDropdown(false)} />
                                {title}
                                <SvgMobileLeftArrow style={{ opacity: 0 }} />
                            </Box>
                            <ListElement containerClass='' listfirstField={listfirstField} firstFieldValue={firstFieldValue} listsecondField={listsecondField} secondFieldValue={secondFieldValue} handleFirstInput={handleFirstInput} handleSecondInput={handleSecondInput} />
                        </FieldWrapper>
                    </Drawer>
                    :
                    openDropdown && <ListElement containerClass='OptionsContainerMultipleField' listfirstField={listfirstField} firstFieldValue={firstFieldValue} listsecondField={listsecondField} secondFieldValue={secondFieldValue} handleFirstInput={handleFirstInput} handleSecondInput={handleSecondInput} />
                }
            </Box>
        </FieldWrapper >
    )
}

export default SearchDropdownMultipleInput