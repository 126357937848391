import { localstorageKey, types } from 'config/constants';
import { put, takeEvery } from 'redux-saga/effects';
import { getShopRequest, getShopSuccess, getShopFailure, sliderHomePageSuccess, sliderHomePageFailure, sliderHomePageRequest, verifyPromoRequest, verifyPromoSuccess, verifyPromoFailure, activeLanguagesRequest, activeLanguagesSuccess, activeLanguagesFailure, sendPromoRequest, sendPromoSuccess, sendPromoFailure, legalDocumentSuccess, legalDocumentFailure, legalDocumentRequest, legalDocumentUpdateRequest, legalDocumentUpdateFailure, legalDocumentUpdateSuccess, getPartnerOptionFailure, getPartnerOptionRequest, PartnersRequest, PartnersFailure, PartnersSuccess, getPartnerOptionSuccessSalutation, getPartnerOptionSuccessSubject, GetCountriesRequest, GetCountriesSuccess, GetCountriesFailure, getHomePageDataFailure, getHomePageDataSuccess, getHomePageDataRequest, getPublicAdsSuccess, getPublicAdsFailure, getPublicAdsRequest, getPublicAuctionAdsRequest, getPublicAuctionAdsSuccess, getPublicHotdealAdsFailure, getPublicHotdealAdsSuccess, getPublicHotdealAdsRequest, getHotdealHomeDataSuccess, getHotdealHomeDataRequest, getHotdealHomeDataFailure } from 'store/actions/public-actions';
import { ApiPaths } from '../../services/ApiPaths';
import Api from '../../services/axios';
import { getPublicAuctionAddsFailure } from 'store/actions/vehicle-actions';


function* _getHomePageDataRequest() {
  try {
    const uri = ApiPaths.public.adVehicles + ApiPaths.public.home;
    const { data } = yield Api.get(uri);
    yield put(getHomePageDataSuccess(data.data))
  } catch (e) {
    yield put(getHomePageDataFailure(e.response));
  }
}

function* _getHotdealHomeDataRequest({ payload }) {
  try {
    let uri = ApiPaths.public.home + ApiPaths.public.hotdeals;
    if ("country_slug" in payload) {
      uri += "?filter[country_slug]=" + payload.country_slug
    }
    const { data } = yield Api.get(uri);
    yield put(getHotdealHomeDataSuccess(data.data))
  } catch (e) {
    yield put(getHotdealHomeDataFailure(e.response.data));
  }
}

function* _getShopRequest({ payload }) {
  try {
    let slug = payload.slug;
    const uri = ApiPaths.public.shop + '/' + slug;
    const { data } = yield Api.get(uri);
    yield put(getShopSuccess(data.data))
  } catch (e) {
    yield put(getShopFailure(e.response));
  }
}

function* _sliderHomePageRequest({ payload }) {
  try {
    let uri = ApiPaths.public.silder + '?';
    if ("country_id" in payload) {
      uri += "filter[country_id]=" + payload.country_id
    }
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(sliderHomePageSuccess(row));
  } catch (e) {
    yield put(sliderHomePageFailure(e.response));
  }
}

function* _activeLanguageRequest({ payload }) {
  try {
    let uri = ApiPaths.public.languages;
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(activeLanguagesSuccess(row));
  } catch (e) {
    yield put(activeLanguagesFailure(e.response.data));
  }
}

function* _verifyPromoRequest({ payload }) {
  try {
    let uri = ApiPaths.public.verifyNomadPromo + '/';
    if ("promo" in payload) {
      uri += "?promo_code=" + payload.promo
    }
    if ("userCode" in payload && "promo" in payload) {
      uri += "&user_code=" + payload.userCode
    }
    else {
      if ("userCode" in payload) {
        uri += "?user_code=" + payload.userCode
      }
    }
    const { data } = yield Api.get(uri, {});
    const row = data.data
    yield put(verifyPromoSuccess(row));
  } catch (e) {
    yield put(verifyPromoFailure(e.response.data));
  }
}

function* _sendPromoRequest({ payload }) {
  try {
    let uri = ApiPaths.public.sendPromo;
    const { data } = yield Api.post(uri, payload);
    const row = data.data
    yield put(sendPromoSuccess(row));
  } catch (e) {
    yield put(sendPromoFailure(e.response.data));
  }
}

function* _legalDocumentRequest({ payload }) {
  try {
    let uri = ApiPaths.public.legalDocuments;

    if ("time" in payload) {
      uri += "/" + payload.time
    }
    if ("type" in payload) {
      // change privacy-policy into privacy_policy
      let payloadSplit = payload.type.split('-').join('_')
      uri += "?type=" + payloadSplit
    }
    const { data } = yield Api.get(uri, {});
    const row = data
    yield put(legalDocumentSuccess(row));
  } catch (e) {

    yield put(legalDocumentFailure(e.response));
  }
}
function* _legalDocumentUpdateRequest({ payload }) {
  try {
    const params: any = {}
    let uri = ApiPaths.public.legalDocuments + '?';
    if ("type" in payload) {
      uri += "type=" + payload.type
    }
    if ("time" in payload) {
      params['start_date'] = payload.time
    }
    const { data } = yield Api.get(uri, params);
    const row = data
    yield put(legalDocumentUpdateSuccess(row));
  } catch (e) {

    yield put(legalDocumentUpdateFailure(e.response));
  }
}

function* _getPartnerOptionRequest({ payload }) {
  try {
    const SalutationType = 1;
    let uri = ApiPaths.public.partner_options + `?type=${payload.type}`;
    const { data } = yield Api.get(uri, {});
    const row = data
    if (payload.type === SalutationType) {
      yield put(getPartnerOptionSuccessSalutation(row));
    } else {
      yield put(getPartnerOptionSuccessSubject(row));
    }
  } catch (e) {
    yield put(getPartnerOptionFailure(e.response));
  }
}

function* _PartnersRequest({ payload }) {
  try {

    let uri = ApiPaths.public.parnters;
    const { data } = yield Api.post(uri, payload);
    const row = data
    yield put(PartnersSuccess(row));
  } catch (e) {
    yield put(PartnersFailure(e.response));
  }
}

function* _GetCountriesRequest({ payload }) {
  try {
    let uri = ApiPaths.common.countries + "/?continent=all";
    const { data } = yield Api.get(uri);
    const item = data.data.items
    localStorage.setItem(localstorageKey.countries, JSON.stringify(item))
    yield put(GetCountriesSuccess(item));
  } catch (e) {
    yield put(GetCountriesFailure(e.response));
  }
}


// Classic Ads
function* _getPublicAdsRequest({ payload }) {
  try {
    let uri = generateURI(ApiPaths.vehicles.addVehicles + "?filter[type_of_sale]=0", payload);
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getPublicAdsSuccess(row));
  } catch (e) {
    yield put(getPublicAdsFailure(e.response));
  }
}

// Auction Ads
function* _getPublicAuctionAdsRequest({ payload }) {
  try {
    let uri = generateURI(ApiPaths.vehicles.addVehicles + "?filter[type_of_sale]=1", payload);
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getPublicAuctionAdsSuccess(row));
  } catch (e) {
    yield put(getPublicAuctionAddsFailure(e.response));
  }
}

// Hot deals Ads
function* _getPublicHotdealAdsRequest({ payload }) {
  try {
    let uri = generateURI(ApiPaths.vehicles.addVehicles + `?filter[type_of_sale]=3${!payload.hideSold ? '&withSold=1' : ''}`, payload);
    const { data } = yield Api.get(uri);
    const row = data.data
    yield put(getPublicHotdealAdsSuccess(row));
  } catch (e) {
    yield put(getPublicHotdealAdsFailure(e.response));
  }
}

const generateURI = (uri, payload) => {
  if ("subsidiary_slug" in payload) {
    uri += "&filter[subsidiary_slug]=" + payload.subsidiary_slug
  }
  if ("hotdeal_id" in payload) {
    uri += "&filter[hotdeal_id]=" + payload.hotdeal_id
  }
  if ("ad_auction_active" in payload) {
    uri += "&filter[ad_auction_active]=" + payload.ad_auction_active
  }
  if ("ad_hotdeal_active" in payload) {
    uri += "&filter[ad_hotdeal_active]=" + payload.ad_hotdeal_active
  }
  if ("hotdeal_active_or_upcoming" in payload) {
    uri += "&filter[hotdeal_active_or_upcoming]=" + payload.hotdeal_active_or_upcoming
  }
  if ("auction_id" in payload) {
    uri += "&filter[auction_id]=" + payload.auction_id
  }
  if ("page" in payload) {
    uri += "&page=" + payload.page
  }
  if ("product_type_id" in payload) {
    uri += "&filter[product_type_id]=" + payload.product_type_id
  }
  if ("keyword" in payload) {
    uri += "&filter[keyword]=" + payload.keyword
  }
  if ("sort" in payload) {
    uri += "&sort=" + payload.sort
  }
  // --------------------Slug Filters--------------------
  if ("brand_slug" in payload) {
    uri += "&filter[brand_slug]=" + payload.brand_slug
  }
  if ("model_slug" in payload) {
    uri += "&filter[model_slug]=" + payload.model_slug
  }
  if ("country_slug" in payload) {
    uri += "&filter[country_slug]=" + payload.country_slug
  }

  // -------------------- id ----------------------------
  if ("brand_id" in payload) {
    uri += "&filter[brand_id]=" + payload.brand_id
  }
  if ("model_id" in payload) {
    uri += "&filter[model_id]=" + payload.model_id
  }
  // --------------------Key Data Filter--------------------
  if ("new_vehicle" in payload) {
    uri += "&filter[new_vehicle]=" + payload.new_vehicle
  }
  if ("registration_start" in payload) {
    uri += "&filter[registration_start]=" + payload.registration_start
  }
  if ("registration_end" in payload) {
    uri += "&filter[registration_end]=" + payload.registration_end
  }
  if ("body_vehicle_id" in payload) {
    uri += "&filter[body_vehicle_id]=" + payload.body_vehicle_id
  }
  if ("outside_color_id" in payload) {
    uri += "&filter[outside_color_id]=" + payload.outside_color_id
  }
  if ("vehicle_mileage_start" in payload) {
    uri += "&filter[vehicle_mileage_start]=" + payload.vehicle_mileage_start
  }
  if ("vehicle_mileage_end" in payload) {
    uri += "&filter[vehicle_mileage_end]=" + payload.vehicle_mileage_end
  }
  if ("gearbox_id" in payload) {
    uri += "&filter[gearbox_id]=" + payload.gearbox_id
  }
  if ("fuel_id" in payload) {
    uri += "&filter[fuel_id]=" + payload.fuel_id
  }
  if ("frame_size_id" in payload) {
    uri += "&filter[frame_size_id]=" + payload.frame_size_id
  }
  // --------------------Price Filter--------------------
  if ("price_start" in payload) {
    uri += "&filter[price_start]=" + payload.price_start
  }
  if ("price_end" in payload) {
    uri += "&filter[price_end]=" + payload.price_end
  }
  if ("deductible_vat" in payload) {
    uri += "&filter[deductible_vat]=" + payload.deductible_vat
  }
  // --------------------Performance Filter--------------------
  if ("power_cv_start" in payload) {
    uri += "&filter[power_cv_start]=" + payload.power_cv_start
  }
  if ("power_cv_end" in payload) {
    uri += "&filter[power_cv_end]=" + payload.power_cv_end
  }
  if ("power_kw_start" in payload) {
    uri += "&filter[power_kw_start]=" + payload.power_kw_start
  }
  if ("power_kw_end" in payload) {
    uri += "&filter[power_kw_end]=" + payload.power_kw_end
  }
  if ("euro_standard_level" in payload) {
    uri += "&filter[euro_standard_level]=" + payload.euro_standard_level
  }
  if ("battery_range_max" in payload) {
    uri += "&filter[battery_range_max]=" + payload.battery_range_max
  }
  if ("charge_duration_home_80_end" in payload) {
    uri += "&filter[charge_duration_home_80_end]=" + payload.charge_duration_home_80_end
  }
  if ("charge_duration_fast_80_end" in payload) {
    uri += "&filter[charge_duration_fast_80_end]=" + payload.charge_duration_fast_80_end
  }
  // --------------------Electric Filter--------------------
  if ("is_electric" in payload) {
    uri += "&filter[is_electric]=" + payload.is_electric
  }
  if ("top_speed" in payload) {
    uri += "&filter[top_speed]=" + payload.top_speed
  }
  if ("motor_power_watt" in payload) {
    uri += "&filter[motor_power_watt]=" + payload.motor_power_watt
  }
  // --------------------History Filter--------------------
  if ("owner_number" in payload) {
    uri += "&filter[owner_number]=" + payload.owner_number
  }
  if ("maintenance_history" in payload) {
    uri += "&filter[maintenance_history]=" + payload.maintenance_history
  }
  // --------------------Equiments Filter--------------------
  if ("seats_start" in payload) {
    uri += "&filter[seats_start]=" + payload.seats_start
  }
  if ("seats_end" in payload) {
    uri += "&filter[seats_end]=" + payload.seats_end
  }
  if ("interior_id" in payload) {
    uri += "&filter[interior_id]=" + payload.interior_id
  }
  if ("equipment_id" in payload) {
    uri += "&filter[equipment_id]=" + payload.equipment_id
  }
  // --------------------Seller and Warranty Filter--------------------
  if ("dealer_seller" in payload) {
    uri += "&filter[dealer_seller]=" + payload.dealer_seller
  }
  if ("private_seller" in payload) {
    uri += "&filter[private_seller]=" + payload.private_seller
  }
  if ("warranty_start" in payload) {
    uri += "&filter[warranty_start]=" + payload.warranty_start
  }
  if ("with_media" in payload) {
    uri += "&filter[with_media]=" + payload.with_media
  }
  if ('except_ad_id' in payload)
    uri += "&filter[except_ad_id]=" + payload.except_ad_id

  if ('per_page' in payload) {
    uri += '&per_page=' + payload.per_page;
  }
  else uri += "&per_page=" + 20
  return uri
}


export const publicSaga = [
  takeEvery(getHomePageDataRequest, _getHomePageDataRequest),
  takeEvery(getShopRequest, _getShopRequest),
  takeEvery(verifyPromoRequest, _verifyPromoRequest),
  takeEvery(activeLanguagesRequest, _activeLanguageRequest),
  takeEvery(sliderHomePageRequest, _sliderHomePageRequest),
  takeEvery(sendPromoRequest, _sendPromoRequest),
  takeEvery(legalDocumentRequest, _legalDocumentRequest),
  takeEvery(legalDocumentUpdateRequest, _legalDocumentUpdateRequest),
  takeEvery(getPartnerOptionRequest, _getPartnerOptionRequest),
  takeEvery(PartnersRequest, _PartnersRequest),
  takeEvery(GetCountriesRequest, _GetCountriesRequest),
  takeEvery(getPublicAdsRequest, _getPublicAdsRequest),
  takeEvery(getPublicAuctionAdsRequest, _getPublicAuctionAdsRequest),
  takeEvery(getPublicHotdealAdsRequest, _getPublicHotdealAdsRequest),
  takeEvery(getHotdealHomeDataRequest, _getHotdealHomeDataRequest),
];