import styled from 'styled-components';
import { colorNew } from 'styles/StyleConstants';

export const DetailsWrapper = styled.div`
.detail-grid{
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}
.price-grid{
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}
.price-color-grid{
  flex: 3;
}
.identification-grid{
  flex:1
}

.price-box{
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: flex-start;
}
.color-name{
  display: flex;
  align-items: center;
  column-gap: 10px;  
}
.color-name p{
  margin  : 0px;
  color:${colorNew.colorGrayOnButton};
}
.text-style {    
    padding: 10px;
    height: 42px;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    letter-spacing: 0.36px;
    justify-content: space-between;
    }
  .identification-tab {
      justify-content: space-between;
      column-gap: 20px;
    }
    .list-of-identification{            
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.36px;
      color: ${colorNew.colorGrayOnButton};
      flex: 1,
    }
    
  .grid_color_odd div:nth-child(odd) {
      background-color: #FAFAFF
  }
  .grid_color_even div:nth-child(even) {
      background-color: #FAFAFF
  }
`;