import { Box } from '@mui/material'
import { FieldWrapper } from 'app/components/CustomFields/style'
import { SvgchevronIcon } from 'app/components/svgicons/svgNew'
import { translations } from 'locales/translations'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Selectequipment = ({ showEquipments, setShowEquipments, selectedEquipments, equipmentList }) => {

    const { t } = useTranslation()

    const [showValue, _showValue] = useState<string>('')

    // Generating text to show as selected value in field
    useEffect(() => {
        if (selectedEquipments && selectedEquipments.length > 0 && equipmentList && equipmentList.length > 0) {
            let str = ''
            selectedEquipments.forEach(options => {
                let existence = equipmentList.findIndex((option) => option.id == options)
                if (existence != -1) {
                    str = str == '' ? equipmentList[existence].option_name : str + ", " + equipmentList[existence].option_name
                }
            });
            _showValue(str)
            return
        }
        _showValue('')
    }, [selectedEquipments])

    return (
        <FieldWrapper>
            <Box className='field flexRow alignItemsCenter' sx={{ 'svg': { 'path': { stroke: '#666' } } }}>
                <span className='fieldHeader'>{t(translations.FILTER_COMPONENT.FILTER_COMPONENT_EQUIPMENTS)}</span>
                <Box className='inputBox flexRow justifyContentBetween alignItemsCenter pointer' sx={{ p: '0 8px 0 16px', width: '100%' }} onClick={() => setShowEquipments(!showEquipments)}>
                    <span className='selectedValue'>
                        {showValue ? showValue : t(translations.webTexts.NOT_SELECTED)}
                    </span>
                    <SvgchevronIcon />
                </Box>
            </Box>
        </FieldWrapper>
    )
}

export default Selectequipment