import React, { useEffect, useState, useMemo } from 'react'
import { Box, ButtonGroup, Skeleton, Tooltip } from '@mui/material'
import { useWindowSize } from 'app/Hooks/windowResize'
import { SvgMobile, SvgMobileLeftArrow, SvgPhoneIcon, SvgfluentIcon } from 'app/components/svgicons/svgNew'
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';
import { Rating } from 'react-simple-star-rating'
import { Link, useHistory, useParams } from 'react-router-dom';
import { routes } from 'routes/routes';
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom';
import { SvgNotification, SvgNotificationIconNotAllowd, SvgUserAdd } from 'app/components/svgicons/svg';
import { SvgExternalLink } from 'app/components/svgicons/svg2';
import ChatIcon from 'app/pages/Chat/Component/ChildComponents/ChatIcon';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Followers from '../ChildComponents/Followers';
import ShareProfile from '../ChildComponents/ShareProfile';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { chatCases, createStorechat } from 'config/chatConfig';
import { followUnfollowRequest, followUnfollowSuccess } from 'store/actions/publicProfile-action';
import * as publicProfileConstants from "config/publicProfileConfig"
import { Strings, getSubsidiaryStored } from 'config/variables';
import { Toaster } from 'services/Toaster';
import ToastMessageWithButton from 'app/components/ToastMessageWithButton';
import { lan } from 'locales/multiPageTranslation/adsLan';
import { Helmet } from 'react-helmet-async';
import AccountVerifiedIcon from 'app/Component2/GlobalComponent/AccountVerifiedIcon ';
import Api from 'services/axios';
import { ApiPaths } from 'services/ApiPaths';

const appBannerHeight = 60;
const UserInformation = ({ isNavFixedTop }) => {

    const [mobile, width] = useWindowSize()
    const { t } = useTranslation()

    const history = useHistory()
    const dispatch = useDispatch()

    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth)
    const { profileSuccessData, followUnfollowSuccessData, profileLoading } = useSelector((state: RootStateOrAny) => state.publicProfile)
    const { isAppBarOpen } = useSelector((state: any) => state.publicReducer);
    const { userData } = useSelector((state: any) => state.user)
    const { userType, slug } = useParams()
    // Followers
    const [followed, setFollowed] = useState<boolean>(false)
    const [followers, setFollowers] = useState<number>(0)
    const [following, setFollowing] = useState<number>(0)
    const [allowNotification, setAllowNotification] = useState<boolean>(false)
    const [disableBtn, setDisableBtn] = useState<boolean>(false)
    const [followedCurrentProfile, _followedCurrentProfile] = useState<boolean>(false)

    const [showButtons, _showButton] = useState<boolean>(true)


    const phoneNumber = useMemo(() => {
        let phoneNo = ''
        if (profileSuccessData?.limited_address?.prefix_call && (profileSuccessData.phone || profileSuccessData.phone_principal)) {
            phoneNo = profileSuccessData.limited_address.prefix_call + " " + (profileSuccessData.phone || profileSuccessData.phone_principal)
        }
        return phoneNo
    }, [profileSuccessData])

    useEffect(() => {
        if (!userData || Object.keys(userData).length === 0) {
            return
        }
        if (loggedIn && profileSuccessData && Object.keys(profileSuccessData).length > 0) {
            setAllowNotification(profileSuccessData?.receive_notification ? true : false)
            // Comparing id's of logged in user and public profile page's id when logged in as private user
            if (userData.id == profileSuccessData.id && !getSubsidiaryStored()) {
                _showButton(false)
                return
            }
            // Comparing id's of logged in subsidiary and public profile page's id when logged in as subsidiary user
            if (getSubsidiaryStored() && getSubsidiaryStored() == profileSuccessData.id) {
                _showButton(false)
                return
            }
            _showButton(true)
        }
    }, [profileSuccessData.id, slug, getSubsidiaryStored(), userData])

    // Updating follow/unfollow value when user gets followed/unfollowed
    useEffect(() => {
        if ((followedCurrentProfile) && followUnfollowSuccessData && Object.keys(followUnfollowSuccessData).length > 0) {
            // updating followers count if getting followed or unfollowed
            if (followed) {
                setFollowers(prevFollowers => prevFollowers - 1)
            }
            else if (!followed) {
                setFollowers(prevFollowers => prevFollowers + 1)
            }
            setFollowed(prevFollowers => !prevFollowers)
            dispatch(followUnfollowSuccess({}))
            _followedCurrentProfile(false)
        }
    }, [followUnfollowSuccessData])


    // Updating following count if user opens his profile and follows/unfollows any user 
    useEffect(() => {
        if (!showButtons && followUnfollowSuccessData && Object.keys(followUnfollowSuccessData).length > 0) {
            if (followUnfollowSuccessData.data.status) {
                setFollowing(following => following + 1)
                dispatch(followUnfollowSuccess({}))
                return
            }
            setFollowing(following => following - 1)
            dispatch(followUnfollowSuccess({}))
        }
    }, [followUnfollowSuccessData])

    // Setting initial value for follow/unfollow button
    useEffect(() => {
        if (profileSuccessData && Object.keys(profileSuccessData).length > 0) {
            setFollowed(profileSuccessData.is_follow)
            setFollowers(profileSuccessData.followers_count)
            setFollowing(profileSuccessData.following_count)
        }
    }, [profileSuccessData])


    // initiating chat
    const handleChat = (receiverId, accountType) => {
        if (loggedIn) {
            createStorechat(receiverId, chatCases.PERSONAL_CHAT, dispatch, accountType)
        }
    }


    // Follow /unfollow request
    const handleFollow = () => {
        _followedCurrentProfile(true)
        if (!loggedIn) {
            history.push(routes.authRoutes.login)
            return;
        }
        if (profileLoading) {
            return;
        }
        if (userData?.profile_is_complete === false && !getSubsidiaryStored()) {
            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.CHAT.COMPLETE_PROFILE_BUTTON)} message={Strings.completeProfileString} click={() => history.push(routes.userRoutes.userProfileEdit)} />, false)
            return;
        }
        if (!followed) {
            setAllowNotification(false)
        }
        let payload = {
            'status': !followed
        }
        if (userType == publicProfileConstants.profile.pro) {
            payload['profile_subsidiary_id'] = profileSuccessData.id
        }
        if (userType == publicProfileConstants.profile.user) {
            payload['profile_user_id'] = profileSuccessData.id
        }
        dispatch(followUnfollowRequest(payload))
    }


    const handlePushNotification = () => {
        if (disableBtn) {
            return
        }
        setDisableBtn(true)
        let payload = {
            "status": allowNotification ? 0 : 1,
            "profile_user_id": profileSuccessData.id
        }

        if (userType === publicProfileConstants.profile.pro) {
            payload["profile_subsidiary_id"] = profileSuccessData.id
            delete payload["profile_user_id"]
        }
        //API call for manage pushnotifications
        Api.post(ApiPaths.public.manage_natification, payload).then((response) => {
            setDisableBtn(false)
            if (response.data.success) {
                setAllowNotification((prev) => !prev)
            }
        }).catch((error) => {
            setDisableBtn(false)
            const resp = error.response;
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {
                    Object.keys(resp.data.errors).map(
                        (error, index) => (error_message = resp.data.errors[error][0])
                    );
                }
            } else if (resp.data.data?.error !== undefined) {
                error_message = resp.data.data.error;
            } else if (resp.data?.error !== undefined) {
                error_message = resp.data.error
            } else {
                error_message = resp.data.message
            }
            setDisableBtn(true)

            Toaster.error(error_message)
        })
    }
    return (
        <Box className='userDetailsContainer'>

            <Box sx={{ margin: '10px', border: (!mobile ? '1px solid rgb(239 239 239)' : 'none') }}>

                <Box className='flexRow justifyContentCenter userImageContainer'>
                    <Box className='userDetailsSubContainers userImageBox'>
                        <img src={profileSuccessData.medium_profile_picture_url || userPlaceHolder} alt='userimg' className='userImage' />
                        {/* Flag appears only when limited address is not null */}
                        {(profileSuccessData && profileSuccessData.limited_address && profileSuccessData.limited_address.flag_thumbnail) && <img src={profileSuccessData.limited_address.flag_thumbnail} alt='userimg' className='userFlag' />}
                    </Box>
                </Box>


                <Box className='flexRow justifyContentCenter'>
                    <h1 className="userName">{profileSuccessData.pseudo || profileSuccessData.name || (profileLoading ? <Skeleton variant="rectangular" width={150} height={32} /> : t(translations.CHAT.ANONYMOUS))}</h1>
                    <AccountVerifiedIcon isVerified={profileSuccessData.profile_is_verified} iconStyles={{ marginTop: '20px' }} />
                </Box>


                <Box className='flexRow justifyContentCenter alignItemsCenter' style={{ margin: "10px 0" }}>
                    {profileSuccessData && Object.keys(profileSuccessData).length > 0 ?
                        <>
                            <Rating initialValue={profileSuccessData.average_ratings || 0} readonly size={25} allowFraction={true} />
                            <span style={{ paddingLeft: '10px' }}>  {profileSuccessData.average_ratings || 0} ({profileSuccessData.total_ratings_count || 0})</span>
                        </> :
                        profileLoading ? <Skeleton variant="rectangular" width={210} height={28} /> : ""}
                </Box>


                <Followers followers={followers} following={following} selfProfile={!showButtons} />


                {/* Appears only when subsidiary's public profile is opened */}
                {(userType == publicProfileConstants.profile.pro) && profileSuccessData.slug &&
                    <Box className='userDetailsSubContainers flexRow justifyContentCenter alignItemsCenter'>
                        <Box className='pointer'>
                            <SvgExternalLink />
                            <Link to={routes.publicRoutes.shopURL + "/" + profileSuccessData.slug} className='link pointer' style={{ marginLeft: '5px' }} > {t(translations.PUBLICPROFILE.VISIT_SHOWCASE)}</Link>
                        </Box>
                    </Box>}


                {(profileSuccessData && Object.keys(profileSuccessData).length > 0) &&
                    <Box className='userDetailsSubContainers flexRow justifyContentCenter alignItemsCenter flexWrap' sx={{
                        gap: 2,
                        '.icon': {
                            marginRight: '0px !important'
                        },
                        '.icon svg path': {
                            stroke: 'white'
                        },
                        '.icon .chatIcon svg': {
                            width: '30px !important',
                            height: '30px !important'
                        },
                        '.icon div a span svg': {
                            width: '30px !important',
                            height: '30px !important'
                        }
                    }}>
                        {/* Showing buttons only if user has opened any other user's profile/subsidiary */}
                        {
                            showButtons &&
                            <>
                                <ButtonGroup>
                                    <ButtonCustom textBtn={t(followed ? translations.PUBLICPROFILE.UNFOLLOW : translations.PUBLICPROFILE.FOLLOW)} btnColor="primary" icon={!followed ? <SvgUserAdd /> : null} style={{ gap: '2px', height: '42px', borderRadius: !followed ? '4px 4px 4px 4px' : '4px 0px 0px 4px' }} clickFunction={handleFollow} tooltiptitle={t(followed ? translations.PUBLICPROFILE.UNFOLLOW : translations.PUBLICPROFILE.FOLLOW)} />
                                    {followed ?
                                        <ButtonCustom disable={disableBtn} btnColor='primary' icon={allowNotification ? <SvgNotification /> : <SvgNotificationIconNotAllowd />}
                                            style={{
                                                gap: '5px',
                                                height: '42px',
                                                background: '#5a6ff3 !important',
                                                width: '25px !important',
                                                borderRadius: '0px 4px 4px 0px', 'svg': { path: { fill: '#fff' } }
                                            }}
                                            clickFunction={handlePushNotification}
                                            tooltiptitle={t(allowNotification ? translations.PUBLICPROFILE.NOTIFICATION_ALLOWED : translations.PUBLICPROFILE.NOTIFICATION_NOT_ALLOWED)} /> : null}
                                </ButtonGroup>
                                {phoneNumber != '' && <Tooltip title={t(translations.PUBLICPROFILE.CALL)}>
                                    <a href={`tel:${phoneNumber}`} className='phoneIcon'>
                                        <SvgMobile />
                                    </a>
                                </Tooltip>}

                                <ButtonCustom btnColor="primary" icon={<ChatIcon ads={'adsDetail'} handleChat={() => {
                                    if (profileSuccessData && profileSuccessData.id) {
                                        if (userData?.profile_is_verified === false && !getSubsidiaryStored()) {
                                            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(...lan.clickHereToVerify())} message={Strings.verifyString} click={() => history.push(routes.userRoutes.idVerfication)} />, false)
                                            return;
                                        }
                                        handleChat(profileSuccessData.id, userType)
                                    }
                                }} />} />
                            </>
                        }

                        <ShareProfile followers={followers} following={following} userName={profileSuccessData.pseudo || profileSuccessData.name || ""} />
                    </Box>}
            </Box>
        </Box>
    )
}

export default UserInformation