import { Box, Drawer } from '@mui/material'
import { SvgBlock, SvgImport, SvgMail, SvgMute, SvgUnmute } from 'app/components/svgicons/svg2'
import { SvgverticalDots } from 'app/components/svgicons/svgNew'
import React, { useState } from 'react'
import { ChatWrapper } from '../../style/chatStyle'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { ChatOptionInterface, Nullable } from '../../interface/interface'
import { useDispatch } from 'react-redux'
import { deleteParticularChatRequest } from 'store/actions/chat-action'
import DeleteConfirm from 'app/components/stylesComponents/Ads/DeleteConfirm'
import * as ChatConstants from "config/chatConfig"
import { getSubsidiaryStored } from 'config/variables'
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';

const ChatOptionsMobile = ({ componenttype, chatData, handleArchive, handleBlock, handleMute, handleUnread, setOpen, open, chatType }: ChatOptionInterface) => {

    const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);

    const dispatch = useDispatch()

    const { t } = useTranslation()

    const [actionType, setActionType] = useState<number>(ChatConstants.ACTION_TYPE.BLOCK)

    const getWarningMessage = () => {

        let msg = t(translations.CHAT.ARE_YOU_SURE_TO_BLOCK_THIS_USER)

        if (chatType === ChatConstants.chatType.ALL_MESSAGES && Object.keys(chatData).length > 0 && chatData.chat_user_info.subsidiary_id) {
            msg = t(translations.CHAT.ARE_YOU_SURE_TO_BLOCK_THIS_SUBSIDIARY)
        }

        if (chatType === ChatConstants.chatType.BLOCKED) {
            msg = t(translations.CHAT.ARE_YOU_SURE_TO_UNBLOCK_THIS_USER)
            if (Object.keys(chatData).length > 0 && chatData.chat_user_info.subsidiary_id) {
                msg = t(translations.CHAT.ARE_YOU_SURE_TO_UNBLOCK_THIS_SUBSIDIARY)
            }
        }

        return msg
    }

    //----------------------------------- Action Functions-----------------------------------

    const handleAction = (type: number, val: boolean) => {
        setActionType(type)
        setDeleteConfirmationPopup(val)
    }

    const handleDelete = () => {
        dispatch(deleteParticularChatRequest(chatData.id))
        setDeleteConfirmationPopup(false)
        setOpen(false)
    }

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setOpen(open);
            };

    const list = () => (
        <ChatWrapper>
            {chatData && Object.keys(chatData).length > 0 && <Box
                sx={{ width: 'auto' }}
                role="presentation"
            >
                <Box className='chatOptionsMobileNameContainer flexRow'>
                    {/* <SvgProfileUser /> */}
                    <Box className={'userProfileView'}>
                        <img src={chatData.chat_user_info && chatData.chat_user_info.profile_picture_url ? chatData.chat_user_info.profile_picture_url : userPlaceHolder} alt={"UserImage"} />
                    </Box>
                    <span>{chatData.chat_user_info && chatData.chat_user_info.name ? chatData.chat_user_info.name : ""}</span>
                </Box>
                <Box className='chatOptionsMobileOptionsContainer flexColumn'>
                    <Box className='flexRow optionContainer' onClick={() => handleUnread()}>
                        <Box className='iconContainer'>
                            <SvgMail className='pointer' />
                        </Box>
                        <span>{chatData.is_read ? `${t(translations.CHAT.UNREAD)}` : `${t(translations.CHAT.READ)}`}</span>
                    </Box>
                    {/* Chat can be muted only if is is not blocked and archived */}
                    {chatType === ChatConstants.chatType.ALL_MESSAGES && <Box className='flexRow optionContainer' onClick={() => handleMute()}>
                        <Box className='iconContainer'>
                            {chatData.is_muted ? <SvgUnmute className='pointer' /> : <SvgMute className='pointer' />}
                        </Box>
                        <span>{chatData.is_muted ? `${t(translations.CHAT.UNMUTE)}` : `${t(translations.CHAT.MUTE)}`}</span>
                    </Box>}
                    {/* If a user is blocked, it can't be archived */}
                    {chatType !== ChatConstants.chatType.BLOCKED && <Box className='flexRow optionContainer' onClick={() => handleArchive()}>
                        <Box className='iconContainer'>
                            <SvgImport className='pointer' />
                        </Box>
                        <span>{chatData.is_archived ? `${t(translations.CHAT.UNARCHIVE)}` : `${t(translations.CHAT.ARCHIVE)}`}</span>
                    </Box>}
                    {/* If a chat is archived, it can't be blocked */}
                    {!chatData.is_archived && <Box className='flexRow optionContainer' onClick={() => handleAction(ChatConstants.ACTION_TYPE.BLOCK, true)}>
                        <Box className='iconContainer'>
                            <SvgBlock className='pointer' />
                        </Box>
                        <span> {chatType === ChatConstants.chatType.BLOCKED ? `${t(translations.CHAT.UNBLOCKS)}` : `${t(translations.CHAT.BLOCK)}`}</span>
                    </Box>}
                    {/* <Box className='flexRow optionContainer' onClick={() => handleAction(ChatConstants.ACTION_TYPE.DELETE, true)}>
                        <Box sx={{
                            'svg': {
                                'path': {
                                    fill: 'red'
                                }
                            }
                        }} className='iconContainer'>
                            <SvgDelete className='pointer' />
                        </Box>
                        <span style={{ color: 'red' }}> {t(translations.ads.DELETE)}</span>
                    </Box> */}
                </Box>
            </Box>}
        </ChatWrapper>
    );
    const handleDrawer = (value) => {
        if (chatData && chatData.chat_user_info && chatData.chat_user_info.id) {
            setOpen(value)
        }
    }

    return (
        <>
            {componenttype === "chatList" ?
                <span onClick={() => {
                    handleDrawer(true)
                }}>{t(translations.CHAT.MORE)}</span>
                :
                <SvgverticalDots className='pointer' onClick={() => handleDrawer(true)} />
            }
            <Drawer
                anchor='bottom'
                open={open}
                onClose={toggleDrawer(false)}
                sx={{
                    '.MuiPaper-root': {
                        borderRadius: '6% 6% 0 0'
                    }
                }}
            >
                {list()}
            </Drawer>
            <DeleteConfirm open={deleteConfirmationPopup} setOpen={setDeleteConfirmationPopup} deleteFunc={actionType === ChatConstants.ACTION_TYPE.BLOCK ? handleBlock : handleDelete} confirmBtn={actionType === ChatConstants.ACTION_TYPE.BLOCK ? t(translations.ads.CONFIRM) : null} message={getWarningMessage()} />
        </>
    )
}

export default ChatOptionsMobile