import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import Captions from "yet-another-react-lightbox/dist/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/dist/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Video from "yet-another-react-lightbox/dist/plugins/video";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import "yet-another-react-lightbox/dist/styles.css";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";

const  ZoomImage = (props)  => {
// Zoom Image component for slider images 
  const {open, setOpen, slides,hideThumbnail}= props;
  
  return (
    <> 
      <Lightbox
        open={open}
        index={open-1}
        close={() => setOpen(0)}
        slides={slides}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
        //   [Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
        plugins={hideThumbnail ? [Zoom]: [Thumbnails,Zoom]}
      />
    </>
  );
}
export default ZoomImage;
