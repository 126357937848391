import { Box, Popover } from '@mui/material'
import { SvgverticalDots } from 'app/components/svgicons/svgNew'
import React, { useEffect } from 'react'
import FlagComment from './flagComment';
import AddReview from './addReview';
import { PublicProfileWrapper } from 'app/pages/PublicProfile/style/publicProfileStyle';
import { ReviewsEditInterface } from 'app/pages/PublicProfile/interface/interface';
import { RootStateOrAny, useSelector } from 'react-redux';

const ReviewOptions = ({recId ,userRating ,userReview ,editable , flaggable}:ReviewsEditInterface) => {

    const { updateReviewSuccessData, flagReviewSuccessData } = useSelector((state:RootStateOrAny) => state.publicProfile)

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // Update edited review 
    useEffect(()=>{
        if (updateReviewSuccessData && Object.keys(updateReviewSuccessData).length > 0) {
            handleClose()
        }
    },[updateReviewSuccessData])

    // Update flagged review 
    useEffect(()=>{
        if (flagReviewSuccessData && Object.keys(flagReviewSuccessData).length > 0) {
            handleClose()
        }
    },[flagReviewSuccessData])


    return (
        <>
            <Box sx={{ padding: '0 5px' }} className='pointer flexColumn justifyContentCenter'>
                <span className='pointer' style={{ width: '18px', textAlign: 'center' }} onClick={handleClick}><SvgverticalDots /></span>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >   
                <PublicProfileWrapper>
                    {flaggable &&<Box className='reviewOptions'>
                        <FlagComment recId={recId}/>
                    </Box> }
                    {editable && <Box className='reviewOptions'>
                        <AddReview edit={true} recId={recId} userRating={userRating} userReview={userReview}/>
                    </Box>}
                </PublicProfileWrapper>
            </Popover>
        </>
    )
}

export default ReviewOptions