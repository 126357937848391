import React, { useState } from 'react'
import styled from 'styled-components'
import userPlaceholderImage from 'app/assets/Images/userplaceholder.png'
import { SvgCheck, SvgClose } from 'app/components/svgicons/svg'
import { color, colorNew } from 'styles/StyleConstants'
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material'
import { SvgTrashIcon } from 'app/components/svgicons/svgNew'
import NotFound from 'app/components/RepeatComponents/NoFound'
import { useDispatch, useSelector } from 'react-redux'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { CircularLoader } from 'app/components/ContantLoader/ContentLoader'
import { useHistory, useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'
import * as publicProfileConstants from "config/publicProfileConfig"
import { notificationReset } from 'store/actions/component-actions'
import { resetPublicProfile } from 'store/actions/publicProfile-action'
import { adsType, productTypes, productTypesInverse, vehicles } from 'config/variables'
import { chatCases, createStorechat } from 'config/chatConfig'

const AllNotification = (props: any) => {
    const { loading } = useSelector((state) => state.company)
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()
    const { t } = useTranslation()
    const [actionDisable, _actionDisable] = useState<boolean>(false)

    // resets public profile data only if user gets redirected to different url than curent
    const resetPublicProfileData = (url) => {
        if (!url.includes(location.pathname)) {
            dispatch(resetPublicProfile())
        }
    }

    // Redirecting to public profile page
    const handleNavigate = (notificationData) => {
        // if notification is of review
        if (notificationData.notification_type.title == 'review_add') {
            if (notificationData.datas.type == publicProfileConstants.profileType.subsidiary) { //
                history.push(routes.publicRoutes.public_profile_subisidiary + "/" + notificationData.datas.profile_slug + "/review")
                resetPublicProfileData(routes.publicRoutes.public_profile_subisidiary + "/" + notificationData.datas.profile_slug + "/review")
                dispatch(notificationReset())
                return;
            }
            history.push(routes.publicRoutes.public_profile_user + "/" + notificationData.datas.profile_slug + "/review")
            resetPublicProfileData(routes.publicRoutes.public_profile_user + "/" + notificationData.datas.profile_slug + "/review")
            dispatch(notificationReset())
        }

        // if notification is of follow
        if (notificationData.notification_type.title == 'profile_follow') {
            if (notificationData.datas.type == publicProfileConstants.profileType.subsidiary) { //
                history.push(routes.publicRoutes.public_profile_subisidiary + "/" + notificationData.datas.profile_slug)
                resetPublicProfileData(routes.publicRoutes.public_profile_subisidiary + "/" + notificationData.datas.profile_slug)
                dispatch(notificationReset())
                return;
            }
            history.push(routes.publicRoutes.public_profile_user + "/" + notificationData.datas.profile_slug)
            resetPublicProfileData(routes.publicRoutes.public_profile_user + "/" + notificationData.datas.profile_slug)
            dispatch(notificationReset())
        }

        //if notification is of create ad
        if (notificationData.notification_type.title == "create_ad") {
            if (notificationData.datas.type_of_sale == adsType.auction) {
                history.push(routes.vehicleRoutes.auctionAds + '/' + notificationData.datas.slug)
                dispatch(notificationReset())
                return;
            }
            history.push(routes.vehicleRoutes.publicAds + '/' + notificationData.datas.slug)
            dispatch(notificationReset())
            // return
        }

        //if notification is of buy vehicle
        if (notificationData.notification_type.title == "vehicle_buy") {
            // initiate chat from notification when user bought vehicle 
            const accountType = notificationData.datas.type == publicProfileConstants.profileType.subsidiary ? 'subsidiary' : 'user';
            createStorechat(notificationData.datas.profile_id, chatCases.PERSONAL_CHAT, dispatch, accountType)
            dispatch(notificationReset())
            history.push('/chat')
        }

        //if notification is of sold vehicle
        if (notificationData.notification_type.title == "vehicle_sold" ||
            notificationData.notification_type.title == "vehicle_reserved" ||
            notificationData.notification_type.title == "receipt_confirmed"
        ) {
            const vehicleType = productTypesInverse[notificationData.datas.product_type_id] || vehicles.car;
            // redirect on my vehicle from notification when vehicle sold
            history.push(routes.vehicleRoutes.myAds + '/' + vehicleType + routes.vehicleRoutes.park)
            dispatch(notificationReset())
        }

        //if notification is of about event start or end 
        if (notificationData.notification_type.title == "event_start" || // event about to start
            notificationData.notification_type.title == "event_end" ||  // event  ended
            notificationData.notification_type.title == "event_started" // event started
        ) {
            // redirect on my vehicle from notification when hotdeal is started or ending
            history.push(routes.publicRoutes.hotDealEvent + '/' + notificationData.datas.hotdeal_slug)
            dispatch(notificationReset())
        }

        //if notification is of about credit added
        if (notificationData.notification_type.title == "credits_added") {
            // redirect on my wallet page from notification cedit added 
            history.push(routes.userRoutes.myWallet)
            dispatch(notificationReset())
        }

        if (notificationData.notification_type.title == "vehicle_sold_cancel" ||
            notificationData.notification_type.title == "ad_delete" ||
            notificationData.notification_type.title == "ad_delete_and_restore_previous_classic_ad" ||
            notificationData.notification_type.title == "cancel_reservation_request_action") {
            // redirect on my ads page from notification when buyer cancel the reservation after the seller marked the vehicle as sold
            const vehicleType = productTypesInverse[notificationData.datas.product_type_id] || vehicles.car;
            history.push(routes.vehicleRoutes.myAds + '/' + vehicleType + routes.vehicleRoutes.classic)
            dispatch(notificationReset())
        }


        if (notificationData.notification_type.title == "sale_finalized") {
            const accountType = notificationData.datas.profile_type === publicProfileConstants.profileType.subsidiary ? publicProfileConstants.profile.pro : publicProfileConstants.profile.user
            history.push(`/${accountType}/${notificationData.datas.profile_slug}`)
            dispatch(notificationReset())
        }


    }
    return (
        <>
            {loading ? <CircularLoader style={{}} /> :
                <Box
                    sx={{
                        padding: '0 20px'
                    }}
                >
                    {props.notificationList && props.notificationList.length > 0 ?
                        props.notificationList.map((notification, i) =>
                            <Wrapper>
                                <div onClick={() => {
                                    handleNavigate(notification)
                                    props.handleOpenNotifications(notification)
                                }} className="item-all">
                                    {moment(notification.created_at).isSame(moment(), 'day') ?
                                        <span className="d-inline-block notification-time">{moment(notification.created_at).format("h:mm a")}</span>
                                        :
                                        <span className="d-inline-block notification-time">{moment(notification.created_at).format("DD/MM/yyyy")}</span>
                                    }
                                    <div className="notification_box">
                                        <img src={notification.image == null ? userPlaceholderImage : notification.image} alt={'user'} width={45} height={45} className="align-self-start" />
                                        <div className="notification-content px-2">
                                            <div className="notification-content-text">
                                                <h2>{notification.title}</h2>
                                                <p>{notification.body}</p>
                                            </div>
                                            {notification.type == "invitation" ?
                                                <>
                                                    {notification.status == "in_progress" &&
                                                        <div className='notification-action pt-2'>
                                                            <div className="btns d-flex justify-content-end">
                                                                {/* <span className="text-success">accepted</span> */}
                                                                <button
                                                                    className="cancel-btn me-2 d-flex text-center text-uppercase  py-1 px-2 bg-transparent pointer"
                                                                    onClick={() => {
                                                                        if (!loading) {
                                                                            props.respondNotification(notification.id, "refused", notification.type)
                                                                            _actionDisable(true)
                                                                        }
                                                                    }}>
                                                                    <span className="">
                                                                        <SvgClose />
                                                                    </span> {t(translations.webTexts.DECLINE)}
                                                                </button>
                                                                <button
                                                                    className="accept-btn d-flex text-center text-uppercase py-1 px-2 bg-transparent pointer"
                                                                    onClick={() => {
                                                                        if (!loading) {
                                                                            props.respondNotification(notification.id, "accepted", notification.type)
                                                                            _actionDisable(true)
                                                                        }
                                                                    }}>
                                                                    <span className="d-flex flex-column align-items-center me-2">
                                                                        <SvgCheck />
                                                                    </span> {t(translations.webTexts.ACCEPT)}
                                                                </button>
                                                            </div>
                                                        </div>}
                                                    {notification.status == "accepted" &&
                                                        <div className='notification-action pt-2'>
                                                            <div className="d-flex justify-content-end">
                                                                <span className="text-success">{t(translations.webTexts.ACCEPTED)}</span>
                                                            </div>
                                                        </div>}
                                                    {notification.status == "refused" &&
                                                        <div className='notification-action pt-2'>
                                                            <div className="d-flex justify-content-end">
                                                                <span className="text-danger">{t(translations.webTexts.REFUSED)}</span>
                                                            </div>
                                                        </div>}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => props.deleteNotification(notification.id)}><SvgTrashIcon /></button>
                            </Wrapper>
                        )
                        :
                        !props.loadingNotification ? <NotFound /> : <CircularLoader style={{}} />
                    }
                </Box>}
        </>
    )
}


const Wrapper = styled.div`
    position: relative;
    .btns{
        display:flex;
        gap:10px;
    }
    &:hover{
        > button{
            opacity:1;
            width:50px;
        }
        /* .item-all{
            transform: translateX(-150px);
        } */
    }
    > button {
        background-color:#DC4040;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        font-weight: 400;       
        font-size: 14px;
        color:${color.colorWhite};
        border:0;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity:0;
        overflow:hidden;
        white-space: nowrap;
        padding: 16px;
        transition: opacity .2s ease-in-out, 
        width 0.3s ease-in-out;
        cursor: pointer;
        transform: translate(191%, -85%);        
    }
    .item-all{
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.04) ;
        border-radius: 6px; 
        z-index:1;
        transition: .3s ease-in-out;
        padding: 16px;
        margin-bottom: 20px;
        cursor:pointer;
        img{
            object-fit:cover;
            border-radius: 100%;
            min-height: 45px;
            height: 48px;
        }
        .notification-time{
            position:absolute;
            right: 15px;
            top:6px;
        }
        .notification-content{
            h2 {
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;
                letter-spacing: 0.02em;
                color: #000000;
                margin-bottom: 0;
            }
            p{
                color: ${colorNew.colorGrayOnButton};
            }
        }
        .notification-action{
            >div{
                button{
                    border-radius:8px;
                    transition:0.2s ease-in-out;
                    &:hover{
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
                    }
                    span{
                        width:20px;
                        height:20px;
                        border-radius:50px;
                    }
                    &.accept-btn{
                        padding:5px;
                        margin-right: 10px;
                        border:1px solid #42B72A;
                        span{
                            background:#42B72A;
                        }
                    }
                    &.cancel-btn{
                        border:1px solid #FF0000;
                        padding:5px;
                        span{
                            background:#FF0000;
                        }
                    }
                    svg{
                        width: 16px;
                        height: 14px;
                        path{
                            stroke:${color.colorWhite};
                        }
                    }
                }
            }
        }
    }
`
AllNotification.propTypes = {
    deleteNotification: PropTypes.func,
    respondNotification: PropTypes.func,
    handleOpenNotifications: PropTypes.func,
    notificationList: PropTypes.array,
};

export default AllNotification;