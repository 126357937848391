import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


function CircularProgressBar({ color, size, value }) {
    return (
        <Box className='relative'>
            <Box sx={{
                'span svg circle': { stroke: '#f5f5f5' }
            }}>
                <CircularProgress variant='determinate' value={100} color='primary' size={size} />
            </Box>
            <Box sx={{
                position: 'absolute',
                top: 0,
                'span svg circle': { stroke: color }
            }}>
                <CircularProgress variant='determinate' value={value} color='inherit' size={size} />
            </Box>

        </Box>
    );
}

export default CircularProgressBar;