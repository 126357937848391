import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const lan = {
  date: () => _t(translations.invoiceScreen.DATE),
  invoices: () => _t(translations.invoiceScreen.INVOICES),
  invoiceNumber: () => _t(translations.invoiceScreen.INVOICENUMBER),
  total: () => _t(translations.invoiceScreen.TOTAL),
  porcheCenter: () => _t(translations.invoiceScreen.PORSCHECENTER),
  planName: () => _t(translations.invoiceScreen.PLANNAME),
  subTotal: () => _t(translations.invoiceScreen.SUBTOTAL),
  discount: () => _t(translations.invoiceScreen.DISCOUNT),
  invoiceTotal: () => _t(translations.invoiceScreen.INVOICETOTAL),
  vat: () => _t(translations.invoiceScreen.VAT),
  paid: () => _t(translations.invoiceScreen.PAID),
  amountDue: () => _t(translations.invoiceScreen.AMOUNTDUE),
  home: () => _t(translations.formText.HOME),
};  