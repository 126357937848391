import { Box, Button, Drawer, Modal } from '@mui/material'
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { SvgClose } from 'app/components/svgicons/svg';
import { HomePageWrapper } from 'app/pages/AdsHomePage/style/HomepageWrapper';
import { translations } from 'locales/translations';
import React from 'react'
import { Trans, useTranslation } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '700px',
    width: "100%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    px: 4,
    py: 2,
};

const LearnMorePopup = ({ header, contentList, buttonText }) => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isSmallDevice] = useDeviceType();
    const { t } = useTranslation();


    return (
        <Box className='benefitInformationContainer'>
            <Button variant="outlined" onClick={handleOpen} className='learn-more-btn'>
                {buttonText}
            </Button>
            {!isSmallDevice ?
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <HomePageWrapper>
                        <Box sx={style} className='infoModal'>
                            {BenifitsList(contentList, handleClose, header, t)}
                        </Box>
                    </HomePageWrapper>
                </Modal>
                :

                <Drawer
                    anchor='bottom'
                    open={open}
                    onClose={() => handleClose()}
                    sx={{
                        '.MuiPaper-root': {
                            height: 'auto',
                            borderRadius: '6% 6% 0 0',
                            overflowY: 'inherit',
                            padding: '16px 14px'
                        },
                    }}
                >
                    <HomePageWrapper>
                        <Box className='drawer-container'>
                            {BenifitsList(contentList, handleClose, header, t)}
                        </Box>
                    </HomePageWrapper>
                </Drawer >
            }



        </Box>
    )
}

export default LearnMorePopup

const BenifitsList = (contentList, handleClose, header, t) => {
    return <>
        <Box className='relative close-Icon-style'>
            <SvgClose onClick={handleClose} className='closeIcon' />
        </Box>
        <span className='benefitsSubHeader modalHeader justifyContentCenter'>{header}</span>
        <span className='benefitsContent modalContent'>
            {<Trans
                i18nKey={t(translations.CTB_CREDIT.CREDIT_LEARMORE)}
                components={{
                    bold: <strong className='bold-text' />,
                }}
            />}
        </span>
        {contentList.length && contentList.map((content, index) => {
            return <Box className='flexRow'>
                <span style={{ padding: '2px 10px 0px 0px' }}>&bull; </span>
                <span key={index + 'learnCredit'} className='benefitsContent modalContent'>
                    {content}
                </span>
            </Box>;
        })}
    </>
}
