import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const MyAdsWrapper = styled.div`
span{
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #000;
}
input:focus{
  outline: none;
}
.filterBox{
  width : 20%;
  position: fixed;
  height: calc(100vh - 155px);
  display : block;
}
.listContainer{
  width : 80%;
  margin-left: 20%;
}
.endTime span{
  color: #EC1C24;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.startTime span{
  color: #000;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.startTime .time{
  margin-left : 10px;
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.endTime .time{
  margin-left : 10px
}
.homeMainContainer{
  max-width: 2760px;
  width: 100%;
  margin: auto;
}
.scrollTop{
  position : fixed;
  background : #e3e3e3;
  width : 50px;
  height : 50px;
  bottom : 90px;
  right : 25px;
  border-radius : 5px;
  display: flex;
  align-items: center;
  justify-content:center;
}
.scrollTop svg{
  transform: rotate(90deg);
}
// -------------------------------------------------Header-------------------------------------------------
.header{
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.42px;
  margin : 0;
}
.headerCount{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin: 0;
}
.searchBox{
  border-bottom :1px solid #CCC;
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.searchBox svg{
  width : 15px
}
.searchBox input{
  color: var(--text-deep-gray, #666);
  font-size: 16px;
  border: none;
  min-height: 40px;
  max-width: 150px;
}
.soldVehicleFilter{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.sortBox{
  display : flex;
  align-items: center;
}
.sortBox span{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-right:5px;
}
.sortBox select{
  cursor: pointer;
  color: var(--text-deep-gray, #666);
  border: 1px solid #CCC;
  background : white;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  min-height: 40px;
  max-width: 150px;
}
.filterIcon{
  margin-left: 5px;
  width: 30px;
  height: 30px;
  background : #6579F4;
  padding :2px;
  border-radius : 5px;
}
.filterIcon svg path{
  fill : white;
}
.mobileSearchBox{
  width : 100%;
  gap: 5px;
}
.mobileSearchBox svg{
  height : 20px;
}
.mobileSearchBox span{
  color : #666666;

}
.mobileSearchBox input{
  width : 100%;
  height: 40px;
  border : none;
  font-size: 18px;
  background: #F8F8F8;
  color : #666666;
}
.parallelogramContainer{
  height : 80%;
  max-height :40px;
  margin: 5px;
}
.parallelogram{
  display:flex;
  align-items :center;
  justify-content : center;
	transform: skew(-20deg);
  background :#F1F1F9;
  padding : 0 20px;
  min-width :80px;
}
.parallelogram span{
  color :#6579F4;
  transform: skew(20deg);
  font-size: 18px;
}
.tabsContainerMobile{
  justify-content: space-between;
  margin: 0 10%;
  padding:10px;
}
.tabsContainerMobile span{
  color: #999;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.activeTab {
  background :#6579F4;
}
.activeTab span{
  color :#fff;
  padding:5px;
}
.activeTabMobile{
  background : white;
  border-bottom:1px solid #6579F4;
}
.activeTabMobile span{
  color: #6579F4;
}
.count{
  color : #666;
  font-weight:500;
  font-size: 20px;
}
.totalItems{
  color : #000;
  font-weight:500;
  font-size: 20px;
}

// -------------------------------------------------Status-------------------------------------------------
.status{
  margin-left: 5px;
  text-align: center;
  width: auto;
  min-width: 78px;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 13px;
}
.draft{
  background:#fbc623;
  color :#000
}
.parked{
  background:#fbc623;
  color :#000
}
.reserved{
  background:#6579f4;
  color :white
}
.sold , .purchased{
  background:#28a745;
  color :white
}
.disabled{
  background:#6579f4;
  color :white;
  opacity: 100%;
  cursor: default !important;
}
.pendingApproval{
  background:#6579f4;
  color :white
}
.closed{
  background:#F2F2F2;
  color :#999
}
.adUnderValidation{
  background : #F2F2F2;
  cursor: not-allowed !important;
  span{
    color: #767676;
  }
}
.underValidationMobile, .disputed{
  background : #fff8e1;
  color: #FBC623;
  border: 1px solid #FBC623;
}
.registrationExceededMobile{
  background : #F2F2F2;
  color: #666;
}
.default{
  .timerBox{
          border: 1px solid white !important;
          background-color: transparent !important;
        }
        span{
          color: white !important;
        }
}
.blink2{
    animation: blink 1s 2;
    transition: 0.2s ease-in-out;
    &.red{
      .timer{
        .timerBox{
          border: 1px solid ${colorNew.colorRed};
          background-color: ${colorNew.colorRedFade};
        }
        span{
          color: ${colorNew.colorRed};
        }
      }
    }
  }
  .blink{
    animation: blink 1s infinite;
    transition: 0.2s ease-in-out;
    &.red{
      .timer{
        .timerBox{
          border: 1px solid ${colorNew.colorRed};
          background-color: ${colorNew.colorRedFade};
        }
        span{
          color: ${colorNew.colorRed};
        }
      }
    }
  }
// -------------------------------------------------Ads-------------------------------------------------
.adDetailContainer{
  width : calc(100% - 300px);
}
.imageContainer{
  position : relative;
  width : 300px;
  height: 225px;
}
.share-icon{
  bottom: 10px;
}
.adsListing{
  max-height: calc(100% - 150px);
  margin-top:0;
  padding-top:0;
}
.mainContainer{
  position : relative;
  width : 100%;
  padding-bottom : 15px;
  margin-top : 15px;
  border-bottom : 1px solid #E5E5E5;
  justify-content : space-between;
  overflow-y: auto;
}
.adContainer{
  width : 75%;
  min-width : 700px;
}
.actionSkeletonContainer{
  width : 20%;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  min-width: 180px;
}
.adContainerLoader{
  max-width : 70%;
  min-width: 800px;
  text-align : center;
}
.adContainerLoader svg circle{
  stroke : #6579F4;
}
.ad-name{ 
  margin : 0px;
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 444;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-wrap:wrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.priceMainContainer{
  max-width : 400px;
  flex-wrap: wrap;
}
.priceContainer{
  border: 1px dashed #E5E5E5;
  padding: 5px;
  position: relative;
}
.priceHeader{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  word-break: keep-all;
}
.creditsUsed{
  position: absolute;
  top: 5px;
  right: 5px;
}
.vat{
  font-size: 13px;
  color: var(--text-deep-gray, #666);
  font-weight: 400;
  letter-spacing: 0.3px;
  .benefitInformationContainer{
    display: inline;
    margin-left: 5px;
  }
}
.price{
  display: flex;
  gap: 16px;
  align-items: center;
  color: #363B39;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.42px;
  margin : 5px 0
}
.orderContainer{
  padding: 5px;
}
.userName{
  div{
    display: inline;
  }
  color: var(--Shade-1, #384386);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.shareIcon{
  bottom: 10px !important;
  width: 30px;
  height: 30px;
}
.counter{
  position: absolute;
  bottom:12px;
  left : 10px;
}
.counter span{
  color : #fff;
}
.timer div{
  backdrop-filter: blur(32px);
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid rgba(255,255,255,0.90);
  background: rgba(0,0,0,0.10);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom  : 20px ;
}
.timer div span{
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.toggle_icon span{
  color : white;
}
.impressions{
  max-width: 400px;
  margin-top: 10px;
  padding: 16px 0;
}
.impressions span{
  color: #666;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.3px;
}

.disabledVehicle{
  opacity : 50%;
}

.marginRight-5{
  margin-right : 5px;
}

.marginRight-10{
  margin-right : 10px;
}

.gap-2{
  gap :2px; 
}

// --------------------------------------Options ---------------------------------------
.myAdsOptionContainer{
  min-width: 180px;
  width: 20%;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  height: max-content;
}
.myAdsOption{
  padding: 10px;
  border-bottom: 1px solid #E5E5E5;
  cursor:pointer;
  display : flex;
  align-items:center;
}
.myAdsOption span{
  margin-left :10px;
}
.myAdsOption:last-child {
  border : none;
}
.myAdsOptionCreateOrder{
  border-bottom: 1px solid #E5E5E5 !important;
}

// --------------------------------------Modal---------------------------------------
.usersModal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 40%;
  width: 600px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 8px;
}
.closeIcon{
  position : absolute;
  right : 7px;
  cursor : pointer; 
}
.closeIcon path{
  fill : #666 
}
.customersContainer{
  max-width: 100%;
  width: 400px;
  margin : 0 auto;
}
.customerHeader{
  padding : 16px 8px;
}
.customerHeader span{
  color: #000;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0.42px;
}
.suggestedMemberHeader{
  color: #000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.inputFieldContainer{
  background: #F8F8F8;
  margin 0 0 20px 0;
  display : flex;
  align-items : center;
}
.inputFieldContainer svg{
  margin : 0 15px;
}
.inputFieldContainer input{
  border: none;
  border-left: 2px solid grey !important;
  background: #F8F8F8;
  margin: 10px 0px;
  padding: 0px 10px;
  height: 30px;
  color: #666;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.suggestedCustomers{
  max-height: 40vh;
  overflow-y: scroll;
}
.not-found-message{
  padding : 10px 20px;
}
.userAvatar{
  width :40px;
  height :40px;
  border-radius:50%;
}
.flagImg{
  border-radius:50%;
  position: absolute;
  top: 28px;
  right: 0px;
  width: 15px;
  height: 15px;
}
.customerDetails{
  padding : 8px;
  cursor : pointer;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.04);
  margin-bottom :15px;
}
.customerNameAndContact{
  display : flex;
  flex-direction : column;
  gap : 5px;
}
.customerNameAndContact .name{
  color: #000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.customerNameAndContact .contact{
  color: #666;
  font-size: 15px;
  font-weight: 440;
  letter-spacing: 0.525px;
}
.spinnerLoader span{
  color: #6579f3;
}
// --------------------------------------Actions Container---------------------------------------
.actionContainer{
  min-width: 180px;
  width: 20%;
  display : flex;
  flex-direction :column;
  gap : 20px;
}
.actionContainer button{
  width : 100%;
  height : 56px;
  border-radius: 6px;
  text-transform: none;
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.02em;
}
.accept{
  background : #28A745;
  color : white
}
.reject{
  background : #EC1C24;
  color : white
}
.accepted{
  background : #EEFFF2;
  color: #28A745;
  text-align: center;
  font-size: 21px;
  font-weight: 500;
  cursor: initial;
}
.underValidation{
  background : #fff8e1;
  color: #FBC623;
  text-align: center;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0.02em !important;
  cursor: initial 
}
.registrationExceeded{
  background : #F2F2F2;
  color: #666;
  text-align: center;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0.02em !important;
  cursor: initial 
}
.rejected{
  background : #FFF1F2;
  color: #EC1C24;
  text-align: center;
  font-size: 21px;
  font-weight: 500;
  cursor: initial 
}
.actionTakenHeader{
  color: #000;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.actionTakenContent span{
  color: #384386;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.actionTakenContent span > div{
  display: inline;
}
.endedDate{
  color: #666;
}
.cancelReservationModal{
  position : absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .modalHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h3{
      font-weight: 500;
      font-size: 1.10rem;
      letter-spacing: 0.0075em;
      color: rgb(102, 102, 102);
      margin: 0;
    }
  }
  .reasonField{
    width: 100%;
  }
  Button{
    text-transform: none;
    line-height: initial;
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 7px;
    margin: 5px 10px;
  }
  .submitBtn{
    border: 1px solid #F63E3E;
    color: #F63E3E;
  }
  .cancelBtn{
    border: 1px solid #607AFC;
    background-color: #607AFC;
    color: white;
    border-radius: 7px;
  }
}
.adorement{
  position: absolute;
  bottom: 16px;
  right: 0px;
  padding-top:10px;
}

// --------------------------------------Media Query ---------------------------------------
@media (min-width :992px) and (max-width :1200px){
  .filterBox{
    width : 26%;
  }
  .listContainer{
    width : 74%;
    margin-left: 26%;
  }
  .adContainer {
    width: 90%;
    min-width: 600px;
  }
  .adDetailContainer{
    width: calc(100% - 300px);
  }
  .timer div {
    padding: 0px 6px;
  }
  .timer div span{
    font-weight :500;
  }
  .offlineContainer{
    width: -webkit-fill-available;
  }
}
@media (min-width :1200px) and (max-width :1260px){
  /* .adDetailContainer{
    width: calc(100% - 350px);
  } */
}

@media (max-width :992px){

  .mainContainer{
    flex-wrap : wrap;
  }
  .filterBox{
    width : 40%;
  }
  .listContainer{
    width : 60%;    
    margin-left: 40%;
  }
  .adsListing{
    margin-top:0;
    padding-top:0;
  }
  .adContainer{
    align-items: center;
    width : 100%;
    max-width : 100%;
    min-width : 100%;
  }
  .adContainerLoader{
    width : 100%;
    max-width : 100%;
    min-width : 100%;
    display: flex;
    align-items: center;
    justify-content : center;
  }
  .adDetailContainer{
    width : 100%;
  }
  .priceMainContainer{
    max-width : 100%;
  }
  .myAdsOptionContainer{
    width : 100%;
    padding : 10px;
    border: none;
  }
  .myAdsOption{
    border-bottom: none !important;
  }
  .mobileOptionsHeader{
    padding : 20px 20px 10px 20px;
    font-size: 21px;
    font-weight: 500;
    border-bottom: 1px solid #E5E5E5;
  }
  .actionContainer{
    flex-direction :row;
    width:100%;
  }
  .actionsTaken{
    width:100%;
    display: flex;
    flex-direction: column-reverse;
  }
  .actionTakenDetails{
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .impressions{
    margin: 0 auto;
  }
  .ad-name{
    /* width :100%; */
    display: flex;
    flex-wrap: wrap;
  }
  .usersModal{
    width: 100%;
    background: white;
    padding: 8px;
    height : 95vh;
    min-width : 100%;
  }
  .suggestedCustomers{
    max-height: calc(100vh - 250px);
  }
  .customersContainer{
    width : 100%;
  }
  .switchCustomer{
    color: #384386;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
  }
  .status{
  margin-top: 2px;
  margin-bottom: 2px;
}
}
@media (min-width :769px) and (max-width :992px){
  .filterBox{
    position: sticky;
  }
  .listContainer{
    margin-left: 0;
  }
}
@media (max-width :768px){

  .adsListing{
    margin-bottom:15px;
    padding: 0 10px;
  }
  .filterBox{
    width : 0%;
    min-width:0px;
    display: none;
  } 
  .listContainer{
    width : 100%;
    max-width: 100%;
    margin-left: 0px;
  }
  .loader{
    height : 12px !important;
    width : 12px !important;
  }
  .priceMainContainer{
    flex-wrap: wrap;
  }
  .myAdsPriceContainer{
    width : 100%;
    margin-bottom:10px;
  }
  .myAdsOfflineContainer{
    width : 90%;
  }
  .cancelReservationModal{
    max-width: 300px;
  }
}

@media (max-width :575px){
  .imageContainer {
    max-width: 100%;
    min-width: 100%;
    width : auto;
    height: auto;
  }
  .counter{
    top: inherit !important;
    bottom: 12px !important;
  }
}
`