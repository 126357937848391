import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Box } from '@mui/material';
import { SvgelectricIcon } from '../svgicons/svgNew';
import { CarouselWrapper } from './style';
import { SvgLiked, SvgUnliked } from '../svgicons/svg';
import adsImageNotFound from 'app/assets/Images/adsPlaceholder.svg';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { addAdInFavouriteFailure, addAdInFavouriteRequest, removeAdFromFavouriteFailure, removeAdFromFavouriteRequest } from 'store/actions/vehicle-actions';
import ShareAd from '../shareAd';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDeviceType } from "app/Hooks/useMediaQuery";
import { adsTypeName } from "config/constants";
import { useEffect, useState } from "react";
import { routes } from "routes/routes";
import { Toaster } from "services/Toaster";

const ImageCounter = ({ currentImageCount, totalImages }) => {

    return (
        <Box className='contentBox imageCount'>
            <span> {`${currentImageCount} / ${totalImages}`} </span>
        </Box>
    )
}


const ImageCarousel = ({ images, kmCount, isFavourite, adId, title, linkToShare, handleImageClick, zoom = false, adType, imageAltText, url = '/', myAd = false, showLikeButton = false, showShareButton = false, showCount = true }) => {

    const location = useLocation();
    const history = useHistory()
    const [isSmallDevice, isMediumDevice] = useDeviceType()

    const { likeDislikeProcessing, addAddFavouriteError, removeAddFavouriteError } = useSelector((state: RootStateOrAny) => state.vehicle)
    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth)

    const [currentImageCount, setCurrentImageCount] = useState(1);

    const dispatch = useDispatch()

    useEffect(() => {
        if (addAddFavouriteError && Object.keys(addAddFavouriteError).length > 0) {
            Toaster.error(addAddFavouriteError.message)
            dispatch(addAdInFavouriteFailure())
        }
    }, [addAddFavouriteError])

    useEffect(() => {
        if (removeAddFavouriteError && Object.keys(removeAddFavouriteError).length > 0) {
            Toaster.error(removeAddFavouriteError.message)
            dispatch(removeAdFromFavouriteFailure())
        }
    }, [removeAddFavouriteError])

    const handlefavourite = () => {
        // returning if already in process
        if (likeDislikeProcessing) {
            return
        }

        if (!loggedIn) {
            history.push(`${routes.authRoutes.login}?redirectTo=${encodeURIComponent(location.pathname + location.search)}`)
            return
        }

        if (!isFavourite) {
            dispatch(addAdInFavouriteRequest({ id: adId }))
            return
        }
        dispatch(removeAdFromFavouriteRequest({ id: adId }))
    }

    const handleAction = (e, index) => {
        if (zoom && handleImageClick) {
            handleImageClick(e, index)
            return
        }
    }


    const showImage = (image, imageAltText, index) => {
        return <>
            <img src={image} fetchpriority={index === 0 ? 'high' : 'low'} alt={imageAltText} width='auto' height='100%' style={{ aspectRatio: "4/3", objectFit: 'contain', maxHeight: 'initial', width: '100%' }} />
        </>
    }

    return (
        <CarouselWrapper>
            <Box sx={{ position: 'relative', cursor: 'pointer' }} >
                {images && images.length > 0 && showCount ? <ImageCounter currentImageCount={currentImageCount} totalImages={images.length} /> : null}
                {images && images.length > 0 ?
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        swipeable={((isSmallDevice || isMediumDevice) && (location.pathname === '/' || adType == adsTypeName.auctionAds)) ? false : true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        onChange={(currentImageCount) => setCurrentImageCount(currentImageCount + 1)}
                    >
                        {
                            images.map((image, index) => {
                                return (!myAd ?
                                    <Link className="image-container" key={`clickable${image.id}`} id={`image${image.id}`} to={url} onClick={(e) => handleAction(e, index)} >
                                        {showImage(image.medium_url, imageAltText, index)}
                                    </Link>
                                    :
                                    <Box className="image-container" key={`non-clickable${image.id}`} id={`image${image.id}`} onClick={(e) => handleAction(e, index)} >
                                        {showImage(image.medium_url, imageAltText, index)}
                                    </Box>

                                )
                            })
                        }
                    </Carousel>
                    : !myAd ?
                        <Link className="image-container" to={url} >
                            {showImage(adsImageNotFound, imageAltText, 1)}
                        </Link>
                        :
                        <Box className="image-container" >
                            {showImage(adsImageNotFound, imageAltText, 1)}
                        </Box>
                }
                {
                    <>
                        {!((isSmallDevice || isMediumDevice) && adType == adsTypeName.auctionAds) && kmCount ?
                            <>
                                <Box sx={{ position: 'absolute', paddingLeft: '10px !important' }} className='contentBox kmCount'>
                                    <span>{kmCount} km</span>
                                    <Box className='kmIcon'><SvgelectricIcon /></Box>
                                </Box></> :
                            null}
                        {!(location.search.includes('created_vehicle')) &&
                            <>
                                {showShareButton && <ShareAd topBar={false} title={title} linkToShare={linkToShare} adType={adType} showLikeButton={showLikeButton} />}
                                {showLikeButton && <Box sx={{ position: 'absolute' }} className='contentBox likeIcon' onClick={() => handlefavourite()}>{isFavourite ? <SvgLiked /> : <SvgUnliked />} </Box>}
                            </ >
                        }
                    </>
                }
            </Box >


        </CarouselWrapper >
    )
}

export default ImageCarousel