import { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { PrivatePusherClient } from 'websocket';
import { blockedByRequest, chatStoreFailure, getArchivedChatRequest, getArchivedChatSuccess, getBlockedChatRequest, getBlockedChatSuccess, getChatRequest, getChatSuccess, getNewMessageNotification, getsubsidiaryActiveMemberListRequest, resetChatRequest } from 'store/actions/chat-action';
import { Members } from 'pusher-js';
import * as ChatConstants from "config/chatConfig"
import { getSubsidiaryStored } from 'config/variables';
import { Toaster } from 'services/Toaster';
import { routes } from 'routes/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'app/Hooks/windowResize';
import { onlineUsersRequest } from 'store/actions/onlineUser-actions';
import { getParamQueries } from 'config/appConfig';

const HandleChats = () => {
  //local states
  const [newMessage, _newMessage] = useState<object>({})

  //Redux states
  const { loggedIn } = useSelector((state: any) => state.auth)
  const { profileUpdated, userData } = useSelector((state: any) => state.user)
  const { chatStoreData, chatList, chatListAllData, blockedChatList, blockedChatListAllData, archivedChatListAllData, archivedChatList, chatStoreError, archivedChatLoaded, chatsRequested } = useSelector((state) => state.chat)

  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [mobile] = useWindowSize();

  // subsidiary data from localstorage
  const subsidiary = getSubsidiaryStored()

  // creating instance of pusher 
  const PrivatePusherClientInstance = PrivatePusherClient();


  useEffect(() => {
    if (!chatsRequested) {
      const payload = getParamQueries(location.search)
      if (payload.create_new_chat && payload.accountType) {
        // create chat when follwer get vehicl_buy notification
        ChatConstants.createStorechat(payload?.create_new_chat, ChatConstants.chatCases.PERSONAL_CHAT, dispatch, payload.accountType)
      }
    }
  }, [chatsRequested])
  useEffect(() => {
    //if user loggedIn and profile is complete then requesting data for
    if (loggedIn) {
      let profileStatus = false
      if (Object.keys(userData).length > 0) {
        profileStatus = userData?.profile_is_complete ? true : false
      }

      if (profileStatus) {
        dispatch(resetChatRequest()); //resetting all redux for chat whenever switching accounts
        requestChats()
        dispatch(blockedByRequest())//list of users who have blocked loggedIn user
      }
    }
  }, [userData?.profile_is_complete])

  useEffect(() => {
    if (userData.id) {
      if (subsidiary) {
        dispatch(getsubsidiaryActiveMemberListRequest({ subsidiary_id: subsidiary }))
      }
    }
  }, [userData])

  useEffect(() => {
    if (chatStoreError && Object.keys(chatStoreError).length > 0) {
      if (chatStoreError.data) {
        Toaster.error(Object.values(chatStoreError.data).toString())
        dispatch(chatStoreFailure({}))
        return
      }
      Toaster.error(chatStoreError.message)
      dispatch(chatStoreFailure({}))
    }
  }, [chatStoreError])

  // function for getting list of online users via socket
  const listenChatUserStatus = () => {
    /* Subscribe to get online users */
    const presenceChannel = PrivatePusherClientInstance?.subscribe('presence-online.users');

    presenceChannel?.bind("pusher:subscription_succeeded", (members: Members) => {
      if (members) {
        let onlineMembers: string[] = [];
        Object.keys(members.members).map((member) => {
          // only members other than loggedIn user considered as online user 
          if (member !== members.members.myID) {
            onlineMembers.push(member);
          }
          return onlineMembers;
        })
        dispatch(onlineUsersRequest({ onlineMembers: onlineMembers, status: "online" }))
      }
    });
    //in case of connection failure for socket 
    presenceChannel?.bind("pusher:subscription_error", (error) => {
      // try to reconnect for 5 times only
      var { status } = error;
    });

    presenceChannel?.bind("pusher:member_added", (members) => {
      // updating data of online users whenever some comes online
      dispatch(onlineUsersRequest({ onlineMembers: members, status: "member_added" }))
    });

    presenceChannel?.bind("pusher:member_removed", (members) => {
      // removing data of online users whenever some goes offline
      dispatch(onlineUsersRequest({ onlineMembers: members, status: "member_removed" }))
    });
  }

  useEffect(() => {
    if (userData.id) {
      // listening socket only if user loggedIn
      listenChatUserStatus();
    }
  }, [chatList, userData])


  const requestChats = () => {
    dispatch(getChatRequest({ page: 1 }))//chatlist
    dispatch(getArchivedChatRequest({ page: 1 }))//archived chat list
    dispatch(getBlockedChatRequest({ page: 1 })) //blocked chat list
  }

  // Function for connecting socket for messaging
  const connectPusher = () => {
    const userId = userData.id;
    if (userId === undefined || userId === null || userId <= 0) return;

    // if loggedin as subsidiary then subscribe channel for subsidiary and vice-versa for private user
    const privateChannelName = getSubsidiaryStored() ? `private-inbox.subsidiary.${getSubsidiaryStored()}.user.${userId}` : `private-inbox.user.${userId}`;
    /* Subscribe to get messages */
    const privateChannel = PrivatePusherClientInstance?.subscribe(privateChannelName);

    privateChannel?.bind("pusher:subscription_succeeded", (members) => {
    });

    //in case of connection failure for socket 
    privateChannel?.bind("pusher:subscription_error", (error) => {
      var { status } = error;
    });

    // runs whenever new message recieves
    privateChannel?.bind("new-message", (message) => {
      switch (message.event) {
        case "receptionistChangeEvent":
        case "blockEvent":
        case "unblockEvent":
          requestChats()
          dispatch(resetChatRequest());
          dispatch(blockedByRequest())
          break;
        default:
          const recievedMsg = JSON.parse(JSON.stringify(message.body));
          // display new notification iocn only if message is not blank
          if (recievedMsg === null && recievedMsg === undefined) {
            dispatch(getNewMessageNotification(false))
            return;
          }
          _newMessage(recievedMsg)
          dispatch(getNewMessageNotification(true))
      }
    });
  }

  // unsubscribe channels for messages 
  const disconnectPusher = () => {
    const privateChannelName = subsidiary ? `private-inbox.subsidiary.${subsidiary}.user.${userData.id}` : `private-inbox.user.${userData.id}`;
    const PrivatePusherClientInstance = PrivatePusherClient();
    PrivatePusherClientInstance?.unsubscribe(privateChannelName);
  }

  useEffect(() => {
    //subscribe message channels whenever switching subsidiary/private accounts 
    connectPusher()
    return () => {
      disconnectPusher();
    }
  }, [subsidiary, userData])

  useEffect(() => {
    if (newMessage && Object.keys(newMessage).length > 0) {
      removeDataFromArchiveList(newMessage)
      let chatListObject = { ...chatListAllData }
      let chatListArray = [...chatList]
      let prevChat = { ...newMessage }
      delete prevChat.chat
      // creating/updating data in messages list whenever new message recieved 
      let appendChatinList = newMessage.chat
      appendChatinList['chat_user_info'] = newMessage.chat.chat_user_info
      if (newMessage.user) {
        appendChatinList['users'] = [newMessage.user]
      }
      appendChatinList['is_read'] = ChatConstants.readStatus.unRead
      appendChatinList['messages'] = [prevChat]
      appendChatinList['last_chat_at'] = new Date()
      appendChatinList['last_message'] = {
        chat_id: newMessage['chat_id'],
        id: newMessage['id'],
        media: newMessage['media'],
        message: newMessage['message']
      }
      if (newMessage.is_old_chat) {
        appendChatinList['is_old_chat'] = newMessage.is_old_chat
      }
      // fetches existing data in state && code commented for future use
      // if(newMessage.chat.is_archived ){
      //   if(!archivedChatLoaded){
      //     return
      //   }
      //   chatListObject = { ...archivedChatListAllData }
      //   chatListArray = [...archivedChatList]
      // }
      // searchs for existance of chat in current data
      let existence = chatListArray.findIndex(o => o.id == newMessage['chat_id'])
      // If exists it replaces the chat data in current state
      if (existence !== -1) {
        appendChatinList['messages'] = chatListArray[existence].messages ? [prevChat, ...chatListArray[existence].messages] : [prevChat]
        appendChatinList['chat_user_info'] = chatListArray[existence].chat_user_info
        appendChatinList['users'] = chatListArray[existence].users
        appendChatinList['is_admin'] = chatListArray[existence].is_admin ? chatListArray[existence].is_admin : false
        appendChatinList['is_read'] = ChatConstants.readStatus.unRead
        if (localStorage.getItem('chat_id') && localStorage.getItem('chat_id') == newMessage['chat_id'] && location.pathname.includes(routes.userRoutes.chat)) {
          appendChatinList['is_read'] = ChatConstants.readStatus.read
        }
        chatListArray[existence] = appendChatinList
        // Sorts data in current state based on last message 
        chatListArray.sort(function (a, b) { return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime() })
      } else {
        // If doesn't exists it appends the new data current data
        chatListArray = [appendChatinList, ...chatListArray]
      }
      if (chatListObject?.data?.data) {
        chatListObject.data.data = chatListArray
        if (newMessage.chat.is_archived & archivedChatLoaded) {
          dispatch(getArchivedChatSuccess({ data: chatListObject, append: false }))
          return;
        }
        dispatch(getChatSuccess({ data: chatListObject, append: false }))
      }
    }
  }, [newMessage])

  // Fucntion removes chat from archived chat if any message recieved for respected Chat
  const removeDataFromArchiveList = (newMessage) => {
    if (!archivedChatLoaded) {
      return
    }
    let chatListObject = { ...archivedChatListAllData }
    let chatListArray = [...archivedChatList]
    let chatIndex = chatListArray.findIndex(o => o.id === newMessage['chat_id'])
    if (chatIndex !== -1) {
      chatListArray.splice(chatIndex, 1)
      chatListObject.data.data = chatListArray
      dispatch(getArchivedChatSuccess({ data: chatListObject, append: false }))
    }

  }

  //Redirect on chat page from public ads
  useEffect(() => {
    if (Object.keys(chatStoreData).length && chatStoreData.success) {
      // Redirection to chat page when chat is blocked with selecting blocked list filter
      if (chatStoreData.data.is_blocked) {
        let chatListObject = { ...blockedChatListAllData }
        let chatListArray = [...blockedChatList]
        let existence = chatListArray.findIndex((o) => o.id == chatStoreData.data.item.id)
        // Adds chat to blocked list if chat doesn't exists in blocklist
        if (existence == -1 && chatListObject?.data?.data) {
          chatListObject.data.data = [chatStoreData.data.item, ...chatListArray]
          dispatch(getBlockedChatSuccess({ data: chatListObject, append: false }))
        }
        history.push({
          pathname: routes.userRoutes.chat,
          search: '?chat_id=' + chatStoreData.data.item.id + '&chat_type=' + ChatConstants.chatType.BLOCKED
        }
        )
        return
      }
      // Redirection to chat page when chat is archived with selecting archived list filter
      if (chatStoreData.data && chatStoreData.data.item && chatStoreData.data.item.is_archived && mobile) {
        history.push({
          pathname: routes.userRoutes.chat,
          search: '?chat_id=' + chatStoreData.data.item.id + '&chat_type=' + ChatConstants.chatType.ARCHIVED
        }
        )
        return
      }
      let chatListData = [...chatList]
      const findUser = chatListData.findIndex((o) => o.id === chatStoreData.data.item.id)

      // Appending new chat when creating for first time through ads 
      if (findUser == -1) {
        if (!chatStoreData.data.is_blocked && !chatStoreData.data.item.is_archived) {
          Toaster.success(t(translations.CHAT.CHAT_CREATED))
        }
        let chatListObject = { ...chatListAllData }
        chatListData = [chatStoreData.data.item, ...chatList]
        if (chatListObject?.data?.data) {
          chatListObject.data.data = chatListData
          dispatch(getChatSuccess({ data: chatListObject, append: false }))
        }
      }

      // Updating existing chat if chat already exists with new data 
      if (findUser != -1) {
        let chatListObject = { ...chatListAllData }
        chatListData[findUser] = chatStoreData.data.item
        chatListObject.data.data = chatListData.sort(function (a, b) { return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime() })
        dispatch(getChatSuccess({ data: chatListObject, append: false }))
      }

      if (window.innerWidth < 992) {
        history.push({
          pathname: routes.userRoutes.chat,
          search: '?chat_id=' + chatStoreData.data.item.id
        }
        )
      }
    }

  }, [chatStoreData])

  return (
    <>
    </>
  )
}

export default HandleChats