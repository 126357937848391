import { Box, Checkbox, FormControlLabel } from '@mui/material'
import TextMultipleInput from 'app/components/CustomFields/textMultipleInput'
import { Nullable } from 'app/pages/PublicProfile/interface/interface'
import { translations } from 'locales/translations'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isVAtRefundable } from '../filterConfig'
import { getParamQueries } from 'config/appConfig'
import { useLocation } from 'react-router-dom'
import { Toaster } from 'services/Toaster'

const Price = ({ updateURL }) => {

    const { t } = useTranslation()
    const location = useLocation()

    const [vatRefundable, _vatRefundable] = useState<boolean>(false)

    const [priceMin, _priceMin] = useState<Nullable<number>>()
    const [priceMax, _priceMax] = useState<Nullable<number>>()

    const handlePriceMin = (value) => {
        _priceMin(value)
        if (priceMax && priceMax < parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MIN_ERROR))
            return
        }
        updateURL('price_start', value)
    }

    const handlePriceMax = (value) => {
        _priceMax(value)
        if (priceMin && priceMin > parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MAX_ERROR))
            return
        }
        updateURL('price_end', value)
    }

    const handleVAT = () => {
        _vatRefundable(!vatRefundable)
        updateURL('deductible_vat', vatRefundable ? '' : isVAtRefundable.true)
    }

    // Sets values got from url to fields (runs only when page is rendered for the first time)
    useEffect(() => {
        let payload = getParamQueries(location.search)
        _priceMin(payload.price_start ?? null)
        _priceMax(payload.price_end ?? null)
        _vatRefundable(payload.deductible_vat ?? false)
    }, [location.search])


    return (
        <Box sx={{ gap: '24px', mt: 1 }} className='flexColumn'>
            <TextMultipleInput title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_PRICE)} labelFirstField={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FROM)} labelSecondField={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_TO)} handleFirstField={(value) => handlePriceMin(value)} handleSecondField={(value) => handlePriceMax(value)} firstFieldValue={priceMin} secondFieldValue={priceMax} showtext="€" />
            <Box className='flexRow justifyContentBetween alignItemsCenter'>
                <span className='vatCheckBoxLabel'>{t(translations.ads.VAT_REFUNDABLE)}</span>
                <Checkbox checked={vatRefundable} onChange={handleVAT} />
            </Box>
        </Box>
    )
}

export default Price