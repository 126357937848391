import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FieldWrapper } from './style'

const TextMultipleInput = ({ title, labelFirstField, labelSecondField, handleFirstField, firstFieldValue, handleSecondField, secondFieldValue, showtext = '' }) => {

    const [firstFieldQuery, _firstFieldQuery] = useState<string>('')
    const [secondFieldQuery, _secondFieldQuery] = useState<string>('')

    const updateFilterValueforFirstField = () => {
        handleFirstField(firstFieldQuery)
    }

    const updateFilterValueforSecondField = () => {
        handleSecondField(secondFieldQuery)
    }

    useEffect(() => {
        _firstFieldQuery(firstFieldValue)
    }, [firstFieldValue])

    useEffect(() => {
        _secondFieldQuery(secondFieldValue)
    }, [secondFieldValue])

    return (
        <FieldWrapper>
            <Box className='field'>
                <span className='fieldHeader'>{title}</span>
                <Box className='flexRow textMultipleFieldContainer alignItemsCenter'>
                    <Box>
                        <span className='fieldText'>{showtext}</span>
                           <input type="number" placeholder={labelFirstField} value={firstFieldQuery ? firstFieldQuery : ""} onChange={(e) => _firstFieldQuery(e.target.value)} onBlur={updateFilterValueforFirstField} />
                    </Box>
                    <span>-</span>
                    <Box>
                        <span className='fieldText'>{showtext}</span>
                        <input type="number" placeholder={labelSecondField} value={secondFieldQuery ? secondFieldQuery : ''} onChange={(e) => _secondFieldQuery(e.target.value)} onBlur={updateFilterValueforSecondField} />
                    </Box>
                </Box>
            </Box>
        </FieldWrapper>
    )
}

export default TextMultipleInput