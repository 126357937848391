import { Fragment, useEffect, useRef } from 'react'
import { Box, Button, Tooltip } from '@mui/material'
import mainLogo from 'app/AssetNew/Images/Whitelogo.png'
import { Link, matchPath, NavLink, useHistory, useLocation } from 'react-router-dom'
import { SvgAnnounce, Svgauction, SvgfaqIcon, Svghomeicon, SvgPlus, SvgStar, SvgTranslation, SvgUserHammerIcon, SvgUserILineIcon, SvgWhiteHome } from 'app/components/svgicons/svgNew'
import styled from 'styled-components'
import { colorNew } from 'styles/StyleConstants'
import { routes } from 'routes/routes'
import { useDispatch, useSelector } from 'react-redux'
import { getWebSideMenuOpenRequest, getWebSideMenuOpenReset, langugageSwitchOpen, sidebarOpenReset } from 'store/actions/component-actions'
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader'
import { hasPermissions, PERMISSIONS } from 'config/permission'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { adsTypes, vehicles } from 'config/variables'
import { setCreateAdModalOpen } from 'store/actions/public-actions'
const Sidebar = () => {
  const { loading, logout, loggedIn } = useSelector((state: any) => state.auth);
  const{webMenuOpen} = useSelector((state:any)=>state.componentReducers) 
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory()
  const location = useLocation()
  const isMounted = useRef(false)
  const permissionList = useSelector((state: any) => state.user.permissionData);
  const user = JSON.parse(localStorage.getItem('user')|| '{}')
  useEffect(() => {
    if (logout) {
      history.push(routes.authRoutes.login)
    }
  }, [logout])

  useEffect(()=>{
     if(isMounted.current){
      if(webMenuOpen){
        dispatch(getWebSideMenuOpenReset())
      }
     }else{
      isMounted.current = true;
     }    
  },[location.pathname])

  return (
    <Fragment>
      {loading && <ContentLoader />}
      <BoxSidebar bgcolor={'primary.main'}
        className="BoxSidebar"
        sx={{
          maxWidth: 100,
          minWidth: 100,
          borderRadius: '0px 20px 20px 0',
          minHeight: '100vh',
          zIndex:webMenuOpen ? 9991 : 'initial'
        }}
      >
        <Box textAlign="center"
          sx={{
            minHeight: '100vh'
          }}
        >
          <Link to={routes.vehicleRoutes.adsHome}>
            <img src={mainLogo} alt="main-logo" style={{ maxWidth: 73, height: 73, margin: '0 auto' }} />
          </Link>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: 'calc(100vh - 74px)'
            }}
          >
            <Box 
            className="scrollBar"
            sx={{
              textAlign: 'center',
              maxHeight: 'calc(100vh - 100px)',
              overflow: 'auto'
            }}>
              <Tooltip title={`${t(translations.formText.HOME)}`} placement="right">
                <Button component={NavLink} exact to={routes.vehicleRoutes.adsHome} activeClassName='is-active'>
                  <SvgWhiteHome />
                </Button>
              </Tooltip>
              {user.profile_is_complete ?
                <>
                    <Tooltip title={`${t(translations.ads.ADS)}`} placement="right">
                      <Button 
                        component={Link}
                        to={routes.vehicleRoutes.publicAds+'/'+vehicles.car} 
                        className={matchPath(location.pathname , {path:routes.vehicleRoutes.publicAds+'/:vehicle'}) ? 'is-active' :'' }
                        >
                        <SvgAnnounce />
                      </Button> 
                    </Tooltip>

                    <Tooltip title={`${t(translations.summaryVehicleScreen.AUCTIONS_ADS)}`} placement="right">
                      <Button 
                        component={Link} 
                        className={matchPath(location.pathname , {path:routes.vehicleRoutes.auction+'/:vehicle'}) ? 'is-active' : ''}
                        to={routes.vehicleRoutes.auction+'/'+vehicles.car}> 
                        <Svgauction />
                      </Button>
                    </Tooltip>
                  </>: null }
              
                  {hasPermissions(permissionList, PERMISSIONS.FEATURES.VEHICLE, PERMISSIONS.ACTIONS.ADD) &&
                    <Tooltip title={`${t(translations.mobileSideBar.ADDVEHICLE)}`} placement="right">
                      <Button  onClick={()=>dispatch(setCreateAdModalOpen(true))}>
                        <SvgPlus />
                      </Button>
                    </Tooltip>
                  }
                  {user.profile_is_complete ?<>
                  {loggedIn && hasPermissions(permissionList, PERMISSIONS.FEATURES.ADS, PERMISSIONS.ACTIONS.READ) ?
                    <Tooltip title={`${t(translations.ads.MY_ADS)}`} placement="right">
                      <Button component={NavLink} exact to={routes.vehicleRoutes.myAds+`/${vehicles.car}/${adsTypes.classic}`} activeClassName='is-active'>
                        <SvgUserILineIcon />
                      </Button>
                    </Tooltip>
                    : null
                  }
                  {hasPermissions(permissionList, PERMISSIONS.FEATURES.BUYING, PERMISSIONS.ACTIONS.READ) ?
                    <Tooltip title={`${t(translations.mobileSideBar.AUCTIONPLACE)}`} placement="right">
                      <Button component={NavLink} exact to={routes.vehicleRoutes.auctionPlace+routes.vehicleRoutes.auctionPlaceInprogress} activeClassName='is-active'>
                        <SvgUserHammerIcon />
                      </Button>
                    </Tooltip> : null
                  }
                </>
                : null}
              {/* {loggedIn ?
              <Tooltip title="Favorites" placement="right">
                <Button component={NavLink} exact to={routes.vehicleRoutes.favorites} activeClassName='is-active'>
                  <SvgStar />
                </Button>
              </Tooltip>
              :null
              } */}

              <Tooltip title={`${t(translations.mobileSideBar.FAQ)}`} placement="right">
                <Button component={NavLink} exact to={routes.publicRoutes.faq} activeClassName='is-active'>
                  <SvgfaqIcon />
                </Button>
              </Tooltip>

              <Tooltip title={`${t(translations.mobileSideBar.SWITCHL)}`} placement="right">
                <Button onClick={() => {
                  dispatch(langugageSwitchOpen());
                  dispatch(getWebSideMenuOpenReset())
                  }} >
                  <SvgTranslation />
                </Button>
              </Tooltip>
            </Box>           
          </Box>
        </Box>
      </BoxSidebar>
    </Fragment>
  )
}
export default Sidebar;
const BoxSidebar = styled(Box)`
  .MuiButton-root{
    position:relative;
    width: 100%;
    height: 64px;
    opacity: 0.5;
    &:hover, &.is-active, &.active-exact{
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,0.16) 15%, rgba(159,159,214,0) 72%, rgba(0,212,255,0) 100%);
      opacity: 1;
      &::before{
        content:'';
        height: 100%;
        background: ${colorNew.colorYellow};
        width: 5px;
        z-index: 5;
        position: absolute;
        left: 0px;
    }
    &.active-exact-false{
      background:transparent !important;
      &::before{
        display: none;
      }
    }
    &::after{
      content:'';
    }
      }
  }
`