import React from 'react'
import styled from 'styled-components'
import userPlaceholderImage from 'app/assets/Images/userplaceholder.png'
import { SvgCheck, SvgClose } from 'app/components/svgicons/svg'
import { color, colorNew } from 'styles/StyleConstants'
import PropTypes from 'prop-types';
import moment from 'moment';
import NotFound from 'app/components/RepeatComponents/NoFound'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { CircularLoader } from 'app/components/ContantLoader/ContentLoader'
import { SvgTrashIcon } from 'app/components/svgicons/svgNew'
const InvitationNotification = (props: any) => {
    const { t } = useTranslation()
    const { loading } = useSelector((state) => state.company)
    return (
        <>
            {loading ? <CircularLoader style={{}} /> : <Box>
                {props.notificationList && props.notificationList.length > 0 ?
                    props.notificationList.map((notification, i) =>
                        <Wrapper className="px-3 mt-4 mb-4 inactive">
                            <div className="item-all p-3 position-relative">
                                {moment(notification.created_at).isSame(moment(), 'day') ?
                                    <span className="d-inline-block notification-time">{moment(notification.created_at).format("h:mm a")}</span>
                                    :
                                    <span className="d-inline-block notification-time">{moment(notification.created_at).format("DD/MM/yyyy")}</span>
                                }
                                <div className="d-flex">
                                    <img src={notification.image == null ? userPlaceholderImage : notification.image} alt={'user'} width={45} height={45} className="align-self-start" />
                                    <div className="notification-content px-2">
                                        <div className="notification-content-text">
                                            <h2>{notification.title}</h2>
                                            <p className="mb-0">{notification.body}</p>
                                        </div>
                                        {notification.type == "invitation" ?
                                            <>
                                                {notification.status == "in_progress" &&
                                                    <div className='notification-action pt-2'>
                                                        <div className="btns d-flex justify-content-end">
                                                            {/* <span className="text-success">accepted</span>     */}
                                                            <button
                                                                className="cancel-btn d-flex text-center me-2 text-uppercase  py-1 px-2 bg-transparent pointer"
                                                                onClick={() => props.respondNotification(notification.id, "refused", notification.type)}>
                                                                <span className="d-flex flex-column align-items-center me-2">
                                                                    <SvgClose />
                                                                </span> {t(translations.webTexts.DECLINE)}
                                                            </button>
                                                            <button
                                                                className="accept-btn d-flex text-center text-uppercase py-1 px-2 bg-transparent pointer"
                                                                onClick={() => props.respondNotification(notification.id, "accepted", notification.type)}>
                                                                <span className="d-flex flex-column align-items-center me-2">
                                                                    <SvgCheck />
                                                                </span> {t(translations.webTexts.ACCEPT)}
                                                            </button>
                                                        </div>
                                                    </div>}
                                                {notification.status == "accepted" &&
                                                    <div className='notification-action pt-2'>
                                                        <div className="d-flex justify-content-end">
                                                            <span className="text-success">{t(translations.webTexts.ACCEPTED)}</span>
                                                        </div>
                                                    </div>}
                                                {notification.status == "refused" &&
                                                    <div className='notification-action pt-2'>
                                                        <div className="d-flex justify-content-end">
                                                            <span className="text-danger">{t(translations.webTexts.REFUSED)}</span>
                                                        </div>
                                                    </div>}
                                            </>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => props.deleteNotification(notification.id)}><SvgTrashIcon /></button>
                        </Wrapper>
                    )
                    : <NotFound />
                }
            </Box>}
        </>
    )
}
const Wrapper = styled.div`
position:relative;
.btns{
    display:flex;
    gap:10px;
}
&:hover{
    > button{
        opacity:1;
        width:50px;
    }
    /* .item-all{
        transform: translateX(-150px);
    } */
}
> button {
    background-color:#DC4040;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    font-weight: 400;       
    font-size: 14px;
    color:${color.colorWhite};
    border:0;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity:0;
    overflow:hidden;
    white-space: nowrap;
    padding: 16px;
    transition: opacity .2s ease-in-out, 
    width 0.3s ease-in-out;
    cursor: pointer;
    transform: translate(191%, -85%);        
}
    &.inactive{
        padding: 0 20px;
        .item-all{
        }
    }
    .item-all{
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 2px rgb(0 0 0 / 4%);
        border-radius: 6px;
        padding: 16px;
        margin-bottom: 20px;
        img{
            object-fit:cover;
            border-radius: 100%;
            min-height: 45px;
        }
        .notification-time{
            position:absolute;
            right: 30px;
            top:6px;
        }
        .notification-content{
            .notification-content-text{
                h2{
                    margin-bottom: 0;
                    font-size: 21px;
                    font-weight: 400;
                }
                p{
                    color: ${colorNew.colorGrayOnButton};
                    padding-bottom: 10px;
                    margin: 0;
                }
            }
        }
        .notification-action{
            >div{
                button{
                    border-radius:8px;
                    transition:0.2s ease-in-out;
                    &:hover{
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
                    }
                    span{
                        width:20px;
                        height:20px;
                        border-radius:50px;
                    }
                    &.accept-btn{
                        padding:5px;
                        margin-right: 10px;
                        border:1px solid #42B72A;
                        span{
                            background:#42B72A;
                        }
                    }
                    &.cancel-btn{
                        padding:5px;
                        border:1px solid #FF0000;
                        span{
                            background:#FF0000;
                        }
                    }
                    svg{
                        width: 16px;
                        height: 14px;
                        path{
                            stroke:${color.colorWhite};
                        }
                    }
                }
            }
        }
    }
`
InvitationNotification.propTypes = {
    deleteNotification: PropTypes.func,
    respondNotification: PropTypes.func,
    notificationList: PropTypes.array,
};
export default InvitationNotification