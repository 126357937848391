import React from 'react'
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material'
import { SvgClose, SvgSearchLite } from 'app/components/svgicons/svg'
import { translations } from 'locales/translations'
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader'
import { colorNew } from 'styles/StyleConstants'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useSelector } from 'react-redux'
import LicensePlate from './LicensePlate'
import { useWindowSize } from 'app/Hooks/windowResize'


const VehicleListComponent = ({ searchAble, setSearchAble, handleSelectedVehicle, suggestedVehicles, setSearchKeyWord, handleScroll, scrollRef, handleKeyPress, searchKeyWord ,closePopup }) => {
  const { t } = useTranslation();
  const [mobile] = useWindowSize();
  const { vehicleListLoading } = useSelector((state: RootStateOrAny) => state.licensePlate)
  return (
    <Box>      
        <Box className='suggested-vehicles'>
          <Box className={mobile ? 'vehicle-list-mobile' : ''} sx={{ width: '100%'}}>
            <Box className='flex-div vehicle-list' sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
              <Box sx={{textAlign:'center'}}><h4>{t(translations.LICENSEPLATE.SELECT_PRODUCTS)}</h4></Box>
              <Box
                sx={{
                  alignItems: 'center',
                  mr: {
                    md: 'initial',
                    xs: 1
                  },
                  maxWidth: '240px',
                  display: 'flex',
                  flexDirection: 'row',                 
                }}
                onScroll={(e) => handleScroll(e)} ref={scrollRef}
                className='vehicle_search'>
                <TextField
                  variant="standard"
                  type="text"
                  placeholder={t(translations.ads.SEARCH)+" "+t(translations.showCaseForm.TITLE) +',VIN, Refr.'}
                  onChange={handleKeyPress}
                  value={searchKeyWord}
                  InputProps={{
                    endAdornment: <InputAdornment position="end"><SvgSearchLite style={{marginRight:'5px'}}/>
                    </InputAdornment>,
                  }}
                />
              </Box>
            </Box>
            <span onClick={() => closePopup(false)} style={{position:'absolute', right:"15px",top:'10px', cursor: 'pointer'}}>
              <SvgClose style={{width:'18px', height:'18px'}}/>
            </span>
          </Box>
          <Box className={(suggestedVehicles.length == 0 ? " flexRow alignItemsCenter justifyContentCenter" : "")+ ' scrollBar listContainer'}
            sx={{
              overflowY: 'auto',
              height: document.getElementById('openApp') !== null ? 'calc(100vh - 250px) !important' : 'calc(100vh - 285px) !important'
            }}
            onScroll={(e) => handleScroll(e)}
            ref={scrollRef}
          >
            {/* Listing of suggested vehicle for create LicensePlate */}
            {(suggestedVehicles && suggestedVehicles.length > 0) ? suggestedVehicles.map((vehicle, index) => {              
              return <Box key={vehicle.id} onClick={() => handleSelectedVehicle(vehicle)} sx={{ mt: 0, padding: "10px" }}>
                <LicensePlate
                  hideFindableToggle={true}
                  vehicleDetail={vehicle}
                  searchAble={searchAble}
                  setSearchAble={setSearchAble}
                />
              </Box>
            }) :vehicleListLoading ? <ContentLoader /> : 
            <Box>
              <Typography sx={{ color: `${colorNew.colorGrayDark}`}}>{t(translations.LICENSEPLATE.VEHICLE_NOT_FOUND)}</Typography>
            </Box>
            }
          </Box>
        </Box>
    </Box> 
  )
}

export default VehicleListComponent