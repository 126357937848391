export const vehicleRoutes = {
  addVehicle: '/add-vehicle',
  favorites: '/favorites',
  vehicleSummary: '/vehicle-summary',
  typeOfsales: '/type-of-sales',
  publicAds: '/public-ads',
  myAds: '/my-ads',
  adsDetails: '/ads/:slug',
  auction: '/auction',
  park: '/park',
  classic: '/classic',
  live: '/live',
  action: '/action',
  history: '/history',
  hotDeals: '/hot-deals',
  ads: '/ads',
  adsHome: '/',
  auctionAds: '/auction-ads',
  auctionAd: '/public-auction',
  auctionAdsId: '/auction-ads/:id',
  auctions: '/auctions',
  auctionId: '/auctions/:id',
  auctionDetails: '/auction-ads/:id',
  auctionCategory: '/auction-category',
  auctionPlace: '/auction-place',
  auctionPlaceInprogress: '/in-progress',
  auctionPlaceWaiting: '/waiting-approval',
  auctionPlaceOrder: '/order',
  confirmSold: '/confirm_vehicle/:owner_vehicle/:customer_id/:customer_type/:action',
};