import { useCallback, useEffect, useMemo, useState } from 'react';
import CompanyProfileMainComponent from '../components/CompanyProfileMainComponent';
import { getSubsidiaryStored, vehicles } from 'config/variables';
import { useHistory, useLocation } from 'react-router-dom';
import UserLayout from 'app/Component2/Layouts/UserLayout/UserLayout';
import { SvgMobileLeftArrow } from 'app/components/svgicons/svgNew';
import { useDispatch, useSelector } from 'react-redux';
import TopBar from 'app/Component2/Layouts/AdsLayouts/component/TopBar';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { getUserSubsidiaryRequest } from 'store/actions/company-actions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { lan } from '../lan';
import { colorNew } from 'styles/StyleConstants';
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom';
import { envVariables } from 'config/appConfig';
import { Toaster } from 'services/Toaster';
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { addTypes } from 'config/constants';

const Features = (props: any) => {
  // State, constants and other variables
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isSmallDevice, isMediumDevice] = useDeviceType()

  const currentSubsidiaryId = getSubsidiaryStored();

  // local states
  const [subsidiaryId, _subsidiaryId] = useState<number>();
  const [slug, _slug] = useState<string>();

  // Redux
  const { subscriptionCreatedSuccess, subsidiaryDetails } = useSelector((state: any) => state.company)

  //Hooks and effects  
  useEffect(() => {

    if (Object.keys(subsidiaryDetails).length > 1) {
      _slug(subsidiaryDetails.slug);
    }
  }, [subsidiaryDetails]);

  useEffect(() => {
    getSubsidiary();
  }, [location]);


  useEffect(() => {
    if (Object.keys(subscriptionCreatedSuccess).length) {
      dispatch(getUserSubsidiaryRequest())
    }
  }, [subscriptionCreatedSuccess])

  // Functions

  const getSubsidiary = () => {
    let subsidiary: any = '';
    let state = location.state;
    if (state != undefined) {
      subsidiary = state.subsidiary;
      _subsidiaryId(subsidiary);
    }
    if (subsidiary == '' && currentSubsidiaryId != null) {
      subsidiary = currentSubsidiaryId;
      _subsidiaryId(subsidiary);
    }
  }

  const getLanguages = useCallback((slug) => {
    if (i18n.language === 'en-GB' || i18n.language === 'en-US') {
      return `${envVariables.baseUrlHttps}/en/shop/${slug}/${addTypes.classic}/${vehicles.car}`
    } else if (i18n.language) {
      return `${envVariables.baseUrlHttps}/${i18n.language}/shop/${slug}/${addTypes.classic}/${vehicles.car}`
    }
    return `${envVariables.baseUrlHttps}/fr/shop/${slug}/${addTypes.classic}/${vehicles.car}`
  }, [i18n.language])


  const shopUrl = useMemo(() => getLanguages(slug), [i18n.language, slug]);

  const copyHandler = (text) => {
    if (text && navigator) {
      navigator.clipboard.writeText(getLanguages(slug))
      Toaster.success(t(translations.webTexts.SHOP_URL_COPIED))
    }
  }

  return (
    <>
      <UserLayout
        title={t(translations.profilePrivateSideBar.MYPROFILE)}
        profileLink={true}
      >
        <TopBar
          title={t(translations.profilePrivateSideBar.MYPROFILE)}
          iconsFunction={<SvgMobileLeftArrow />}
          onClick={() => history.goBack()}
        />

        <CompanyProfileMainComponent subsidiaryId={subsidiaryId} copyHandler={copyHandler} shopUrl={shopUrl} />
      </UserLayout>
      {/* copy showcase link  */}
      {!isSmallDevice && !isMediumDevice &&
        <Box
          sx={{
            textAlign: "center",
            position: "absolute",
            right: "0",
            top: '450px',
            margin: "25px auto 0 ",
            width: "calc(100% - 234px)",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              padding: "0 0 8px ",
            }}
          >
            {t(translations.companyProfileScreen.SHOPLINK)}
          </Typography>
          <Box
            sx={{
              fontSize: "15px",
              fontWeight: 400,
            }}
            onClick={() => window.open(shopUrl, '_self')}
            className="pointer fw-bold opacity-75">
            {shopUrl}
          </Box>

          <ButtonCustom textBtn={t(...lan.copyLink())} btnColor={`${colorNew.colorBlack}`} clickFunction={() => { copyHandler(slug) }}
            style={{
              margin: "16px auto 0",
              width: "30%",
              bgcolor: "#F2F2F2",
              padding: "11px",
              boderRadius: "4px",
              color: `${colorNew.colorBlack}`
            }}
          />
        </Box>}

    </>
  );
};


export default Features;

