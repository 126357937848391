import { useQueryStringParams } from 'app/Hooks/useParams';
import { getSubsidiaryStored } from 'config/variables';
import { translations } from 'locales/translations';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Toaster } from 'services/Toaster';
import { getSubsidiaryDetailsRequest, privateSwitchRequest } from 'store/actions/company-actions';
import { resetMyAds } from 'store/actions/myAds-actions';
import { getUsersPermissionsRequest } from 'store/actions/user-actions';
import { PrivatePusherClient } from 'websocket';

const SwitchAccountByNotificationClick = ({ }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const subsidiary = getSubsidiaryStored();
    const params = useQueryStringParams();


    // const { userData } = useSelector((state: RootStateOrAny) => state.user)
    const userData = JSON.parse(localStorage.getItem('user') || '');

    useEffect(() => {
        // Switch profile on click on notification
        if (params.subsidiary_id && params.switch === 'true' && params.subName) {
            changeUser(parseInt(params.subsidiary_id), params.subName);
            removeQueryParams();
            return;
        }
        if (params.switch === 'true') {
            changeUser(null, null);
            removeQueryParams();
            return;
        }
    }, []);

    const removeQueryParams = () => {
        // remove query params as landed on my ads from notification (which includes subsidiary_id and subsidiary name as query params)
        const queryParams = new URLSearchParams(location.search);
        const subsidiaryId = queryParams.get('subsidiary_id');
        const switchParam = queryParams.get('switch');
        const subName = queryParams.get('subName');

        // if getting subName and subsidiary Id in url then
        if (subName || subsidiaryId || switchParam) {
            // remove subName &&  subsidiary_id            
            queryParams.delete('subsidiary_id');
            queryParams.delete('switch');
            queryParams.delete('subName');
            history.replace({
                search: queryParams.toString(),
            });
        }
    };
    const changeUser = (id, profileName) => {
        const PrivatePusherClientInstance = PrivatePusherClient()
        dispatch(resetMyAds())
        //  if user loggedin as private user
        if (!subsidiary && id) {
            // then update user permissions and switch to subsidiary
            Toaster.success(`${t(translations.CHAT.ACCOUNT_SWITCH_TO_SUBSIDIARY)} ${profileName}`);
            localStorage.setItem('subsidiary', id);
            PrivatePusherClientInstance?.unsubscribe(`private-inbox.user.${userData.id}`); //and subsidiary to subsidiary
            dispatch(getUsersPermissionsRequest({ subsidiaryId: id }));
            dispatch(getSubsidiaryDetailsRequest({ subsidiary_Id: id, saveSubsidiary: true, subsidiaryId: id }))
        }
        // if user is loggedin as subsidiary then
        else if (id && subsidiary && id != subsidiary) {
            //  Store or update subsidiary id in localstorage
            localStorage.setItem('subsidiary', id);
            PrivatePusherClientInstance?.unsubscribe(
                `private-inbox.subsidiary.${subsidiary}.user.${userData.id}`
            ); //account switches to private to subsidiary
            PrivatePusherClientInstance?.unsubscribe(`private-inbox.user.${userData.id}`); //and subsidiary to subsidiary
            localStorage.setItem('subsidiary', id);
            // then update subsidiary details and permissions
            dispatch(getSubsidiaryDetailsRequest({ subsidiary_Id: id, saveSubsidiary: true, subsidiaryId: id }))
            dispatch(getUsersPermissionsRequest({ subsidiaryId: id }));
            Toaster.success(`${t(translations.CHAT.ACCOUNT_SWITCH_TO_SUBSIDIARY)} ${profileName}`);
        } else if (id && subsidiary && id == subsidiary) {
            //notification for same subsidiary loggedIn
            removeQueryParams();
            return;
        } else {
            // switching from subsidiary to private user account
            if (subsidiary && !id) {
                PrivatePusherClientInstance?.unsubscribe(
                    `private-inbox.subsidiary.${subsidiary}.user.${userData.id}`
                ); //account switches to private to subsidiary
                localStorage.removeItem('subsidiary');
                dispatch(privateSwitchRequest(true));
                dispatch(getUsersPermissionsRequest({}));
                Toaster.success(
                    `${t(translations.CHAT.ACCOUNT_SWITCH_TO_SUBSIDIARY)} ${userData.first_name} ${userData.last_name
                    }`
                );
            }

            return;
        }
    };

    return (
        <></>
    )
}

export default SwitchAccountByNotificationClick