import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { color } from 'styles/StyleConstants';
import { Box, } from '@mui/material';
import { SvgVectorDownArrow } from 'app/components/svgicons/svgNew';
import { theme } from 'config';
import flagPlaceholder from 'app/assets/Images/be.png';
import { useTranslation } from 'react-i18next';
import { localstorageKey } from 'config/constants';
import { CountryDropDownPopup } from 'app/components/GlobalComponent/CountryDropDownPopup';

export const CountryDropDown = (props: any) => {
    const [defSet, _defSet] = useState<boolean>(true);
    const [countries, _countries] = useState<Array<any>>([]);
    const [SelectedCountry, _SelectedCountry] = useState<any>({});
    const [filteredCountry, _filteredCountry] = useState<any>({});
    const [searchedText, _searchedText] = useState<string>('')
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const { t } = useTranslation();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const SelectCountry = (data) => {
        props.countryhandler && props.countryhandler(data)
        _SelectedCountry(data)
        handleClose()
    }
    const defaultSet = (id) => {
        countries && Object.keys(countries).forEach((option, index) => {
            if (defSet && countries[option].length) {
                let def = countries[option][0];
                if (id !== '' && id !== null) {
                    def = countries[option]?.find(x => x.id === id);
                }
                if (def !== undefined) {
                    let data = { id: def.id, name: def.country_name, flag: def.flag_thumbnail, code: def.prefix_call, fullname: def.fullname };
                    _SelectedCountry(data)
                    _defSet(false);
                }
            } else {
                _SelectedCountry(SelectedCountry);
            }
        })
    }

    useEffect(() => {
        if (props.default) {
            defSet && defaultSet(props.default);
        }

    }, [countries, props.default])

    useEffect(() => {
        const countriesObj = localStorage.getItem(localstorageKey.groupedCountries)
        if (countriesObj) {
            _countries(JSON.parse(countriesObj))
        }

    }, [localStorage.getItem(localstorageKey.groupedCountries)])

    useEffect(() => {
        if (countries) {
            _filteredCountry(countries)
        }
    }, [countries])

    useEffect(() => {
        const cloneData: any = countries;
        const cloneDataFilter: any = {};
        Object.keys(cloneData).map((x: any) => {
            const FilterData = cloneData[x].filter((data: any) => (data.fullname.toLowerCase().includes(searchedText.toLowerCase()) || data.prefix_call.includes(searchedText)))
            cloneDataFilter[x] = FilterData
        })
        _filteredCountry(cloneDataFilter)
    }, [searchedText])

    const handleSearch = (e) => {
        _searchedText(e.target.value);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <Wrapper className={`${props.classes}`}>
            <Box
                onClick={(e) => {
                    if (!props.hide) {
                        handleClick(e)
                    }
                }}
                className="country_bg">
                {SelectedCountry &&
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",

                            }}
                            className="country-drop"
                        >
                            <Box
                                className="icon-hold"
                                sx={{
                                    display: 'flex',
                                    alignItems: "center",
                                    justifyContent: "center",
                                    p: "4px",
                                    '._code': {
                                        ml: 2,
                                        'span': {
                                            whiteSpace: 'nowrap'
                                        },
                                    },
                                    '.thumbnailImage': {
                                        width: "50px",
                                        height: "30px",
                                        mr: 1,
                                        [theme.breakpoints.down('sm')]: {
                                            marginRight: '0',
                                            width: '40px',
                                        },
                                    }
                                }}

                            >
                                <img src={SelectedCountry.flag ? SelectedCountry.flag : flagPlaceholder} alt={'flag'} className={"thumbnailImage"} />
                                {props.hide ? null : <SvgVectorDownArrow />}
                            </Box>
                        </Box>
                    </>
                }

            </Box>
            <CountryDropDownPopup t={t} filteredCountry={filteredCountry} searchedText={searchedText} anchorEl={anchorEl} open={open} id={id} handleClose={handleClose} handleSearch={handleSearch} SelectCountry={SelectCountry} />
        </Wrapper>
    )
}
const Wrapper = styled(Box)`
    position: relative;
    display: flex;
    . {
        display: flex;
        .flag{
            width: 50px;
            height: 30px;
            overflow: hidden;
            /* border-radius: 100%; */
            img{
                /* height: 100%;
                width: 100%; */
                object-fit:cover;
            }
        }
        .code{
            white-space: nowrap;
        }
    }
    &.s-hide-name{
        .name{
            display: none !important;
        }
    }
    &.s-hide-fullname{
        .fullname{
            display: none !important;
        }
    }
    &.s-hide-code{
        .code{  
            display: none !important;
        }
    }

    &.d-hide-fullname{
        .country-fullname{
            display: none !important;
        }
    }
    &.d-hide-name{
        .country-name{
            display: none !important;
        }
    }
    &.d-hide-code{
        .code{
            display: none !important;
        }
    }

    ul{
        position: absolute;
        top: 40px;
        left: 0;
        padding: 10px 0px;
        background: #fff;
        box-shadow: 0 0 5px #ccc;
        max-height: 220px;
        overflow: auto;
        z-index: 3;
        min-width: 140px;
        min-height: 250px;
        li{
            align-items: center;
            border-bottom: 1px solid #ccc;
            padding: 5px 10px;
            list-style: none;
            >Box{
                font-size: 12px;
            }
            .flag {
                width: 20px;
                height: 20px;
                overflow: hidden;
                border-radius: 100%;
                margin-right: 5px;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit:cover;
                }
            }
            .code{
                color: ${color.colorGrayDark};
                font-size: 12px;
            }
            .country-name{
                color: ${color.colorGrayDark};
                font-size: 12px;
                margin-right: 10px;
                margin-left: 10px;
            }
        }
    }
`