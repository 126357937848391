import styled from "styled-components";

const StyleWrapper = styled.div`
    border: 1px solid #ECEFFF;
    padding: 16px;
    padding-bottom: 0px;
 h2{
    font-size: 23px;
    font-weight:500;
    margin-top: 0;

}
.filterContainer{
    display:grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr; 
    overflow-x: auto;
    padding-bottom: 15px;
    gap: 0 40px;
    margin-left: 0;
    width: 100%;
    .brandBox{
        min-width: 100%;
        width: 250px;
        padding-left: 0px
    } 
    .link{
        border: 1px solid #f2f2f2;
        color: black;
        font-size: 18px;
        font-weight: 400; 
        width: 100% !important;
        height: 100%;
        display: block;
        text-align: center;
        padding: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
@media (max-width:768px){
    h2{
        font-size:21px;
    }
    .filterContainer{
        gap: 0 20px;
        .brandBox{
            width: 200px;
        }
    }
}
`

export default StyleWrapper