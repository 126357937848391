import { Box } from '@mui/material';
import PublicLayout from 'app/Component2/Layouts/PublicLayout'
import { useDeviceType } from 'app/Hooks/useMediaQuery';
import { SvgArrowBack, SvgMobileLeftArrow } from 'app/components/svgicons/svgNew';
import React, { useEffect } from 'react'
import { CreditPaymentWrapper } from './style/CreditPaymentWrapper';
import SelectedCreditPlan from './component/SelectedCreditPlan';
import MakeCreditPayment from './component/MakeCreditPayment';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { creditsPaymentRequest } from 'store/actions/user-actions';
import { localstorageKey } from 'config/constants';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
const CreditPayment = () => {

    //Hooks
    const [isSmallDevice, isMediumDevice] = useDeviceType()
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //decode price Id from url
    const queryParamPriceId = atob(new URLSearchParams(location.search).get('pid'))
    const previousRoute = localStorage.getItem(localstorageKey.purchaseCredit);

    useEffect(() => {
        // API for getting all payment details
        if (queryParamPriceId)
            dispatch(creditsPaymentRequest({ price_id: queryParamPriceId }));
    }, [queryParamPriceId])

    // Functions 
    const handleGoBack = () => {
        // if user get back from payment checkout to credit-payment screen and
        // payment is not authorized (neither fail nor success payment)
        // then need to redirect back on previous page instead of stripe page
        if (previousRoute) {
            localStorage.removeItem(localstorageKey.purchaseCredit)
            history.push(previousRoute)
            return;
        }
        // if paymnet success or fail then redirect accordingly 
        history.goBack();
    }
    return (
        <PublicLayout
            showNavbar={false}
            hideHeader={isSmallDevice || isMediumDevice}
            backButton={<span className='back-button' onClick={() => handleGoBack()}><SvgMobileLeftArrow /></span>}
            hideFooter={true}
            hideFooterNavBar={true}
        >

            <CreditPaymentWrapper>
                {/* code for header in small screens mobile/tabs */}
                {(isSmallDevice || isMediumDevice) &&
                    <Box className='flexRow justifyContentBetween alignItemsCenter pageHeader'>
                        <SvgArrowBack onClick={() => { history.goBack() }} />
                        <h2>{t(translations.CTB_CREDIT.CREDIT_PAYMENT)}</h2>
                        <SvgArrowBack style={{ visibility: 'hidden' }} />
                    </Box>}
                <Box className='flexRow payment-wrapper'>
                    <SelectedCreditPlan />
                    <MakeCreditPayment />
                </Box>

            </CreditPaymentWrapper>

        </PublicLayout>
    )
}

export default React.memo(CreditPayment)