import { Box, Button, Popover, Tooltip } from '@mui/material'
import { SvgShareIconWhite } from 'app/components/svgicons/svg2'
import { SvgCloseRound, SvgCopyLink } from 'app/components/svgicons/svgNew'
import { translations } from 'locales/translations'
import { localstorageKey } from 'config/constants';
import React from 'react'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from 'react-share'
import { Toaster } from 'services/Toaster'
import { useTranslation } from 'react-i18next';
import { PublicProfileWrapper } from '../../style/publicProfileStyle'
import { useParams } from 'react-router-dom';
import { shareLink } from 'config/utils';


const ShareProfile = ({ followers, following, userName }) => {

    const { t } = useTranslation()
    const { userType, slug } = useParams()

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const title =
        `${userName}\n` +
        `${t(translations.PUBLICPROFILE.FOLLOW)} @${slug}\n\n` +
        `${followers} ${t(translations.PUBLICPROFILE.FOLLOWER)} - ${following} ${t(translations.PUBLICPROFILE.FOLLOWING)}\n\n`

    const shareUrl = window.location.origin + '/' + localStorage.getItem(localstorageKey.i18nextLng) + "/" + userType + "/" + slug;

    const emailBody =
        `${t(translations.SHARE_AD.HELLO)},
${t(translations.SHARE_AD.CHECKOUT_PROFILE)} 
${title}`;

    return (
        <Box>
            <Button aria-describedby={id} className='shareBtn' onClick={(e) => shareLink(e, t(translations.webTexts.SHARED_FROM_CARTOBIKE), title, shareUrl, setAnchorEl)}>
                <Tooltip title={t(translations.PUBLICPROFILE.SHARE_PROFILE)}>
                    <SvgShareIconWhite style={{ filter: 'contrast(0.5)' }} />
                </Tooltip>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <PublicProfileWrapper>
                    <Box className='shareContainer' sx={{
                        'button': {
                            textAlign: 'left',
                            'svg': {
                                mr: '13px'
                            }
                        },
                        '.close-icon': {
                            fontWeight: 600,
                            'svg': {
                                margin: '0',
                                position: 'absolute',
                                right: '18px',
                                top: '18px',
                            }
                        }
                    }}>
                        <Box className='flexRow justifyContentBetween width-100'>
                            <span>{t(translations.PUBLICPROFILE.SHARE_PROFILE)}</span>
                            <span onClick={() => handleClose()} className='pointer'><SvgCloseRound /></span>
                        </Box>
                        <FacebookShareButton
                            url={shareUrl}
                            quote={title}
                            hashtag={title + "#CarToBike"}
                            className="some-network_share-button width-100 pointer"
                        >
                            <FacebookIcon size={32} round />{t(translations.PUBLICPROFILE.FACEBOOK)}
                        </FacebookShareButton>

                        <EmailShareButton
                            url={shareUrl}
                            subject={"Cartobike.com - Profile"}
                            body={emailBody}
                            className="network__share-button width-100 pointer"
                        >
                            <EmailIcon size={32} round />{t(translations.PUBLICPROFILE.EMAIL)}
                        </EmailShareButton>
                        <TwitterShareButton
                            url={shareUrl}
                            title={title}
                            hashtags={["CarToBike", "Profile"]}
                            className="network__share-button width-100 pointer"
                        >
                            <XIcon size={32} round />{t(translations.PUBLICPROFILE.TWITTER)}
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={shareUrl}
                            title={title}
                            separator={""}
                            className="network_share-button width-100 pointer"
                        >
                            <WhatsappIcon size={32} round />{t(translations.PUBLICPROFILE.WHATSAPP)}
                        </WhatsappShareButton>
                        <Box
                            className="some-network_share-button width-100 pointer"
                            sx={{
                                'svg': {
                                    mr: "13px",
                                    width: 32
                                }
                            }}
                            onClick={() => {
                                if ('clipboard' in navigator) {
                                    navigator.clipboard.writeText(shareUrl)
                                    Toaster.success(t(translations.webTexts.COPIED))
                                } else {
                                    document.execCommand('copy', true, shareUrl);
                                    Toaster.success(t(translations.webTexts.COPIED))
                                }
                            }}
                        >
                            <SvgCopyLink />{t(translations.PUBLICPROFILE.COPYLINK)}
                        </Box>
                    </Box>
                </PublicProfileWrapper>
            </Popover>
        </Box>
    )
}

export default ShareProfile