import { put, select, takeEvery } from 'redux-saga/effects';
import { onlineUsersFailure, onlineUsersRequest, onlineUsersSuccess } from 'store/actions/onlineUser-actions';

//make User Online 
function* _onlineUsersRequest({ payload }) {
  try {
    const userStatus = yield select(state => state.chat);
    let onlineUsers: any = []
    if (payload.status === 'online') {
      onlineUsers = [...userStatus.onlineUserData, ...payload.onlineMembers]
    }
    if (payload.status === 'member_added') {
      onlineUsers = [...userStatus.onlineUserData, payload.onlineMembers.id]
    }
    if (payload.status === 'member_removed') {
      let array = [...userStatus.onlineUserData]
      let index = array.findIndex(o => o == payload.onlineMembers.id)
      if (index !== -1) {
        array.splice(index, 1)
      }
      onlineUsers = array
    }
    const unique = (arr) => [...new Set(arr)];
    yield put(onlineUsersSuccess(unique(onlineUsers)))
  } catch (e: any) {
    yield put(onlineUsersFailure(e.response));
  }
}

export const onlineUserssaga = [
  takeEvery(onlineUsersRequest, _onlineUsersRequest),
];
