import { createAction } from 'redux-actions';

export const loginRequest = createAction('LOGIN_REQUEST');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILURE');
export const loginReset = createAction('LOGIN_RESET');

export const registerRequest = createAction('REGISTER_REQUEST');
export const registerFailure = createAction('REGISTER_FAILURE');
export const registerSuccess = createAction('REGISTER_SUCCESS');

export const activateAccountRequest = createAction('ACTIVATE_ACCOUNT_REQUEST');
export const activateAccountFailure = createAction('ACTIVATE_ACCOUNT_FAILURE');
export const activateAccountSuccess = createAction('ACTIVATE_ACCOUNT_SUCCESS');

export const forgotRequest = createAction('FORGOT_REQUEST');
export const forgotFailure = createAction('FORGOT_FAILURE');
export const forgotSuccess = createAction('FORGOT_SUCCESS');

export const resetPasswordRequest = createAction('RESET_PASSWORD_REQUEST');
export const resetPasswordFailure = createAction('RESET_PASSWORD_FAILURE');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');

export const logoutRequest = createAction('LOGOUT_REQUEST');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const logoutFailure = createAction('LOGOUT_FAILURE');

export const resendEmailRequest = createAction('RESEND_EMAIL_REQUEST');
export const resendEmailSuccess = createAction('RESEND_EMAIL_SUCCESS');
export const resendEmailFailure = createAction('RESEND_EMAIL_FAILURE');

export const verifyIdRequest = createAction('VERIFY_ID_REQUEST');
export const verifyIdSuccess = createAction('VERIFY_ID_SUCCESS');
export const verifyIdFailure = createAction('VERIFY_ID_FAILURE');

export const verifiedIdentityRequest = createAction('VERIFIED_IDENTITY_REQUEST');
export const verifiedIdentitySuccess = createAction('VERIFIED_IDENTITY_SUCCESS');
export const verifiedIdentityFailure = createAction('VERIFIED_IDENTITY_FAILURE');
export const verifiedIdentityReset = createAction('VERIFIED_IDENTITY_RESET');

export const getActiveLanguageRequest = createAction('ACTIVE_LANGUAGE_REQUEST');
export const getActiveLanguageSuccess = createAction('ACTIVE_LANGUAGE_SUCCESS');
export const getActiveLanguageFailure = createAction('ACTIVE_LANGUAGE_FAILURE');

export const changeLanguageRequest = createAction('CHANGE_LANGUAGE_REQUEST');
export const changeLanguageSuccess = createAction('CHANGE_LANGUAGE_SUCCESS');
export const changeLanguageFailure = createAction('CHANGE_LANGUAGE_FAILURE');

export const saveDeviceTokenRequest = createAction('SAVE_DEVICE_TOKEN_REQUEST');
export const saveDeviceTokenSuccess = createAction('SAVE_DEVICE_TOKEN_SUCCESS');
export const saveDeviceTokenFailure = createAction('SAVE_DEVICE_TOKEN_FAILURE');

export const removeDeviceTokenRequest = createAction('REMOVE_DEVICE_TOKEN_REQUEST');
export const removeDeviceTokenSuccess = createAction('REMOVE_DEVICE_TOKEN_SUCCESS');
export const removeDeviceTokenFailure = createAction('REMOVE_DEVICE_TOKEN_FAILURE');

export const getFiltersRequest = createAction('GET_FILTERS_REQUEST')
export const getFiltersSuccess = createAction('GET_FILTERS_SUCCESS')
export const getFiltersFailure = createAction('GET_FILTERS_FAILURE')

export const updateMyWalletBalance = createAction('UPDATE_WALLET_BALANCE')