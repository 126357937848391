import { put, takeEvery } from 'redux-saga/effects';
import { ApiPaths } from "services/ApiPaths";
import Api from "services/axios";
import { addMemberFailure, addMemberRequest, addMemberSuccess, archiveParticularChatFailure, archiveParticularChatRequest, archiveParticularChatSuccess, blockParticularChatFailure, blockParticularChatRequest, blockParticularChatSuccess, chatStoreFailure, chatStoreSuccess, deleteParticularChatFailure, deleteParticularChatRequest, deleteParticularChatSuccess, getArchivedChatFailure, getArchivedChatRequest, getArchivedChatSuccess, getBlockedChatFailure, getBlockedChatRequest, getBlockedChatSuccess, getChatFailure, getChatRequest, getChatSuccess, getUserListFailure, getUserListRequest, getUserListSuccess, getsubsidiaryActiveMemberListFailure, getsubsidiaryActiveMemberListRequest, getsubsidiaryActiveMemberListSuccess, makeReceptionistFailure, makeReceptionistRequest, makeReceptionistSuccess, muteAllChatFailure, muteAllChatRequest, muteAllChatSuccess, muteParticularChatFailure, muteParticularChatRequest, muteParticularChatSuccess, particularChatFailure, particularChatRequest, particularChatSuccess, sendMessageFailure, sendMessageRequest, sendMessageSuccess, unreadAllChatFailure, unreadAllChatRequest, unreadAllChatSuccess, unreadParticularChatFailure, unreadParticularChatRequest, unreadParticularChatSuccess, updateGroupDetailFailure, updateGroupDetailRequest, updateGroupDetailSuccess, blockedByFailure, blockedByRequest, blockedBySuccess, chatStoreRequest, } from "store/actions/chat-action";

// get All Chat List Saga function
function* _getChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + "?per_page=25&page=" + payload.page
    const { data } = yield Api.get(uri, {});
    yield put(getChatSuccess({ data: data, append: payload.page !== 1 ? true : false }))
  } catch (e: any) {
    yield put(getChatFailure(e.response));
  }
}

// get Archieved chat list Saga function
function* _getArchivedChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + "?per_page=25&filter[archived]=1" + "&page=" + payload.page
    const { data } = yield Api.get(uri, {});
    yield put(getArchivedChatSuccess({ data: data, append: true }))
  } catch (e: any) {
    yield put(getArchivedChatFailure(e.response));
  }
}

// get Blocked chat list Saga function
function* _getBlockedChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + "?per_page=25&filter[blocked]=1" + "&page=" + payload.page
    const { data } = yield Api.get(uri, {});
    yield put(getBlockedChatSuccess({ data: data, append: true }))
  } catch (e: any) {
    yield put(getBlockedChatFailure(e.response));
  }
}

// get all user's list Saga function 
function* _getUserListRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + ApiPaths.chat.users + "?"
    if (payload?.searchByLicensePlate) {
      uri += 'search_by_license_plate=' + payload.searchByLicensePlate + "&"
    }
    if (payload?.license_plate) {
      uri += 'license_plate=' + payload.license_plate + "&"
    }
    if (payload?.country_id) {
      uri += 'country_id=' + payload.country_id
    }
    if (payload?.keyword) {
      uri += 'keyword=' + payload.keyword + "&"
    }

    if (payload?.page) {
      uri += 'page=' + payload.page + "&"
    }
    const { data } = yield Api.get(uri, {});
    yield put(getUserListSuccess(data))
  } catch (e: any) {
    yield put(getUserListFailure(e.response));
  }
}


// create chat  user's list Saga function 
function* _chatStoreRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats
    const { data } = yield Api.post(uri, payload);
    yield put(chatStoreSuccess(data))
  } catch (e: any) {
    yield put(chatStoreFailure(e.response));
  }
}

// delete particular chat request 
function* _deleteParticularChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + `/${payload}`
    const { data } = yield Api.delete(uri, {});
    yield put(deleteParticularChatSuccess(data))
  } catch (e: any) {
    yield put(deleteParticularChatFailure(e.response));
  }
}

// block particular chat request 
function* _blockParticularChatRequest({ payload }) {
  try {
    let obj = {
      blocked_entity_id: payload.id,
      type: payload.type
    }
    let uri = ApiPaths.chat.chats + `${!payload.isblocked ? ApiPaths.chat.block : ApiPaths.chat.unblock}`
    const { data } = yield Api.post(uri, obj);
    yield put(blockParticularChatSuccess(data))
  } catch (e: any) {
    yield put(blockParticularChatFailure(e.response));
  }
}
// get particular chat request 
function* _getParticularChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + `/${payload.id}` + ApiPaths.chat.messages + "?per_page=50&page=" + payload.page
    const { data } = yield Api.get(uri, {});
    yield put(particularChatSuccess(data))
  } catch (e: any) {
    yield put(particularChatFailure(e.response));
  }
}

// archive particular chat request 
function* _archiveParticularChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + `/${payload.id}` + ApiPaths.chat.archive + "?_method=patch"
    const { data } = yield Api.post(uri, {
      is_archived: payload.data
    });
    yield put(archiveParticularChatSuccess(data))
  } catch (e: any) {
    yield put(archiveParticularChatFailure(e.response));
  }
}

// mute particular chat request 
function* _muteParticularChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + `/${payload.id}` + ApiPaths.chat.mute + "?_method=patch"
    const { data } = yield Api.post(uri, {
      is_muted: payload.data
    });
    yield put(muteParticularChatSuccess(data))
  } catch (e: any) {
    yield put(muteParticularChatFailure(e.response));
  }
}

// read/unread particular chat request 
function* _unreadParticularChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + `/${payload.id}` + ApiPaths.chat.read + "?_method=patch"
    const { data } = yield Api.post(uri, {
      is_read: payload.is_read
    });
    yield put(unreadParticularChatSuccess(data))
  } catch (e: any) {
    yield put(unreadParticularChatFailure(e.response));
  }
}

// mute all chat request 
function* _muteAllChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + ApiPaths.chat.mute + ApiPaths.chat.all
    const { data } = yield Api.post(uri, {
      is_muted: payload.is_muted
    });
    yield put(muteAllChatSuccess(data))
  } catch (e: any) {
    yield put(muteAllChatFailure(e.response));
  }
}

// read/unread all chat request 
function* _unreadAllChatRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + ApiPaths.chat.read + ApiPaths.chat.all
    const { data } = yield Api.post(uri, {
      is_read: payload.is_read
    });
    yield put(unreadAllChatSuccess(data))
  } catch (e: any) {
    yield put(unreadAllChatFailure(e.response));
  }
}

// send message request 
function* _sendMessageRequest({ payload }) {
  try {
    const formData = new FormData();
    formData.append('type', payload.type)
    formData.append('message', payload.message)
    formData.append('refId', payload.refId)
    if (payload.attachment.length) {
      payload.attachment.forEach(element => {
        formData.append('attachment[]', element)
      });
    }
    let files = JSON.stringify(payload.attachment)
    let uri = ApiPaths.chat.chats + `/${payload.chat_id}` + ApiPaths.chat.messages
    const { data } = yield Api.post(uri, formData);
    yield put(sendMessageSuccess(data))
  } catch (e: any) {
    if (e.response && e.response.data) {
      e.response.data['refId'] = payload.refId
    }
    yield put(sendMessageFailure(e.response));
  }
}

//Make User as Receptionist
function* _makeReceptionistRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.subsidiaries + `/${payload.subsidiary_id}` + ApiPaths.chat.users + `/${payload.user_id}` + ApiPaths.chat.markReceptionist + '?_method=PATCH'
    const { data } = yield Api.post(uri, { user_id: payload.user_id });
    yield put(makeReceptionistSuccess(data))
  } catch (e: any) {
    yield put(makeReceptionistFailure(e.response));
  }
}

// Suggested member list for group (subsidiarywise)

function* _getsubsidiaryActiveMemberListRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + `/${payload.subsidiary_id}` + ApiPaths.chat.members
    const { data } = yield Api.get(uri, {});
    yield put(getsubsidiaryActiveMemberListSuccess(data))
  } catch (e: any) {
    yield put(getsubsidiaryActiveMemberListFailure(e.response));
  }
}

// blocked by users/subsidiaries request 
function* _blockedByRequest({ payload }) {
  try {
    let uri = ApiPaths.chat.chats + ApiPaths.chat.blockedBy
    const { data } = yield Api.get(uri, {});
    yield put(blockedBySuccess(data))
  } catch (e: any) {
    yield put(blockedByFailure(e.response));
  }
}



function* _addMemberRequest({ payload }) {
  try {
    const uniqueMembers = [...new Set(payload.member)];
    let uri = ApiPaths.chat.chats + `/${payload.chat_id}` + ApiPaths.chat.addMember
    const { data } = yield Api.post(uri, { members: uniqueMembers });
    yield put(addMemberSuccess(data))
  } catch (e: any) {
    yield put(addMemberFailure(e.response));
  }
}

function* _updateGroupDetailRequest({ payload }) {

  try {
    const uniqueRemovedMembers = [...new Set(payload.remove_member_ids)];
    let uri = ApiPaths.chat.chats + `/${payload.chat_id}` + ApiPaths.chat.save_setting
    const { data } = yield Api.post(uri, { remove_member_ids: uniqueRemovedMembers, title: payload.title });
    yield put(updateGroupDetailSuccess(data))
  } catch (e: any) {
    yield put(updateGroupDetailFailure(e.response));
  }
}

export const chatsaga = [
  takeEvery(getChatRequest, _getChatRequest),
  takeEvery(getArchivedChatRequest, _getArchivedChatRequest),
  takeEvery(getBlockedChatRequest, _getBlockedChatRequest),
  takeEvery(getUserListRequest, _getUserListRequest),
  takeEvery(chatStoreRequest, _chatStoreRequest),
  takeEvery(particularChatRequest, _getParticularChatRequest),
  takeEvery(deleteParticularChatRequest, _deleteParticularChatRequest),
  takeEvery(blockParticularChatRequest, _blockParticularChatRequest),
  takeEvery(archiveParticularChatRequest, _archiveParticularChatRequest),
  takeEvery(unreadParticularChatRequest, _unreadParticularChatRequest),
  takeEvery(muteParticularChatRequest, _muteParticularChatRequest),
  takeEvery(muteAllChatRequest, _muteAllChatRequest),
  takeEvery(unreadAllChatRequest, _unreadAllChatRequest),
  takeEvery(sendMessageRequest, _sendMessageRequest),
  takeEvery(makeReceptionistRequest, _makeReceptionistRequest),
  takeEvery(getsubsidiaryActiveMemberListRequest, _getsubsidiaryActiveMemberListRequest),
  takeEvery(addMemberRequest, _addMemberRequest),
  takeEvery(updateGroupDetailRequest, _updateGroupDetailRequest),
  takeEvery(blockedByRequest, _blockedByRequest)
];
