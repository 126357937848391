import { Box, Checkbox, FormControlLabel, Radio } from '@mui/material'
import SearchDropdown from 'app/components/CustomFields/searchDropdown'
import TextInput from 'app/components/CustomFields/textInput'
import TextMultipleInput from 'app/components/CustomFields/textMultipleInput'
import { Nullable } from 'app/pages/PublicProfile/interface/interface'
import React, { useEffect, useState } from 'react'
import { MAX_HOURS, MAX_MINUTES, convertSeconds, powerTypeStatus } from '../filterConfig'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import SearchDropdownMultipleInput from 'app/components/CustomFields/searchDropdownMultipleInput'
import { getParamQueries } from 'config/appConfig'
import { useLocation } from 'react-router-dom'
import { Toaster } from 'services/Toaster'
import { useQueryStringParams } from 'app/Hooks/useParams'
import { vehicles } from 'config/variables'

const Performance = ({ filterData, updateURL, vehicle }) => {

    const { t } = useTranslation()
    const location = useLocation()

    const [leftDropdownValues, _leftDropdownValues] = useState<Array<number>>([])
    const [rightDropdownValues, _rightDropdownValues] = useState<Array<number>>([])

    const [powerType, _powerType] = useState<Nullable<number>>(powerTypeStatus.KW)
    const [powerMin, _powerMin] = useState<Nullable<number>>()
    const [powerMax, _powerMax] = useState<Nullable<number>>()
    const [euroStandard, _euroStandard] = useState<Nullable<number>>()
    const [homeChargingHour, _homeChargingHour] = useState<Nullable<number>>()
    const [homeChargingMinute, _homeChargingMinute] = useState<Nullable<number>>()
    const [fastChargingHour, _fastChargingHour] = useState<Nullable<number>>()
    const [fastChargingMinute, _fastChargingMinute] = useState<Nullable<number>>()
    const [drivingRange, _drivingRange] = useState<Nullable<number>>()

    const [isElectric, setisElectric] = useState<Nullable<boolean>>(false);
    const { fuel_id } = useQueryStringParams()

    useEffect(() => {
        if (fuel_id) {
            const isElctrc = getIsElectric(fuel_id)
            setisElectric(isElctrc);
        }
    }, [filterData, fuel_id])

    useEffect(() => {
        let hoursArray: number[] = []
        let minuteArray: number[] = []
        for (let i = 0; i < MAX_HOURS; i++) {
            hoursArray.push(i)
        }
        for (let i = 0; i < MAX_MINUTES; i++) {
            minuteArray.push(i)
        }
        _leftDropdownValues(hoursArray)
        _rightDropdownValues(minuteArray)
    }, [])

    // Sets values got from url to fields (runs only when page is rendered for the first time)
    useEffect(() => {
        let payload = getParamQueries(location.search)
        if (payload.power_kw_start) {
            _powerType(powerTypeStatus.KW)
            _powerMin(payload.power_kw_start)
        }
        if (payload.power_kw_end) {
            _powerType(powerTypeStatus.KW)
            _powerMax(payload.power_kw_end)
        }
        if (payload.power_cv_start) {
            _powerType(powerTypeStatus.HP)
            _powerMin(payload.power_cv_start)
        }
        if (payload.power_cv_end) {
            _powerType(powerTypeStatus.HP)
            _powerMax(payload.power_cv_end)
        }
        _euroStandard(payload.euro_standard_level ?? null)
        _drivingRange(payload.battery_range_max ?? null)
        convertSeconds(_homeChargingHour, _homeChargingMinute, payload.charge_duration_home_80_end ?? null)
        convertSeconds(_fastChargingHour, _fastChargingMinute, payload.charge_duration_fast_80_end ?? null)
    }, [location.search])


    useEffect(() => {
        if (homeChargingHour != null && homeChargingMinute != null) {
            let seconds = ((homeChargingHour * 60) + homeChargingMinute) * 60
            updateURL('charge_duration_home_80_end', seconds)
        }
    }, [homeChargingHour, homeChargingMinute])


    useEffect(() => {
        if (fastChargingHour != null && fastChargingMinute != null) {
            let seconds = ((fastChargingHour * 60) + fastChargingMinute) * 60
            updateURL('charge_duration_fast_80_end', seconds)
        }
    }, [fastChargingHour, fastChargingMinute])


    const handlePowerType = (status) => {
        _powerType(status)
        updateURL('power_cv_start', '')
        updateURL('power_kw_start', '')
        updateURL('power_cv_end', '')
        updateURL('power_kw_end', '')
        _powerMin(null)
        _powerMax(null)
    }

    // check if selected fuel types contains electric fuel type or not,
    const getIsElectric = (fuelIds) => {
        if (fuelIds) {
            const fuelIdsArray = fuelIds?.split(',') || [];
            for (let fuelId of fuelIdsArray) {
                if (filterData) {
                    const fuelType = filterData?.fuelList?.items.find((item: any) => item.id == fuelId)
                    if (fuelType?.is_electric == 1 || fuelType?.is_hybrid == 1) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const handlePowerMin = (value) => {
        _powerMin(value)
        if (powerMax && powerMax < parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MIN_ERROR))
            return
        }
        if (powerType == powerTypeStatus.KW) {
            updateURL('power_cv_start',)
            updateURL('power_kw_start', value)
        }
        if (powerType == powerTypeStatus.HP) {
            updateURL('power_cv_start', value)
            updateURL('power_kw_start', '')
        }
    }

    const handlePowerMax = (value) => {
        _powerMax(value)
        if (powerMin && powerMin > parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MAX_ERROR))
            return
        }
        if (powerType == powerTypeStatus.KW) {
            updateURL('power_cv_end',)
            updateURL('power_kw_end', value)
        }
        if (powerType == powerTypeStatus.HP) {
            updateURL('power_cv_end', value)
            updateURL('power_kw_end', '')
        }
    }

    const handleEuroStandard = (value) => {
        if (euroStandard == value) {
            _euroStandard(null)
            updateURL('euro_standard_level', '')
            return
        }
        _euroStandard(value)
        updateURL('euro_standard_level', value)
    }

    const handleHomeChargingHour = (value) => {
        _homeChargingHour(value)
    }

    const handleHomeChargingMinute = (value) => {
        _homeChargingMinute(value)
    }

    const handleFastChargingHour = (value) => {
        _fastChargingHour(value)
    }

    const handleFastChargingMinute = (value) => {
        _fastChargingMinute(value)
    }

    const handleDrivingRange = (value) => {
        _drivingRange(value)
        updateURL('battery_range_max', value)
    }

    return (
        <Box sx={{ gap: '24px', mt: 1 }} className='flexColumn'>
            <TextMultipleInput title={t(translations.FILTER_COMPONENT.POWER) + " " + (powerType == powerTypeStatus.KW ? t(translations.FILTER_COMPONENT.FILTER_COMPONENT_KW) : t(translations.FILTER_COMPONENT.FILTER_COMPONENT_CV))} labelFirstField={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FROM)} labelSecondField={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_TO)} handleFirstField={(value) => handlePowerMin(value)} handleSecondField={(value) => handlePowerMax(value)} firstFieldValue={powerMin} secondFieldValue={powerMax} key={powerType} />
            <Box className='flexRow justifyContentBetween' sx={{ px: 1 }}>
                <FormControlLabel control={<Radio checked={powerType == powerTypeStatus.KW} onChange={() => handlePowerType(powerTypeStatus.KW)} />} label={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_KW)} sx={{ mr: 0 }} className='filterCheckBox' />
                <FormControlLabel control={<Radio checked={powerType == powerTypeStatus.HP} onChange={() => handlePowerType(powerTypeStatus.HP)} />} label={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_CV)} sx={{ mr: 0 }} className='filterCheckBox' />
            </Box>
            {vehicle === vehicles.car ? <SearchDropdown title={t(translations.ads.EURO_STANDARD)} list={filterData?.euroList?.items ?? []} value={euroStandard} handleChange={(id) => handleEuroStandard(id)} /> : null}
            {
                isElectric ?
                    <>
                        <SearchDropdownMultipleInput title={t(translations.FILTER_COMPONENT.HOME_CHARGING_TIME)} labelFirstField={t(translations.ads.HOURS)} labelSecondField={t(translations.ads.MINUTES)} listfirstField={leftDropdownValues} listsecondField={rightDropdownValues} handleFirstField={(value) => handleHomeChargingHour(value)} handleSecondField={(value) => handleHomeChargingMinute(value)} firstFieldValue={homeChargingHour} secondFieldValue={homeChargingMinute} />
                        <SearchDropdownMultipleInput title={t(translations.FILTER_COMPONENT.FAST_CHARGING_TIME)} labelFirstField={t(translations.ads.HOURS)} labelSecondField={t(translations.ads.MINUTES)} listfirstField={leftDropdownValues} listsecondField={rightDropdownValues} handleFirstField={(value) => handleFastChargingHour(value)} handleSecondField={(value) => handleFastChargingMinute(value)} firstFieldValue={fastChargingHour} secondFieldValue={fastChargingMinute} />
                        <TextInput title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_DRIVING_RANGE)} preText={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_FROM) + "."} postText={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_KM)} value={drivingRange} handleChange={(value) => handleDrivingRange(value)} />
                    </> : null
            }
        </Box>
    )
}

export default Performance