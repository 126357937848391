import { Box } from '@mui/material';
import { useCountdown } from 'app/Hooks/useCoundown';
import { hotDealAdStatus } from 'config/constants';
import { translations } from 'locales/translations';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

const HotDealStatus = ({ adDetail }) => {

    const [timer, _timer] = useState<string>('')
    const [updateTimer, _updateTimer] = useState<boolean>(false)

    const { t } = useTranslation()
    const currentTime = new Date().getTime();
    const [days, hours, minutes, seconds] = useCountdown(timer);


    const [status, _status] = useState<string>("")

    useEffect(() => {
        const setValuesforStatus = () => {
            if (adDetail && Object.keys(adDetail).length > 0) {
                if (adDetail.ad_status === hotDealAdStatus.sold) {
                    _timer(null)
                    return
                }
                const hotdealStartin = new Date(adDetail.start_hotdeal).getTime();
                const hotdealEndTime = new Date(adDetail.end_hotdeal).getTime();

                if (currentTime < hotdealStartin) {
                    _timer(adDetail.start_hotdeal)
                    _status(t(translations.HOT_DEALS.HOT_DEAL_STARTS_IN))
                }

                if (currentTime > hotdealStartin && currentTime < hotdealEndTime) {
                    _timer(adDetail.end_hotdeal)
                    _status(t(translations.HOT_DEALS.HOT_DEAL_ENDING_IN))
                }
            }
        }
        setValuesforStatus()
    }, [adDetail.ad_status, updateTimer])

    useEffect(() => {
        // Function that changes timer from hotdeal start to hotdeal end when counter ends
        const resetTimer = () => {
            if (days == '00' && hours == '00' && minutes == '00' && seconds == '00') {
                if (adDetail && Object.keys(adDetail).length > 0) {
                    const time = new Date(timer).getTime();
                    setTimeout(() => {
                        const currentTime = new Date().getTime();
                        if (currentTime > time) {
                            _updateTimer(true)
                        }
                    }, 1000)
                }
            }
        }
        resetTimer()
    }, [seconds, hours, minutes, days])


    return (
        <Box className='flexColumn alignItemsCenter statusTextContainer' sx={{
            position: 'relative',
            width: '80%',
            '.divider': {
                border: '1px solid #DCDCDC',
                position: 'absolute',
                width: '100%',
                top: '9px'
            },
            '.status': {
                zIndex: 2
            }
        }}>
            <span className='divider'></span>
            <span className={`eventEnds status`}>{status}</span>
        </Box>
    )
}

export default HotDealStatus