import { Box, Typography } from '@mui/material'
import { SvgRemoveIcon } from 'app/components/svgicons/svg'
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';
import React from 'react'
import { addMember } from '../../interface/interface';

export const MemberAdded = ({ memberList, handleRemoveMember }: addMember) => {
    return <Box className='scrollBar flexRow alignItemsCenter'
        sx={{
            background: "#f8f8f8",
            width: '100%',
            fontSize: "15px",
            padding: '5px 15px',
            color: "#6579F4",
            lineHeight: "18px",
            overflowX: 'scroll',
        }}
    >
        {memberList.length > 0 && memberList.map((member, index) => {
            return (<>
                {member && Object.keys(member).length > 0 && <Box key={member.id} className='flexRow alignItemsCenter'>
                    <Box className='userProfileView' sx={{ mr: 1 }}>
                        <img src={member?.profile_picture_url ? member.profile_picture_url : userPlaceHolder} alt="User Image" />
                    </Box>
                    <Typography sx={{ mr: 1 }} className='addedUserName' title={member.name}>
                        {member.name.substring(0, 18) + '...'}
                    </Typography>
                    <Box className={'pointer'} sx={{ mr: 1 }} onClick={() => handleRemoveMember(member.id)}>
                        <SvgRemoveIcon />
                    </Box>
                </Box>}

            </>)
        })}
    </Box>
}

export default MemberAdded