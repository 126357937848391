import { Box, Checkbox } from '@mui/material'
import SearchDropdown from 'app/components/CustomFields/searchDropdown'
import { Nullable } from 'app/pages/PublicProfile/interface/interface'
import React, { useEffect, useState } from 'react'
import { MAX_NO_OF_OWNERS, isVehicleElectric } from '../filterConfig'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import TextInput from 'app/components/CustomFields/textInput'
import { urlParamInterface } from 'config/interface'
import { useLocation, useParams } from 'react-router-dom'
import { vehicles } from 'config/variables'
import { getParamQueries } from 'config/appConfig'

const Electric = ({ updateURL }) => {

    const { t } = useTranslation()
    const { vehicle }: urlParamInterface = useParams();
    const location = useLocation()


    const [isElectric, _isElectric] = useState<boolean>(false)
    const [autonomy, _autonomy] = useState<Nullable<number>>()
    const [topSpeed, _topSpeed] = useState<Nullable<number>>()
    const [enginePower, _enginePower] = useState<Nullable<number>>()

    // Sets values got from url to fields (runs only when page is rendered for the first time)
    useEffect(() => {
        let payload = getParamQueries(location.search)
        _isElectric(payload.is_electric ?? null)
        _autonomy(payload.battery_range_max ?? null)
        _topSpeed(payload.top_speed ?? null)
        _enginePower(payload.motor_power_watt ?? null)
    }, [location.search])

    const handleAutonomy = (value) => {
        _autonomy(value)
        updateURL('battery_range_max', value)
    }
    const handleTopSpeed = (value) => {
        _topSpeed(value)
        updateURL('top_speed', value)
    }
    const handleEnginePower = (value) => {
        _enginePower(value)
        updateURL('motor_power_watt', value)
    }

    const handleElectric = () => {
        _isElectric(!isElectric)
        updateURL('is_electric', isElectric ? '' : isVehicleElectric.true)
        // Removing filters if vehicle is not electric 
        // Checking true condition as it checks prev condition
        if (isElectric) {
            updateURL('battery_range_max', '')
            updateURL('top_speed', '')
            updateURL('motor_power_watt', '')
        }
    }

    const fieldText = (vehicle) => {
        if (vehicle == vehicles.step) {
            return `${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_ELECTRIC_SCOOTER)}`
        }
        if (vehicle == vehicles.bike) {
            return `${t(translations.FILTER_COMPONENT.FILTER_COMPONENT_ELECTRIC_BIKE)}`
        }
    }

    return (
        <Box sx={{ gap: '24px', mt: 1 }} className='flexColumn'>
            <Box className='flexRow justifyContentBetween alignItemsCenter'>
                <span className='vatCheckBoxLabel'> {fieldText(vehicle)} </span>
                <Checkbox checked={isElectric} onChange={handleElectric} />
            </Box>
            {
                isElectric ? <>
                    <TextInput title={t(translations.ads.AUTONOMY)} postText={t(translations.FILTER_COMPONENT.FROM_KM)} preText={""} value={autonomy} handleChange={(value) => handleAutonomy(value)} />
                    <TextInput title={t(translations.ads.TOP_SPEED_KM_H)} postText={t(translations.FILTER_COMPONENT.UPTO_KM_H)} preText={""} value={topSpeed} handleChange={(value) => handleTopSpeed(value)} />
                    <TextInput title={t(translations.webTexts.ENGINE_POWER)} postText={t(translations.FILTER_COMPONENT.WATT)} preText={""} value={enginePower} handleChange={(value) => handleEnginePower(value)} />
                </> : null
            }
        </Box>
    )
}

export default Electric