import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const PublicAdsWrapper = styled.div`
span{
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: #000;
}
input:focus{
  outline: none;
}
.filterBox{
  width : 20%;
  position: fixed;
  height: calc(100vh - 155px);
  display : block;
}
.listContainer{
  width : 80%;
  /* max-width : calc(100% - 292px); */
  margin-left: 20%;
}
.homeMainContainer{
  max-width: 2760px;
  width: 100%;
  margin: auto;
}
.not-found-wrapper{
   width:100% !important;
   padding:0  !important;
   border:0  !important;
   .not-found-message{
      max-width:100%  !important;
   }
}

.scrollTop{
  position : fixed;
  background : #e3e3e3;
  width : 50px;
  height : 50px;
  bottom : 90px;
  right : 25px;
  border-radius : 5px;
  display: flex;
  align-items: center;
  justify-content:center;
}
.scrollTop svg{
  transform: rotate(90deg);
}
// -------------------------------------------------Header-------------------------------------------------
.header{
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.42px;
  margin : 0;
  width: 100%;
}
.header-mobile{
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.42px;
  margin-top : 6px;
}
.headerCount{
  color: var(--text-deep-gray, #666);
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin: 0;
}
.top-heading {
  height: 45px;
  position: relative;
  align-items: center;
  padding: 0 10px;
}
.top-heading > .back-to-page {
  position: absolute;
  left: 15px;
  top: 20px;
}
.searchBox{
  border-bottom :1px solid #CCC;
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.searchBox svg{
  width : 15px
}
.searchBox input{
  color: var(--text-deep-gray, #666);
  font-size: 18px;
  font-weight: 400;
  border: none;
  min-height: 22px;
  max-width: 150px;
  letter-spacing: 0.36px;
}
.sortBox{
  display : flex;
  align-items: center;
}
.sortBox span{
  color: var(--text-deep-gray, #666);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-right:5px;
}
.sortBox select{
  cursor: pointer;
  color: var(--text-deep-gray, #666);
  border: 1px solid #CCC;
  background : white;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  min-height: 40px;
  max-width: 150px;
}
.filterIcon{
  margin-left: 5px;
  width: 30px;
  height: 30px;
  background : #6579F4;
  padding :2px;
  border-radius : 5px;
}
.filterIcon svg path{
  fill : white;
}
.mobileSearchBox{
  width : 100%;
  gap: 5px;
}
.mobileSearchBox svg{
  height : 20px;
}
.mobileSearchBox span{
  color : ${colorNew.colorGrayOnButton};

}
.mobileSearchBox input{
  width : 100%;
  height: 40px;
  border : none;
  font-size: 18px;
  background: #F8F8F8;
  color : ${colorNew.colorGrayOnButton};
}
.pagination-container{
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  margin-top: 0px;
  &.car{
    margin-bottom:0;
  }
}

// ......................... backlink used cars , popular cars
.filterWrapper{
  &.popularCars{
    margin:20px 20px 80px 20px;
  }
  &.usedCars{
    margin:50px 20px 20px 20px;
  }
}

// -------------------------------------------------Ads-------------------------------------------------
.adDetailContainer{
  width: 100%;
}
.imageContainer{
  max-width : 275px;
  min-width : 275px;
  img{
    border-radius:4px;
  }
}
.adsListing{
  /* overflow:auto; */
  max-height: calc(100% - 150px);
  margin-top:0;
  padding-top:0;
}

.adContainer{
  width : 80%;
  min-width : 700px; 
  margin-top : 16px;  
  border:1px solid #F2F2F2;
  border-radius:6px;
  padding:16px;
}
.adContainerLoader{
  max-width : 70%;
  min-width: 700px;
  text-align : center;
}
.adContainerLoader svg circle{
  stroke : #6579F4;
}
.ad-name{ 
  margin : 0px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-wrap:wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.price p{
  margin : 0;
  color: #363B39;
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.42px;
}
.price span{
  color: var(--text-deep-gray, #666);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
}
.price .benefitInformationContainer {
    margin-left: 5px;
    display: inline;
}
@media (max-width :992px){
  .filterBox{
    width : 40%;
  }
  .share-icon{ 
    bottom:12px  !important;
    right:12px  !important; 
    width:32px !important;
    height:32px !important;
    padding:0 7px !important;
  }
  .likeIcon{
    bottom:12px  !important;
    right:54px  !important; 
    width:32px !important;
    height:32px !important;
    padding:0 7px !important;
  }
  .listContainer{
    width : 60%;    
    margin-left: 40%;

  }
  .adsListing{
    margin-top:0;
    padding-top:0;
  }
  .adContainer{
    align-items: center;
    width : 100%;
    max-width : 100%;
    min-width : 100%;
    padding:10px 0 0 0 !important;
    &>div{
      padding:0 10px;
    }
  }

  .adContainerLoader{
    width : 100%;
    max-width : 100%;
    min-width : 100%;
  }
  .user-detail-wrapper{
    padding: 0 !important;
  }
  .adDetailContainer{
    width : 100%;
    padding:0 !important; 
    & > div{
       padding:0 10px;
    }
  }
  .pagination-container{
    display: flex;
    justify-content: center;
    width:100%; 
  }
  .pagination-container ul{
    gap: 8px;
  }
}
@media (min-width :769px) and (max-width :992px){
  .filterBox{
    position: sticky;
  }
  .listContainer{
    margin-left: 0;
  }
}
@media (min-width :992px) and (max-width :1200px){
  .imageContainer {
    max-width: 280px;
    min-width: 280px; 
  }
  .filterBox{
    width : 26%;
  }
  .listContainer{
    width : 74%;
    margin-left: 26%;
  }
}
@media (max-width :768px){
  .header{
    font-size: 18px;
    font-weight: 500; 
    text-align: center
  }
  .adsListing {
    padding: 0 10px;
  }
  .filterWrapper{
    &.popularCars{
      margin:20px;
    }
    &.usedCars{
      margin:32px 20px 20px 20px;
    }
  }
  .imageContainer{
    img{
      border-radius:2px;
    }
  }
  .adsListing :nth-child(2) {
    margin-top:0;
    }
  .filterBox{
    width : 0%;
    min-width:0px;
    display: none;
  } 
  .listContainer{
    width : 100%;
    max-width: 100%;
    margin-left: 0px;
  }
  .button-wrapper button{
    height:36px  !important;
    padding:5px;
  }
}
@media (max-width :575px){
  .imageContainer {
    max-width: 100%;
    min-width: 100%;
    
  } 
}
`