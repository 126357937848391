import { Box } from '@mui/material'
import SearchDropdown from 'app/components/CustomFields/searchDropdown'
import { Nullable } from 'app/pages/Chat/interface/interface'
import { getParamQueries, updateURL } from 'config/appConfig'
import { translations } from 'locales/translations'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'



const MyadsFilters = () => {

    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const [isMyParkAdsPage] = useState(() => location.pathname.includes(routes.vehicleRoutes.park))
    const [isMyAllAdsPage] = useState(() => location.pathname.includes(routes.vehicleRoutes.classic))

    const [adType, _adType] = useState<Nullable<number>>()
    const [orderType, _orderType] = useState<Nullable<string>>()

    useEffect(() => {
        const payload = getParamQueries(location.search)
        _adType(payload.is_online ?? null)
        _orderType(payload.order ?? null)
    }, [location.search])

    const handleAdTypeChange = (id) => {
        if (adType == id) {
            _adType(null)
            updateURL('is_online', '', history)
            return
        }
        _adType(id)
        updateURL('is_online', id, history)
    }

    const handleOrderTypeChange = (id) => {
        if (orderType == id) {
            _orderType(null)
            updateURL('order', '', history)
            return
        }
        _orderType(id)
        updateURL('order', id, history)
    }

    const AdTypeOptions = useMemo(() => {
        return [
            {
                id: null,
                name: t(translations.FILTER_COMPONENT.FILTER_ALL_ADS)
            },
            {
                id: 1,
                name: t(translations.FILTER_COMPONENT.FILTER_ONLINE_ADS)
            },
            {
                id: 0,
                name: t(translations.FILTER_COMPONENT.FILTER_OFFLINE_ADS)
            },
        ]
    }, [])

    const OrderTypeOptions = useMemo(() => {
        return [
            {
                id: null,
                name: t(translations.FILTER_COMPONENT.FILTER_ALL_ORDERS)
            },
            {
                id: 'purchased',
                name: t(translations.FILTER_COMPONENT.FILTER_PURCHASE_ORDERS)
            },
            {
                id: 'sale',
                name: t(translations.FILTER_COMPONENT.FILTER_SALE_ORDERS)
            }
        ]
    }, [])

    if (!isMyParkAdsPage && !isMyAllAdsPage) {
        return null
    }

    return (
        <Box className='myAdsFilters'>
            {isMyAllAdsPage && <SearchDropdown title={t(translations.FILTER_COMPONENT.FILTER_AD_TYPE)} list={AdTypeOptions} value={adType} handleChange={(id) => handleAdTypeChange(id)} />}
            {isMyParkAdsPage && <SearchDropdown title={t(translations.FILTER_COMPONENT.FILTER_ORDER_TYPE)} list={OrderTypeOptions} value={orderType} handleChange={(id) => handleOrderTypeChange(id)} />}
        </Box>
    )
}

export default MyadsFilters