import { Box } from '@mui/material'
import { translations } from 'locales/translations'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { routes } from 'routes/routes'

const BreadCrumb = () => {
    const { t } = useTranslation()

    const { adData } = useSelector((state: RootStateOrAny) => state.vehicle)

    return (
        <Box className='breadCrumb'>
            <Link to={routes.vehicleRoutes.adsHome}>
                <span className='link'>{t(translations.ads.HOME)}</span>
            </Link>
            <span className='link'>{">"}</span>
            <Link to={routes.publicRoutes.hotDeals}>
                <span className='link'>{t(translations.HOT_DEALS.HOT_DEALS)}</span>
            </Link>
            <span className='link'>{">"}</span>
            <Link to={`${routes.publicRoutes.hotDealEvent}/${adData?.hotdeal?.slug}`}>
                <span className='link'>{adData?.hotdeal?.hotdeal_event?.title ?? ''}</span>
            </Link>
            <span>{">"}</span>
            <span>{adData?.owner_vehicle?.full_title ?? "-"}</span>
        </Box>
    )
}

export default memo(BreadCrumb)