import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { colorNew } from 'styles/StyleConstants';
import { routes } from 'routes/routes';
import { theme } from 'config';
import { socialMedia } from 'config/variables';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
const d = new Date();
let year = d.getFullYear();
const LoginFooter = (props: any) => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 5,
            'a': {
              width: 32,
              height: 32,
              minWidth: 'auto',
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 0,
              maxWidth: 1,
            }
          }}
        >
          {socialMedia.map((item, i) => {
            const { Icon } = item
            return (
              <a href={item?.link} target='_blank' className="links" key={i} >
                <Icon />
              </a>
            )
          })}

        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",

            'a': {
              textDecoration: "none",
              color: `${colorNew.colorBlack}`,
              fontSize: 13
            },
            '.legalDoc': {
              mr: "60px"
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: 'wrap',
              pt: 2,
              'a': {
                whiteSpace: 'nowrap',
                display: 'inline-block',
                padding: '0px 10px 15px 0px',
                position: 'relative',
                mr: 1,
                '&:not(:last-child)': {
                  '&::before': {
                    content: '""',
                    width: '1px',
                    height: 13,
                    background: colorNew.colorBlack,
                    position: 'absolute',
                    right: '0px',
                    top: '3px',
                  }
                }
              }
            }}
          >

            <Link to={routes.publicRoutes.terms}>{`${t(translations.FOOTER.FOOTER_TERMS)}`}</Link>
            <Link to={routes.publicRoutes.privacy_policy}>{`${t(translations.FOOTER.FOOTER_PRIVACYPOLICY)}`}</Link>
            <Link to={routes.publicRoutes.data_protection}>{`${t(translations.FOOTER.FOOTER_DATAPROTECTION)}`}</Link>
            <Link to={routes.publicRoutes.legal_notice}>{`${t(translations.FOOTER.FOOTER_LEGALNOTICE)}`}</Link>
            <Link to={routes.publicRoutes.legal_documents}>{`${t(translations.FOOTER.FOOTER_LEGALDOCUMENTS)}`}</Link>
          </Box>
          <Box>
            <p className='copyRight'><Link to={"#"}>{`${t(translations.FOOTER.FOOTER_COPYRIGHT)}`} {year}</Link></p>
          </Box>
        </Box>

      </Box>

    </>
  )
}

export default LoginFooter;