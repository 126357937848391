import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { useWindowSize } from 'app/Hooks/windowResize';
import { CountriesDrop1, getSubsidiaryStored, vehicles } from 'config/variables'
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import ProfileLayout from 'app/Component2/Layouts/ProfileLayout/ProfileLayout';
import ButtonCustom from 'app/Component2/GlobalComponent/ButtonCustom';
import { colorNew } from 'styles/StyleConstants';
import MobileNavBottom from 'app/components/NavBar/MobileNavBottom';
import { translations } from 'locales/translations';
import { routes } from 'routes/routes';
import { useDeviceType } from 'app/Hooks/useMediaQuery';

const CompanyProfileMainComponent = props => {
    // State, constants and other variables
    const [name, _name] = useState<string>('');
    const [url, _url] = useState<string>('');
    const [slug, _slug] = useState<string>('');
    const [phone, _phone] = useState<string>('');
    const [verified, _verified] = useState<any>();
    const [email, _email] = useState<string>('');
    const [streetNumber, _streetNumber] = useState<string>("");
    const [route, _route] = useState<string>("");
    const [locality, _locality] = useState<string>("");
    const [postalCode, _postalCode] = useState<string>("");
    const [country, _country] = useState<any>();
    const [companyLogo, _companyLogo] = useState<string>('');
    const [callPrefix, _callPrefix] = useState<string>('')
    const [countryFlag, _countryFlag] = useState<string>("")

    // props
    const { loading, copyHandler, shopUrl, subsidiaryId } = props;
    const { subsidiaryDetails, subsidiaryData } = useSelector((state: any) => state.company);

    // Hooks and effects
    const { t, i18n } = useTranslation();
    const [isSmallDevice] = useDeviceType()


    React.useEffect(() => {
        if (Object.keys(subsidiaryDetails).length > 1) {
            let addresses = subsidiaryDetails.address_principal;
            _name(subsidiaryDetails.name);
            _slug(subsidiaryDetails.slug);
            _url(`${routes.publicRoutes.public_profile_subisidiary}/${subsidiaryDetails.slug}`);
            _verified(subsidiaryDetails?.parent_company?.vat_valid)
            _phone(subsidiaryDetails.phone_principal)
            _email(subsidiaryDetails.email_principal)
            _companyLogo(subsidiaryDetails?.media[0]?.medium_url)
            if (addresses) {
                _streetNumber(addresses.street_number);
                _route(addresses.route);
                _locality(addresses.locality);
                _postalCode(addresses.postal_code);
                getCountry(addresses.country_id)
            }
        }
    }, [subsidiaryDetails, subsidiaryData]);

    const getCountry = (id) => {
        CountriesDrop1().then(data => {
            data.map((country, i) => {
                if (country.id == id) {
                    _country(country.fullname.toLowerCase())
                    _callPrefix(country.prefix_call)
                    _countryFlag(country.flag_thumbnail)
                }
            });
        })
    }

    return (
        <>
            {loading && <ContentLoader />}
            <ProfileLayout
                number={route}
                profileLink={true}
                name={name}
                verified={verified}
                countryFlag={countryFlag}
                email={email}
                callPrefix={callPrefix}
                phone={phone}
                userImage={companyLogo}
                country={country}
                route={route}
                postalCode={postalCode}
                street={streetNumber}
                locality={locality}
                url={url}
                component={<Box
                    sx={{
                        textAlign: "center",
                        position: "absolute",
                        left: "0",
                        right: "0",
                        margin: "25px auto 0 ",
                        width: "calc(100% - 35px)",
                        pb: '120px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "18px",
                            fontWeight: 500,
                            padding: "0 0 8px ",
                        }}
                    >
                        {t(translations.companyProfileScreen.SHOPLINK)}
                    </Typography>
                    <Box
                        sx={{
                            fontSize: "15px",
                            fontWeight: 400,
                        }}
                        onClick={() => window.open(shopUrl, '_self')}
                        className="pointer fw-bold opacity-75">
                        {shopUrl}
                    </Box>

                    <ButtonCustom textBtn={t(translations.companyProfileScreen.COPYLINK)} btnColor={`${colorNew.colorBlack}`} clickFunction={() => { copyHandler(slug) }}
                        style={{
                            margin: "16px auto 0",
                            width: "100%",
                            bgcolor: "#F2F2F2",
                            padding: "11px",
                            boderRadius: "4px",
                            color: `${colorNew.colorBlack}`
                        }}
                    />
                </Box>}
            />
            {isSmallDevice && <MobileNavBottom />}

        </>
    )
}


export default connect((state: any) => ({ ...state }), {
})(CompanyProfileMainComponent);
