import { Drawer } from '@mui/material'
import { useEffect, useState } from 'react'
import { NavBarWrapper } from '../Style/NavStyle'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { getCloseNavbar } from 'store/actions/component-actions'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import NavigationBarLargeDevices from './NavigationBarComponent/NavigationBarLargeDevices'
import { nameTemplate } from 'config/chatConfig'
import { CountriesDrop1, getSubsidiaryStored } from 'config/variables'
import NavigationBarSmallDevices from './NavigationBarComponent/NavigationBarSmallDevices'
import { useLocation } from 'react-router-dom'
import { routes } from 'routes/routes'

const SubHeader = ({ isFixed }) => {

    const dispatch = useDispatch();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()
    const subsidiary = getSubsidiaryStored()
    const location = useLocation();
    const [anchorElMenu, setAnchorElMenu] = useState<HTMLButtonElement | null>(null);
    const [countryFlag, _countryFlag] = useState<string>('');
    const [userName, _userName] = useState<string>("")

    const { navBarMenu } = useSelector((state: RootStateOrAny) => state.componentReducers)
    const { subsidiaryDetails, logoUrl, subsidiaryName } = useSelector((state: RootStateOrAny) => state.company);
    const { profileUpdatedData, updatedImage, profileUpdated, loggedIn } = useSelector((state: any) => state.auth)
    const { userData } = useSelector((state: any) => state.user)

    useEffect(() => {
        const name = nameTemplate(subsidiaryDetails, subsidiary, subsidiaryName, profileUpdatedData, userData)
        _userName(name)
    }, [subsidiaryDetails, subsidiary, userData, logoUrl, subsidiaryName, updatedImage, profileUpdated, profileUpdatedData])

    useEffect(() => {
        handleCloseMenu()
    }, [location.pathname])

    useEffect(() => {
        if (loggedIn) {
            if (subsidiary && subsidiary !== null && subsidiaryDetails?.address_principal?.country_id) {
                getCountry(subsidiaryDetails.address_principal.country_id)
            } else {
                if (!!userData.addresses && userData.addresses.length) {
                    getCountry(userData.addresses[0].country_id)
                }
            }
        }
    }, [subsidiaryDetails, userData])


    const getCountry = (id: number) => {
        CountriesDrop1().then(data => {
            data.map((country, i) => {
                if (country.id == id) {
                    _countryFlag(country?.flag_thumbnail)
                }
            });
        })
    }

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
        dispatch(getCloseNavbar())
    };

    const id = anchorElMenu ? 'simple-popover ' : undefined;

    return (
        <>
            {/* Navbar that appears in web view */}
            {(isLargeDevice || isExtraLargeDevice) && location.pathname.includes(routes.vehicleRoutes.adsHome) ?
                <NavBarWrapper className='nav-bar'>
                    <NavigationBarLargeDevices />
                </NavBarWrapper>
                : null
            }

            {/* Navbar that appears in tab and mobile view */}
            {(isSmallDevice || isMediumDevice) &&
                <Drawer
                    id={id}
                    anchor='right'
                    open={navBarMenu}
                    onClose={() => handleCloseMenu()}
                    className='menuPopover'
                    sx={{
                        '.MuiPaper-elevation': {
                            top: '0 !important',
                            left: '0 !important',
                            borderRadius: '0px',
                            maxWidth: '100% !important',
                            maxHeight: '100% !important'
                        }
                    }}
                >
                    <NavigationBarSmallDevices handleCloseMenu={handleCloseMenu} userName={userName} countryFlag={countryFlag} />
                </Drawer>
            }
        </>
    )
}

export default SubHeader;