import { ReactComponent as Tire_1_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/tire_1.svg'
import { ReactComponent as Tire_2_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/tire_1.svg'
import { ReactComponent as Rim_1_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/rim_1.svg'
import { ReactComponent as Rim_2_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/rim_1.svg'
import { ReactComponent as Sample } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/sample.svg'
import { ReactComponent as Trunkdown_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/part-1.svg'
// import {ReactComponent as Trunkdown_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/trunkdown.svg'
import { ReactComponent as Triangle_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/triangle.svg'
import { ReactComponent as Doordownf_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/doordownf.svg'
import { ReactComponent as TopMirror1_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/topMirror1.svg'
import { ReactComponent as Doordowns_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/doordowns.svg'
import { ReactComponent as TopMirror2_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/topMirror2.svg'
import { ReactComponent as Fenders_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/fenders.svg'
import { ReactComponent as Hoods_8_34_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/hoods-8-34.svg'
import { ReactComponent as Part_12_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/part-12.svg'
import { ReactComponent as TrunkLids1_10_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/trunkLids1-10.svg'
import { ReactComponent as Part7_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/part7-15.svg'
import { ReactComponent as Part_9_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/part-9.svg'
import { ReactComponent as Taillightright_11_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/taillightleft-11.svg'
import { ReactComponent as Taillightright_13_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/taillightright-13.svg'
import { ReactComponent as Tailgatethird_14_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/tailgatethird-14.svg'
import { ReactComponent as BackGlass_16_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/backGlass-16.svg'
import { ReactComponent as Roof3_17_1_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/roof3-17.svg'
import { ReactComponent as Roof3_17_2_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/roof3-17.svg'
import { ReactComponent as Roof3_17_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/roof3-17.svg'
import { ReactComponent as TopWindow1_18_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/topWindow1-18.svg'
import { ReactComponent as Wiper_19_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/wiper-19.svg'
import { ReactComponent as Bonet_20_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/bonet-20.svg'
import { ReactComponent as Grills_22_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/grills-22.svg'
import { ReactComponent as Rightheadlight_21_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/rightheadlight-21.svg'
import { ReactComponent as Leftheadlight_23_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/leftheadlight-23.svg'
import { ReactComponent as Lamp_24_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/lamp-24.svg'
import { ReactComponent as Lamp_25_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/lamp-24.svg'
import { ReactComponent as FrontBumper_26_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/frontBumper-26.svg'
import { ReactComponent as Straightbonet_27_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/straightbonet-27.svg'
import { ReactComponent as StraightTriangle_28_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/straightTriangle-28.svg'
import { ReactComponent as StraightDoor1_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/straightDoor2-42.svg'
import { ReactComponent as StraightDoor2_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/straightDoor1-43.svg'
import { ReactComponent as DownMirror1_31_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/downMirror1-31.svg'
import { ReactComponent as DownMirror2_32_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/downMirror2-32.svg'
import { ReactComponent as StraightHood_33_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/straightHood-33.svg'
import { ReactComponent as Hoods_34_8_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/trunkdown.svg'
import { ReactComponent as Tire_3_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/tire_1.svg'
import { ReactComponent as Rim_3_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/rim_1.svg'
import { ReactComponent as Tire_4_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/tire_1.svg'
import { ReactComponent as Rim_4_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/rim_1.svg'
import { ReactComponent as Simple_1_ICON } from 'app/pages/VehicleSummaryPage/Component/Experties/Images/sample.svg'
export let imagesGroup = {
  tire_1: {
    color: null,
    name: "LEFT REAR TIRE",
    id: 2,
    classes: 'tire_1_icon tire_icon',
    url: Tire_1_ICON,
    fillStoreClass: 'stroke',
    description:'',
    media:[]
  },
  tire_2: {
    color: null,
    name: "tire_2",
    id: 7,
    classes: 'tire_2_icon tire_icon',
    url: Tire_2_ICON,
    fillStoreClass: 'stroke',
    description:'',
    media:[]
  },

  rim_1: {
    color: null,
    name: "rim_1",
    id: 36,
    classes: 'rim_icon',
    url: Rim_1_ICON,
    fillStoreClass: 'circle',
    description:'',
    media:[]
  },
  rim_2: {
    color: null,
    name: "rim_2",
    id: 37,
    classes: 'rim_icon',
    url: Rim_2_ICON,
    fillStoreClass: 'circle',
    description:'',
    media:[]
  },
  simple: {
    color: null,
    name: "simple",
    id: 4,
    classes: 'simple_icon',
    url: Sample,
    fillStoreClass: 'fill',
    description:'',
    media:[]
  },
  trunkdown: {
    color: null,
    name: "trunkdown",
    id: 1,
    classes: 'trunkdown_icon',
    url: Trunkdown_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]
  },
  triangle: {
    color: null,
    name: "triangle",
    id: 3,
    classes: 'triangle_icon',
    url: Triangle_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]
  },
  doordownf: {
    color: null,
    name: "doordownf",
    id: 40,
    classes: 'doordownf_icon',
    url: Doordownf_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]
  },
  mirrordownf: {
    color: null,
    name: "mirrordownf",
    id: 5,
    classes: 'mirrordownf_icon',
    url: TopMirror1_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  doordowns: {
    color: null,
    name: "doordowns",
    id: 41,
    classes: 'doordowns_icon',
    url: Doordowns_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  mirrorrdowns: {
    color: null,
    name: "mirrorrdowns",
    id: 6,
    classes: 'mirrorrdowns_icon',
    url: TopMirror2_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  fenders: {
    color: null,
    name: "fenders",
    id: 9,
    classes: 'fenders_icon',
    url: Fenders_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  hoods: {
    color: null,
    name: "hoods",
    id: 8,
    classes: 'hoods_icon',
    url: Hoods_8_34_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  trunkLids1: {
    color: null,
    name: "trunkLids1",
    id: 10,
    classes: 'trunkLids1_icon',
    url: TrunkLids1_10_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  part9: {
    color: null,
    name: "part9",
    id: 12,
    classes: 'part9_icon',
    url: Part_12_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  taillightright: {
    color: null,
    name: "taillightright",
    id: 11,
    classes: 'taillightright_icon',
    url: Taillightright_11_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  taillightleft: {
    color: null,
    name: "taillightleft",
    id: 13,
    classes: 'taillightleft_icon',
    url: Taillightright_13_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  tailgatethird: {
    color: null,
    name: "tailgatethird",
    id: 14,
    classes: 'tailgatethird_icon',
    url: Tailgatethird_14_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  part7: {
    color: null,
    name: "part7",
    id: 15,
    classes: 'part7_icon',
    url: Part7_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  backGlass: {
    color: null,
    name: "backGlass",
    id: 16,
    classes: 'backGlass_icon',
    url: BackGlass_16_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  roof3: {
    color: null,
    name: "roof3",
    id: 17,
    classes: 'roof3_icon',
    url: Roof3_17_1_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]
  },
  topWindow1: {
    color: null,
    name: "topWindow1",
    id: 18,
    classes: 'topWindow1_icon',
    url: TopWindow1_18_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  wiper: {
    color: null,
    name: "wiper",
    id: 19,
    classes: 'wiper_icon',
    url: Wiper_19_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  upsideleft: {
    color: null,
    name: "upsideleft",
    id: 17,
    classes: 'upsideleft_icon',
    url: Roof3_17_1_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  upsideright: {
    color: null,
    name: "upsideright",
    id: 17,
    classes: 'upsideright_icon',
    url: Roof3_17_1_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  bonet: {
    color: null,
    name: "bonet",
    id: 20,
    classes: 'bonet_icon',
    url: Bonet_20_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  grills: {
    color: null,
    name: "grills",
    id: 22,
    classes: 'grills_icon',
    url: Grills_22_ICON,
    fillStoreClass: 'stroke',
    description:'',
    media:[]

  },
  rightheadlight: {
    color: null,
    name: "rightheadlight",
    id: 21,
    classes: 'rightheadlight_icon',
    url: Rightheadlight_21_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  leftheadlight: {
    color: null,
    name: "leftheadlight",
    id: 23,
    classes: 'leftheadlight_icon',
    url: Leftheadlight_23_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  leftLamp: {
    color: null,
    name: "leftLamp",
    id: 24,
    classes: 'leftLamp_icon',
    url: Lamp_24_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  rightLamp: {
    color: null,
    name: "rightLamp",
    id: 25,
    classes: 'rightLamp_icon',
    url: Lamp_25_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  frontBumper: {
    color: null,
    name: "frontBumper",
    id: 26,
    classes: 'frontBumper_icon',
    url: FrontBumper_26_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  straightbonet: {
    color: null,
    name: "straightbonet",
    id: 27,
    classes: 'straightbonet_icon',
    url: Straightbonet_27_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  straightTriangle: {
    color: null,
    name: "straightTriangle",
    id: 28,
    classes: 'straightTriangle_icon',
    url: StraightTriangle_28_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  straightDoor1: {
    color: null,
    name: "straightDoor1",
    id: 42,
    classes: 'straightDoor1_icon',
    url: StraightDoor1_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  downMirror1: {
    color: null,
    name: "downMirror1",
    id: 31,
    classes: 'downMirror1_icon',
    url: DownMirror1_31_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]



  },
  straightDoor2: {
    color: null,
    name: "straightDoor2",
    id: 43,
    classes: 'straightDoor2_icon',
    url: StraightDoor2_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  downMirror2: {
    color: null,
    name: "downMirror2",
    id: 32,
    classes: 'downMirror2_icon',
    url: DownMirror2_32_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  straightHood: {
    color: null,
    name: "straightHood",
    id: 33,
    classes: 'fenders_icon',
    url: StraightHood_33_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  straightFbonet: {
    color: null,
    name: "straightFbonet",
    id: 34,
    classes: 'straightFbonet_icon',
    url: Hoods_34_8_ICON,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  tire_3: {
    color: null,
    name: "tire_3",
    id: 29,
    classes: 'tire_icon',
    url: Tire_3_ICON,
    fillStoreClass: 'stroke',
    description:'',
    media:[]

  },
  rim_3: {
    color: null,
    name: "rim_3",
    id: 38,
    classes: 'rim_icon',
    url: Rim_3_ICON,
    fillStoreClass: 'circle',
    description:'',
    media:[]

  },
  simple_1: {
    color: null,
    name: "simple_1",
    id: 30,
    classes: 'simple_icon',
    url: Sample,
    fillStoreClass: 'fill',
    description:'',
    media:[]

  },
  tire_4: {
    color: null,
    name: "tire_4",
    id: 35,
    classes: 'tire_icon',
    url: Tire_4_ICON,
    fillStoreClass: 'stroke',
    description:'',
    media:[]

  },
  rim_4: {
    color: null,
    name: "rim_4",
    id: 39,
    classes: 'rim_icon',
    url: Rim_4_ICON,
    fillStoreClass: 'circle',
    description:'',
    media:[]

  },
}

// change color
export const changeColorFunctionRecived = (key, colorCode) => {
  let modifiedSelectedElement = { ...imagesGroup };
  modifiedSelectedElement[key].color = colorCode
  imagesGroup = modifiedSelectedElement;
}

// assing name
export const changeName = (key, name) => {
  let modifiedSelectedElement = { ...imagesGroup };
  modifiedSelectedElement[key].name = name
  imagesGroup = modifiedSelectedElement;
}
// assing  description
export const changeDescription = (key, description) => {
  let modifiedSelectedElement = { ...imagesGroup };
  modifiedSelectedElement[key].description = description
  imagesGroup = modifiedSelectedElement;
}

// assing image 
export const  changeMedia = (key, media) => {
  let modifiedSelectedElement = { ...imagesGroup };
  modifiedSelectedElement[key].media = media
  imagesGroup = modifiedSelectedElement;
}

// reset all selected data 
export const resetObject = () => {
  Object.keys(imagesGroup).map((el)=> {
    imagesGroup[el].color = null;
    imagesGroup[el].description = '';
    imagesGroup[el].media = [];
  })
}

// set intial data from according to api response 
export const setDetailExpertise = (data) => {
  const dataMode = data ? data : []
  Object.keys(imagesGroup).map((el)=> {
    const getData = dataMode.filter(x => x.expertise_section_id === imagesGroup[el].id)
    if(getData.length){
      imagesGroup[el].color = getData[0].damage
      imagesGroup[el].media = getData[0].media
      imagesGroup[el].description = getData[0].description
    }else{
      imagesGroup[el].color = null
      imagesGroup[el].description = ''
      imagesGroup[el].media = []
    }
  })
}