import React, { useEffect, useState } from 'react'
import StyleWrapper from './style'
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';
import { vehicles } from 'config/variables';
import { Grid } from '@mui/material';
import { localstorageKey } from 'config/constants';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { BacklinkBrandType, BacklinkCountryType, BacklinkFuelType, BacklinkVehicleBodyType } from 'config/interface';
import { useSelector } from 'react-redux';


const price = 5000
const years = 3;
const backlinkyear = new Date().getFullYear() - years;

type BacklinkModel = {
  id: number;
  slug: string;
  name: string;
  brandName: string;
  modelName: string;
};

export function BackLinkWithModels({ brandName, data }) {
  const { t } = useTranslation()


  if (data && Object.keys(data).length == 0) return <></>

  return <StyleWrapper className='filterWrapper popularCars'>
    <h2 className={'alignLeft'}>{t(translations.ads.OTHER_MODEL_OF_BRAND, { brandName })} </h2>
    <Grid className='filterContainer scrollBar scrollOnHover' container spacing={2}  >
      {
        data.map((model: BacklinkModel) => {
          return <Grid key={'model' + (model.id)} item xs={12} sm={6} md={4} lg={3} xl={3} className="backlinks">
            <Link className='link' to={{
              pathname: routes.vehicleRoutes.publicAds + '/' + vehicles.car + '/' + model.slug
            }}
              aria-label={t(translations.META_AND_H1_FOR_SEO.CAR_META_TITLE_WITH_BRAND_MODEL, { brandName: model.brandName, modelName: model.modelName })}
            >
              {model.name}
            </Link>
          </Grid>
        })
      }
    </Grid>
  </StyleWrapper>
}

function BacklinkWithFilters() {

  const [backLinkData, _backLinkData] = useState<object>({})
  const { t } = useTranslation()
  const { loadingForce } = useSelector((state: any) => state.vehicle)

  useEffect(() => {
    const backlinks = JSON.parse(localStorage.getItem(localstorageKey.backlinks) || '{}')
    _backLinkData(backlinks)
  }, [loadingForce])


  if (Object.keys(backLinkData).length == 0) return <></>

  return (
    <StyleWrapper className='filterWrapper popularCars'>
      <h2 className={'alignCenter'}>{t(translations.ads.USED_POPULAR_CARS)} </h2>
      <Grid className='filterContainer scrollBar scrollOnHover' container spacing={2}  >
        {
          backLinkData.fuels.length > 0 && backLinkData.fuels.map((fuel: BacklinkFuelType) => {
            return <Grid key={'fuel' + (fuel.id)} item xs={12} sm={6} md={4} lg={3} xl={3} className="backlinks">
              <Link className='link' to={{
                pathname: routes.vehicleRoutes.publicAds + '/' + vehicles.car,
                search: `?fuel_id=${fuel.id}&new_vehicle=0`,
              }}
                aria-label={t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_USED_WITH_ITEM, { item: fuel.name })}
              >
                {t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_USED_WITH_ITEM, { item: fuel.name })}
              </Link>
            </Grid>
          })
        }
        {
          backLinkData.bodyVehicles.length > 0 && backLinkData.bodyVehicles.map((body: BacklinkVehicleBodyType) => {
            return <Grid key={'body' + (body.id)} item xs={12} sm={6} md={4} lg={3} xl={3} className="backlinks">
              <Link className='link' to={{
                pathname: routes.vehicleRoutes.publicAds + '/' + vehicles.car,
                search: `?body_vehicle_id=${body.id}&new_vehicle=0`,
              }}
                aria-label={`${t(translations.VEHICLE_ADS_TYPE.CAR)} ${body.name}`}
              >
                {`${t(translations.VEHICLE_ADS_TYPE.CAR)} ${body.name}`}
              </Link>
            </Grid>
          })
        }
        {
          backLinkData.brands.length > 0 && backLinkData.brands.map((brand: BacklinkBrandType) => {
            return <Grid key={'brand' + (brand.id)} item xs={12} sm={6} md={4} lg={3} xl={3} className="backlinks">
              <Link className='link' to={{
                pathname: routes.vehicleRoutes.publicAds + '/' + vehicles.car + '/' + brand.slug,
                search: `?new_vehicle=0`,
              }}
                aria-label={`${brand.name} ${t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_USED)}`}
              >
                {`${brand.name} ${t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_USED)}`}
              </Link>
            </Grid>
          })
        }
        {
          backLinkData.countries.length > 0 && backLinkData.countries.map((country: BacklinkCountryType) => {
            return <Grid key={'country' + (country.id)} item xs={12} sm={6} md={4} lg={3} xl={3} className="backlinks">
              <Link className='link' to={{
                pathname: routes.vehicleRoutes.publicAds + '/' + vehicles.car + '/' + country.slug,
                search: `?new_vehicle=0`,
              }}
                aria-label={`${t(translations.VEHICLE_ADS_TYPE.CAR)} ${country.fullname}`}
              >
                {`${t(translations.VEHICLE_ADS_TYPE.CAR)} ${country.fullname}`}
              </Link>
            </Grid>
          })
        }
        <Grid key={'low price used car'} item xs={12} sm={6} md={4} lg={3} xl={3} className="backlinks">
          <Link className='link' to={{
            pathname: routes.vehicleRoutes.publicAds + '/' + vehicles.car,
            search: `?price_end=${price}&new_vehicle=0`,
          }}
            aria-label={`${t(translations.PUBLIC_ADS_SCREEN.LOW_PRICE_USED_CAR)} `}
          >
            {`${t(translations.PUBLIC_ADS_SCREEN.LOW_PRICE_USED_CAR)} `}
          </Link>
        </Grid>
        <Grid key={'used car less than three years'} item xs={12} sm={6} md={4} lg={3} xl={3} className="backlinks">
          <Link className='link' to={{
            pathname: routes.vehicleRoutes.publicAds + '/' + vehicles.car,
            search: `?registration_start=${backlinkyear}&new_vehicle=0`,
          }}
            aria-label={`${t(translations.PUBLIC_ADS_SCREEN.USED_CAR_LESS_THAN_YEARS, { years })} `}
          >
            {`${t(translations.PUBLIC_ADS_SCREEN.USED_CAR_LESS_THAN_YEARS, { years })} `}
          </Link>
        </Grid>
      </Grid>
    </StyleWrapper>
  )
}

export default BacklinkWithFilters
