import * as React from 'react';

export function useWindowSize() {
  const [size, setSize] = React.useState<any>([true, 0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth < 993 ? true : false, window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export function ScrollIntoView(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return <>
    {props.children}
  </>
}
