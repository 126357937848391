import { createAction } from 'redux-actions';

//steps 1 to 8
export const getVehicleTypeRequest = createAction('GET_VEHICLE_TYPE_REQUEST');
export const getVehicleTypeSuccess = createAction('GET_VEHICLE_TYPE_SUCCESS');
export const getVehicleTypeFailure = createAction('GET_VEHICLE_TYPE_FAILURE');

export const getVehicleBrandRequest = createAction('GET_VEHICLE_BRAND_REQUEST');
export const getVehicleBrandSuccess = createAction('GET_VEHICLE_BRAND_SUCCESS');
export const getVehicleBrandFailure = createAction('GET_VEHICLE_BRAND_FAILURE');

export const getVehicleYearRequest = createAction('GET_VEHICLE_YEAR_REQUEST');
export const getVehicleYearSuccess = createAction('GET_VEHICLE_YEAR_SUCCESS');
export const getVehicleYearFailure = createAction('GET_VEHICLE_YEAR_FAILURE');

export const getVehicleVersionRequest = createAction('GET_VEHICLE_VERSION_REQUEST');
export const getVehicleVersionSuccess = createAction('GET_VEHICLE_VERSION_SUCCESS');
export const getVehicleVersionFailure = createAction('GET_VEHICLE_VERSION_FAILURE');

export const getVehicleModelListRequest = createAction('GET_VEHICLE_MODEL_LIST_REQUEST');
export const getVehicleModelListSuccess = createAction('GET_VEHICLE_MODEL_LIST_SUCCESS');
export const getVehicleModelListFailure = createAction('GET_VEHICLE_MODEL_LIST_FAILURE');

export const getPowerListRequest = createAction('GET_POWER_LIST_REQUEST');
export const getPowerListSuccess = createAction('GET_POWER_LIST_SUCCESS');
export const getPowerListFailure = createAction('GET_POWER_LIST_FAILURE');

export const getFuelListRequest = createAction('GET_FUEL_LIST_REQUEST');
export const getFuelListSuccess = createAction('GET_FUEL_LIST_SUCCESS');
export const getFuelListFailure = createAction('GET_FUEL_LIST_FAILURE');

export const getGearBoxListRequest = createAction('GET_GEAR_BOX_LIST_REQUEST');
export const getGearBoxListSuccess = createAction('GET_GEAR_BOX_LIST_SUCCESS');
export const getGearBoxListFailure = createAction('GET_GEAR_BOX_LIST_FAILURE');

export const getVehicleBodyListRequest = createAction('GET_VEHICLE_BODY_LIST_REQUEST');
export const getVehicleBodyListSuccess = createAction('GET_VEHICLE_BODY_LIST_SUCCESS');
export const getVehicleBodyListFailure = createAction('GET_VEHICLE_BODY_LIST_FAILURE');

export const getVehicleValidateRequest = createAction('GET_VEHICLE_VALIDATE_REQUEST');
export const getVehicleValidateSuccess = createAction('GET_VEHICLE_VALIDATE_SUCCESS');
export const getVehicleValidateFailure = createAction('GET_VEHICLE_VALIDATE_FAILURE');
export const getVehicleValidateReset = createAction('GET_VEHICLE_VALIDATE_RESET');

//step 9
export const getVehicleEquipmentRequest = createAction('GET_VEHICLE_EQUIPMENT_REQUEST');
export const getVehicleEquipmentSuccess = createAction('GET_VEHICLE_EQUIPMENT_SUCCESS');
export const getVehicleEquipmentFailure = createAction('GET_VEHICLE_EQUIPMENT_FAILURE');

export const getVehicleInteriorRequest = createAction('GET_VEHICLE_INTERIOR_REQUEST');
export const getVehicleInteriorSuccess = createAction('GET_VEHICLE_INTERIOR_SUCCESS');
export const getVehicleInteriorFailure = createAction('GET_VEHICLE_INTERIOR_FAILURE');

export const getVehicleConditionsRequest = createAction('GET_VEHICLE_CONDITIONS_REQUEST');
export const getVehicleConditionsSuccess = createAction('GET_VEHICLE_CONDITIONS_SUCCESS');
export const getVehicleConditionsFailure = createAction('GET_VEHICLE_CONDITIONS_FAILURE');

export const getVehicleSeasonRequest = createAction('GET_VEHICLE_SEASON_REQUEST');
export const getVehicleSeasonSuccess = createAction('GET_VEHICLE_SEASON_SUCCESS');
export const getVehicleSeasonFailure = createAction('GET_VEHICLE_SEASON_FAILURE');

export const getVehicleTiersRequest = createAction('GET_VEHICLE_TIERS_REQUEST');
export const getVehicleTiersSuccess = createAction('GET_VEHICLE_TIERS_SUCCESS');
export const getVehicleTiersFailure = createAction('GET_VEHICLE_TIERS_FAILURE');

export const getVehicleColorsRequest = createAction('GET_VEHICLE_COLORS_REQUEST');
export const getVehicleColorsSuccess = createAction('GET_VEHICLE_COLORS_SUCCESS');
export const getVehicleColorsFailure = createAction('GET_VEHICLE_COLORS_FAILURE');

export const getVehicleWheelsRequest = createAction('GET_VEHICLE_WHEELS_REQUEST');
export const getVehicleWheelsSuccess = createAction('GET_VEHICLE_WHEELS_SUCCESS');
export const getVehicleWheelsFailure = createAction('GET_VEHICLE_WHEELS_FAILURE');

export const getVehicleFrameSizeRequest = createAction('GET_VEHICLE_FRAME_SIZE_REQUEST');
export const getVehicleFrameSizeSuccess = createAction('GET_VEHICLE_FRAME_SIZE_SUCCESS');
export const getVehicleFrameSizeFailure = createAction('GET_VEHICLE_FRAME_SIZE_FAILURE');

export const updateVehicleDataRequest = createAction('UPDATE_VEHICLE_DATA_REQUEST');
export const updateVehicleDataSuccess = createAction('UPDATE_VEHICLE_DATA_SUCCESS');
export const updateVehicleDataFailure = createAction('UPDATE_VEHICLE_DATA_FAILURE');
export const updateVehicleDataReset = createAction('UPDATE_VEHICLE_DATA_RESET');

export const createAddsRequest = createAction('CREATE_ADDS_REQUEST');
export const createAddsSuccess = createAction('CREATE_ADDS_SUCCESS');
export const createAddsFailure = createAction('CREATE_ADDS_FAILURE');
export const createAddsReset = createAction('CREATE_ADDS_RESET');

export const getPublicAddsRequest = createAction('GET_PUBLIC_ADDS_REQUEST');
export const getPublicAddsSuccess = createAction('GET_PUBLIC_ADDS_SUCCESS');
export const getPublicAddsFailure = createAction('GET_PUBLIC_ADDS_FAILURE');
export const getPublicAddsReset = createAction('GET_PUBLIC_ADDS_RESET');

export const getHomePublicAddsRequest = createAction('GET_HOME_PUBLIC_ADDS_REQUEST');
export const getHomePublicAddsSuccess = createAction('GET_HOME_PUBLIC_ADDS_SUCCESS');
export const getHomePublicAddsFailure = createAction('GET_HOME_PUBLIC_ADDS_FAILURE');
export const getHomePublicAddsReset = createAction('GET_HOME_PUBLIC_ADDS_RESET');

export const getPublicAddsShopRequest = createAction('GET_PUBLIC_ADDS_SHOP_REQUEST');
export const getPublicAddsShopSuccess = createAction('GET_PUBLIC_ADDS_SHOP_SUCCESS');
export const getPublicAddsShopFailure = createAction('GET_PUBLIC_ADDS_SHOP_FAILURE');

export const getPublicAuctionAddsRequest = createAction('GET_PUBLIC_AUCTION_ADDS_REQUEST');
export const getPublicAuctionAddsSuccess = createAction('GET_PUBLIC_AUCTION_ADDS_SUCCESS');
export const getPublicAuctionAddsFailure = createAction('GET_PUBLIC_AUCTION_ADDS_FAILURE');
export const getPublicAuctionAddsReset = createAction('GET_PUBLIC_AUCTION_ADDS_RESET');

//bike and step api
export const getVehicleMaterialRequest = createAction('GET_VEHICLE_MATERIAL_REQUEST');
export const getVehicleMaterialSuccess = createAction('GET_VEHICLE_MATERIAL_SUCCESS');
export const getVehicleMaterialFailure = createAction('GET_VEHICLE_MATERIAL_FAILURE');

export const getVehicleBatteryPositionRequest = createAction('GET_VEHICLE_BATTERY_POSITION_REQUEST');
export const getVehicleBatteryPositionSuccess = createAction('GET_VEHICLE_BATTERY_POSITION_SUCCESS');
export const getVehicleBatteryPositionFailure = createAction('GET_VEHICLE_BATTERY_POSITION_FAILURE');

export const getVehicleDriveTypesRequest = createAction('GET_VEHICLE_DRIVE_TYPES_REQUEST');
export const getVehicleDriveTypesSuccess = createAction('GET_VEHICLE_DRIVE_TYPES_SUCCESS');
export const getVehicleDriveTypesFailure = createAction('GET_VEHICLE_DRIVE_TYPES_FAILURE');

export const getVehicleSuspensionTypesRequest = createAction('GET_VEHICLE_SUSPENSION_TYPES_REQUEST');
export const getVehicleSuspensionTypesSuccess = createAction('GET_VEHICLE_SUSPENSION_TYPES_SUCCESS');
export const getVehicleSuspensionTypesFailure = createAction('GET_VEHICLE_SUSPENSION_TYPES_FAILURE');

export const getVehicleBrakeTypesRequest = createAction('GET_VEHICLE_BRAKE_TYPES_REQUEST');
export const getVehicleBrakeTypesSuccess = createAction('GET_VEHICLE_BRAKE_TYPES_SUCCESS');
export const getVehicleBrakeTypesFailure = createAction('GET_VEHICLE_BRAKE_TYPES_FAILURE');

export const getVehicleEnginePositionsRequest = createAction('GET_VEHICLE_ENGINE_POSITIONS_REQUEST');
export const getVehicleEnginePositionsSuccess = createAction('GET_VEHICLE_ENGINE_POSITIONS_SUCCESS');
export const getVehicleEnginePositionsFailure = createAction('GET_VEHICLE_ENGINE_POSITIONS_FAILURE');

export const getVehicleEnginesRequest = createAction('GET_VEHICLE_ENGINES_REQUEST');
export const getVehicleEnginesSuccess = createAction('GET_VEHICLE_ENGINES_SUCCESS');
export const getVehicleEnginesFailure = createAction('GET_VEHICLE_ENGINES_FAILURE');

export const getVehicleComponentsRequest = createAction('GET_VEHICLE_COMPONENTS_REQUEST');
export const getVehicleComponentsSuccess = createAction('GET_VEHICLE_COMPONENTS_SUCCESS');
export const getVehicleComponentsFailure = createAction('GET_VEHICLE_COMPONENTS_FAILURE');

export const getVehicleSexRequest = createAction('GET_VEHICLE_SEX_REQUEST');
export const getVehicleSexSuccess = createAction('GET_VEHICLE_SEX_SUCCESS');
export const getVehicleSexFailure = createAction('GET_VEHICLE_SEX_FAILURE');

export const getAuctionRequest = createAction('GET_AUCTION_REQUEST');
export const getAuctionSuccess = createAction('GET_AUCTION_SUCCESS');
export const getAuctionReset = createAction('GET_AUCTION_RESET');
export const getAuctionFailure = createAction('GET_AUCTION_FAILURE');

export const getHotDealRequest = createAction('GET_HOT_DEAL_REQUEST');
export const getHotDealSuccess = createAction('GET_HOT_DEAL_SUCCESS');
export const getHotDealReset = createAction('GET_HOT_DEAL_RESET');
export const getHotDealFailure = createAction('GET_HOT_DEAL_FAILURE');

export const getAuctionHomeRequest = createAction('GET_AUCTION_HOME_REQUEST');
export const getAuctionHomeSuccess = createAction('GET_AUCTION_HOME_SUCCESS');
export const getAuctionHomeFailure = createAction('GET_AUCTION_HOME_FAILURE');

export const getPublicAddsDetailRequest = createAction('GET_PUBLIC_ADDS_DETAIL_REQUEST');
export const getPublicAddsDetailSuccess = createAction('GET_PUBLIC_ADDS_DETAIL_SUCCESS');
export const getPublicAddsDetailFailure = createAction('GET_PUBLIC_ADDS_DETAIL_FAILURE');
export const getPublicAddsDetailReset = createAction('GET_PUBLIC_ADDS_DETAIL_RESET');


export const createBidRequest = createAction('CREATE_BID_REQUEST');
export const createBidSuccess = createAction('CREATE_BID_SUCCESS');
export const createBidFailure = createAction('CREATE_BID_FAILURE');
export const createBidReset = createAction('CREATE_BID_RESET');

export const uploadVehicleImagesRequest = createAction('UPLOAD_VEHICLE_IMAGES_REQUEST');
export const uploadVehicleImagesSuccess = createAction('UPLOAD_VEHICLE_IMAGES_SUCCESS');
export const uploadVehicleImagesFailure = createAction('UPLOAD_VEHICLE_IMAGES_FAILURE');

export const bidAcceptRejectRequest = createAction('BID_ACCEPT_REJECT_REQUEST');
export const bidAcceptRejectSuccess = createAction('BID_ACCEPT_REJECT_SUCCESS');
export const bidAcceptRejectFailure = createAction('BID_ACCEPT_REJECT_FAILURE');
export const bidAcceptRejectReset = createAction('BID_ACCEPT_RESET');

export const vehicleSoldReserveRequest = createAction('VEHICLE_SOLD_RESERVE_REQUEST');
export const vehicleSoldReserveSuccess = createAction('VEHICLE_SOLD_RESERVE_SUCCESS');
export const vehicleSoldReserveFailure = createAction('VEHICLE_SOLD_RESERVE_FAILURE');

export const getVehicleDetailRequest = createAction('VEHICLE_DETAIL_REQUEST');
export const getVehicleDetailSuccess = createAction('VEHICLE_DETAIL_SUCCESS');
export const getVehicleDetailFailure = createAction('VEHICLE_DETAIL_FAILURE');
export const getVehicleDetailReset = createAction('VEHICLE_DETAIL_RESET');

export const editAdRequest = createAction('EDIT_AD_REQUEST');
export const editAdSuccess = createAction('EDIT_AD_SUCCESS');
export const editAdFailure = createAction('EDIT_AD_FAILURE');
export const editAdReset = createAction('EDIT_AD_RESET');

export const getAllCustomersRequest = createAction('ALL_CUSTOMERS_REQUEST');
export const getAllCustomersSuccess = createAction('ALL_CUSTOMERS_SUCCESS');
export const getAllCustomersFailure = createAction('ALL_CUSTOMERS_FAILURE');

export const deleteVehicleRequest = createAction('DELETE_VEHICLE_REQUEST');
export const deleteVehicleSuccess = createAction('DELETE_VEHICLE_SUCCESS');
export const deleteVehicleFailure = createAction('DELETE_VEHICLE_FAILURE');
export const deleteVehicleReset = createAction('DELETE_VEHICLE_RESET');

export const deleteClassicAuctionAdRequest = createAction('DELETE_CLASSIC_AUCTION_AD_REQUEST');
export const deleteClassicAuctionAdSuccess = createAction('DELETE_CLASSIC_AUCTION_AD_SUCCESS');
export const deleteClassicAuctionAdFailure = createAction('DELETE_CLASSIC_AUCTION_AD_FAILURE');
export const deleteClassicAuctionAdReset = createAction('DELETE_CLASSIC_AUCTION_AD_RESET');

export const getVehicleTargetAudienceRequest = createAction('GET_VEHICLE_TARGET_AUDIENCE_REQUEST');
export const getVehicleTargetAudienceSuccess = createAction('GET_VEHICLE_TARGET_AUDIENCE_SUCCESS');
export const getVehicleTargetAudienceFailure = createAction('GET_VEHICLE_TARGET_AUDIENCE_FAILURE');

export const getAdDetailRequest = createAction('AD_DETAIL_REQUEST');
export const getAdDetailSuccess = createAction('AD_DETAIL_SUCCESS');
export const getAdDetailFailure = createAction('AD_DETAIL_FAILURE');
export const getAdDetailReset = createAction('AD_DETAIL_RESET');

export const cancelReservationRequest = createAction('CANCEL_RESERVATION_REQUEST');
export const cancelReservationSuccess = createAction('CANCEL_RESERVATION_SUCCESS');
export const cancelReservationFailure = createAction('CANCEL_RESERVATION_FAILURE');
export const cancelReservationReset = createAction('CANCEL_RESERVATION_RESET');

export const confirmVehiclePurchaseRequest = createAction('CONFIRM_VEHICLE_PURCHASE_REQUEST');
export const confirmVehiclePurchaseSuccess = createAction('CONFIRM_VEHICLE_PURCHASE_SUCCESS');
export const confirmVehiclePurchaseFailure = createAction('CONFIRM_VEHICLE_PURCHASE_FAILURE');
export const confirmVehiclePurchaseReset = createAction('CONFIRM_VEHICLE_PURCHASE_RESET');

export const rejectVehiclePurchaseRequest = createAction('REJECT_VEHICLE_PURCHASE_REQUEST');
export const rejectVehiclePurchaseSuccess = createAction('REJECT_VEHICLE_PURCHASE_SUCCESS');
export const rejectVehiclePurchaseFailure = createAction('REJECT_VEHICLE_PURCHASE_FAILURE');
export const rejectVehiclePurchaseReset = createAction('REJECT_VEHICLE_PURCHASE_RESET');

export const vehicleExpertiseSaveRequest = createAction('VEHICLE_EXPERTISE_SAVE_REQUEST');
export const vehicleExpertiseSaveSuccess = createAction('VEHICLE_EXPERTISE_SAVE_SUCCESS');
export const vehicleExpertiseSaveFailure = createAction('VEHICLE_EXPERTISE_SAVE_FAILURE');

export const vehicleExpertiseUpdateRequest = createAction('VEHICLE_EXPERTISE_UPDATE_REQUEST');
export const vehicleExpertiseUpdateSuccess = createAction('VEHICLE_EXPERTISE_UPDATE_SUCCESS');
export const vehicleExpertiseUpdateFailure = createAction('VEHICLE_EXPERTISE_UPDATE_FAILURE');

export const vehicleExpertiseFetchRequest = createAction('VEHICLE_EXPERTISE_FETCH_REQUEST');
export const vehicleExpertiseFetchSuccess = createAction('VEHICLE_EXPERTISE_FETCH_SUCCESS');
export const vehicleExpertiseFetchFailure = createAction('VEHICLE_EXPERTISE_FETCH_FAILURE');

export const callRequest = createAction('CALL_REQUEST');
export const callSuccess = createAction('CALL_SUCCESS');
export const callFailure = createAction('CALL_FAILURE');

export const addAdInFavouriteRequest = createAction('ADD_AD_IN_FAVOURITE_REQUEST');
export const addAdInFavouriteSuccess = createAction('ADD_AD_IN_FAVOURITE_SUCCESS');
export const addAdInFavouriteFailure = createAction('ADD_AD_IN_FAVOURITE_FAILURE');

export const removeAdFromFavouriteRequest = createAction('REMOVE_AD_FROM_FAVOURITE_REQUEST');
export const removeAdFromFavouriteSuccess = createAction('REMOVE_AD_FROM_FAVOURITE_SUCCESS');
export const removeAdFromFavouriteFailure = createAction('REMOVE_AD_FROM_FAVOURITE_FAILURE');

export const getAuctionAdsCountRequest = createAction('GET_AD_COUNT_REQUEST');
export const getAuctionAdsCountSuccess = createAction('GET_AD_COUNT_SUCCESS');
export const getAuctionAdsCountFailure = createAction('GET_AD_COUNT_FAILURE');
export const updateAuctionAdsCount = createAction('UPDATE_AD_COUNT');

export const getEuroRequest = createAction('GET_EURO_REQUEST');
export const getEuroSuccess = createAction('GET_EURO_SUCCESS');
export const getEuroFailure = createAction('GET_EURO_FAILURE');

export const updateOnBidChange = createAction('UPDATE_ON_BID_CHANGE')
export const resetBidChange = createAction('RESET_ON_BID_CHANGE')

export const getCarFilterRequest = createAction('GET_CAR_FILTER_REQUEST');
export const getCarFilterSuccess = createAction('GET_CAR_FILTER_SUCCESS');
export const getCarFilterFailure = createAction('GET_CAR_FILTER_FAILURE');

export const getMotorCycleFilterRequest = createAction('GET_MOTORCYCLE_FILTER_REQUEST');
export const getMotorCycleFilterSuccess = createAction('GET_MOTORCYCLE_FILTER_SUCCESS');
export const getMotorCycleFilterFailure = createAction('GET_MOTORCYCLE_FILTER_FAILURE');

export const getBikeFilterRequest = createAction('GET_BIKE_FILTER_REQUEST');
export const getBikeFilterSuccess = createAction('GET_BIKE_FILTER_SUCCESS');
export const getBikeFilterFailure = createAction('GET_BIKE_FILTER_FAILURE');

export const getStepFilterRequest = createAction('GET_STEP_FILTER_REQUEST');
export const getStepFilterSuccess = createAction('GET_STEP_FILTER_SUCCESS');
export const getStepFilterFailure = createAction('GET_STEP_FILTER_FAILURE');

export const getFilterDataRequest = createAction('GET_FILTRER_DATA_REQUEST');
export const getFilterDataSuccess = createAction('GET_FILTRER_DATA_SUCCESS');
export const getFilterDataFailure = createAction('GET_FILTRER_DATA_FAILURE');

export const setLoading = createAction('SET_LOADER');
export const removeLoading = createAction('REMOVE_LOADER');

export const setSelectedProductCategory = createAction('SELECT_PRODUCT_TYPE')
export const setSelectedVehicleType = createAction('SELECT_VEHICLE_TYPE')

export const putAdsIntoAuctionRequest = createAction('PUT_INTO_AUCTION_REQUEST');
export const putAdsIntoAuctionSuccess = createAction('PUT_INTO_AUCTION_SUCCESS');
export const putAdsIntoAuctionFailure = createAction('PUT_INTO_AUCTION_FAILURE');
export const putAdsIntoAuctionReset = createAction('PUT_INTO_AUCTION_RESET');


export const putAdsIntoHotDealRequest = createAction('PUT_INTO_HOTDEAL_REQUEST');
export const putAdsIntoHotDealSuccess = createAction('PUT_INTO_HOTDEAL_SUCCESS');
export const putAdsIntoHotDealFailure = createAction('PUT_INTO_HOTDEAL_FAILURE');
export const putAdsIntoHotDealReset = createAction('PUT_INTO_HOTDEAL_RESET');