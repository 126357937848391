import { Box, Typography } from '@mui/material'
import { translations } from 'locales/translations'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { creditsBalanceRequest } from 'store/actions/user-actions'

export const minimumCreditPrice = process.env.REACT_APP_MINIMUM_CREDIT_BALANCE
const CreditFeatuers = () => {
    //Redux
    const { myWalletBalance } = useSelector((state: RootStateOrAny) => state.auth)

    //hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(creditsBalanceRequest())
    }, [])

    const creditFeatures = [
        `${t(translations.HOT_DEALS.LOCATE_USER_BY_LIC_PLATE)}`,
        `${t(translations.HOT_DEALS.FIXED_PRICE_SPECIAL_OFFER)}`,
        `${t(translations.HOT_DEALS.AUCTION_WITHOUT_RESERVE_PRICE)}`,
    ]
    return (
        <Box className='feature-container'>
            <Box className='credit'>
                <Typography component={'h1'} className='header-credit'>
                    {t(translations.HOT_DEALS.CARTOBIKE_CREDIT)}
                </Typography>
                <Typography className='credit-desc'>
                    {t(translations.HOT_DEALS.CARTOBIKE_CREDIT_DESCRIPTION)}
                </Typography>
                <Box className={`${myWalletBalance <= minimumCreditPrice ? 'background-red' : 'background-green'} credit-balance-container`}>
                    <span className='balance text'>{t(translations.HOT_DEALS.CREDIT_BALANCE)}</span>
                    <span className={`text ${myWalletBalance <= minimumCreditPrice ? 'balance-value-red' : 'balance-value-green'}`}>{myWalletBalance || 0}</span>
                </Box>
            </Box>
            <Box className='features'>
                <Typography className='feature-heading'>
                    {t(translations.HOT_DEALS.CARTOBIKE_CREDIT_FEATURES)}
                </Typography>
                <Box className='features-list'>
                    {creditFeatures.map((feature, index) => {
                        return <li key={`${index}feature`}>{feature}</li>
                    })}
                </Box>
            </Box>
        </Box>
    )
}

export default CreditFeatuers