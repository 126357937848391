import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";

export const PromoWrapper = styled.div`
  background-color: #fff;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  position: absolute;
  max-width: 400px;
  width:calc(100% - 20px);
  box-shadow: 24;
  border-radius: 5px;
  overflow:scroll;
  padding: 5px ;
  max-height: 50%;
  outline-width: 0px;

.list-promo{
  display: flex;
  gap: 15px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
.promo-cell{
  width: 30%;
  display: flex; 
}
.promo-code-listing{
  margin-bottom: 8px;
  gap: 15px;
}

.apply-btn{
  display: flex;
  max-width: 115px !important;
  width:100%;
}

.text-blue{
  color: ${colorNew.primaryColor};
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
}
`;

export const CreditPaymentWrapper = styled.div`

  max-width: 1440px;
  width: 100%;
  margin: 0px auto;
  gap: 40px;  
  display: flex;
  flex-direction: column;

  .payment-wrapper{
    gap:21px;
    justify-content: space-between;
    margin-top:40px;
  }
  .plan-payment-wrapper{
    width :49% ;
    border:1px solid #f2f2f2;
    padding: 24px;
  }
    
  .selecte-plan{
    display: flex;
    flex-direction: column;
    gap:20px
  }
  .credit-header{
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.02em; 
  text-align: left;
  max-width:255px;
  margin-bottom: 24px;
  }
  .credits-info{
    justify-content: space-between;
    border-left: 4px solid #28A745;
    .credits{
    font-size: 18px;
    font-weight: 500;
    line-height: 29.16px;
    letter-spacing: 0.015em;
    text-align: left;

  }
  }
  
  .sub-total-info{
    justify-content: space-between;    
  }
  .total-credit{
    font-size: 27px;
    font-weight: 500;
    line-height: 43.75px;
    letter-spacing: 0.015em;
    text-align: right;
     background-color: #28A745;
     color: #fff;
     padding: 4px 14px;
     border-radius: 4px;
  }
  .text-style-left{
    font-size: 15px;
    font-weight: 350;
    line-height: 24.3px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #666666;
  }
  .text-style-right{ 
    font-size: 15px;
    font-weight: 500;
    line-height: 24.3px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #000;
    text-wrap:nowrap;
  }
  .total-style{
  font-size: 18px;
  font-weight: 400;
  line-height: 29.16px;
  letter-spacing: 0.015em;
  text-align: left;

  }
  .total-style-right{
  font-size: 18px;
  font-weight: 500;
  line-height: 29.16px;
  letter-spacing: 0.015em;
  text-align: right;

  }

  .make-payment{
   
  }
  .make-payment-header{
    font-size: 23px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.02em; 
  text-align: left;
  max-width:255px;
  }
  .payment-header-description{
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    letter-spacing: 0.02em;
    text-align: left; 
    color: #666666 ;
  }
  .promo-error{
    font-weight: 500;
    font-size: 12px;
    color: red;
    margin-top: 8px;
  }
  .apply-btn{
    font-size: 21px;
    font-weight: 400;
    line-height: 24.89px;
    letter-spacing: 0.02em;
    text-align: center; 
    max-height: 56px;
    min-width: 140px;
    text-transform: capitalize;
    margin-left: 16px; 
  }

  .card-detail-text{
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .amount{
    /* border-bottom: 2px solid ${colorNew.colorGray}; */
    padding: 12px 0px 16px 0px;
  }

  .payable-amount{
    font-size: 21px;
    font-weight: 500;
    line-height: 34.02px;
    letter-spacing: 0.015em;
    color : #000000;
  }
  .promo-code{
    max-width: 530px;
    width: 100%;
    & label {
        color: #666666;
        text-transform: capitalize;
    }
    & label.Mui-focused {
        color: #666666;
    }
    & .MuiOutlinedInput-root{
        & fieldset {
            border-color: #f5f5f5;
        }
        &:hover fieldset {
            border-color: #f5f5f5;
        }
        &.Mui-focused fieldset {
            border-color: #f5f5f5
        }
    }
  }

    .validity-days{
    font-weight: 500;
    text-transform: lowercase ;
  }

  .check-promos{
    color: #384386 !important;
    text-decoration: underline;
    text-transform: capitalize;
  }
  .secure-payment{
    text-transform: uppercase;

  }
  .payment-btn{
    width:100%;
    /* margin-top: 20px;    */
    box-shadow: none;
    height: 56px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
  }

  .disable-payment-btn{
     background: #E5E5E5; 
     color: #666666;
     cursor: not-allowed;
  }

  .payment-btns{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap:16px;
    margin-bottom: 8px;
  }
  .choose-btn{
    width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: #666;
    border-color: #e6e6e6;
  }
  .active{
    color: ${colorNew.colorWhite};
    border-color: ${colorNew.primaryColor};
  }
  .terms-style{
    color: #6579F4;
    cursor: pointer;
    font-style: italic;
    font-size: 16px;
    font-weight: 500;
  }
  .pageHeader{
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
      padding: 10px 0;
  }
  @media (max-width :1440px){
    max-width: 1170px;
  }
  @media (max-width :1170px){
    padding: 16px;
  }
  
  @media (max-width:992px) {
    padding: 0 16px;
    margin-bottom: 100px;
    gap:5px;
    .pageHeader {
      h2{
        font-family: Inter;
        font-size: 21px;
        font-weight: 500;
        line-height: 25.41px;
        letter-spacing: 0.02em;
        margin-top: -10px;
        margin: 0;
      }
    }
    .payment-wrapper{
      flex-direction: column;
      margin-top:0px;
    }
    .plan-payment-wrapper{
      padding: 0;
      border: none;
      width: 100%;
    }
   
  .make-payment-wrapper{
    flex-shrink: 0;
    border-radius: 6px;
    background: #FFF;
    margin:16px;
    border:1px solid rgb(220,220,220);
    padding:16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    /* card shadow */
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.04);
   
    position: fixed;
    bottom:5px;
    z-index: 11;
    left: 0px;  
    width: 90%;
  }
  }
  @media (max-width :768px){
    .card-detail-text{   
    font-size: 16px;    
  }
  .secure-payment{
    font-size: 15px;    
  }
  }
`