import styled from "styled-components";

const StyleWrapper = styled.div`
    border: 1px solid #ECEFFF; 
    padding: 16px;
    padding-bottom: 0px;
    gap: 8px;
    display:flex;
    flex-direction: column;
 h2{ 
    font-size: 23px;
    font-weight: 500;
    line-height: 27.26px;
    letter-spacing: 0.02em;
}
.filterContainer{
    display:grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr; 
    overflow-x: auto;
    padding-bottom: 15px;
    margin-left: 0;
    width: 100%;
    gap: 0 40px;
    .backlinks{
        min-width: 100%;
        width: max-content;
        padding-left: 0px
    } 
    .link{ 
        color: #384386;
        font-size: 18px;
        font-weight: 400; 
        width: 100% !important;
        height: 100%;
        display: block;
        text-align: start;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
@media (max-width: 768px ){
    h2{
        font-size:21px;
    }
    .filterContainer{
        gap: 0 20px;
        padding-bottom:30px;
        border:none;
        .link{
            font-size:15px;
            padding:0;
        }
    }
}
`

export default StyleWrapper