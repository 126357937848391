import { Box } from '@mui/material'
import SearchDropdown from 'app/components/CustomFields/searchDropdown'
import SearchDropdownMultipleInput from 'app/components/CustomFields/searchDropdownMultipleInput'
import { Nullable } from 'app/pages/PublicProfile/interface/interface'
import React, { useEffect, useState } from 'react'
import { MAX_NO_OF_BIKE_SEATS, MAX_NO_OF_SEATS } from '../filterConfig'
import Selectequipment from './equipmentSelect'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { useLocation, useParams } from 'react-router-dom'
import { urlParamInterface } from 'config/interface'
import { vehicles } from 'config/variables'
import { getParamQueries } from 'config/appConfig'
import { Toaster } from 'services/Toaster'

const Equipment = ({ filterData, showEquipments, setShowEquipments, selectedEquipments, updateURL }) => {

    const { t } = useTranslation()
    const location = useLocation()

    const { vehicle }: urlParamInterface = useParams();

    const [leftDropdownValues, _leftDropdownValues] = useState<Array<number>>([])
    const [rightDropdownValues, _rightDropdownValues] = useState<Array<number>>([])

    const [minSeats, _minSeats] = useState<Nullable<number>>()
    const [maxSeats, _maxSeats] = useState<Nullable<number>>()
    const [uphoistery, _uphoistery] = useState<Nullable<number>>()

    useEffect(() => {
        let seatsArray: number[] = []
        const NO_OF_SEATS = vehicle == vehicles.car ? MAX_NO_OF_SEATS : MAX_NO_OF_BIKE_SEATS;
        for (let i = 1; i <= NO_OF_SEATS; i++) {
            seatsArray.push(i)
        }
        _leftDropdownValues(seatsArray)
        _rightDropdownValues(seatsArray)
    }, [vehicle])


    // Sets values got from url to fields (runs only when page is rendered for the first time)
    useEffect(() => {
        let payload = getParamQueries(location.search)
        _minSeats(payload.seats_start ?? null)
        _maxSeats(payload.seats_end ?? null)
        _uphoistery(payload.interior_id ?? null)
    }, [location.search])


    const handleminSeats = (value) => {
        if (minSeats == value) {
            _minSeats(null)
            updateURL('seats_start', '')
            return
        }
        _minSeats(value)
        if (maxSeats && maxSeats < parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MIN_ERROR))
            return
        }
        updateURL('seats_start', value)
    }

    const handleMaxSeats = (value) => {
        if (maxSeats == value) {
            _maxSeats(null)
            updateURL('seats_end', '')
            return
        }
        _maxSeats(value)
        if (minSeats && minSeats > parseInt(value) && value != '') {
            Toaster.error(t(translations.FILTER_COMPONENT.FILTER_COMPONENT_MAX_ERROR))
            return
        }
        updateURL('seats_end', value)
    }

    const handleUphoistery = (value) => {
        if (uphoistery == value) {
            _uphoistery(null)
            updateURL('interior_id', '')
            return
        }
        _uphoistery(value)
        updateURL('interior_id', value)
    }

    return (
        <Box sx={{ gap: '24px', mt: 1 }} className='flexColumn'>

            <SearchDropdownMultipleInput title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_NB_SEATS)} labelFirstField={t(translations.FILTER_COMPONENT.MINIMUM)} labelSecondField={t(translations.FILTER_COMPONENT.MAXIMUM)} listfirstField={leftDropdownValues} listsecondField={rightDropdownValues} handleFirstField={(value) => handleminSeats(value)} handleSecondField={(value) => handleMaxSeats(value)} firstFieldValue={minSeats} secondFieldValue={maxSeats} />

            {/* Filter available only for car ads */}
            {
                vehicle == vehicles.car &&
                <>
                    <Selectequipment showEquipments={showEquipments} setShowEquipments={setShowEquipments} selectedEquipments={selectedEquipments} equipmentList={filterData?.equipmentList?.equipments ?? []} />
                    <SearchDropdown title={t(translations.FILTER_COMPONENT.FILTER_COMPONENT_UPHOLSTERY)} list={filterData?.interiorList?.items ?? []} value={uphoistery} handleChange={(id) => handleUphoistery(id)} />
                </>
            }
        </Box>
    )
}

export default Equipment