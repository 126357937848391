import { Box, Popover, Stack, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import flagPlaceHolder from 'app/assets/Images/be.png'
import { AntSwitch } from 'app/components/stylesComponents/AntSwitch'
import { colorNew } from 'styles/StyleConstants'
import { SvgverticalDots } from 'app/components/svgicons/svgNew'
import { SvgDelete } from 'app/components/svgicons/svg2'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import adsImageNotFound from 'app/assets/Images/adsPlaceholder.svg'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes/routes'
import { LicensePlatesI } from '../Interface/interface'
import { TYPE_OF_AD } from '../LicensePlateConstant'
import { useWindowSize } from 'app/Hooks/windowResize'

const LicensePlate = ({ vehicleDetail, searchable, recIndex, handleFindable, hideFindableToggle, handleRemove }: LicensePlatesI) => {
    // Local States
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [mobile, width] = useWindowSize()

    // Hooks
    const { t } = useTranslation();
    const history = useHistory();


    // Open PopOver for more Option
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    // function for closing more option popover 
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function for handling Redirection on detail page of vehicles
    const handleTitleRedirection = () => {
        if (!hideFindableToggle) {
            if (vehicleDetail?.latest_ad_vehicle && Object.keys(vehicleDetail.latest_ad_vehicle).length) {
                if (vehicleDetail.latest_ad_vehicle?.type_of_sale === TYPE_OF_AD.PUBLIC_ADS) {
                    // redirect on public ads page
                    history.push(routes.vehicleRoutes.publicAds + "/" + vehicleDetail.latest_ad_vehicle.slug)
                    return;
                }
                // redirect on auction ads page
                history.push(routes.vehicleRoutes.auctionAds + "/" + vehicleDetail.latest_ad_vehicle.slug)
                return;
            }
            history.push({ pathname: routes.vehicleRoutes.myAds + "/car/park", state: { searchAdByword: vehicleDetail?.full_title } })
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box sx={{
            boxShadow: '0px 1px 6px 2px #0000000A',
            padding: "15px 18px 15px ",
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: "row",
            }}>
                {/* vehicle image */}
                <Box className={'vehicle-img'} sx={{
                    'img': {
                        width: window.innerWidth < 767 ? "60px" : hideFindableToggle || window.innerWidth > 767 && window.innerWidth < 1023 ? "110px" : "173px",
                        height: window.innerWidth < 767 ? "45px" : hideFindableToggle || window.innerWidth > 767 && window.innerWidth < 1023 ? "83px" : '130px',
                        mr: "20px",
                        objectFit: vehicleDetail && vehicleDetail.vehicle_picture_url ? "contain" : "cover",
                        border: "1px solid  #eee"
                    },
                }}>
                    <img src={vehicleDetail?.vehicle_picture_url || adsImageNotFound} alt={"vehicle-img"} />
                </Box>
                {/* Vehicle Description */}
                <Box sx={{ width: "100%" }} >
                    <Box className={'plateList'}
                        sx={{
                            color: `${colorNew.primaryColor}`,
                            fontWeight: 400,
                            fontSize: '18px',
                            columnGap: "20px"
                        }}
                    >
                        <Tooltip title={vehicleDetail?.full_title || ''}>
                            <span onClick={() => handleTitleRedirection()} className='licence-plate-heading pointer'>{vehicleDetail?.full_title || "-"}</span>
                        </Tooltip>
                        {!hideFindableToggle && !mobile && <span className='pointer' style={{ padding: '0 10px' }} onClick={(e) => handleClick(e)}><SvgverticalDots className='verticalThreeDots' /></span>}

                    </Box>
                    <Box className='plateLists platList-div'>
                        <Box sx={{ color: '#666666', display: "flex", flexWrap: 'wrap', alignItems: "center", columnGap: "30px", padding: "10px 0", '.vin': { color: '#191919', ml: '10px', textTransform: 'none' } }}>
                            <span>{mobile ? t(translations.summaryVehicleScreen.VIN_MOBILE) : t(translations.ads.VIN)}:<span className='vin'>{vehicleDetail?.vin || '-'}</span></span>
                            <span>{t(translations.ads.YEAR)}:<span className='vin'>{vehicleDetail?.vehicle_first_register_year || t(translations.webTexts.NEWW)}</span></span>
                        </Box>
                        {!hideFindableToggle && <Box>
                            <Box>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent={"space-between"} flexWrap={'wrap'}>
                                    <Box className='flag-img'>
                                        <img src={vehicleDetail?.license_plate?.country?.flag_thumbnail || flagPlaceHolder} alt={"flag-img"} />
                                        <Box style={{ marginTop: '2px' }}>
                                            <span >{vehicleDetail?.license_plate?.license_plate || "-"}</span>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', columnGap: "10px", alignItems: "center", fontSize: '18px', color: '#999999', margin: '0px !important' }}><span>{t(translations.LICENSEPLATE.FINDABLE_FOR_PUBLIC)}</span> <AntSwitch name={"findable"} checked={searchable ? true : false} onChange={() => handleFindable(vehicleDetail?.license_plate?.id, recIndex)} inputProps={{ 'aria-label': 'ant design' }} className="toggle_icon" /></Box>
                                </Stack>
                            </Box>
                        </Box>}
                    </Box>
                </Box>
            </Box>
            {/* Vehicle Description */}
            <Box className='vehicle-description' sx={{ width: "100%" }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    fontSize: '15px',
                    fontWeight: 400,
                    margin: "10px 0"
                }}>

                    {!hideFindableToggle &&
                        <>
                            <Box sx={{ color: '#666666', display: "flex", alignItems: "center", columnGap: "30px", '.vin': { color: '#191919', ml: '10px' } }}>
                                <span>{mobile ? t(translations.summaryVehicleScreen.VIN_MOBILE) : t(translations.ads.VIN)}:<span className='vin'>{vehicleDetail?.vin || "-"}</span></span>
                                <span>{t(translations.ads.YEAR)}:<span className='vin'>{vehicleDetail?.vehicle_first_register_year || t(translations.webTexts.NEWW)}</span></span>
                            </Box>
                            <Box>
                                <Box>
                                    <Stack direction="row" display={'block'} spacing={1} alignItems="center" justifyContent={"space-between"} flexWrap={'wrap'}>
                                        <Box className='flag-img' sx={{ marginY: '10px' }}>
                                            <img src={vehicleDetail?.license_plate?.country?.flag_thumbnail || flagPlaceHolder} alt={"flag-img"} />
                                            <Box style={{ marginTop: '2px' }}>
                                                <span >{vehicleDetail?.license_plate?.license_plate || "-"}</span>
                                            </Box>
                                        </Box>
                                        {/* Findable for public toggle Icon*/}
                                        <Box sx={{ display: 'flex', columnGap: "10px", alignItems: "center", fontSize: '18px', color: '#999999', justifyContent: 'space-between', margin: '0px !important' }}>
                                            <Box className='findable' sx={{}}>
                                                <span>{t(translations.LICENSEPLATE.FINDABLE_FOR_PUBLIC)}</span>
                                                <AntSwitch name={"findable"} checked={searchable ? true : false} onChange={() => handleFindable(vehicleDetail?.license_plate?.id, recIndex)} inputProps={{ 'aria-label': 'ant design' }} className="toggle_icon" />
                                            </Box>
                                            <Box className={'plateList pointer'}
                                                sx={{
                                                    color: `${colorNew.primaryColor}`,
                                                    fontWeight: 400,
                                                    fontSize: '18px'
                                                }}
                                            >
                                                {!hideFindableToggle && mobile && <SvgverticalDots onClick={handleClick} />}
                                            </Box>
                                        </Box>

                                    </Stack>
                                </Box>
                            </Box>
                        </>
                    }
                </Box>
            </Box>
            {/* Popover for more options */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography className='pointer' sx={{ p: 2, display: 'flex', gap: '10px' }}
                    onClick={() => {
                        handleRemove(vehicleDetail?.license_plate?.id)
                        setAnchorEl(null)
                    }}>
                    <SvgDelete />
                    <span>{t(translations.CHAT.REMOVE_FROM_GROUP)}</span>
                </Typography>
            </Popover>
        </Box>
    )
}

export default LicensePlate