import { createTheme } from "@mui/system";

export  const customLabels = [
  { primary: 'GB', secondary: '+44' },
  { primary: 'US', secondary: '+1' },
];

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#0000FF',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 993,
      lg: 1200,
      xl: 1536,
    },
  },
});