import { Box } from '@mui/material';
import React from 'react'
import { colorNew } from 'styles/StyleConstants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  setWidth?: string;
}

function TabParent(props: TabPanelProps) {
  const { children, value, index, setWidth, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={setWidth || ''}
      style={{
        marginBottom: '50px'
      }}
    >
      <Box>
        {children}
      </Box>
    </div>
  );
}

export default TabParent