import { Box, Drawer, List } from '@mui/material'
import { SvgBlock, SvgImport, SvgMail, SvgMute } from 'app/components/svgicons/svg2'
import { SvgFillerIcon } from 'app/components/svgicons/svgNew'
import React, { useEffect, useRef, useState } from 'react'
import { ChatWrapper } from '../../style/chatStyle'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations';
import * as ChatConstants from "config/chatConfig"
import { SvgCheckDark } from 'app/components/svgicons/svg'
import { chatList } from '../../interface/interface'
import { useDispatch, useSelector } from 'react-redux'
import { getArchivedChatRequest, getBlockedChatRequest, muteAllChatRequest, particularChatSuccess, unreadAllChatRequest } from 'store/actions/chat-action'

const MoreOptionsMobile = ({ setChatType, chatType, isAllReadStatus, isAllMuteStatus, drawer,setSelectedChatID ,setChatId }: chatList) => {

    const [state, setState] = useState(false);
    
    const { blockedChatLoaded } = useSelector((state) => state.chat)

    const dispatch = useDispatch()
    
    const { t } = useTranslation()

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState(open);
            };


    const handleChatType = (chatType) => {
        setChatId(null)
        dispatch(particularChatSuccess({}))
        if(chatType===ChatConstants.chatType.BLOCKED && !blockedChatLoaded){
            dispatch(getBlockedChatRequest({ page: 1 }))
        }
        setSelectedChatID(false)
        setChatType(chatType)
        toggleDrawer(false)
    }

    // Actions
    const handleReadUnread = () => {
        let data = {
            is_read: isAllReadStatus
        }
        dispatch(unreadAllChatRequest(data))
    }

    const handleMuteUnmute = () => {
        let data = {
            is_muted: isAllMuteStatus
        }
        dispatch(muteAllChatRequest(data))
    }

    const list = () => (
        <ChatWrapper>
            <Box
                sx={{ width: 'auto' }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                {chatType === ChatConstants.chatType.ALL_MESSAGES &&<Box className='chatOptionsMobileOptionsContainer flexColumn'>
                    <Box className='flexRow optionContainer' onClick={handleReadUnread}>
                        <Box className='iconContainer'>
                            <SvgMail className='pointer' />
                        </Box>
                        <Box className='filterTextContainer'>
                            <span>{!isAllReadStatus ? t(translations.CHAT.MARK_ALL_AS_UNREAD) : t(translations.CHAT.MARK_ALL_AS_READ)}</span>
                        </Box>
                    </Box>
                    <Box className='flexRow optionContainer' onClick={handleMuteUnmute}>
                        <Box className='iconContainer'>
                            <SvgMute className='pointer' />
                        </Box>
                        <Box className='filterTextContainer'>
                            <span>{!isAllMuteStatus ? t(translations.CHAT.UNMUTE_ALL) : t(translations.CHAT.MUTE_ALL)}</span>
                        </Box>
                    </Box>
                </Box>}
                <Box className='chatOptionsMobileOptionsContainer flexColumn'>
                    <Box className='flexRow optionContainer' onClick={() => handleChatType(ChatConstants.chatType.ALL_MESSAGES)}>
                        <Box className='iconContainer'>
                            <SvgMail className='pointer' />
                        </Box>
                        <Box className='filterTextContainer'>
                            <span> {t(translations.CHAT.ALL_MESSAGE)}</span>
                            {chatType === ChatConstants.chatType.ALL_MESSAGES && <SvgCheckDark />}
                        </Box>
                    </Box>
                    <Box className='flexRow optionContainer' onClick={() => {
                        handleChatType(ChatConstants.chatType.ARCHIVED)
                    }}>
                        <Box className='iconContainer'>
                            <SvgImport className='pointer' />
                        </Box>
                        <Box className='filterTextContainer'>
                            <span> {t(translations.CHAT.ARCHIVED)}</span>
                            {chatType === ChatConstants.chatType.ARCHIVED && <SvgCheckDark />}
                        </Box>
                    </Box>
                    <Box className='flexRow optionContainer' onClick={() => {
                        handleChatType(ChatConstants.chatType.BLOCKED)
                    }}>
                        <Box className='iconContainer'>
                            <SvgBlock className='pointer' />
                        </Box>
                        <Box className='filterTextContainer'>
                            <span> {t(translations.CHAT.BLOCKED)}</span>
                            {chatType === ChatConstants.chatType.BLOCKED && <SvgCheckDark />}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ChatWrapper>
    );

    return (
        <>
            <Box>
                {drawer ?
                    <span onClick={toggleDrawer(true)}>{t(translations.CHAT.MORE)}</span> :
                    <>
                        <SvgFillerIcon className='pointer' onClick={toggleDrawer(true)} />
                        {chatType !== ChatConstants.chatType.ALL_MESSAGES && <span className='icon-dot-mobile'></span>}
                    </>
                }
            </Box>
            <Drawer
                anchor='bottom'
                open={state}
                onClose={toggleDrawer(false)}
                sx={{
                    '.MuiPaper-root': {
                        borderRadius: '6% 6% 0 0'
                    }
                }}
            >
                {list()}
            </Drawer>
        </>
    )
}

export default MoreOptionsMobile