import { Autocomplete, Box, Checkbox, Drawer, Radio, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FieldWrapper } from './style'
import { SvgMobileLeftArrow, SvgchevronIcon } from '../svgicons/svgNew'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { lan } from 'locales/multiPageTranslation/adsLan'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


// Code for list of dropdown elements
const ListElement = ({ showIcon, showColor, list, isSelected, handleChange, title, hideSearch, value, multiselect }) => {

    const { t } = useTranslation()
    const [isSmallDevice] = useDeviceType()

    const [optionSearch, _optionSearch] = useState<string>('')
    const [optionsList, _optionsList] = useState<Array<Object>>(() => list ?? [])

    useEffect(() => {
        if (optionSearch && optionSearch !== '') {
            _optionsList(list.filter((option) => option.name.toLowerCase().includes(optionSearch.toLowerCase())))
            return
        }
        _optionsList(list)
    }, [optionSearch])

    const setValue = (option) => {
        if (multiselect) {
            let selectedValues = []
            value.forEach(element => {
                const selectedOption = list.find((option) => option.id == element)
                if (selectedOption) {
                    selectedValues.push(selectedOption)
                }
            });
            const existence = value.findIndex((optionId) => optionId == option.id)
            // adding filter as it was not already selected
            if (existence === -1) {
                handleChange([option, ...selectedValues])
                return
            }
            // removing filter as it was already selected
            handleChange(selectedValues.filter(choosenOption => choosenOption.id != option.id))
            return
        }
        handleChange(option.id)
    }

    return (
        <Box className='optionsMainContainer'>
            {/* Search Field */}
            {
                !hideSearch ?
                    <Box className='optionSearchContainer'>
                        <input type='text' placeholder='Search' value={optionSearch} onChange={(e) => _optionSearch(e.target.value)} />
                    </Box> : null

            }
            <Box className='flexColumn optionListContainer scrollBar' sx={{
                maxHeight: !isSmallDevice ? '300px' : 'unset'
            }}>
                {/* List of Options */}
                {
                    (optionsList && optionsList.length > 0) &&
                    optionsList.map((option) => {
                        return <Box key={title + option.id} className='optionContainer' sx={{ backgroundColor: isSelected(option.id) ? 'aliceblue' : '' }} onClick={() => { setValue(option) }}>
                            <Box className='flexRow alignItemsCenter nameContainer'>
                                {showIcon && option.media && option.media.length > 0 && <img src={option.media[0].thumb_url} alt='icon' className='optionIcon' />}
                                {showColor && <span style={{ background: option.hexa, height: '20px', width: '20px' }} className='optionColor'></span>}
                                <span className='optionValue'>{option.name}</span>
                            </Box>
                            <span className={(isSelected(option.id) ? 'selected' : '') + ' selectDiv'}></span>

                        </Box>
                    })

                }

                {/* No result found if search query doesnt matches any list options */}
                {
                    optionsList && optionsList.length == 0 && <span className='optionValue noResultFound'>{t(...lan.notFound())}</span>
                }
            </Box>
        </Box>
    )
}

const SearchDropdown = ({ title, value, list, handleChange, multiselect = false, showIcon = false, showColor = false, hideSearch = false }) => {

    const { t } = useTranslation()
    const [openDropdown, _openDropdown] = useState<boolean>(false)
    const [showValue, _showValue] = useState<string>(t(translations.webTexts.NOT_SELECTED))
    const [isSmallDevice] = useDeviceType()

    // Generating text to show as selected value in field
    useEffect(() => {
        // closing modal only if field is not multiselect and user has chosen any value
        if (!multiselect) {
            _openDropdown(false)
        }
        if (value !== undefined && value !== '' && list && list.length > 0) {
            if (!multiselect) {
                const existence = list.findIndex((option) => option.id == value)
                if (existence != -1) {
                    _showValue(list[existence].name)
                }

                return
            }
            if (multiselect && value && value.length > 0) {
                let str = ''
                value.forEach(options => {
                    const existence = list.findIndex((option) => option.id == options)
                    if (existence != -1) {
                        str = str == '' ? list[existence].name : str + ", " + list[existence].name
                    }
                });
                _showValue(str)
            }
        }
    }, [value, list])

    const isSelected = (id) => {
        if (multiselect && value != '' && value) {
            const existence = value.findIndex((option) => option == id)
            if (existence != -1) {
                return true
            }
            return false
        }
        if (!multiselect && value != '' && value !== undefined) {
            if (value == id) {
                return true
            }
            return false
        }
    }

    const selectedValues = React.useMemo(
        () => {
            if (multiselect) {
                let arr = []
                value.forEach(optionID => {
                    const existence = list.findIndex(option => option.id == optionID)
                    if (existence !== -1) {
                        arr.push(list[existence])
                    }
                });
                return arr
            }
            return list.find(option => option.id == value) ?? null
        },
        [list, value]
    );


    return (
        <FieldWrapper>

            {isSmallDevice &&
                <Box className='field flexRow alignItemsCenter'>
                    <span className='fieldHeader'>{title}</span>
                    <Box className='inputBox flexRow justifyContentBetween alignItemsCenter pointer'
                        sx={{
                            px: 2, width: '100%',
                            'svg': {
                                'path': {
                                    stroke: '#666'
                                }
                            }
                        }}
                        onClick={() => _openDropdown((previousState) => !previousState)}>
                        <span className='selectedValue'>
                            {showValue}
                        </span>
                        <SvgchevronIcon />
                    </Box>
                    <Drawer
                        anchor='bottom'
                        open={openDropdown}
                        onClose={() => _openDropdown(false)}
                        sx={{
                            '.MuiPaper-root': {
                                height: '70vh',
                                borderRadius: '6% 6% 0 0',
                                overflowY: 'inherit'
                            },
                        }}
                    >
                        <FieldWrapper>
                            <Box className='drawerTitleStyle'>
                                <SvgMobileLeftArrow onClick={() => _openDropdown(false)} />
                                {title}
                                <SvgMobileLeftArrow style={{ opacity: 0 }} />
                            </Box>
                            <ListElement hideSearch={hideSearch} list={list} handleChange={handleChange} isSelected={isSelected} showIcon={showIcon} showColor={showColor} title={title} value={value} multiselect={multiselect} />
                        </FieldWrapper >
                    </Drawer >
                </Box >
            }

            {!isSmallDevice &&
                <Autocomplete
                    multiple={multiselect}
                    disableCloseOnSelect={multiselect}
                    popupIcon={<SvgchevronIcon />}
                    getOptionLabel={(option) => option.name}
                    id={`checkboxes-tags-demo-${title}`}
                    options={list}
                    open={openDropdown}
                    onOpen={() => _openDropdown(true)}
                    onClose={() => _openDropdown(false)}

                    renderInput={(params) =>
                        <TextField {...params} label={title} placeholder={(!openDropdown && ((multiselect && selectedValues.length) == 0 || !multiselect)) ? t(translations.webTexts.NOT_SELECTED) : ""} InputLabelProps={{ shrink: true }} className='autocompleteInputField' />
                    }

                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ paddingLeft: '10px' }} className="flexRow justifyContentBetween alignItemsCenter filterDropDownOption pointer" key={option.name + option.id}>
                            <Box className='flexRow alignItemsCenter'>
                                {showIcon && option.media && option.media.length > 0 && <img src={option.media[0].thumb_url} alt='icon' className='optionIcon' />}
                                {showColor && <span style={{ background: option.hexa, height: '20px', width: '20px' }} className='optionColor'></span>}
                                <span className='optionValue'>{option.name}</span>
                            </Box>

                            {!multiselect && <Radio
                                checked={selected}
                            />}

                            {multiselect && <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />}

                        </li>
                    )}
                    renderTags={(value: readonly string[], getTagProps) =>
                        value && value.map((option: string, index: number) => (
                            <span className='selectedValue'>
                                {option.name + ", "}
                            </span>
                        ))
                    }

                    value={selectedValues}

                    onChange={(event: any, newValue: string | null) => {
                        if (multiselect) {
                            handleChange(newValue)
                        } else {
                            handleChange(newValue?.id);
                        }
                    }}
                    disablePortal
                    disableClearable={true}
                />
            }
        </FieldWrapper >
    )
}

export default SearchDropdown