import styled from "styled-components";
import ContactUsImage from 'app/AssetNew/Images/contact_us_image.webp'
import { colorNew } from "styles/StyleConstants";

export const ContactUsWrappper = styled.div`
padding:24px 0px 48px;
display:flex;
min-height: 100vh;
margin:0px 115px;
.leftIcon{
  transform: rotate(90deg);
}
.contact-us{
  font-size:32px;
  font-weight:500;
}
.imageSide {
  max-width: 50%;
  width: 100%;
  margin-top: 34px;
  background-color: #FAFAFA;
  border-radius:12px;
  background-image: url(${ContactUsImage});
  background-position: center center;
  background-repeat: no-repeat;
}
.contact-form{
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content {
      lg:center;
      xs: space-between;
  }
}
.home-link{
  margin-bottom: 10px;
}
.radio-label{
  color:#000;
  font-size:18px;
}
.required{
  background-color: white;
  top: 12px;
  left: 10px;
  width: max-content;
  padding:0px 3px;
  z-index: 9;
}
.filterCheckBox{
  .MuiTypography-root{
    font-size:15px!important;
  }
}
.input-label{
  font-size:15px;
}
.red-asterisk{
  color:red;
}
.nav-btns{
  font-size:15px;
}
.send-btn{
  width:100%;
  display:flex;
  justify-content:center;
  height:56px;
  margin-top:16px;
  button{
    font-size:21px;
    text-transform:none;
    color:white;
    background:${colorNew.primaryColor};
    font-weight:500;
    width:100%; 
  }
}
.error-field {
  border-color: red; /* You can customize this to your preferred error style */
}
.error-message {
  color:'red';
}
.adorement{
  position: absolute;
  bottom: 16px;
  right: 0px;
  padding-top:10px;
}
.country-phone-input{
  display: flex;
  > .MuiBox-root {
    margin-right: 20px;
    margin-top:22px;
  }
  .country-drop {
    border: 1px solid rgb(0 0 0 / 23%);
    height: 55px;
    .icon-hold {
      img {
        width: 60;
        height: 31;
      }
    }
  }
}

@media (max-width: 992px){
  margin:0px 0px;
  padding:11px 0px 40px;
  .imageSide{
    display:none;
  }
}
`