import { translations } from "locales/translations";
import { routes } from "routes/routes";
import { vehicles } from "./variables";
import 'locales/i18n';
import i18n from 'i18next';

// META TITLE FOR SHOP PAGE
export const showShopMetaTitle = (matchShopPath, shopCaseItems) => {
  const commonParams = {
    companyName: shopCaseItems?.showcase?.name,
    localityName: shopCaseItems?.showcase?.limited_address?.locality,
  };

  const pathMappings = {
    [`${routes.publicRoutes.shop}/:ads_type?/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})`]: {
      classic: translations.META_AND_H1_FOR_SEO.SHOWCASE_HOME_PAGE_CLASSIC_META_TITLE,
      auction: translations.META_AND_H1_FOR_SEO.SHOWCASE_HOME_PAGE_AUCTION_META_TITLE,
    },
    [`${routes.publicRoutes.shop}/about`]: translations.META_AND_H1_FOR_SEO.SHOWCASE_ABOUT_PAGE_META_TITLE,
    [`${routes.publicRoutes.shop}/contact`]: translations.META_AND_H1_FOR_SEO.SHOWCASE_CONTACT_PAGE_META_TITLE,
    [`${routes.publicRoutes.shop}/service`]: translations.META_AND_H1_FOR_SEO.SHOWCASE_SERVICE_PAGE_META_TITLE,
  };

  const path = Object.keys(pathMappings).find((p) => matchShopPath?.path === p);

  if (path) {
    const descriptionKey = path.includes(':ads_type') ? pathMappings[path][matchShopPath?.params?.ads_type] : pathMappings[path];
    return i18n.t(descriptionKey, commonParams);
  }
  return '';
};

// META DESCRIPTION FOR SHOP PAGE
export const showShopMetaDescription = (matchShopPath, shopCaseItems) => {
  const commonParams = {
    companyName: shopCaseItems?.showcase?.name,
    localityName: shopCaseItems?.showcase?.limited_address?.locality,
    countryName: shopCaseItems?.showcase?.limited_address?.country,
  };

  const pathMappings = {
    [`${routes.publicRoutes.shop}/:ads_type?/:vehicle(${vehicles.car}|${vehicles.motorcycles}|${vehicles.bike}|${vehicles.step})`]: {
      classic: translations.META_AND_H1_FOR_SEO.SHOWCASE_HOME_PAGE_CLASSIC_META_DESCRIPTION,
      auction: translations.META_AND_H1_FOR_SEO.SHOWCASE_HOME_PAGE_AUCTION_META_DESCRIPTION,
    },
    [`${routes.publicRoutes.shop}/about`]: translations.META_AND_H1_FOR_SEO.SHOWCASE_ABOUT_PAGE_META_DESCRIPTION,
    [`${routes.publicRoutes.shop}/contact`]: translations.META_AND_H1_FOR_SEO.SHOWCASE_CONTACT_PAGE_META_DESCRIPTION,
    [`${routes.publicRoutes.shop}/service`]: translations.META_AND_H1_FOR_SEO.SHOWCASE_SERVICE_PAGE_META_DESCRIPTION,
  };

  const path = Object.keys(pathMappings).find((p) => matchShopPath?.path === p);

  if (path) {
    const descriptionKey = path.includes(':ads_type') ? pathMappings[path][matchShopPath?.params?.ads_type] : pathMappings[path];
    return i18n.t(descriptionKey, commonParams);
  }
  return '';
};

// H1 HEADING TRANSLATIONS
export const getDefaultHeading = (vehicle) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_H1_HEADING);
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_H1_HEADING);
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_H1_HEADING);
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_H1_HEADING);
  }
}

export const getHeadingWithCountry = (vehicle, countryName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_H1_HEADING_WITH_COUNTRY, { countryName });
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_H1_HEADING_WITH_COUNTRY, { countryName });
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_H1_HEADING_WITH_COUNTRY, { countryName });
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_H1_HEADING_WITH_COUNTRY, { countryName });
  }
}

export const getHeadingWithBrand = (vehicle, brandName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_H1_HEADING_WITH_BRAND, { brandName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_H1_HEADING_WITH_BRAND, { brandName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_H1_HEADING_WITH_BRAND, { brandName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_H1_HEADING_WITH_BRAND, { brandName })
  }
}

export const getHeadingWithBrandAndModel = (vehicle, brandName, modelName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_H1_HEADING_WITH_BRAND_MODEL, { brandName, modelName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_H1_HEADING_WITH_BRAND_MODEL, { brandName, modelName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_H1_HEADING_WITH_BRAND_MODEL, { brandName, modelName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_H1_HEADING_WITH_BRAND_MODEL, { brandName, modelName })
  }
}

export const getHeadingWithBrandAndCountry = (vehicle, brandName, countryName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_H1_HEADING_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_H1_HEADING_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_H1_HEADING_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_H1_HEADING_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
}

export const getHeadingWithBrandModelCountry = (vehicle, brandName, modelName, countryName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_H1_HEADING_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_H1_HEADING_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_H1_HEADING_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_H1_HEADING_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
}

// META TITLE TRANSLATIONS
export const getMetaTitleWithCountry = (vehicle, countryName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_TITLE_WITH_COUNTRY, { countryName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_TITLE_WITH_COUNTRY, { countryName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_TITLE_WITH_COUNTRY, { countryName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_TITLE_WITH_COUNTRY, { countryName })
  }
}

export const getMetaTitleWithBrand = (vehicle, brandName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_TITLE_WITH_BRAND, { brandName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_TITLE_WITH_BRAND, { brandName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_TITLE_WITH_BRAND, { brandName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_TITLE_WITH_BRAND, { brandName })
  }
}

export const getMetaTitleWithBrandAndModel = (vehicle, brandName, modelName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_TITLE_WITH_BRAND_MODEL, { brandName, modelName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_TITLE_WITH_BRAND_MODEL, { brandName, modelName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_TITLE_WITH_BRAND_MODEL, { brandName, modelName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_TITLE_WITH_BRAND_MODEL, { brandName, modelName })
  }
}

export const getMetaTitleWithBrandAndCountry = (vehicle, brandName, countryName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_TITLE_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_TITLE_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_TITLE_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_TITLE_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
}

export const getMetaTitleWithBrandModelCountry = (vehicle, brandName, modelName, countryName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_TITLE_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_TITLE_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_TITLE_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_TITLE_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
}

// META DESCRIPTION TRANSLATIONS
export const getMetaDescriptionWithCountry = (vehicle, countryName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_DESCRIPTION_WITH_COUNTRY, { countryName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_DESCRIPTION_WITH_COUNTRY, { countryName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_DESCRIPTION_WITH_COUNTRY, { countryName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_DESCRIPTION_WITH_COUNTRY, { countryName })
  }
}

export const getMetaDescriptionWithBrand = (vehicle, brandName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_DESCRIPTION_WITH_BRAND, { brandName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_DESCRIPTION_WITH_BRAND, { brandName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_DESCRIPTION_WITH_BRAND, { brandName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_DESCRIPTION_WITH_BRAND, { brandName })
  }
}

export const getMetaDescriptionWithBrandAndModel = (vehicle, brandName, modelName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_DESCRIPTION_WITH_BRAND_MODEL, { brandName, modelName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_DESCRIPTION_WITH_BRAND_MODEL, { brandName, modelName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_DESCRIPTION_WITH_BRAND_MODEL, { brandName, modelName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_DESCRIPTION_WITH_BRAND_MODEL, { brandName, modelName })
  }
}

export const getMetaDescriptionWithBrandAndCountry = (vehicle, brandName, countryName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_DESCRIPTION_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_DESCRIPTION_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_DESCRIPTION_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_DESCRIPTION_WITH_BRAND_COUNTRY, { brandName, countryName })
  }
}

export const getMetaDescriptionWithBrandModelCountry = (vehicle, brandName, modelName, countryName) => {
  if (vehicle == vehicles.car) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_DESCRIPTION_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
  if (vehicle == vehicles.bike) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_DESCRIPTION_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
  if (vehicle == vehicles.step) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_DESCRIPTION_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
  if (vehicle == vehicles.motorcycles) {
    return i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_DESCRIPTION_WITH_BRAND_MODEL_COUNTRY, { brandName, modelName, countryName })
  }
}