import { put, select, takeEvery } from 'redux-saga/effects';
import { ApiPaths } from "services/ApiPaths";
import Api from "services/axios";
import { addReviewsFailure, addReviewsRequest, addReviewsSuccess, flagReviewsFailure, flagReviewsRequest, flagReviewsSuccess, followUnfollowFailure, followUnfollowRequest, followUnfollowSuccess, followersListFailure, followersListRequest, followersListSuccess, followingListFailure, followingListRequest, followingListSuccess, getProfileFailure, getProfileRequest, getProfileSuccess, getReviewsFailure, getReviewsRequest, getReviewsSuccess, getVehicleHistoryFailure, getVehicleHistorySuccess, updateRating, updateReviewsFailure, updateReviewsRequest, updateReviewsSuccess } from 'store/actions/publicProfile-action';

// get Reviews List Saga function
function* _getReviewsRequest({ payload }) {
  try {
    let uri = ApiPaths.publicProfile.reviewsRatings + `?page=${payload.page}&filter[model_type]=${payload.model_type}&filter[model_id]=${payload.model}`
    const { data } = yield Api.get(uri, {});
    yield put(getReviewsSuccess(data))
  } catch (e: any) {
    yield put(getReviewsFailure(e.response));
  }
}

// Add review Saga function
function* _addReviewRequest({ payload }) {
  try {
    let uri = ApiPaths.publicProfile.addReviews
    const { data } = yield Api.post(uri, payload);
    yield put(addReviewsSuccess(data))
    yield put(updateRating(data))

  } catch (e: any) {
    yield put(addReviewsFailure(e.response));
  }
}

// Add review Saga function
function* _updateReviewRequest({ payload }) {
  try {
    let newPayload = structuredClone(payload)
    delete newPayload['id'];
    const uri = `${ApiPaths.publicProfile.updateReviews}/${payload.id}?_method=PATCH`
    const { data } = yield Api.post(uri, newPayload);
    yield put(updateReviewsSuccess(data))
    yield put(updateRating(data))

  } catch (e: any) {
    yield put(updateReviewsFailure(e.response));
  }
}

// Add review Saga function
function* _flagReviewRequest({ payload }) {
  try {
    let newPayload = structuredClone(payload)
    delete newPayload['id'];
    let uri = `${ApiPaths.publicProfile.reviewsRatings}/${payload.id}${ApiPaths.publicProfile.flagReviews}`
    const { data } = yield Api.post(uri, newPayload);
    data.data = payload
    yield put(flagReviewsSuccess(data))
  } catch (e: any) {
    yield put(flagReviewsFailure(e.response));
  }
}

// Get profile Saga function
function* _getProfileRequest({ payload }) {
  try {
    let uri = `${payload.slug}${ApiPaths.publicProfile.profile}/${payload.type}`
    const { data } = yield Api.get(uri, {});
    yield put(getProfileSuccess(data))
  } catch (e: any) {
    yield put(getProfileFailure(e.response));
  }
}

// Get profile Saga function
function* _getfollowUnfollowRequest({ payload }) {
  try {
    let uri = ApiPaths.publicProfile.follow
    const { data } = yield Api.post(uri, payload);
    data.data = payload
    yield put(followUnfollowSuccess(data))
  } catch (e: any) {
    yield put(followUnfollowFailure(e.response));
  }
}

// Get followers lisr Saga function
function* _followersListRequest({ payload }) {
  try {
    let uri = `${ApiPaths.publicProfile.followers}?page=${payload.page}&keyword=${payload.keyword}&${payload.profile_user_id ? "profile_user_id=" + payload.profile_user_id : ""}${payload.profile_subsidiary_id ? "profile_subsidiary_id=" + payload.profile_subsidiary_id : ""}`
    const { data } = yield Api.get(uri, {});
    yield put(followersListSuccess(data))
  } catch (e: any) {
    yield put(followersListFailure(e.response));
  }
}

// Get following lisr Saga function
function* _followingListRequest({ payload }) {
  try {
    let uri = `${ApiPaths.publicProfile.following}?page=${payload.page}&keyword=${payload.keyword}&${payload.profile_user_id ? "profile_user_id=" + payload.profile_user_id : ""}${payload.profile_subsidiary_id ? "profile_subsidiary_id=" + payload.profile_subsidiary_id : ""}`
    const { data } = yield Api.get(uri, {});
    yield put(followingListSuccess(data))
  } catch (e: any) {
    yield put(followingListFailure(e.response));
  }
}

export const publicProfileSaga = [
  takeEvery(getReviewsRequest, _getReviewsRequest),
  takeEvery(addReviewsRequest, _addReviewRequest),
  takeEvery(updateReviewsRequest, _updateReviewRequest),
  takeEvery(getProfileRequest, _getProfileRequest),
  takeEvery(followUnfollowRequest, _getfollowUnfollowRequest),
  takeEvery(followersListRequest, _followersListRequest),
  takeEvery(followingListRequest, _followingListRequest),
  takeEvery(flagReviewsRequest, _flagReviewRequest)
];
