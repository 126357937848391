import { Box, CircularProgress, Modal, Popover, Tab, Tabs } from '@mui/material';
import { SvgPlusLite } from 'app/components/svgicons/svg';
import { SvgmemSearch } from 'app/components/svgicons/svgNew';
import { translations } from 'locales/translations';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ChatWrapper } from '../../style/chatStyle';
import AvatarandDetails from './AvatarandDetails';
import { InitiateChat } from '../../interface/interface';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { archiveParticularChatSuccess, getChatSuccess, getUserListRequest, particularChatSuccess, resetUserList } from 'store/actions/chat-action';
import { CHAT_TABS, archivedStatus, chatCases, chatType, createStorechat } from 'config/chatConfig';
import { Toaster } from 'services/Toaster';
import { Strings, getSubsidiaryStored } from 'config/variables';
import { lan } from 'locales/multiPageTranslation/adsLan';
import ToastMessageWithButton from 'app/components/ToastMessageWithButton';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes/routes';
import CreateLicensePlate from 'app/pages/LicensePlatePage/Component/CreateLicensePlate';
import * as publicProfileConstants from "config/publicProfileConfig"


const CreateNewChat = ({ setSearchText, setLicensePlateAndCountry, chatTypeId, setChatType, page, setPage, handleSearchUser, showInitiateChatModal, setShowInitiateChatModal, setChatId, allUserList, searchText, handleSearchForLicensePlate, searched }: InitiateChat) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const scrollRef: any = useRef(null)

    const { chatStoreData, chatList, chatListAllData, loadingUsers, userListLastPage } = useSelector((state: RootStateOrAny) => state.chat)
    const { userData } = useSelector((state: RootStateOrAny) => state.user)

    const [scrollFlag, _scrollFlag] = useState<boolean>(true);
    const [selectedTab, _selectedTab] = React.useState(CHAT_TABS.USERS);

    // function for handling tab change 
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        _selectedTab(newValue);
    };

    const handleCreatechat = (receiverId, accountType) => {
        createStorechat(receiverId, chatCases.PERSONAL_CHAT, dispatch, accountType)
        setShowInitiateChatModal(false)
    }
    useEffect(() => {
        if (chatStoreData && Object.keys(chatStoreData).length && chatStoreData.success) {

            let chatListData = [...chatList]
            const findUser = chatListData.some((o) => o.id === chatStoreData.data.item.id)
            dispatch(archiveParticularChatSuccess({}))
            dispatch(particularChatSuccess({}))
            // If chat is initiated with blocked user
            if (chatStoreData.data.is_blocked) {
                setChatType(chatType.BLOCKED)
                setChatId(chatStoreData.data.item.id)
                return;
            }
            // if chat initiated with archived user then
            if (chatStoreData.data.item.is_archived == archivedStatus.archive) {
                // user appended in archived list and chat type updated
                setChatType(chatType.ARCHIVED)
                setChatId(chatStoreData.data.item.id)
                return;
            }
            //if chat is not archived user appended in All message chat list and chat type updated
            if (chatStoreData.data.item.is_archived == archivedStatus.unArchive) {
                setChatType(chatType.ALL_MESSAGES)
                setChatId(chatStoreData.data.item.id)
            }
            if (!findUser) {
                let chatListObject = { ...chatListAllData }
                chatListData = [chatStoreData.data.item, ...chatList]
                chatListObject.data.data = chatListData
                setChatId(chatStoreData.data.item.id)
                dispatch(getChatSuccess({ data: chatListObject, append: false }))
                return;
            }
            localStorage.setItem("chat_id", chatStoreData.data.item.id)
            setChatId(chatStoreData.data.item.id)
        }

    }, [chatStoreData])


    const handleModal = () => {
        if (userData?.profile_is_verified === false && !getSubsidiaryStored()) {
            Toaster.error(<ToastMessageWithButton type='error' buttonText={t(...lan.clickHereToVerify())} message={Strings.verifyString} click={() => history.push(routes.userRoutes.idVerfication)} />, false)
            return;
        }
        setPage(1)
        setShowInitiateChatModal(true)
        dispatch(getUserListRequest({ keyword: '' }))
    }

    //Function request for next page data when scrolled to bottom
    const handleScroll = (e) => {
        // bottom return boolean value and Math.abs to get absolute value 
        const bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;
        if (bottom && scrollFlag && page < userListLastPage) {
            setPage(page + 1)
            let data = {
                page: page + 1
            }
            if (searchText && searchText !== '') {
                data['keyword'] = searchText
            }
            dispatch(getUserListRequest(data));
            _scrollFlag(false)
            return;
        } else {
            _scrollFlag(true)
        }
    }
    const handlLicensePlate = () => {
        setSearchText('')
        dispatch(resetUserList())
    }
    const handleUsers = () => {
        dispatch(getUserListRequest({ keyword: '' }))
        setLicensePlateAndCountry({
            country: '',
            LicensePlate: ''
        })
    }

    const showListing = () => {
        if (selectedTab === CHAT_TABS.USERS) {
            return true
        }
        if (selectedTab === CHAT_TABS.LICENSE_PLATE && searched) {
            return true
        }
        return false
    }

    return (
        <ChatWrapper>
            {chatTypeId === chatType.ALL_MESSAGES && <span onClick={() => handleModal()}><SvgPlusLite className='pointer' /></span>}
            <Modal
                onClose={() => {
                    setShowInitiateChatModal(false)
                    _selectedTab(CHAT_TABS.USERS)
                    handleUsers()
                }}
                open={showInitiateChatModal}>
                <ChatWrapper>
                    <Box className='intiateChat intiateChatPopup flexColumn'>

                        <Box className="invite-member" >
                            {<> <h2>{t(translations.CHAT.CREATE_PERSONAL_CHAT)}</h2>
                                <Box sx={{ width: '100%' }}>
                                    <Tabs
                                        value={selectedTab}
                                        onChange={handleChange}
                                        aria-label="wrapped label tabs example"
                                    >
                                        <Tab value="users" label={t(translations.CHAT.USERS)} onClick={handleUsers} sx={{ width: '50%', textTransform: 'none', fontSize: '19px' }} />
                                        <Tab value="licPlate" label={t(translations.ads.LPLATE)} onClick={handlLicensePlate} sx={{ width: '50%', textTransform: 'none', fontSize: '19px' }} />
                                    </Tabs>
                                </Box>
                                {selectedTab == CHAT_TABS.USERS ?
                                    <div className="input-group">
                                        <span>
                                            <SvgmemSearch />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t(translations.webTexts.SEARCH_MEMBER)}
                                            aria-label="search"
                                            aria-describedby="search"
                                            onChange={(e) => handleSearchUser(e)}
                                            autoFocus
                                        />
                                    </div> :
                                    <Box sx={{ mt: 2 }}>
                                        <CreateLicensePlate setLicensePlateAndCountry={setLicensePlateAndCountry} hideItems={true} handleSearchForLicensePlate={handleSearchForLicensePlate} />
                                    </Box>
                                }
                            </>
                            }

                            {showListing() &&
                                <>
                                    <Box className="suggestedMember" sx={{ mt: 2 }}>
                                        <h6 className="suggesttitle">
                                            {selectedTab === CHAT_TABS.USERS ? t(translations.webTexts.SUGESTION_MEMBER_CARTOBIKE) : t(translations.LICENSEPLATE.OWNERS)}
                                        </h6>
                                    </Box>
                                    <Box className="intiateChatUserList intiateChatUserListPopup scrollBar" onScroll={(e) => handleScroll(e)} ref={scrollRef} sx={{ maxHeight: selectedTab == CHAT_TABS.USERS ? '39vh !important' : '31.3vh !important' }}>

                                        {/* Showing list of data */}
                                        {allUserList && allUserList.length > 0 ? allUserList.map((user, index) => (
                                            <Box className='select_user pointer flexRow alignItemsCenter justifyContentBetween' key={user.id} style={{ position: 'relative' }}
                                                onClick={() => handleCreatechat(user.id, user.source)}>
                                                <AvatarandDetails userInfo={user} hideStatus={true} hideFlag={false} newChat={true} popup={true} hidebackButton={true} userInfo={user} text={`${publicProfileConstants.convertFollowersCount(user.followers_count)}  ${user.followers_count == 1 ? t(translations.PUBLICPROFILE.FOLLOWER) : t(translations.PUBLICPROFILE.FOLLOWERS)}`} followersCount={true} />
                                                {user.source === "subsidiary" && <Box sx={{ position: 'absolute', right: "15px" }}>
                                                    <span className="dealerText">{t(translations.webTexts.DEALER)}</span>
                                                </Box>}
                                            </Box>
                                        ))
                                            :
                                            !loadingUsers &&
                                            <>
                                                {
                                                    selectedTab === CHAT_TABS.USERS && <Box className={'alignItemsCenter justifyContentCenter select_user pointer'} sx={{ color: '#999999' }}>
                                                        {t(translations.CHAT.USER_NOT_FOUND)}
                                                    </Box>
                                                }
                                                {
                                                    searched && selectedTab === CHAT_TABS.LICENSE_PLATE && <Box className={'select_user pointer'} sx={{ color: '#999999' }}>
                                                        {t(translations.LICENSEPLATE.USER_NOT_FOUND)}
                                                    </Box>
                                                }
                                            </>
                                        }

                                        {/* Loader appears while loading data */}
                                        {loadingUsers &&
                                            <Box className='select_user pointer alignItemsCenter justifyContentCenter'>
                                                <CircularProgress />
                                            </Box>
                                        }
                                    </Box>
                                </>
                            }
                        </Box>
                    </Box>
                </ChatWrapper>
            </Modal>
        </ChatWrapper>
    )
}

export default CreateNewChat