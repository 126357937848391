import PublicLayout from 'app/Component2/Layouts/PublicLayout'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { urlParamInterface } from 'config/interface'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { MyVehicleSaleWrapper } from '../Style/MyVehicleSaleWrapper'
import { myVehicleStatus } from 'config/variables'
import { Box, Button } from '@mui/material'
import UserAndVehicleDetails from '../Components/UserAndVehicleDetails'
import PricingAndSalesDetails from '../Components/PricingAndSalesDetails'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { getVehicleDetailRequest } from 'store/actions/vehicle-actions'
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader'
import { translations } from 'locales/translations'
import { useTranslation } from 'react-i18next'
import { SvgMobileLeftArrow } from 'app/components/svgicons/svgNew'
import { getParamQueries, saleType } from 'config/appConfig'
import { Nullable } from 'app/pages/Chat/interface/interface'

const Feature = (props: any) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const { t } = useTranslation()


    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType()
    const { my_vehicle_status, vehicle_id }: urlParamInterface = useParams();

    const { loading, validateData } = useSelector((state: RootStateOrAny) => state.vehicle)

    const [typeOfSale, _typeOfSale] = useState<Nullable<number>>()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [my_vehicle_status])

    useEffect(() => {
        dispatch(getVehicleDetailRequest({ id: vehicle_id }))
    }, [vehicle_id])

    useEffect(() => {
        if (my_vehicle_status == myVehicleStatus.confirmSale) {
            const payload = getParamQueries(location.search)
            if (payload.saleType) {
                _typeOfSale(parseInt(payload.saleType))
            }
        }
    }, [location])

    const handleCancelButton = () => {
        const prevURL = localStorage.getItem('myadsURL')
        if (prevURL) {
            history.push(prevURL)
            return
        }
        history.goBack()
    }

    return (
        <PublicLayout
            hideFooter={true}
            hideFooterNavBar={true}
            hideHeader={isSmallDevice || isMediumDevice ? true : false}
            hideNaveBar={true}
            title={'My ads'}
            image={process.env.REACT_APP_BASE_URL + '/content.jpg'}
            type="website"
            hideFilter={false}
            backButton={<span className='back-button' onClick={() => history.goBack()}><SvgMobileLeftArrow /></span>}
        >

            {loading ? <ContentLoader /> : null}

            <MyVehicleSaleWrapper>

                <Box className='pageHeader container'>

                    {(isSmallDevice || isMediumDevice) && <SvgMobileLeftArrow onClick={() => history.goBack()} />}

                    <h1>
                        {my_vehicle_status == myVehicleStatus.createOrder && t(translations.myAdscreen.CREATE_ORDER)}

                        {my_vehicle_status == myVehicleStatus.confirmSale && typeOfSale && typeOfSale == saleType.sold && t(translations.ads.SALE_VEHICLE)}

                        {my_vehicle_status == myVehicleStatus.confirmSale && typeOfSale && typeOfSale == saleType.reserve && t(translations.ads.CONFIRM_RESERATION)}
                    </h1>


                    {/* cancel button appears only in Webview */}
                    {
                        (isLargeDevice || isExtraLargeDevice) && my_vehicle_status == myVehicleStatus.confirmSale &&
                        <Button onClick={handleCancelButton}>
                            {typeOfSale && typeOfSale == saleType.sold && t(translations.ads.CANCEL_SALE)}
                            {typeOfSale && typeOfSale == saleType.reserve && t(translations.ads.CANCEL_RESERATION)}
                        </Button>
                    }

                    {(isSmallDevice || isMediumDevice) && <SvgMobileLeftArrow style={{ opacity: 0 }} />}

                </Box>

                <Box className='flexRow flexWrap container justifyContentBetween '>
                    <Box className='userAndVehicleDetails'>
                        <UserAndVehicleDetails />
                    </Box>
                    <Box className='pricingAndSalesDetails'>
                        <PricingAndSalesDetails />
                    </Box>
                </Box>

                {!loading && (isLargeDevice || isExtraLargeDevice) && <Box className='vehcileDetails' sx={{ p: 2 }}>
                    <span className='vehicleName'>{validateData?.full_title ?? "-"}</span>
                    <Box className='details flexRow'>
                        <p> {t(translations.ads.VIN)} : </p>
                        <span > {validateData?.vin ?? "-"}</span>
                    </Box>
                    <Box className='details flexRow'>
                        <p > {t(translations.ads.FIRST_REGISTER)} :</p>
                        <span >
                            {validateData?.vehicle_first_register_month && validateData?.vehicle_first_register_year ? `${validateData.vehicle_first_register_month}/ ${validateData.vehicle_first_register_year}` : "-"}
                        </span>

                    </Box>
                </Box>
                }

            </MyVehicleSaleWrapper>
        </PublicLayout>
    )
}

export default Feature