import { Box } from '@mui/material'
import { DetailListing, DetailsTitle } from 'app/Component2/Layouts/AdsLayouts/AdsStyledSlice'
import { ElectricDetailsI } from 'app/pages/AdsAuctionDetailsPage/AuctionDetailInterface/AuctionInterface'
import { secToHourMinun } from 'config/variables'
import { lan } from 'locales/multiPageTranslation/adsLan'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DetailsWrapper } from '../Details'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { translations } from 'locales/translations'

const ElectricDetails = ({ item }: ElectricDetailsI) => {
    const { t } = useTranslation();
    const [isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice] = useDeviceType();

    return (
        <DetailsWrapper>
            <Box className={`${!isSmallDevice && 'grid_color_odd'}`}
                sx={{
                    'span': {
                        fontWeight: 500,
                        display: "inline-block",
                        whiteSpace: "nowrap"
                    },

                }}
            >
                <DetailsTitle sx={{ ml: '12px' }}>{t(...lan.electric())}</DetailsTitle>
                <Box>
                    <DetailListing
                        className='vehicle-detail-label'
                    >
                        <label>{t(...lan.powerKwChargingMax())}</label>
                        <span>{item?.owner_vehicle?.power_kw_charging_max ? item?.owner_vehicle?.power_kw_charging_max + " kW" : "-"}</span>
                    </DetailListing>
                    <DetailListing
                        className='vehicle-detail-label'
                    >
                        <label>{t(...lan.homeChargingTimeTo80())}</label>
                        <span>{item?.owner_vehicle?.charge_duration_home_80 ? secToHourMinun(item?.owner_vehicle?.charge_duration_home_80) + ' hrs' : "-"}</span>
                    </DetailListing>
                    <DetailListing
                        className='vehicle-detail-label'
                    >
                        <label>{t(...lan.fastChargingTimeTo80())}</label>
                        <span>{item?.owner_vehicle?.charge_duration_fast_80 ? secToHourMinun(item?.owner_vehicle?.charge_duration_fast_80) + ' hrs' : '-'}</span>
                    </DetailListing>
                    <DetailListing
                        className='vehicle-detail-label'
                    >
                        <label>{t(translations.ads.AUTONOMY)}</label>
                        <span>{item?.owner_vehicle?.battery_range_max ? item?.owner_vehicle?.battery_range_max + " km" : "-"}</span>
                    </DetailListing>

                    <DetailListing
                        className='vehicle-detail-label'
                    >
                        <label>{t(...lan.consumption())} </label>
                        <span>{item?.owner_vehicle?.consumption_wh_per_km ? item?.owner_vehicle?.consumption_wh_per_km + " Wh/km" : "-"}</span>
                    </DetailListing>

                    <DetailListing
                        className='vehicle-detail-label'
                    >
                        <label>{t(...lan.numberOfMotor())}</label>
                        <span>{item?.owner_vehicle?.number_of_motor ? item?.owner_vehicle?.number_of_motor : "-"}</span>
                    </DetailListing>
                </Box>
            </Box>
        </DetailsWrapper>
    )
}

export default ElectricDetails