import React, { useState } from 'react'
import { onMessageListener } from 'firebasePushNotifications'; //push notification code
import { adsType, getSubsidiaryStored } from 'config/variables';
import { useDispatch } from 'react-redux';
import { getUserDataRequest } from 'store/actions/user-actions';
import { Toaster } from 'services/Toaster';
import { VERIFICATION_STATUS } from 'config/appConfig';
import { getsubsidiaryActiveMemberListRequest } from 'store/actions/chat-action';
import { getNotificationCountRequest } from 'store/actions/company-actions';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes/routes';

const FCMListener = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [notification, setNotification] = useState({ title: '', body: '' });

    //*************************************
    //push notification code
    onMessageListener().then((payload: any) => {
        let data = payload;
        let sub = getSubsidiaryStored()
        let parJson: any = '';
        setNotification({ title: payload.notification.title, body: payload.notification.body })
        if (data?.data?.additional_data) {
            parJson = JSON.parse(payload.data.additional_data)
        }
        if (payload.data && payload.data.notification_type !== 'chat' && payload.data.notification_type !== 'profile_follow' && payload.data.notification_type !== 'review_add') {
            let profileVerification = JSON.parse(payload.data.additional_data)
            if (profileVerification.profile_verification_status && profileVerification.profile_verification_status == VERIFICATION_STATUS.VERIFIED) {
                dispatch(getUserDataRequest())
            }
            if (sub && sub !== null) {
                if (parJson?.subsidiary_id == sub) {
                    Toaster.info(
                        <>
                            <div onClick={() => {
                                if (payload.data && payload.data.notification_type == 'create_ad') {
                                    if (parJson.type_of_sale == adsType.auction) {
                                        history.push(routes.vehicleRoutes.auctionAds + '/' + parJson.slug)
                                        return
                                    }
                                    history.push(routes.vehicleRoutes.publicAds + '/' + parJson.slug)
                                }
                            }}>
                                {payload.notification.body}
                            </div>
                        </>
                    )
                }
            } else {
                Toaster.info(
                    <>
                        <div onClick={() => {
                            if (payload.data && payload.data.notification_type == 'create_ad') {
                                if (parJson.type_of_sale == adsType.auction) {
                                    history.push(routes.vehicleRoutes.auctionAds + '/' + parJson.slug)
                                    return
                                }
                                history.push(routes.vehicleRoutes.publicAds + '/' + parJson.slug)
                            }
                        }}>
                            {payload.notification.body}
                        </div>
                    </>
                )
            }
        }
        if (payload && payload.data && payload.data.notification_type == 'staff_invitation_response') {
            dispatch(getsubsidiaryActiveMemberListRequest({ subsidiary_id: getSubsidiaryStored() }))
        }
        if (payload && payload.data && payload.data.notification_type !== 'chat') {
            dispatch(getNotificationCountRequest())
        }
    }).catch(err => console.log('failed: ', err));
    // **************************************

    return (
        <></>
    )
}

export default FCMListener