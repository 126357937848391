import moment from 'moment';
import { ApiPaths } from '../services/ApiPaths';
import Api from '../services/axios';
import 'locales/i18n';
import i18n from 'i18next';
import { SvgfacebookIcon, SvginstaIcon, SvgsnapIcon, SvgticktokIcon, SvgtwitterIcon } from 'app/components/svgicons/svgNew';
import { localstorageKey } from './constants';
import { translations } from 'locales/translations';
import { routes } from 'routes/routes';
// function hello() {
//     return i18n.t("FOR_SALE_IN");
// }
export const EmailValidationRegex = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
export const spaceValidationRegex = {
  exp: /^[A-Za-z0-9]*$/,
};

export const PasswordValidationRegex = {
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])^(?!.*[\s]).{8,20}$/,
};
export const PhoneValidationRegex = {
  // for 13 digits 
  phone: /^((\+|0{0,2})([0-9]){1,3})?[-.●\s]?\(?([0-9]{2,3})\)?[-.●\s]?([0-9]{3})[-.●\s]?([0-9]{4})$/
  //for 10 digits
  // phone: /^[-.●\s]?\(?([0-9]{2,3})\)?[-.●\s]?([0-9]{3})[-.●\s]?([0-9]{4})$/
};

export const onlyNumberType = (evt) => {
  ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
}
export const onlyNumberAcceptDecimal = (evt) => {
  if (["e", "E", "+", "-"].includes(evt.key)) {
    evt.preventDefault()
    return;
  }
  const value = evt.target.value;
  if (value.includes(".")) {
    const spliteText = value.split('.')
    if (spliteText[1].length >= 2 && evt.code !== 'Backspace') {
      evt.preventDefault()
      return;
    }
  }
}

export const sitemapCountries = (country) => {
  const sitemapCountries = ["belgium", "netherlands", "france"]
  if (sitemapCountries.includes(country)) {
    return true
  }
  return false
};

export const onlyNumbers = (event) => {
  if (event.type == "paste") {
    var clipboardData = event.clipboardData || window.clipboardData;
    var pastedData = clipboardData.getData('Text');
    if (isNaN(pastedData)) {
      event.preventDefault();

    } else {
      return;
    }
  }
  var keyCode = event.keyCode || event.which;
  if (keyCode >= 96 && keyCode <= 105) {
    // Numpad keys
    keyCode -= 48;
  }
  var charValue = String.fromCharCode(keyCode);
  if (isNaN(parseInt(charValue)) && event.keyCode != 8) {
    event.preventDefault();
  }
}

export const Strings = {
  email: i18n.t('validationAndPopText.EMAIL_IN_VALID'),
  required: i18n.t('validationAndPopText.REQUIRED_FEILD'),
  error: i18n.t('validationAndPopText.SOMETHING_ERROR'),
  password: i18n.t('validationAndPopText.PASSWORD_FORMAT_ERROR'),
  phone: i18n.t('validationAndPopText.PHONE_IN_VALID'),
  completeProfileString: i18n.t('validationAndPopText.COMPLETE_PROFILE_STRING'),
  verifyString: i18n.t('validationAndPopText.VERIFY_ID_STRING'),
  pendingVerification: i18n.t('validationAndPopText.VERIFICATION_PENDING'),
  confirmPassword: i18n.t('validationAndPopText.CONFIRM_PASSWORD'),
  incomplete: "incomplete",
  incompleteExpired: "incomplete_expired",
  pastDue: "past_due",
  vinLengthError: i18n.t('validationAndPopText.VIN_LENGTH_ERROR'),
  titleLengthError: i18n.t('validationAndPopText.TITLE_LENGTH_ERROR'),
  lastMaintanenceError: i18n.t('validationAndPopText.LAST_MAINTANENCE_ERROR'),
  warrantyError: i18n.t('validationAndPopText.WARRANTY_ERROR'),
  warrantyMinimumError: i18n.t('validationAndPopText.WARRANTY_MINIMUM_ERROR'),
  nameRestriction: i18n.t('validationAndPopText.NAME_RESTRICTION_VALIDATION'),
  specialCharactersRestriction: i18n.t('validationAndPopText.SPECIAL_CHARACTERS_VALIDATION'),
  message: i18n.t('validationAndPopText.MESSAGE_LENGTH_VALIDATION_MSSG'),
};
// Plan Detail
export const serviceList = [
  { title: i18n.t('planDetails.ULTIMATECARS') },
  { title: i18n.t('planDetails.ULTIMATEBIKES') },
  { title: i18n.t('planDetails.ULTIMATESCOOTER') },
  { title: i18n.t('planDetails.ACCESSTOAUCTION') },
  { title: i18n.t('planDetails.CRAETEULTIMATE') },
  { title: i18n.t('planDetails.ADDUNLIMITED') },
  { title: i18n.t('planDetails.CREATEYOUROWN') },
  { title: i18n.t('planDetails.MANAGEYOURMEMBER') },
  { title: i18n.t('planDetails.CUSTOMIZEYOURSHOWCASE') },
  { title: i18n.t('planDetails.SHOWCASEPAGE') },
  { title: i18n.t('planDetails.SHOWCASEPAGEVISIBLE') },
  { title: i18n.t('planDetails.PERFORMANCEREPORT') },
]
export const AlertMessages = {
  confirmInvitationError: i18n.t('webTexts.CONFIRM_INVITATION_ERROR'),
  confirmEmailChangeMessage: i18n.t('webTexts.CONFIRME_MAIL_CHANGE_MESSAGE'),
  confirmEmailChangeSuccess: i18n.t('webTexts.CONFIRME_MAIL_CHANGE_SUCCESS'),
  confirmEmailChangeError: i18n.t('webTexts.CONFIRME_MAIL_CHANG_EERROR'),
  permissionsRequiredError: i18n.t('webTexts.PERMISSIONS_REQUIRED_ERROR'),
  selectRoleError: i18n.t('webTexts.SELECT_ROLE_ERROR'),
  noRoleMembers: i18n.t('webTexts.NO_ROLE_MEMBERS'),
  adminDeleteError: i18n.t('webTexts.ADMIN_DELETE_ERROR'),
  adminEditError: i18n.t('webTexts.ADMIN_EDIT_ERROR'),
  adminEditStatusError: i18n.t('webTexts.ADMIN_EDIT_STATUS_ERROR'),
  selectCardError: i18n.t('webTexts.SELECT_CARD_ERROR'),
  cardDeletedSuccess: i18n.t('webTexts.CARD_DELETED_SUCCESS'),
  memberStatusUpdatedSuccess: i18n.t('webTexts.MEMBER_STATUS_UPDATED_SUCCESS'),
  memberDeletedSuccess: i18n.t('webTexts.MEMBER_DELETED_SUCCESS'),
  memberDetailsUpdatedSuccess: i18n.t('webTexts.MEMBER_DETAILS_UPDATED_SUCCESS'),
  roleUpdatedSuccess: i18n.t('webTexts.ROLEUP_DATED_SUCCESS'),
  promoCodeConfirmation: i18n.t('webTexts.PROMO_CODE_CONFIRMATION'),
  companyProfileUpdated: i18n.t('webTexts.COMPANY_PROFILE_UPDATED'),
  companyLogoUpdated: i18n.t('webTexts.COMPANY_LOGO_UPDATED'),
  deletePermissionError: i18n.t('webTexts.DELETE_PERMISSION_ERROR'),
  editPermissionError: i18n.t('webTexts.EDITPER_MISSION_ERROR'),
  bidPermissionError: i18n.t('webTexts.BID_PERMISSION_ERROR'),
  sellingError: i18n.t('webTexts.SELLING_ERROR'),
  fillRequiredFeilds: i18n.t('webTexts.FILL_REQUIRED_FEILDS'),
  confirmPopUp: i18n.t('webTexts.CONFIRM_POPUP'),
  imageSize: i18n.t('webTexts.IMAGE_SIZE'),
  imageFormate: i18n.t('webTexts.IMAGE_FORMATE'),
  imageRemove: i18n.t('webTexts.IMAGE_REMOVE'),
  updateServices: i18n.t('webTexts.SERVICES_SAVED_SUCCESSFULLY'),
  appointmentUpdate: i18n.t('validationAndPopText.ALL_APPOINTMENTS_ARE_UPDATED'),
  appointmentPeriod: i18n.t('validationAndPopText.THE_NUMBER_OF_APPOINTMENTS_PER_PERIOD_MUST_BE'),
  cardDeleteSuccessFul: i18n.t('validationAndPopText.CARD_DELETED_SUCCESSFUL'),
  cardAddedSuccessful: i18n.t('validationAndPopText.CARD_ADDED_SUCCESSFUL'),
  cardUpdated: i18n.t('validationAndPopText.CARD_IS_UPDATED'),
  discountCouponApplied: i18n.t('validationAndPopText.DISCOUNT_COUPON_APPLIED'),
  promoCodeApplied: i18n.t('validationAndPopText.PROMO_CODE_IS_APPLIED'),
  deleteActiveAddError: i18n.t('validationAndPopText.DELETE_ACTIVE_ADD_ERROR'),
  cancelReservationSuccess: i18n.t('validationAndPopText.CANCEL_RESERVSTION_SUCCESS'),
  cancelReservationRequestSent: i18n.t('validationAndPopText.CANCEL_RESERVSTION_REQUEST_SENT'),
  deleteMemberSuccess: i18n.t('validationAndPopText.DELETE_MEMEBER_SUCCESS'),
  editPermissionsError: i18n.t('webTexts.EDITPER_MISSIONS_ERROR'),
  numberOfKeysError: i18n.t('webTexts.NUMBER_OF_KEYS_ERROR'),
  uploadDocumnetError: i18n.t('webTexts.UPLOAD_DOC_ERROR')
};

export const socialMedia = [
  { id: 1, Icon: SvgfacebookIcon, link: `${process.env.REACT_APP_FACEBOOK_SOCIAL_LINK}` },
  { id: 2, Icon: SvginstaIcon, link: `${process.env.REACT_APP_INSTA_SOCIAL_LINK}` },
  { id: 3, Icon: SvgticktokIcon, link: `${process.env.REACT_APP_TIK_TOK_SOCIAL_LINK}` },
  { id: 5, Icon: SvgtwitterIcon, link: `${process.env.REACT_APP_TWITTER_SOCIAL_LINK}` },
  { id: 4, Icon: SvgsnapIcon, link: `${process.env.REACT_APP_SNAPCHAT_SOCIAL_LINK}` },
]

export const CountryCodes = {
  US: { primary: 'English', secondary: '+1' },
  FR: { primary: 'French', secondary: '+2' },
  PK: { primary: 'Deutsch', secondary: '+3' },
  ES: { primary: 'Spanish', secondary: '+4' },
  NL: { primary: 'Dutch', secondary: '+5' },
};

export const productTypes = {
  car: 1,
  motorcycles: 3,
  bike: 4,
  step: 7
}

export const productTypesInverse = {
  1: 'car',
  4: 'bikes',
  7: 'e-scooter',
  3: 'motorcycles'
}
export const vehicleCreateScreens = {
  summaryScreen: 0,
  ChooseToDo: 1,
  CreateclassicAdComponent: 2,
  ChooseAuctionComponent: 3,
  CreateAuctionAddComponent: 4,
  PictureSelection: 5
}
export const getProductTypeId = (text: string) => {
  if (text === vehicles.bike) {
    return productTypes.bike;
  }
  else if (text === vehicles.step) {
    return productTypes.step
  }
  else if (text === vehicles.motorcycles) {
    return productTypes.motorcycles
  }
  return productTypes.car
}

export const vehicles = {
  car: 'car',
  bike: 'bikes',
  step: 'e-scooter',
  motorcycles: 'motorcycles'
}

export const getProductTypeName = {
  [productTypes.bike]: vehicles.bike,
  [productTypes.step]: vehicles.step,
  [productTypes.motorcycles]: vehicles.motorcycles,
  [productTypes.car]: vehicles.car
}

export const KeepOnPark = {
  inclassicAdd: 0,
  inauctionAdd: 1,
  keepOnpark: 2,
}
export const wheelTypes = {
  wheel: 1,
  rim: 0
}
export const adsType = {
  classic: 0,
  auction: 1,
  park: 'park',
  hotdeal: 3
}

export const shopView = {
  web: 1,
  mobile: 0,
}

export const shopTab = {
  ads: 0,
  about: 1,
  contact: 2,
  service: 3,
}

export const status = {
  on: 1,
  off: 0,
}

export const onlineOfflineStatus = {
  on: 1,
  off: 0,
}


export const trophy = {
  won: 1,
  lose: 0,
}

export const becompartnerScreen = {
  salutation: 1,
  subject: 2,
}

export const companySettingTabs = {
  store: 1,
  workinHours: 2,
  department: 3
}

export const InvoiceTabs = {
  creditInvoice: 1,
  vehiclePurchaseInvoice: 2,
  subscriptionInvoice: 3,
  vehicleCommissionsInvoice: 4,
}

export const notifications = {
  allNotification: 1,
  carTopoBikeNotification: 2,
  invitationNotification: 3
}

export const bidResult = {
  won: 1,
  lost: 0
}
export const commissionStatus = {
  commissionPending: 1,
  commissionPaid: 2
}

export const commandText = {
  Cancel: 'Cancel',
  SellerTakeDecision: 'seller_take_decision',
  vehicleReserved: 'vehicle_reserved',
  vehicleUnReserved: 'vehicle_unreserved',
  auctionOver: 'auction_over',
  bidLost: 'bid_lost',
  verificationStatus: 'document_verification_status',
  hotdealPurchased: 'hotdeal_purchased'
}

export const CountriesDrop = ['US', 'FR', 'PK', 'ES', 'NL'];

export const CountriesDropGet = async () => {
  await Api.get(ApiPaths.common.countries + "/?continent=all", {})
    .then((response) => {
      localStorage.setItem("countries", JSON.stringify(response.data.data.items))
    }
    )
};
export const CountriesDropGroupWise = async () => {
  await Api.get(ApiPaths.common.countries + "/?continent=all&group=true", {})
    .then((response) => {
      localStorage.setItem("groupedCountries", JSON.stringify(response.data.data.items))
    }
    )
};
export const convertFourDigiMiliSec = (digi) => {
  let timeParts = digi.split(":");
  const hours = (timeParts[0] * 60)
  const second = (hours + parseInt(timeParts[1])) * 60
  return second
}
export const secToHourMinun = (secs) => {
  var h = Math.floor(secs / (60 * 60));
  var divisor_for_minutes = secs % (60 * 60);
  var m = Math.floor(divisor_for_minutes / 60);
  var divisor_for_seconds = divisor_for_minutes % 60;
  var s = Math.ceil(divisor_for_seconds);
  return `${('0' + h).slice(-2)}:${('0' + m).slice(-2)}`
}
// Converting milliseconds into hours and minutes
export const secToHourMinun2 = (ms) => {
  let minuteMs = (ms / (60)).toFixed(1);
  var hours = Math.floor(minuteMs / 60);
  var minutes = minuteMs % 60;
  return ('0' + hours).slice(-2) + ":" + ('0' + minutes).slice(-2);
}

export const calculateTimer = (targetDate: any) => {
  var date = moment(targetDate);
  var now = moment();

  var duration: any = moment.duration(date.diff(now));
  let days = parseInt(duration.asDays());
  let hours = parseInt(duration.asHours()) % 24;
  let minutes = parseInt(duration.asMinutes()) % 60;
  let seconds = parseInt(duration.asSeconds()) % 60;

  let log = `FROM DB: ${date.format()}, 
  Current Time: ${now.format()}, 
  Difference: ${days} days ${hours}:${minutes}:${seconds}`;

  return [
    days < 0 || isNaN(days) == true ? '00' : days.toString().length == 2 ? days : '0' + days,
    hours < 0 || isNaN(hours) == true ? '00' : hours.toString().length == 2 ? hours : '0' + hours,
    minutes < 0 || isNaN(minutes) == true ? '00' : minutes.toString().length == 2 ? minutes : '0' + minutes,
    seconds < 0 || isNaN(seconds) == true ? '00' : seconds.toString().length == 2 ? seconds : '0' + seconds,
  ];
};

export const CountriesDrop1 = async () => {
  let getCountries: any = JSON.parse(localStorage.getItem('countries') || '{}');
  if (getCountries.length > 0) {
    return getCountries;
  } else {
    // await CountriesDropGet()
    // let getCountries: any = await JSON.parse(localStorage.getItem('countries') || '{}');
    // return getCountries
  }
};
export const CountriesDropGroupWiseData = async () => {
  let getCountries: any = JSON.parse(localStorage.getItem('groupedCountries') || '{}');
  if (Object.keys(getCountries).length > 0) {
    return getCountries;
  } else {
    // await CountriesDropGroupWise()
    // let getCountries: any = await JSON.parse(localStorage.getItem('groupedCountries') || '{}');
    // return getCountries
  }
};

export const numberFormat = (number: any) => {
  if (number === null || number === '') {
    return 0
  } else {
    if (number) {
      let result = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      return result.replace(/\./g, ',')
    }
  }
}

export const getCountry = (id) => {
  CountriesDrop1().then(data => {
    data.map((country, i) => {
      if (country.id == id) {
        return country.fullname;
      }
    });
  })
}

export const getCountryObject = (id) => {
  CountriesDrop1().then(data => {
    data.map((country, i) => {
      if (country.id == id) {
        return country;
      }
    });
  })
}

export const days = [
  i18n.t('webTexts.MONDAY'),
  i18n.t('webTexts.TUESDAY'),
  i18n.t('webTexts.WEDNESDAY'),
  i18n.t('webTexts.THURSDAY'),
  i18n.t('webTexts.FRIDAY'),
  i18n.t('webTexts.SATURDAY'),
  i18n.t('webTexts.SUNDAY'),
];


export const hourss = () => {
  const hoursS: any = [];
  for (let index = 0; index < 24; index++) {
    let numText = index.toString()
    if (numText.length < 2) {
      hoursS.push(`0${index}`)
    } else {
      hoursS.push(`${index}`)
    }
  }
  return hoursS;
}
export const hours = () => {
  const hoursArr: any = [];
  for (let index = 0; index < 24; index++) {
    let numText = index.toString()
    if (numText.length < 2) {
      hoursArr.push(`0${index}`)
    } else {
      hoursArr.push(`${index}`)
    }
  }
  return hoursArr;
}

export const setUserDataToLocal = (params: any) => {
  localStorage.setItem('user', JSON.stringify(params));
}

export const setSubsidiaryToLocal = (params: any) => {
  localStorage.setItem('subsidiary', JSON.stringify(params));
}

export const setActiveSubsidiaryToLocal = (item) => {
  return localStorage.setItem('activeSubsidiary', item);
}

export const getSubsidiaryStored = () => {
  return localStorage.getItem('subsidiary');
}

export const getProduct = (vehicle) => {
  if (vehicle == vehicles.step) {
    return productTypes.step
  }
  if (vehicle == vehicles.bike) {
    return productTypes.bike
  }
  if (vehicle == vehicles.car) {
    return productTypes.car
  }
  if (vehicle == vehicles.motorcycles) {
    return productTypes.motorcycles
  }
}

export const getActiveSubsidiaryStored = () => {
  return localStorage.getItem('activeSubsidiary');
}

export const getUserStored = () => {
  return localStorage.getItem('user');
}

export const removeActiveSubsidiaryStored = () => {
  localStorage.removeItem('activeSubsidiary');
}

export const getTokenStored = () => {
  return localStorage.getItem('token');
}

export const myVehicleStatus = {
  createOrder: "create-order",
  confirmSale: "confirm-sale"
}

export const adsTypes = {
  classic: "classic",
  auction: "auction",
  park: 'park',
  hotDeals: "hot-deals"
}

export const hotdealVehicleStatus = {
  BOOK_OR_SOLD_BY_SELLER: 1,
  PURCHASED_BY_BUYER_OR_BID_ACCEPTED_BY_SELLER: 2
}

export const userTypes = {
  pro: "pro",
  user: "user"
}

export const adsTypesAuction = {
  live: "live",
  action: "action",
  history: 'history'
}

export const auctionPlaceTab = {
  inProgress: 1,
  waitingApproval: 2,
  orders: 3
}

export const myAdsAuctionTab = {
  live: 1,
  actionRequired: 2,
  histroy: 3
}
export const minute = () => {
  const minuteArr: any = [];
  for (let index = 0; index < 60; index++) {
    let numText = index.toString()
    if (numText.length < 2) {
      minuteArr.push(`0${index}`)
    } else {
      minuteArr.push(`${index}`)
    }
  }
  return minuteArr;
}


export const setTime = (shedules, setObj) => {
  const Objects: any = {
    [i18n.t('webTexts.MONDAY')]: {
      day: []
    },
    [i18n.t('webTexts.TUESDAY')]: {
      day: []
    },
    [i18n.t('webTexts.WEDNESDAY')]: {
      day: []
    },
    [i18n.t('webTexts.THURSDAY')]: {
      day: []
    },
    [i18n.t('webTexts.FRIDAY')]: {
      day: []
    },
    [i18n.t('webTexts.SATURDAY')]: {
      day: []
    },
    [i18n.t('webTexts.SUNDAY')]: {
      day: []
    },
  }

  shedules.forEach((item, index) => {
    switch (item.day) {
      case 0:
        Objects[i18n.t('webTexts.MONDAY')].day = [...Objects[i18n.t('webTexts.MONDAY')].day, { start: item.start, end: item.end, day: 0, index: index }];
        break;
      case 1:
        Objects[i18n.t('webTexts.TUESDAY')].day = [...Objects[i18n.t('webTexts.TUESDAY')].day, { start: item.start, end: item.end, day: 1, index: index }];
        break;
      case 2:
        Objects[i18n.t('webTexts.WEDNESDAY')].day = [...Objects[i18n.t('webTexts.WEDNESDAY')].day, { start: item.start, end: item.end, day: 2, index: index }];
        break;
      case 3:
        Objects[i18n.t('webTexts.THURSDAY')].day = [...Objects[i18n.t('webTexts.THURSDAY')].day, { start: item.start, end: item.end, day: 3, index: index }];
        break;
      case 4:
        Objects[i18n.t('webTexts.FRIDAY')].day = [...Objects[i18n.t('webTexts.FRIDAY')].day, { start: item.start, end: item.end, day: 4, index: index }];
        break;
      case 5:
        Objects[i18n.t('webTexts.SATURDAY')].day = [...Objects[i18n.t('webTexts.SATURDAY')].day, { start: item.start, end: item.end, day: 5, index: index }];
        break;
      case 6:
        Objects[i18n.t('webTexts.SUNDAY')].day = [...Objects[i18n.t('webTexts.SUNDAY')].day, { start: item.start, end: item.end, day: 6, index: index }];
        break;
    }

  })
  setObj(Objects)
}

export const publicDescription = (obj) => {
  let string: string = "";
  let vehicleType = obj.type;
  const getCountries: any = JSON.parse(localStorage.getItem(localstorageKey.countries) || '{}')
  string += vehicleType;
  string += ' ' + obj.text1;
  if (obj.country_id) {
    const country = getCountries.filter(x => x.id === parseInt(obj.country_id))
    if (country.length) {
      string += ' ' + country[0].fullname + ','
    }
  } else {
    string += ' belgium,'
  }
  string += ' ' + obj.text2;
  string += ' ' + vehicleType;
  string += ' ' + obj.text3;
  return string;
}

export const publicOgDescription = (vehicleType) => {
  let string: string = "";
  if (vehicleType == productTypes.car) {
    string = i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_DESCRIPTION)
  }
  if (vehicleType == productTypes.bike) {
    string = i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_DESCRIPTION)
  }
  if (vehicleType == productTypes.step) {
    string = i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_DESCRIPTION)
  }
  if (vehicleType == productTypes.motorcycles) {
    string = i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_DESCRIPTION)
  }

  return string;
}

export const publicTitle = (vehicleType) => {
  let string: string = "";
  if (vehicleType == productTypes.car) {
    string = i18n.t(translations.META_AND_H1_FOR_SEO.CAR_META_TITLE)
  }
  if (vehicleType == productTypes.bike) {
    string = i18n.t(translations.META_AND_H1_FOR_SEO.BIKE_META_TITLE)
  }
  if (vehicleType == productTypes.step) {
    string = i18n.t(translations.META_AND_H1_FOR_SEO.SCOOTER_META_TITLE)
  }
  if (vehicleType == productTypes.motorcycles) {
    string = i18n.t(translations.META_AND_H1_FOR_SEO.MOTOR_META_TITLE)
  }

  return string;
}
export const auctionTitle = (vehicleType) => {
  let string: string = "";
  if (vehicleType == productTypes.car) {
    string = i18n.t(translations.ads.AUCTIONMETATITLECAR)
  }
  if (vehicleType == productTypes.bike) {
    string = i18n.t(translations.ads.AUCTIONMETATITLEBIKE)
  }
  if (vehicleType == productTypes.step) {
    string = i18n.t(translations.ads.AUCTIONMETATITLESTEP)
  }

  return string;
}

export const auctionDescription = (vehicleType) => {
  let string: string = "";
  if (vehicleType == productTypes.car) {
    string = i18n.t(translations.ads.AUCTIONDESCRIPTIONCAR)
  }
  if (vehicleType == productTypes.bike) {
    string = i18n.t(translations.ads.AUCTIONDESCRIPTIONBIKE)
  }
  if (vehicleType == productTypes.step) {
    string = i18n.t(translations.ads.AUCTIONDESCRIPTIONSTEP)
  }

  return string;
}

//Meta title
export const metaSetCar = (type, data) => {
  let string: string = "";
  if (!data?.owner_vehicle?.full_title) {
    return
  }
  string += ' ' + data?.owner_vehicle?.full_title
  string += ' -'
  if (data?.user_data && data.user_data?.limited_address?.country) {
    string += ' ' + data.user_data.limited_address.country
  }
  return string + ' - CarToBike';
}

//  specific ad car description
// {brand} {model} | {state neworused} | {fuel} | de {year} | {price} € | à {localityname} - Vente de voitures sur Cartobike en {countryname}
export const ogDescription = (obj) => {
  let string: string = "";
  // {brand}{model}
  if (obj.type == productTypes.car) {
    if (obj?.data?.owner_vehicle?.vehicle?.product_category_type?.product_type_id) {
      if (obj.data.owner_vehicle?.model?.brand_car?.name) {
        string += ' ' + obj.data.owner_vehicle.model.brand_car.name;
      }
      if (obj?.data?.owner_vehicle?.model?.model_name) {
        string += ' ' + obj.data.owner_vehicle?.model?.model_name;
      }
    } else {
      if (obj?.data?.owner_vehicle?.title) {
        string += ' ' + obj.data.owner_vehicle?.title;
      }
    }
  } else {
    string += obj.data.owner_vehicle?.full_title
  }
  string += ' |'

  // state {New or Used}
  if (obj?.data?.owner_vehicle?.new_vehicle) {
    string += " " + i18n.t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_NEW)
  } else {
    string += " " + i18n.t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_USED);
  }
  // {fuel}
  if (obj.type == productTypes.car) {
    if (obj?.data?.owner_vehicle?.vehicle?.product_category_type?.product_type_id === 1) {
      if (obj.data.owner_vehicle?.fuel?.name) {
        string += ' | ' + obj.data.owner_vehicle?.fuel?.name;
      }
    }
    //{year Car}
    if (obj?.data?.owner_vehicle?.vehicle_first_register_year) {
      string += ' | ' + i18n.t(translations.ads.META_FROM_YEAR) + " " + obj.data.owner_vehicle?.vehicle_first_register_year;
    }
  }


  // {body type}
  if (obj.type == productTypes.bike) {
    if (obj?.data?.owner_vehicle?.body_vehicle?.name) {
      string += ' | ' + obj.data.owner_vehicle?.body_vehicle?.name;
    }
  }

  // autonomie
  if (obj.type == productTypes.bike || obj.type == productTypes.step) {
    if (obj?.data?.owner_vehicle?.vehicle_mileage) {
      string += ` | ${i18n.t(translations.ads.AUTONOMY)} : ` + obj.data.owner_vehicle?.vehicle_mileage + ' km';
    }
  }

  // {year Bike}
  if (obj.type == productTypes.bike) {
    if (obj?.data?.owner_vehicle?.vehicle_first_register_year) {
      string += ' | ' + obj.data.owner_vehicle?.vehicle_first_register_year;
    }
  }

  // price
  if (obj?.data?.total_price && obj?.data?.type_of_sale !== adsType.auction) {
    string += ' | ' + obj.data.total_price + " €";
  }
  // {locality}
  string += ' | '
  if (obj?.data?.user_data) {
    if (obj.data.user_data?.limited_address?.locality) {
      string += i18n.t(translations.ads.META_CAR__DETAIL_DESCRIPTION) + " " + obj.data.user_data.limited_address.locality
    } else {
      string += i18n.t(translations.ads.META_CAR__DETAIL_DESCRIPTION) + " " + obj.data.user_data.limited_address.country
    }
  }
  //  {country}
  if (obj.type == productTypes.car || obj.type == productTypes.step) {
    if (obj?.data?.user_data && obj.data.user_data?.limited_address?.country) {
      string += (obj.type == productTypes.car ? i18n.t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_SALE_OF_CARS) : " - ") + " " + obj.data.user_data.limited_address.country
    }
  }
  string += ' '
  return string
}

export const imageMeta = (data) => {
  let string: string = ''
  if (data?.owner_vehicle?.media && data?.owner_vehicle?.media.length > 0) {
    return string = data?.owner_vehicle?.media[0].original_url
  } else {
    return string = process.env.REACT_APP_BASE_URL + '/content.jpg'
  }
}

export const imageAltData = (data) => {
  let string: string = ''
  if (data?.owner_vehicle?.vehicle?.product_category_type?.product_type_id) {
    if (data.owner_vehicle?.model?.brand_car?.name) {
      string += ' ' + data.owner_vehicle.model.brand_car.name;
    }
    if (data?.owner_vehicle?.model?.model_name) {
      string += ' ' + data.owner_vehicle?.model?.model_name;
    }
    if (data?.owner_vehicle?.title) {
      string += ' ' + data.owner_vehicle?.title;
    }
  } else {
    if (data?.owner_vehicle?.title) {
      string += ' ' + data.owner_vehicle?.title;
    }
  }
  string += ' |'
  if (data?.owner_vehicle?.vehicle?.product_category_type?.product_type_id === productTypes.car) {
    if (data?.owner_vehicle?.outside_color?.name) {
      string += '  ' + data?.owner_vehicle?.outside_color?.name;
      string += ' |'
    }
  }

  if (data?.owner_vehicle?.vehicle_first_register_year) {
    string += ' ' + data.owner_vehicle?.vehicle_first_register_year;
  } else if (data?.owner_vehicle?.new_vehicle) {
    string += ' ' + i18n.t(translations.PUBLIC_ADS_SCREEN.PUBLIC_ADS_SCREEN_NEW)
  }
  string += ' - CartoBike'

  return string;
}

export const withoutVAT = (value, vatValue, totalValue) => {
  let obj = {};
  if (!!value) {
    obj['init_value'] = value;
    obj['percentage'] = vatValue;
    let amount = (value / 100) * vatValue
    obj['vat_amount'] = amount
    let tot = parseInt(value) + amount
    obj['goal_price'] = tot;
    return obj;
  }
  if (totalValue && vatValue) {
    let check;
    // obj['init_value'] = value;
    obj['goal_price'] = value;
    obj['percentage'] = vatValue;
    let checkvalType = typeof vatValue
    if (checkvalType === 'string') {
      check = totalValue / (1 + (parseInt(vatValue) / 100))
    } else {
      check = totalValue / (1 + (vatValue / 100))
    }
    let amount = (totalValue - check).toFixed(2)
    obj['vat_amount'] = amount
    obj['init_value'] = check;
    return obj;
  }
  return null;
}

export const setCookie = (name, value, days: any = "") => {
  var expires = "";
  var date = new Date();
  if (days) {
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  } else {
    date.setTime(date.getTime() + (10 * 365 * 24 * 60 * 60))
    expires = "; expires" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}


export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const deleteCookie = (name) => {
  document.cookie = name + '=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};


export const SeoString = {
  siteTitle: 'CarToBike',
  adNotFound: 'Ad not found',
}

export const removeLocalStorageScreen = () => {
  localStorage.removeItem(localstorageKey.screen);
}

export const getAltText = (item, adType) => {
  let price: any;
  const title = item?.owner_vehicle?.title
  const color = item?.owner_vehicle?.outside_color?.name
  const year = item?.owner_vehicle?.new_vehicle ? "New" : item?.owner_vehicle?.new_vehicle === false && item?.owner_vehicle?.vehicle_first_register_year != null ? item?.owner_vehicle?.vehicle_first_register_year : ''
  const transmission = item?.owner_vehicle?.gearbox?.name
  const bodyType = item?.owner_vehicle?.body_vehicle?.name
  let string: string = ""
  //for price
  if (item?.public_price) {
    if (item?.owner_vehicle?.vehicle_deductible_vat) {
      if (item?.user_data && item?.user_data?.limited_address?.vat_rate) {
        let totalVatPrice = (item?.public_price * parseInt(item.user_data.limited_address.vat_rate)) / 100
        let totalPrice = item?.public_price + totalVatPrice
        price = totalPrice
      }

    } else {
      price = item?.public_price
    }
  }
  // for title
  if (adType === 'park') {
    string += ' ' + item?.full_title;
  } else {
    if (item?.owner_vehicle?.model?.brand_car?.name) {
      string += ' ' + item?.owner_vehicle?.model?.brand_car?.name
    }
    if (item?.owner_vehicle?.model?.model_name) {
      string += ' ' + item?.owner_vehicle?.model?.model_name
    }
  }
  const altText = `${title && (string + " " + title + " |") || ''} ${color && color + " |" || ''}Price: € ${price ? numberFormat(price.toFixed(2)) + " |" : 0 + " |" || ''} ${year && year + " |"} ${transmission && transmission + " |" || ''} ${bodyType && bodyType || ''}`
  return altText
}

export const userAgentType = {
  isAndroid: "Android",
  isIphone: "iOS",
  isWindowsPhone: "Windows Phone",
  isDesktop: "unknown"
}

//for check the device OS
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return userAgentType.isWindowsPhone;
  }

  if (/android/i.test(userAgent)) {
    return userAgentType.isAndroid;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return userAgentType.isIphone;
  }

  return userAgentType.isDesktop;
}

export enum showcaseMenu {
  AboutType = 1,
  ServiceType = 2,
}

export const myAdsType = {
  classic: 1,
  auction: 2,
  park: 3
}

export const selectSort = {
  selectCreationDate: 0,
  selectAscendingPrice: 1,
  selectDescendingPrice: 2,
  selectAscendingBrand: 3,
  selectDescendingBrand: 4,
  selectRecentAds: 5,
  selectOldestAds: 6,
  selectRecentActions: 7
}


export const auctionEndAnimationTime = {
  total: 5000,
  endingTime: 3000,
  endedTime: 2000
}

export const animationType = {
  auction: 1,
  hotdeal: 3
}

export const editPageRoutes = ['edit-role']


export const auctionStatus = {
  registrationStartsIn: 1,
  registrationCloseIn: 2,
  auctionStartsIn: 3,
  auctionCloseIn: 4,
  auctionStartedInExtraTime: 5
}

export const StripePaymentConstant = {
  PaymentIncomplete: 'incomplete',
  PaymentIncompleteExpired: 'incomplete_expired',
  PaymentActive: 'active',
  PaymentPastDue: 'past_due',
  PaymentCanceled: 'canceled',
}

export const StepScreen = {
  MyAccount: 1,
  PlanDetails: 2,
  SelectCard: 3,
  PlanDetailsPayment: 4,
  PaymentSuccess: 5,
  Steps: 6
}

export const showCardType = {
  NewCard: 'newCard',
  SavedCards: 'savedCards',
}

export const vehicleStatus = {
  INITIAL: 0,
  DRAFT: 1,
  PARKED: 2,
  RESERVED: 3,
  SOLD: 4,
  DISABLED: 5,
  PENDING_APPROVAL: 6,
  DISPUTED: 7,
  PURCHASED: 8
}

export const vehicleStatusInverse = {
  1: 'draft',
  2: 'parked',
  3: 'reserved',
  4: 'sold',
  5: 'disabled',
  6: 'pendingApproval',
  7: 'disputed',
  8: 'purchased'
}

export const orderStatus = {
  inProgress: 1,
  buy: 2
}

export const isDamaged = {
  YES: 1,
  NO: 0
}

//function for getting country id using language code
export function getIdFromCode(localeCode) {
  const languageData = JSON.parse(localStorage.getItem('activeLanguages') || '')
  if (localeCode && languageData) {
    return languageData.languages.find(x => x.code === localeCode)
  }
  return
}
export const ValidationRegex = {
  uppercase: /^(?=.*[A-Z]).+$/,
  lowercase: /^(?=.*[a-z]).+$/,
  number: /^(?=.*[0-9]).+$/,
  specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
};

export function getAcceptLanguage() {
  let getLanguaget = localStorage.getItem(localstorageKey.i18nextLng);
  let laguagesVariable;
  if (getLanguaget === 'en-GB' || getLanguaget === 'us' || getLanguaget === 'en-US') {
    laguagesVariable = 'en'
  } else if (getLanguaget === 'fr') {
    laguagesVariable = 'fr'
  } else {
    laguagesVariable = getLanguaget ? getLanguaget : 'en'
  }
  return laguagesVariable
}

export const sendContactUsRequest = (payload) => {
  return new Promise((resolve, reject) => {
    Api.post(ApiPaths.public.contactUs, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
