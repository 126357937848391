import { Box, Modal } from '@mui/material';
import { useEffect, useState } from 'react'
import { ChatWrapper } from '../../style/chatStyle';
import { InitiateChat, UserInterface } from '../../interface/interface';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { addMemberSuccess, getArchivedChatSuccess, getChatSuccess, updateGroupDetailSuccess } from 'store/actions/chat-action';
import { getSubsidiaryStored } from 'config/variables';
import { suggestedMembers } from 'config/chatConfig';
import { useWindowSize } from 'app/Hooks/windowResize';
import * as ChatConstants from "config/chatConfig"
import GroupMemberList from './GroupMemberList';
import SuggestedMemberList from './SuggestedMemberList';


const CreateNewGroupChat = ({ chatType, chatId, setGroupTitle, groupTitle, groupMembers, showInitiateChatModal, setShowInitiateChatModal, allUserList, chatData }: InitiateChat) => {
    // local states
    const [addNewMember, setAddNewMember] = useState<boolean>(false)
    const [groupMemberList, setGroupMemberList] = useState<Array<UserInterface>>([])
    const [removedMemberList, setRemovedMemberList] = useState<Array<number>>([])
    const [removedMemberListDetail, setRemovedMemberListDetail] = useState<Array<UserInterface>>([])
    const [newSelectedMemberList, setNewSelectedMemberList] = useState<Array<UserInterface>>([])
    const [allData, setAllData] = useState<Array<UserInterface>>([])
    // hooks and global state
    const [mobile] = useWindowSize()

    const [groupUpdated, setGroupUpdated] = useState<boolean>(false)

    const { subsidiaryMemberList } = useSelector((state: RootStateOrAny) => state.chat)
    const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth)
    const { subsidiaryDetails } = useSelector((state: any) => state.company);
    const { addMemberList,
        removeMemberList,
        chatListAllData,
        chatList,
        archivedChatListAllData,
        archivedChatList, removeMemberListFailure } = useSelector((state: RootStateOrAny) => state.chat)


    const dispatch = useDispatch();

    useEffect(() => {
        if (groupMembers && groupMembers.length > 0) {
            setGroupMemberList(groupMembers)
            return
        }
        setGroupMemberList([])
    }, [groupMembers])

    useEffect(() => {
        const data = suggestedMembers(subsidiaryMemberList?.members, groupMembers)
        setAllData(data)
    }, [subsidiaryMemberList, groupMembers])

    useEffect(() => {
        if (addMemberList && Object.keys(addMemberList).length > 0 && addMemberList.success) {
            setGroupUpdated(true)
            let chatListObject = { ...chatListAllData }
            let chatListArray = [...chatList]
            if (chatType === ChatConstants.chatType.ARCHIVED) {
                chatListObject = { ...archivedChatListAllData }
                chatListArray = [...archivedChatList]
            }
            // find newly added member in chatlist
            let index = chatListArray.findIndex((o) => o.id === chatId)
            if (index !== -1) {
                chatListArray[index].users = [...chatListArray[index].users, ...newSelectedMemberList]
                // append new member in group member list locally
                setGroupMemberList([...groupMemberList, ...newSelectedMemberList])
            }
            chatListObject.data.data = chatListArray.sort(function (a, b) { return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime() })
            if (chatType === ChatConstants.chatType.ALL_MESSAGES) {
                // append new member in group member list globally (redux state) 
                dispatch(getChatSuccess({ data: chatListObject, append: false }))
            }
            if (chatType === ChatConstants.chatType.ARCHIVED) {
                dispatch(getArchivedChatSuccess({ data: chatListObject, append: false }))
            }
            dispatch(addMemberSuccess({}))
            setAddNewMember(false)
            setNewSelectedMemberList([])
        }
    }, [addMemberList])

    useEffect(() => {
        if (removeMemberList && Object.keys(removeMemberList).length > 0 && removeMemberList.success) {
            let chatListObject = { ...chatListAllData }
            let chatListArray = [...chatList]
            if (chatType === ChatConstants.chatType.ARCHIVED) {
                chatListObject = { ...archivedChatListAllData }
                chatListArray = [...archivedChatList]
            }
            // find newly added member in chatlist
            let index = chatListArray.findIndex((o) => o.id === chatId)
            if (index !== -1) {
                let users = [...chatListArray[index].users]
                // removing users from users array in chatlist data
                removeMemberList.data && removeMemberList.data?.removed_member_ids?.map((memberId, indx) => {
                    let userExistence = users.findIndex(o => o.id == memberId && o.pivot?.subsidiary_id == getSubsidiaryStored())
                    if (userExistence !== -1) {
                        users.splice(userExistence, 1)
                    }
                })
                chatListArray[index].users = [...users]
            }
            chatListObject.data.data = chatListArray.sort(function (a, b) { return new Date(b.last_chat_at).getTime() - new Date(a.last_chat_at).getTime() })
            if (chatType === ChatConstants.chatType.ALL_MESSAGES) {
                // append new member in group member list globally (redux state) 
                dispatch(getChatSuccess({ data: chatListObject, append: false }))
            }
            if (chatType === ChatConstants.chatType.ARCHIVED) {
                dispatch(getArchivedChatSuccess({ data: chatListObject, append: false }))
            }
            setRemovedMemberListDetail([])
            setRemovedMemberList([])
            dispatch(updateGroupDetailSuccess({}))
        }
    }, [removeMemberList])

    useEffect(() => {
        if (removeMemberListFailure && Object.keys(removeMemberListFailure).length > 0 && removeMemberListFailure.data) {
            resetGroupMember()
        }
    }, [removeMemberListFailure])


    // Function to handle add member in group
    const handleCreateGroupchat = (userDetail) => {
        let allUserListData = allData
        let index = allUserListData.findIndex(o => o.id === userDetail.id && userDetail.pivot.subsidiary_id == getSubsidiaryStored())
        if (index !== -1) {
            // remove member from suggested member list
            allUserListData.splice(index, 1)
        }
        setNewSelectedMemberList([...newSelectedMemberList, userDetail])
    }
    // Function to handle remove members from add new memberlist
    const handleRemoveMember = (MemberId) => {
        let allSuggestedMemberList = [...newSelectedMemberList]
        let index = allSuggestedMemberList.findIndex(o => o.id === MemberId)
        if (index !== -1) {
            setAllData([...allData, allSuggestedMemberList[index]])
            allSuggestedMemberList.splice(index, 1)
            setNewSelectedMemberList(allSuggestedMemberList)
        }
    }
    // Function to handle remove members from group
    const removeMembersFromGroup = (userID, subID) => {
        let allUserListData = [...groupMemberList]
        let index = allUserListData.findIndex(o => o.id == userID && o.pivot?.subsidiary_id == subID)
        if (index !== -1) {
            let removedUser = allUserListData[index]
            setRemovedMemberListDetail([...removedMemberListDetail, removedUser])
            allUserListData.splice(index, 1)
            setRemovedMemberList([...removedMemberList, userID])
            setGroupMemberList(allUserListData)
            setNewSelectedMemberList([])
        }
    }

    // Resets removed members back to list when unmounted if not saved 
    const resetGroupMember = () => {
        if (Object.keys(removeMemberList).length === 0) {
            setGroupUpdated(false)
            setGroupMemberList([...groupMemberList, ...removedMemberListDetail])
            setRemovedMemberListDetail([])
            setRemovedMemberList([])
        }
    }

    return (
        <ChatWrapper>
            <Modal
                onClose={() => {
                    resetGroupMember()
                    setShowInitiateChatModal(false)
                    setAddNewMember(false)
                }}
                open={showInitiateChatModal}>
                <ChatWrapper>
                    <Box className={`intiateChat  flexColumn ${mobile ? 'intiateChatPopupInMobile' : 'intiateChatPopup'}`}>
                        <Box className="invite-member" >
                            {addNewMember ?
                                <SuggestedMemberList chatId={chatId} newMembers={newSelectedMemberList} setNewSelectedMemberList={setNewSelectedMemberList} handleRemoveMember={handleRemoveMember} setAddNewMember={setAddNewMember} allUserList={allData} handleCreateGroupchat={handleCreateGroupchat} groupMembers={groupMembers} setAllData={setAllData} /> :
                                <GroupMemberList setGroupTitle={setGroupTitle} subsidiaryDetails={subsidiaryDetails} chatId={chatId} removedMemberList={removedMemberList} groupTitle={groupTitle} removeMembersFromGroup={removeMembersFromGroup} setAddNewMember={setAddNewMember} setShowInitiateChatModal={setShowInitiateChatModal} allUserList={groupMemberList} chatData={chatData} groupUpdated={groupUpdated} setGroupUpdated={setGroupUpdated} resetGroupMember={resetGroupMember} />}
                        </Box>
                    </Box>
                </ChatWrapper>
            </Modal>
        </ChatWrapper>
    )
}

export default CreateNewGroupChat




