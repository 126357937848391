import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SvgfluentIcon } from 'app/components/svgicons/svgNew';
import userPlaceHolder from 'app/assets/Images/userplaceholder.png';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { userData } from 'app/pages/PublicProfile/interface/interface';
import * as publicProfileConstants from "config/publicProfileConfig"
import { useHistory } from 'react-router-dom';
import { Strings, getSubsidiaryStored } from 'config/variables';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { routes } from 'routes/routes';
import { followUnfollowRequest, resetPublicProfile } from 'store/actions/publicProfile-action';
import { Nullable } from 'app/pages/Chat/interface/interface';
import { Toaster } from 'services/Toaster';
import ToastMessageWithButton from 'app/components/ToastMessageWithButton';

const UserDetails = ({ name, profilePic, flag, verified, followers, source, slug, setOpen, followed, recID, list, updateUserList }: userData) => {

  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { loggedIn } = useSelector((state: RootStateOrAny) => state.auth)
  const { userData } = useSelector((state: any) => state.user)
  const { followUnfollowSuccessData } = useSelector((state: RootStateOrAny) => state.publicProfile)

  const [selectedID, _selectedID] = useState<Nullable<number>>(null)

  const handleNaviagte = () => {
    setOpen(false)
    dispatch(resetPublicProfile())
    // redirecting to public page of subsidiary if source is subsidiary
    if (source == publicProfileConstants.profileTypeString.subsidiary) {
      history.push('/' + publicProfileConstants.profile.pro + '/' + slug)
      return
    }
    // redirecting to public page of user if source is user
    history.push('/' + publicProfileConstants.profile.user + '/' + slug)
  }

  const showBtn = () => {
    // Shows follow btn if user is not loggedIn
    if (!loggedIn) {
      return true
    }

    // if subsidiary is same as logged in subsidary we wont' show follow/unfollow button
    if (source == publicProfileConstants.profileTypeString.subsidiary) {
      if (recID == getSubsidiaryStored()) {
        return false
      }
      return true
    }
    // if user is same as logged in user we wont' show follow/unfollow button
    if (source == publicProfileConstants.profileTypeString.user) {
      if (recID == userData.id && !getSubsidiaryStored()) {
        return false
      }
      return true
    }
  }

  // Follow /unfollow request
  const handleFollow = () => {
    if (!loggedIn) {
      history.push(routes.authRoutes.login)
      return;
    }
    if (userData?.profile_is_complete === false && userData?.profile_is_complete === false && !getSubsidiaryStored()) {
      Toaster.error(<ToastMessageWithButton type='error' buttonText={t(translations.CHAT.COMPLETE_PROFILE_BUTTON)} message={Strings.completeProfileString} click={() => history.push(routes.userRoutes.userProfileEdit)} />, false)
      return;
    }
    _selectedID(recID)
    let payload = {
      'status': !followed
    }
    if (source == publicProfileConstants.profileTypeString.subsidiary) {
      payload['profile_subsidiary_id'] = recID
    }
    if (source == publicProfileConstants.profileTypeString.user) {
      payload['profile_user_id'] = recID
    }
    dispatch(followUnfollowRequest(payload))
  }


  useEffect(() => {
    // updating follow/ unfollow button in user's followers list whenever any user is followed or unfollowed
    if (followUnfollowSuccessData && Object.keys(followUnfollowSuccessData).length > 0 && selectedID) {
      let userList = [...list]
      let existence = userList.findIndex(user => user.id == selectedID)
      if (existence != -1) {
        userList[existence].is_follow = !userList[existence].is_follow
        if (userList[existence].is_follow) {
          userList[existence].followers_count = userList[existence].followers_count + 1
          updateUserList(userList)
          _selectedID(null)
          return
        }
      }
      userList[existence].followers_count = userList[existence].followers_count - 1
      updateUserList(userList)
      _selectedID(null)
    }
  }, [followUnfollowSuccessData])

  return (
    <Box className='UserDetailsContainer flexRow alignItemsCenter justifyContentBetween'>
      <Box className='UserDetailsBox flexRow alignItemsCenter pointer' onClick={() => handleNaviagte()}>
        <Box className='userProfileView'>
          <img src={profilePic || userPlaceHolder} alt="user-img" />
          {flag && <img src={flag} alt="user-img" className='flag' />}
        </Box>
        <Box className='userDetails'>
          <Box className='flexRow'>
            <p className='no-margin'> {name} </p>
            {verified ? <span style={{ marginLeft: '5px' }}> <SvgfluentIcon /> </span> : null}
          </Box>
          <span>{publicProfileConstants.convertFollowersCount(followers) || 0} {followers == 1 ? t(translations.PUBLICPROFILE.FOLLOWER) : t(translations.PUBLICPROFILE.FOLLOWERS)}</span>
        </Box>
      </Box>

      {showBtn() && <span className={"pointer " + (followed ? "unfollowBtn" : "followBtn")} onClick={handleFollow}> {followed ? t(translations.PUBLICPROFILE.UNFOLLOW) : t(translations.PUBLICPROFILE.FOLLOW)}</span>}
    </Box>
  )
}

export default UserDetails