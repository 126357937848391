import { Box } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import english from 'styles/img/english.png';
import france from 'styles/img/france.png';
import deLanguage from 'app/AssetNew/Images/de.png';
import esLanguage from 'app/AssetNew/Images/es.png';
import nlLanguage from 'app/AssetNew/Images/nl.png';
import { colorNew } from 'styles/StyleConstants';
import { useDispatch } from 'react-redux';
import { langugageSwitchOpen } from 'store/actions/component-actions';
import { useDeviceType } from 'app/Hooks/useMediaQuery';

const LanguageSwitchinHeader = () => {

    const [isSmallDevice] = useDeviceType()
    const { i18n } = useTranslation();
    const dispatch = useDispatch();

    const languageImages = {
        en: english,
        fr: france,
        de: deLanguage,
        es: esLanguage,
        nl: nlLanguage,
    };

    const FlatImage = useMemo(() => {
        const imageSrc = languageImages[i18n.language] || english;
        return <img src={imageSrc} alt="flagIcon" className="flagIcon" />;
    }, [i18n.language]);

    if (isSmallDevice) {
        return null
    }

    return (
        <Box
            onClick={() => dispatch(langugageSwitchOpen())}
            className='pointer languageSwitch'>
            <span>
                {FlatImage}
            </span>
        </Box>
    );
};

export default LanguageSwitchinHeader;
