import { Box } from "@mui/material";
import styled from "styled-components";
import { colorNew } from "styles/StyleConstants";



export const HotdealPopupStyle = styled(Box)`
.display-text{
  display: flex;
  justify-content: center;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.7px;
  letter-spacing: 0.02em;
  text-align: center;
}
.launch-header{
font-family: Rubik;
font-size: 18px;
font-weight: 500;
line-height: 28px;
text-align: left;
}
.description{
  font-family: Rubik;
font-size: 14px;
font-weight: 400;
line-height: 25px;
text-align: left;
}
.closeIcon{
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  width: 18px;  
  }

  svg.closeIcon path {
  stroke: ${colorNew.colorBlack};
}

.image-style{
  width: 400px;
  height: 525px;
  object-fit: cover;
  border-radius: 12px 0px 0px 12px;
}

.hot-deal-btn{
 display: flex;
justify-content: center;
background-color: #010A1E;
color:#fff !important;
min-width: 220px;
width:fit-content;
height: 44px;
border-radius: 8px;
text-transform: none;
}
.learn-more{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color:#475569;
}
.hotdeal-img {
  background-image: url('/hotdealsBackground.png');
  background-size: cover; 
  background-repeat: no-repeat;
  width: 320px;
  height: 100px;
  position: relative;
  transform: rotate(-2deg);
  margin: 0px 10px 30px 5px;
} 
.hot-deal-text{
  font-family: Sansita Swashed, cursive;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  text-wrap:nowrap;
  transform: rotate(2deg) translate(-50%,-50%);
}

@media (max-width: 767px) {
  .hotdeal-img {
    width: 260px;
    margin: -10px 10px 10px 5px;
    height: 100px;
  }
  .closeIcon{
  right: -12px;
  top: 12px;
}
.hot-deal-text{ 
  font-size: 35px;
}
}
`;