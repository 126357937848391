import { lazyLoad } from 'utils/loadable';
import { ContentLoader } from 'app/components/ContantLoader/ContentLoader';

export const MyAdsPage = lazyLoad(
  () => import('./index'),
  module => module.MyAdsPage,
  {
    fallback: (
      <ContentLoader />
    ),
  },
);