import { Box } from '@mui/material'
import { SvgfluentIcon } from 'app/components/svgicons/svgNew'
import React, { useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { urlParamInterface } from 'config/interface'
import { useLocation, useParams } from 'react-router-dom'
import ChooseCustomer from 'app/components/ChooseCustomer/ChooseCustomer'
import UserPlaceholer from 'app/assets/Images/userplaceholder.png'
import adsImageNotFound from 'app/assets/Images/adsPlaceholder.svg';
import { Carousel } from 'react-responsive-carousel'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/translations'
import { useDeviceType } from 'app/Hooks/useMediaQuery'
import { profile, profileType, profileTypeString } from 'config/publicProfileConfig'
import { myVehicleStatus } from 'config/variables'
import { getParamQueries } from 'config/appConfig'
import { getProfileRequest } from 'store/actions/publicProfile-action'
import { selectCustomer } from 'store/actions/myAds-actions'
import AccountVerifiedIcon from 'app/Component2/GlobalComponent/AccountVerifiedIcon '

const UserAndVehicleDetails = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { my_vehicle_status, vehicle_id }: urlParamInterface = useParams();
    const [isSmallDevice, isMediumDevice] = useDeviceType()

    const { selectedCustomer } = useSelector((state: RootStateOrAny) => state.myAds)
    const { validateData, loading } = useSelector((state: RootStateOrAny) => state.vehicle)


    useEffect(() => {
        const orderCreatedFor = localStorage.getItem('createOrderFor') ? JSON.parse(localStorage.getItem('createOrderFor') || '') : {}
        if ((!selectedCustomer || Object.keys(selectedCustomer).length == 0) && orderCreatedFor && Object.keys(orderCreatedFor).length > 0) {
            dispatch(selectCustomer(orderCreatedFor))
        }
    }, [])


    const getName = (selectedCustomer) => {
        if (selectedCustomer.name && selectedCustomer.source == profileTypeString.subsidiary) {
            return selectedCustomer.name
        }
        if (selectedCustomer.source == profileTypeString.user && selectedCustomer.name) {
            return `${selectedCustomer.name}`
        }
        if (selectedCustomer.source == profileTypeString.user && selectedCustomer.pseudo) {
            return `${selectedCustomer.pseudo}`
        }
        return '-'
    }

    const getImage = (selectedCustomer) => {
        if (selectedCustomer.image_url) {
            return selectedCustomer.image_url
        }
        if (selectedCustomer.logo_url && selectedCustomer.source == profileTypeString.subsidiary) {
            return selectedCustomer.logo_url
        }
        if (selectedCustomer.profile_picture_url && selectedCustomer.source == profileTypeString.user) {
            return selectedCustomer.profile_picture_url
        }
        return UserPlaceholer
    }

    const getEmail = (selectedCustomer) => {
        if (selectedCustomer.email) {
            return selectedCustomer.email
        }
        if (selectedCustomer.email_principal) {
            return selectedCustomer.email_principal
        }
        return '-'
    }

    const getPhone = (selectedCustomer) => {
        if (selectedCustomer.phone) {
            return selectedCustomer.phone
        }
        if (selectedCustomer.phone_principal) {
            return `${selectedCustomer.limited_address.prefix_call} ${selectedCustomer.phone_principal}`
        }
        return '-'
    }

    return (
        <Box>
            <Box className='userDetailsContainer flexRow'>

                <Box className='userDetails flexRow'>
                    <Box className='userImages'>
                        <img src={getImage(selectedCustomer)} alt='' className='userImage' />
                        {selectedCustomer?.customer_limited_address?.flag_thumbnail && <img src={selectedCustomer.customer_limited_address.flag_thumbnail} alt='' className='userFlag' />}
                    </Box>
                    <Box className='flexColumn userInfo'>
                        <Box component={'span'} className='userName' sx={{ display: 'flex' }}>
                            {getName(selectedCustomer)}
                            <AccountVerifiedIcon isVerified={selectedCustomer.profile_is_verified} />
                        </Box>
                        <span className='userContact'>{getEmail(selectedCustomer)}</span>
                        <span className='userContact'>{getPhone(selectedCustomer)}</span>
                    </Box>
                </Box>

                {my_vehicle_status == myVehicleStatus.createOrder && <ChooseCustomer adID={vehicle_id} type={'changeCustomer'} />}

            </Box>
            <Box className='vehicleImage'>

                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    swipeable
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                >
                    {
                        validateData.media && validateData.media.length > 0 ? validateData.media.map((image, index) => {
                            return <Box key={image + (image.id ?? index)}>
                                <img src={image.medium_url} alt='vehcile_image' className='vehicleImage' />
                            </Box>
                        })
                            :
                            <img src={adsImageNotFound} />
                    }
                </Carousel>
            </Box>

            {!loading && (isSmallDevice || isMediumDevice) && <Box className='vehcileDetails borderBottom' sx={{ py: 2, mb: 2 }}>
                <span className='vehicleName'>{validateData?.full_title ?? "-"}</span>
                <Box className='details flexRow'>
                    <p> {t(translations.ads.VIN)} : </p>
                    <span > {validateData?.vin ?? "-"}</span>
                </Box>
                <Box className='details flexRow'>
                    <p > {t(translations.ads.FIRST_REGISTER)} :</p>
                    <span >
                        {validateData?.vehicle_first_register_month && validateData?.vehicle_first_register_year ? `${validateData.vehicle_first_register_month}/ ${validateData.vehicle_first_register_year}` : "-"}
                    </span>

                </Box>
            </Box>
            }

        </Box>
    )
}

export default UserAndVehicleDetails